import React, { ReactNode } from "react";

import { colors } from "#utils/colors";

interface IText {
    xxlarge?: boolean;
    xlarge?: boolean;
    large?: boolean;
    small?: boolean;
    xsmall?: boolean;
    center?: boolean;
    semiBold?: boolean;
    bold?: boolean;
    medium?: boolean;
    color?: string;
    underline?: boolean;
    lineThrough?: boolean;
    style?: React.CSSProperties;
    children?: ReactNode;
    onPress?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

/**
 * @deprecated
 * this has been deprecated because it belongs to a piece of
 * legacy code. If you would like to use this in new code please copy only what
 * you need out of the legacy directory and into the new structure. Any copied
 * code must adhere to the frontend style guide / quality standards;
 * in addition to this, all other non-legacy code using this component should
 * be adapted with your refactored and reorganized version
 */
export const Text = ({
    xxlarge,
    xlarge,
    large,
    small,
    xsmall,
    center,
    bold,
    semiBold,
    medium,
    color,
    underline,
    lineThrough,
    style,
    children,
    onPress
}: IText) => {
    let fontSize = 16;
    let textAlign;
    textAlign = "left" as const;

    let fontWeight: any;
    fontWeight = "normal" as const;

    let fontStyle = "none";
    const cursor = onPress ? "pointer" : "auto";

    if (xlarge) {
        fontSize = 30;
    }
    if (xxlarge) {
        fontSize = 36;
    }
    if (large) {
        fontSize = 18;
    }
    if (small) {
        fontSize = 14;
    }
    if (xsmall) {
        fontSize = 12;
    }
    if (center) {
        textAlign = "center" as const;
    }
    if (bold) {
        fontWeight = "bold" as const;
    }
    if (medium) {
        fontWeight = "500";
    }
    if (semiBold) {
        fontWeight = "600";
    }
    if (underline) {
        fontStyle = "underline" as const;
    }
    if (lineThrough) {
        fontStyle = "line-through" as const;
    }
    return (
        <span
            onClick={onPress}
            style={{
                color: color || colors.text,
                fontSize: fontSize,
                textAlign: textAlign,
                fontWeight: fontWeight,
                ...style,
                textDecoration: fontStyle,
                display: "inline-block",
                cursor
            }}
        >
            {children}
        </span>
    );
};
