import { NumberInput } from "@tremor/react";
import clsx from "clsx";
import React from "react";

import { useAppSelector } from "src/redux/hooks";
import { getUserFeeSettingsPermission } from "src/redux/selectors";

type Props = {
    value?: number;
    type?: "number";
    disabled?: boolean;
    prefix?: string;
    suffix?: string;
    step: number;
    placeholder: string;
    min?: number;
    max: number;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const FeeInput = ({
    value,
    prefix,
    suffix,
    disabled = false,
    step,
    placeholder,
    min = 0,
    max,
    onChange
}: Props) => {
    const writeAccess = useAppSelector(getUserFeeSettingsPermission);
    const allDisabled = !writeAccess || disabled;
    return (
        <div className="flex flex-row">
            {prefix && (
                <div
                    className={clsx(
                        "pointer-events-none flex items-center rounded-l-lg border border-r-0 px-2 text-sm text-gray-700",
                        allDisabled ? "opacity-40" : ""
                    )}
                >
                    {prefix}
                </div>
            )}
            <NumberInput
                className={clsx(
                    "flex bg-white",
                    prefix ? "rounded-l-none" : "",
                    allDisabled ? "opacity-40" : ""
                )}
                value={value}
                disabled={allDisabled}
                step={step}
                placeholder={placeholder}
                min={min}
                max={max}
                onChange={onChange}
                icon={() =>
                    suffix ? (
                        <div className="absolute right-0 my-auto mr-20 flex text-micro">
                            {suffix}
                        </div>
                    ) : undefined
                }
            />
        </div>
    );
};
