import React, { useContext, useState } from "react";
import { Checkbox, Col, Form, Input, Row } from "antd";
import { debounce } from "radash";

import api from "src/api/rest";
import CloseButton from "#payouts/components/shared/CloseButton";
import { StyledModal, Header } from "#payouts/components/shared/Modal";
import {
    StatusReasons,
    Statuses,
    VerificationsResponse
} from "#payouts/domain/types";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { notifyFailure } from "#payouts/utils/notify";
import { messages } from "#payouts/utils/messages";
import { Label, RowDivider } from "#payouts/components/shared/ModalComponents";
import {
    adminNoteValidation,
    confirmStoreValidation
} from "#payouts/utils/validation/form";
import { handleException } from "#payouts/utils/handleException";
import { UpdatedButton } from "#payouts/components/shared/UpdatedButton";
import {
    AdminEnableSchema,
    AdminHoldSchema
} from "#payouts/utils/validation/schema";

export const AdminHoldModal = () => {
    const [form] = Form.useForm();

    const {
        store,
        storeId,
        verification,
        adminHoldModal,
        setVerification,
        handleModalChange
    } = useContext(PayoutsSettingsContext);

    const [hasChange, setHasChange] = useState(false);
    const [loading, setLoading] = useState(false);

    const [adminNote, setAdminNote] = useState<string | undefined>(undefined);
    const [confirmStore, setConfirmStore] = useState(false);

    const isPausing = verification?.status === Statuses.enabled;
    const action = isPausing ? "Pause" : "Enable";
    const newStatus = isPausing ? Statuses.paused : Statuses.enabled;
    const newReason = isPausing
        ? StatusReasons.snackpassAdminHold
        : StatusReasons.approved;

    const subtitle = isPausing
        ? "By proceeding you will disable payouts to the partners. Please provide a message to be displayed to partner admins."
        : "By proceeding you will re-enable payouts to the store.";

    const Schema = isPausing ? AdminHoldSchema : AdminEnableSchema;
    const error = adminHoldModal
        ? !Schema.safeParse({ adminNote, confirmStore }).success
        : false;

    const resetState = () => {
        setHasChange(false);
        setLoading(false);
        handleModalChange();
    };

    const disabled = !storeId || loading;
    const isValid = hasChange && !error;

    const submitUpdate = async ({
        status,
        reason,
        adminNote
    }: {
        status: Statuses;
        reason: StatusReasons;
        adminNote?: string;
    }): VerificationsResponse => {
        if (disabled || !isValid) {
            return Promise.reject();
        }
        setLoading(true);
        return api.verifications.updateAccountStatus(
            storeId,
            status,
            reason,
            adminNote
        );
    };

    const debouncedSubmit = debounce({ delay: 300 }, async () =>
        submitUpdate({
            status: newStatus,
            reason: newReason,
            adminNote
        })
            .then(({ data }) => {
                if (data?.success) {
                    setVerification(data.verification);
                    setHasChange(false);
                    setLoading(false);
                    handleModalChange({});
                } else {
                    notifyFailure(messages.modal.account);
                    handleException(data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                notifyFailure(messages.modal.account);
                handleException(error);
                setLoading(false);
            })
    );

    if (!adminHoldModal) return <></>;

    return (
        <StyledModal
            open={adminHoldModal}
            setOpen={() => handleModalChange()}
            header={
                <Header
                    left={<CloseButton onClose={() => resetState()} />}
                    center={`${action} Payouts`}
                />
            }
            footer={
                <>
                    <UpdatedButton
                        block
                        smallRadius
                        variant="tertiary"
                        size="regular"
                        children={<>Cancel</>}
                        disabled={disabled}
                        isValid={true} // cancel is always valid
                        onClick={() => handleModalChange()}
                    />
                    <UpdatedButton
                        block
                        smallRadius
                        variant="danger"
                        size="regular"
                        children={<>{action}</>}
                        disabled={disabled}
                        isValid={isValid}
                        loading={loading}
                        onClick={async () => {
                            if (disabled) {
                                return;
                            }
                            if (!isValid) {
                                form.setFields([
                                    {
                                        name: "adminNote",
                                        errors: adminNoteValidation(
                                            adminNote,
                                            !isPausing
                                        )
                                    },
                                    {
                                        name: "confirmStore",
                                        errors: confirmStoreValidation(
                                            confirmStore
                                        )
                                    }
                                ]);
                                return;
                            }
                            void debouncedSubmit();
                        }}
                    />
                </>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    adminNote,
                    confirmStore
                }}
            >
                {isPausing && (
                    <>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="adminNote"
                                    label={
                                        <Label
                                            required={isPausing}
                                            omitRequired={!isPausing}
                                            label={`${action} Payouts`}
                                            subtitle={subtitle}
                                        />
                                    }
                                >
                                    <Input
                                        disabled={!isPausing}
                                        placeholder={
                                            isPausing
                                                ? "Note to display to partner"
                                                : "Notes are only displayed when paused."
                                        }
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const errors =
                                                adminNoteValidation(value);
                                            setHasChange(true);
                                            setAdminNote(value);
                                            form.setFields([
                                                {
                                                    name: "adminNote",
                                                    value,
                                                    errors
                                                }
                                            ]);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <RowDivider />
                    </>
                )}
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="confirmStore"
                            label={<Label required label={"Confirm Store"} />}
                        >
                            <Checkbox
                                checked={confirmStore}
                                children={
                                    <>
                                        {`${action} payouts for ${store?.name} at ${store?.address}.`}
                                    </>
                                }
                                onChange={(e) => {
                                    const value = e.target.checked;
                                    const errors =
                                        confirmStoreValidation(value);
                                    setHasChange(true);
                                    setConfirmStore(value);
                                    form.setFields([
                                        {
                                            name: "confirmStore",
                                            value,
                                            errors
                                        }
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledModal>
    );
};
