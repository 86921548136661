import React from "react";

import Text from "#payouts/components/shared/Text";
import theme from "#payouts/utils/theme";
import { ReactComponent as Good } from "#payouts/assets/checkmark.svg";
import { ReactComponent as Info } from "#payouts/assets/info.svg";

export const ModalRequirement = ({
    good,
    message
}: {
    good: boolean;
    message: string;
}) => (
    <Text.Subtitle style={{ margin: `${theme.spacing.half} 0` }}>
        <RequirementMet good={good} />
        <span>{message}</span>
    </Text.Subtitle>
);

const goodTransform = { transform: "scale(0.8)" };
const infoTransform = { transform: "scale(1.0)" };

const RequirementMet = ({ good }: { good: boolean }) =>
    good ? <Good style={goodTransform} /> : <Info style={infoTransform} />;
