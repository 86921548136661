/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from "react";
import { SystemColors } from "@snackpass/design-system";
import Fuse from "fuse.js";
import fp from "lodash/fp";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Primitive } from "type-fest";
import _ from "lodash";

import logo_round from "src/assets/images/logo-round.png";
import { setActiveStoreId } from "src/redux/slices";
import { SelectPlaceholder } from "#store-selector/select-placeholder";
import {
    _filterArchivedStores,
    _formatOption,
    _getMyStores,
    _showStoreSelector,
    _tokeniseStringWithQuotesBySpaces
} from "#store-selector/utils";
import { FuseStore, MinimalStore, OptionType } from "#store-selector/types";
import { StoreOption } from "#store-selector/store-option";

import { useAsync } from "../hooks/use-async";
import { useGlobalDate } from "../hooks";
import {
    getActiveStore,
    getActiveStoreLogoUrl,
    getStores,
    getUser,
    getUserTeamPermission
} from "../../redux/selectors";

export const SelectStore = () => {
    const { resetGlobalDateDefaults } = useGlobalDate();
    const history = useHistory();
    const dispatch = useDispatch();

    const isSnackpassTeam = useSelector(getUserTeamPermission);
    const stores = useSelector(getStores);
    const activeStore = useSelector(getActiveStore);
    const user = useSelector(getUser);
    const activeStoreLogoUrl = useSelector(getActiveStoreLogoUrl);
    const icon = activeStoreLogoUrl || logo_round;

    const [availableStores, setAvailableStores] = useState<MinimalStore[]>([]);
    const [searchQuery, setSearchQuery] = useState("");

    const fetch = async () => {
        if (isSnackpassTeam) {
            return;
        } else if (user?.hasOwnProperty("snackpassPermissions")) {
            return await _getMyStores(user);
        }
    };

    useAsync(
        fetch,
        (res) => {
            if (!res) {
                fp.compose(setAvailableStores, _filterArchivedStores)(stores);
            } else {
                fp.compose(setAvailableStores, _filterArchivedStores)(res);
            }
        },
        [
            isSnackpassTeam,
            user?._id,
            stores.map((store) => store._id).join("")
        ] as Primitive[]
    );

    const onChange = (option: OptionType | null) => {
        if (option?.value) {
            dispatch(setActiveStoreId(option.value));
            resetGlobalDateDefaults();
            history.push("/");
        }
    };

    const fuse: Fuse<FuseStore> = useMemo(
        () =>
            new Fuse(
                availableStores.map((s) => ({
                    // _id matching is handled separately, since a fuzzy match on _id brings in too many results
                    keywords: [s.region, s.name, s.address],
                    ...s
                })),
                {
                    keys: ["keywords"],
                    isCaseSensitive: false,
                    // 0 = needs to be a perfect match, 1 = matches everything
                    threshold: 0.2,
                    shouldSort: true,
                    ignoreLocation: true
                }
            ),
        [availableStores]
    );

    const fuseFilter = (query: string) => {
        if (query.length === 0 || fuse === null) {
            return availableStores;
        }
        const tokenisedSearchQuery = _tokeniseStringWithQuotesBySpaces(query);
        // Every token must match for this to be considered a match.
        // See https://github.com/krisk/Fuse/issues/235 for the original idea.
        const fuseResult = fuse.search({
            $and: tokenisedSearchQuery.map((searchToken: string) => ({
                keywords: searchToken
            }))
        });
        const storesFromIdBasedExactMatching: MinimalStore[] =
            availableStores.filter((store) => store._id.includes(query));

        const storesFromFuse: FuseStore[] = fuseResult.map((r) => r.item);

        // Start with empty object to avoid mutating the original values
        return (
            _({})
                // Merge the results from the id-based exact matching and the fuzzy matching
                .merge(
                    _.keyBy(storesFromIdBasedExactMatching, "_id"),
                    _.keyBy(storesFromFuse, "_id")
                ) // create a dictionary from fuse results, and merge it to the 1st
                .values() // turn the combined dictionary to array
                .value()
        );
    };

    const postFilterAvailableStores = useMemo(
        () => fuseFilter(searchQuery),
        [fuse, searchQuery, availableStores]
    );

    if (!activeStore) {
        return null;
    }

    if (!user || !_showStoreSelector(user)) {
        return (
            <span style={{ paddingLeft: "10px" }}>
                <SelectPlaceholder name={activeStore.name} imgUrl={icon} />
            </span>
        );
    }

    // TODO: Refactor this into a less angry select component.
    return (
        <Select
            options={postFilterAvailableStores.map((s) => _formatOption(s))}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value={activeStore._id as any} // TODO: Remove these any casts.
            placeholder={
                <SelectPlaceholder name={activeStore.name} imgUrl={icon} />
            }
            onChange={onChange}
            onInputChange={setSearchQuery} // Gain access to the typed in search query
            filterOption={null} // we're filtering with fuse rather than react-select
            components={{ Option: StoreOption }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            styles={customStyles as any} // TODO: Remove these any casts.
            menuContainerStyle={{ zIndex: 99999 }}
            theme={(theme) => ({
                ...theme,
                borderRadius: 9,
                colors: {
                    ...theme.colors,
                    primary25: `${SystemColors.v2.salt20.light}`,
                    primary: `${SystemColors.v2.salt80.light}`
                }
            })}
        />
    );
};

const customStyles = {
    indicatorSeparator: () => ({
        visibility: "hidden"
    }),
    dropdownIndicator: (provided: Record<string, unknown>) => ({
        ...provided,
        color: SystemColors.v2.salt100.light
    }),
    option: (provided: Record<string, unknown>) => ({
        ...provided,
        cursor: "pointer"
    }),
    control: (provided: Record<string, unknown>) => ({
        ...provided,
        cursor: "pointer"
    })
};
