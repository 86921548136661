import React from "react";
import { SystemColors } from "@snackpass/design-system";
import styled from "@emotion/styled";

import PromoImage from "src/assets/icons/promo.png";
import { Title } from "#guestbook/components/styled-components";

import { DISCOUNTS, DiscountTemplate } from "./constants";

type OnSelect = (data: DiscountTemplate) => void;

export const Discounts = ({ onSelect }: { onSelect: OnSelect }) => (
    <div>
        <Title icon={PromoImage} label="Discounts" />
        <DiscountListContainer>
            {DISCOUNTS.map((template, index) => (
                <Discount
                    key={template.label}
                    isLast={DISCOUNTS.length - 1 === index}
                    template={template}
                    onSelect={onSelect}
                />
            ))}
        </DiscountListContainer>
    </div>
);

type DiscountProps = {
    template: DiscountTemplate;
    onSelect: OnSelect;
    isLast: boolean;
};

const Discount = ({ isLast, template, onSelect }: DiscountProps) => (
    <DiscountContainer onClick={() => onSelect(template)} isLast={isLast}>
        <DiscountImageContainer>
            <img src={PromoImage} style={{ width: 32, objectFit: "contain" }} />
        </DiscountImageContainer>

        <div style={{ flex: 1 }}>
            <DiscountLabel>{template.label}</DiscountLabel>
            <div
                style={{
                    fontSize: 16,
                    color: SystemColors.v1.gray30
                }}
            >
                Expires in {template.expiresInDays} days
            </div>
        </div>

        <DiscountSubtext>{template.subtext}</DiscountSubtext>
    </DiscountContainer>
);

const DiscountListContainer = styled.div`
    padding: 0 15px;
    border: 1px solid ${SystemColors.v1.gray80};
    border-radius: 16px;
`;

const DiscountContainer = styled.div`
    cursor: pointer;
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: ${(props: { isLast: boolean }) =>
        props.isLast ? "none" : "1px solid " + SystemColors.v1.gray80};
`;

const DiscountImageContainer = styled.div`
    background-color: ${SystemColors.v1.parsley50};
    width: 60px;
    height: 60px;
    text-align: center;
    margin-right: 15px;
    border-radius: 8px;
    padding-top: 13px;
`;

const DiscountLabel = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: ${SystemColors.v1.black};
    margin-bottom: 0;
`;

const DiscountSubtext = styled.div`
    font-weight: bold;
    font-size: 16px;
    color: ${SystemColors.v1.parsley50};
`;
