import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useSetRecoilState } from "recoil";

import { CardTitle } from "src/@/components/ui/card";
import CampaignBrandRegistrationHeader from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationHeader";
import { Button } from "src/@/components/ui/button";
import {
    BrandRegistrationStep,
    brandRegistrationStepAtom
} from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationAtoms";

function CampaignBrandRegistrationPendingReview() {
    const setStep = useSetRecoilState(brandRegistrationStepAtom);
    return (
        <>
            <CampaignBrandRegistrationHeader>
                <Button
                    onClick={() => setStep(BrandRegistrationStep.GetStarted)}
                >
                    Back to Campaigns
                </Button>
            </CampaignBrandRegistrationHeader>
            <div className="flex flex-col items-center">
                <div className="flex max-w-screen-md flex-col items-start gap-4 p-12">
                    <CheckCircleIcon className="h-9 w-9" />
                    <CardTitle className="text-xl">
                        We’re registering your number
                    </CardTitle>
                    <div className="text-gray-500">
                        We’ll reach out if there’s any additional information
                        needed.
                    </div>
                    <div className="font-medium">What happens now?</div>
                    <div className="text-gray-500">
                        Before you can use Snackpass Text Message Marketing,
                        your account goes through a verification process.
                        Usually the time to verify information and register your
                        phone number is around 2 to 3 business days, but it may
                        take up to 14 business days.
                    </div>
                    <div className="text-gray-500">
                        You will receive a notification when your phone number
                        is ready to use.
                    </div>
                </div>
            </div>
        </>
    );
}

export default CampaignBrandRegistrationPendingReview;
