import { debounce } from "radash";
import { toast } from "sonner";

import api from "src/api/rest";
import { descriptions } from "#billing/utils/descriptions";

const fetch = async (
    storeId: string,
    cb: (loading: boolean, success: boolean) => void
) => {
    void api.billing
        .getPortal(storeId)
        .then(({ data }) => {
            window.location.href = data.portalUrl;
        })
        .catch((error) => {
            cb(false, false);
            toast.error(descriptions.fetch.error, {
                description: error.message
            });
        });
};

export const onBilling = debounce({ delay: 300 }, fetch);
