import {
    AddonOverrideFields,
    Menu as MultiMenu,
    ProductOverrideFields
} from "@snackpass/snackpass-types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { pick } from "lodash";

import api from "src/api/rest";
import { IProductCategoryWithProducts } from "#menu-editor/mobile-friendly/helpers/context";
import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import {
    addNewAddonGroupsToMenus,
    addNewAddonsToMenus,
    NewAddonGroups,
    NewAddons,
    removeAddonGroupFromMenus,
    RemoveAddons,
    removeAddonsFromMenus,
    UpdateOverridesPayload
} from "#menu-editor/multi-menus/redux/thunk-helpers";
import {
    ItemTypes,
    MultiMenuState
} from "#menu-editor/multi-menus/redux/types";
import { setActiveMenu } from "src/redux/slices";

const getState = (thunkAPI: { getState: () => unknown }) =>
    thunkAPI.getState() as { multiMenus: MultiMenuState };

export const fetchStoreMultiMenus = createAsyncThunk(
    "multiMenus/fetchStoreMultiMenus",
    async (storeId: string) => await api.multiMenus.getStoreMultiMenus(storeId)
);

export const createMultiMenu = createAsyncThunk(
    "multiMenus/createMultiMenu",
    async (storeId: string, thunkAPI) => {
        const state = thunkAPI.getState() as { multiMenus: MultiMenuState };
        const newMenu = await api.multiMenus.createMultiMenu(
            storeId,
            state.multiMenus.currentMenu
        );
        return newMenu;
    }
);

const updateMenu = createAsyncThunk(
    "multiMenus/updateOneMenu",
    async (params: { menu: MultiMenu; fields: void | (keyof MultiMenu)[] }) =>
        await api.multiMenus.updateMultiMenu(
            params.menu.storeId,
            params.menu.id,
            !params.fields ? params.menu : pick(params.menu, params.fields)
        )
);

const persistCurrentMenu = createAsyncThunk(
    "multiMenus/updateCurrentMenu",
    async (fields: void | (keyof MultiMenu)[], thunkAPI) => {
        const state = thunkAPI.getState() as {
            multiMenus: MultiMenuState;
        };

        const updatedMenu = await api.multiMenus.updateMultiMenu(
            state.multiMenus.currentMenu.storeId as string,
            state.multiMenus.currentMenu.id as string,
            !fields
                ? state.multiMenus.currentMenu
                : pick(state.multiMenus.currentMenu, fields)
        );

        return updatedMenu;
    }
);

const resetCurrentMenu = createAsyncThunk(
    "multiMenus/resetCurrentMenu",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as { multiMenus: MultiMenuState };

        const serverMenu = await api.multiMenus.getMenu(
            state.multiMenus.currentMenu.storeId as string,
            state.multiMenus.currentMenu.id as string
        );

        return serverMenu;
    }
);

export const selectAll = createAsyncThunk(
    "multiMenus/selectAllThunk",
    (
        {
            productCategories
        }: { productCategories: IProductCategoryWithProducts[] },
        thunkAPI
    ) => {
        productCategories.forEach((categoryWithProducts) => {
            thunkAPI.dispatch(
                multiMenuActions.selectCategory({ categoryWithProducts })
            );
        });
    }
);

export const discardAll = createAsyncThunk(
    "multiMenus/discardAllThunk",
    (
        {
            productCategories
        }: { productCategories: IProductCategoryWithProducts[] },
        thunkAPI
    ) => {
        productCategories.forEach((categoryWithProducts) => {
            thunkAPI.dispatch(
                multiMenuActions.discardCategory({ categoryWithProducts })
            );
        });
    }
);

export const deleteMultiMenu = createAsyncThunk(
    "multiMenus/deleteMultiMenu",
    async (
        {
            storeId,
            menuId,
            deleteCallback
        }: {
            storeId: string;
            menuId: string;
            deleteCallback: (success: boolean) => void;
        },
        thunkAPI
    ) => {
        try {
            await api.multiMenus.deleteMultiMenu(storeId, menuId);
            deleteCallback(true);
            return menuId;
        } catch (error) {
            deleteCallback(false);
        }
    }
);

export const selectMultiMenu = createAsyncThunk(
    "multiMenus/selectMultiMenuThunk",
    async (
        {
            menuId,
            onMenuNotFound
        }: { menuId: string; onMenuNotFound: () => void },
        thunkAPI
    ) => {
        const state = getState(thunkAPI);
        const currentMenu = state.multiMenus.storeMenus.find(
            ({ id }) => id === menuId
        );

        if (!currentMenu) return onMenuNotFound();

        thunkAPI.dispatch(multiMenuActions.selectMultiMenu({ id: menuId }));
        thunkAPI.dispatch(
            setActiveMenu(
                currentMenu.type === "standard" ? "regular" : "catering"
            )
        );
    }
);

export const resetProductFields = createAsyncThunk(
    "multiMenus/resetProductFieldsThunk",
    async (params: {
        storeId: string;
        menuFields: {
            menuId: string;
            productId?: string;
            addonIds?: string[];
        }[];
    }) =>
        await Promise.all(
            params.menuFields.map(async ({ menuId, productId, addonIds }) =>
                api.multiMenus.updateMultiMenuOverrides(
                    params.storeId,
                    menuId,
                    {
                        ...(productId && { products: { [productId]: null } }),
                        ...(!!addonIds?.length && {
                            addons: addonIds.reduce(
                                (acc, addonId) => {
                                    acc[addonId] = null;
                                    return acc;
                                },
                                {} as Record<string, null>
                            )
                        })
                    }
                )
            )
        )
);

export const resetMenuOverrides = createAsyncThunk(
    "multiMenus/resetMenuOverridesThunk",
    async (
        params: {
            storeId: string;
            menuId: string;
        },
        thunkAPI
    ) => {
        const {
            multiMenus: { currentMenu }
        } = getState(thunkAPI);

        if (params.menuId !== currentMenu.id || !currentMenu.menuOverrides)
            return;

        const updateOverridesPayload: {
            products: Record<string, ProductOverrideFields | null>;
            addons: Record<string, AddonOverrideFields | null>;
        } = { products: {}, addons: {} };

        for (const productId in currentMenu.menuOverrides.products) {
            if (currentMenu.menuOverrides.products?.[productId].taxes)
                updateOverridesPayload.products[productId] = {
                    taxes:
                        currentMenu.menuOverrides.products?.[productId].taxes ??
                        null,
                    price: null
                };
            else updateOverridesPayload.products[productId] = null;
        }
        for (const addonId in currentMenu.menuOverrides.addons)
            updateOverridesPayload.addons[addonId] = null;

        return await api.multiMenus.updateMultiMenuOverrides(
            currentMenu.storeId ?? params.storeId,
            currentMenu.id ?? params.menuId,
            updateOverridesPayload
        );
    }
);

export const updateOverrides = createAsyncThunk(
    "multiMenus/updateProductFieldsThunk",
    async (
        params: {
            itemId: string;
            itemType: Exclude<ItemTypes, "addonGroups">;
        },
        thunkAPI
    ) => {
        const {
            multiMenus: { currentMenu }
        } = getState(thunkAPI);

        let updateOverridesPayload:
            | {
                  products?: Record<string, ProductOverrideFields | null>;
                  addons?: Record<string, AddonOverrideFields | null>;
              }
            | undefined;
        if (params.itemType === "products")
            updateOverridesPayload = {
                products: {
                    [params.itemId]:
                        currentMenu.menuOverrides?.products?.[params.itemId] ??
                        null
                }
            };
        else
            updateOverridesPayload = {
                addons: {
                    [params.itemId]:
                        currentMenu.menuOverrides?.addons?.[params.itemId] ??
                        null
                }
            };

        return await api.multiMenus.updateMultiMenuOverrides(
            currentMenu.storeId ?? "no-set",
            currentMenu.id ?? "no-set",
            updateOverridesPayload
        );
    }
);

export const updateModifiers = createAsyncThunk(
    "multiMenus/updateModifiersThunk",
    async (
        params: {
            newAddons?: NewAddons;
            newAddonGroups?: NewAddonGroups;
            deletedAddons?: RemoveAddons;
            deletedAddonGroups?: string[];
            productId: string;
        },
        thunkAPI
    ) => {
        const {
            multiMenus: { storeMenus }
        } = getState(thunkAPI);
        const updatedMenus: MultiMenu[] = [];

        let updateOverridesPayload: UpdateOverridesPayload = {};

        if (params.newAddonGroups && params.newAddonGroups.length > 0) {
            updateOverridesPayload = addNewAddonGroupsToMenus(
                storeMenus,
                params.newAddonGroups,
                params.productId,
                updateOverridesPayload
            );
        }

        if (params.newAddons && Object.keys(params.newAddons).length > 0) {
            updateOverridesPayload = addNewAddonsToMenus(
                storeMenus,
                params.newAddons,
                updateOverridesPayload
            );
        }

        if (params.deletedAddonGroups && params.deletedAddonGroups.length > 0) {
            updateOverridesPayload = removeAddonGroupFromMenus(
                storeMenus,
                params.productId,
                params.deletedAddonGroups,
                updateOverridesPayload
            );
        }

        if (
            params.deletedAddons &&
            Object.keys(params.deletedAddons).length > 0
        ) {
            updateOverridesPayload = removeAddonsFromMenus(
                storeMenus,
                params.deletedAddons,
                updateOverridesPayload
            );
        }

        if (Object.keys(updateOverridesPayload).length > 0) {
            await Promise.all(
                Object.entries(updateOverridesPayload).map(
                    async ([menuId, payload]) => {
                        const updatedMenu =
                            await api.multiMenus.updateMultiMenu(
                                payload.storeId,
                                menuId,
                                {
                                    menuOverrides: payload.overrides
                                }
                            );
                        updatedMenus.push(updatedMenu);
                    }
                )
            );
        }

        return updatedMenus;
    }
);

export const multiMenuThunks = {
    fetchStoreMultiMenus,
    discardAll,
    selectAll,
    selectMultiMenu,
    createMultiMenu,
    deleteMultiMenu,
    persistCurrentMenu,
    updateMenu,
    updateOverrides,
    resetMenuOverrides,
    resetCurrentMenu,
    resetProductFields,
    updateModifiers
};
