import { z } from "zod";

import {
    ChargeFormSchema,
    CommissionFormSchema,
    ConvenienceFormSchema,
    CustomFeeLabelFormSchema,
    MinimumFormSchema,
    MiscellaneousFormSchema,
    OperatingFormSchema
} from "#settings/settings-fees/utils/schema";

export type CommissionFormValues = z.infer<typeof CommissionFormSchema>;
export type ChargeFormValues = z.infer<typeof ChargeFormSchema>;
export type ConvenienceFormValues = z.infer<typeof ConvenienceFormSchema>;
export type CustomFeeLabelValues = z.infer<typeof CustomFeeLabelFormSchema>;
export type MinimumFormValues = z.infer<typeof MinimumFormSchema>;
export type MiscellaneousFormValues = z.infer<typeof MiscellaneousFormSchema>;
export type OperatingFormValues = z.infer<typeof OperatingFormSchema>;

export enum FeesSettingsMutation {
    Commission = "updateCommission",
    Charge = "updateCharge",
    Convenience = "updateConvenience",
    Labels = "updateLabels",
    Minimum = "updateMinimum",
    Misc = "updateMisc",
    Operating = "updateOperating"
}
