import { PlusCircledIcon, TrashIcon } from "@radix-ui/react-icons";
import { ColumnDef } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";

import { DataTable } from "src/@/components/ui/data-table";
import { CardDescription } from "src/@/components/ui/card";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger
} from "src/@/components/ui/tooltip";
import {
    formatMinutes,
    getDayMinutesFromLuxon,
    timeToLuxon
} from "#settings/settings-business-info/utils/formatTime";
import {
    getHoursWithNewDay,
    getHoursWithoutDay
} from "#settings/settings-business-info/utils/helpers";
import {
    DAY_END_MINUTES,
    NOON_HOUR_MINUTES,
    DAY_OPTIONS,
    DEFAULT_END_HOURS,
    DEFAULT_START_HOURS
} from "#settings/settings-business-info/utils/constants";

type HourRange = {
    start: number;
    end: number;
};

type HoursByWeekday = {
    weekday: number;
    hours: HourRange[];
};

type ValueType = {
    weekday: number;
    range: HourRange;
}[];

type TimeInputProps = {
    originalTime: string;
    onBlur: (
        rowWeekday: number,
        time: string,
        hour: HourRange,
        type: "start" | "end"
    ) => void;
    rowWeekday: number;
    hour: HourRange;
    type: "start" | "end";
    disabled: boolean;
};

const TimeInput = ({
    originalTime,
    onBlur,
    rowWeekday,
    hour,
    type,
    disabled
}: TimeInputProps) => {
    const [time, setTime] = useState(originalTime);

    const setNewTime = useCallback(() => {
        if (time !== originalTime) {
            onBlur(rowWeekday, time, hour, type); // modify hours function
        }
    }, [hour, onBlur, originalTime, rowWeekday, time, type]);
    return (
        <input
            className="h-9 cursor-pointer rounded-md border border-input bg-background text-small shadow-sm"
            type="time"
            value={time}
            onBlur={setNewTime}
            onChange={(e) => {
                setTime(e.target.value);
            }}
            disabled={disabled}
        />
    );
};

type DatatableProps = {
    value: ValueType;
    onChange: (value: ValueType) => void;
    disabled: boolean;
};

export function RegularHoursDatatable({
    value,
    onChange,
    disabled
}: DatatableProps) {
    const createNewHours = useCallback(
        (rowWeekday: number, start: number, end: number) => {
            const newHours = getHoursWithNewDay(value, rowWeekday, start, end);
            onChange(newHours);
        },
        [value, onChange]
    );

    const deleteHour = useCallback(
        (rowWeekday: number, hour: HourRange) => {
            const newHours = getHoursWithoutDay(value, rowWeekday, hour);
            onChange(newHours);
        },
        [value, onChange]
    );

    const modifyHours = useCallback(
        (
            rowWeekday: number,
            time: string,
            hour: HourRange,
            position: "start" | "end"
        ) => {
            const dateObject = timeToLuxon(time);
            if (!dateObject) return;
            const minutes = getDayMinutesFromLuxon(dateObject);
            const startMinutes = position === "start" ? minutes : hour.start;
            const endMinutes = position === "end" ? minutes : hour.end;
            const nextDay = rowWeekday === 7 ? 1 : rowWeekday + 1;

            // Delete existing hours first
            const filteredHours = getHoursWithoutDay(value, rowWeekday, hour);

            // If hour is past midnight, add two hours for both days
            if (startMinutes > endMinutes) {
                const preMidnightHours = getHoursWithNewDay(
                    filteredHours,
                    rowWeekday,
                    startMinutes,
                    DAY_END_MINUTES
                );
                const midnightHours = getHoursWithNewDay(
                    preMidnightHours,
                    nextDay,
                    0,
                    endMinutes
                );
                onChange(midnightHours);
            } else {
                // Normal hours
                const normalHours = getHoursWithNewDay(
                    filteredHours,
                    rowWeekday,
                    startMinutes,
                    endMinutes
                );
                onChange(normalHours);
            }
        },
        [value, onChange]
    );

    const data: HoursByWeekday[] = useMemo(() => {
        const grouped = DAY_OPTIONS.reduce<{ [key: number]: HourRange[] }>(
            (acc, day) => {
                acc[day.value] = [];
                return acc;
            },
            {}
        );

        // Populate the grouped object with hours
        value.forEach((hour) => {
            const {
                weekday,
                range: { start, end }
            } = hour;
            grouped[weekday].push({ start, end });
        });

        // Combine hours so that we allow for stores to have hours that run past midnight
        const midnightDays = new Set();
        for (let weekday = 1; weekday <= 7; weekday++) {
            if (!grouped || !(weekday in grouped)) {
                continue;
            }
            const hours = grouped[weekday];

            hours.forEach((hour, _) => {
                if (hour.start === 0) {
                    // find previous day
                    const prevWeekday = weekday === 1 ? 7 : weekday - 1;
                    const prevHours = grouped[prevWeekday];

                    // find an hour in previous day where it ended right before midnight
                    if (!prevHours || prevHours.length === 0) {
                        return;
                    }
                    const prevHourIndex = prevHours.findIndex(
                        (h) => h.end === DAY_END_MINUTES
                    );

                    if (prevHourIndex !== -1) {
                        // Combine the hours
                        const prevHour = prevHours[prevHourIndex];
                        if (hour.end >= NOON_HOUR_MINUTES) {
                            return; // if the hours of the next day pass on to the afternoon, we just pass it as two separate store hour days.
                        }
                        const combinedHour = {
                            start: prevHour.start,
                            end: DAY_END_MINUTES + 1 + hour.end
                        };

                        // update the previous weekday's hours
                        grouped[prevWeekday][prevHourIndex] = combinedHour;
                        midnightDays.add(weekday);
                    }
                }
            });
        }
        const filteredData: { [key: number]: HourRange[] } = {};

        for (const [weekday, hours] of Object.entries(grouped)) {
            filteredData[Number(weekday)] = midnightDays.has(Number(weekday))
                ? hours.filter((hour) => hour.start !== 0)
                : hours;
        }

        // convert the grouped object to an array grouped in terms of days
        const result = Object.entries(filteredData).map(([weekday, hours]) => ({
            weekday: parseInt(weekday, 10),
            hours
        }));
        return result;
    }, [value]);

    const columns: ColumnDef<HoursByWeekday>[] = useMemo(
        () => [
            {
                header: () => <div>Day</div>,
                id: "weekday",
                accessorKey: "weekday",
                cell: ({ row }) => (
                    <div>{DAY_OPTIONS[row.original.weekday - 1].label}</div>
                ),
                size: 500
            },
            {
                header: () => <div className="ml-4">Store Hours</div>,
                id: "hours",
                accessorKey: "hours",
                cell: ({ row }) => (
                    <div>
                        {row.original.hours && row.original.hours.length > 0 ? (
                            <div className="flex flex-col space-y-2">
                                {row.original.hours.map((hour, idx) => (
                                    <div
                                        key={idx}
                                        className="flex items-center justify-between"
                                    >
                                        <div>
                                            <TimeInput
                                                originalTime={formatMinutes(
                                                    hour.start
                                                )}
                                                onBlur={modifyHours}
                                                rowWeekday={
                                                    row.original.weekday
                                                }
                                                hour={hour}
                                                type={"start"}
                                                disabled={disabled}
                                            />
                                            <span className="mx-2">-</span>
                                            <TimeInput
                                                originalTime={formatMinutes(
                                                    hour.end
                                                )}
                                                onBlur={modifyHours}
                                                rowWeekday={
                                                    row.original.weekday
                                                }
                                                hour={hour}
                                                type={"end"}
                                                disabled={disabled}
                                            />
                                            {hour.end > DAY_END_MINUTES && (
                                                <span className="ml-2 text-xs text-red-500">
                                                    Next Day
                                                </span>
                                            )}
                                        </div>
                                        <div className="flex">
                                            <div>
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger
                                                            asChild
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                if (!disabled) {
                                                                    deleteHour(
                                                                        row
                                                                            .original
                                                                            .weekday,
                                                                        hour
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <TrashIcon className="mr-1 h-4 w-4" />
                                                        </TooltipTrigger>
                                                        <TooltipContent>
                                                            <p className="text-sm">
                                                                Delete Hours
                                                            </p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </div>
                                            <div className="ml-2">
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger
                                                            asChild
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                if (!disabled)
                                                                    createNewHours(
                                                                        row
                                                                            .original
                                                                            .weekday,
                                                                        DEFAULT_START_HOURS,
                                                                        DEFAULT_END_HOURS
                                                                    );
                                                            }}
                                                        >
                                                            <PlusCircledIcon className="mr-1 h-4 w-4" />
                                                        </TooltipTrigger>
                                                        <TooltipContent>
                                                            <p className="text-sm">
                                                                Add Hours
                                                            </p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="flex items-center justify-between">
                                <CardDescription className="ml-4">
                                    {data.some(
                                        (item) =>
                                            item.hours.some(
                                                (hour) =>
                                                    hour.end > DAY_END_MINUTES
                                            ) &&
                                            item.weekday ===
                                                (row.original.weekday === 1
                                                    ? 7
                                                    : row.original.weekday - 1)
                                    )
                                        ? "Hours Carry Over from Day Before"
                                        : "Closed"}
                                </CardDescription>
                                <div>
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger
                                                asChild
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    if (!disabled)
                                                        createNewHours(
                                                            row.original
                                                                .weekday,
                                                            DEFAULT_START_HOURS,
                                                            DEFAULT_END_HOURS
                                                        );
                                                }}
                                            >
                                                <PlusCircledIcon className="mr-1 h-4 w-4" />
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p className="text-sm">
                                                    Add Hours
                                                </p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            </div>
                        )}
                    </div>
                ),
                size: 500
            }
        ],
        [createNewHours, data, deleteHour, disabled, modifyHours]
    );

    return (
        <DataTable
            columns={columns}
            data={data}
            emptyText="No Hours Available."
            customPageSize={
                999999 // functionally infinite rows
            }
            className="border-0 border-y"
        />
    );
}
