import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";

import {
    CommandDialog,
    CommandItem,
    CommandList,
    CommandEmpty
} from "src/@/components/ui/command";
import { RouteDefinition, useNavMap } from "#navigation/navigations-routes";

export type SearchResultField = {
    name: string;
    icon?: React.ReactElement | null;
    path: string;
    matchedKeywords: {
        keyword?: string;
        matchedWord?: string;
    }[];
};

type SearchProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const GlobalSearchCommandPalette = ({
    isOpen,
    onClose
}: SearchProps) => {
    const [query, setQuery] = useState<string>("");
    const navMapping = useNavMap();
    const history = useHistory();

    const handleItemClick = (item: SearchResultField) => {
        onClose();
        history.push(item.path);
    };

    const initialList = useMemo(
        () =>
            navMapping
                .filter((item) => item.condition !== false)
                .map((item) => ({
                    name: item.name,
                    icon: item.icon ? (
                        <div className="mx-2 [&>svg]:fill-neutral-500">
                            {item.icon}
                        </div>
                    ) : null,
                    path: item.path,
                    matchedKeywords: []
                })),
        [navMapping]
    );

    const [searchResults, setSearchResults] =
        useState<SearchResultField[]>(initialList);

    const pushResults = (
        searchArray: RouteDefinition[],
        results: SearchResultField[],
        query: string,
        prevName?: string,
        parentIcon?: React.ReactElement
    ) => {
        searchArray.forEach((item) => {
            if (item.condition === false) return;
            const name = item.name;
            const icon = item.icon ? (
                <div className="mx-2 [&>svg]:fill-neutral-500">{item.icon}</div>
            ) : (
                parentIcon
            );
            if (
                item.searchKeywords &&
                item.searchKeywords.some((keyword) =>
                    keyword.toLowerCase().includes(query)
                )
            ) {
                const matchedKeywords = item.searchKeywords.filter((keyword) =>
                    keyword.toLowerCase().includes(query)
                );

                results.push({
                    name: prevName ? prevName + " -> " + name : name,
                    icon: icon,
                    path: item.path,
                    matchedKeywords: matchedKeywords.map((keyword) => ({
                        keyword,
                        matchedWord: query
                    }))
                });
            }

            if (item.children)
                pushResults(item.children, results, query, name, icon);
        });
    };

    const searchMenu = (query: string) => {
        setQuery(query);
        if (query.length === 0) {
            setSearchResults(initialList);
            return;
        }
        const lowercasedQuery = query.toLowerCase();
        const results: SearchResultField[] = [];
        pushResults(navMapping, results, lowercasedQuery);
        setSearchResults(results);
        return results;
    };

    return (
        <CommandDialog open={isOpen} onOpenChange={onClose}>
            <div className="relative">
                <input
                    value={query}
                    onChange={(e) => searchMenu(e.target.value)}
                    placeholder="Search for keywords..."
                    className="w-full py-3 pl-12 pr-4"
                />
                <MagnifyingGlassIcon className="absolute left-3 top-1/2 h-6 w-6 -translate-y-1/2 text-gray-400" />
            </div>
            <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                {searchResults.map((result, index) => (
                    <CommandItem
                        key={index}
                        onSelect={() => handleItemClick(result)}
                        className="mt-0.5 space-x-2"
                    >
                        {result?.icon}
                        <div className="flex flex-col">
                            <p className="overflow-hidden text-sm">
                                {result.name}
                            </p>
                            {query.length !== 0 && (
                                <p className="text-sm text-gray-500">
                                    <span className="font-bold">
                                        {result.matchedKeywords[0]?.keyword}
                                    </span>
                                </p>
                            )}
                        </div>
                    </CommandItem>
                ))}
            </CommandList>
        </CommandDialog>
    );
};
