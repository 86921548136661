import { useSelector } from "react-redux";
import { useConfig, useGate } from "statsig-react";

// eslint-disable-next-line no-restricted-imports
import config from "#config";
import { getActiveStore } from "src/redux/selectors";

export const useCateringEnabled = () => {
    const store = useSelector(getActiveStore);
    return store?.cateringEnabled;
};

export const useMultiMenusEnabled = () =>
    useGate(config.featureGates.multiMenus).value;

export const useReportsPayoutEnabled = () =>
    useGate(config.featureGates.rdbReportsHidePayout).value;

export const useMultiStoreSalesSummary = () =>
    useGate(config.featureGates.multistoreSalesSummaryReport).value;

export const useBlacklistedPhoneKioskRewardsFieldEnabled = () =>
    useGate(config.featureGates.blacklistedPhoneKioskRewards).value;

export const useDiscountRewardAddonsEnabled = () =>
    useGate(config.featureGates.discountRewardAddonsEnabled).value;

export const useReportsLaborTiles = () =>
    useGate(config.featureGates.reportsLaborTiles).value;

export const useSelfServePromoCodeEnabled = () =>
    useGate(config.featureGates.selfServePromoCodeEnabled).value;

export const useSnackTvSettingsEnabled = () =>
    useGate(config.featureGates.snackTvSettings).value;

export const useGiftCardReportsEnabled = () =>
    useGate(config.featureGates.reportsGiftCard).value;

export const useChannelVisibilitySettings = () =>
    useGate(config.featureGates.channelVisibilitySettings).value;

export const useSelfServeTaxRateEnabled = () =>
    useGate(config.featureGates.selfServeTaxRate).value;

export const useStripeHostedPayoutsOnboardingEnabled = () =>
    useGate(config.featureGates.stripeHostedPayoutsOnboarding).value;

export const useTaxFormsEnabled = () =>
    useConfig("2023_1099k_status").config.get<
        "not_applicable" | "no_w9" | "ineligible" | "eligible"
    >("status", "not_applicable") !== "not_applicable";

export const useInvoicesEnabled = () =>
    useGate(config.featureGates.invoices).value;

export const useTablesideEnabled = () =>
    useGate(config.featureGates.tableside).value;

export const usePrepStationsEnabled = () =>
    useGate(config.featureGates.prepStations).value;

export const useIsOfflineModeEligiblityEnabled = () =>
    useGate(config.featureGates.offlineModeEligibility).value;
