import { IStore } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";

const acceptableCategoryNames = ["Drinks", "Beverages", "Drink"];
const drinks = ["Water", "Coke", "Sprite", "Juice", "Soda"];

class MenuHasDrinksCheck implements IMenuAutomationCheck<IStore> {
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "MenuHasDrinksCheck";
    }

    get itemToCheck(): ItemType {
        return "store";
    }

    checkItems(): MenuIssue<IStore>[] {
        return [];
    }

    checkItem(): MenuIssue<IStore> | null {
        const categories = this.store.productCategories;
        if (!categories) return null;
        // Compare each product category name with the list of acceptable names.
        for (let i = 0; i < categories.length; i++) {
            const currentCategory = categories[i];
            for (let j = 0; j < acceptableCategoryNames.length; j++) {
                if (currentCategory.name.includes(acceptableCategoryNames[j])) {
                    return null;
                }
            }
        }

        // Compare each product name with the list of drink products.
        if (this.store.products) {
            for (let i = 0; i < this.store.products.length; i++) {
                const currentProduct = this.store.products[i];
                for (let j = 0; j < drinks.length; j++) {
                    if (currentProduct.name.includes(drinks[j])) {
                        return null;
                    }
                }
            }
        }

        // At this point in the code, there must be no drinks for this menu.
        return new MenuIssue<IStore>(
            "NoDrinks",
            "All menus should have drink products.",
            "warning",
            this.store
        );
    }
}

export { MenuHasDrinksCheck };
