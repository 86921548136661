import { zodResolver } from "@hookform/resolvers/zod";
import {
    IStore,
    SnackpassConvenienceFeeStrategy
} from "@snackpass/snackpass-types";
import { FormProvider, useForm } from "react-hook-form";

import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "src/@/components/ui/form";
import { SelectTrigger, SelectValue } from "src/@/components/ui/select";
import { Header } from "#settings/settings-fees/components/header";
import { OperatingFormSchema } from "#settings/settings-fees/utils/schema";
import { OperatingFormValues } from "#settings/settings-fees/utils/types";
import { StrategyOptions } from "#settings/settings-fees/components/strategy-options";
import { FeeSelect } from "#settings/settings-fees/components/fee-select";
import { FeeInput } from "#settings/settings-fees/components/fee-input";
import { operatingFormDefaults } from "#settings/settings-fees/forms/operating/operating-defaults";
import { useUpdateOperating } from "#settings/settings-fees/utils/hooks/useUpdateOperating";
import { OperatingFooter } from "#settings/settings-fees/forms/operating/operating-footer";

export const OperatingFeeForm = ({ store }: { store: IStore }) => {
    const form = useForm<OperatingFormValues>({
        defaultValues: operatingFormDefaults(store),
        resolver: zodResolver(OperatingFormSchema),
        mode: "all"
    });

    const updateStore = useUpdateOperating({
        storeId: store._id,
        reset: form.reset
    });

    return (
        <>
            <form
                onSubmit={form.handleSubmit(async (values) =>
                    updateStore(values)
                )}
                className="relative h-full w-full overflow-hidden"
            >
                <FormProvider {...form}>
                    <Header
                        title="Operating Fees (Customer pays Snackpass)"
                        titleSubtext="Fee Policies"
                        subtitle={`
                                When a purchase is made from a particular platform (app,
                                kiosk, or online ordering), we use the customer to
                                Snackpass convenience fee policy entered in the
                                corresponding row below. These fees go to SNACKPASS. If
                                you select the "Both" fee option then the consumer will
                                be charged BOTH the specified percent and the specified
                                flat. If you select the "Lesser Of" option the consumer
                                will be charged THE LESSER OF the specified percent and
                                the specified flat. To disable any fee, simply set the
                                fee type to "Both" and the fee values to 0.
                            `}
                    />
                    <div className="mb-2 flex w-full flex-col">
                        <FormLabel className="my-2">App</FormLabel>
                        <div className="mx-auto flex w-fit flex-col gap-2 sm:flex-row">
                            <FormField
                                control={form.control}
                                name="appStrategy"
                                render={({ field }) => (
                                    <FormItem className="mr-4 flex w-[200px]">
                                        <FeeSelect
                                            onChange={field.onChange}
                                            defaultValue={field.value}
                                        >
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select fee type" />
                                                </SelectTrigger>
                                            </FormControl>
                                            <StrategyOptions />
                                        </FeeSelect>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="appFlat"
                                render={({ field }) => (
                                    <FormItem className="flex w-[200px] flex-col">
                                        <FormControl>
                                            <FeeInput
                                                step={0.01}
                                                placeholder="0.0"
                                                min={0}
                                                max={10}
                                                value={field.value}
                                                prefix="$"
                                                onChange={(e) =>
                                                    field.onChange(
                                                        parseFloat(
                                                            e.target.value
                                                        )
                                                    )
                                                }
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <div className="my-auto flex w-full justify-center sm:mx-2 sm:w-[30px]">
                                {form.getValues().appStrategy ===
                                SnackpassConvenienceFeeStrategy.FlatAndPercent
                                    ? "and"
                                    : "or"}
                            </div>
                            <FormField
                                control={form.control}
                                name="appPercent"
                                render={({ field }) => (
                                    <FormItem className="flex w-[200px] flex-col">
                                        <FormControl>
                                            <FeeInput
                                                suffix="%"
                                                value={field.value}
                                                step={0.01}
                                                placeholder="0.0"
                                                min={0}
                                                max={10}
                                                onChange={(e) =>
                                                    field.onChange(
                                                        parseFloat(
                                                            e.target.value
                                                        )
                                                    )
                                                }
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                        <FormLabel className="my-2">Kiosk</FormLabel>
                        <div className="mx-auto flex w-fit flex-col gap-2 sm:flex-row">
                            <FormField
                                control={form.control}
                                name="kioskStrategy"
                                render={({ field }) => (
                                    <FormItem className="mr-4 flex w-[200px]">
                                        <FeeSelect
                                            onChange={field.onChange}
                                            defaultValue={field.value}
                                        >
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select fee type" />
                                                </SelectTrigger>
                                            </FormControl>
                                            <StrategyOptions />
                                        </FeeSelect>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="kioskFlat"
                                render={({ field }) => (
                                    <FormItem className="flex w-[200px] flex-col">
                                        <FormControl>
                                            <FeeInput
                                                value={field.value}
                                                prefix="$"
                                                step={0.01}
                                                placeholder="0.0"
                                                min={0}
                                                max={10}
                                                onChange={(e) =>
                                                    field.onChange(
                                                        parseFloat(
                                                            e.target.value
                                                        )
                                                    )
                                                }
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <div className="my-auto flex w-full justify-center sm:mx-2 sm:w-[30px]">
                                {form.getValues().kioskStrategy ===
                                SnackpassConvenienceFeeStrategy.FlatAndPercent
                                    ? "and"
                                    : "or"}
                            </div>
                            <FormField
                                control={form.control}
                                name="kioskPercent"
                                render={({ field }) => (
                                    <FormItem className="flex w-[200px] flex-col">
                                        <FormControl>
                                            <FeeInput
                                                suffix="%"
                                                step={0.01}
                                                placeholder="0.0"
                                                min={0}
                                                max={10}
                                                value={field.value}
                                                onChange={(e) =>
                                                    field.onChange(
                                                        parseFloat(
                                                            e.target.value
                                                        )
                                                    )
                                                }
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                        <FormLabel className="my-2">Online Ordering</FormLabel>
                        <div className="mx-auto flex w-fit flex-col gap-2 sm:flex-row">
                            <FormField
                                control={form.control}
                                name="onlineStrategy"
                                render={({ field }) => (
                                    <FormItem className="mr-4 flex w-[200px]">
                                        <FeeSelect
                                            onChange={field.onChange}
                                            defaultValue={field.value}
                                        >
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select fee type" />
                                                </SelectTrigger>
                                            </FormControl>
                                            <StrategyOptions />
                                        </FeeSelect>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="onlineFlat"
                                render={({ field }) => (
                                    <FormItem className="flex w-[200px] flex-col">
                                        <FormControl>
                                            <FeeInput
                                                prefix="$"
                                                step={0.01}
                                                placeholder="0.0"
                                                min={0}
                                                max={10}
                                                value={field.value}
                                                onChange={(e) =>
                                                    field.onChange(
                                                        parseFloat(
                                                            e.target.value
                                                        )
                                                    )
                                                }
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <div className="my-auto flex w-full justify-center sm:mx-2 sm:w-[30px]">
                                {form.getValues().onlineStrategy ===
                                SnackpassConvenienceFeeStrategy.FlatAndPercent
                                    ? "and"
                                    : "or"}
                            </div>
                            <FormField
                                control={form.control}
                                name="onlinePercent"
                                render={({ field }) => (
                                    <FormItem className="flex w-[200px] flex-col">
                                        <FormControl>
                                            <FeeInput
                                                suffix="%"
                                                step={0.01}
                                                placeholder="0.0"
                                                min={0}
                                                max={10}
                                                value={field.value}
                                                onChange={(e) =>
                                                    field.onChange(
                                                        parseFloat(
                                                            e.target.value
                                                        )
                                                    )
                                                }
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <OperatingFooter />
                </FormProvider>
            </form>
        </>
    );
};
