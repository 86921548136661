import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

import { Alert, AlertTitle } from "src/@/components/ui/alert";
import { useGetStoreStatsQuery } from "src/api/graphql/generated/types";
import { getActiveStore } from "src/redux/selectors";

function ReachableSMSBanner() {
    const activeStore = useSelector(getActiveStore);
    const { data, loading } = useGetStoreStatsQuery({
        variables: { storeId: activeStore!._id },
        skip: !activeStore
    });
    const reachableCustomersCount =
        data?.storeById?.stats?.totalReachableSMSCustomers;
    if (loading) {
        return <Skeleton className="rounded-full" width={500} height={48} />;
    }
    if (!reachableCustomersCount) {
        return null;
    }
    return (
        <Alert variant="blackBanner">
            <AlertTitle>
                Reach your {reachableCustomersCount.toLocaleString()} customers
                with Campaigns
            </AlertTitle>
        </Alert>
    );
}

export default ReachableSMSBanner;
