import { IStore } from "@snackpass/snackpass-types";

export enum TaxFormFields {
    taxRate = "taxRate",
    detail = "detail",
    dineIn = "dineIn",
    pickup = "pickup",
    delivery = "delivery"
}

export type TaxValue = {
    taxRate: IStore["taxRate"];
    detail: IStore["specifyTaxRateByFulfillment"];
    dineIn: IStore["taxRateDineIn"];
    pickup: IStore["taxRatePickup"];
    delivery: IStore["taxRateDelivery"];
};
