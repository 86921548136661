import { IProduct, IStore } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";

class ProductNameRequireAddonsCheck implements IMenuAutomationCheck<IProduct> {
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "ProductNameRequireAddonsCheck";
    }

    get itemToCheck(): ItemType {
        return "product";
    }

    checkItems() {
        return [];
    }

    checkItem(item: IProduct): MenuIssue<IProduct> | null {
        // if word has "or" and the addon groups length is < 1
        if (item.name.includes(" or ") && item.addonGroups.length < 1) {
            return new MenuIssue(
                "NeedAddonGroup",
                "Products that have a name with the word 'or' should include > 1 modifier groups.",
                "warning",
                item
            );
        }
        return null;
    }
}

export { ProductNameRequireAddonsCheck };
