import styled from "@emotion/styled";
import { captureException } from "@sentry/react";
import { SystemColors } from "@snackpass/design-system";
import React, { PropsWithChildren } from "react";

import colors from "#reusable/colors/colors.json";

type WidgetProps = {
    /**
     * Runs *only* after successful Cloudinary upload
     * @param result Result data from Cloudinary upload.
     */
    onUpload: (result: any) => void;
    /**
     * Runs on UploadButton click, after widget is opened.
     */
    onClick?: () => void;
    /**
     * Runs when widget closes, regardless of success
     */
    onClose?: () => void;
    cropping?: boolean;
    disabled?: boolean;
    croppingAspectRatio?: number;
};

type HookProps = {
    preset: string;
    folder: string;
    clientAllowedFormats?: string[]; //https://cloudinary.com/documentation/upload_widget_reference
    maxVideoFileSize?: number;
    maxImageFileSize?: number;
};

//TODO: Turn this into upload widget hook
export const useCloudinaryUploadWidget = ({
    preset,
    folder,
    clientAllowedFormats,
    maxVideoFileSize,
    maxImageFileSize
}: HookProps) => {
    const cloudName = "ddhetmqtt"; // replace with your own cloud name
    const uploadPreset = preset; // replace with your own upload preset

    const openWidget = (
        onUpload?: (result: any) => void,
        cropping?: boolean,
        onClose?: () => void,
        croppingAspectRatio?: number
    ) =>
        // @ts-ignore Cloudinary is defined globally at index.html
        cloudinary.openUploadWidget(
            {
                cloudName: cloudName,
                uploadPreset: uploadPreset,
                cropping: cropping ? cropping : false, //add a cropping step
                sources: ["local", "url"], // restrict the upload sources to URL and local files
                multiple: false, //restrict upload to a single file
                folder: folder, //upload files to the specified folder
                ...(clientAllowedFormats && {
                    clientAllowedFormats: clientAllowedFormats
                }), //restrict uploading to image files only
                ...(maxVideoFileSize && {
                    maxVideoFileSize: maxVideoFileSize
                }),
                ...(maxImageFileSize && {
                    maxImageFileSize: maxImageFileSize
                }),
                // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
                // theme: "purple", //change to a purple theme,
                showAdvancedOptions: false,
                croppingAspectRatio: croppingAspectRatio
            },
            // @ts-ignore
            (error, result) => {
                if (!error && result) {
                    if (result.event === "success") {
                        onUpload && onUpload(result.info);
                    }
                    if (result.event === "close") {
                        onClose && onClose();
                    }
                } else if (error) {
                    console.log(error);
                    captureException(Error("Cloudinary Upload Error"));
                }
            }
        );

    const UploadButton = ({
        onUpload,
        onClick,
        onClose,
        cropping,
        disabled = false,
        children,
        croppingAspectRatio
    }: PropsWithChildren<WidgetProps>) => (
        <Button
            type="button"
            className={"cloudinary-button"}
            disabled={disabled}
            onClick={
                !disabled
                    ? () => {
                          openWidget(
                              onUpload,
                              cropping,
                              onClose,
                              croppingAspectRatio
                          );
                          onClick && onClick();
                      }
                    : () => {}
            }
        >
            {children ? children : "Upload Video"}
        </Button>
    );

    return {
        UploadButton,
        /**
         * Sends info to onUpload on success.
         * Use `.secure_url` when saving image/video url
         */
        openWidget
    };
};

const Button = styled.button<{ disabled: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 6px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 8px;
    border: 1px solid ${colors["neutral-400"]};
    background-color: white;
    box-shadow: none;

    ${({ disabled }) =>
        disabled &&
        `background-color: ${SystemColors.v1.gray90}; color: ${SystemColors.v1.gray60}`}
    &:hover {
        box-shadow: none;
        border-color: ${colors["neutral-600"]};
    }
`;
