import styled from "@emotion/styled";
import { SystemColors } from "@snackpass/design-system";
import { useFormContext } from "react-hook-form";

import { FormFieldDescriptor } from "#promotion/components/shared/form-field-descriptor";
import { FormFieldName } from "#promotion/components/shared/form-field-name";
// @ts-ignore this package is installed
import { ReactComponent as PlusIcon } from "src/assets/icons/plus.svg";
import { FIELD_NAMES } from "#promotion/utils/types";
import { useCloudinaryUploadWidget } from "#reusable/upload/useCloudinaryUploadWidget";
import colors from "#reusable/colors/colors.json";

const MB20 = 20000000; // 20MB

export const PromoImage = () => {
    const methods = useFormContext();
    const { watch, setValue } = methods;
    const image_url = watch(FIELD_NAMES.IMAGE);

    const removeImage = () => setValue(FIELD_NAMES.IMAGE, "");

    const uploadPreset = "image-promo";

    const _onUpload = (info: any) => {
        setValue(FIELD_NAMES.IMAGE, info.secure_url);
    };

    const { UploadButton, openWidget } = useCloudinaryUploadWidget({
        preset: uploadPreset,
        folder: "promo-images",
        clientAllowedFormats: ["png", "jpg"],
        maxImageFileSize: MB20
    });

    return (
        <Container>
            <LabelContainer>
                <FormFieldName name="Promo Image" recommended />
                <FormFieldDescriptor descriptor="A photo of an item included in promo works well. Minimum size is 400x400 pixels" />
            </LabelContainer>
            <ImageContainer>
                <ImagePlaceholder
                    style={
                        image_url
                            ? {
                                  backgroundImage: `url(${image_url})`,
                                  backgroundPosition: "center",
                                  backgroundSize: "240px 240px",
                                  backgroundRepeat: "no-repeat"
                              }
                            : {}
                    }
                    onClick={() => openWidget(_onUpload, true)}
                >
                    {image_url ? null : (
                        <PlusIcon
                            fill={SystemColors.v1.gray50}
                            height={48}
                            width={48}
                        />
                    )}
                </ImagePlaceholder>
                {image_url ? (
                    <RemoveImageButton onClick={removeImage}>
                        Remove Image
                    </RemoveImageButton>
                ) : (
                    <UploadButton onUpload={_onUpload} cropping>
                        Add Image
                    </UploadButton>
                )}
            </ImageContainer>
        </Container>
    );
};

const Container = styled.div`
    padding-bottom: 24px;
    border-bottom: 1px solid ${colors["neutral-400"]};

    .cloudinary-button {
        border: none;
        color: ${SystemColors.v1.candy50};
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
    }
`;

const LabelContainer = styled.div`
    margin-bottom: 24px;
`;

const ImageContainer = styled.div`
    display: flex;
    width: 240px;
    flex-direction: column;
    align-items: center;
`;

const ImagePlaceholder = styled.div`
    width: 240px;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${SystemColors.v1.gray90};
    border-radius: 8px;
    &:hover {
        opacity: 0.8;
        background-color: ${SystemColors.v1.gray80};
        cursor: pointer;
    }
`;

const RemoveImageButton = styled.button`
    margin: 0;
    margin-top: 8px;
    padding: 8px;
    color: ${SystemColors.v1.candy50};
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    cursor: pointer;
    background-color: transparent;
    box-shadow: none;
`;
