import React, { CSSProperties } from "react";

import { Button, ButtonProps } from "./button";

type IconButtonProps = ButtonProps & {
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    placement: "left" | "right";
    style?: CSSProperties;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    children: React.ReactNode;
};

const iconDimensions = (size: IconButtonProps["size"]) => {
    switch (size) {
        // TODO: complete
        case "large":
        case "regular":
        case "small":
        case "micro":
            return "18px";
        default:
            break;
    }
};

/**
 * Make sure the svg you use as icon do not override the fill property in its definition or else
 * the color of the icon in this component will not work properly
 */
export const IconButton: React.FC<IconButtonProps> = ({
    type,
    size = "regular",
    disabled = false,
    icon,
    placement,
    children,
    style,
    onClick
}) => {
    const Icon = icon;

    const iconColor = disabled
        ? "#A0ADBA"
        : type === "primary"
          ? "#FFF"
          : "#282D32";

    return (
        <Button
            type={type}
            size={size}
            disabled={disabled}
            onClick={onClick}
            style={style}
        >
            {placement === "left" ? (
                <Icon
                    style={{
                        marginRight: "8px",
                        color: iconColor,
                        width: iconDimensions(size),
                        height: iconDimensions(size)
                    }}
                    fill="currentColor"
                />
            ) : null}
            {children}
            {placement === "right" ? (
                <Icon
                    style={{
                        marginLeft: "8px",
                        color: iconColor,
                        width: iconDimensions(size),
                        height: iconDimensions(size)
                    }}
                    fill="currentColor"
                />
            ) : null}
        </Button>
    );
};
