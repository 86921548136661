import React, { useEffect, useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useCollapse from "react-collapsed";
import { Drawer as AntdDrawer } from "antd";
import { ScreenState } from "@snackpass/snackpass-types";
import styled from "styled-components";
import { SystemColors } from "@snackpass/design-system";
import { isMobile } from "react-device-detect";

import useWindowDimensions from "#hooks/use-window-dimensions";
import { colors as deprecatedColors } from "#utils/colors";
import { MonthOptions } from "#time-and-attendance/types";
import { ReactComponent as CalendarIcon } from "src/assets/icons/calendar.svg";
import { ReactComponent as XIcon } from "src/assets/icons/close-x.svg";
import { ButtonContainer, Text } from "#pickers/ui";
import colors from "#reusable/colors/colors.json";

type Props = {
    months: MonthOptions[];
    setSelectedMonth: (value: MonthOptions) => void;
    selectedMonth: MonthOptions;
};

export const MonthPicker = (props: Props) => {
    const { months, selectedMonth, setSelectedMonth } = props;
    const { width, height } = useWindowDimensions();
    const [isExpanded, setExpanded] = useState(false);
    const [monthPairs, setMonthPairs] = useState<MonthOptions[][]>([]);
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

    useEffect(() => {
        const pairs: MonthOptions[][] = [];
        for (let i = 0; i <= months.length - 2; i += 2) {
            const pair = [months[i], months[i + 1]];
            pairs.push(pair);
        }
        setMonthPairs(pairs);
    }, [months]);

    if (!months || months.length < 1) return <></>;

    return (
        <div css={Styles}>
            <ButtonContainer
                {...getToggleProps({
                    onClick: () => {
                        setExpanded((prevExpanded) => !prevExpanded);
                    }
                })}
            >
                <CalendarIcon css={iconCSS} />
                <Text>{`${selectedMonth.month} ${selectedMonth.year}`}</Text>
            </ButtonContainer>
            {!isMobile && (
                <div {...getCollapseProps()} className="panel">
                    <Panel
                        monthPairs={monthPairs}
                        selectedMonth={selectedMonth}
                        setSelectedMonth={setSelectedMonth}
                        setExpanded={setExpanded}
                    />
                </div>
            )}
            <AntdDrawer
                width={width}
                height={height - 50}
                placement="bottom"
                onClose={() => setExpanded(false)}
                open={isExpanded && isMobile}
                closable={false}
                destroyOnClose={true}
                forceRender={true}
            >
                <MobileClose onClick={() => setExpanded(false)}>
                    <XIcon css={iconCSS} />
                </MobileClose>
                <MobileContents>
                    <Panel
                        monthPairs={monthPairs}
                        selectedMonth={selectedMonth}
                        setSelectedMonth={setSelectedMonth}
                        setExpanded={setExpanded}
                    />
                </MobileContents>
            </AntdDrawer>
        </div>
    );
};

interface PanelProps {
    selectedMonth: MonthOptions;
    monthPairs: MonthOptions[][];
    setSelectedMonth: (value: MonthOptions) => void;
    setExpanded: (value: boolean) => void;
}

const Panel = ({
    selectedMonth,
    monthPairs,
    setSelectedMonth,
    setExpanded
}: PanelProps) => (
    <>
        <PanelTitle>
            Show Labor Cost for <br />{" "}
            {`${selectedMonth.month} ${selectedMonth.year}`}
        </PanelTitle>
        {monthPairs.map((pair, index: number) => (
            <MonthRow key={index}>
                {pair.map(
                    ({ month, year, date }: MonthOptions, index: number) => (
                        <MonthButton
                            key={index}
                            onClick={() => {
                                setSelectedMonth({ month, year, date });
                                setExpanded(false);
                            }}
                            selected={month === selectedMonth.month}
                        >
                            <CalendarIcon css={iconCSS} />
                            <MonthYear>
                                <MonthText>{month}</MonthText>
                                <YearText>{year}</YearText>
                            </MonthYear>
                        </MonthButton>
                    )
                )}
            </MonthRow>
        ))}
    </>
);

const MobileContents = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const MobileClose = styled.button`
    height: 48px;
    width: 48px;
    border-radius: 24px;
    background: none;
    border: 1px solid ${colors["neutral-400"]};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const MonthRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const MonthButton = styled.button<{ selected: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 162px;
    height: 80px;
    border: ${(props) =>
        props.selected
            ? `2px solid ${SystemColors.v1.candy50}`
            : `1px solid ${colors["neutral-400"]}`};
    border-radius: 16px;
    margin: 7px;
    font-family: "Inter";
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    background: none;
    box-shadow: none;

    @media ${ScreenState.MOBILE}, ${ScreenState.TABLET} {
        font-size: 18px;
        line-height: 24px;
        margin: 10px;
    }
`;

const MonthYear = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 5px;
`;

const MonthText = styled.div`
    font-weight: 600;
`;

const YearText = styled.div`
    font-weight: 400;
`;

const PanelTitle = styled.div`
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 10px;
`;

const iconCSS = css`
    left: 16px;
    top: 10px;
    flex-grow: 0;
    margin: 0px 8px;
    width: 16px;
    height: 16px;

    @media ${ScreenState.MOBILE} {
        width: 24px;
        height: 24px;
    }
`;

const Styles = css`
    .button-wrapper {
        border: 1px solid ${colors["neutral-400"]};
        border-radius: 56px;
        order: 0;
        background: none;
        box-shadow: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 7px 15px 5px 7px;

        @media ${ScreenState.MOBILE}, ${ScreenState.TABLET} {
            width: 230px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .panel {
        position: absolute;
        background: ${deprecatedColors.white};
        border: 1px solid ${deprecatedColors.gray80};
        border-radius: 21px;
        margin-top: 10px;
        padding: 1em;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
        z-index: 1000;

        @media ${ScreenState.MOBILE}, ${ScreenState.TABLET} {
            width: 230px;
        }
    }
`;
