import { Table } from "antd";
import React, { useContext, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import DownloadButton from "#reports/sales-summary/shared-components/DownloadButton";
import { ReportsContext } from "#app/reports-context-provider";
import {
    formatNumber,
    formatRange,
    toDollarFormatted
} from "#reports/sales-summary/lib";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";

interface TableRow {
    [key: string]: string;
}

const SalesReportTable = () => {
    const { reportsState } = useContext(ReportsContext);
    const { dateRanges, locationReportsData, filter, stores, granularity } =
        reportsState;

    const data = locationReportsData?.salesReportData || [];

    const columns = [
        {
            title: "Location",
            dataIndex: "location",
            key: "location",
            width: "10%"
        },
        {
            title: "Net Sales",
            dataIndex: "netSales",
            key: "netSales"
        },
        {
            title: "Orders",
            dataIndex: "orders",
            key: "orders",
            className: "whitespace-nowrap"
        },
        {
            title: "Average Order",
            dataIndex: "averageOrder",
            key: "averageOrder",
            className: "pr-[3rem]"
        },
        {
            title: "Items",
            dataIndex: "items",
            key: "items"
        }
    ];

    const rows: TableRow[] = useMemo(() => {
        if (!data) return [];
        const storeRows = Object.entries(data || {})
            .filter(
                ([id, _]) =>
                    filter.storeIds.includes(id) || filter.storeIds.length == 0
            )
            .sort(([_id1, a], [_id2, b]) => b.netSales - a.netSales)
            .map(([id, storeData], i) => ({
                location: stores.find((e) => e._id == id)?.name || "",
                netSales: toDollarFormatted(storeData.netSales),
                orders: formatNumber(storeData.orders),
                averageOrder: toDollarFormatted(storeData.avgOrder),
                items: formatNumber(storeData.items),
                key: `${id}-${i}`
            }));

        const totalRow = {
            location: "Total",
            netSales: toDollarFormatted(
                Object.values(data).reduce(
                    (acc, curr) => acc + curr.netSales,
                    0
                )
            ),
            orders: formatNumber(
                Object.values(data).reduce((acc, curr) => acc + curr.orders, 0)
            ),
            averageOrder: toDollarFormatted(
                Object.values(data).reduce(
                    (acc, curr) => acc + curr.netSales,
                    0
                ) /
                    Object.values(data).reduce(
                        (acc, curr) => acc + curr.orders,
                        0
                    )
            ),
            items: formatNumber(
                Object.values(data).reduce((acc, curr) => acc + curr.items, 0)
            ),
            key: "total"
        };

        return [...storeRows, totalRow];
    }, [data]);

    const getRowClassName = (record: TableRow) => record.className;

    return (
        <div className="mb-20 mt-10 pt-10">
            <div className="mb-4 flex items-center justify-between">
                <h4 className="text-large">Sales Breakdown by Location</h4>
                <DownloadButton
                    rows={rows}
                    columns={columns}
                    filename={`Sales Report By Location Breakdown ${formatRange(
                        dateRanges[0],
                        granularity
                    )}`}
                />
            </div>
            <div>
                {locationReportsData?.salesReportDataLoading ||
                (!locationReportsData?.salesReportData &&
                    !locationReportsData?.salesReportDataFailed) ? (
                    <Skeleton className="h-96" />
                ) : !locationReportsData?.salesReportData ? (
                    <ErrorChart className="h-96 rounded-md" />
                ) : (
                    <Table
                        className="whitespace-normal break-keep md:whitespace-nowrap "
                        columns={columns}
                        dataSource={rows}
                        pagination={false}
                        rowClassName={getRowClassName}
                        scroll={{ x: true }}
                        sticky
                        onRow={(_, rowIndex) => ({
                            className:
                                rowIndex === rows.length - 1 ? "font-bold" : ""
                        })}
                    />
                )}
            </div>
        </div>
    );
};

export default SalesReportTable;
