import { TooltipProvider } from "@radix-ui/react-tooltip";
import clsx from "clsx";

import {
    HybridTooltip,
    HybridTooltipContent,
    HybridTooltipTrigger
} from "src/@/components/ui/HybridTooltip";
import { ReactComponent as InfoIcon } from "src/assets/icons/Info.svg";

interface Props {
    body: string | JSX.Element;
    className?: string;
    contentClassName?: string;
}
const ReportsTooltip = ({ body, className, contentClassName }: Props) => (
    <TooltipProvider delayDuration={100}>
        <HybridTooltip>
            <HybridTooltipTrigger asChild>
                <InfoIcon
                    className={clsx(
                        "mx-1 h-3 w-3 cursor-pointer fill-success-light",
                        className
                    )}
                />
            </HybridTooltipTrigger>

            <HybridTooltipContent
                className={clsx(
                    "z-50 w-96 whitespace-normal rounded-md bg-neutral-900 px-3 py-2 text-center font-normal text-neutral-50",
                    contentClassName
                )}
                sideOffset={5}
            >
                {body}
            </HybridTooltipContent>
        </HybridTooltip>
    </TooltipProvider>
);

export default ReportsTooltip;
