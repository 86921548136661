import { ErrorMessage } from "@hookform/error-message";
import _ from "lodash";

import {
    FormControl,
    FormDescription,
    FormField,
    FormLabel
} from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";
import { ValidatorMessage } from "#reusable/validators/validator-message";

import {
    useInternalSettingsForm,
    InternalSettingsFormKeysOfType
} from "./types";
import { InternalSettingsFormItem } from "./form-item";

type InternalSettingsTextInputPropsEmptyString = {
    onChange?: (value: string) => void;
    emptyValue: "string";
};

type InternalSettingsTextInputPropsEmptyStringNull = {
    onChange?: (value: string | null) => void;
    emptyValue: "null";
};

type InternalSettingsTextInputPropsEmptyStringUndefined = {
    onChange?: (value: string | undefined) => void;
    emptyValue: "undefined";
};

type InternalSettingsTextInputProps = {
    name: InternalSettingsFormKeysOfType<string>;
    label: string;
    description?: string;
} & (
    | InternalSettingsTextInputPropsEmptyString
    | InternalSettingsTextInputPropsEmptyStringNull
    | InternalSettingsTextInputPropsEmptyStringUndefined
);

export function InternalSettingsTextInput({
    name,
    label,
    description,
    onChange,
    emptyValue
}: InternalSettingsTextInputProps) {
    const form = useInternalSettingsForm();
    const errorObject = _.get(form.formState.errors, name);
    return (
        <FormField
            control={form.control}
            name={name}
            render={({ field }) => (
                <>
                    <InternalSettingsFormItem>
                        <FormLabel className="text-body font-semibold">
                            {label}
                        </FormLabel>
                        <FormControl>
                            <Input
                                aria-invalid={(errorObject && true) || false}
                                disabled={field.disabled}
                                value={field.value ?? ""}
                                onBlur={field.onBlur}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (emptyValue === "null" && value === "") {
                                        field.onChange({
                                            target: { value: null }
                                        });
                                        onChange?.(null);
                                    } else if (
                                        emptyValue === "undefined" &&
                                        value === ""
                                    ) {
                                        field.onChange({
                                            target: { value: undefined }
                                        });
                                        onChange?.(undefined);
                                    } else {
                                        field.onChange({
                                            target: { value }
                                        });
                                        onChange?.(value);
                                    }
                                }}
                            />
                        </FormControl>
                        {description && (
                            <FormDescription className="text-[#585B5F]">
                                {description}
                            </FormDescription>
                        )}
                        <ErrorMessage
                            errors={form.formState.errors}
                            name={name}
                            render={({ message }) => (
                                <ValidatorMessage
                                    paddingVertical="0"
                                    type="error"
                                    description={message}
                                />
                            )}
                        />
                    </InternalSettingsFormItem>
                </>
            )}
        />
    );
}
