import { useMemo } from "react";

import { useAppSelector } from "src/redux/hooks";
import { getLegacyProducts } from "src/redux/selectors";

/**
 * Provides essential product data for a given product ID.
 */
export function useProductEssentialsByID(id: string) {
    const legacyProducts = useAppSelector(getLegacyProducts);

    const product = useMemo(
        () => legacyProducts.find((product) => product._id === id),
        [id, legacyProducts]
    );

    return useMemo(
        () =>
            product
                ? {
                      id: product._id,
                      name: product.name,
                      categoryId: product.categoryId
                  }
                : null,
        [product]
    );
}
