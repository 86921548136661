import { useEffect, useState } from "react";

import constants from "#core/constants";

type WindowDimensions = { width: number; height: number };

type ScreenStateResponse = {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
};

export type UseWindowDimensionsResponse = WindowDimensions &
    ScreenStateResponse;

const getWindowDimensions = (): WindowDimensions => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
};

const getScreenState = (): ScreenStateResponse => {
    const width = window.innerWidth;
    const isMobile = width <= constants.MOBILE_MAX_WIDTH;
    const isTablet =
        width >= constants.TABLET_MIN_WIDTH &&
        width <= constants.TABLET_MAX_WIDTH;
    const isDesktop = !isMobile && !isTablet;
    return {
        isMobile,
        isTablet,
        isDesktop
    };
};

const useWindowDimensions = (): UseWindowDimensionsResponse => {
    const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(
        getWindowDimensions()
    );

    const screenState = getScreenState();
    const [isMobile, setIsMobile] = useState<boolean>(screenState.isMobile);
    const [isTablet, setIsTablet] = useState<boolean>(screenState.isTablet);
    const [isDesktop, setIsDesktop] = useState<boolean>(screenState.isDesktop);

    useEffect(() => {
        const handleResize = () => {
            const screenState = getScreenState();
            setIsMobile(screenState.isMobile);
            setIsTablet(screenState.isTablet);
            setIsDesktop(screenState.isDesktop);

            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return { ...windowDimensions, isMobile, isTablet, isDesktop };
};

export default useWindowDimensions;
