import { Table } from "antd";
import React, { useContext, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { getActiveStore } from "@snackpass/accounting";

import { ReportsContext } from "#app/reports-context-provider";
import {
    formatNumber,
    formatRange,
    toDollarFormatted
} from "#reports/sales-summary/lib";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import DownloadButton from "#reports/sales-summary/shared-components/DownloadButton";
import { GranularityType } from "#reports/sales-summary/types";
import { ReactComponent as SearchIcon } from "src/assets/icons/search.svg";
import { ShowLegendSwitch } from "#reports/location-sales/components/ShowLegendSwitch";
import {
    aggregateItemsByLocationByCategory,
    doesQueryMatch
} from "#reports/location-menu-categories/lib";
import { LocationMenuGenericRow } from "#reports/location-menu-categories/types";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";
import { MENU_SALES_TOOLTIP_COPY } from "#reports/menu-item-insights/lib";

type TableRow = {
    name: string;
    orders: string;
    sales: string;
    index?: number;
};

const STORE_NAME_ROW_CLASS_NAME =
    "bg-neutral-300 font-bold [&>.ant-table-cell]:py-1 [&>.ant-table-cell]:bg-neutral-300 [&>.ant-table-cell]:before:invisible";

const CategoriesByLocationTable = () => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState } = useContext(ReportsContext);
    const { locationMenuInsightsData, dateRanges, stores } = reportsState;

    const [searchQuery, setSearchQuery] = useState("");
    const [shouldUseExactMatch, setShouldUseExactMatch] = useState(true);

    const data: { [storeId: string]: LocationMenuGenericRow[] } | undefined =
        useMemo(() => {
            if (!locationMenuInsightsData?.itemInsightsData) return undefined;

            return aggregateItemsByLocationByCategory(
                locationMenuInsightsData?.itemInsightsData
            );
        }, [locationMenuInsightsData]);

    const columnsForExport = [
        {
            title: "",
            dataIndex: "index"
        },
        {
            title: "Category",
            dataIndex: "name"
        },
        {
            title: "Orders",
            dataIndex: "orders"
        },
        {
            title: "Net Sales",
            dataIndex: "sales"
        }
    ];

    const columns = [
        {
            title: "",
            dataIndex: "index",
            key: "index",
            width: "5%",
            render: (text: string) => (
                <div className="whitespace-nowrap">{text}</div>
            )
        },
        {
            title: "Category",
            dataIndex: "name",
            key: "name",
            width: "65%"
        },
        {
            title: "Orders",
            dataIndex: "orders",
            key: "orders",
            width: "15%"
        },
        {
            title: (
                <>
                    Net Sales
                    <ReportsTooltip
                        body={MENU_SALES_TOOLTIP_COPY}
                        className="mx-3"
                    />
                </>
            ),
            dataIndex: "sales",
            key: "sales",
            width: "15%"
        }
    ];

    // provide a columns array that creates a header row for the store name
    const getColumnsForSubTable = (storeName: string) =>
        columns.map(({ dataIndex, key, width }) => ({
            title: dataIndex == "name" ? storeName : "",
            dataIndex,
            key,
            width
        }));

    const rowsForExport = useMemo(() => {
        if (!data) return [];
        return Object.entries(data).reduce(
            (acc, [storeId, categories]) =>
                acc.concat([
                    {
                        name: stores.find((e) => e._id == storeId)?.name || "",
                        sales: "",
                        orders: ""
                    },
                    ...categories
                        .sort((a, b) => b.netSales - a.netSales)
                        .map((e, i) => ({
                            name: e.name,
                            index: i + 1,
                            sales: toDollarFormatted(e.netSales),
                            orders: formatNumber(e.orders)
                        }))
                ]),
            [] as TableRow[]
        );
    }, [data]);

    const tables = useMemo(() => {
        if (!data) return [];
        return [
            // empty table just to display header
            <Table
                className="min-w-[700px] whitespace-normal break-keep md:whitespace-nowrap"
                columns={columns}
                scroll={{ y: 0 }}
                dataSource={[]}
                pagination={false}
                key={-1}
            />,

            ...Object.entries(data).map(([storeId, categories], i) => {
                const storeName =
                    stores.find((e) => e._id == storeId)?.name || "";
                const rows = categories
                    .sort((a, b) => b.netSales - a.netSales)
                    .filter((e) =>
                        doesQueryMatch(
                            e.name,
                            searchQuery,
                            !shouldUseExactMatch
                        )
                    )
                    .map((e, i) => ({
                        name: e.name,
                        index: i + 1,
                        sales: toDollarFormatted(e.netSales),
                        orders: formatNumber(e.orders)
                    }));

                return (
                    <div key={i}>
                        <Table
                            className="min-w-[700px] whitespace-normal break-keep md:whitespace-nowrap"
                            onHeaderRow={() => ({
                                className: STORE_NAME_ROW_CLASS_NAME
                            })}
                            columns={getColumnsForSubTable(storeName)}
                            scroll={{ y: rows.length ? undefined : 0 }}
                            dataSource={rows}
                            pagination={{
                                defaultPageSize: 5,
                                pageSize: 5,
                                showSizeChanger: false,
                                position: ["bottomLeft"]
                            }}
                        />
                        {rows.length == 0 && (
                            <div className="flex flex-col items-center justify-center ">
                                <p className="my-6 text-body text-neutral-500">
                                    No Results
                                </p>
                            </div>
                        )}
                    </div>
                );
            })
        ];
    }, [data, searchQuery, shouldUseExactMatch]);

    return (
        <div className="mb-6 mt-10 pt-10">
            <div className="mb-4 flex items-center justify-between">
                <h4 className="text-large">Categories by Location</h4>
                <DownloadButton
                    rows={rowsForExport}
                    columns={columnsForExport}
                    filename={`${
                        activeStore?.name
                    } Categories by Location ${formatRange(
                        dateRanges[0],
                        GranularityType.DAILY
                    )}`}
                />
            </div>
            <div className="mb-6 flex items-center">
                <div className="flex h-10 w-96 items-center rounded-lg border border-neutral-400 bg-white">
                    <div className="pl-4">
                        <SearchIcon className="h-4 w-4 fill-neutral-400" />
                    </div>
                    <input
                        type="text"
                        className="h-full w-full rounded-lg border-none px-2 outline-none focus:shadow-none"
                        placeholder="Search Categories"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className="pl-6">
                    <ShowLegendSwitch
                        value={shouldUseExactMatch}
                        onChange={setShouldUseExactMatch}
                        label={"Show Exact Matches"}
                    />
                </div>
            </div>
            <div className="overflow-x-scroll">
                {!locationMenuInsightsData?.itemInsightsDataLoading &&
                (locationMenuInsightsData?.itemInsightsData ||
                    locationMenuInsightsData?.itemInsightsDataFailed) ? (
                    locationMenuInsightsData?.itemInsightsData ? (
                        tables
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

export default CategoriesByLocationTable;
