import React, { useContext } from "react";
import produce from "immer";

import SettingsSection from "#devices/components/EditDrawer/SettingsSection";
import Toggle from "#devices/components/FormControls/Toggle";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import {
    ChannelOption,
    ChannelType
} from "#devices/utils/deviceTypes/SnackTVDevice";

export const HideSoldOut = () => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const deviceOptions = (updatedDevice?.deviceDetails?.channelOptions ??
        snackTvDevice?.deviceDetails?.channelOptions ??
        []) as ChannelOption[];

    const channel =
        (updatedDevice?.deviceDetails?.channel as ChannelType) ??
        snackTvDevice?.deviceDetails?.channel;

    const channelOptions = deviceOptions.find(
        (o: ChannelOption) => o.channel === channel
    );

    const checked = channelOptions?.options?.hideSoldOut;

    return (
        <SettingsSection
            label="Hide Sold Out Products"
            description="Hide all sold out products from channel"
        >
            <Toggle
                id="hideSoldOutToggle"
                checked={checked}
                onChange={(e) => {
                    const updatedOptions = produce(deviceOptions, (draft) => {
                        if (draft) {
                            const channelOption = draft.find(
                                (o) => o.channel === channel
                            );
                            if (!channelOption) {
                                draft.push({
                                    channel,
                                    options: {
                                        hideSoldOut: e.target.checked
                                    }
                                });
                            } else {
                                channelOption.options.hideSoldOut =
                                    e.target.checked;
                            }
                        }
                    });
                    updateDeviceField("channelOptions", updatedOptions);
                }}
            />
        </SettingsSection>
    );
};
