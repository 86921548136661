import React from "react";

import FilterHeader, {
    FilterType
} from "#reports/sales-summary/shared-components/FilterHeader";
import { ReportType } from "#reports/sales-summary/types";
import { useTrackEvent } from "#reports/mixpanel-tracking/hooks";
import PurchaseStatTable from "#reports/gift-card-purchases/components/PurchaseStatTable";
import PurchaseStatTiles from "#reports/gift-card-purchases/components/PurchaseStatTiles";

const GiftCardPurchaseReport = () => {
    const trackEvent = useTrackEvent();

    return (
        <div>
            <FilterHeader
                hideFilters={[
                    FilterType.CHANNEL,
                    FilterType.GRANULARITY,
                    FilterType.FULFILLMENT,
                    FilterType.PLATFORM
                ]}
                reportType={ReportType.GIFT_CARD_PURCHASE_REPORT}
                showAllTimeRange
                showLocationFilter
            />
            <div className="px-6 md:px-24">
                <PurchaseStatTiles />
                <PurchaseStatTable />
            </div>
        </div>
    );
};

export default GiftCardPurchaseReport;
