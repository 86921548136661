import { colors } from "#utils/colors";

import { MenuIssue } from "./interfaces";

class DataAggregator {
    public numberOfChecks: number;
    public numberOfIssues: number;
    public numberOfCriticalIssues: number;
    public numberOfWarningIssues: number;

    constructor() {
        this.numberOfChecks = 0;
        this.numberOfIssues = 0;
        this.numberOfCriticalIssues = 0;
        this.numberOfWarningIssues = 0;
    }

    get healthColor(): string {
        const health = this.health;
        if (health < 75) return colors.red;
        if (75 < health && health < 90) return colors.apricot;
        return colors.green;
    }

    get health(): number {
        return parseFloat(
            (
                ((this.numberOfChecks - this.numberOfIssues) /
                    this.numberOfChecks) *
                100
            ).toFixed(0)
        );
    }

    addCheck() {
        this.numberOfChecks += 1;
    }

    addIssue(issue: MenuIssue<any> | null) {
        if (!issue) return;
        this.numberOfIssues += 1;
        if (issue.severity === "critical") {
            this.numberOfCriticalIssues += 1;
        } else if (issue.severity === "warning") {
            this.numberOfWarningIssues += 1;
        }
    }
}

export { DataAggregator };
