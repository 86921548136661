import { IUser } from "@snackpass/snackpass-types";
import { Spinner } from "react-activity";
import { useDispatch } from "react-redux";
import { toast } from "sonner";

import { Button } from "src/@/components/ui/button";
import {
    Card,
    CardContent,
    CardFooter,
    CardHeader
} from "src/@/components/ui/card";
import { UserInvite } from "src/core/types";
import { Routes } from "#navigation/routes";
import { clearUser } from "src/redux/slices";
import { firebaseAuth } from "#app/firebase";
import api from "src/api/rest";
import { logAndSendError } from "src/utils/errors";

type Props = {
    invite?: UserInvite;
    fetching: boolean;
    handleAccept: () => void;
    user: IUser | null;
};

export const AcceptInviteLanding = ({
    invite,
    fetching,
    handleAccept,
    user
}: Props) => {
    const dispatch = useDispatch();

    const logout = () => {
        void firebaseAuth.signOut();
        dispatch(clearUser());
    };

    const forwardToMyAccount = () => {
        window.location.href = Routes.SettingsAccount;
    };

    const logoutAndRefreshPage = () => {
        logout();
        window.location.reload();
    };

    const upgradeMeThenForward = async () => {
        if (!invite) return;
        try {
            await api.adminUserInvite.upgradeAccountToAcceptInvite(
                invite.inviteNonce,
                invite.email,
                invite.storeId
            );
            forwardToMyAccount();
        } catch (err) {
            toast.error("Failed to promote account to admin");
            logAndSendError(err);
        }
    };

    // We have 3 cases:
    // 1. no user exists, so just let them fill out their details and accept
    // 2. user exists on server and they are logged in as that user so just forward them to My Account to accept
    // 3. users exists on server but they are not logged in or logged in as a diff user so log them out and send them back here
    const serverUserExists = invite?.existingUser || false;
    const localUserLoggedIn = !!user;
    const existingUserIsSame = serverUserExists
        ? user?.email === invite?.email
        : false;

    let cardContent = (
        <AcceptInviteCardContent
            storeName={invite?.storeName ?? ""}
            email={invite?.email ?? ""}
        />
    );
    let buttonText = "Accept";
    let buttonAction = handleAccept;

    if (serverUserExists || localUserLoggedIn) {
        if (localUserLoggedIn) {
            if (existingUserIsSame) {
                buttonText = "Accept Invite On My Account Page";
                buttonAction = forwardToMyAccount;
            } else {
                cardContent = (
                    <WrongEmail
                        email={user?.email}
                        currentEmail={invite?.email}
                    />
                );
                buttonText = "Sign In With Different Email";
                buttonAction = logoutAndRefreshPage;
            }
        } else {
            cardContent = <PleaseSignIn email={invite?.email} />;
            buttonText = "Log In To Accept Invite";
            buttonAction = invite?.existingUserIsAdmin
                ? forwardToMyAccount
                : upgradeMeThenForward;
        }
    }

    return (
        <div className="flex h-full w-full items-center justify-center">
            <Card className="m-4 md:w-2/5">
                <CardHeader />
                <CardContent>
                    {fetching ? (
                        <div className="flex flex-col items-center justify-center text-2xl font-semibold">
                            Looking up invite
                            <Spinner size={24} />
                        </div>
                    ) : invite ? (
                        cardContent
                    ) : (
                        <div className="flex flex-col items-center justify-center">
                            <div className="mb-4 text-2xl font-semibold">
                                Sorry, that invite was not found
                            </div>
                        </div>
                    )}
                </CardContent>
                {invite && (
                    <CardFooter className="flex flex-col items-center justify-center border border-x-0 border-b-0 p-4">
                        <Button className="w-full" onClick={buttonAction}>
                            {buttonText}
                        </Button>
                    </CardFooter>
                )}
            </Card>
        </div>
    );
};

type InviteCardContentProps = {
    storeName?: string;
    email?: string;
    currentEmail?: string;
};

const AcceptInviteCardContent = ({
    storeName,
    email
}: InviteCardContentProps) => (
    <div className="flex flex-col items-center justify-center">
        <div className="text-2xl font-semibold">Join your teammates at</div>
        <div className="text-2xl font-semibold text-success-light">
            {storeName}
        </div>
        <div className="mt-4 text-base font-normal">
            Your email {email} was invited.
        </div>
    </div>
);

const WrongEmail = ({ email }: InviteCardContentProps) => (
    <div className="flex flex-col items-center justify-center">
        <div className="text-2xl font-semibold">Unable to accept invite</div>
        <div className="mt-4 text-base font-normal">
            You are currently signed in with {email}
        </div>
    </div>
);

const PleaseSignIn = ({ email }: InviteCardContentProps) => (
    <div className="flex flex-col items-center justify-center">
        <div className="text-2xl font-semibold">Sign in to continue</div>
        <div className="mt-4 text-base font-normal">
            Please sign in with your existing account: {email}
        </div>
    </div>
);
