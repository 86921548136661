import { SystemColors } from "@snackpass/design-system";
import React, { CSSProperties } from "react";
import Table, { CellInfo } from "react-table";

import { InventoryReport } from "src/redux/slices";

import { TopUp } from "./top-up";

type InventoryTableProps = {
    inventory: any[];
    loading: boolean;
    onClickEdit: (item: InventoryReport) => void;
    showTopUp: boolean;
};

/**
 * @deprecated
 * this has been deprecated because it belongs to a piece of
 * legacy code. If you would like to use this in new code please copy only what
 * you need out of the legacy directory and into the new structure. Any copied
 * code must adhere to the frontend style guide / quality standards;
 * in addition to this, all other non-legacy code using this component should
 * be adapted with your refactored and reorganized version
 */
const StatusColor = ({ color }: { color: string }) => (
    <div
        style={{
            backgroundColor: color,
            width: 8,
            height: 8,
            borderRadius: 30,
            marginRight: 5
        }}
    ></div>
);

/**
 * @deprecated
 * this has been deprecated because it belongs to a piece of
 * legacy code. If you would like to use this in new code please copy only what
 * you need out of the legacy directory and into the new structure. Any copied
 * code must adhere to the frontend style guide / quality standards;
 * in addition to this, all other non-legacy code using this component should
 * be adapted with your refactored and reorganized version
 */
export const InventoryTable = (props: InventoryTableProps) => {
    const { inventory, loading, onClickEdit, showTopUp } = props;

    const nameColumn = () => ({
        Header: "Item Name",
        id: "name",
        accessor: "Item Name",
        minWidth: 125,
        Cell: ({ value }: CellInfo) => value || ""
    });

    const SKUColumn = () => ({
        Header: "SKU",
        id: "SKU",
        accessor: "SKU",
        minWidth: 125,
        Cell: ({ value }: CellInfo) => value || ""
    });

    const statusColumn = () => ({
        Header: "Status",
        id: "status",
        minWidth: 125,
        sortable: false,
        accessor: "Status",
        Cell: ({ value: status }: CellInfo) => {
            if (status === "Out of Stock") {
                return (
                    <span style={styles.statusContainer}>
                        <StatusColor color={SystemColors.v1.melon50} /> Out of
                        Stock
                    </span>
                );
            }
            if (status === "Low Stock") {
                return (
                    <span style={styles.statusContainer}>
                        <StatusColor color={SystemColors.v1.macaroni40} /> Low
                        Stock
                    </span>
                );
            }
            return (
                <span style={styles.statusContainer}>
                    <StatusColor color={SystemColors.v1.parsley50} /> In Stock
                </span>
            );
        }
    });

    const onHand = () => ({
        Header: "On Hand",
        id: "onHand",
        accessor: "On Hand",
        minWidth: 100,
        Cell: ({ value }: CellInfo) => value || 0
    });

    const UOM = () => ({
        Header: "UOM",
        id: "UOM",
        accessor: "UOM",
        minWidth: 100,
        Cell: ({ value }: CellInfo) => value || ""
    });

    const price = () => ({
        Header: "Price",
        id: "price",
        accessor: "Price",
        minWidth: 100,
        Cell: ({ value }: CellInfo) => value || "$0.00"
    });

    const quantitySold = () => ({
        Header: "Quantity Sold",
        id: "quantitySold",
        accessor: "Quantity Sold",
        minWidth: 125,
        sortable: false,
        Cell: ({ value }: CellInfo) => value || 0
    });

    const totalCost = () => ({
        Header: "Total Cost",
        id: "totalCost",
        accessor: "Total Cost",
        minWidth: 125,
        sortable: false,
        Cell: ({ value }: CellInfo) => value || "$0.00"
    });

    const actions = () => ({
        Header: "",
        id: "edit",
        accessor: undefined,
        minWidth: 50,
        sortable: false,

        Cell: ({ original: { original } }: CellInfo) => (
            <span
                style={{ paddingLeft: 10, cursor: "pointer" }}
                onClick={() => {
                    onClickEdit(original);
                }}
                className="hover fas fa-edit"
            ></span>
        )
    });

    const topOff = () => ({
        Header: "Adjust Quantity",
        id: "topUp",
        accessor: undefined,
        minWidth: 100,
        sortable: false,
        headerStyle: { backgroundColor: SystemColors.v1.candy90 },
        Cell: (props: CellInfo) => (
            <TopUp inventoryReport={props.original.original} />
        )
    });

    const buildColumns = () => {
        const columns = [
            actions(),
            nameColumn(),
            SKUColumn(),
            statusColumn(),
            onHand(),
            UOM(),
            price(),
            quantitySold(),
            totalCost()
        ];
        const ON_HAND_INDEX = 5;
        if (showTopUp) {
            columns.splice(ON_HAND_INDEX, 0, topOff());
        }
        return columns;
    };

    return (
        <Table
            defaultPageSize={20}
            data={inventory}
            loading={loading}
            className="snackpass_table inventory_table -striped -highlight"
            columns={buildColumns()}
        />
    );
};

const styles = {
    statusContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center"
    } as CSSProperties
};
