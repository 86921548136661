import React, { useContext } from "react";
import { Spinner } from "react-activity";
import { useMutation } from "@tanstack/react-query";
import { CashDrawerWithPrinter } from "@snackpass/snackpass-types";
import { toast } from "sonner";
import { Pencil2Icon } from "@radix-ui/react-icons";

import { useCashDrawers } from "#devices/hooks/useCashDrawers";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import api from "src/api/rest";
import { Button } from "src/@/components/ui/button";

export const EditOrDisconnect = ({
    openEditModal: openModal,
    linkedDrawer
}: {
    openEditModal: () => void;
    linkedDrawer: CashDrawerWithPrinter;
}) => {
    const { disconnectCashDrawer, isLoading: isLoadingDisconnectCashDrawer } =
        useDisconnectCashDrawer(linkedDrawer);
    const { deviceHasChanges } = useContext(DevicesPageContext);
    return (
        <>
            <div className="mb-2">Connected to: {linkedDrawer.name}</div>
            <div className="flex justify-end space-x-1">
                <Button
                    onClick={openModal}
                    disabled={deviceHasChanges}
                    className="px-3"
                >
                    <Pencil2Icon className="mr-1 h-4 w-4" />
                    Edit
                </Button>
                <Button
                    variant="destructive"
                    onClick={() => disconnectCashDrawer()}
                    disabled={deviceHasChanges}
                >
                    {isLoadingDisconnectCashDrawer ? (
                        <Spinner speed={0.5} size={18} />
                    ) : (
                        "Disconnect"
                    )}
                </Button>
            </div>
        </>
    );
};

const useDisconnectCashDrawer = (cashDrawer?: CashDrawerWithPrinter) => {
    const { refetch } = useCashDrawers();
    const refetchCashDrawers = () => {
        void refetch();
    };
    const { isPending, mutate } = useMutation({
        onSuccess: () => {
            refetchCashDrawers();
            toast.success("Successfully disconnected cash drawer.");
        },
        mutationFn: async () => {
            if (!cashDrawer) {
                throw new Error("No cash drawer.");
            }
            const newCashDrawer = {
                name: cashDrawer.name,
                printer: null,
                device: null
            };
            return api.cashDrawers.updateCashDrawer(
                cashDrawer.id,
                newCashDrawer
            );
        }
    });
    return { isLoading: isPending, disconnectCashDrawer: mutate };
};
