import React from "react";
import styled from "styled-components";
import { SystemColors } from "@snackpass/design-system";
import { useFormContext } from "react-hook-form";
import _ from "lodash";
import { ScreenState } from "@snackpass/snackpass-types";
import { Spinner } from "react-activity";

type Props = {
    isEdit: boolean;
    submitting: boolean;
};

type InputProps = {
    hasErrors: boolean;
};

export const FormSubmit = ({ isEdit, submitting }: Props) => {
    const {
        formState: { errors }
    } = useFormContext();

    const hasErrors = !_.isEmpty(errors);

    return (
        <Container>
            {hasErrors ? (
                <ErrorMessage>* There are errors in the form</ErrorMessage>
            ) : null}
            {submitting ? (
                <SubmitPlaceholder>
                    <Spinner color={SystemColors.v1.white} />
                </SubmitPlaceholder>
            ) : (
                <Input
                    type={"submit"}
                    hasErrors={hasErrors}
                    disabled={hasErrors}
                    value={isEdit ? "Save Promo" : "Create Promo"}
                />
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
    @media ${ScreenState.MOBILE} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const Input = styled.input<InputProps>`
    background-color: ${SystemColors.v2.candy50.light};
    border-radius: 4px;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 16px;
    cursor: pointer;
    ${({ hasErrors }) =>
        hasErrors &&
        `background-color: ${SystemColors.v1.gray90}; color: ${SystemColors.v1.gray50}; cursor: not-allowed;`}
`;

const SubmitPlaceholder = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${SystemColors.v2.candy50.light};
    border-radius: 4px;
    border: none;
    color: white;
    padding: 10px 16px;
    width: 124px;
    height: 40px;
`;

const ErrorMessage = styled.p`
    margin: 0;
    margin-right: 24px;
    color: ${SystemColors.v1.melon50};
    font-size: 14px;
    @media ${ScreenState.MOBILE} {
        margin-bottom: 24px;
    }
`;
