import { IStore, IUser } from "@snackpass/snackpass-types";
import React, {
    Dispatch,
    createContext,
    useMemo,
    SetStateAction,
    useState,
    useCallback
} from "react";
import { useSelector } from "react-redux";

import { StoreVerification, PersonVerification } from "#payouts/domain/types";
import {
    hasEditPermission,
    hasEmployeePermission,
    hasSeniorSupportPermission
} from "#payouts/utils/validation/permissions";
import { getActiveStore, getUser } from "src/redux/selectors";

type StateDispatch<T> = Dispatch<SetStateAction<T>>;
type Maybe<T> = T | null;

type ModalChange = Partial<{
    company: boolean;
    account: boolean;
    person: boolean;
    terms: boolean;
    removePerson: boolean;
    makePrimary: boolean;
    adminHold?: boolean;
}>;

type PayoutsSettingsContextType = {
    user: Maybe<IUser>;
    store: Maybe<IStore>;
    storeId: string | undefined;
    editEnabled: boolean;
    isEmployee: boolean;
    isSeniorSupport: boolean;
    verification: Maybe<StoreVerification>;
    person: Maybe<PersonVerification>;
    companyModal: boolean;
    personModal: boolean;
    accountModal: boolean;
    termsModal: boolean;
    removePersonModal: boolean;
    removePerson: Maybe<PersonVerification>;
    adminHoldModal: boolean;
    newPrimary: Maybe<PersonVerification>;
    makePrimaryModal: boolean;
    emailVerified: boolean;
    shouldReloadVerification: boolean;
    resetState: (v?: Maybe<StoreVerification>) => void;
    setVerification: StateDispatch<PayoutsSettingsContextType["verification"]>;
    setPerson: StateDispatch<PayoutsSettingsContextType["person"]>;
    setRemovePerson: StateDispatch<PayoutsSettingsContextType["removePerson"]>;
    setNewPrimary: StateDispatch<PayoutsSettingsContextType["newPrimary"]>;
    handleModalChange: (set?: ModalChange) => void;
    setEmailVerified: StateDispatch<
        PayoutsSettingsContextType["emailVerified"]
    >;
    setShouldReloadVerification: StateDispatch<
        PayoutsSettingsContextType["shouldReloadVerification"]
    >;
};

export const PayoutsSettingsContext = createContext<PayoutsSettingsContextType>(
    {} as PayoutsSettingsContextType
);

const PayoutsSettingsProvider = ({ children }: { children: JSX.Element }) => {
    const activeStore = useSelector(getActiveStore);
    const activeUser = useSelector(getUser);

    const [store, storeId] = useMemo(
        () => [activeStore, activeStore?._id],
        [activeStore?._id]
    );
    const user = useMemo(() => activeUser, [activeUser?._id]);

    const isEmployee = hasEmployeePermission(user);
    const isSeniorSupport = hasSeniorSupportPermission(user);
    const editEnabled = hasEditPermission(user, storeId);

    const [companyModal, setCompanyModal] = useState(false);
    const [personModal, setPersonModal] = useState(false);
    const [accountModal, setAccountModal] = useState(false);
    const [termsModal, setTermsModal] = useState(false);
    const [removePersonModal, setRemovePersonModal] = useState(false);
    const [adminHoldModal, setAdminHoldModal] = useState(false);
    const [makePrimaryModal, setMakePrimaryModal] = useState(false);

    const [verification, setVerification] =
        useState<Maybe<StoreVerification>>(null);
    const [person, setPerson] = useState<Maybe<PersonVerification>>(null);
    const [removePerson, setRemovePerson] =
        useState<Maybe<PersonVerification>>(null);
    const [newPrimary, setNewPrimary] =
        useState<Maybe<PersonVerification>>(null);

    // User must confirm access to their email when initially loading the page
    const [emailVerified, setEmailVerified] = useState(false);

    // Set to reload the verification info in root page
    const [shouldReloadVerification, setShouldReloadVerification] =
        useState(false);

    // Reset everything but the verification, unless it needs to be updated
    const resetState = useCallback((v?: Maybe<StoreVerification>) => {
        if (v) {
            setVerification(v);
        }
        setCompanyModal(false);
        setPersonModal(false);
        setTermsModal(false);
        setRemovePersonModal(false);
        setAdminHoldModal(false);
        setMakePrimaryModal(false);
        setPerson(null);
        setRemovePerson(null);
        setNewPrimary(null);
    }, []);

    const handleModalChange = useCallback((set?: ModalChange) => {
        const settings: ModalChange = {
            company: set?.company,
            account: set?.account,
            person: set?.person,
            terms: set?.terms,
            removePerson: set?.removePerson,
            makePrimary: set?.makePrimary,
            ...set
        };
        setCompanyModal(settings.company ?? false);
        setAccountModal(settings.account ?? false);
        setPersonModal(settings.person ?? false);
        setTermsModal(settings.terms ?? false);
        setRemovePersonModal(settings.removePerson ?? false);
        // only unset it when the modals are closed
        if (!settings.person) {
            setPerson(null);
        }
        if (!settings.removePerson) {
            setRemovePerson(null);
        }
        setAdminHoldModal(settings.adminHold ?? false);
        setMakePrimaryModal(settings.makePrimary ?? false);
        if (!settings.makePrimary) {
            setNewPrimary(null);
        }
    }, []);

    const value = useMemo(
        () => ({
            user,
            store,
            storeId,
            editEnabled,
            isEmployee,
            isSeniorSupport,
            verification,
            person,
            companyModal,
            personModal,
            accountModal,
            termsModal,
            removePersonModal,
            removePerson,
            adminHoldModal,
            makePrimaryModal,
            newPrimary,
            emailVerified,
            shouldReloadVerification,
            resetState,
            setVerification,
            setPerson,
            setRemovePerson,
            setNewPrimary,
            setEmailVerified,
            handleModalChange,
            setShouldReloadVerification
        }),
        [
            user?._id,
            store?._id,
            verification?.id,
            person?.id,
            editEnabled,
            isEmployee,
            isSeniorSupport,
            verification,
            person,
            companyModal,
            personModal,
            accountModal,
            termsModal,
            removePersonModal,
            removePerson,
            adminHoldModal,
            makePrimaryModal,
            newPrimary,
            emailVerified,
            shouldReloadVerification
        ]
    );

    return (
        <PayoutsSettingsContext.Provider value={value}>
            {children}
        </PayoutsSettingsContext.Provider>
    );
};

export default PayoutsSettingsProvider;
