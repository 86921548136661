import { CSSProperties, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Fab } from "@material-ui/core";
import { SystemColors } from "@snackpass/design-system";

import constants from "#core/constants";
import { GlobalDndContext } from "#menu-editor/mobile-friendly/helpers/dnd-context";
import { getMultiMenuLoadingState } from "#menu-editor/multi-menus/redux/selectors";
import { multiMenuThunks } from "#menu-editor/multi-menus/redux/thunks";
import { Header } from "#menu-editor/multi-menus/screens/menu-overview-desktop/components/header";
import { ItemsTable } from "#menu-editor/multi-menus/screens/menu-overview-desktop/components/items-table/items-table";
import { OutlineChangesTracker } from "#menu-editor/multi-menus/screens/menu-overview-desktop/components/outline-changes-tracker";
import { MobileHeader } from "#menu-editor/multi-menus/shared-components/mobile-header";
import { NoContentMessage } from "#menu-editor/multi-menus/shared-components/no-content-message";
import { ScreenLayout } from "#menu-editor/multi-menus/styled-components/layout";
import { getCurrentMultiMenu } from "src/redux/selectors";
import { ReactComponent as GearSVG } from "src/assets/icons/gear.svg";
import { RoundButton } from "#menu-editor/multi-menus/styled-components/button";
import { ReactComponent as PlusSVG } from "src/assets/icons/plus-sign.svg";
import {
    openNotification,
    NotificationPosition
} from "#menu-editor/multi-menus/shared-components/notification";
import { useAppDispatch } from "src/redux/hooks";

export function MultiMenuOverview() {
    const {
        params: { id: menuId }
    } = useRouteMatch<{ id: string }>();

    const dispatch = useAppDispatch();
    const history = useHistory();
    const currentMenu = useSelector(getCurrentMultiMenu);
    const loadingStatus = useSelector(
        getMultiMenuLoadingState("FetchStoreMenus")
    );
    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`
    });

    const [outlineChangesCount, setOutlineChangesCount] = useState(0);

    const increaseOutlineChangesCount = () =>
        setOutlineChangesCount(outlineChangesCount + 1);

    const resetOutlineChangesCount = useCallback(
        () => setOutlineChangesCount(0),
        [setOutlineChangesCount]
    );

    useEffect(() => {
        if (loadingStatus === "succeeded")
            dispatch(
                multiMenuThunks.selectMultiMenu({
                    menuId,
                    onMenuNotFound: () => history.replace("/multi-menus")
                })
            );
        if (loadingStatus === "failed") {
            openNotification({
                message: "Failed to fetch menu",
                description:
                    "Failed to fetch current menu, please try again later.",
                position: NotificationPosition.TopCenter
            });
        }
    }, [loadingStatus]);

    const navigateToAddItems = useCallback(() => {
        history.push(`/multi-menus-edit/${menuId}`);
    }, [history]);

    const navigateToMenuSettings = useCallback(() => {
        history.push(`/multi-menus-settings/${menuId}`);
    }, [history]);

    return (
        <>
            <GlobalDndContext>
                <ScreenLayout
                    isLoading={loadingStatus === "pending"}
                    breadcrumbItems={
                        !isMobile
                            ? [
                                  {
                                      label: "Items",
                                      to: "/mobile-friendly-menu-editor"
                                  },
                                  { label: "Menus", to: "/multi-menus" },
                                  {
                                      label: currentMenu.name ?? "",
                                      to: `/multi-menus/${currentMenu.id}`
                                  }
                              ]
                            : []
                    }
                    header={
                        !isMobile ? (
                            <Header
                                toMenuSettings={navigateToMenuSettings}
                                toAddItems={navigateToAddItems}
                            />
                        ) : (
                            <MobileHeader
                                title="Menu Outline"
                                right={
                                    <RoundButton
                                        onClick={navigateToMenuSettings}
                                    >
                                        <GearSVG />
                                    </RoundButton>
                                }
                            />
                        )
                    }
                    content={
                        currentMenu?.categories?.length ? (
                            <ItemsTable
                                increaseOutlineChangesCount={
                                    increaseOutlineChangesCount
                                }
                            />
                        ) : (
                            <NoContentMessage
                                title="No Items"
                                subtitle="Start by adding your first item"
                                buttonText="Select Items"
                                onClick={navigateToAddItems}
                            />
                        )
                    }
                    footer={
                        outlineChangesCount ? (
                            <OutlineChangesTracker
                                changesCount={outlineChangesCount}
                                resetOutlineChangesCount={
                                    resetOutlineChangesCount
                                }
                                isMobile={isMobile}
                            />
                        ) : undefined
                    }
                    centerContent={!currentMenu?.categories?.length}
                />
            </GlobalDndContext>
            {isMobile ? (
                <Fab
                    aria-label="add"
                    style={styles.fabStyles(outlineChangesCount > 0)}
                    onClick={navigateToAddItems}
                >
                    <PlusSVG fill={SystemColors.v1.white} width={18} />
                </Fab>
            ) : null}
        </>
    );
}

const styles = {
    fabStyles: (footer: boolean) =>
        ({
            position: "fixed",
            right: "16px",
            bottom: footer ? "200px" : "100px",
            background: "#0077FF"
        }) as CSSProperties
};
