/** @jsxImportSource @emotion/react */
import { IStore } from "@snackpass/snackpass-types";
import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";

import "antd/dist/antd.css";
import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { Button, Divider, Form, FormProps, Input, Modal } from "antd";
import { AccordionDetails } from "@material-ui/core";
import { toast } from "sonner";

import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";

const validateMessages = {
    required: "${label} is required!",
    types: {
        name: "${label} is not valid",
        number: "${label} is not a valid number!"
    },
    number: {
        min: "${label} cannot be less than ${min}"
    }
};

type ImportMenuModalProp = {
    isImportMenuModalOpen: boolean;
    setIsImportMenuModalOpen: React.Dispatch<boolean>;
    setIsLoading: React.Dispatch<boolean>;
    setImportStatus: React.Dispatch<{
        status: string;
        message: string;
    }>;
};

const ImportWoflowV2: React.FC<ImportMenuModalProp> = ({
    setImportStatus,
    setIsLoading
}) => {
    const activeStore = useSelector(getActiveStore) as IStore;
    const [menuJsonURL, setMenuJsonURL] = useState<string>("");
    const [catalogId, setCatalogId] = useState("");

    const onFinish = () => {
        handleImportMenuV2();
    };

    const onFinishFailed: FormProps["onFinishFailed"] = (errorInfo) => {
        console.log("Failed:", errorInfo);
        toast.error(errorInfo.toString());
    };

    const handleImportMenuV2 = () => {
        if (catalogId || menuJsonURL) {
            const isLargeMenu = !!menuJsonURL;
            setIsLoading(true);
            api.stores
                .importMenuV2({
                    storeId: activeStore._id,
                    isLargeMenu,
                    url: menuJsonURL,
                    catalogId
                })
                .then(async () => {
                    await api.stores
                        .getImportStatus(activeStore._id)
                        .then((response) => {
                            const data = response.data;
                            setImportStatus({
                                status: data.status,
                                message: data.message
                            });
                            setCatalogId("");
                        });
                })
                .catch((err) => {
                    setImportStatus({
                        status: "fail",
                        message: err.message
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <Form
            name="importMenu"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            validateMessages={validateMessages}
        >
            <Form.Item
                name="menuId"
                label="Woflow Catalog ID"
                rules={[
                    {
                        async validator() {
                            if (!(catalogId || menuJsonURL)) {
                                return Promise.reject(
                                    "Catalog id and json url can not be empty at same time"
                                );
                            }
                            return Promise.resolve();
                        }
                    }
                ]}
            >
                <Input
                    placeholder="Catalog ID"
                    value={catalogId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCatalogId(e.target.value)
                    }
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter") {
                            e.currentTarget.blur();
                            const val = (e.target as HTMLInputElement).value;
                            setCatalogId(val);
                        }
                    }}
                ></Input>
            </Form.Item>
            <Form.Item
                name="menuJson"
                label="For a very large menu, import with the url of its json"
                rules={[
                    {
                        async validator() {
                            if (!(catalogId || menuJsonURL)) {
                                return Promise.reject(
                                    "Catalog id and json url can not be empty at same time"
                                );
                            }
                            return Promise.resolve();
                        }
                    }
                ]}
            >
                <Input
                    placeholder="url of the json"
                    value={menuJsonURL}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setMenuJsonURL(e.target.value)
                    }
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter") {
                            e.currentTarget.blur();
                            const val = (e.target as HTMLInputElement).value;
                            setMenuJsonURL(val);
                        }
                    }}
                ></Input>
            </Form.Item>
            <Divider />
            <Button
                type="primary"
                className="import-menu-btn"
                htmlType="submit"
                onClick={handleImportMenuV2}
                shape="round"
            >
                Import Menu
            </Button>
        </Form>
    );
};

enum Accordions {
    v1 = "V1",
    v2 = "V2"
}

const ImportMenuModal: React.FC<ImportMenuModalProp> = (props) => {
    const { isImportMenuModalOpen, setIsImportMenuModalOpen } = props;
    const [expanded, setExpanded] = useState<Accordions | undefined>();

    const handleChange =
        (panel: Accordions) =>
        (_event: ChangeEvent<{}>, newExpanded: boolean) => {
            if (!newExpanded) return setExpanded(undefined);
            if (panel === Accordions.v2) return setExpanded(panel);
        };

    return (
        <>
            <Modal
                centered
                css={ModalCSS}
                visible={isImportMenuModalOpen}
                footer={null}
                onCancel={() => {
                    setIsImportMenuModalOpen(false);
                }}
            >
                <HeaderRow>
                    <span className="header-text" onClick={() => {}}>
                        Import Menu
                    </span>
                </HeaderRow>
                <AccordionDetails>
                    <ImportWoflowV2 {...props} />
                </AccordionDetails>
            </Modal>
        </>
    );
};

const ModalCSS = css`
    font-family: "Inter";
    align-items: center;
    justify-content: center;
    .ant-btn {
        border-radius: 8px;
    }

    .ant-form label {
        font-size: 16px;
        margin-top: 5px;
        margin-right: 1rem;
    }
    .ant-col.ant-form-item-label {
        padding-bottom: 1px;
        padding-top: 1px;
    }

    .ant-row.ant-form-item {
        margin: 5px;
        margin-right: 1rem;
    }

    .input-number {
        border-radius: 8px;
    }

    input {
        border-radius: 8px;
    }
    .import-menu-btn {
        margin-left: 10rem;
    }
`;

const HeaderRow = styled.div`
    margin-bottom: 1rem;
    .header-text {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        cursor: pointer;
    }
`;

export default ImportMenuModal;
