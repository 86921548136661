import { Dropdown, Menu } from "antd";
import type { MenuProps } from "antd";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { dispatch } from "src/redux/store";
import { isProduct, RowInstance } from "#menu-editor/multi-menus/helpers";
import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import { multiMenuThunks } from "#menu-editor/multi-menus/redux/thunks";
import { ReactComponent as DeleteRedIcon } from "src/assets/icons/delete-red.svg";
// import { ReactComponent as EditIcon } from "src/assets/icons/edit.svg";
// import { ReactComponent as OutOfStock } from "src/assets/icons/mark-out-of-stock.svg";

enum KeyEnum {
    SoldOut = "SoldOut",
    Edit = "Edit",
    Remove = "Remove"
}

type ProductDropdownProps = {
    item: RowInstance;
};

export const ProductDropdown: React.FC<ProductDropdownProps> = ({ item }) => {
    const history = useHistory();

    const onClick: MenuProps["onClick"] = ({ key }) => {
        switch (key) {
            case KeyEnum.SoldOut:
                break;
            case KeyEnum.Edit:
                history.push("mobile-friendly-menu-editor");
                break;
            case KeyEnum.Remove:
                if (isProduct(item)) {
                    dispatch(
                        multiMenuActions.discardProduct({
                            product: item,
                            categoryId: item.parentId ?? "no-set"
                        })
                    );
                    dispatch(multiMenuThunks.persistCurrentMenu());
                }
                break;
            default:
                break;
        }
    };

    const menu = (
        <Menu
            style={menuStyle}
            items={[
                // TODO: Add again when the new "Sold out" pop-up and the "Edit item" screen are implemented.
                /*
                {
                    label: "Mark as Sold Out",
                    key: KeyEnum.SoldOut,
                    icon: <OutOfStock />,
                    onClick: onClick,
                    style: { ...baseLabelStyle, ...topLabelStyle }
                },
                {
                    label: "Edit Item",
                    key: KeyEnum.Edit,
                    icon: <EditIcon fill="#282D32" />,
                    onClick: onClick,
                    style: { ...baseLabelStyle, ...middleLabelStyle }
                },
                */ {
                    label: "Remove from Menu",
                    key: KeyEnum.Remove,
                    icon: <DeleteRedIcon />,
                    onClick: onClick,
                    style: {
                        ...baseLabelStyle,
                        ...bottomLabelStyle
                    }
                }
            ]}
        />
    );

    return (
        <ProductDropdownWrapper>
            <Dropdown
                overlay={menu}
                className="dropdown-btn"
                trigger={["click"]}
            >
                <Dots />
            </Dropdown>
        </ProductDropdownWrapper>
    );
};

const ProductDropdownWrapper = styled.div`
    .dropdown-btn > .ant-dropdown-trigger {
        border: none;
    }
`;

const Dots = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 20px;
    cursor: pointer;
    :after {
        content: "•••";
        top: 50%;
        left: 50%;
        font-size: 11px;
    }
    &:hover {
        box-shadow: inset 999px 4px 0px rgba(241, 245, 248, 0.5);
        transition: 0.5s;
    }
`;

const baseLabelStyle = {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    borderRadius: "4px",
    padding: "10px 15px 10px 15px"
} as CSSProperties;

const topLabelStyle = {
    margin: "8px 8px 0px 8px"
} as CSSProperties;

const bottomLabelStyle = {
    margin: "8px",
    color: "#FF3929"
} as CSSProperties;

const middleLabelStyle = {
    margin: "0px 8px"
} as CSSProperties;

const menuStyle = { borderRadius: "8px" } as CSSProperties;
