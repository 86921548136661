import React from "react";
import styled from "@emotion/styled";
import { SystemColors } from "@snackpass/design-system";

type CloseButtonProps = {
    onClick: () => void;
};

export const CloseButton = ({ onClick }: CloseButtonProps) => (
    <CloseButtonContainer onClick={onClick}>
        <i className="fas fa-times" style={{ marginRight: 5 }} />
        <span
            style={{
                fontWeight: "bold"
            }}
        >
            Close
        </span>
    </CloseButtonContainer>
);

const CloseButtonContainer = styled.button`
    border-radius: 30px;
    margin-bottom: 10px;
    padding: 5px 15px;
    background-color: ${SystemColors.v1.white};
    border: 1px solid ${SystemColors.v1.gray80};
    box-shadow: none;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
