import { PromoTarget } from "@snackpass/snackpass-types";

import {
    FIELD_NAMES,
    FirstTimePromo,
    StudentPromo
} from "#promotion/utils/types";
import { GenericDefaultValues } from "#promotion/utils/validation/form-schemas/generic";

export const StudentsDefaultValues = {
    ...GenericDefaultValues,
    // Type Overrides
    [FIELD_NAMES.AUDIENCE]: PromoTarget.Students
} as StudentPromo;

export const FirstTimeDefaultValues = {
    ...GenericDefaultValues,
    // Type Overrides
    [FIELD_NAMES.AUDIENCE]: PromoTarget.FirstTime
} as FirstTimePromo;
