import {
    Statuses,
    StatusReasons,
    StoreVerification
} from "#payouts/domain/types";

/**
 * Stripe Connect requirements that are directly managed in RDB.
 *
 * Other requirements are managed automatically by Snackpass -- e.g.
 * `tos_acceptance.date`.
 */
const SUPPORTED_REQUIREMENTS = [
    // Company
    "company.address.line1",
    "company.address.city",
    "company.address.state",
    "company.address.country",
    "company.address.postal_code",
    "company.owners_provided",
    "company.directors_provided",
    "company.executives_provided",
    "external_account",
    "company.name",
    "company.phone",
    "company.structure",
    "company.tax_id",
    "business_profile.url",
    "business_profile.mcc",
    // "tos_acceptance.date",
    // "tos_acceptance.ip",
    "verification.document.front",
    "verification.document.back",

    "documents.bank_account_ownership_verification.files",

    // Person
    "owners.address.city",
    "owners.address.line1",
    "owners.address.postal_code",
    "owners.address.state",
    "owners.dob.day",
    "owners.dob.month",
    "owners.dob.year",
    "owners.email",
    "owners.first_name",
    "owners.last_name",
    "owners.phone",

    "representative.address.city",
    "representative.address.line1",
    "representative.address.postal_code",
    "representative.address.state",
    "representative.dob.day",
    "representative.dob.month",
    "representative.dob.year",
    "representative.email",
    "representative.first_name",
    "representative.last_name",
    "representative.phone",
    "representative.relationship.title",
    "representative.ssn_last_4"
];

/**
 * Regexes for requirements that are supported by RDB. This is for requirements
 * that are not static strings.
 */
const SUPPORTED_REQUIREMENTS_REGEXES = [/person_[a-zA-Z0-9]+.id_number/];

/**
 * Determine whether a list of requirements is composed solely of requirements
 * that cannot be managed in RDB.
 */
const hasOnlyUnsupportedRequirements = (requirements: string[]) =>
    requirements.every(
        (r) =>
            !SUPPORTED_REQUIREMENTS.includes(r) &&
            !SUPPORTED_REQUIREMENTS_REGEXES.some((regex) => regex.test(r))
    );

// Stripe connect dates are stored in time since epoch seconds.
// This should never be undefined if we calling it, but as a fallback,
// use the current date.
const getDate = (dueDate: number | undefined): Date =>
    dueDate ? new Date(dueDate * 1000) : new Date();

/**
 * For a given StoreVerification, returns the warning status, either
 * "none", "warning_" (+ reason for warning), or "error_".
 *
 * This is used to determine the color (and presence/absence) of the warning
 * icon in the verification status section and on the navigation side bar:
 * "none" means no warning, "warning_*" means a yellow warning icon, and
 * "error_*" means a red warning icon.
 */
export const getVerificationWarningStatus = (
    verification: StoreVerification
):
    | "none"
    | "warning_future_requirements"
    | "warning_future_requirements_support"
    | "warning_future_eventual_requirements"
    | "warning_future_eventual_requirements_support"
    | "warning_admin_pause"
    | "warning_pending"
    | "warning_eventual_requirements"
    | "warning_eventual_requirements_support"
    | "warning_current_requirements"
    | "warning_current_requirements_support"
    | "error_support"
    | "error" => {
    const isEnabled =
        verification.status === Statuses.enabled &&
        verification.statusReason === StatusReasons.approved;

    const isAdminPaused =
        verification.status === Statuses.paused &&
        verification.statusReason === StatusReasons.snackpassAdminHold;

    const isPending = [
        StatusReasons.pendingReview,
        StatusReasons.prohibitedEntity
    ].includes(verification.statusReason);

    const hasSubmitted = !!verification.termsOfServiceDate;

    const hasCurrentRequirementsDue =
        !!verification.currentRequirements?.length;

    const hasEventualRequirementsDue =
        !!verification.eventualRequirements?.length;

    const currentRequirementsDueIn = hasCurrentRequirementsDue
        ? getDate(verification.currentDueDate) > new Date()
            ? "future"
            : "past"
        : null;

    const hasFutureRequirementsDue = !!verification.futureRequirements?.length;

    const hasFutureEventualRequirementsDue =
        !!verification.futureEventualRequirements?.length;

    const allCurrentRequirementsAreUnsupported =
            hasCurrentRequirementsDue &&
            hasOnlyUnsupportedRequirements(verification.currentRequirements!),
        allEventualRequirementsAreUnsupported =
            hasEventualRequirementsDue &&
            hasOnlyUnsupportedRequirements(verification.eventualRequirements!),
        allFutureRequirementsAreUnsupported =
            hasFutureRequirementsDue &&
            hasOnlyUnsupportedRequirements(verification.futureRequirements!),
        allFutureEventualRequirementsAreUnsupported =
            hasFutureEventualRequirementsDue &&
            hasOnlyUnsupportedRequirements(
                verification.futureEventualRequirements!
            );

    /**
     * States:
     * - Enabled ('none'): No outstanding requirements (current, eventual, future, futureEventual).
     *
     * - Admin Paused ('warning_admin_pause'): Override state; Stripe requirements are irrelevant.
     *
     * - Pending ('warning_pending'): Verification is pending review by Stripe.
     *
     * - Current Requirements Due in Future ('warning_current_requirements'): There are more requirements to complete,
     *      but they are not yet due. Though `status` is paused, the store is not actually paused yet (until the due
     *      date passes).
     *
     * - Eventual Requirements ('warning_eventual_requirements'): There will be more requirements when the store
     *      reaches a certain threshold of $ volume. Though `status` is paused, the store is not actually paused yet
     *      (until the threshold is reached, and then a due date will be set).
     *
     * - Future Requirements Due ('warning_future_requirements'): There will be more requirements to complete when
     *      Stripe updates their requirements (on futureDueDate). Though `status` is paused, the store is not actually
     *      paused yet (futureRequirements will become currentRequirements on futureDueDate, and a new due date will
     *      be set).
     *
     * - Future Eventual Requirements ('warning_future_eventual_requirements'): There will be more requirements to
     *   complete when Stripe updates their requirements (on futureDueDate) and the store reaches a certain threshold
     *   of $ volume. Though `status` is paused, the store is not actually paused yet (futureEventualRequirements will
     *   become eventualRequirements on futureDueDate, and a new due date will be set).
     *
     * - Incomplete Requirements ('error'): There are outstanding requirements that are past due. The store is paused
     *   until these requirements are completed.
     *
     * All states with outstanding requirements can also have a '_support' suffix, which indicates that the
     * outstanding requirements are not able to be changed by the User in RDB.
     */

    if (isEnabled) {
        if (hasFutureRequirementsDue) {
            return allFutureRequirementsAreUnsupported
                ? "warning_future_requirements_support"
                : "warning_future_requirements";
        } else if (hasFutureEventualRequirementsDue) {
            return allFutureEventualRequirementsAreUnsupported
                ? "warning_future_eventual_requirements_support"
                : "warning_future_eventual_requirements";
        }

        return "none";
    }

    if (isAdminPaused) return "warning_admin_pause";

    if (isPending) return "warning_pending";

    if (!hasSubmitted) return "error";

    // At this point, we know status=paused, statusReason=verificationRequest
    // we have to distinguish if we want to show it as paused or if the pause
    // is not actually enforced yet.
    //
    // Reasons include that the pause is:
    // - solely due to eventual requirement(s)
    // - solely due to unsupported requirement(s)
    // - solely due to requirement(s) whose due date has not yet passed

    if (!hasCurrentRequirementsDue) {
        if (hasEventualRequirementsDue) {
            return allEventualRequirementsAreUnsupported
                ? "warning_eventual_requirements_support"
                : "warning_eventual_requirements";
        }
    }

    if (currentRequirementsDueIn === "future")
        return allCurrentRequirementsAreUnsupported
            ? "warning_current_requirements_support"
            : "warning_current_requirements";

    return allCurrentRequirementsAreUnsupported ? "error_support" : "error";
};
