import { ErrorMessage } from "@hookform/error-message";

import {
    FormControl,
    FormDescription,
    FormField,
    FormLabel
} from "src/@/components/ui/form";
import { Switch } from "src/@/components/ui/switch";
import { ValidatorMessage } from "#reusable/validators/validator-message";

import { InternalSettingsFormItem } from "./form-item";
import {
    useInternalSettingsForm,
    InternalSettingsFormKeysOfType
} from "./types";

type InternalSettingsSwitchProps = {
    name: InternalSettingsFormKeysOfType<boolean>;
    label: string;
    description?: string;
    onChange?: (value: boolean) => false | void;
    border?: boolean;
};

export function InternalSettingsSwitch({
    name,
    label,
    description,
    onChange,
    border = true
}: InternalSettingsSwitchProps) {
    const form = useInternalSettingsForm();
    return (
        <FormField
            control={form.control}
            name={name}
            render={({ field }) => (
                <InternalSettingsFormItem border={border}>
                    <FormLabel className="text-body font-semibold">
                        {label}
                    </FormLabel>
                    <FormControl>
                        <Switch
                            disabled={field.disabled}
                            checked={field.value as boolean}
                            onBlur={field.onBlur}
                            onCheckedChange={(checked) => {
                                if (onChange?.(checked) === false) {
                                    return;
                                }
                                field.onChange({
                                    target: { value: checked }
                                });
                            }}
                        />
                    </FormControl>
                    {description && (
                        <FormDescription className="text-[#585B5F]">
                            {description}
                        </FormDescription>
                    )}
                    <ErrorMessage
                        errors={form.formState.errors}
                        name={name}
                        render={({ message }) => (
                            <ValidatorMessage
                                paddingVertical="0"
                                type="error"
                                description={message}
                            />
                        )}
                    />
                </InternalSettingsFormItem>
            )}
        />
    );
}
