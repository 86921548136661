/** @jsxImportSource @emotion/react */
import { PurchaseSummaryData } from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Column, ColumnDef } from "@tanstack/react-table";
import Skeleton from "react-loading-skeleton";
import { toast } from "sonner";

import { useFinancialReportsData } from "#financial-reports/hooks/useFinancialReportsData";
import { getUserTeamPermission } from "src/redux/selectors/user";
import DownloadPurchasesCSVButton from "#download-purchase-button";
import { getActiveStore } from "src/redux/selectors";
import { getStoreTimezone } from "#utils/helpers";
import { DataTable } from "src/@/components/ui/data-table";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { toDollarFormatted } from "#reports/sales-summary/lib";
import { ReportGranularity } from "#financial-reports/types";

export const EssentialReportTable = ({ granularity }: Props) => {
    const updatedTime = moment().format("h:mm a");
    const { data, isLoading, error } = useFinancialReportsData(granularity);
    const isUserTeam = useSelector(getUserTeamPermission);

    useEffect(() => {
        if (error) {
            const errorMessage =
                // @ts-expect-error error's type is unknown
                error?.cause?.response?.data?.message ||
                "Failed to fetch payment history";
            toast.error(errorMessage);
        }
    }, [error]);

    const activeStore = useSelector(getActiveStore);
    const timezone = getStoreTimezone(activeStore);

    const columns: ColumnDef<PurchaseSummaryData>[] = useMemo(
        () => [
            {
                id: "essentials_downloadButton",
                header: "",
                cell: (props: CellProps) => (
                    <DownloadPurchasesCSVButton
                        hideLabel
                        startDate={moment(props.row.original.date)
                            .tz(timezone)
                            .startOf("day")}
                        endDate={moment(
                            props.row.original.endDate ??
                                props.row.original.date
                        )
                            .tz(timezone)
                            .endOf("day")}
                    />
                )
            },
            ...(granularity === "day"
                ? [
                      {
                          id: "essentials_date",
                          header: ({
                              column
                          }: {
                              column: Column<PurchaseSummaryData>;
                          }) => (
                              <DataTableColumnHeader
                                  buttonClassName="bg-transparent"
                                  column={column}
                                  hideIconUntilSorted
                                  title="Date"
                              />
                          ),
                          enableHiding: false,
                          accessor: "attributes.date",
                          cell: (props: CellProps) => {
                              const timestamp = props.row.original.date;
                              const date = moment
                                  .tz(timestamp, timezone)
                                  .format("MM/DD/YY");

                              return <span className="sticky">{date}</span>;
                          }
                      }
                  ]
                : [
                      {
                          id: "essentials_startDate",
                          header: ({
                              column
                          }: {
                              column: Column<PurchaseSummaryData>;
                          }) => (
                              <DataTableColumnHeader
                                  buttonClassName="bg-transparent"
                                  column={column}
                                  hideIconUntilSorted
                                  title="Start Date"
                              />
                          ),
                          enableHiding: false,
                          cell: (props: CellProps) => {
                              const timestamp = props.row.original.date;
                              const date = moment
                                  .tz(timestamp, timezone)
                                  .format("MM/DD/YY");
                              return <span>{date}</span>;
                          }
                      },
                      {
                          id: "essentials_endDate",
                          header: ({
                              column
                          }: {
                              column: Column<PurchaseSummaryData>;
                          }) => (
                              <DataTableColumnHeader
                                  buttonClassName="bg-transparent"
                                  column={column}
                                  title="End Date"
                                  hideIconUntilSorted
                              />
                          ),
                          enableHiding: false,
                          cell: (props: CellProps) => {
                              const timestamp = props.row.original.endDate;
                              const date = moment
                                  .tz(timestamp, timezone)
                                  .format("MM/DD/YY");
                              return <span>{date}</span>;
                          }
                      }
                  ]),
            {
                id: "essentials_count",
                header: ({ column }) => (
                    <DataTableColumnHeader
                        buttonClassName="bg-transparent"
                        column={column}
                        hideIconUntilSorted
                        title="Orders"
                    />
                ),
                enableHiding: false,
                accessorFn: (info) => info.count,
                cell: (props: CellProps) => {
                    const orders = props.row.original.count;
                    return <span>{orders}</span>;
                }
            },
            {
                id: "essentials_subtotal",
                header: ({ column }) => (
                    <DataTableColumnHeader
                        buttonClassName="bg-transparent"
                        hideIconUntilSorted
                        column={column}
                        title={
                            <div>
                                Subtotal
                                <ReportsTooltip
                                    body={
                                        "Cart Subtotal + Price Adjustments (up-charges) - Partial Refunds"
                                    }
                                />
                            </div>
                        }
                    />
                ),

                accessorFn: (info) => info.subtotal,

                cell: (props: CellProps) => {
                    const subtotal = toDollarFormatted(
                        props.row.original.subtotal
                    );
                    return <span>{subtotal}</span>;
                }
            },
            {
                id: "essentials_netSales",
                header: ({ column }) => (
                    <DataTableColumnHeader
                        buttonClassName="bg-transparent"
                        column={column}
                        hideIconUntilSorted
                        title={
                            <div className="whitespace-nowrap">
                                Net Sales
                                <ReportsTooltip
                                    body={
                                        "The subtotal of all sales plus any customer to store fees."
                                    }
                                />
                            </div>
                        }
                    />
                ),
                enableHiding: false,
                accessorFn: (info) => info.netSales,
                cell: (props: CellProps) => {
                    const amountPaid = toDollarFormatted(
                        props.row.original.netSales
                    );
                    return <span>{amountPaid}</span>;
                }
            },
            {
                id: "essentials_tip",
                header: ({ column }) => (
                    <DataTableColumnHeader
                        buttonClassName="bg-transparent"
                        column={column}
                        hideIconUntilSorted
                        title="Tips"
                    />
                ),
                accessorFn: (info) => info.tip,
                cell: (props: CellProps) => {
                    const tip = toDollarFormatted(props.row.original.tip);

                    return <span>{tip}</span>;
                },
                enableHiding: false
            },
            {
                id: "essentials_storeTaxesToRemit",
                header: () => (
                    <div>
                        Taxes You Owe
                        <ReportsTooltip body="Taxes you will be paid out and are responsible for remitting to the state." />
                    </div>
                ),
                Footer: "",
                accessor: "attributes.storeTaxesToRemit",
                width: 3,
                cell: (props: CellProps) => {
                    const storeTaxesToRemit = toDollarFormatted(
                        props.row.original.storeTaxesToRemit
                    );

                    return <span>{storeTaxesToRemit}</span>;
                }
            },
            {
                id: "essentials_storeTaxesWithheld",
                header: () => (
                    <div>
                        Taxes Remitted On Your Behalf
                        <ReportsTooltip
                            body={
                                "Taxes that Snackpass will remit to the government on your behalf. You will not be paid these out."
                            }
                        />
                    </div>
                ),
                Footer: "",
                accessor: "attributes.storeTaxesWithheld",
                width: 3,
                cell: (props: CellProps) => {
                    const storeTaxesWithheld = toDollarFormatted(
                        props.row.original.storeTaxesWithheld
                    );

                    return <span>{storeTaxesWithheld}</span>;
                }
            },
            {
                id: "essentials_processingFee",
                header: "Processing Fees",
                Footer: "",
                accessor: "attributes.processingFee",
                width: 3,
                cell: (props: CellProps) => {
                    const processingFee = toDollarFormatted(
                        props.row.original.processingFee
                    );

                    return <span>{processingFee}</span>;
                }
            },
            {
                id: "essentials_snackpassFees",
                header: () => (
                    <div>
                        Snackpass Fees
                        <ReportsTooltip
                            body={
                                "Any fees charged to your restaurant by Snackpass EXCEPT credit card fees. Commission is a common example."
                            }
                        />
                    </div>
                ),
                accessor: "attributes.snackpassFees",
                cell: (props: CellProps) => {
                    const snackFees = toDollarFormatted(
                        props.row.original.snackpassFees
                    );

                    return <span>{snackFees}</span>;
                }
            },
            {
                id: "essentials_faxFee",
                header: () => (
                    <div>
                        Fax Fees
                        <ReportsTooltip body="Any fees charged to your restaurant by Snackpass for sending orders via fax." />
                    </div>
                ),
                accessor: "faxFee",

                cell: (props: CellProps) => {
                    const faxFee = toDollarFormatted(props.row.original.faxFee);

                    return <span>{faxFee}</span>;
                }
            },
            {
                id: "essentials_customerToSnackpassFees",
                header: () => (
                    <div>
                        Customer → Snackpass Fees
                        <ReportsTooltip
                            body={
                                "Any fees charged directly to your customer by Snackpass. An example of this are customer fees on third party delivery orders, which allow us to keep restaurant costs low."
                            }
                        />
                    </div>
                ),
                accessor: "attributes.customerToSnackpassFees",
                cell: (props: CellProps) => {
                    const customerToSnackpassFees = toDollarFormatted(
                        props.row.original.customerToSnackpassFees
                    );

                    return <span>{customerToSnackpassFees}</span>;
                }
            },
            {
                id: "essentials_storeCredit",
                header: () => (
                    <div>
                        Store Credit
                        <ReportsTooltip body="The amount of store credit redeemed by customers in this time period." />
                    </div>
                ),

                accessor: "storeCredit",

                cell: (props: CellProps) => {
                    const storeCredit = toDollarFormatted(
                        props.row.original.storeCredit
                    );

                    return <span>{storeCredit}</span>;
                }
            },
            {
                id: "essentials_giftCardCredit",
                header: () => (
                    <div>
                        Gift Card Credit
                        <ReportsTooltip body="The amount of gift card credit redeemed by customers in this time period." />
                    </div>
                ),

                accessor: "giftCardCredit",

                cell: (props: CellProps) => {
                    const giftCardCredit = toDollarFormatted(
                        props.row.original.giftCardCredit
                    );

                    return <span>{giftCardCredit}</span>;
                }
            },
            {
                id: "essentials_expectedPayout",
                header: () => (
                    <div>
                        Estimated Payout
                        <ReportsTooltip
                            body={
                                "The amount of money you should expect to be paid into your bank account for sales occurring in this time period." +
                                "May differ from actual payout due to changes (like refunds) performed after the payout has been made."
                            }
                        />
                    </div>
                ),
                accessor: "attributes.expectedPayout",
                cell: (props: CellProps) => {
                    const expectedPayout = toDollarFormatted(
                        props.row.original.expectedPayout
                    );

                    return <span>{expectedPayout}</span>;
                }
            },
            {
                id: "essentials_expectedCashCollected",
                header: () => (
                    <div>
                        Expected Cash Collected
                        <ReportsTooltip
                            body={
                                "The amount of money we expect you to have accepted in cash in your store for sales occurring in this time period."
                            }
                        />
                    </div>
                ),
                accessor: "attributes.expectedCashCollected",
                cell: (props: CellProps) => {
                    const expectedCashCollected = toDollarFormatted(
                        props.row.original.expectedCashCollected
                    );

                    return <span>{expectedCashCollected}</span>;
                }
            },
            ...(isUserTeam
                ? [
                      {
                          id: "essentials_actualPayout",
                          header: () => (
                              <div>
                                  Actual Payout
                                  <ReportsTooltip
                                      body={
                                          "The amount of money you were paid into your bank account for sales occurring in this time period."
                                      }
                                  />
                              </div>
                          ),
                          accessor: "attributes.actualPayout",
                          cell: (props: CellProps) => {
                              const actualPayout = toDollarFormatted(
                                  props.row.original.actualPayout || 0
                              );

                              return <span>{actualPayout}</span>;
                          }
                      }
                  ]
                : [])
        ],
        [granularity, timezone]
    );

    if (isLoading) return <Skeleton className="h-72 w-full" />;

    return (
        <DataTable
            showPagination
            columns={columns}
            data={data}
            defaultPinned={["date", "startDate", "endDate"]}
            footerElement={
                <span className="text-small text-neutral-600">
                    Updated {updatedTime}
                </span>
            }
        />
    );
};

type Props = {
    granularity: ReportGranularity;
};

type CellProps = {
    row: {
        original: PurchaseSummaryData;
    };
};
