import React, { useContext, useMemo, useState } from "react";
import { Moment } from "moment";
import Skeleton from "react-loading-skeleton";
import { BarChart, BarChartProps, Color, Legend } from "@tremor/react";

import { ReportsContext } from "#app/reports-context-provider";
import {
    aggregateDatedRows,
    datePointsFromRangeAndGranularity,
    formatNumber,
    formatRange,
    getGraphGranularity,
    rangePresets,
    toDollarFormatted
} from "#reports/sales-summary/lib";
import {
    GranularityType,
    SalesByChannelGroup,
    TableSummaryDataRow
} from "#reports/sales-summary/types";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import {
    ChartTypeSelect,
    ChartType
} from "#reports/sales-summary/shared-components/ChartTypeSelect";
import { calculateChartWidth } from "#utils/helpers";

const COLORS: Color[] = ["blue", "yellow"];

const LocationSalesWChannelByPeriodChart = () => {
    const { reportsState } = useContext(ReportsContext);
    const { dateRanges, locationReportsData, granularity } = reportsState;

    const formatDate = (date: Moment) =>
        highestSuitableGranularity == GranularityType.MONTHLY
            ? date.format("MMM YYYY")
            : date.format("MMM D");

    // Attempts to adhere to the granularity that the user selects, unless it results in too few data points.
    // This is used as the granularity for the chart as a whole.
    const highestSuitableGranularity = useMemo(
        () => getGraphGranularity(dateRanges[0], granularity),
        [dateRanges, granularity]
    );

    const labels = useMemo(
        () =>
            datePointsFromRangeAndGranularity(
                dateRanges[0],
                highestSuitableGranularity
            ).map((e) => formatDate(e)),
        [dateRanges, highestSuitableGranularity]
    );

    const aggregateSnackpassData = (
        rows: TableSummaryDataRow[] | undefined
    ) => {
        if (!rows) return null;

        const aggregateSummaryDataRows = (
            acc: TableSummaryDataRow,
            toAdd: TableSummaryDataRow
        ) => {
            for (const key in toAdd) {
                if ((acc[key] as SalesByChannelGroup).total) {
                    const dataColumn = acc[key] as SalesByChannelGroup;
                    const columnToAggregate = toAdd[key] as SalesByChannelGroup;

                    dataColumn.total += columnToAggregate.total;
                    dataColumn.snackpass += columnToAggregate.snackpass;
                    dataColumn.thirdParty += columnToAggregate.snackpass;
                }
            }
            return acc;
        };

        return aggregateDatedRows(
            rows,
            aggregateSummaryDataRows,
            highestSuitableGranularity
        );
    };

    const [graphType, setGraphType] = useState(ChartType.NET_SALES);

    const data = useMemo(
        () =>
            aggregateSnackpassData(
                locationReportsData?.salesReportAggregateData
            ),
        [locationReportsData, highestSuitableGranularity]
    );

    const valueFormatter =
        graphType == ChartType.NET_SALES ? toDollarFormatted : formatNumber;

    const chartData = useMemo(() => {
        if (!data) return [];
        return data.map((e, idx) => ({
            label: labels[idx],

            snackpass:
                graphType == ChartType.NET_SALES
                    ? e.netSales.snackpass
                    : e.orders.snackpass,

            thirdParty:
                graphType == ChartType.NET_SALES
                    ? e.netSales.thirdParty
                    : e.orders.thirdParty,

            totalNetSales: e.netSales.total,
            snackpassNetSales: e.netSales.snackpass,
            thirdPartyNetSales: e.netSales.thirdParty,

            totalOrders: e.orders.total,
            snackpassOrders: e.orders.snackpass,
            thirdPartyOrders: e.orders.thirdParty
        }));
    }, [data, graphType]);

    const getRangeLabel = (range: Moment[]) => {
        const label = rangePresets.find(
            (e) =>
                range[0].isSame(e.value[0]) &&
                range[1].isSameOrBefore(e.value[1])
        )?.label;
        return label ? label : formatRange(range, highestSuitableGranularity);
    };

    const formatChartTitle = () => `${getRangeLabel(dateRanges[0])} `;

    const yWidth = (): number => {
        const maxNetSales = Math.max(
            ...chartData.map((item) => item.totalNetSales)
        );
        return calculateChartWidth(maxNetSales, true);
    };

    return (
        <div className="my-10">
            <div className="flex items-center justify-between">
                <h4 className="mb-2 text-large">{`Total Location Sales ${formatChartTitle()}`}</h4>
                <ChartTypeSelect value={graphType} onChange={setGraphType} />
            </div>
            <div className="mb-6">
                <Legend
                    categories={["Snackpass", "Third Party"]}
                    colors={COLORS}
                    className="mb-3 p-0"
                />
            </div>
            <div className="h-96 w-full">
                {!locationReportsData?.salesReportAggregateDataLoading &&
                (locationReportsData?.salesReportAggregateData ||
                    locationReportsData?.salesReportAggregateDataFailed) ? (
                    locationReportsData?.salesReportAggregateData ? (
                        <BarChart
                            data={chartData}
                            colors={COLORS}
                            categories={["snackpass", "thirdParty"]}
                            index={"label"}
                            customTooltip={CustomTooltip}
                            valueFormatter={valueFormatter}
                            stack={true}
                            showLegend={false}
                            yAxisWidth={yWidth()}
                        />
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

const CustomTooltip: BarChartProps["customTooltip"] = ({
    payload,
    active,
    label
}) => {
    if (!active || !payload || !payload[0]) return null;

    const info = payload[0].payload;
    return (
        <div className="flex w-56 flex-col space-y-2 rounded-md border border-neutral-200 bg-neutral-50 p-2 text-small shadow-tremor-dropdown">
            <p className="font-semibold">{label}</p>

            <div className="flex flex-1 space-x-2.5">
                <div className={"flex w-1 flex-col rounded bg-yellow-500"} />
                <div className="flex w-full justify-between">
                    <div className="text-neutral-600">Third Party</div>
                    <div>
                        <div className="ml-2 font-medium text-neutral-800">
                            {toDollarFormatted(info.thirdPartyNetSales)}
                        </div>
                        <div className="ml-2 font-medium text-neutral-800">
                            {formatNumber(info.thirdPartyOrders)} orders
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 space-x-2.5">
                <div className={"flex w-1 flex-col rounded bg-blue-500"} />
                <div className="flex w-full justify-between">
                    <div className="text-neutral-600">Snackpass</div>
                    <div>
                        <div className="ml-2 font-medium text-neutral-800">
                            {toDollarFormatted(info.snackpassNetSales)}
                        </div>
                        <div className="ml-2 font-medium text-neutral-800">
                            {formatNumber(info.snackpassOrders)} orders
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 space-x-2.5">
                <div className={"flex w-1 flex-col rounded bg-neutral-500"} />
                <div className="flex w-full justify-between">
                    <div className="text-neutral-600">Total</div>
                    <div>
                        <div className="ml-2 font-medium text-neutral-800">
                            {toDollarFormatted(info.totalNetSales)}
                        </div>
                        <div className="ml-2 font-medium text-neutral-800">
                            {formatNumber(info.totalOrders)} orders
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationSalesWChannelByPeriodChart;
