import { Menu as MultiMenu, MenuOverrides } from "@snackpass/snackpass-types";
import { difference, omit } from "lodash";

export type UpdateOverridesPayload = {
    [key: string]: {
        storeId: string;
        overrides: MenuOverrides;
    };
};

export type RemoveAddons = {
    [key: string]: string[];
};

export type NewAddons = {
    [key: string]: { addonIds: string[] };
};

export type NewAddonGroups = {
    addonGroupId: string;
    addonIds: string[];
}[];

export const addNewAddonsToMenus = (
    storeMenus: MultiMenu[],
    newAddons: NewAddons,
    updateOverridesPayload: UpdateOverridesPayload
) => {
    Object.entries(newAddons).forEach(([addonGroupId, addons]) => {
        if (addons.addonIds.length > 0) {
            storeMenus.forEach((menu) => {
                if (menu.menuOverrides.addonGroups?.[addonGroupId]) {
                    const newOverride = {
                        [addonGroupId]: {
                            addons: [
                                ...menu.menuOverrides.addonGroups[addonGroupId]
                                    .addons,
                                ...addons.addonIds
                            ]
                        }
                    };
                    if (!updateOverridesPayload[menu.id]) {
                        updateOverridesPayload = {
                            ...updateOverridesPayload,
                            [menu.id]: {
                                storeId: menu.storeId,
                                overrides: { ...menu.menuOverrides }
                            }
                        };
                    }
                    updateOverridesPayload[menu.id].overrides.addonGroups = {
                        ...updateOverridesPayload[menu.id].overrides
                            .addonGroups,
                        ...newOverride
                    };
                }
            });
        }
    });
    return updateOverridesPayload;
};

export const addNewAddonGroupsToMenus = (
    storeMenus: MultiMenu[],
    newAddonGroups: NewAddonGroups,
    productId: string,
    updateOverridesPayload: UpdateOverridesPayload
) => {
    storeMenus.forEach((menu) => {
        newAddonGroups?.forEach((newAddonGroup) => {
            const isInMenu = menu.categories.find((category) =>
                category.productIds.includes(productId)
            );
            if (isInMenu) {
                if (!updateOverridesPayload[menu.id]) {
                    updateOverridesPayload = {
                        ...updateOverridesPayload,
                        [menu.id]: {
                            storeId: menu.storeId,
                            overrides: { ...menu.menuOverrides }
                        }
                    };
                }
                updateOverridesPayload[menu.id].overrides.addonGroups = {
                    ...updateOverridesPayload[menu.id].overrides.addonGroups,
                    [newAddonGroup.addonGroupId]: {
                        addons: newAddonGroup.addonIds
                    }
                };
            }
        });
    });
    return updateOverridesPayload;
};

export const removeAddonsFromMenus = (
    storeMenus: MultiMenu[],
    deletedAddons: RemoveAddons,
    updateOverridesPayload: UpdateOverridesPayload
) => {
    Object.entries(deletedAddons).forEach(([addonGroupId, addons]) => {
        if (addons.length > 0) {
            storeMenus.forEach((menu) => {
                if (menu.menuOverrides.addonGroups?.[addonGroupId]) {
                    if (!updateOverridesPayload[menu.id]) {
                        updateOverridesPayload = {
                            ...updateOverridesPayload,
                            [menu.id]: {
                                storeId: menu.storeId,
                                overrides: { ...menu.menuOverrides }
                            }
                        };
                    }

                    updateOverridesPayload[menu.id].overrides.addons = omit(
                        updateOverridesPayload[menu.id].overrides.addons,
                        addons
                    );

                    const selectedAddons =
                        updateOverridesPayload[menu.id].overrides.addonGroups?.[
                            addonGroupId
                        ];

                    if (selectedAddons && selectedAddons.addons) {
                        updateOverridesPayload[menu.id].overrides.addonGroups =
                            {
                                ...updateOverridesPayload[menu.id].overrides
                                    .addonGroups,
                                ...{
                                    [addonGroupId]: {
                                        addons: difference(
                                            selectedAddons.addons,
                                            addons
                                        )
                                    }
                                }
                            };
                    }
                }
            });
        }
    });
    return updateOverridesPayload;
};

export const removeAddonGroupFromMenus = (
    storeMenus: MultiMenu[],
    productId: string,
    deleteAddonGroups: string[],
    updateOverridesPayload: UpdateOverridesPayload
) => {
    storeMenus.forEach((menu) => {
        const isInMenu = menu.categories.find((category) =>
            category.productIds.includes(productId)
        );
        if (isInMenu) {
            deleteAddonGroups.forEach((addonGroupId) => {
                if (menu.menuOverrides.addonGroups?.[addonGroupId]) {
                    if (!updateOverridesPayload[menu.id]) {
                        updateOverridesPayload = {
                            ...updateOverridesPayload,
                            [menu.id]: {
                                storeId: menu.storeId,
                                overrides: { ...menu.menuOverrides }
                            }
                        };
                    }
                    updateOverridesPayload[menu.id].overrides.addons = omit(
                        updateOverridesPayload[menu.id].overrides.addons,
                        menu.menuOverrides.addonGroups[addonGroupId].addons
                    );

                    const addonGroupsOverrides =
                        updateOverridesPayload[menu.id].overrides.addonGroups;
                    if (addonGroupsOverrides) {
                        const {
                            [addonGroupId]: removedProperty,
                            ...updatedAddonGroups
                        } = addonGroupsOverrides;

                        updateOverridesPayload[menu.id].overrides.addonGroups =
                            updatedAddonGroups;
                    }
                }
            });
        }
    });
    return updateOverridesPayload;
};
