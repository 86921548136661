import {
    IStore,
    SnackpassConvenienceFeeStrategy
} from "@snackpass/snackpass-types";

import { OperatingFormValues } from "#settings/settings-fees/utils/types";

export const operatingFormDefaults = (store: IStore): OperatingFormValues => {
    const appPolicy = store.feePolicies?.find((p) =>
        p.rules?.transactionSources?.includes("app")
    );
    const kioskPolicy = store.feePolicies?.find((p) =>
        p.rules?.transactionSources?.includes("kiosk")
    );
    const onlinePolicy = store.feePolicies?.find((p) =>
        p.rules?.transactionSources?.includes("online")
    );

    return {
        appFlat: appPolicy ? appPolicy.flat : 0,
        appPercent: appPolicy ? appPolicy.percent : 0,
        appStrategy: appPolicy?.strategy
            ? appPolicy.strategy
            : SnackpassConvenienceFeeStrategy.FlatAndPercent,
        kioskFlat: kioskPolicy ? kioskPolicy.flat : 0,
        kioskPercent: kioskPolicy ? kioskPolicy.percent : 0,
        kioskStrategy: kioskPolicy?.strategy
            ? kioskPolicy.strategy
            : SnackpassConvenienceFeeStrategy.FlatAndPercent,
        onlineFlat: onlinePolicy ? onlinePolicy.flat : 0,
        onlinePercent: onlinePolicy ? onlinePolicy.percent : 0,
        onlineStrategy: onlinePolicy?.strategy
            ? onlinePolicy.strategy
            : SnackpassConvenienceFeeStrategy.FlatAndPercent
    };
};
