import { TWILIO_BUSINESS_TYPES } from "@snackpass/snackpass-types";
import { useFormContext } from "react-hook-form";

import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "src/@/components/ui/form";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "src/@/components/ui/select";
import SharedBrandRegistrationEINField from "#guestbook/screens/Campaigns/CampaignBrandRegistration/SharedBrandRegistrationSMSForm/SharedBrandRegistrationEINField";
import CampaignCardContent from "#guestbook/shared-components/CampaignCardContent";

function BusinessInfoCardInput() {
    const { control } = useFormContext();
    return (
        <CampaignCardContent>
            <FormLabel>
                <p className="text-xl font-semibold">Business Info</p>
            </FormLabel>
            <FormField
                control={control}
                name="businessType"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>
                            <p className="pt-6 font-semibold">Business Type</p>
                        </FormLabel>
                        <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                        >
                            <FormControl>
                                <SelectTrigger>
                                    <SelectValue placeholder="Select a Business Type" />
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                                {TWILIO_BUSINESS_TYPES.map((type) => (
                                    <SelectItem value={type} key={type}>
                                        {type}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <SharedBrandRegistrationEINField />
        </CampaignCardContent>
    );
}

export default BusinessInfoCardInput;
