import { useAppSelector } from "src/redux/hooks";
import { getUserFeeSettingsPermission } from "src/redux/selectors";
import { Select } from "src/@/components/ui/select";

export const FeeSelect = ({
    onChange,
    defaultValue,
    children,
    disabled = false
}: {
    onChange: (v: string) => void;
    defaultValue: string;
    children: React.ReactNode;
    disabled?: boolean;
}) => {
    const writeAccess = useAppSelector(getUserFeeSettingsPermission);
    return (
        <Select
            disabled={!writeAccess || disabled}
            onValueChange={onChange}
            defaultValue={defaultValue}
        >
            {children}
        </Select>
    );
};
