import {
    TWILIO_BUSINESS_TYPES,
    TWILIO_JOB_POSITIONS
} from "@snackpass/snackpass-types";
import { SegmentedMessage } from "sms-segments-calculator";
import { z } from "zod";

export const EIN_FORMAT = "XX-XXXXXXX";

function validatePhoneNumber(p: string) {
    const phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    const digits = p.replace(/\D/g, "");
    return phoneRe.test(digits);
}

const EIN_VALIDATOR = z
    .string()
    .regex(/^[0-9]{2}-?[0-9]{7}$/, `EIN must match format ${EIN_FORMAT}`);

const DISPLAY_NAME_VALIDATOR = z
    .string()
    .min(3, "Input a recognizable display name")
    .max(24, "Maximum of 24 characters")
    .refine(
        (data) => new SegmentedMessage(data).getNonGsmCharacters().length === 0,
        (data) => {
            const badCharacters = new SegmentedMessage(
                data
            ).getNonGsmCharacters();
            return {
                message: `Please remove the following characters: ${badCharacters.join(
                    ", "
                )}.`
            };
        }
    );

const DESIRED_AREA_CODE_VALIDATOR = z
    .string()
    .length(3, "Area code must be 3 digits")
    .regex(/^[2-9]\d\d/, `Area code cannot start with a 1`);

// Keep formSchema in sync with snackpass-server src/modules/messaging/surfaces/express/validators.ts
export const campaignBrandRegistrationSMSFormSchema = z.object({
    businessName: z.string().min(3, "Input a valid business name"),
    displayName: DISPLAY_NAME_VALIDATOR,
    address: z.object({
        line1: z.string().min(3, "Input a valid address"),
        line2: z.string().optional(),
        city: z.string().min(3, "Input a valid city"),
        state: z.string().length(2, "Input a valid state abbreviation"),
        zip: z
            .string()
            .length(5, "Postal Code must be 5 digits")
            .regex(/^[0-9]*$/, "Postal Code can only be digits")
    }),
    businessType: z.enum(TWILIO_BUSINESS_TYPES, {
        required_error: "Select a business type"
    }),
    ein: EIN_VALIDATOR,
    contactInfo: z.object({
        businessTitle: z.string().min(2, "Input a valid title"),
        jobPosition: z.enum(TWILIO_JOB_POSITIONS, {
            required_error: "Select a job position"
        }),
        firstName: z.string().min(2, "Input a valid name"),
        lastName: z.string().min(2, "Input a valid name"),
        email: z.string().email("Input a valid email"),
        phoneNumber: z
            .string()
            .refine(
                (s) => validatePhoneNumber(s),
                "Please enter a valid phone number."
            )
    }),
    desiredAreaCode: DESIRED_AREA_CODE_VALIDATOR,
    reviewedInfo: z.boolean(),
    agreedToPricing: z.boolean(),
    hasPaymentMethod: z.boolean()
});

export const SharedTwilioBrandFormSchema = z.object({
    foreignStoreId: z.string(),
    desiredAreaCode: z.union([z.literal(""), DESIRED_AREA_CODE_VALIDATOR]), // This store would reuse the shared brand's phone number if empty string
    displayName: DISPLAY_NAME_VALIDATOR,
    ein: EIN_VALIDATOR,
    agreedToPricing: z.boolean(),
    hasPaymentMethod: z.boolean()
});

export const ShareTwilioBrandCheckSchema = z.object({
    foreignStoreId: z.string(),
    ein: EIN_VALIDATOR,
    hasPaymentMethod: z.boolean()
});
