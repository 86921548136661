import { HTMLAttributes } from "react";
import { Merge } from "type-fest";

import {
    Campaign,
    CampaignDeliveryStatus
} from "src/api/graphql/generated/types";
import { cn } from "src/@/lib/utils";

export function campaignDeliveryStatusDisplay({
    deliveryStatus
}: Pick<Campaign, "deliveryStatus">): string {
    switch (deliveryStatus) {
        case CampaignDeliveryStatus.Finished:
            return "Delivered";
        case CampaignDeliveryStatus.InProgress:
            return "In Progress";
        // Instead of using a default case, we can use a case for each possible value,
        // compiler will throw an error if we forget to handle a value
        case undefined:
        case null:
            return "Pending";
    }
}

type StatusPillProps = Merge<
    HTMLAttributes<HTMLSpanElement>,
    Pick<Campaign, "deliveryStatus">
>;

export function DeliveryStatusPill({
    deliveryStatus,
    className,
    ...rest
}: StatusPillProps) {
    const deliveryStatusDisplay = campaignDeliveryStatusDisplay({
        deliveryStatus
    });
    let pillColors: string;
    switch (deliveryStatus) {
        case CampaignDeliveryStatus.Finished:
            pillColors = "bg-success-subtle-light text-success-a11y-light";
            break;
        default:
            pillColors = "bg-neutral-200 text-neutral-950";
            break;
    }
    return (
        <span
            {...rest}
            className={cn(
                "rounded-full px-2 py-1 font-medium",
                pillColors,
                className
            )}
        >
            {deliveryStatusDisplay}
        </span>
    );
}
