import React, { useEffect, useState } from "react";
import { IProduct, IStore } from "@snackpass/snackpass-types";
import { useSelector } from "react-redux";

import { getActiveStore, getLegacyProducts } from "src/redux/selectors";

import Card from "./picture-menu-card";

type ProductEditProps = {
    onShow: () => void;
    setProductInEdit: (value: IProduct) => void;
};

const PictureMenu: React.FC<ProductEditProps> = ({
    onShow,
    setProductInEdit
}) => {
    const products = useSelector(getLegacyProducts) as IProduct[];
    const activeStore = useSelector(getActiveStore) as IStore;
    const [sortedProducts, setSorted] = useState<IProduct[]>([]);

    const setSortedPictures = (productIds: string[]) => {
        productIds = Array.from(new Set(productIds)); // remove duplicates
        if (productIds?.length > 0) {
            const sortedData = productIds.map(
                (id) =>
                    products.find((product) => product._id === id) as IProduct
            );
            setSorted(sortedData.filter((product) => product !== undefined));
        } else {
            const sortedData: IProduct[] = [];
            products.forEach((product: IProduct) => {
                if (product.image) sortedData.push(product as IProduct);
            });
            setSorted(sortedData.filter((product) => product !== undefined));
        }
    };

    const PictureMenuComponent = (
        <Cards
            sortedProducts={sortedProducts}
            onShow={onShow}
            setProductInEdit={setProductInEdit}
        />
    );

    useEffect(() => {
        if (activeStore && products.length > 0) {
            const productIds: string[] = activeStore.pictureMenuProductIds;
            setSortedPictures(productIds);
        }
    }, [products, activeStore]);

    return sortedProducts.length === 0 ? null : PictureMenuComponent;
};

type CardsProps = {
    sortedProducts: IProduct[];
    onShow: () => void;
    setProductInEdit: (value: IProduct) => void;
};

const Cards = ({ sortedProducts, onShow, setProductInEdit }: CardsProps) => {
    const renderCard = (item: IProduct, i: number) => {
        if (!item) return null;
        return (
            <Card
                key={item._id}
                index={i}
                item={item}
                data={sortedProducts}
                onShow={onShow}
                setProductInEdit={setProductInEdit}
            />
        );
    };

    return (
        <div
            style={{
                display: "flex",
                alignSelf: "stretch",
                flexDirection: "row",
                overflowX: "scroll"
            }}
        >
            {sortedProducts.map(renderCard)}
        </div>
    );
};

export default PictureMenu;
