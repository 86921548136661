/** @jsxImportSource @emotion/react */
import React, { useContext } from "react";
import { AddonGroup } from "@snackpass/snackpass-types";
import styled from "styled-components";

import "antd/dist/antd.css";
import { Divider, Drawer } from "antd";
import { SystemColors } from "@snackpass/design-system";

import constants from "#core/constants";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-x.svg";

import {
    ProductEditSharedContext,
    ProductNewSharedContext
} from "../helpers/context";

type ModifierBottomDrawerType = {
    isProductInEditMode: boolean;
};

const ModifierBottomDrawer: React.FC<ModifierBottomDrawerType> = ({
    isProductInEditMode
}) => {
    const {
        setShowNewModifierForm,
        modifierGroups,
        showBottomDrawer,
        setShowBottomDrawer,
        setModifierGroups,
        setIsModifierGroupInEdit,
        modifierGroupsOptions
    } = useContext(
        isProductInEditMode ? ProductEditSharedContext : ProductNewSharedContext
    );
    const { width } = useWindowDimensions();

    const getDrawerStyle = () => {
        const style: React.CSSProperties = {
            position: "absolute",
            left: width < constants.MOBILE_MAX_WIDTH ? "0" : "50%",
            right: "0",
            width: width < constants.MOBILE_MAX_WIDTH ? width : width / 2
        };
        if (showBottomDrawer) {
            style.transform = undefined;
        }
        return style;
    };

    const handleCreateAddonGroup = (item: {
        label: string;
        addonGroup: AddonGroup;
    }) => {
        if (!item) return;
        if (setModifierGroups && modifierGroups) {
            setModifierGroups([...modifierGroups, item.addonGroup]);
        }
    };

    return showBottomDrawer ? (
        <Drawer
            placement="bottom"
            height={width < constants.MOBILE_MAX_WIDTH ? 500 : 1000}
            style={getDrawerStyle()}
            onClose={() => {
                setShowBottomDrawer(false);
            }}
            open={showBottomDrawer}
            destroyOnClose={true}
            closable={false}
            forceRender={true}
            zIndex={1001}
        >
            <Body>
                <HeaderRow>
                    <span className="header-text">
                        <CloseIcon
                            fill={SystemColors.v1.sesame}
                            className="clickable-icon"
                            onClick={() => setShowBottomDrawer(false)}
                        />
                        <span className="head-text-span">
                            Add Modifier Group
                        </span>
                    </span>
                </HeaderRow>
                <NewModifiers
                    onClick={() => {
                        setShowBottomDrawer(false);
                        setIsModifierGroupInEdit(false);
                        setShowNewModifierForm(true);
                    }}
                >
                    <span className="new-modifier-group">
                        New Modifier Groups
                    </span>
                    <span className="add">+</span>
                </NewModifiers>
                <Divider />
                {modifierGroupsOptions.map((modifierGroup, i) => (
                    <ModifierGroupsWrapper
                        key={i}
                        onClick={() => {
                            setShowBottomDrawer(false);
                            handleCreateAddonGroup(modifierGroup);
                        }}
                    >
                        <NewModifiers>{modifierGroup.label}</NewModifiers>
                        <Divider />
                    </ModifierGroupsWrapper>
                ))}
            </Body>
        </Drawer>
    ) : null;
};

const Body = styled.div`
    width: 100%;
    overflow-x: "auto";
    padding-bottom: 10rem;
    position: relative;
    font-family: "Inter" !important;

    .add {
        font-size: 2rem;
        grid-column: 3;
        padding-left: 1.5rem;
        grid-row: 1;
        cursor: pointer;
    }
    .new-modifier-group {
        grid-column: 1;
        grid-row: 1;
        cursor: pointer;
    }
`;

const HeaderRow = styled.div`
    .header-text {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        cursor: pointer;
        text-align: center;
    }

    .head-text-span {
        align-self: center;
        flex: 1;
    }
    .clickable-icon {
        cursor: pointer;
    }
`;

const NewModifiers = styled.div`
    font-size: 20px;
    font-family: "Inter";
    display: grid;
    grid-template-columns: auto 1fr 60px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-top: 1.5rem;
`;

const ModifierGroupsWrapper = styled.div`
    margin-top: 1rem;
`;

export default ModifierBottomDrawer;
