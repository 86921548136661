import React from "react";

import { DeviceType } from "#devices/utils/deviceTypes";
import EditSection from "#devices/components/EditDrawer/EditSection";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";
import { DeleteSection } from "#devices/components/EditDrawer/DeleteSection";
import { EditDeviceName } from "#devices/components/EditDrawer/Settings";
import { EditDeviceType } from "#devices/components/EditDrawer/Settings/DeviceType";
import { ToggleField } from "#devices/components/EditDrawer/Settings/ToggleField";

const deviceTypeOptions = [DeviceType.OrderHub].map((option) => ({
    value: option,
    label: getDeviceTypeName(option) || "--"
}));

export default (): JSX.Element => (
    <>
        <EditSection heading="Info">
            <EditDeviceName />
            <EditDeviceType disabled options={deviceTypeOptions} />
        </EditSection>

        <EditSection heading="Settings">
            <ToggleField
                id="silentModeToggle"
                label="Silent Mode"
                description="Order hub will not ring when receiving new orders"
                field="silentMode"
            />
        </EditSection>

        <EditSection heading="Danger Zone">
            <DeleteSection />
        </EditSection>
    </>
);
