import { isMobile } from "react-device-detect";

export default {
    // Prefix of project name required for Sentry
    version: "restaurant-dashboard@2.0",
    navBarWidth: isMobile ? 0 : 75,

    DEFAULT_POS_PICKUP_TIMES: ["0", "2", "5", "8", "10", "15", "20", "25"],
    DEFAULT_POS_DELIVERY_TIMES: [
        "15",
        "20",
        "25",
        "30",
        "45",
        "60",
        "75",
        "90"
    ],
    DELAY_OPTIONS: [2, 5, 8, 10, 15],
    DESKTOP_MIN_WIDTH: 992,
    TABLET_MIN_WIDTH: 768,
    TABLET_MAX_WIDTH: 991,
    MOBILE_MAX_WIDTH: 767,
    HOGWARTS_STORE_ID: "5b4a618f326bd9777aa5dc90",

    REQUEST_CHANGE_MENU_FORM: "https://airtable.com/shrq5LUHPOSyccYoh",

    NEW_MENU_EDITOR_TUTORIAL: "https://vimeo.com/showcase/9988035",

    ESPER_ID_NAMESPACE: "TTY-NYN-",
    DEFAULT_ATTRACT_SCREEN_URL:
        "https://d3h3ny262c73zf.cloudfront.net/Hogwarts/DEFAULT_KIOSK_SPLASH.mp4",
    kioskSplashVideoRequirementText: [
        "Must be a video",
        "50 mb or less file size"
    ],
    kioskSplashVideoRecommendationText: [
        "1920x1080 or 1080x1920 resolution",
        "MP4 file type",
        "Show “Tap to Order” text on the screen",
        "If your kiosk is in vertical mode, please upload a vertical video to avoid black bars on top and bottom"
    ]
};
