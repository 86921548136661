import { Spinner } from "react-activity";
import { useCallback, useEffect, useState } from "react";
import { toast } from "sonner";
import { AxiosError } from "axios";

import { Button } from "src/@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle
} from "src/@/components/ui/dialog";
import { SeniorSupportUser } from "#settings/settings-senior-support/utils";
import api from "src/api/rest";
import CheckBox from "#settings/settings-senior-support/components/checkbox";

type Props = {
    user: SeniorSupportUser | null;
    isVisible: boolean;
    canEdit: boolean;
    onClose: () => void;
    onSubmit: (u: SeniorSupportUser) => void;
};

export const UserEditPopover = ({
    user,
    isVisible,
    canEdit,
    onClose,
    onSubmit
}: Props) => {
    const [isChecked, setIsChecked] = useState(user?.isSeniorSupport ?? false);
    const [hasChange, setHasChange] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleUpdate = useCallback(async () => {
        if (user) {
            setIsSubmitting(true);
            try {
                const response = await api.users.updateEmployee(user.id, {
                    isSeniorSupport: isChecked
                });
                onSubmit(response.data.user);
                _reset();
            } catch (e) {
                const message =
                    e instanceof AxiosError
                        ? e.response?.data.message
                        : "Unable to save";
                toast.error(message);
            } finally {
                setIsSubmitting(false);
            }
        }
    }, [user, isChecked, onSubmit]);

    const handleChange = () => {
        setIsChecked(!isChecked);
        setHasChange(true);
    };

    const _reset = (c = false) => {
        setIsChecked(c);
        setHasChange(false);
        setIsSubmitting(false);
    };

    useEffect(() => {
        if (user?.id) {
            setIsChecked(user.isSeniorSupport);
            setHasChange(false);
            setIsSubmitting(false);
        }
    }, [user?.id]);

    const disableSave =
        !canEdit || !hasChange || user?.isSeniorSupport === isChecked;

    if (!isVisible) {
        return null;
    }

    return (
        <Dialog
            open={isVisible}
            onOpenChange={(open: boolean) => {
                if (!open) {
                    onClose();
                    _reset();
                }
            }}
        >
            <DialogContent className={"overflow-y-scroll bg-white"}>
                <DialogHeader className="w-full border-b border-neutral-400">
                    <DialogTitle className="mb-6 text-body">
                        Edit User
                    </DialogTitle>
                </DialogHeader>
                <div>
                    <div>
                        {user?.firstName} {user?.lastName}
                    </div>
                    <div className="italic text-gray-600">{user?.email}</div>
                </div>
                <CheckBox
                    title="Senior Support"
                    onClick={handleChange}
                    isChecked={isChecked}
                />
                <div className="sticky flex flex-col bg-white">
                    <div className="flex justify-around gap-4">
                        <Button
                            variant="outline"
                            className="flex-1"
                            type="submit"
                            onClick={() => _reset(user?.isSeniorSupport)}
                        >
                            Reset
                        </Button>
                        <Button
                            className="flex-1 text-white"
                            type="submit"
                            disabled={disableSave}
                            onClick={handleUpdate}
                        >
                            {isSubmitting ? <Spinner size={10} /> : "Save"}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};
