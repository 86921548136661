import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TimePicker, Tooltip } from "antd";
import type { Moment } from "moment";
import moment from "moment";
import { IHoursSchema } from "@snackpass/snackpass-types";
import _ from "lodash";
import { toast } from "sonner";

import DropDownSelect from "#reusable/select/dropdown";
import { ReactComponent as Plus } from "src/assets/icons/plus.svg";
import { ReactComponent as DeleteRed } from "src/assets/icons/delete-red.svg";
import useWindowDimensions from "#hooks/use-window-dimensions";
import constants from "#core/constants";
import "antd/dist/antd.css";

import {
    DAY_OPTIONS,
    defaultEndTime,
    defaultStartTime,
    format12,
    SpecialHoursItemType
} from "#reusable/special-hours/helper";
import colors from "#reusable/colors/colors.json";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`;

const DropDownSelectWrapper = styled.div`
    flex: 1;
    max-width: 12rem;
    height: 40px;
`;

const DashWrapper = styled.div`
    display: flex;
    height: 36px;
    justify-content: center;
    align-items: center;
    padding: 0px 5px;
`;

const IconsSection = styled.div`
    display: flex;
`;

type SelectedDayOfWeekOptionType = {
    label: string;
    value: number;
};

type IconProps = {
    onClick: Function;
};
const IconWrapper = styled.div<IconProps>`
    display: flex;
    margin-left: 0.1rem;
    border: 1px solid ${colors["neutral-400"]};
    height: 36px;
    width: 36px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`;

type HourProps = {
    hours?: IHoursSchema | null;
    show: boolean;
    specialHours: SpecialHoursItemType[];
    setSpecialHours: React.Dispatch<
        React.SetStateAction<SpecialHoursItemType[]>
    >;
    removeItem: (id: number) => void;
    updateItemHours: (
        idx: number,
        start?: Moment | null,
        end?: Moment | null
    ) => void;
    id: number;
    dayOfWeek: number;
    start: Moment | null | undefined;
    end: Moment | null | undefined;
    setFieldTouched?: (
        field: string,
        isTouched?: boolean | undefined,
        shouldValidate?: boolean | undefined
    ) => void;
    fieldName: string;
};

export const SpecialHourRow: React.FC<HourProps> = ({
    specialHours,
    setSpecialHours,
    removeItem,
    updateItemHours,
    id,
    dayOfWeek,
    start,
    end,
    setFieldTouched,
    fieldName
}) => {
    const [selectedDay, setSelectedDay] = React.useState(
        DAY_OPTIONS[dayOfWeek]
    );
    const [startTime, setStartTime] = useState<Moment | null | undefined>(null);
    const [endTime, setEndTime] = useState<Moment | null | undefined>(null);
    const { width } = useWindowDimensions();

    useEffect(() => {
        const selectedDay = DAY_OPTIONS.find(
            (option) => option.value === dayOfWeek
        );
        setSelectedDay(selectedDay || DAY_OPTIONS[0]);
        setStartTime(start);
        setEndTime(end);
    }, [specialHours]);

    const handleStartTime = (dateObject: Moment | null): void => {
        setStartTime(moment(dateObject, format12));
        updateItemHours(id, moment(dateObject, format12), endTime);
    };
    const handleEndTime = (dateObject: moment.Moment | null): void => {
        setEndTime(moment(dateObject, format12));
        updateItemHours(id, start, moment(dateObject, format12));
    };

    const handleDuplicate = () => {
        if (_.findIndex(specialHours, (item) => item.dayOfWeek === 7) >= 0) {
            // if everyday already in the special hours, do nothing
            toast.info(
                "You cannot add more hours if everyday is selected, please switch to other days to create different hours for each day"
            );
            return;
        }
        const targetTime = specialHours.find((hour) => hour.id === id)?.time;
        targetTime &&
            setSpecialHours([
                ...specialHours,
                {
                    id: specialHours[specialHours.length - 1].id + 1,
                    dayOfWeek: selectedDay.value,
                    time: targetTime
                }
            ]);
        setFieldTouched && setFieldTouched(fieldName);
    };

    const handleRemove = () => {
        removeItem(id);
        setFieldTouched && setFieldTouched(fieldName);
    };

    const onChange = (selectedOption: SelectedDayOfWeekOptionType) => {
        setSelectedDay(selectedOption);
        if (selectedOption.value === DAY_OPTIONS[7].value) {
            // if everyday selected, remove all week days and apply start and end time to everydays
            const everydaySpecialHours = specialHours.filter(
                (item) => item.id === id
            );
            everydaySpecialHours[0].dayOfWeek = selectedOption.value;
            setSpecialHours(everydaySpecialHours);
        } else {
            setSpecialHours((existingItems: SpecialHoursItemType[]) =>
                existingItems.map((item: SpecialHoursItemType) =>
                    item.id === id
                        ? {
                              ...item,
                              dayOfWeek: selectedOption.value
                          }
                        : item
                )
            );
        }
        setFieldTouched && setFieldTouched(fieldName);
    };

    const desktopLayOut = () => (
        <Row>
            <DropDownSelectWrapper>
                <DropDownSelect
                    mr={3}
                    square
                    placeholder={"Day"}
                    options={DAY_OPTIONS}
                    value={selectedDay}
                    onChange={onChange}
                />
            </DropDownSelectWrapper>
            <TimePicker
                size="large"
                placeholder="Start Time"
                value={startTime}
                format={format12}
                style={{
                    borderRadius: "8px",
                    flex: "1",
                    height: "36px",
                    marginLeft: "4px"
                }}
                onChange={handleStartTime}
                allowClear={false}
            />
            <DashWrapper>-</DashWrapper>
            <TimePicker
                size="large"
                placeholder="End Time"
                value={endTime}
                format={format12}
                style={{
                    borderRadius: "8px",
                    flex: "1",
                    height: "36px",
                    marginRight: "4px"
                }}
                onChange={handleEndTime}
                allowClear={false}
            />

            <IconsSection>
                <IconWrapper onClick={handleDuplicate}>
                    <Tooltip title="Add more hours">
                        <Plus className="h-[12px] w-[12px]" />
                    </Tooltip>
                </IconWrapper>
                <IconWrapper onClick={handleRemove}>
                    <Tooltip title="Delete">
                        <DeleteRed />
                    </Tooltip>
                </IconWrapper>
            </IconsSection>
        </Row>
    );

    const mobileLayout = () => (
        <>
            <Row>
                <DropDownSelect
                    mr={3}
                    square
                    placeholder={"Day"}
                    options={DAY_OPTIONS}
                    value={selectedDay}
                    onChange={onChange}
                />
                <IconsSection>
                    <IconWrapper onClick={handleDuplicate}>
                        <Plus className="h-[12px] w-[12px]" />
                    </IconWrapper>
                    <IconWrapper onClick={handleRemove}>
                        <DeleteRed />
                    </IconWrapper>
                </IconsSection>
            </Row>
            <Row>
                <TimePicker
                    size="large"
                    value={startTime}
                    placeholder="Start Time"
                    defaultValue={defaultStartTime}
                    format={format12}
                    style={{ borderRadius: "8px", flex: "1" }}
                    onChange={handleStartTime}
                    inputReadOnly={true}
                />
                <DashWrapper>-</DashWrapper>
                <TimePicker
                    size="large"
                    placeholder="End Time"
                    value={endTime}
                    format={format12}
                    style={{ borderRadius: "8px", flex: "1" }}
                    defaultValue={defaultEndTime}
                    onChange={handleEndTime}
                    inputReadOnly={true}
                />
            </Row>
        </>
    );

    return width > constants.TABLET_MAX_WIDTH
        ? desktopLayOut()
        : mobileLayout();
};
