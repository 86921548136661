import React from "react";
import styled from "@emotion/styled";

type TitleProps = {
    icon: any;
    label: string;
};

export const Title = ({ icon, label }: TitleProps) => (
    <TitleContainer>
        <img
            src={icon}
            style={{
                width: 15,
                marginRight: 5,
                objectFit: "contain"
            }}
        />
        <TitleLabel>{label}</TitleLabel>
    </TitleContainer>
);

const TitleContainer = styled.div`
    margin: 0;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TitleLabel = styled.div`
    margin: 0;
    font-weight: bold;
    font-size: 18px;
`;
