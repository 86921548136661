import { SystemColors } from "@snackpass/design-system";
import styled from "styled-components";

export const BorderContainer = styled.div`
    border-radius: 8px;
    border: 2px solid ${SystemColors.v1.candy50};
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`;

export const Title = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: ${SystemColors.v1.sesame};
    font-family: Inter;
`;

export const Button = styled.button`
    all: unset;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${SystemColors.v1.candy50};
    color: ${SystemColors.v1.white};
    margin-top: 8px;
    width: calc(100% - 24px);
`;
