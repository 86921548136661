import {
    MAX_GIFTCARD_AMOUNT_CENTS,
    PromoTypes
} from "@snackpass/snackpass-types";
import * as yup from "yup";

import { DISCOUNT_TYPES } from "#promotion/utils/constants";
import { FIELD_NAMES, GiftCardPromo } from "#promotion/utils/types";
import {
    nameValidation,
    discountTypeValidation,
    discountAmountPercentValidation,
    discountAmountDollarValidation
} from "#promotion/utils/validation/field-schemas";
import { Options } from "#reusable/select/dropdown";

export const GIFT_CARD_PERCENT_OPTIONS: Options[] = [
    { label: "10", value: 10 },
    { label: "15", value: 15 },
    { label: "25", value: 25 }
];

export const GIFT_CARD_DISCOUNT_DOLLAR_OPTIONS: Options[] = [
    { label: "2", value: 2 },
    { label: "5", value: 5 },
    { label: "10", value: 10 }
];

export const GiftCardDefaultValues = {
    [FIELD_NAMES.NAME]: "Gift Card Promo",
    [FIELD_NAMES.PROMOTION_TYPE]: PromoTypes.GiftCard,

    [FIELD_NAMES.DISCOUNT_TYPE]: DISCOUNT_TYPES.PERCENT_OFF,
    [FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT]: Number(
        GIFT_CARD_PERCENT_OPTIONS[1].value
    ),
    [FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS]: Number(
        GIFT_CARD_DISCOUNT_DOLLAR_OPTIONS[1].value
    ),
    [FIELD_NAMES.IMAGE]: ""
} as GiftCardPromo;

export const GiftCardPromoValidationSchema = yup.object().shape({
    [FIELD_NAMES.NAME]: nameValidation,

    [FIELD_NAMES.DISCOUNT_TYPE]: discountTypeValidation,
    [FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT]: discountAmountPercentValidation,
    [FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS]: discountAmountDollarValidation,

    [FIELD_NAMES.MINIMUM_PRICE]: yup
        .number()
        .min(1, "Minimum Price must be at least $1")
        .max(
            MAX_GIFTCARD_AMOUNT_CENTS / 100,
            `Cannot enforce a minimum price higher than $${
                MAX_GIFTCARD_AMOUNT_CENTS / 100
            }`
        ),
    [FIELD_NAMES.MAXIMUM_DISCOUNT]: yup
        .number()
        .min(1, "Maximum Discount must be at least $1")
        .max(
            MAX_GIFTCARD_AMOUNT_CENTS / 100,
            `Maximum Discount cannot exceed $${MAX_GIFTCARD_AMOUNT_CENTS / 100}`
        )
});
