import { Space } from "antd";
import React from "react";
import styled from "styled-components";

import { Button } from "#menu-editor/multi-menus/styled-components/button";
import { UnstyledLink } from "#menu-editor/multi-menus/styled-components/unstyled-link";

const OverriddenButton = styled(Button)`
    cursor: default !important;
`;

const BreadcrumbText = styled.div`
    display: flex;

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #606c76;
`;

const BreadcrumbSeparatorWrapper = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #bcc9d6;
`;

const BreadcrumbSeparator: React.FC<{ separator?: string }> = ({
    separator = "/"
}) => <BreadcrumbSeparatorWrapper>{separator}</BreadcrumbSeparatorWrapper>;

export type BreadcrumbItems = { label: string; to: string | null }[];

type Props = {
    items: BreadcrumbItems;
    separator?: string;
};

const Wrapper = styled.div`
    // Hide the last separator
    .ant-space-item:last-child {
        display: none;
    }

    // Align first breadcrumb item with title
    > div > div:first-child > a > div {
        margin-left: -12px;
    }
`;

export const Breadcrumb: React.FC<Props> = ({ items, separator }) => (
    <Wrapper>
        <Space>
            {items.map((item) => (
                <React.Fragment key={item.label}>
                    {item.to ? (
                        <UnstyledLink to={item.to}>
                            <Button type="quaternary" size="micro">
                                <BreadcrumbText>{item.label}</BreadcrumbText>
                            </Button>
                        </UnstyledLink>
                    ) : (
                        <OverriddenButton type="quaternary" size="micro">
                            <BreadcrumbText>{item.label}</BreadcrumbText>
                        </OverriddenButton>
                    )}
                    <BreadcrumbSeparator separator={separator} />
                </React.Fragment>
            ))}
        </Space>
    </Wrapper>
);
