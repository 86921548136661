import React, { createContext, useCallback, useEffect, useMemo } from "react";

import { getActiveStore, getUser } from "src/redux/selectors";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { invariant } from "src/lib/invariant";
import { sendError, logAndSendError } from "src/utils/errors";
import { setLegacyProducts } from "src/redux/slices";

import { provideMenuService } from "./service";

export type MenuService = ReturnType<typeof useMenuService>;

export const MenuServiceContext = createContext({} as MenuService);

export type MenuServiceProviderProps = {
    children: React.ReactNode;
};

export function MenuServiceProvider({ children }: MenuServiceProviderProps) {
    const _menuService = useMenuService();

    return (
        <MenuServiceContext.Provider value={_menuService}>
            {children}
        </MenuServiceContext.Provider>
    );
}

function useMenuService() {
    const dispatch = useAppDispatch();
    const store = useAppSelector(getActiveStore);
    const user = useAppSelector(getUser);

    const service = useMemo(
        () => (store?._id && user ? provideMenuService(store?._id) : null),
        [store?._id, user]
    );

    const fetchMenu = useCallback(async () => {
        invariant(service, "[fetchMenu]: Menu service is not available.");

        const result = await service.fetchMenu();
        result.match(
            (menu) => {
                if (!menu) {
                    throw new Error("[fetchMenu]: No menu returned.");
                }
                dispatch(setLegacyProducts(menu.products));
            },
            (err) => {
                throw err;
            }
        );
    }, [dispatch, service]);

    // Automatically fetch the menu when the service is available
    useEffect(() => {
        if (service) {
            void fetchMenu().catch((error) => {
                // TODO: We should expose the axios error directly
                if (
                    !error.message.includes("Network Error") &&
                    !error.message.includes("404")
                ) {
                    logAndSendError(error);
                    sendError(error);
                }
            });
        }
    }, [fetchMenu, service]);

    const sellOutProduct = useCallback(
        async (productId: string, until?: Date) => {
            invariant(
                service,
                "[sellOutProduct]: Menu service is not available."
            );
            const result = await service.sellOutProduct(productId, until);

            result.match(
                () => {
                    const updatedMenu = service.getMenu();
                    if (updatedMenu) {
                        dispatch(setLegacyProducts(updatedMenu.products));
                    }
                },
                (error) => {
                    logAndSendError(error);
                    throw error;
                }
            );
        },
        [dispatch, service]
    );

    const restockProduct = useCallback(
        async (productId: string) => {
            invariant(
                service,
                "[restockProduct]: Menu service is not available."
            );
            const result = await service.restockProduct(productId);

            result.match(
                () => {
                    const updatedMenu = service.getMenu();
                    if (updatedMenu) {
                        dispatch(setLegacyProducts(updatedMenu.products));
                    }
                },
                (error) => {
                    logAndSendError(error);
                    throw error;
                }
            );
        },
        [dispatch, service]
    );

    const sellOutAddonsByName = useCallback(
        async (addonName: string, until?: Date) => {
            invariant(
                service,
                "[sellOutAddonsByName]: Menu service is not available."
            );
            const result = await service.sellOutAddonsByName(addonName, until);

            result.match(
                () => {
                    const updatedMenu = service.getMenu();
                    if (updatedMenu) {
                        dispatch(setLegacyProducts(updatedMenu.products));
                    }
                },
                (error) => {
                    logAndSendError(error);
                    throw error;
                }
            );
        },
        [dispatch, service]
    );

    const restockAddonsByName = useCallback(
        async (addonName: string) => {
            invariant(
                service,
                "[restockAddonsByName]: Menu service is not available."
            );
            const result = await service.restockAddonsByName(addonName);

            result.match(
                () => {
                    const updatedMenu = service.getMenu();
                    if (updatedMenu) {
                        dispatch(setLegacyProducts(updatedMenu.products));
                    }
                },
                (error) => {
                    logAndSendError(error);
                    throw error;
                }
            );
        },
        [dispatch, service]
    );

    return useMemo(
        () => ({
            fetchMenu,
            restockProduct,
            sellOutProduct,
            sellOutAddonsByName,
            restockAddonsByName
        }),
        [
            fetchMenu,
            restockProduct,
            sellOutProduct,
            sellOutAddonsByName,
            restockAddonsByName
        ]
    );
}
