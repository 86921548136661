import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/redux/store";

export const getActiveStore = (state: RootState) => state.activeStore;

export const getLegacyCategories = createSelector(
    [getActiveStore],
    (activeStore) => (activeStore ? activeStore.productCategories : [])
);

export const getActiveStoreId = createSelector(
    [getActiveStore],
    (activeStore) => (activeStore ? activeStore._id : "")
);

export const getActiveStoreLogoUrl = createSelector(
    [getActiveStore],
    (store) => store?.logoUrl
);

export const getActiveStoreClosedUntil = createSelector(
    [getActiveStore],
    (activeStore) => activeStore?.closedUntil
);

export const getActiveStoreAttendanceSettings = createSelector(
    [getActiveStore],
    (store) => store?.attendanceSettings
);

export const getActiveStoreIs3PIntegrated = createSelector(
    [getActiveStore],
    (store) =>
        store?.integrations.checkmate?.enabled ||
        store?.integrations.chowly?.enabled
);
