import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ScreenState } from "@snackpass/snackpass-types";

import constants from "#core/constants";
import { Text } from "#reusable/text/index";
import { Space } from "#reusable/divider/space";

import { ScreenLayout } from "../../reusable/layout/screen-layout";
import { MobileHeader } from "../../reusable/layout/header";

const SettingsLegal = () => {
    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`
    });

    return (
        <div>
            <ScreenLayout
                header={
                    isMobile ? (
                        <MobileHeader title="Legal" />
                    ) : (
                        <Text size="xl" weight="x-bold">
                            Legal
                        </Text>
                    )
                }
                description={
                    isMobile ? null : (
                        <Text
                            className="pt-1"
                            size="s"
                            color="light-grey"
                            weight="thin"
                        >
                            Select a Document to View
                        </Text>
                    )
                }
                content={
                    <ContentRow>
                        <Space vertical="l" />
                        <div>
                            <Link
                                className="no-underline"
                                to={{
                                    pathname: "https://privacy.snackpass.co"
                                }}
                                target="_blank"
                            >
                                Privacy Center
                            </Link>
                        </div>
                        <Space vertical="s" />
                        <div>
                            <Link
                                className="no-underline"
                                to={{
                                    pathname:
                                        "https://legal.snackpass.co/snackpass-restaurant-terms-of-service"
                                }}
                                target="_blank"
                            >
                                Terms of Service
                            </Link>
                        </div>
                        <Space vertical="s" />
                        <div>
                            <Link
                                className="no-underline"
                                to={{
                                    pathname:
                                        "https://snackpass-customer-store.myshopify.com/pages/warranty"
                                }}
                                target="_blank"
                            >
                                {" "}
                                Hardware Warranty
                            </Link>
                        </div>
                    </ContentRow>
                }
            />
        </div>
    );
};

const ContentRow = styled.div`
    overflow-y: scroll;
    padding-bottom: 50px;
    padding-right: 20%;
    height: 100%;
    @media ${ScreenState.MOBILE} {
        padding-left: 20px;
        padding-right: 30px;
    }
    @media ${ScreenState.TABLET} {
        padding-right: 50px;
    }
    width: -webkit-fill-available;
    width: -moz-available;
`;

export default SettingsLegal;
