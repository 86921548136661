/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { getActiveStore } from "@snackpass/accounting";
import {
    useConversations,
    usePubNubWithToken
} from "@snackpass/conversations.hooks";
import { ChatChannelMetadata } from "@snackpass/conversations.types";
import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";
import { Empty, Skeleton, Divider, List, Spin } from "antd";
import { ChannelMetadataObject, ObjectCustom } from "pubnub";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { PubNubPublisher } from "@snackpass/conversations.client";
import _ from "lodash";
import { useIntersectionObserver } from "react-intersection-observer-hook";

import SmallSearchSVG from "src/assets/icons/search.svg";
import {
    filterByChannelName,
    isImportant
} from "#guestbook/components/conversations-list/lib";
import { TypeButton } from "#guestbook/shared-styled-components";
import { SegmentEvents, trackSegmentEvent } from "#utils/segment";
import { getActiveStoreNumUnreadChannels } from "src/redux/slices/activeStoreNumUnreads";
import colors from "#reusable/colors/colors.json";

import { Conversation } from "./conversation";

// KC: Pubnub interval to keep auto-loading. A short timeout prevents a burst of requests to constantly keep loading new channels
// that may all be filtered out. Ideally could be exponential backoff to a max limit, but currently unnecessary.
const SHORT_DEFERRED_LOAD_TIME = 100;

const isUnread = (channel: string, unreadChannels: string[]) =>
    unreadChannels.includes(channel);

enum ConversationFilter {
    ALL = "All",
    UNREAD_ONLY = "Unread Only",
    IMPORTANT = "Important"
}

const ConversationsListComponent = ({
    activeChannelId,
    setActiveChannelId,
    setActiveChatChannel,
    setShowConversation,
    numUnreads
}: {
    activeChannelId: string;
    setActiveChannelId: React.Dispatch<React.SetStateAction<string>>;
    setActiveChatChannel: React.Dispatch<
        React.SetStateAction<ChannelMetadataObject<ObjectCustom> | undefined>
    >;
    setShowConversation: React.Dispatch<React.SetStateAction<boolean>>;
    numUnreads: number;
}) => {
    const activeStore = useSelector(getActiveStore);
    useEffect(() => {
        if (activeStore) {
            trackSegmentEvent(SegmentEvents.Guestbook.Conversations.VIEWED, {
                actor: "store",
                store_id: activeStore._id,
                store_name: activeStore.name
            });
        }
    }, [activeStore?._id]);

    const pubnub = usePubNubWithToken();

    const {
        conversations: chatChannels,
        hasMore,
        fetchMore: moreChatChannels,
        isLoading
    } = useConversations(pubnub);
    const unreadChannels = useSelector(getActiveStoreNumUnreadChannels);
    const [searchText, setSearchText] = useState("");
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [importantChannels, setImportantChannels] = useState<
        ChannelMetadataObject<ChatChannelMetadata>[]
    >([]);
    const [numImportant, setNumImportant] = useState<number>(0);
    const [selectConversationFilter, setSelectConversationFilter] =
        useState<ConversationFilter>(ConversationFilter.IMPORTANT);

    const [channelsToDisplay, setChannelsToDisplay] = useState(chatChannels);

    useEffect(() => {
        // set important channels based on unread channels
        if (!unreadChannels.length && !chatChannels.length) return;
        setNumImportant(
            filterByChannelName<ChatChannelMetadata>(chatChannels, searchText)
                .filter((ch) => !!ch.custom)
                .filter((ch) => unreadChannels.includes(ch.id))
                .filter(isImportant).length
        );
    }, [unreadChannels.length, chatChannels.length]);

    // this is used to filter channels using search bar and filter unreads
    useEffect(() => {
        if (selectConversationFilter === ConversationFilter.IMPORTANT) {
            const importantChannels = filterByChannelName<ChatChannelMetadata>(
                chatChannels,
                searchText
            )
                .filter((ch) => !!ch.custom)
                .filter((ch) => isUnread(ch.id, unreadChannels))
                .filter(isImportant);
            setChannelsToDisplay(importantChannels);
            setImportantChannels(importantChannels);
            if (chatChannels.length > 0) {
                setIsFirstLoad(false);
            }
        } else if (
            selectConversationFilter === ConversationFilter.UNREAD_ONLY
        ) {
            setChannelsToDisplay(
                filterByChannelName<ChatChannelMetadata>(
                    chatChannels,
                    searchText
                )
                    .filter((ch) => !!ch.custom)
                    .filter((ch) => isUnread(ch.id, unreadChannels))
            );
            if (chatChannels.length > 0) {
                setIsFirstLoad(false);
            }
        } else {
            setChannelsToDisplay(
                filterByChannelName<ChatChannelMetadata>(
                    chatChannels,
                    searchText
                ).filter((ch) => !!ch.custom)
            );
        }
    }, [selectConversationFilter, searchText, chatChannels.length]);

    // Switch to unreads if no important, switch to all if no unreads
    useEffect(() => {
        if (!isFirstLoad && _.isEmpty(importantChannels) && numUnreads) {
            setSelectConversationFilter(ConversationFilter.UNREAD_ONLY);
        } else if (!isFirstLoad && channelsToDisplay.length === 0) {
            setSelectConversationFilter(ConversationFilter.ALL);
        }
    }, [isFirstLoad]);

    // if loaded but there are no campaigns
    const noChannels = chatChannels.length === 0 && !hasMore;
    // unread inbox is empty
    const noUnread =
        selectConversationFilter === ConversationFilter.UNREAD_ONLY &&
        channelsToDisplay.length === 0;
    const noImportant =
        selectConversationFilter === ConversationFilter.IMPORTANT &&
        channelsToDisplay.length === 0;

    const pub = new PubNubPublisher(
        { uuid: pubnub.getUUID(), subscribeKey: "" },
        pubnub
    );

    const ImportantEmptyState = () => {
        if (!noImportant) return null;

        return (
            <Empty description={"You have read all important messages! 🎉"} />
        );
    };

    return (
        <ConvoListWrapper>
            {chatChannels.length === 0 && isLoading ? (
                <SpinnerWrapper>
                    <Spin tip="Loading..." />
                </SpinnerWrapper>
            ) : noChannels ? (
                <Empty
                    description={
                        "No conversation channel exists. Contact support for more info."
                    }
                />
            ) : (
                <>
                    <TopBarWrapper>
                        <SearchBar
                            searchText={searchText}
                            setSearchText={setSearchText}
                        />
                        <div className="options">
                            <TypeButton
                                isSelected={
                                    selectConversationFilter ===
                                    ConversationFilter.IMPORTANT
                                }
                                onClick={() =>
                                    setSelectConversationFilter(
                                        ConversationFilter.IMPORTANT
                                    )
                                }
                                width={"auto"}
                                padding={"7px 8px"}
                            >
                                Important · {numImportant}
                            </TypeButton>
                            <TypeButton
                                isSelected={
                                    selectConversationFilter ===
                                    ConversationFilter.UNREAD_ONLY
                                }
                                onClick={() =>
                                    setSelectConversationFilter(
                                        ConversationFilter.UNREAD_ONLY
                                    )
                                }
                                width={"auto"}
                                padding={"7px 8px"}
                            >
                                Unread · {numUnreads}
                            </TypeButton>
                            <TypeButton
                                isSelected={
                                    selectConversationFilter ===
                                    ConversationFilter.ALL
                                }
                                onClick={() =>
                                    setSelectConversationFilter(
                                        ConversationFilter.ALL
                                    )
                                }
                                width={"auto"}
                            >
                                All
                            </TypeButton>
                        </div>
                    </TopBarWrapper>
                    {ImportantEmptyState()}
                    {noUnread ? (
                        <Empty
                            description={"You have read all new messages! 🎉"}
                        />
                    ) : (
                        <ChannelList
                            channelsToDisplay={channelsToDisplay}
                            pub={pub}
                            activeChannelId={activeChannelId}
                            setActiveChannelId={setActiveChannelId}
                            setActiveChatChannel={setActiveChatChannel}
                            setShowConversation={setShowConversation}
                            moreChatChannels={moreChatChannels}
                            hasMore={hasMore}
                            isLoading={isLoading}
                            selectConversationFilter={selectConversationFilter}
                            setImportantChannels={setImportantChannels}
                        />
                    )}
                </>
            )}
        </ConvoListWrapper>
    );
};

const ChannelList = ({
    channelsToDisplay,
    pub,
    activeChannelId,
    setActiveChannelId,
    setActiveChatChannel,
    setShowConversation,
    moreChatChannels,
    hasMore,
    isLoading,
    selectConversationFilter,
    setImportantChannels
}: {
    channelsToDisplay: ChannelMetadataObject<ObjectCustom>[];
    pub: PubNubPublisher;
    activeChannelId: string;
    setActiveChannelId: React.Dispatch<React.SetStateAction<string>>;
    setActiveChatChannel: React.Dispatch<
        React.SetStateAction<ChannelMetadataObject<ObjectCustom> | undefined>
    >;
    setShowConversation: React.Dispatch<React.SetStateAction<boolean>>;
    moreChatChannels: () => void;
    hasMore: boolean;
    isLoading: boolean;
    selectConversationFilter: ConversationFilter;
    setImportantChannels: React.Dispatch<
        React.SetStateAction<ChannelMetadataObject<ChatChannelMetadata>[]>
    >;
}) => {
    const conversationsListRef = useRef(null);
    const [loadingSkeletonRef, { entry }] = useIntersectionObserver({
        threshold: 1.0 // 100% visibility
    });
    // Auto load more channels on the edge case where a filter is applied, resulting in
    // too few channels to allow scrolling to trigger InfiniteScroll's load.
    const isLoadingSkeletonVisible = !!entry?.isIntersecting;
    const debouncedLoadMore = useMemo(
        () => _.debounce(moreChatChannels, SHORT_DEFERRED_LOAD_TIME),
        []
    );
    useEffect(() => {
        if (isLoading === false && hasMore && isLoadingSkeletonVisible) {
            debouncedLoadMore();
        }
    }, [isLoading, isLoadingSkeletonVisible]);
    return (
        <div
            ref={conversationsListRef}
            className="conversations"
            id="scrollableDiv"
        >
            <InfiniteScroll
                dataLength={channelsToDisplay.length}
                next={moreChatChannels}
                hasMore={
                    [
                        ConversationFilter.UNREAD_ONLY,
                        ConversationFilter.IMPORTANT
                    ].includes(selectConversationFilter)
                        ? false
                        : hasMore
                }
                loader={
                    <div ref={loadingSkeletonRef}>
                        <Skeleton avatar paragraph={{ rows: 1 }} active />
                    </div>
                }
                endMessage={<Divider plain>End of inbox</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                    dataSource={channelsToDisplay}
                    renderItem={(
                        channel: ChannelMetadataObject<ObjectCustom>
                    ) => (
                        <Conversation
                            key={channel.id}
                            channel={channel}
                            pub={pub}
                            activeChannelId={activeChannelId}
                            setActiveChannelId={setActiveChannelId}
                            setActiveChatChannel={setActiveChatChannel}
                            setShowConversation={setShowConversation}
                            setImportantChannels={setImportantChannels}
                        />
                    )}
                />
            </InfiniteScroll>
        </div>
    );
};

const SearchBar = ({
    searchText,
    setSearchText
}: {
    searchText: string;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
}) => (
    <div css={SearchBarStyles}>
        <label>
            <input
                type="search"
                id={"2222"}
                autoFocus={true}
                value={searchText || ""}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder={"Search"}
            />
        </label>
    </div>
);

const SpinnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 50%;
    .ant-progress-outer {
        width: 60%;
    }
`;

const ConvoListWrapper = styled.div`
    height: calc(100vh - 236px);
    width: 100%;
    position: relative;

    .conversations {
        overflow-y: auto;
        height: 100%;
    }
    .ant-empty {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
    }
`;

const TopBarWrapper = styled.div`
    width: 100%;
    position: sticky;
    border-bottom: 1px solid ${colors["neutral-400"]};
    display: flex;
    flex-direction: column;

    .options {
        display: flex;
        flex-direction: row;
        margin: 0px 15px 10px 15px;
        gap: 4px;
        @media ${ScreenState.TABLET} {
            gap: 2px;
        }
    }
`;

const SearchBarStyles = css`
    color: ${SystemColors.v1.gray30};
    border: 1px solid ${SystemColors.v1.gray80};
    border-radius: 8px;
    margin-top: 3px;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    outline: none;
    padding: 0 0 0 35px;
    display: flex;
    flex: 1;
    position: relative;
    margin: 8px 15px 0px 15px;

    label {
        width: 100%;
    }

    label:before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background: url(${SmallSearchSVG}) center no-repeat;
        margin-top: 7px;
        margin-left: -24px;
    }

    input {
        border: none;
        padding: 0px;
        height: 100%;
        width: 90%;
        background: center;
    }

    input:focus {
        box-shadow: none !important;
        caret-color: ${SystemColors.v1.candy50};
    }
`;

export const ConversationsList = React.memo(ConversationsListComponent);
