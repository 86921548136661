import React, { useContext } from "react";

import { ReportsContext } from "#app/reports-context-provider";
import TopItemsChart from "#reports/menu-item-insights/shared-components/TopItemsChart";
import { aggregateItemsByCategory } from "#reports/menu-category-insights/lib";

const CategorySalesChart = () => {
    const { reportsState } = useContext(ReportsContext);
    const { menuInsightsData } = reportsState;

    const categoryData = aggregateItemsByCategory(
        menuInsightsData?.itemInsightsData
    );

    return (
        <TopItemsChart
            data={categoryData}
            loading={
                menuInsightsData?.itemInsightsDataLoading ||
                (!menuInsightsData?.itemInsightsData &&
                    !menuInsightsData?.itemInsightsDataFailed)
            }
            title={"Top Categories"}
        />
    );
};

export default CategorySalesChart;
