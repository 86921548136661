import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";
import React from "react";
import styled from "styled-components";

type Props = {
    name?: string;
    size?: "sm" | "lg";
    required?: boolean;
    recommended?: boolean;
    autofilled?: boolean;
    disabled?: boolean;
};
type NameProps = {
    size: "sm" | "lg";
    disabled: boolean;
};
type LabelProps = {
    required?: boolean;
    recommended?: boolean;
    autofilled?: boolean;
};

const FieldLabel = ({ required, recommended, autofilled }: LabelProps) => {
    if (required) {
        if (autofilled) {
            return <RequiredLabel>Required by Shortcut</RequiredLabel>;
        }
        return <RequiredLabel>Required</RequiredLabel>;
    } else if (recommended) {
        return <RecommendedLabel>Recommended</RecommendedLabel>;
    } else if (autofilled) {
        return <AutofilledLabel>Autofilled by Shortcut</AutofilledLabel>;
    }
    return null;
};

export const FormFieldName = ({
    name,
    required = false,
    recommended = false,
    autofilled = false,
    disabled = false,
    size = "sm"
}: Props) =>
    name ? (
        <Container>
            <Name size={size} disabled={disabled}>
                {name}
            </Name>
            <FieldLabel
                required={required}
                recommended={recommended}
                autofilled={autofilled}
            />
        </Container>
    ) : null;

const Container = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
`;

const RequiredLabel = styled.span`
    color: white;
    background-color: ${SystemColors.v2.apricot50.light};
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-left: 8px;
`;

const RecommendedLabel = styled.span`
    color: ${SystemColors.v1.gray50};
    background-color: ${SystemColors.v1.gray90};
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-left: 8px;
`;

const AutofilledLabel = styled.span`
    color: white;
    background-color: ${SystemColors.v2.apricot50.light};
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-left: 8px;
`;

const Name = styled.p<NameProps>`
    margin: 0;
    font-weight: 500;
    line-height: 24px;
    ${({ size }) => (size === "lg" ? `font-size: 24px;` : `font-size: 18px;`)}
    @media ${ScreenState.MOBILE} {
        ${(props) =>
            props.size === "lg" ? `font-size: 24px;` : `font-size: 16px;`}
    }
    ${({ disabled }) => disabled && `color: ${SystemColors.v1.gray60};`}
`;
