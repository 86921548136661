import * as yup from "yup";
import {
    ITimeRangeSchema,
    // KioskFulfillment,
    IIntegration,
    FulfillmentTypeEnum,
    ThirdPartySource,
    KioskMenuDisplayType
} from "@snackpass/snackpass-types";

import {
    checkOverlappingHours,
    validateHoursStartTimeBeforeEndTime
} from "#settings/helper";

export enum TabName {
    appAndOnline = "App & Online",
    KioskAndRegister = "Kiosk & Register"
}

export type KioskCustomerIdentifier = "ORDER_NUMBER" | "TABLE_NUMBER";
export type KioskTipDisplay = "FLAT" | "PERCENT";

export enum KioskTipDisplayEnum {
    flat = "FLAT",
    percent = "PERCENT"
}
export enum KioskCustomerIdentifierEnum {
    orderNumber = "ORDER_NUMBER",
    tableNumber = "TABLE_NUMBER"
}

export enum FormFieldEnum {
    customWayfindingMessage = "customWayfindingMessage",
    customerIdentifierType = "customerIdentifierType",
    defaultFulfillment = "defaultFulfillment",
    delivery = "delivery",
    deliveryMin = "deliveryMin",
    disablePopularSection = "disablePopularSection",
    fulfillmentTypes = "fulfillmentTypes",
    hasBatching = "hasBatching",
    hasDineIn = "hasDineIn",
    hasPickupNow = "hasPickupNow",
    hasScheduledOrders = "hasScheduledOrders",
    hasSpecialDeliveryHours = "hasSpecialDeliveryHours",
    kioskRewards = "kioskRewards",
    registerRewards = "registerRewards",
    manualThirdPartySources = "manualThirdPartySources",
    onlineOrderingEnabled = "onlineOrderingEnabled",
    pickup = "pickup",
    pickupDirections = "pickupDirections",
    pickupMin = "pickupMin",
    requireTableNumber = "requireTableNumber",
    scheduleAheadBatchSize = "scheduleAheadBatchSize",
    scheduleAheadInterval = "scheduleAheadInterval",
    scheduledOrderMinLeadTime = "scheduledOrderMinLeadTime",
    specialDeliveryHoursLocal = "specialDeliveryHoursLocal",
    menuDisplayType = "menuDisplayType"
}

export type PlatformFormType = {
    // APP & ONLINE
    //pickup
    pickup: boolean;
    pickupMin: number | null;
    hasPickupNow: boolean;
    hasScheduledOrders: boolean;
    scheduleAheadInterval?: number;
    scheduledOrderMinLeadTime: number;
    pickupDirections?: string | null;
    hasBatching: boolean;
    scheduleAheadBatchSize?: number;
    //dine-in
    hasDineIn: boolean;
    requireTableNumber: boolean;
    //delivery
    delivery: boolean;
    deliveryMin: number;
    hasSpecialDeliveryHours: boolean;
    specialDeliveryHoursLocal: ITimeRangeSchema[];
    //online ordering
    onlineOrderingEnabled: boolean;
    // KIOSK AND REGISTER
    customerIdentifierType: KioskCustomerIdentifier;
    customWayfindingMessage: string | null | undefined;
    defaultFulfillment: boolean | null;
    disablePopularSection: boolean | null | undefined;
    fulfillmentTypes: FulfillmentTypeEnum[];
    hasKiosk: boolean;
    kioskRewards: boolean;
    registerRewards: boolean;
    blacklistedRewardPhoneNumbers?: string[];
    manualThirdPartySources: ThirdPartySource[];
    menuDisplayType: KioskMenuDisplayType;
};

export const PlatformFromInitialValue: PlatformFormType = {
    // APP & ONLINE
    pickup: true,
    pickupMin: null,
    hasPickupNow: false,
    hasScheduledOrders: false,
    scheduleAheadInterval: 15,
    scheduledOrderMinLeadTime: 15,
    pickupDirections: "",
    hasBatching: false,
    scheduleAheadBatchSize: 10,
    //dine-in
    hasDineIn: false,
    requireTableNumber: false,
    //delivery
    delivery: false,
    deliveryMin: 0,
    hasSpecialDeliveryHours: true,
    specialDeliveryHoursLocal: [],
    //online ordering:
    onlineOrderingEnabled: false,
    // KIOSK AND REGISTER
    customerIdentifierType: KioskCustomerIdentifierEnum.orderNumber,
    customWayfindingMessage: "",
    defaultFulfillment: false,
    disablePopularSection: false,
    fulfillmentTypes: [],
    hasKiosk: false,
    kioskRewards: false,
    registerRewards: true,
    blacklistedRewardPhoneNumbers: [],
    manualThirdPartySources: [],
    menuDisplayType: KioskMenuDisplayType.CLASSIC
};

const TIME_MIN = 0;
const TIME_MAX = 7 * 24 * 60 - 1;
export const BLACKLISTED_REWARD_MAX_PHONE_NUMBERS = 5;

const hoursSchema = yup.array(
    yup.object({
        start: yup.number().min(TIME_MIN).max(TIME_MAX),
        end: yup.number().min(TIME_MIN).max(TIME_MAX)
    })
);

export const platformFormValidationSchema = yup.object({
    //App & Online
    pickup: yup.boolean().required(),
    delivery: yup.boolean().required(),
    hasDineIn: yup.boolean().required(),
    pickupDirections: yup.string().nullable(),
    pickupMin: yup.number().nullable().min(0),
    requireTableNumber: yup.boolean(),
    scheduleAheadInterval: yup.number().when("hasScheduledOrders", {
        is: true,
        then: yup
            .number()
            .required(
                "Time slot intervals is required if schedule ahead order is on"
            ),
        otherwise: yup.number()
    }),
    //delivery
    deliveryMin: yup.number().nullable(),
    hasSpecialDeliveryHours: yup.boolean(),
    specialDeliveryHoursLocal: hoursSchema
        .nullable()
        .test({
            name: "Overlapping Hours",
            message: "Store Hours cannot overlap!",
            test: (
                value:
                    | { start: number | undefined; end: number | undefined }[]
                    | null
                    | undefined
            ) => {
                if (value) {
                    return checkOverlappingHours(value as ITimeRangeSchema[]);
                }
                return true;
            }
        })
        .test({
            name: "End time must be after start time",
            message: "End time must be after start time!",
            test: (value) => {
                if (value) {
                    return validateHoursStartTimeBeforeEndTime(
                        value as ITimeRangeSchema[]
                    );
                }
                return true;
            }
        }),

    onlineOrderingEnabled: yup.boolean().required(),
    scheduleAheadBatchSize: yup.number().min(0),
    manualThirdPartySources: yup
        .array()
        .max(9, "You can only select at most 9 providers"),
    // Kiosk
    blacklistedRewardPhoneNumbers: yup
        .array()
        .of(
            yup.string().test({
                name: "Phone Number Check",
                message: "Invalid phone number format",
                test: (value: string | undefined | null) => {
                    const phoneRegExp =
                        /^(\+1)?((?:\(?[1-9](?:(?=1)1[01-9]|(?:(?=0)0[01-9]|\d{2}))\)?\D{0,3})(?:\(?[01-9](?:(?=1)1[01-9]|\d{2})\)?\D{0,3})\d{4})$/;
                    const schema = yup.string().matches(phoneRegExp);
                    return schema.isValidSync(value);
                }
            })
        )
        .max(BLACKLISTED_REWARD_MAX_PHONE_NUMBERS),
    menuDisplayType: yup.mixed().oneOf(Object.values(KioskMenuDisplayType))
});

interface Tab {
    title: string;
    value: string;
}

export const platformTabs: Tab[] = [
    {
        title: "App & Online",
        value: "1"
    },
    {
        title: "Kiosk & Register",
        value: "2"
    }
];

export const checkStoreHasDeliveryIntegrations = (
    integrations: IIntegration | undefined
): boolean => {
    if (!integrations) return false;
    return (
        (integrations?.postmates?.enabled ||
            integrations?.doordash?.enabled) === true
    );
};

export const tabKeyEnum = {
    appAndOnline: "tab-app-and-online",
    kioskAndRegister: "tab-kiosk-and-register"
};
