import { useCallback, useState } from "react";
import { z } from "zod";
import { Resolver, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { useHistory } from "react-router-dom";

import { Label } from "src/@/components/ui/label";
import { Input } from "src/@/components/ui/input";
import { Textarea } from "src/@/components/ui/textarea";
import { Button } from "src/@/components/ui/button";
import { useCreateInvoiceMutation } from "src/api/graphql/generated/types";
import {
    FormControl,
    FormField,
    FormItem,
    FormMessage,
    Form,
    FormDescription
} from "src/@/components/ui/form";
import { getActiveStoreId } from "src/redux/slices";
import { InvoicePreview } from "#invoices/components/invoice-preview";

export const InvoicesNameSchema = z.object({
    customerName: z.string().min(1).max(100),
    customerEmail: z.string().email().min(1).max(254),
    description: z.string().min(1).max(500),
    salesTaxPercentage: z.coerce
        .number()
        .min(0, {
            message: "Sales tax must be set to a value between 0-15%"
        })
        .max(15, {
            message: "Sales tax must be set to a value between 0-15%"
        })
        .step(0.001)
        .default(-1), // by setting default to -1, if section is left empty it will trigger sales tax must be set to a value error
    amount: z.coerce.number().min(1).max(100000).step(0.01).default(0)
});

const INVOICE_TERMS_URL =
    "https://snackpass.notion.site/Snackpass-Invoice-Terms-196ee37487f843bea79811d2c3d836ab";

type InvoicesNewProps = {
    onCreate: () => void;
    close: () => void;
};

export const InvoiceNew = ({ onCreate, close }: InvoicesNewProps) => {
    const activeStoreId = useSelector(getActiveStoreId);
    const [createInvoiceMutation] = useCreateInvoiceMutation();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const resolver: Resolver<z.infer<typeof InvoicesNameSchema>> = useCallback(
        async (data, context, options) =>
            zodResolver(InvoicesNameSchema)(data, context, options),
        []
    );

    const form = useForm<z.infer<typeof InvoicesNameSchema>>({ resolver });

    const customerName = form.watch("customerName");
    const customerEmail = form.watch("customerEmail");
    const description = form.watch("description");
    // Form values come back as strings but typed as numbers
    const amount = parseFloat(`${form.watch("amount")}`);
    const amountInCents = amount * 100;
    const salesTaxPercentage = parseFloat(
        `${form.watch("salesTaxPercentage")}`
    );
    const salesTaxAmount = Math.round(
        (amountInCents * salesTaxPercentage) / 100
    );
    const totalAmount = amountInCents + salesTaxAmount;

    const displayInvoicePreview =
        form.formState.isDirty &&
        !form.formState.isSubmitting &&
        !form.formState.isSubmitSuccessful;

    const handleSubmit = useCallback(
        async (formValues: z.infer<typeof InvoicesNameSchema>) => {
            setIsLoading(true);
            try {
                await createInvoiceMutation({
                    variables: {
                        storeId: activeStoreId,
                        input: {
                            ...formValues,
                            salesTaxPercentage,
                            amount: amountInCents
                        }
                    }
                });

                toast.success("Successfully sent invoice email to customer");
                onCreate();
            } catch (err) {
                const errorMessage =
                    (err as Error).message || "Error sending invoice email";
                if (errorMessage.includes("Stripe connect account id")) {
                    close();
                    toast.error(
                        <span>
                            <strong className="text-red-500">ERROR: </strong>{" "}
                            Invoice Not Created - Before sending an invoice, you
                            must set up your payouts{" "}
                            <a
                                onClick={() => history.push("/payouts")}
                                className="text-blue-500 underline focus:cursor-pointer"
                            >
                                here
                            </a>
                        </span>,
                        {
                            duration: 7000,
                            position: "top-center",
                            closeButton: true
                        }
                    );
                } else {
                    toast.error(errorMessage);
                }
                form.reset({ ...formValues });
            }

            setIsLoading(false);
        },
        [
            activeStoreId,
            amountInCents,
            close,
            createInvoiceMutation,
            form,
            history,
            onCreate,
            salesTaxPercentage
        ]
    );

    return (
        <div className="space-y-6">
            <div className="space-y-2">
                <h1 className="text-2xl font-bold">Send Invoice</h1>
                <p className="text-gray-500 dark:text-gray-400">
                    Fill out the details to send invoice email to customer.{" "}
                    <a
                        href={INVOICE_TERMS_URL}
                        className="text-blue-500 no-underline"
                        target="_blank"
                    >
                        See invoice terms
                    </a>
                    .
                </p>
            </div>
            <Form {...form}>
                <form
                    className="space-y-6"
                    onSubmit={form.handleSubmit(handleSubmit)}
                >
                    <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-2">
                            <Label htmlFor="customerName">Customer Name</Label>
                            <FormField
                                disabled={isLoading}
                                control={form.control}
                                name="customerName"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                id="customerName"
                                                placeholder="Enter customer name"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="customerEmail">
                                Customer Email
                            </Label>
                            <FormField
                                disabled={isLoading}
                                control={form.control}
                                name="customerEmail"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                id="customerEmail"
                                                type="email"
                                                placeholder="Enter customer email"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="description">Description</Label>
                        <FormField
                            disabled={isLoading}
                            control={form.control}
                            name="description"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Textarea
                                            id="description"
                                            placeholder="Enter description"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormDescription>
                                        Add details about invoice items,
                                        quantities, gratuity, and charges.
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-2">
                            <Label htmlFor="amount">Amount ($)</Label>
                            <FormField
                                disabled={isLoading}
                                control={form.control}
                                name="amount"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                id="amount"
                                                type="number"
                                                placeholder="Enter amount"
                                                step="0.01"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormDescription>
                                            Include any service charges and
                                            gratuity in invoice amount.
                                        </FormDescription>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="salesTaxPercentage">
                                Sales Tax (%)
                            </Label>
                            <FormField
                                disabled={isLoading}
                                control={form.control}
                                name="salesTaxPercentage"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                id="salesTaxPercentage"
                                                type="number"
                                                placeholder="Enter sales tax percentage"
                                                step="0.001"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormDescription>
                                            Sales tax will be added to amount
                                            total.
                                        </FormDescription>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    {displayInvoicePreview && (
                        <InvoicePreview
                            customerName={customerName}
                            customerEmail={customerEmail}
                            description={description}
                            amount={amountInCents}
                            salesTaxPercentage={salesTaxPercentage}
                            salesTaxAmount={salesTaxAmount}
                            totalAmount={totalAmount}
                        />
                    )}
                    <Button
                        disabled={isLoading}
                        loading={isLoading}
                        type="submit"
                        className="w-full"
                    >
                        Send Invoice
                    </Button>
                </form>
            </Form>
        </div>
    );
};
