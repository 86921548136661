import { captureException } from "@sentry/react";

import api from "src/api/rest";

export const fetchPromotions = async (storeId: string) => {
    try {
        const res = await api.promotions.list(storeId);
        const promotions = res.data.promotions;
        return promotions;
    } catch (err) {
        captureException(Error(JSON.stringify(err)));
        throw err;
    }
};

export const togglePromotionActive = async (
    promotionId: string,
    isActive: boolean
) => {
    const body = {
        isRunning: isActive
    };
    try {
        const res = await api.promotions.update(promotionId, body);
        const promotion = res.data.promotion;
        return promotion;
    } catch (err) {
        captureException(Error(JSON.stringify(err)));
        throw err;
    }
};
