"use client";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "sonner";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReloadIcon } from "@radix-ui/react-icons";
import { WaitTimeTypeEnum, FeePolicy } from "@snackpass/snackpass-types";
import { NumberInput } from "@tremor/react";
import { Button } from "src/@/components/ui/button";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormMessage
} from "src/@/components/ui/form";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle
} from "src/@/components/ui/card";
import { Switch } from "src/@/components/ui/switch";
import { sendError } from "src/utils/errors";
import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";
import { getActiveStore } from "src/redux/selectors";
import api from "src/api/rest";
import { setActiveStore } from "src/redux/slices";
import { Textarea } from "src/@/components/ui/textarea";
import { Input } from "src/@/components/ui/input";
import { useUnsavedChangesPrompt } from "#settings/hooks/useUnsavedChangesPrompt";
import { UnsavedChangesModal } from "#settings/components/unsaved-changes";

const formSchema = z.object({
    smartReceiptCustomerMessage: z.string().max(275),
    noNotes: z.boolean(),
    defaultBagInCart: z.boolean(),
    flat: z.number(),
    isTaxable: z.boolean(),
    externalReviewUrl: z.string().url().max(275).optional()
});

type ManualWaitTimeType = "specific" | "range";

type BagFeeProps = {
    value: number | undefined;
    onChange: (val: number) => void;
    disabled: boolean;
};

const BagFeeInput = ({ value, onChange, disabled }: BagFeeProps) => {
    const [dollarValue, setDollarValue] = useState((value ?? 0).toFixed(2));

    useEffect(() => {
        setDollarValue((value ?? 0).toFixed(2));
    }, [value]);

    const setNewValue = useCallback(() => {
        if (Number(dollarValue) !== value) {
            setDollarValue(Number(parseFloat(dollarValue ?? 0)).toFixed(2));
            onChange(Number(dollarValue));
        }
    }, [dollarValue, onChange, value]);

    return (
        <NumberInput
            icon={() => <span className="pl-3 text-small">$</span>}
            placeholder="0.00"
            min={0}
            max={1}
            step={0.01}
            className="w-[280px] flex-1 [&>input]:pl-2"
            value={dollarValue}
            onBlur={() => {
                setNewValue();
            }}
            onChange={(e) => {
                setDollarValue(e.target.value);
            }}
            disabled={disabled}
            enableStepper={false}
        />
    );
};

export function SettingsAllOrders() {
    const [isLoading, setIsLoading] = useState(false);
    const canEdit = useHasEditSettingsForActiveStore();
    const activeStore = useSelector(getActiveStore);
    const dispatch = useDispatch();

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            smartReceiptCustomerMessage: "",
            noNotes: false,
            defaultBagInCart: false,
            flat: 0,
            isTaxable: false,
            externalReviewUrl: ""
        }
    });

    const valuesFromActiveStore: z.infer<typeof formSchema> = useMemo(() => {
        const bagFeePolicy = activeStore?.feePolicies?.find(
            (p) => p.name === "BAG_STORE_FEE"
        );
        return {
            smartReceiptCustomerMessage:
                activeStore?.smartReceiptCustomerMessage ?? "",
            noNotes: activeStore?.noNotes ?? false,
            defaultBagInCart: activeStore?.defaultBagInCart === 1,
            pickupTimeType:
                (activeStore?.pickupTimeType as ManualWaitTimeType) ?? "range",
            waitTimeType: activeStore?.waitTimeType ?? WaitTimeTypeEnum.Dynamic,
            defaultPickupMinTime: activeStore?.defaultPickupMinTime ?? 10,
            defaultPickupMaxTime: activeStore?.defaultPickupMaxTime ?? 20,
            defaultPickupTime: activeStore?.defaultPickupTime ?? 10,
            flat: bagFeePolicy ? bagFeePolicy.flat : 0,
            isTaxable: bagFeePolicy ? !!bagFeePolicy.isTaxable : false,
            externalReviewUrl: activeStore?.externalReviewUrl ?? ""
        };
    }, [activeStore]);

    useEffect(() => {
        if (activeStore) {
            form.reset(valuesFromActiveStore);
        }
    }, [activeStore, form, valuesFromActiveStore]);

    const resetForm = useMemo(
        () => () => {
            form.reset(valuesFromActiveStore);
        },
        [form, valuesFromActiveStore]
    );

    const onSubmit = useCallback(
        async (values: z.infer<typeof formSchema>) => {
            if (!activeStore) {
                return;
            }

            if (!canEdit) {
                toast.error("You do not have permission to edit this store");
                return;
            }

            try {
                setIsLoading(true);
                const bagFeePolicy =
                    values.flat > 0
                        ? ({
                              name: "BAG_STORE_FEE",
                              payer: "customer",
                              recipient: "store",
                              flat: values.flat,
                              percent: 0,
                              isTaxable: !!values.isTaxable
                          } as FeePolicy)
                        : undefined;
                const { data } = await api.stores.update(activeStore._id, {
                    smartReceiptCustomerMessage:
                        values.smartReceiptCustomerMessage,
                    noNotes: values.noNotes,
                    defaultBagInCart: values.defaultBagInCart,
                    externalReviewUrl: values.externalReviewUrl
                });
                const bagResponse = await api.stores.updateBagFeePolicy(
                    activeStore._id,
                    bagFeePolicy
                );
                const mergedData = Object.keys(form.formState.dirtyFields).some(
                    (field) => field === "isTaxable" || field === "flat"
                )
                    ? {
                          ...data.store,
                          feePolicies: bagResponse.data.store.feePolicies
                      }
                    : data.store;

                dispatch(setActiveStore(mergedData));
                toast.success("Store updated successfully");
            } catch (e) {
                sendError(e);
                toast.error("Can not update the store, please try again later");
            } finally {
                setIsLoading(false);
            }
        },
        [activeStore, canEdit, dispatch, form, setIsLoading]
    );

    useEffect(() => {
        const subscription = form.watch(() => {});
        return () => subscription.unsubscribe();
    }, [form]);

    const {
        showModal,
        handleConfirmNavigationClick,
        handleCancelNavigationClick
    } = useUnsavedChangesPrompt(form.formState.isDirty);

    return (
        <div className="h-max overflow-auto bg-gray-50">
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="mb-24 space-y-8 p-8 sm:p-16"
                >
                    <UnsavedChangesModal
                        show={showModal}
                        onConfirm={handleConfirmNavigationClick}
                        onCancel={handleCancelNavigationClick}
                    />
                    <div>
                        <CardTitle className="text-2xl font-bold">
                            All Orders
                        </CardTitle>
                        <div className="text-gray-600">
                            Configure your settings that affect all channels,
                            including mobile, online, kiosk and register.
                        </div>
                        <hr className="border-gray-300" />
                    </div>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Receipt
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="smartReceiptCustomerMessage"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Smart receipt customer message
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Add an announcement for
                                                customers that appears on the
                                                receipt texted to all customers
                                                after ordering.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pb-2 pt-4">
                                                    <Textarea
                                                        className="resize-none outline-none [scrollbar-width:'none'] [&::-webkit-scrollbar]:hidden"
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        rows={3}
                                                        maxLength={275}
                                                        disabled={!canEdit}
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormDescription className="text-xs">
                                                Characters: {field.value.length}
                                                /275
                                            </FormDescription>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="externalReviewUrl"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                External review URL
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Enter the URL of your
                                                restaurant's review page (e.g.,
                                                Yelp, Google). After customers
                                                rate their order, they can click
                                                'Add Review' to share their
                                                experience on the linked
                                                platform.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pb-2 pt-4">
                                                    <Input
                                                        className="resize-none outline-none [scrollbar-width:'none'] [&::-webkit-scrollbar]:hidden"
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        placeholder="https://www.yelp.com/writeareview/..."
                                                        maxLength={275}
                                                        disabled={!canEdit}
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">Cart</CardTitle>
                            <CardDescription>
                                Cart rules will apply to all channels (mobile,
                                online, kiosk, and register).
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="noNotes"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Disable custom notes
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Disable customers from
                                                    adding item level and order
                                                    level notes.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="defaultBagInCart"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Automatically include bag
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    A bag will be added to the
                                                    cart for all purchases.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="flat"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Bag Fee
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Modify the cost of a single bag.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pb-2 pt-4">
                                                    <BagFeeInput
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="isTaxable"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Enable Bag Tax
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Tax bags on checkout.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    {form.formState.isDirty ? (
                        <div className="fixed bottom-0 left-0 flex w-full flex-row items-center justify-end gap-4 border-t bg-white p-2">
                            <Button
                                type="button"
                                variant={"outline"}
                                onClick={resetForm}
                            >
                                Cancel
                            </Button>
                            <Button disabled={isLoading} type="submit">
                                {isLoading && (
                                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                Submit
                            </Button>
                        </div>
                    ) : null}
                </form>
            </Form>
        </div>
    );
}
