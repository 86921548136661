/** @jsxImportSource @emotion/react */
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { toDollar } from "@snackpass/accounting";
import "antd/dist/antd.css";
import { css } from "@emotion/react";
import { IProduct } from "@snackpass/snackpass-types";
import { toast } from "sonner";

import { optimizedImageURL } from "src/utils/image";

type Props = {
    item: IProduct;
    index: number;
    data: IProduct[];
    onShow: () => void;
    setProductInEdit: (value: IProduct) => void;
};

const Card = ({ item, onShow, setProductInEdit }: Props) => {
    const CardComponent = (
        <div css={cardContainer}>
            <div css={Container}>
                <div
                    css={imageWrapper}
                    onClick={() => {
                        setProductInEdit(item);
                        onShow();
                    }}
                    style={{
                        backgroundImage: `url("${optimizedImageURL(item.image, {
                            size: { w: 200, h: 140 }
                        })}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: 200,
                        height: 140
                    }}
                ></div>
            </div>
            <div css={Container}>
                <CopyToClipboard
                    text={item.image ?? ""}
                    onCopy={() => toast.success("Copied to clipboard")}
                >
                    <button css={copyImageBtn}>copy image url</button>
                </CopyToClipboard>
            </div>
            <div
                css={textContainer}
                onClick={() => {
                    setProductInEdit(item);
                    onShow();
                }}
            >
                {item.name}
            </div>
            <div css={textContainer}>{toDollar(item.price)}</div>
        </div>
    );

    return CardComponent;
};

export default Card;

const cardContainer = css`
    margin-bottom: 1rem;
    margin-top: 1.5rem;
`;
const Container = css`
    display: flex;
    flex-direction: row;
    font-family: "Inter";
    font-size: 16px;
    align-items: start;
    justify-content: start;
`;

const imageWrapper = css`
    width: 200px;
    height: 140px;
    overflow: hidden;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 8px;
    &:hover {
        opacity: 0.8;
    }
`;

const copyImageBtn = css`
    height: 30px;
    width: auto;
    font-size: 14px;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-left: 14px;
`;

const textContainer = css`
    display: flex;
    flex-direction: row;
    font-family: "Inter";
    font-size: 16px;
    align-items: start;
    justify-content: start;
    padding-left: 14px;
    font-weight: 600;
    cursor: pointer;
`;
