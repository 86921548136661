import React, { useContext } from "react";
import styled from "styled-components";
import { ScreenState } from "@snackpass/snackpass-types";

import Text from "#payouts/components/shared/Text";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { StatusReasons, Statuses } from "#payouts/domain/types";
import { Button } from "#payouts/components/shared/Button";
import config from "#config";

const BASE_STRIPE_URL = "https://dashboard.stripe.com";

const generateLink = (id: string) =>
    `${BASE_STRIPE_URL}/${
        config.isProduction ? "" : "test/"
    }connect/accounts/${id}`;

const PageHeader = (): JSX.Element => {
    const { isMobile } = useWindowDimensions();
    const { isSeniorSupport, isEmployee, verification, handleModalChange } =
        useContext(PayoutsSettingsContext);

    const status = verification?.status;
    const statusReason = verification?.statusReason;

    const showPause = status === Statuses.enabled;
    const showEnable =
        !showPause &&
        status === Statuses.paused &&
        statusReason === StatusReasons.snackpassAdminHold;

    const style = { width: "130px", font: "14px Inter" };

    const handleClick = () => {
        handleModalChange({ adminHold: true });
    };

    const PausePayouts = () => {
        if (!isSeniorSupport || !showPause) return <></>;
        return (
            <Button
                smallRadius
                variant="primary"
                onClick={handleClick}
                size="small"
                style={style}
            >
                Pause Payouts
            </Button>
        );
    };

    const EnablePayouts = () => {
        if (!isSeniorSupport || !showEnable) return <></>;
        return (
            <Button
                smallRadius
                variant="danger"
                onClick={handleClick}
                size="small"
                style={style}
            >
                Enable Payouts
            </Button>
        );
    };

    const MainTitle = () =>
        isEmployee && verification?.connectId ? (
            <>
                <a
                    href={generateLink(verification.connectId)}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: "inherit", // Uses the parent's text color
                        textDecoration: "none" // Removes the underline
                    }}
                >
                    {text.payouts.message}
                </a>
            </>
        ) : (
            <>{text.payouts.message}</>
        );

    return (
        <PageHeaderWrapper>
            <TitleWrapper>
                <Text.Title2>
                    <MainTitle />
                </Text.Title2>
                {!isMobile && (
                    <SubtitleContainer>
                        <Text.Subtitle>
                            {text.payouts.description}
                        </Text.Subtitle>
                    </SubtitleContainer>
                )}
            </TitleWrapper>
            <PausePayouts />
            <EnablePayouts />
        </PageHeaderWrapper>
    );
};

export default PageHeader;

const text = {
    payouts: {
        message: "Payouts",
        description: "Manage payout schedule and required company information"
    }
};

const TitleWrapper = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
`;

const SubtitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    justify-content: space-between;

    h2 {
        margin: auto 0;
    }
`;

const PageHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    width: 100%;
    justify-content: space-between;

    margin: ${({ theme }) =>
        `${theme.spacing.base} ${theme.spacing.quadruple}`};

    & .container-fluid {
        padding: 0;
    }

    @media ${ScreenState.DESKTOP} {
        max-width: 600px;
    }

    @media ${ScreenState.MOBILE} {
        max-width: 92.5%;
        margin-left: ${({ theme }) => theme.spacing.base};
        margin-right: ${({ theme }) => theme.spacing.base};
    }

    @media ${ScreenState.TABLET} {
        max-width: 95%;
        margin-left: ${({ theme }) => theme.spacing.baseAndAHalf};
        margin-right: ${({ theme }) => theme.spacing.tripe};
    }
`;
