import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "sonner";

import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";
import { FormCard } from "src/@/components/form-card";
import { getUser } from "src/redux/selectors";
import { useUpdateSelf } from "#settings/hooks/use-update-self";

export const NameSchema = z.object({
    firstName: z.string().min(1),
    lastName: z.string().min(1)
});

export function ShowEditName() {
    const user = useSelector(getUser);

    const { isLoading, mutate, isSuccess, error } = useUpdateSelf();

    const form = useForm<z.infer<typeof NameSchema>>({
        resolver: zodResolver(NameSchema),
        defaultValues: {
            firstName: user?.firstName ?? "",
            lastName: user?.lastName ?? ""
        }
    });

    const onSubmit = () => {
        mutate(form.getValues());
    };

    useEffect(() => {
        if (error) {
            toast.error("Unable to update name", {
                description: error?.message || "Attempt failed"
            });
        }
    }, [error]);

    useEffect(() => {
        if (isSuccess) {
            toast.success("Update saved!");
            // we need to reload the site to get user updates
            // currently refetching the user leaves the site blank
            window.location.reload();
        }
    }, [isSuccess]);

    return (
        <Form {...form}>
            <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="w-full max-w-[900px]"
            >
                <FormCard
                    title="Full Name"
                    subtitle="Please enter your full name as it appears on your License or ID."
                    showSaveButton
                    disabled={!form.formState.isDirty || isLoading}
                >
                    <div className="flex items-center justify-between max-md:flex-col md:space-x-3">
                        <FormField
                            control={form.control}
                            name="firstName"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>First name</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder="First name"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormDescription />
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="lastName"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>Last name</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder="Last name"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormDescription />
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </FormCard>
            </form>
        </Form>
    );
}
