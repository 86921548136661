import { IStore } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";
import { AdjustedAddon } from "../adjusted-addon";

const allowedToBeLowercase = [
    "or",
    "and",
    "with",
    "the",
    "w/",
    "oz",
    "de",
    "of",
    "pcs"
];

class AddonTitleCaseCheck implements IMenuAutomationCheck<AdjustedAddon> {
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "AddonTitleCaseCheck";
    }

    get itemToCheck(): ItemType {
        return "addon";
    }

    checkItems(): MenuIssue<AdjustedAddon>[] {
        return [];
    }

    checkItem(item: AdjustedAddon): MenuIssue<AdjustedAddon> | null {
        const words = item.name.split(" ");
        for (const word of words) {
            // if a word's first letter is not uppercase
            // and it is not allowed to be lowercase
            if (
                word.charAt(0) !== word.charAt(0).toUpperCase() &&
                !allowedToBeLowercase.includes(word)
            ) {
                return new MenuIssue<any>(
                    "AddonNotTitleCase",
                    "Modifiers must be title cased (first letter of each word capitalized).",
                    "warning",
                    item
                );
            }
        }
        return null;
    }
}

export { AddonTitleCaseCheck };
