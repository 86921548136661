import { useContext } from "react";

import { TaxFormsContext } from "#tax-forms/Context";

export const ContentContainer = ({
    children
}: {
    children: React.ReactNode;
}) => {
    const { emailVerified } = useContext(TaxFormsContext);
    return emailVerified ? <>{children}</> : null;
};
