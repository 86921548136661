import {
    FeePolicyPayer,
    FeePolicyRecipient,
    FeePolicyStrategy,
    PaymentProvider
} from "@snackpass/snackpass-types";

import { OperatingFormValues } from "#settings/settings-fees/utils/types";

const buildPolicy = ({
    source,
    flat,
    percent,
    strategy
}: {
    source: "app" | "kiosk" | "online";
    flat: number;
    percent: number;
    strategy: FeePolicyStrategy;
}) => ({
    name: "SNACKPASS_CONVENIENCE_FEE",
    description: null,
    payer: FeePolicyPayer.Customer,
    recipient: FeePolicyRecipient.Snackpass,
    flat,
    percent,
    strategy,
    rules: {
        transactionSources: [source],
        excludedPaymentProviders: [PaymentProvider.inStore]
    }
});

export const buildOperatingPolicies = (values: OperatingFormValues) => [
    buildPolicy({
        source: "app",
        flat: values.appFlat,
        percent: values.appPercent,
        strategy: values.appStrategy
    }),
    buildPolicy({
        source: "kiosk",
        flat: values.kioskFlat,
        percent: values.kioskPercent,
        strategy: values.kioskStrategy
    }),
    buildPolicy({
        source: "online",
        flat: values.onlineFlat,
        percent: values.onlinePercent,
        strategy: values.onlineStrategy
    })
];
