import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { ScreenState } from "@snackpass/snackpass-types";
import { useCallback, useEffect, useState, KeyboardEvent } from "react";
import styled from "styled-components";
import {
    sendPasswordResetEmail,
    signInWithCustomToken,
    signInWithEmailAndPassword
} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { toast } from "sonner";

import api from "src/api/rest";
import { firebaseAuth } from "#app/firebase";
import { sendError } from "src/utils/errors";
import { EmailVerification } from "#email-verification";
import { Button } from "src/@/components/ui/button";
import { Input } from "src/@/components/ui/input";

const _verifyUser = async (phoneNumber: string) => {
    const code = prompt(
        "We just sent you a verification code! Please enter it here:"
    );

    if (!code) {
        return;
    }

    try {
        const verifyPhoneResponse = await api.auth.verify(
            phoneNumber,
            code,
            false // Do not create a new user if one does not exist
        );
        const { customAuthToken, user } = verifyPhoneResponse.data;
        if (!user) {
            toast.error("No user available");
            return;
        }
        await signInWithCustomToken(firebaseAuth, customAuthToken);
    } catch (err) {
        toast.error("Invalid verification code");
    }
};

const _promptPhoneNumber = async () => {
    const phoneNumber = prompt("Enter phone number here 📲");

    if (!phoneNumber) {
        return;
    }

    try {
        const response = await api.auth.sendVerification(phoneNumber);
        if (response?.status !== 200) {
            toast.error("Invalid phone number");
            return;
        }
        void _verifyUser(phoneNumber);
    } catch (err) {
        toast.error("Invalid phone number");
    }
};

type Props = {
    showEmailVerification: boolean;
    setHasVerifiedEmail: (hasVerifiedEmail: boolean) => void;
};

const SignIn = ({ showEmailVerification, setHasVerifiedEmail }: Props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const username = query.get("username");
        const password = query.get("password");

        if (username && password) {
            setEmail(username);
            setPassword(password);
        }
    }, []);

    const login = useCallback(() => {
        if (!email || !password) {
            toast.error("Please fill in all fields.");
            return;
        }

        signInWithEmailAndPassword(firebaseAuth, email.trim(), password).catch(
            (err: Error | FirebaseError) => {
                sendError(err);

                const errorMessage = ((e: Error | FirebaseError) => {
                    if (e instanceof FirebaseError && e.code) {
                        // Translate Firebase error message into something more
                        // user-friendly.
                        switch (e.code) {
                            case "auth/invalid-email":
                                return "The email address is formatted incorrectly.";
                            case "auth/user-disabled":
                                return "This account has been disabled. Please contact Snackpass support for more information.";
                            case "auth/user-not-found":
                                return "There is no account associated with the provided credentials.";
                            case "auth/wrong-password":
                                return "There is no account associated with the provided credentials.";
                            default:
                                return e.message;
                        }
                    } else {
                        return e.message;
                    }
                })(err);
                toast.error(errorMessage);
            }
        );
    }, [email, password]);

    const resetPassword = async () => {
        if (!email) {
            toast.error("Email required", {
                description:
                    "Please input your account's email address to reset its password."
            });
            return;
        }

        await sendPasswordResetEmail(firebaseAuth, email)
            .then(async () =>
                toast.success("Email sent", {
                    description:
                        "An email has been sent with a link to reset your password."
                })
            )
            .catch(async (err: FirebaseError) => {
                sendError(err);
                toast.error("Email could not be sent", {
                    description: err.message
                });
            });
    };

    const onEnter = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                login();
            }
        },
        [login]
    );

    return (
        <PageWrapper>
            <div style={styles.signinWrapper}>
                <img
                    src="https://firebasestorage.googleapis.com/v0/b/futura-c7f12-1gezdsnacksite/o/Vector.png?alt=media&token=d8129da8-ed62-4aa6-bf08-6b6f0c11a01b"
                    alt="Logo"
                    style={{
                        height: 60,
                        width: 60
                    }}
                />
                {showEmailVerification ? (
                    <EmailVerification
                        setHasVerifiedEmail={setHasVerifiedEmail}
                    />
                ) : (
                    <>
                        <br />
                        <p className="text-lg">Snackpass Partner Dashboard</p>

                        <br />

                        <Button variant="outline" onClick={_promptPhoneNumber}>
                            Sign In With Phone Number
                        </Button>
                        <br />
                        <p>or</p>
                        <br />
                        <Input
                            placeholder="email"
                            type="email"
                            name={"email"}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                        <br />
                        <Input
                            placeholder="password"
                            type="password"
                            name={"password"}
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            onKeyDown={onEnter}
                        />
                        <br />
                        <Button onClick={login}>Sign In</Button>

                        <br />
                        <Button onClick={resetPassword} variant={"outline"}>
                            Reset Password
                        </Button>
                    </>
                )}
            </div>
        </PageWrapper>
    );
};

export default SignIn;

// This unblocks the keyboard on order-hub webview for log-ins
const PageWrapper = styled.div`
    @media ${ScreenState.TABLET} {
        height: 150vh;
    }
`;

const flexCol: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
};

const panel = {
    background: "white",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.05)",
    borderRadius: 8,
    margin: 10,
    padding: 15,
    alignSelf: "stretch"
};

const styles = {
    signinWrapper: {
        ...flexCol,
        ...panel,
        justifyContent: "center",
        margin: "50px auto",
        width: "100%",
        maxWidth: 400,
        padding: "50px 25px",
        textAlign: "center",
        overflow: "hidden"
    } as CSSProperties,
    imageStyle: {
        width: 75,
        display: "inline-block",
        height: 75,
        marginLeft: 5,
        marginRight: 20
    } as CSSProperties
};
