import styled from "styled-components";
import React from "react";
import { Redirect } from "react-router-dom";

import { OrderList } from "#catering/components/order-list";
import { OrderDetail } from "#catering/components/order-detail";
import { Routes } from "#navigation/routes";
import { useCateringEnabled } from "#navigation/utils";

const Catering = () => {
    const cateringEnabled = useCateringEnabled();

    if (!cateringEnabled) return <Redirect to={Routes.SettingsProducts} />;

    return (
        <Container>
            <OrderList />
            <OrderDetail />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    font-family: Inter;
`;

export default Catering;
