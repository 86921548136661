import { useMemo } from "react";

import { useAppSelector } from "src/redux/hooks";
import { getLegacyProducts } from "src/redux/selectors";

export type ProductToCategory = { [productId: string]: string };

/**
 * Provides a lookup map for product ID to its primary category.
 */
export function useProductToCategoryMap() {
    const legacyProducts = useAppSelector(getLegacyProducts);

    return useMemo(
        () =>
            legacyProducts.reduce<ProductToCategory>((acc, product) => {
                if (!product.categoryId) return acc;
                acc[product._id] = product.categoryId;
                return acc;
            }, {}),
        [legacyProducts]
    );
}
