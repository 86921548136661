export const sortByCategory = (
    firstCategory: string,
    secondCategory: string,
    activeCategory: string
) => {
    const firstInCategory = firstCategory === activeCategory;
    const secondInCategory = secondCategory === activeCategory;

    // 0: leave as is
    // 1: sort second before first
    // -1: sort first before second
    if (!firstInCategory) {
        // neither in category
        if (!secondInCategory) return 0;
        else return 1; // second in category but first is not
    } else {
        // first in category but second not
        if (!secondInCategory) return -1;
        else return 0; // both in category
    }
};
