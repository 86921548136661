import { SegmentedMessage } from "sms-segments-calculator";
import { z } from "zod";

import { GetStoreAudienceResponse } from "src/api/graphql/apollo";

export enum CampaignAudienceType {
    Followers = "FOLLOWERS",
    NeedsAttention = "NEEDS_ATTENTION",
    NewCustomers = "NEW_CUSTOMERS",
    RecentCustomers = "RECENT_CUSTOMERS",
    Test = "TEST"
}

export function getLabelForAudienceType(type: CampaignAudienceType) {
    switch (type) {
        case CampaignAudienceType.Followers:
            return "Followers";
        case CampaignAudienceType.NeedsAttention:
            return "Needs Attention";
        case CampaignAudienceType.NewCustomers:
            return "New Customers";
        case CampaignAudienceType.RecentCustomers:
            return "Recent Customers";
        case CampaignAudienceType.Test:
            return "Test Customers";
    }
}

export function getNumberCustomersFromAudienceType(
    type: CampaignAudienceType,
    data: GetStoreAudienceResponse | undefined
): number | undefined {
    switch (type) {
        case CampaignAudienceType.Followers:
            return data?.storeAudience?.followersWithIndividualConsent ?? 0;
        case CampaignAudienceType.NeedsAttention:
            return (
                data?.storeAudience
                    ?.needAttentionCustomersWithIndividualConsent ?? 0
            );

        case CampaignAudienceType.NewCustomers:
            return data?.storeAudience?.newCustomersWithIndividualConsent ?? 0;
        case CampaignAudienceType.RecentCustomers:
            return (
                data?.storeAudience?.recentCustomersWithIndividualConsent ?? 0
            );
        case CampaignAudienceType.Test:
            return (
                data?.storeAudience?.testCustomersWithIndividualConsent ??
                undefined
            );
    }
}

export function getNumberCustomersFromAudienceTypeIncludingUnreachable(
    type: CampaignAudienceType,
    data: GetStoreAudienceResponse | undefined
): number | undefined {
    switch (type) {
        case CampaignAudienceType.Followers:
            return data?.storeAudience?.followers ?? 0;
        case CampaignAudienceType.NeedsAttention:
            return data?.storeAudience?.needAttentionCustomers ?? 0;

        case CampaignAudienceType.NewCustomers:
            return data?.storeAudience?.newCustomers ?? 0;
        case CampaignAudienceType.RecentCustomers:
            return data?.storeAudience?.recentCustomers ?? 0;
        case CampaignAudienceType.Test:
            return data?.storeAudience?.testCustomers ?? undefined;
    }
}

// We want to keep messages within 2 segments, which is 313 characters no emojis,
// and 137 characters with emojis. However, we retain 53 characters for the "Reply STOP to unsubscribe" (25 characaters)
// and store display name (24 chars) + 4 linking characters of ": \n\n"
// Note that emojis already cost 2 characters in terms of calling .length on the string,
// which is the same as how it'll be counted in SegmentedMessage
export const MAX_MESSAGE_LENGTH = 250; // This is 313 - 53 = 260, but rounded down to 250.
export const MAX_MESSAGE_WITH_EMOJI = 80; // This is 137 - 53 = 84, but rounded down to 80.

export const COST_PER_CUSTOMER = 0.03;
export const MAX_CUSTOMERS = 50000; // Any higher could risk queue overloading twilio, depending on how quickly we're dumping to twilio

export const newCampaignSMSFormSchema = z.object({
    campaignName: z.string().min(3),
    segment: z.nativeEnum(CampaignAudienceType),
    percentTarget: z.coerce.number().min(0).max(100),
    estimatedCost: z.coerce
        .number()
        .min(COST_PER_CUSTOMER, "Must send to at least 1 customer")
        .max(
            MAX_CUSTOMERS * COST_PER_CUSTOMER,
            "Cannot target more than 50,000 customers"
        ),
    message: z
        .string()
        .min(5, "Text message must be at least 5 characters long!")
        .max(
            MAX_MESSAGE_LENGTH,
            `Text message must be less than ${MAX_MESSAGE_LENGTH} characters`
        )
        .refine(
            (data) => {
                const segmentedMessage = new SegmentedMessage(data);
                if (segmentedMessage.getNonGsmCharacters().length === 0) {
                    return true;
                }
                return data.length <= MAX_MESSAGE_WITH_EMOJI;
            },
            (data) => {
                const specialCharacters = new SegmentedMessage(
                    data
                ).getNonGsmCharacters();
                return {
                    message: `Text message must be less than ${MAX_MESSAGE_WITH_EMOJI} characters when any special characters are used, and each special character costs 2. The following are special characters: ${specialCharacters.join(
                        ","
                    )}`
                };
            }
        )
});
