import { Space } from "antd";
import { useSelector } from "react-redux";

import { isNotUndefined } from "#core";
import { commonStyles } from "#menu-editor/multi-menus/helpers/styles";
import { getTaxOverrideSelector } from "#menu-editor/multi-menus/redux/selectors";
import { Text } from "#menu-editor/multi-menus/styled-components/text";

type TaxesText = {
    productId: string;
    productBaseTaxes: number;
    deliveryTax: number | undefined;
    pickUpTax: number | undefined;
    dineInTax: number | undefined;
};

export const TaxesText: React.FC<TaxesText> = ({
    productId,
    productBaseTaxes,
    deliveryTax,
    pickUpTax,
    dineInTax
}) => {
    const taxesOverrideSelector = getTaxOverrideSelector(productId);
    const taxesOverride = useSelector(taxesOverrideSelector);

    if (
        isNotUndefined(taxesOverride) ||
        (isNotUndefined(deliveryTax) &&
            isNotUndefined(pickUpTax) &&
            isNotUndefined(dineInTax))
    )
        return (
            <Space style={commonStyles.displayFlex} direction="horizontal">
                <Text
                    type="body-regular"
                    color={taxesOverride?.pickup ? "black" : "grey"}
                >
                    {taxesOverride?.pickup ?? pickUpTax ?? productBaseTaxes}
                    %,
                </Text>
                <Text
                    type="body-regular"
                    color={taxesOverride?.delivery ? "black" : "grey"}
                >
                    {taxesOverride?.delivery ?? deliveryTax ?? productBaseTaxes}
                    %,
                </Text>
                <Text
                    type="body-regular"
                    color={taxesOverride?.dineIn ? "black" : "grey"}
                >
                    {taxesOverride?.dineIn ?? dineInTax ?? productBaseTaxes}%
                </Text>
            </Space>
        );

    return (
        <Text type="body-regular" color="grey">
            {productBaseTaxes}%
        </Text>
    );
};
