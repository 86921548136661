import React from "react";
import clsx from "clsx";

import { ReactComponent as ErrorIcon } from "src/assets/icons/rules-alert.svg";

export interface Props {
    className?: string;
    text?: string;
}

const ErrorChart = ({ className, text = "No Data" }: Props) => (
    <div
        className={clsx(
            "flex flex-col items-center justify-center bg-neutral-300",
            className
        )}
    >
        <ErrorIcon className="m-0 h-10 w-10 [&>path]:fill-neutral-500" />
        <p className="mb-4 mt-2 text-body text-neutral-500">{text}</p>
    </div>
);

export default ErrorChart;
