import { ColumnDef } from "@tanstack/react-table";
import { clsx } from "clsx";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { match } from "ts-pattern";
import { Spinner } from "react-activity";
import { toast } from "sonner";

import { DataTable } from "src/@/components/ui/data-table";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { UserInvite } from "src/core/types";
import { PendingInviteColumn } from "#settings/settings-permissions/types";
import { Button } from "src/@/components/ui/button";
import { useCancelInvite } from "#settings/settings-permissions/hooks/use-cancel-invite";
import { useResendInvite } from "#settings/settings-permissions/hooks/user-resend-invite";

type Props = {
    invites: UserInvite[];
    refreshData: () => Promise<void>;
};

export const PendingInvites = ({ invites, refreshData }: Props) => {
    const [inviteLoading, setInviteLoading] = useState<string | undefined>();
    const pendingInvites = invites.filter(
        (invite) => invite.status === "Active"
    );

    const {
        isSuccess: cancelIsSuccess,
        error: cancelError,
        mutate: goCancel
    } = useCancelInvite();

    const {
        isSuccess: resendIsSuccess,
        error: resendError,
        mutate: goResend
    } = useResendInvite();

    const cancelInvite = useCallback(
        (inviteId?: string) => {
            setInviteLoading(inviteId);
            goCancel(inviteId);
        },
        [goCancel]
    );

    useEffect(() => {
        if (cancelIsSuccess) {
            toast.success("Invite canceled!");
            setInviteLoading(undefined);
            void refreshData();
        }
    }, [cancelIsSuccess, refreshData]);

    useEffect(() => {
        if (cancelError) {
            toast.error("Failed to cancel invite", {
                description: cancelError?.message
            });
            setInviteLoading(undefined);
            void refreshData();
        }
    }, [cancelError, refreshData]);

    const resendInvite = useCallback(
        (inviteId?: string) => {
            setInviteLoading(inviteId);
            goResend(inviteId);
        },
        [goResend]
    );

    useEffect(() => {
        if (resendIsSuccess) {
            toast.success("Invite email resent!");
            setInviteLoading(undefined);
        }
    }, [resendIsSuccess]);

    useEffect(() => {
        if (resendError) {
            toast.error("Failed to resend invite email", {
                description: resendError?.message
            });
            setInviteLoading(undefined);
        }
    }, [resendError]);

    const columns: ColumnDef<PendingInviteColumn>[] = useMemo(
        () => [
            {
                id: "email",
                header: ({ column }) => (
                    <DataTableColumnHeader column={column} title="Email" />
                ),
                accessorKey: "name",
                cell: ({ row }) => (
                    <div className="text-small font-normal">
                        {row.original.email}
                    </div>
                )
            },
            {
                id: "identifier",
                header: ({ column }) => (
                    <DataTableColumnHeader column={column} title="Identifier" />
                ),
                accessorKey: "identifier",
                cell: ({ row }) => (
                    <div className="text-small font-normal">
                        {row.original.identifier}
                    </div>
                )
            },
            {
                id: "jobTitle",
                header: ({ column }) => (
                    <DataTableColumnHeader column={column} title="Job Title" />
                ),
                accessorKey: "name",
                cell: ({ row }) => (
                    <div className="text-small font-normal">
                        {row.original.jobTitle}
                    </div>
                )
            },
            {
                id: "expires",
                header: ({ column }) => (
                    <DataTableColumnHeader column={column} title="Expires" />
                ),
                accessorKey: "name",
                cell: ({ row }) => {
                    const expires = DateTime.fromISO(row.original.createdAt)
                        .plus({ days: 30 })
                        .toFormat("LLL dd, yyyy");
                    return (
                        <div className="text-small font-normal">{expires}</div>
                    );
                }
            },
            {
                id: "status",
                header: ({ column }) => (
                    <DataTableColumnHeader column={column} title="Status" />
                ),
                accessorKey: "name",
                cell: ({ row }) => {
                    const color = match(row.original.status)
                        .with("Accepted", () => "text-green-500")
                        .with("Declined", () => "text-critical-light")
                        .with("Canceled", () => "text-amber-400")
                        .otherwise(() => "");
                    const statusName = match(row.original.status)
                        .with("Active", () => "Pending")
                        .otherwise((status) => status);
                    return (
                        <div className={clsx("text-small font-normal", color)}>
                            {statusName}
                        </div>
                    );
                }
            },
            {
                id: "actions",
                header: ({ column }) => (
                    <DataTableColumnHeader column={column} title="Actions" />
                ),
                accessorKey: "actions",
                cell: ({ row }) => (
                    <div className="flex items-center justify-between space-x-2">
                        {row.original.status === "Active" && (
                            <>
                                <Button
                                    disabled={!!inviteLoading}
                                    variant="outline-destructive"
                                    onClick={async () => {
                                        await cancelInvite(row.original._id);
                                    }}
                                >
                                    {inviteLoading === row.original._id ? (
                                        <Spinner />
                                    ) : (
                                        "Cancel"
                                    )}
                                </Button>

                                <Button
                                    disabled={!!inviteLoading}
                                    variant="default"
                                    onClick={async () => {
                                        await resendInvite(row.original._id);
                                    }}
                                >
                                    {inviteLoading === row.original._id ? (
                                        <Spinner />
                                    ) : (
                                        "Resend"
                                    )}
                                </Button>
                            </>
                        )}
                    </div>
                )
            }
        ],
        [cancelInvite, inviteLoading, resendInvite]
    );

    if (invites.length <= 0) {
        return null;
    }

    return (
        <div>
            <div className="my-5 text-xl">Pending Invites</div>
            <DataTable
                data={pendingInvites}
                columns={columns}
                showPagination={false}
                customPageSize={pendingInvites.length}
            />
        </div>
    );
};
