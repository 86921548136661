import { SystemColors } from "@snackpass/design-system";
import {
    CateringStatusType,
    PurchaseReportData,
    PurchaseStatus
} from "@snackpass/snackpass-types";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import React from "react";
import { toDollar } from "@snackpass/accounting";

import { useQuery } from "#hooks/use-query";
import { humanizePurchaseStatus } from "#order-details/utils";

type Props = {
    purchase: PurchaseReportData;
};

export const OrderListRow = ({ purchase }: Props) => {
    const history = useHistory();
    const query = useQuery();
    const isActive = query.get("order") === purchase.purchaseId;
    const status = humanizePurchaseStatus(purchase.purchase);

    const handleClick = () => {
        const params = new URLSearchParams();
        params.append("order", purchase.purchaseId);
        history.push({ search: params.toString() });
    };

    const showNotif =
        purchase.purchase?.catering?.status ===
            CateringStatusType.unconfirmed &&
        purchase.status !== PurchaseStatus.completed &&
        purchase.status !== PurchaseStatus.canceled;

    return (
        <Container onClick={handleClick} isActive={isActive}>
            <FlexRow>
                {showNotif ? <Circle isActive={isActive} /> : null}
                <Title isActive={isActive}>{purchase.customerName}</Title>
            </FlexRow>
            {status ? (
                <Subtitle isActive={isActive} color={status.color}>
                    {status.text}
                </Subtitle>
            ) : null}
            <FlexRow>
                <Subtitle isActive={isActive}>
                    {purchase.items.length} Items
                </Subtitle>
                <Subtitle isActive={isActive} style={{ margin: "0px 4px" }}>
                    ·
                </Subtitle>
                <Subtitle
                    isActive={isActive}
                    color={SystemColors.v1.sesame}
                    style={{ fontWeight: 600 }}
                >
                    {toDollar(purchase.total)}
                </Subtitle>
            </FlexRow>
        </Container>
    );
};

const Container = styled.div<{ isActive?: boolean }>`
    padding: 16px;
    border-bottom: 1px solid ${SystemColors.v1.gray90};
    cursor: pointer;
    background: ${({ isActive }) =>
        isActive ? SystemColors.v1.candy50 : "transparent"};
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Title = styled.div<{ isActive?: boolean }>`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: ${({ isActive }) =>
        isActive ? SystemColors.v1.white : SystemColors.v1.sesame};
`;
const Subtitle = styled.div<{ isActive?: boolean; color?: string }>`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${({ isActive, color }) =>
        isActive ? SystemColors.v1.white : color || SystemColors.v1.gray40};
`;

const Circle = styled.div<{ isActive?: boolean }>`
    background: ${({ isActive }) =>
        isActive ? SystemColors.v1.white : SystemColors.v1.candy50};
    border-radius: 7px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
`;
