import { debounce } from "lodash";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { Button } from "#menu-editor/multi-menus/styled-components/button";
import { getCurrentMultiMenu } from "#menu-editor/multi-menus/redux/selectors";
import { ButtonsWrapper } from "#menu-editor/multi-menus/styled-components/buttons-wrapper";

type FooterProps = {
    disabled: boolean;
    onCancel: () => Promise<void> | void;
    onSubmit: () => Promise<void>;
};

export const ScreenFooter: React.FC<FooterProps> = ({
    disabled,
    onCancel,
    onSubmit
}) => {
    const currentMenu = useSelector(getCurrentMultiMenu);
    const debouncedHandler = useMemo(
        () => debounce(onSubmit, 300),
        [currentMenu]
    );

    return (
        <ButtonsWrapper>
            <Button type="tertiary" size="regular" onClick={onCancel}>
                Cancel
            </Button>
            <Button
                type="primary"
                size="regular"
                onClick={debouncedHandler}
                disabled={disabled}
            >
                Save
            </Button>
        </ButtonsWrapper>
    );
};
