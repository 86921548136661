import React from "react";

import Switch from "#reusable/input/toggle-input";
import { Text } from "#reusable/text/index";

type Props = {
    title: string;
    switchProps: React.ButtonHTMLAttributes<HTMLButtonElement>;
    subtitle?: string;
    disabled?: boolean;
};

export const SettingsToggle = ({
    title,
    subtitle,
    switchProps,
    disabled
}: Props) => (
    <div className="flex h-fit flex-row items-center justify-between">
        <div className="basis-3/4">
            <Text size="m" weight="regular">
                {title}
            </Text>
            <Text size="s" color="light-grey" weight="thin">
                {subtitle}
            </Text>
        </div>
        <div>
            <Switch
                id={`catering-settings-${title}}`}
                {...switchProps}
                isOn={switchProps.value as any}
                onChange={switchProps.onChange as any}
                disabled={disabled}
            />
        </div>
    </div>
);
