import React, { Dispatch, SetStateAction, useEffect } from "react";
import styled from "styled-components";
import type { Moment } from "moment";
import _ from "lodash";
import { Button } from "antd";
import { ScreenState } from "@snackpass/snackpass-types";

import {
    defaultEndTime,
    defaultStartTime,
    formatSpecialHours,
    SpecialHoursItemType
} from "#reusable/special-hours/helper";

import { SpecialHourRow } from "./special-hours-row";

type SpecialHourProps = {
    hasHours?: boolean;
    setHasHours?: Dispatch<SetStateAction<boolean>>;
    localSpecialHours: SpecialHoursItemType[];
    setLocalSpecialHours: Dispatch<SetStateAction<SpecialHoursItemType[]>>;
    setFieldValue?: (
        field: string,
        value: unknown,
        shouldValidate?: boolean | undefined
    ) => void;
    setFieldTouched?: (
        field: string,
        isTouched?: boolean | undefined,
        shouldValidate?: boolean | undefined
    ) => void;
    fieldName: string;
};

export const SpecialHourRows: React.FC<SpecialHourProps> = ({
    hasHours,
    setHasHours,
    localSpecialHours,
    setLocalSpecialHours,
    setFieldValue,
    setFieldTouched,
    fieldName
}) => {
    const removeItem = (id: number) => {
        if (localSpecialHours.length > 1) {
            const removedArr = [...localSpecialHours].filter(
                (item) => item.id !== id
            );
            setLocalSpecialHours(removedArr);
        } else if (setHasHours) {
            setHasHours(false);
        } else {
            setLocalSpecialHours([]);
        }
        setFieldTouched && setFieldTouched(fieldName, true);
    };

    const updateItemHours = (
        id: number,
        start?: Moment | null,
        end?: Moment | null
    ) => {
        setLocalSpecialHours((existingItems: SpecialHoursItemType[]) =>
            existingItems.map((item) => {
                const updatedItem = JSON.parse(JSON.stringify(item));
                updatedItem.time.start = start;
                updatedItem.time.end = end;
                return item.id === id ? updatedItem : item;
            })
        );
        setFieldTouched && setFieldTouched(fieldName, true);
    };

    useEffect(() => {
        setFieldValue &&
            setFieldValue(fieldName, formatSpecialHours(localSpecialHours));
    }, [JSON.stringify(localSpecialHours)]);

    useEffect(() => {
        if (!hasHours) {
            setLocalSpecialHours([]);
        }
    }, [hasHours]);

    const specialHoursRows = () =>
        localSpecialHours.map((element: SpecialHoursItemType) => (
            <SpecialHourRow
                key={`hour-row + ${element.id}`}
                show={!_.isNil(hasHours) ? hasHours : false}
                id={element.id}
                dayOfWeek={element.dayOfWeek}
                specialHours={localSpecialHours}
                setSpecialHours={setLocalSpecialHours}
                removeItem={removeItem}
                updateItemHours={updateItemHours}
                start={element.time.start}
                end={element.time.end}
                setFieldTouched={setFieldTouched}
                fieldName={fieldName}
            />
        ));

    return (
        <SpecialHoursWrapper show={!_.isNil(hasHours) ? hasHours : false}>
            {!_.isEmpty(localSpecialHours) ? (
                specialHoursRows()
            ) : (
                <StyledButton
                    type="primary"
                    onClick={() => {
                        const specialHours: SpecialHoursItemType[] = [
                            {
                                id: 0,
                                dayOfWeek: 0,
                                time: {
                                    start: defaultStartTime,
                                    end: defaultEndTime
                                }
                            }
                        ];

                        setLocalSpecialHours(specialHours);
                        setFieldTouched && setFieldTouched(fieldName);
                    }}
                >
                    Add store hours
                </StyledButton>
            )}
        </SpecialHoursWrapper>
    );
};

type Props = {
    show: boolean;
};

const SpecialHoursWrapper = styled.span<Props>`
    display: ${(props) => (props.show ? "flex" : "none")};
    flex-direction: column;
`;

const StyledButton = styled(Button)`
    border-radius: 8px;
    @media ${ScreenState.MOBILE} {
        justify-content: center;
        display: flex;
        flex: 1;
    }
`;
