import clsx from "clsx";

type Size = "small" | "medium" | "large";
type NoteType = "default" | "secondary" | "success" | "warning" | "critical";
type NoteStyle = "normal" | "subtle" | "contrast";

type NoteProps = {
    note: string | React.ReactNode;
    size?: Size;
    noteType?: NoteType;
    noteStyle?: NoteStyle;
    title?: string;
    link?: string;
    linkPlaceholder?: string;
};

type NoteTypeStyles = {
    backgroundColor: string;
    textColor: string;
    borderColor: string;
    linkTextColor: string;
    linkBackgroundColor: string;
};

const noteStyleAndTypeMap: {
    [s in NoteStyle]: { [t in NoteType]: NoteTypeStyles };
} = {
    normal: {
        default: {
            backgroundColor: "bg-neutral-50",
            textColor: "text-neutral-950",
            borderColor: "border-neutral-400",
            linkTextColor: "text-neutral-50",
            linkBackgroundColor: "bg-black"
        },
        secondary: {
            backgroundColor: "bg-neutral-50",
            textColor: "text-neutral-600",
            borderColor: "border-neutral-400",
            linkTextColor: "text-neutral-950",
            linkBackgroundColor: "bg-neutral-300"
        },
        success: {
            backgroundColor: "bg-neutral-50",
            textColor: "text-success-light",
            borderColor: "border-neutral-400",
            linkTextColor: "text-neutral-50",
            linkBackgroundColor: "bg-success-light"
        },
        warning: {
            backgroundColor: "bg-neutral-50",
            textColor: "text-warning-light",
            borderColor: "border-neutral-400",
            linkTextColor: "text-neutral-50",
            linkBackgroundColor: "bg-warning-light"
        },
        critical: {
            backgroundColor: "bg-neutral-50",
            textColor: "text-critical-light",
            borderColor: "border-neutral-400",
            linkTextColor: "text-neutral-50",
            linkBackgroundColor: "bg-critical-light"
        }
    },
    subtle: {
        default: {
            backgroundColor: "bg-neutral-300",
            textColor: "text-neutral-950",
            borderColor: "border-neutral-400",
            linkTextColor: "text-neutral-50",
            linkBackgroundColor: "bg-black"
        },
        secondary: {
            backgroundColor: "bg-neutral-300",
            textColor: "text-neutral-600",
            borderColor: "border-neutral-400",
            linkTextColor: "text-neutral-950",
            linkBackgroundColor: "bg-neutral-300"
        },
        success: {
            backgroundColor: "bg-success-subtle-light",
            textColor: "text-success-light",
            borderColor: "border-success-accent-light",
            linkTextColor: "text-neutral-50",
            linkBackgroundColor: "bg-success-light"
        },
        warning: {
            backgroundColor: "bg-warning-subtle-light",
            textColor: "text-warning-a11y-light",
            borderColor: "border-warning-accent-light",
            linkTextColor: "text-neutral-50",
            linkBackgroundColor: "bg-warning-light"
        },
        critical: {
            backgroundColor: "bg-critical-subtle-light",
            textColor: "text-critical-light",
            borderColor: "border-critical-accent-light",
            linkTextColor: "text-neutral-50",
            linkBackgroundColor: "bg-critical-light"
        }
    },
    contrast: {
        default: {
            backgroundColor: "bg-neutral-950",
            textColor: "text-neutral-50",
            borderColor: "border-neutral-950",
            linkTextColor: "text-neutral-50",
            linkBackgroundColor: "bg-neutral-950"
        },
        secondary: {
            backgroundColor: "bg-neutral-600",
            textColor: "text-neutral-50",
            borderColor: "border-neutral-600",
            linkTextColor: "text-neutral-50",
            linkBackgroundColor: "bg-neutral-600"
        },
        success: {
            backgroundColor: "bg-success-light",
            textColor: "text-neutral-50",
            borderColor: "border-success-light",
            linkTextColor: "text-neutral-50",
            linkBackgroundColor: "bg-success-light"
        },
        warning: {
            backgroundColor: "bg-warning-light",
            textColor: "text-neutral-50",
            borderColor: "border-warning-light",
            linkTextColor: "text-neutral-50",
            linkBackgroundColor: "bg-warning-light"
        },
        critical: {
            backgroundColor: "bg-critical-light",
            textColor: "text-neutral-50",
            borderColor: "border-critical-light",
            linkTextColor: "text-neutral-50",
            linkBackgroundColor: "bg-critical-light"
        }
    }
};

export const Note = ({
    title,
    note,
    size = "small",
    noteType = "default",
    noteStyle = "normal",
    link,
    linkPlaceholder
}: NoteProps) => {
    const noteStyling = noteStyleAndTypeMap[noteStyle][noteType];

    return (
        <div
            className={clsx(
                "mt-3 flex items-center justify-between border p-3 sm:w-full",
                link ? "rounded-2xl" : "rounded-lg",
                noteStyling.backgroundColor,
                noteStyling.borderColor
            )}
        >
            <p
                className={clsx(
                    noteStyling.textColor,
                    (size === "medium" || size === "large") && "text-body",
                    size === "small" && "text-small"
                )}
            >
                <span className="font-bold">
                    {title ? `${title}: ` : "Note: "}
                </span>
                {note}
            </p>
            {link ? (
                <a
                    href={link}
                    target="_blank"
                    className={clsx(
                        "rounded-lg font-medium no-underline",
                        noteStyling.linkTextColor,
                        noteStyling.linkBackgroundColor,
                        size === "medium" && "p-3 text-body",
                        size === "large" && "px-3 py-2 text-body",
                        size === "small" && "p-2 text-small"
                    )}
                >
                    {linkPlaceholder || ""}
                </a>
            ) : null}
        </div>
    );
};
