import { Moment } from "moment-timezone";

export enum DefinedDates {
    TODAY = "Today",
    YESTERDAY = "Yesterday",
    LAST7DAYS = "Last 7 days",
    LAST30DAYS = "Last 30 days",
    LAST90DAYS = "Last 90 days",
    LAST365DAYS = "Last 365 days",
    CUSTOM = "Custom"
}

type DateRangeDescription = {
    currRangeDesc: string;
    prevRangeDesc: string;
};

export const getDateRangeDescription = (
    currentDuration: DefinedDates,
    startDate: Moment,
    endDate: Moment
): DateRangeDescription => {
    const dayOfTheWeek = endDate.clone().format("dddd");
    const duration = endDate.clone().diff(startDate, "days");
    switch (currentDuration) {
        case DefinedDates.TODAY:
            return {
                currRangeDesc: "from Today",
                prevRangeDesc: `this time last ${dayOfTheWeek}`
            };
        case DefinedDates.LAST7DAYS:
            return {
                currRangeDesc: "in Last 7 Days",
                prevRangeDesc: "in prev. 7 days"
            };
        case DefinedDates.LAST30DAYS:
            return {
                currRangeDesc: "in Last 30 Days",
                prevRangeDesc: "in prev. 30 days"
            };
        case DefinedDates.LAST90DAYS:
            return {
                currRangeDesc: "in Last 90 Days",
                prevRangeDesc: "in prev. 90 days"
            };
        case DefinedDates.LAST365DAYS:
            return {
                currRangeDesc: "in Last 365 Days",
                prevRangeDesc: "in prev. 365 days"
            };
        default:
            return {
                currRangeDesc: `(${getDateString({ startDate, endDate })})`,
                prevRangeDesc: `in prev. ${
                    duration === 0 ? "1 day" : `${duration + 1} days`
                }`
            };
    }
};

type DateStringProps = {
    startDate: Moment;
    endDate: Moment;
};

export const getDateString = ({ startDate, endDate }: DateStringProps) => {
    if (startDate.year() !== endDate.year()) {
        return `${startDate.format("ll")} – ${endDate.format("ll")}`;
    } else if (startDate.isSame(endDate, "day")) {
        return `${endDate.format("LL")}`;
    }
    return `${startDate.format("MMM DD")} – 
    ${endDate.format("ll")}`;
};
