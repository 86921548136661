import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { Spinner } from "react-activity";

import { Button } from "src/@/components/ui/button";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { GetTablesQuery } from "src/api/graphql/generated/types";

type TableSessionRow = NonNullable<GetTablesQuery["storeTables"]>[number];

export const useColumns = (
    onCellSelected: (cell: TableSessionRow) => void,
    deleteTable: (tableId: string) => void,
    setDefaultEditOnOpen: (edit: boolean) => void,
    loadingTableId?: string
): ColumnDef<TableSessionRow>[] =>
    useMemo(
        () => [
            {
                id: "Table Number",
                accessorKey: "name",
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        title="Table Number"
                    />
                ),
                cell: ({ row }) => (
                    <span
                        className="cursor-pointer text-blue-600"
                        onClick={() => onCellSelected(row?.original)}
                    >
                        {row.original.name}
                    </span>
                ),
                enableGlobalFilter: false
            },
            {
                id: "Seats",
                accessorKey: "numSeats",
                header: ({ column }) => (
                    <DataTableColumnHeader column={column} title="Seats" />
                ),
                cell: ({ row }) => <div>{row.original.numSeats}</div>,
                enableGlobalFilter: false
            },
            {
                id: "actions",
                accessorKey: "actions",
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        title="Actions"
                        disableSort
                    />
                ),
                cell: ({ row }) => (
                    <>
                        <Button
                            variant="outline"
                            onClick={() => {
                                setDefaultEditOnOpen(true);
                                onCellSelected(row.original);
                            }}
                            className="mr-1"
                        >
                            Edit
                        </Button>
                        <Button onClick={() => deleteTable(row.original.id)}>
                            {loadingTableId === row.original.id ? (
                                <Spinner />
                            ) : (
                                "Delete"
                            )}
                        </Button>
                    </>
                )
            }
        ],
        []
    );
