import {
    ChevronLeftIcon,
    ChevronRightIcon,
    DoubleArrowLeftIcon,
    DoubleArrowRightIcon
} from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";

import { Button } from "../button";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "../select";

interface DataTablePaginationProps<TData> {
    table: Table<TData>;
    manualPagination: boolean;
}

export function DataTablePagination<TData>({
    table,
    manualPagination = false
}: DataTablePaginationProps<TData>) {
    return (
        <div className="flex items-center justify-between px-2">
            <div className="flex-1 text-small text-muted-foreground">
                {table.getFilteredSelectedRowModel().rows.length > 0 && (
                    <>
                        {table.getFilteredSelectedRowModel().rows.length} of{" "}
                        {table.getFilteredRowModel().rows.length} row(s)
                        selected
                    </>
                )}
            </div>
            <div className="flex items-center space-x-6 lg:space-x-8">
                <div className="flex items-center space-x-2">
                    <p className="text-small font-medium">Rows per page</p>
                    <Select
                        value={`${table.getState().pagination.pageSize}`}
                        onValueChange={(value) => {
                            table.setPageSize(Number(value));
                        }}
                    >
                        <SelectTrigger className="h-8 w-[70px]">
                            <SelectValue
                                placeholder={
                                    table.getState().pagination.pageSize
                                }
                            />
                        </SelectTrigger>
                        <SelectContent side="top" className="bg-white">
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <SelectItem
                                    key={pageSize}
                                    value={`${pageSize}`}
                                >
                                    {pageSize}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className="relative text-small font-medium">
                    {/* allocate enough space to prevent layout shift */}
                    <span className="pointer-events-none select-none text-transparent">
                        {paginationText(9999, 9999)}
                    </span>
                    <div className="absolute inset-0 flex items-center justify-center">
                        {/** -1 means we don't know how many pages we have, see
                    https://tanstack.com/table/v8/docs/api/features/pagination#pagecount
                    */}
                        {table.getPageCount() !== -1 ? (
                            paginationText(
                                table.getState().pagination.pageIndex + 1,
                                table.getPageCount()
                            )
                        ) : (
                            <div className="flex w-full justify-end">
                                Page {table.getState().pagination.pageIndex + 1}
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex items-center space-x-2">
                    {!manualPagination && (
                        <Button
                            variant="outline"
                            className="hidden h-8 w-8 p-0 lg:flex"
                            onClick={() => table.setPageIndex(0)}
                            disabled={!table.getCanPreviousPage()}
                        >
                            <span className="sr-only top-0">
                                Go to first page
                            </span>
                            <DoubleArrowLeftIcon className="h-4 w-4" />
                        </Button>
                    )}
                    <Button
                        variant="outline"
                        className="h-8 w-8 p-0"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <span className="sr-only top-0">
                            Go to previous page
                        </span>
                        <ChevronLeftIcon className="h-4 w-4" />
                    </Button>

                    <Button
                        variant="outline"
                        className="h-8 w-8 p-0"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <span className="sr-only top-0">Go to next page</span>
                        <ChevronRightIcon className="h-4 w-4" />
                    </Button>

                    {!manualPagination && (
                        <Button
                            variant="outline"
                            className="hidden h-8 w-8 p-0 lg:flex"
                            onClick={() =>
                                table.setPageIndex(table.getPageCount() - 1)
                            }
                            disabled={!table.getCanNextPage()}
                        >
                            <span className="sr-only top-0">
                                Go to last page
                            </span>
                            <DoubleArrowRightIcon className="h-4 w-4" />
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

function paginationText(pageIndex: number, pageCount: number) {
    return `Page ${pageIndex} of ${pageCount}`;
}
