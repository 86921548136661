import { useRecoilState } from "recoil";
import { Spinner } from "react-activity";

import { CardTitle } from "src/@/components/ui/card";
import {
    NewCampaignStep,
    newCampaignStepAtom
} from "#guestbook/screens/Campaigns/NewCampaign/NewCampaignAtoms";
import { Button } from "src/@/components/ui/button";
import StackedStickyHeader from "#navigation/StackedStickyHeader";

type Props = {
    isLoading: boolean;
};
function NewCampaignHeader({ isLoading }: Props) {
    const [step, setStep] = useRecoilState(newCampaignStepAtom);
    return (
        <StackedStickyHeader>
            <div className="flex flex-row items-center justify-between gap-1 border-b p-4">
                <CardTitle className="text-xl font-bold sm:text-2xl">
                    New Campaign
                </CardTitle>
                <div className="flex flex-row gap-2">
                    <Button
                        type="button"
                        variant={"outline"}
                        onClick={() => {
                            step === NewCampaignStep.SMSForm
                                ? setStep(null)
                                : setStep(NewCampaignStep.SMSForm);
                        }}
                    >
                        {step === NewCampaignStep.SMSForm ? "Cancel" : "Back"}
                    </Button>
                    <Button type="submit" disabled={isLoading}>
                        {isLoading ? (
                            <Spinner size={14} />
                        ) : step === NewCampaignStep.SMSForm ? (
                            "Continue"
                        ) : (
                            "Send Campaign"
                        )}
                    </Button>
                </div>
            </div>
        </StackedStickyHeader>
    );
}

export default NewCampaignHeader;
