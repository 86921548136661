import * as yup from "yup";

import {
    BogoPromo,
    FIELD_NAMES,
    QualifierValues
} from "#promotion/utils/types";
import {
    discountQualiferValidation,
    requirePurchaseValidation
} from "#promotion/utils/validation/field-schemas";
import {
    GenericDefaultValues,
    GenericPromoValidations
} from "#promotion/utils/validation/form-schemas/generic";

export const BogoDefaultValues = {
    ...GenericDefaultValues,
    // Type Overrides
    [FIELD_NAMES.DISCOUNT_QUALIFIER]: QualifierValues.SPECIFIC,
    [FIELD_NAMES.REQUIRED_PURCHASE]: true
} as BogoPromo;

export const BogoValidationSchema = yup.object({
    ...GenericPromoValidations,
    // Validation Overrides
    [FIELD_NAMES.DISCOUNT_QUALIFIER]: discountQualiferValidation
        .test({
            name: "Is Specific",
            message:
                'Qualifier must be "specific item or category" for this promo type',
            test: (value) => value === QualifierValues.SPECIFIC
        })
        .required(),
    [FIELD_NAMES.REQUIRED_PURCHASE]: requirePurchaseValidation.isTrue(
        "Required purchase must be true for this promo type"
    )
});
