import { ContentState, EditorState } from "draft-js";
import { all, compose } from "lodash/fp";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    DiscountType,
    VariableKind,
    VariableType
} from "src/api/graphql/generated/types";
import { DiscountTemplate } from "#guestbook/components/incentives/constants";
import { DECORATORS } from "#guestbook/components/message-input/editor-decorators";

import {
    ConversationVariable,
    getConversationInput,
    getConversationInputVariables,
    ProductData,
    setConversationInputMessage,
    TemplateVariableType
} from "../../redux";

type ValidatorResponse = { isValid: boolean; message: string | null };

export const useInitializeConversationEditorState = () => {
    const dispatch = useDispatch();
    const editorState = useSelector(getConversationInput);
    const setEditorState = compose(dispatch, setConversationInputMessage);

    useEffect(() => {
        if (!editorState) {
            setEditorState(
                EditorState.createWithContent(
                    ContentState.createFromText(""),
                    DECORATORS
                )
            );
        }
    }, [editorState]);
};

const _allVariablesSelected = (variables: ConversationVariable[]) =>
    all((v: ConversationVariable) => !!v.data, variables);

export const useConversationValidator = (): ValidatorResponse => {
    const message = useSelector(getConversationInput);
    const variables = useSelector(getConversationInputVariables);

    if (!message) {
        return { isValid: false, message: "Please fill out message." };
    }

    if (!variables || !_allVariablesSelected(variables)) {
        return { isValid: false, message: "Please select variables." };
    }

    return { isValid: true, message: null };
};

export type InputProps = {
    _onSubmit: () => Promise<void>;
    isLoading: boolean;
};

export type MessageActionsProps = {
    _onSubmit: () => Promise<void>;
    isLoading: boolean;
    handleGenerateResponse: () => void;
};

const KIND_MAPPING: Record<TemplateVariableType, VariableKind> = {
    PRODUCT: VariableKind.Product,
    REWARD: VariableKind.Reward,
    TEXT: VariableKind.Text
};

const TYPE_MAPPING: Record<TemplateVariableType, VariableType> = {
    PRODUCT: VariableType.Product,
    REWARD: VariableType.Discount,
    TEXT: VariableType.Announcement
};

const discountToData = (discountTemplate: DiscountTemplate) => ({
    reward: {
        discount: {
            newPrice: discountTemplate.discount.newPrice || 0,
            dollarsOff: discountTemplate.discount.dollarsOff || 0,
            percentOff: discountTemplate.discount.percentOff || 0,
            // Note: only support percent based discounts atm,
            // but if that changes then we'll just need to pass the discount
            // type to message variables and then all the way through to this and then
            // should be good
            type: DiscountType.Percent
        },
        products: (discountTemplate.products || []).map((p) => ({
            productId: p.id,
            imageUrl: p.imageUrl,
            name: p.label
        })),
        storewide: discountTemplate.storewide || false
    }
});

const productToData = (productData: ProductData) => ({
    product: {
        productId: productData.product._id,
        imageUrl: productData.product.image || null,
        name: productData.product.name
    }
});
