import {
    PlusCircledIcon,
    TrashIcon,
    CalendarIcon
} from "@radix-ui/react-icons";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "src/@/components/ui/data-table";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger
} from "src/@/components/ui/tooltip";
import { Button } from "src/@/components/ui/button";
import { useCallback, useMemo, useState } from "react";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "src/@/components/ui/select";
import {
    Popover,
    PopoverContent,
    PopoverTrigger
} from "src/@/components/ui/popover";
import { CardDescription } from "src/@/components/ui/card";
import { Calendar } from "src/@/components/ui/calendar";
import { cn } from "src/@/lib/utils";
import {
    formatMinutes,
    getDayMinutesFromLuxon,
    timeToLuxon,
    formatDateWithPrevDay
} from "#settings/settings-business-info/utils/formatTime";
import {
    getSpecialHoursWithNewDay,
    getSpecialHoursWithoutDay,
    formatDate
} from "#settings/settings-business-info/utils/helpers";
import {
    DAY_END_MINUTES,
    NOON_HOUR_MINUTES,
    DEFAULT_END_HOURS,
    DEFAULT_START_HOURS
} from "#settings/settings-business-info/utils/constants";

type HourRange = {
    start: number;
    end: number;
};

type SpecialHours = {
    day: Date;
    isClosed: boolean;
    hours: HourRange[];
};

type TimeInputProps = {
    originalTime: string;
    onBlur: (
        rowDay: Date,
        time: string,
        hour: HourRange,
        type: "start" | "end"
    ) => void;
    rowDay: Date;
    hour: HourRange;
    type: "start" | "end";
    disabled: boolean;
};

const TimeInput = ({
    originalTime,
    onBlur,
    rowDay,
    hour,
    type,
    disabled
}: TimeInputProps) => {
    const [time, setTime] = useState(originalTime);
    const setNewTime = useCallback(() => {
        if (time !== originalTime) {
            onBlur(rowDay, time, hour, type); // modify hours function
        }
    }, [hour, onBlur, originalTime, rowDay, time, type]);
    return (
        <input
            className="h-9 cursor-pointer rounded-md border border-input bg-background text-small shadow-sm"
            type="time"
            value={time}
            onBlur={setNewTime}
            onChange={(e) => {
                setTime(e.target.value);
            }}
            disabled={disabled}
        />
    );
};

type ValueType = {
    date: Date;
    isClosed: boolean;
    range: HourRange;
}[];

type DatatableProps = {
    value: ValueType;
    onChange: (value: ValueType) => void;
    disabled: boolean;
};

export function SpecialHoursDatatable({
    value,
    onChange,
    disabled
}: DatatableProps) {
    const createNewHours = useCallback(
        (
            date = new Date(),
            start = DEFAULT_START_HOURS,
            end = DEFAULT_END_HOURS
        ) => {
            const newHours = getSpecialHoursWithNewDay(value, date, start, end);
            onChange(newHours);
        },
        [value, onChange]
    );

    const modifyHours = useCallback(
        (
            rowDay: Date,
            time: string,
            hour: HourRange,
            position: "start" | "end"
        ) => {
            const dateObject = timeToLuxon(time);
            if (!dateObject) return;

            const minutes = getDayMinutesFromLuxon(dateObject);
            const startMinutes = position === "start" ? minutes : hour.start;
            const endMinutes = position === "end" ? minutes : hour.end;
            const nextDay = new Date(rowDay);
            nextDay.setDate(nextDay.getDate() + 1);

            // Delete existing hours first
            const filteredHours = getSpecialHoursWithoutDay(
                value,
                rowDay,
                hour
            );

            // If hour is past midnight, add two hours for both days
            if (startMinutes > endMinutes) {
                const preMidnightHours = getSpecialHoursWithNewDay(
                    filteredHours,
                    rowDay,
                    startMinutes,
                    DAY_END_MINUTES
                );
                const midnightHours = getSpecialHoursWithNewDay(
                    preMidnightHours,
                    nextDay,
                    0,
                    endMinutes
                );
                onChange(midnightHours);
            } else {
                // Normal hours
                const normalHours = getSpecialHoursWithNewDay(
                    filteredHours,
                    rowDay,
                    startMinutes,
                    endMinutes
                );
                onChange(normalHours);
            }
        },
        [value, onChange]
    );

    const deleteHours = useCallback(
        (rowDay: Date, hour: HourRange) => {
            const newHours = getSpecialHoursWithoutDay(value, rowDay, hour);
            onChange(newHours);
        },
        [value, onChange]
    );

    const modifyIsClosed = useCallback(
        (selectValue: "Open" | "Closed", rowDay: Date) => {
            const closed = selectValue === "Open" ? false : true;
            onChange(
                value.map((val) => {
                    if (formatDate(val.date) === formatDate(rowDay)) {
                        return {
                            ...val,
                            isClosed: closed,
                            range: {
                                start: 0,
                                end: 0
                            }
                        };
                    }
                    return val;
                })
            );
        },
        [value, onChange]
    );

    const modifyDate = useCallback(
        (newDate: Date | undefined, rowDay: Date) => {
            if (!newDate) {
                return;
            }

            const entries = value;
            const changedIndices = entries.reduce<number[]>(
                (acc, item, index) => {
                    if (formatDate(item.date) === formatDate(rowDay)) {
                        acc.push(index);
                    }
                    return acc;
                },
                []
            );

            changedIndices.forEach((index) => {
                entries[index] = {
                    ...entries[index],
                    date: newDate
                };
            });
            onChange(entries);
        },
        [value, onChange]
    );

    const data: SpecialHours[] = useMemo(() => {
        const groupedData: { [key: string]: SpecialHours } = {};
        value.forEach((val) => {
            const formattedDate = formatDate(val.date);
            if (!groupedData[formattedDate]) {
                groupedData[formattedDate] = {
                    day: val.date,
                    isClosed: val.isClosed,
                    hours: [
                        {
                            start: val.range.start,
                            end: val.range.end
                        }
                    ]
                };
            } else {
                groupedData[formattedDate].hours.push({
                    start: val.range.start,
                    end: val.range.end
                });
            }
        });
        const midnightDays = new Set();

        // Combine hours so that we allow for stores to have hours that run past midnight
        for (const currentKey of Object.keys(groupedData)) {
            const currentData = groupedData[currentKey];
            const hours = currentData.hours;

            hours.forEach((hour) => {
                // check to find a special hour that starts at midnight
                if (hour.start === 0) {
                    const prevDayKey = formatDateWithPrevDay(currentKey);
                    if (groupedData[prevDayKey]) {
                        const prevDayData = groupedData[prevDayKey];
                        const prevHours = prevDayData.hours;

                        // check if there is an hour the day before that extends to midnight
                        const prevHourIndex = prevHours.findIndex(
                            (h) => h.end === DAY_END_MINUTES
                        );

                        if (prevHourIndex !== -1) {
                            const prevHour = prevHours[prevHourIndex];

                            if (hour.end >= NOON_HOUR_MINUTES) {
                                return; // Keep as separate store hour days if it passes the afternoon
                            }

                            const combinedHour = {
                                start: prevHour.start,
                                end: DAY_END_MINUTES + 1 + hour.end
                            };

                            // Update the previous day's hours
                            groupedData[prevDayKey].hours[prevHourIndex] =
                                combinedHour;
                            midnightDays.add(currentKey);
                        }
                    }
                }
            });
        }

        const filteredData: { [key: string]: SpecialHours } = {};
        for (const [date, data] of Object.entries(groupedData)) {
            filteredData[date] = midnightDays.has(date)
                ? {
                      ...data,
                      hours: data.hours.filter((hour) => hour.start !== 0)
                  }
                : data;
        }

        return Object.entries(filteredData).map(([_, data]) => ({
            day: data.day,
            isClosed: data.isClosed,
            hours: data.hours
        }));
    }, [value]);

    const columns: ColumnDef<SpecialHours>[] = useMemo(
        () => [
            {
                header: () => <div>Day</div>,
                id: "day",
                accessorKey: "day",
                cell: ({ row }) => (
                    <div>
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                    variant={"outline"}
                                    className={cn(
                                        "w-[200px] pl-3 text-left font-normal",
                                        !row.original.day &&
                                            "text-muted-foreground"
                                    )}
                                    disabled={disabled}
                                >
                                    {row.original.day ? (
                                        formatDate(row.original.day)
                                    ) : (
                                        <span>Pick a date</span>
                                    )}
                                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent
                                className="w-auto p-0"
                                align="start"
                            >
                                <Calendar
                                    mode="single"
                                    selected={row.original.day}
                                    disabled={(date) => date < new Date()}
                                    onSelect={(newDate) => {
                                        modifyDate(newDate, row.original.day);
                                    }}
                                    components={undefined} // Fixes month navigation buttons not being clickable when form is dirty
                                    initialFocus
                                />
                            </PopoverContent>
                        </Popover>
                    </div>
                ),
                size: 500
            },
            {
                header: () => <div className="ml-6">Open or Closed</div>,
                id: "isClosed",
                accessorKey: "isClosed",
                cell: ({ row }) => (
                    <div>
                        <Select
                            value={row.original.isClosed ? "Closed" : "Open"}
                            onValueChange={(selectValue) => {
                                // @ts-expect-error, selectValue is always either Open or Closed
                                modifyIsClosed(selectValue, row.original.day);
                            }}
                            disabled={disabled}
                        >
                            <div className="flex items-center">
                                <SelectTrigger className="ml-3 w-[120px]">
                                    <SelectValue placeholder="Add Special Hours" />
                                </SelectTrigger>
                                <SelectContent>
                                    {["Open", "Closed"].map((option, idx) => (
                                        <SelectItem key={idx} value={option}>
                                            {option}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </div>
                        </Select>
                    </div>
                ),
                size: 500
            },
            {
                header: () => <div className="ml-4">Store Hours</div>,
                id: "hours",
                accessorKey: "hours",
                cell: ({ row, table }) => (
                    <div>
                        {!row.original.isClosed &&
                        row.original.hours &&
                        row.original.hours.length > 0 ? (
                            <div className="flex flex-col space-y-2">
                                {row.original.hours.map((hour, idx) => (
                                    <div className="flex items-center justify-between">
                                        <div
                                            key={idx}
                                            className="flex items-center"
                                        >
                                            <TimeInput
                                                originalTime={formatMinutes(
                                                    hour.start
                                                )}
                                                onBlur={modifyHours}
                                                rowDay={row.original.day}
                                                hour={hour}
                                                type={"start"}
                                                disabled={disabled}
                                            />
                                            <span className="mx-2">-</span>
                                            <TimeInput
                                                originalTime={formatMinutes(
                                                    hour.end
                                                )}
                                                onBlur={modifyHours}
                                                rowDay={row.original.day}
                                                hour={hour}
                                                type={"end"}
                                                disabled={disabled}
                                            />
                                            {hour.end > DAY_END_MINUTES && (
                                                <span className="ml-2 text-xs text-red-500">
                                                    Next Day
                                                </span>
                                            )}
                                        </div>

                                        <div className="flex">
                                            <div>
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger
                                                            asChild
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                if (!disabled) {
                                                                    deleteHours(
                                                                        row
                                                                            .original
                                                                            .day,
                                                                        hour
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <TrashIcon className="mr-1 h-4 w-4" />
                                                        </TooltipTrigger>
                                                        <TooltipContent>
                                                            <p className="text-sm">
                                                                Delete Hours
                                                            </p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </div>
                                            <div className="ml-2">
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger
                                                            asChild
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                if (!disabled)
                                                                    createNewHours(
                                                                        row
                                                                            .original
                                                                            .day
                                                                    );
                                                            }}
                                                        >
                                                            <PlusCircledIcon className="mr-1 h-4 w-4" />
                                                        </TooltipTrigger>
                                                        <TooltipContent>
                                                            <p className="text-sm">
                                                                Add Hours
                                                            </p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="flex items-center justify-between">
                                <CardDescription className="ml-4">
                                    {row.original.isClosed
                                        ? "Closed"
                                        : data.some(
                                                (item) =>
                                                    item.hours.some(
                                                        (hour) =>
                                                            hour.end >
                                                            DAY_END_MINUTES
                                                    ) &&
                                                    formatDate(item.day) ===
                                                        formatDateWithPrevDay(
                                                            formatDate(
                                                                row.original.day
                                                            )
                                                        )
                                            )
                                          ? "Hours Carry Over from Day Before"
                                          : "Regular Hours"}
                                </CardDescription>
                                <div className="flex">
                                    <div>
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger
                                                    asChild
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        if (!disabled) {
                                                            onChange(
                                                                value.filter(
                                                                    (val) =>
                                                                        formatDate(
                                                                            val.date
                                                                        ) !==
                                                                        formatDate(
                                                                            row
                                                                                .original
                                                                                .day
                                                                        )
                                                                )
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <TrashIcon className="mr-1 h-4 w-4" />
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    <p className="text-sm">
                                                        Delete Day
                                                    </p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                    {!row.original.isClosed && (
                                        <div className="ml-2">
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger
                                                        asChild
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            if (!disabled)
                                                                createNewHours(
                                                                    row.original
                                                                        .day
                                                                );
                                                        }}
                                                    >
                                                        <PlusCircledIcon className="mr-1 h-4 w-4" />
                                                    </TooltipTrigger>
                                                    <TooltipContent>
                                                        <p className="text-sm">
                                                            Add Hours
                                                        </p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                ),
                size: 500
            }
        ],
        [
            createNewHours,
            data,
            deleteHours,
            disabled,
            modifyDate,
            modifyHours,
            modifyIsClosed,
            onChange,
            value
        ]
    );

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                emptyText="No Hours Available."
                customPageSize={
                    999999 // functionally infinite rows
                }
                className="border-0 border-y"
            />
            <div className="ml-2 flex justify-between">
                <Button
                    className="max-w-60"
                    type="button"
                    onClick={() => {
                        createNewHours();
                    }}
                    disabled={disabled}
                >
                    <PlusCircledIcon className="mr-1 h-4 w-4" />
                    <p>Add Special Hours</p>
                </Button>
            </div>
        </>
    );
}
