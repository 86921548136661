import * as React from "react";
import { Box, SwipeableDrawer } from "@material-ui/core";
import { Checkbox, Divider, Space } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CSSProperties } from "react";
import { CSSInterpolation } from "@emotion/css";
import { Global } from "@emotion/react";
import { styled } from "@material-ui/styles";

import { ReactComponent as XIcon } from "src/assets/icons/close-x.svg";
import {
    Button,
    RoundButton
} from "#menu-editor/multi-menus/styled-components/button";
import { Text } from "#menu-editor/multi-menus/styled-components/text";
import { commonStyles } from "#menu-editor/multi-menus/helpers/styles";
import colors from "#reusable/colors/colors.json";

import { FilterEnum, FilterType } from "../types";

type DrawerProps = {
    setOpenDrawer: (newOpen: boolean) => void;
    open: boolean;
    filter: FilterType;
    setFilter: (filter: FilterType) => void;
};

const filterOptions = [
    { label: "All Menus", value: FilterEnum.AllMenus },
    { label: "Active", value: FilterEnum.Active },
    { label: "Inactive", value: FilterEnum.Inactive }
];

const Puller = styled(Box)(() => ({
    width: 30,
    height: 6,
    backgroundColor: `${colors["neutral-400"]}`,
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)"
}));

export const FilterDrawer: React.FC<DrawerProps> = ({
    setOpenDrawer,
    open,
    setFilter,
    filter
}) => {
    const handleSelectFilter = (key: FilterType) => () => {
        setFilter(key);
        setOpenDrawer(false);
    };

    const handleFilterChange = (checkedValues: CheckboxChangeEvent) => {
        setFilter(checkedValues.target.value);
        setOpenDrawer(false);
    };

    return (
        <>
            <Global
                styles={{
                    ".MuiDrawer-root > .MuiPaper-root": styles.swipeableDrawer,
                    ".ant-checkbox-inner": styles.antdRoundedCheckbox
                }}
            />
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
                swipeAreaWidth={styles.drawerBleeding}
                disableSwipeToOpen
                ModalProps={{
                    keepMounted: true
                }}
            >
                <Box sx={styles.sx}>
                    <Puller />
                </Box>
                <div style={styles.contentWrapper}>
                    <div style={commonStyles.displayFlex}>
                        <div style={styles.flex1}>
                            <RoundButton onClick={() => setOpenDrawer(false)}>
                                <XIcon />
                            </RoundButton>
                        </div>
                        <Text style={styles.alignCenter} type="header">
                            Filter
                        </Text>
                        <div style={styles.flex1} />
                    </div>
                    <Divider />
                    <Text style={styles.pb24} type="body-bold">
                        Menus
                    </Text>
                    <Space direction="vertical" size={16}>
                        {filterOptions.map((item) => (
                            <Checkbox
                                key={item.label}
                                onChange={handleFilterChange}
                                checked={item.value == filter}
                                value={item.value}
                            >
                                {item.label}
                            </Checkbox>
                        ))}
                    </Space>
                    <Divider />
                    <Button
                        type="tertiary"
                        size="regular"
                        onClick={handleSelectFilter(FilterEnum.AllMenus)}
                    >
                        Clear All
                    </Button>
                </div>
            </SwipeableDrawer>
        </>
    );
};

const styles = {
    contentWrapper: {
        padding: "0px 24px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px"
    } as CSSProperties,

    flex1: {
        flex: "1"
    } as CSSProperties,

    alignCenter: {
        alignItems: "center"
    } as CSSProperties,

    pb24: {
        paddingBottom: "24px"
    } as CSSProperties,

    swipeableDrawer: {
        height: "55%",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px"
    } as CSSInterpolation,

    antdRoundedCheckbox: {
        borderRadius: "48px"
    } as CSSInterpolation,

    drawerBleeding: 56,

    sx: {
        px: 2,
        pb: 4,
        overflow: "auto"
    }
};
