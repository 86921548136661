import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GetSliceState } from "src/core/domain";

export const activeStoreIdSlice = createSlice({
    name: "activeStoreId",
    initialState: "",
    reducers: {
        setActiveStoreId: (__, action: PayloadAction<string>) => action.payload
    }
});

export const { setActiveStoreId } = activeStoreIdSlice.actions;

type State = GetSliceState<typeof activeStoreIdSlice>;
export const { getActiveStoreId } = {
    getActiveStoreId: (state: State) => state.activeStoreId
};
