import { createContext } from "react";

type CashDrawerContextType = {
    cashDrawer: string | undefined;
    setCashDrawer: React.Dispatch<React.SetStateAction<string | undefined>>;
};
export const CashReportContext = createContext<CashDrawerContextType>({
    cashDrawer: undefined,
    setCashDrawer: () => null
});
