import { Col, Dropdown, Menu, Row, Space } from "antd";
import { CSSProperties, useCallback } from "react";

import { IconButton } from "#menu-editor/multi-menus/styled-components/icon-button";
import { Text } from "#menu-editor/multi-menus/styled-components/text";
import { ReactComponent as PlusIcon } from "src/assets/icons/plus.svg";
import { ReactComponent as ChevronDown } from "src/assets/icons/chevron-down.svg";
import {
    FilterEnum,
    FilterType
} from "#menu-editor/multi-menus/screens/menus-desktop/types";
import colors from "#reusable/colors/colors.json";

type Props = {
    menuAmount: number;
    filter: FilterType;
    setFilter: (filter: FilterType) => void;
    navigateToNewMenu: () => void;
};

export const Header: React.FC<Props> = ({
    menuAmount,
    filter,
    setFilter,
    navigateToNewMenu
}) => {
    const handleSelectFilter = useCallback(
        ({ key }: { key: string }): void => {
            setFilter(key as FilterType);
        },
        [setFilter]
    );

    const filterMenu = (
        <Menu onClick={handleSelectFilter} style={styles.dropdownMenu}>
            {filter !== FilterEnum.AllMenus ? (
                <Menu.Item
                    key={FilterEnum.AllMenus}
                    style={styles.dropdownMenuItem}
                >
                    <Text type="body-regular">{FilterEnum.AllMenus}</Text>
                </Menu.Item>
            ) : null}
            {filter !== FilterEnum.Active ? (
                <Menu.Item
                    key={FilterEnum.Active}
                    style={styles.dropdownMenuItem}
                >
                    <Text type="body-regular">{FilterEnum.Active}</Text>
                </Menu.Item>
            ) : null}
            {filter !== FilterEnum.Inactive ? (
                <Menu.Item
                    key={FilterEnum.Inactive}
                    style={styles.dropdownMenuItem}
                >
                    <Text type="body-regular">{FilterEnum.Inactive}</Text>
                </Menu.Item>
            ) : null}
        </Menu>
    );

    return (
        <>
            <Row style={styles.titleRow}>
                <Text type="title">{`${menuAmount} ${
                    menuAmount === 1 ? "Menu" : "Menus"
                }`}</Text>
            </Row>
            <Row justify="space-between">
                <Dropdown overlay={filterMenu} trigger={["click"]}>
                    <IconButton
                        type="tertiary"
                        icon={ChevronDown}
                        placement="right"
                        style={styles.buttons}
                    >
                        {filter}
                    </IconButton>
                </Dropdown>
                <Col>
                    <Row>
                        <Space size={16}>
                            {/* 
                            TODO: add again when actions are specified
                            <IconButton
                                type="tertiary"
                                icon={ChevronDown}
                                placement="right"
                                style={styles.buttons}
                            >
                                Actions
                            </IconButton> */}
                            <IconButton
                                type="primary"
                                icon={PlusIcon}
                                placement="left"
                                style={styles.buttons}
                                onClick={navigateToNewMenu}
                            >
                                New Menu
                            </IconButton>
                        </Space>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

const styles = {
    titleRow: {
        marginBottom: "6px"
    } as CSSProperties,

    dropdownMenu: {
        borderRadius: "8px",
        padding: "8px",
        border: `1px solid ${colors["neutral-400"]}`,
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)"
    } as CSSProperties,

    dropdownMenuItem: {
        borderRadius: "4px",
        padding: "12px 16px"
    } as CSSProperties,

    buttons: {
        height: "40px",
        padding: "10px 16px"
    } as CSSProperties
};
