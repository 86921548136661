import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useRecoilValue } from "recoil";
import { toast } from "sonner";

import CampaignBrandRegistration from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistration";
import {
    LOADING_STATUS_LOADING,
    LOADING_STATUS_ERROR,
    TwilioBrandRegistrationStatus,
    brandRegistrationStatusAtom,
    LoadingStatus
} from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationAtoms";
import { getActiveStore } from "src/redux/selectors";
import CampaignHeaderSkeleton from "#guestbook/screens/Campaigns/CampaignHeaderSkeleton";
import ApprovedCampaignIndex from "#guestbook/screens/Campaigns/ApprovedCampaignIndex";
import { useFetchTwilioBrand } from "#guestbook/screens/Campaigns/useFetchTwilioBrand";
import useTrackCampaignSegmentEvent from "#guestbook/screens/Campaigns/useTrackCampaignSegmentEvent";
import { SegmentEvents } from "#utils/segment";

export function SMSCampaignsPage() {
    return (
        <div className="h-full w-full">
            <SMSCampaignsScreen />
        </div>
    );
}

function SMSCampaignsScreen() {
    const status = useRecoilValue(brandRegistrationStatusAtom);
    const store = useSelector(getActiveStore);
    const { fetchTwilioBrand } = useFetchTwilioBrand();
    const trackCampaignEvent = useTrackCampaignSegmentEvent();
    useEffect(() => {
        trackCampaignEvent(SegmentEvents.Guestbook.Campaigns.VIEWED);
    }, [trackCampaignEvent]);
    useEffect(() => {
        if (store?._id != null) {
            fetchTwilioBrand(store._id).catch((e) => {
                toast.error(e.message);
            });
        }
    }, [fetchTwilioBrand, store?._id]);
    if (
        status instanceof LoadingStatus &&
        status.status === LOADING_STATUS_LOADING
    ) {
        return (
            <>
                <CampaignHeaderSkeleton />
                <div className="flex w-full flex-col items-center">
                    <div className="w-full max-w-screen-md p-12">
                        <Skeleton className="flex h-96 grow flex-row" />
                    </div>
                </div>
            </>
        );
    }
    if (
        status instanceof LoadingStatus &&
        status.status === LOADING_STATUS_ERROR
    ) {
        return (
            <>
                <CampaignHeaderSkeleton />
                <div className="flex w-full flex-col items-center">
                    <div className="w-full max-w-screen-md p-12 text-destructive">
                        {status.message ?? "Error"}
                    </div>
                </div>
            </>
        );
    }
    if (status === TwilioBrandRegistrationStatus.APPROVED) {
        return <ApprovedCampaignIndex />;
    }
    return <CampaignBrandRegistration />;
}
