import React, { useEffect, useState } from "react";
import { Drawer as AntdDrawer, Spin, Collapse } from "antd";
import _ from "lodash/fp";
import { useSelector } from "react-redux";
import { connectModal, InjectedProps } from "redux-modal";
import { IPunchcard, IPurchase } from "@snackpass/snackpass-types";
import styled from "@emotion/styled";
import { SystemColors } from "@snackpass/design-system";
import { useMediaQuery } from "react-responsive";
import { DateTime } from "luxon";

import { ReactComponent as Chevron } from "src/assets/icons/chevron-left.svg";
import { ReactComponent as Info } from "src/assets/icons/Info.svg";
import { ReactComponent as Message } from "src/assets/icons/message.svg";
import { ReactComponent as Stats } from "src/assets/icons/stats.svg";
import { ReactComponent as Clock } from "src/assets/icons/clock.svg";
import api from "src/api/rest";
import { OrderHistoryCard } from "#guestbook/components/order-history/order-history-card";

import "antd/dist/antd.css";

import useWindowDimensions from "#hooks/use-window-dimensions";
import { Text } from "#reusable/text/index";
import { Space } from "#reusable/divider/space";
import { Divider } from "#reusable/divider/index";
import { UserStats } from "#guestbook/components/order-history/user-stats";
import { sendError } from "src/utils/errors";

import { usePubNubWithToken } from "@snackpass/conversations.hooks";
import { PubNubPublisher } from "@snackpass/conversations.client";
import { ChannelMetadataObject, ObjectCustom } from "pubnub";

import { toDollarFormatted } from "#reports/sales-summary/lib";

import { Link } from "react-router-dom";

import { Routes } from "#navigation/routes";

import { CountryCode, formatNumber } from "libphonenumber-js";

import { getStoreTimezone } from "#utils/helpers";
import { getActiveStore } from "src/redux/selectors";

import { toast } from "sonner";

const { Panel } = Collapse;
const PURCHASE_LIMIT = 500;

export type ChatChannelMetadata = {
    userLastMsg?: string;

    userLastOrder?: string;
    userFavProduct?: string;
    userFavProductId?: string;

    userNumOrders?: number;
    userAmountSpent?: string;
    userLastPurchase?: string;
    userPointsBalance?: string;
    userFrequency?: string;

    userEmail?: string;
    userNumber?: string;
};
const _getAllPurchases = (data: {
    purchases: IPurchase[];
    kioskPurchases?: IPurchase[];
}): IPurchase[] => [...data.purchases, ...(data.kioskPurchases || [])];

type DrawerProps = {
    userId: string;
    chatMetadata: ChatChannelMetadata;
    showGuestbookLink?: boolean;
    showContactInfo?: boolean;
    nameOverride?: string;
    showLoyaltyLink?: boolean;
    showPurchaseReportLink?: boolean;
} & InjectedProps;

type UserStatsType = {
    customerSince: DateTime | undefined;

    userNumOrders: number | undefined;
    amountSpent: string;

    frequency?: string;

    lastOrder: DateTime | undefined;

    storeCredit: number;
    pointsBalance: number | undefined;

    numRefunds: number;
    amountRefunded: string;
};

const OrderHistoryDrawerComponent = ({
    userId,
    handleHide,
    show,
    chatMetadata,
    showGuestbookLink,
    showContactInfo,
    nameOverride,
    showLoyaltyLink,
    showPurchaseReportLink
}: DrawerProps) => {
    const activeStore = useSelector(getActiveStore);
    const [purchases, setPurchases] = useState<IPurchase[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [userStats, setUserStats] = useState<UserStatsType | undefined>();
    const [punchCard, setPunchCard] = useState<IPunchcard | undefined>();
    const [channel, setChannel] =
        useState<ChannelMetadataObject<ObjectCustom>>();

    const pubnub = usePubNubWithToken();
    const pub = new PubNubPublisher(
        { uuid: pubnub.getUUID(), subscribeKey: "" },
        pubnub
    );

    const isMobile = useMediaQuery({
        query: `(max-width: 990px)`
    });

    const { width } = useWindowDimensions();

    const nameToInitials = (name: string) =>
        name
            .split(" ")
            .map((e) => e.charAt(0).toUpperCase())
            .join("");

    const _processApiResponse: (res: {
        data: {
            purchases: IPurchase[];
            kioskPurchases?: IPurchase[];
        };
    }) => void = _.compose(
        setPurchases,
        _.orderBy("createdAt", "desc"),
        _getAllPurchases,
        _.prop("data")
    );

    const chatLastPurchaseDate =
        chatMetadata?.userLastPurchase &&
        DateTime.fromFormat(chatMetadata.userLastPurchase, "M/d/yy")
            .setZone(getStoreTimezone(activeStore))
            .endOf("day");

    const fetchPurchasesAndPunchCard = async () => {
        if (activeStore?._id) {
            setIsLoading(true);
            Promise.all([
                api.purchases
                    .getForStoreByUser(activeStore._id, userId, {
                        count: PURCHASE_LIMIT,
                        includeKioskPurchases: true,

                        // ensure we are pulling purchases that are inline with provided lastOrder, to avoid discrepancies
                        ...(chatLastPurchaseDate
                            ? {
                                  before: chatLastPurchaseDate.toJSDate()
                              }
                            : {})
                    })
                    .then(_processApiResponse),

                api.punchCards
                    .getPunchCardForUser(activeStore._id, userId)
                    .then(({ data }) => {
                        setPunchCard(data.punchcards[0]);
                    })
            ])
                .catch((err) => {
                    sendError(err);
                    console.log(err);
                    toast.error(
                        "Something went wrong when getting the user data, please try again later"
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });

            // if no existing guestbook channel, this isn't necessarily an error we need to display to the client.
            if (showGuestbookLink)
                pub.chat({
                    storeId: activeStore._id,
                    userId: userId
                })
                    .getMetadata()
                    .then((data) => {
                        setChannel(data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
        }
    };

    useEffect(() => {
        fetchPurchasesAndPunchCard();
    }, []);

    useEffect(() => {
        if (purchases.length && punchCard) {
            const firstPurchaseDate =
                punchCard?.firstPurchaseDate ||
                purchases[purchases.length - 1].createdAt;

            const lastPurchaseDate =
                punchCard?.lastPurchaseDate || purchases[0].createdAt;

            const firstPurchase = DateTime.fromISO(
                firstPurchaseDate.toString()
            ).setZone(getStoreTimezone(activeStore));

            const lastPurchase = DateTime.fromISO(
                lastPurchaseDate.toString()
            ).setZone(getStoreTimezone(activeStore));
            const amountSpent = toDollarFormatted(
                purchases.reduce(function (acc, p) {
                    return p?.subtotal ? acc + p.subtotal : acc + 0;
                }, 0)
            );

            const refundedPurchases = purchases.filter((e) => e.refund);
            const amountRefunded = toDollarFormatted(
                refundedPurchases.reduce(
                    (acc, p) => acc + (p.refundedAmount || 0),
                    0
                )
            );

            setUserStats({
                customerSince: firstPurchase,

                userNumOrders: chatMetadata?.userNumOrders || 0,
                amountSpent: chatMetadata?.userAmountSpent || amountSpent,

                frequency: chatMetadata?.userFrequency,

                lastOrder: chatLastPurchaseDate || lastPurchase,

                storeCredit: punchCard?.credit,
                pointsBalance: punchCard?.pointsBalance,

                numRefunds: refundedPurchases.length,
                amountRefunded
            });
        }
    }, [purchases, punchCard]);

    return (
        <AntdDrawer
            width={isMobile ? width : 450}
            placement="right"
            onClose={handleHide}
            open={show}
            closable={false}
            destroyOnClose={false}
            // so the first animation when the drawer is triggered is smooth
            forceRender={true}
            zIndex={9000}
        >
            <DrawerWrapper>
                <TitleWrapper>
                    <div className="chevron" onClick={handleHide}>
                        <Chevron fill={SystemColors.v1.sesame} />
                    </div>
                </TitleWrapper>
                <Space vertical="xl" />
                {isLoading ? (
                    <div className="mt-[50%] text-center">
                        <Spin tip="Loading..." />
                    </div>
                ) : punchCard?.user ? (
                    <div>
                        <div className="flex justify-center ">
                            {punchCard.user.profilePicUrl ? (
                                <img
                                    src={punchCard.user.profilePicUrl}
                                    className="h-[100px] w-[100px] rounded-full"
                                />
                            ) : (
                                // Show user initials if no image
                                <div className="color-gray-200 flex h-[100px] w-[100px] items-center justify-center rounded-full bg-neutral-200 text-xlarge font-bold italic">
                                    {nameOverride ? (
                                        nameToInitials(nameOverride)
                                    ) : (
                                        <>
                                            {punchCard.user.firstName?.charAt(
                                                0
                                            )}
                                            {punchCard.user.lastName?.charAt(0)}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="flex justify-center pt-2 text-xlarge font-semibold">
                            {nameOverride || punchCard.user.name}
                        </div>
                    </div>
                ) : (
                    <div>No User Info</div>
                )}
                {userStats && userStats.customerSince ? (
                    <div className="flex justify-center pt-1 text-[14px] text-neutral-500">
                        {`Customer since ${userStats.customerSince.monthLong} 
                        ${userStats.customerSince.year}`}
                    </div>
                ) : null}
                {showGuestbookLink && !!channel && (
                    <Link
                        to={{
                            pathname: Routes.GuestbookConversations,
                            state: { channel, channelUserId: userId }
                        }}
                        className="mt-4 block w-full rounded-md bg-black py-2 text-center text-neutral-50 no-underline"
                    >
                        <Message fill={"white"} className="mr-2" />
                        Message on Guestbook
                    </Link>
                )}
                {showLoyaltyLink && (
                    <Link
                        to={{
                            pathname: Routes.GuestbookCustomers,
                            state: { userId, search: punchCard?.user?.name }
                        }}
                        className="mt-4 block w-full rounded-md bg-black py-2 text-center text-neutral-50 no-underline"
                    >
                        Find in Customer Loyalty
                    </Link>
                )}
                {showPurchaseReportLink && (
                    <Link
                        to={{
                            pathname: Routes.ReportsCustomerDirectoryInsights,
                            state: { userId, search: punchCard?.user?.name }
                        }}
                        className="mt-4 block w-full rounded-md bg-black py-2 text-center text-neutral-50 no-underline"
                    >
                        Find in Customers Report
                    </Link>
                )}
                <Divider className="mb-0 border-[#d9d9d9]" />
                <Collapse
                    bordered={false}
                    className="bg-neutral-50"
                    expandIconPosition="end"
                    defaultActiveKey={[0, 1, 2]}
                >
                    {showContactInfo && (
                        <Panel
                            header={
                                <div className="flex flex-row items-center">
                                    <Info className="mr-6 h-[20px] w-[20px]" />
                                    <span className="bold text-body">
                                        Contact Info
                                    </span>
                                </div>
                            }
                            key="0"
                        >
                            {!isLoading ? (
                                <div>
                                    {chatMetadata?.userEmail && (
                                        <div className="mb-2">
                                            <div className="text-neutral-500">
                                                Email
                                            </div>
                                            <div className="font-semibold">
                                                {chatMetadata?.userEmail}
                                            </div>
                                        </div>
                                    )}
                                    {punchCard?.user?.number && (
                                        <div className="mb-2">
                                            <div className="text-neutral-500">
                                                Phone Number
                                                {punchCard.user.countryCode &&
                                                    punchCard.user
                                                        .countryCode !==
                                                        "US" && (
                                                        <span className="text-critical-light">
                                                            {" "}
                                                            (Non-US)
                                                        </span>
                                                    )}
                                            </div>
                                            <div className="font-semibold">
                                                {punchCard.user.countryCode &&
                                                punchCard.user.countryCode !==
                                                    "US"
                                                    ? formatNumber(
                                                          punchCard.user.number,
                                                          punchCard.user
                                                              .countryCode as CountryCode,
                                                          "INTERNATIONAL"
                                                      )
                                                    : formatNumber(
                                                          punchCard?.user
                                                              ?.number,
                                                          "US",
                                                          "NATIONAL"
                                                      )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <SpinnerWrapper>
                                    <Spin tip="Loading..." />
                                </SpinnerWrapper>
                            )}
                        </Panel>
                    )}
                    <Panel
                        header={
                            <div className="flex flex-row items-center">
                                <Stats className="mr-6 h-[20px] w-[20px]" />
                                <Text size="m">Stats</Text>
                            </div>
                        }
                        key="1"
                    >
                        <UserStats
                            userStats={userStats}
                            isLoading={isLoading}
                        />
                    </Panel>
                    <Panel
                        header={
                            <div className="flex flex-row items-center">
                                <Clock
                                    className="mr-6 h-[20px] w-[20px]"
                                    fill={SystemColors.v1.sesame}
                                />
                                <Text size="m">Order History</Text>
                            </div>
                        }
                        key="2"
                    >
                        {purchases.length === 0 && isLoading ? (
                            <SpinnerWrapper>
                                <Spin tip="Loading..." />
                            </SpinnerWrapper>
                        ) : (
                            purchases.map((purchase: IPurchase) => (
                                <OrderHistoryCard
                                    key={purchase._id}
                                    purchase={purchase}
                                />
                            ))
                        )}
                        {purchases.length === 0 && !isLoading ? (
                            <div>No Order History Found for User</div>
                        ) : (
                            <></>
                        )}
                    </Panel>
                </Collapse>
            </DrawerWrapper>
        </AntdDrawer>
    );
};

const DrawerWrapper = styled.div`
    padding-bottom: 50px;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    .chevron {
        cursor: pointer;
        margin-right: 5px;
    }
`;
const SpinnerWrapper = styled.div`
    margin-top: 50%;
    text-align: center;
`;

export const OrderHistoryDrawer = connectModal({
    name: "OrderHistoryDrawer",
    destroyOnHide: true
})(OrderHistoryDrawerComponent);
