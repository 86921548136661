import { ReactComponent as DownloadIcon } from "src/assets/icons/download-icon.svg";
import { ExportToCsv } from "#utils/export-to-csv";

interface Props {
    rows: {
        [key: string]: string | number;
    }[];
    columns: {
        title: string;
        dataIndex: string;
    }[];
    filename: string;
    onClick?: (downloadCSV: () => void) => void;
}

function csvNumber(value: any) {
    if (typeof value === "number") {
        if (Number.isInteger(value)) {
            return value.toFixed(0);
        }
        return value.toFixed(2);
    }
    return value;
}

const DownloadButton = ({ rows, columns, filename, onClick }: Props) => {
    const data = rows.map((row) =>
        Object.fromEntries(
            columns.map((column) => [
                column.title,
                csvNumber(row[column.dataIndex] || "")
            ])
        )
    );

    const downloadCSV = async () => {
        const csvExporter = new ExportToCsv({
            filename,
            fieldSeparator: ",",
            useKeysAsHeaders: true,
            title: filename.replace(",", ""),
            showTitle: true
        });

        csvExporter.generateCsv(data);
    };
    return (
        <>
            {data.length > 0 ? (
                <button
                    className="cursor-pointer rounded-lg border border-neutral-400 bg-neutral-50 p-2 text-small text-neutral-950 shadow-none"
                    onClick={() =>
                        onClick ? onClick(downloadCSV) : void downloadCSV()
                    }
                >
                    <DownloadIcon className="mr-2" />
                    Download
                </button>
            ) : (
                <></>
            )}
        </>
    );
};

export default DownloadButton;
