import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import api from "src/api/rest";
import { getUser } from "src/redux/selectors";

type Updates = {
    firstName?: string;
    lastName?: string;
    profilePicUrl?: string;
};

export const useUpdateSelf = () => {
    const user = useSelector(getUser);

    const { isPending, mutate, isSuccess, error } = useMutation({
        mutationFn: async (updates: Updates) => {
            if (!user) return;

            return api.admins.updateSelf(user._id, {
                firstName: updates.firstName,
                lastName: updates.lastName,
                profilePicUrl: updates.profilePicUrl
            });
        }
    });
    return {
        isLoading: isPending,
        mutate,
        isSuccess,
        error
    };
};
