import React, { useContext } from "react";
import { useDrag, useDrop } from "react-dnd";
import classNames from "classnames";
import { debounce } from "lodash";
import { Cell, UseExpandedRowProps, UseTableRowProps } from "react-table-7";
import { IProduct } from "@snackpass/snackpass-types";

import MenuAutomation from "#menu-editor/mobile-friendly/menu-automation";
import {
    IProductCategoryWithProducts,
    MenuTopLevelContext
} from "#menu-editor/mobile-friendly/helpers/context";

import { TableRowHeadingCell } from "./tableRowHeading";

type TableRowHTMLAttributes = React.HTMLAttributes<HTMLTableRowElement>;

type TableRowProps = TableRowHTMLAttributes & {
    children: React.ReactNode;
    row: UseTableRowProps<IProductCategoryWithProducts | IProduct> &
        UseExpandedRowProps<IProductCategoryWithProducts | IProduct>;
    index: number;
    handleRowMove?: Function;
    headerCell: Cell<IProductCategoryWithProducts | IProduct, any>;
    parentIndex?: number;
    menuAutomation: MenuAutomation;
};

export const TableRow: React.FC<TableRowProps> = ({
    children,
    row,
    index,
    handleRowMove,
    headerCell,
    parentIndex,
    menuAutomation,

    ...forwardedProps
}) => {
    const { incrementMenuOrderChanges } = useContext(MenuTopLevelContext);
    // Create a row type out of the row depth and parent index
    // to restrict drag and drop to elements from the same depth + branch.
    const rowType = React.useRef(`${row.depth}-${parentIndex}`).current;
    const dropRef = React.useRef<HTMLTableRowElement>(null);
    const dragRef = React.useRef<HTMLTableCellElement>(null);
    const [, drop] = useDrop({
        //TODO the subrows drag and drop under different categories causing unexpected effect, should be issue in hover function here
        accept: rowType,
        hover: debounce((item, monitor) => {
            if (!dropRef.current) {
                return;
            }
            // global table indeces
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            if (clientOffset === null) {
                return;
            }

            // @ts-ignore
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            if (handleRowMove) {
                handleRowMove({
                    dragIndex,
                    hoverIndex,
                    row
                });
            }

            item.index = hoverIndex;
            // keeping debounce since react setState hook also has potentially to cause race condition
        }, 100),
        collect: (monitor) => ({
            canAccept: monitor.getItemType() === rowType,
            isOver: monitor.isOver()
        })
    });

    const [{ isDragging }, drag, preview] = useDrag({
        type: rowType,
        item: { type: rowType, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        end: () => {
            incrementMenuOrderChanges();
        }
    });

    React.useEffect(() => {
        preview(drop(dropRef));
        drag(dragRef);
    });

    return (
        <tr
            {...forwardedProps}
            ref={dropRef}
            className={classNames({
                TableRow: true,
                isDragging
            })}
        >
            <TableRowHeadingCell
                {...headerCell.getCellProps()}
                depth={row.depth}
                canExpand={row.canExpand}
                getToggleRowExpandedProps={row.getToggleRowExpandedProps}
                isExpanded={row.isExpanded}
                isDragging={isDragging}
                dragRef={dragRef}
                row={row}
                menuAutomation={menuAutomation}
            >
                {headerCell.render("Cell")}
            </TableRowHeadingCell>
            {children}
        </tr>
    );
};
