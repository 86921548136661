import React, { useContext, useState } from "react";
import styled from "styled-components";
import produce from "immer";

import Text from "#devices/components/Text";
import { ReactComponent as PlusIcon } from "src/assets/icons/plus-sign.svg";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import {
    ChannelOption,
    ChannelType
} from "#devices/utils/deviceTypes/SnackTVDevice";
import { useCloudinaryUploadWidget } from "#reusable/upload/useCloudinaryUploadWidget";
import colors from "#reusable/colors/colors.json";

const renderLabel = (label: string, id: string) => (
    <Text.Label htmlFor={id} as="label">
        {label}
    </Text.Label>
);

export const Video = () => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const [savingVideo, setSavingVideo] = useState(false);

    const deviceOptions = (updatedDevice?.deviceDetails?.channelOptions ??
        snackTvDevice?.deviceDetails?.channelOptions ??
        []) as ChannelOption[];

    const channel = (updatedDevice?.deviceDetails?.channel ??
        snackTvDevice?.deviceDetails?.channel) as ChannelType;

    const channelOptions = deviceOptions.find(
        (o: ChannelOption) => o.channel === channel
    );

    const videoUrl = channelOptions?.options?.videoURL;

    const _handleUpload = (cloudinaryInfo: {
        thumbnail_url: string;
        secure_url: string;
    }) => {
        const updatedOptions = produce(deviceOptions, (draft) => {
            if (draft) {
                const channelOption = draft.find((o) => o.channel === channel);
                if (!channelOption) {
                    draft.push({
                        channel,
                        options: {
                            cloudinaryResult: cloudinaryInfo,
                            videoThumbnailURL: cloudinaryInfo.thumbnail_url,
                            videoURL: cloudinaryInfo.secure_url
                        }
                    });
                } else {
                    channelOption.options.cloudinaryResult = cloudinaryInfo;
                    channelOption.options.videoThumbnailURL =
                        cloudinaryInfo.thumbnail_url;
                    channelOption.options.videoURL = cloudinaryInfo.secure_url;
                }
            }
        });
        setSavingVideo(false);
        updateDeviceField("channelOptions", updatedOptions);
    };

    const { UploadButton } = useCloudinaryUploadWidget({
        preset: "video-snacktv",
        folder: "snacktv-videos",
        clientAllowedFormats: ["mp4"],
        maxVideoFileSize: 262144000 // 250MB
    });

    return (
        <Container disabled={savingVideo}>
            {renderLabel("Upload a Video", "videoUploadButton")}
            <UploadButton
                disabled={savingVideo}
                onClick={() => {
                    setSavingVideo(true);
                }}
                onUpload={_handleUpload}
                onClose={() => {
                    setSavingVideo(false);
                }}
            >
                {savingVideo ? "Loading..." : "Choose Video"}
                <PlusIcon fill={colors["neutral-600"]} />
            </UploadButton>
            {videoUrl ? (
                <VideoDisplayContainer>
                    {renderLabel("Current Video", "currentVideoLabel")}
                    <VideoContainer>
                        <VideoElement
                            controls
                            autoPlay={false}
                            src={videoUrl}
                            width="50%"
                        />
                    </VideoContainer>
                </VideoDisplayContainer>
            ) : null}
        </Container>
    );
};

const Container = styled.div<{ disabled: boolean }>`
    .cloudinary-button {
        color: ${colors["neutral-600"]};
        justify-content: space-between;
        padding: ${({ theme }) => theme.spacing.base};
        margin-top: ${({ theme }) => theme.spacing.half};
        margin-bottom: ${({ theme }) => theme.spacing.base};
        border-radius: ${({ theme }) => theme.spacing.half};
        cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
        opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
    }
`;

const VideoDisplayContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacing.half};
    background-color: black;
    border-radius: ${({ theme }) => theme.spacing.half};
`;
const VideoElement = styled.video``;
