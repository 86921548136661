import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";
import {
    Controller,
    ControllerProps,
    FieldPath,
    FieldValues,
    FormProvider,
    useFormContext
} from "react-hook-form";
import { v4 as uuid } from "uuid";
import { TooltipTrigger } from "@radix-ui/react-tooltip";
import { InfoCircledIcon } from "@radix-ui/react-icons";

import { cn } from "src/@/lib/utils";
import { Label } from "src/@/components/ui/label";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider
} from "src/@/components/ui/tooltip";

const Form = FormProvider;

type FormFieldContextValue<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
    name: TName;
};

const FormFieldContext = React.createContext<FormFieldContextValue>(
    {} as FormFieldContextValue
);

const FormField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    ...props
}: ControllerProps<TFieldValues, TName>) => (
    <FormFieldContext.Provider value={{ name: props.name }}>
        <Controller {...props} />
    </FormFieldContext.Provider>
);

const useFormField = () => {
    const fieldContext = React.useContext(FormFieldContext);
    const itemContext = React.useContext(FormItemContext);
    const { getFieldState, formState } = useFormContext();

    const fieldState = getFieldState(fieldContext.name, formState);

    if (!fieldContext) {
        throw new Error("useFormField should be used within <FormField>");
    }

    const { id } = itemContext;

    return {
        id,
        name: fieldContext.name,
        formItemId: `${id}-form-item`,
        formDescriptionId: `${id}-form-item-description`,
        formMessageId: `${id}-form-item-message`,
        ...fieldState
    };
};

type FormItemContextValue = {
    id: string;
};

const FormItemContext = React.createContext<FormItemContextValue>(
    {} as FormItemContextValue
);

const FormItem = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
    const id = uuid();

    return (
        <FormItemContext.Provider value={{ id }}>
            <div ref={ref} className={cn("space-y-2", className)} {...props} />
        </FormItemContext.Provider>
    );
});
FormItem.displayName = "FormItem";

type FormLabelProps = React.ComponentPropsWithoutRef<
    typeof LabelPrimitive.Root
> & {
    tooltip?: string;
    hideTooltipIcon?: boolean;
};

const FormLabel = React.forwardRef<
    React.ElementRef<typeof LabelPrimitive.Root>,
    FormLabelProps
>(({ className, tooltip, hideTooltipIcon, ...props }, ref) => {
    const { error, formItemId } = useFormField();
    // snackpass custom: added text-base
    return (
        <div className="flex flex-row items-center text-base">
            {tooltip ? (
                <TooltipProvider delayDuration={100}>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex flex-row items-center">
                                <Label
                                    ref={ref}
                                    className={cn(
                                        error &&
                                            "text-red-500 dark:text-red-900",
                                        className
                                    )}
                                    htmlFor={formItemId}
                                    {...props}
                                />
                                {hideTooltipIcon ? null : (
                                    <InfoCircledIcon className="ml-2 h-4 w-4" />
                                )}
                            </div>
                        </TooltipTrigger>

                        <TooltipContent
                            className="z-50 w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-600 px-3 py-2 text-center text-micro font-normal text-neutral-50"
                            sideOffset={5}
                        >
                            {tooltip}
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            ) : (
                <Label
                    ref={ref}
                    className={cn(
                        error && "text-red-500 dark:text-red-900",
                        className
                    )}
                    htmlFor={formItemId}
                    {...props}
                />
            )}
        </div>
    );
});
FormLabel.displayName = "FormLabel";

const FormControl = React.forwardRef<
    React.ElementRef<typeof Slot>,
    React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
    const { error, formItemId, formDescriptionId, formMessageId } =
        useFormField();

    return (
        <Slot
            ref={ref}
            id={formItemId}
            aria-describedby={
                !error
                    ? `${formDescriptionId}`
                    : `${formDescriptionId} ${formMessageId}`
            }
            aria-invalid={!!error}
            {...props}
        />
    );
});
FormControl.displayName = "FormControl";

type FormDescriptionProps = React.HTMLAttributes<HTMLParagraphElement> & {
    tooltip?: string;
};

const FormDescription = React.forwardRef<
    HTMLParagraphElement,
    FormDescriptionProps
>(({ className, tooltip, ...props }, ref) => {
    const { formDescriptionId } = useFormField();
    return (
        // snackpas custom: added text-sm
        <div className="flex flex-row items-center text-sm">
            {tooltip ? (
                <TooltipProvider delayDuration={100}>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <p
                                ref={ref}
                                id={formDescriptionId}
                                className={cn(
                                    "text-small text-neutral-500 dark:text-neutral-400",
                                    className
                                )}
                                {...props}
                            />
                        </TooltipTrigger>

                        <TooltipContent
                            className="z-50 w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-600 px-3 py-2 text-center text-micro font-normal text-neutral-50"
                            sideOffset={5}
                        >
                            {tooltip}
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            ) : (
                <p
                    ref={ref}
                    id={formDescriptionId}
                    className={cn(
                        "text-small text-neutral-500 dark:text-neutral-400",
                        className
                    )}
                    {...props}
                />
            )}
        </div>
    );
});
FormDescription.displayName = "FormDescription";

const FormMessage = React.forwardRef<
    HTMLParagraphElement,
    React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
    const { error, formMessageId } = useFormField();
    const body = error ? String(error?.message) : children;

    if (!body) {
        return null;
    }

    return (
        <p
            ref={ref}
            id={formMessageId}
            className={cn(
                "text-[0.8rem] font-medium text-red-500 dark:text-red-900",
                className
            )}
            {...props}
        >
            {body}
        </p>
    );
});
FormMessage.displayName = "FormMessage";

export {
    useFormField,
    Form,
    FormItem,
    FormLabel,
    FormControl,
    FormDescription,
    FormMessage,
    FormField
};
