import { useMediaQuery } from "react-responsive";

import constants from "#core/constants";

/* eslint-disable @typescript-eslint/no-explicit-any */
interface SelectItemsMenuProps {
    getTableProps: Function;
    headerGroups: any;
    rows: any;
    getTableBodyProps: Function;
    prepareRow: Function;
}

export const SelectItemsMenuTable = ({
    getTableProps,
    headerGroups,
    rows,
    getTableBodyProps,
    prepareRow
}: SelectItemsMenuProps) => {
    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`
    });
    return (
        <table {...getTableProps()}>
            {isMobile ? null : (
                <thead>
                    {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
            )}
            <tbody {...getTableBodyProps()}>
                {rows.map((row: any, i: number) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell: any) => (
                                <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
