import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { toast } from "sonner";
import { loadStripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from "@stripe/react-stripe-js";

import { getActiveStore } from "src/redux/selectors";
import api from "src/api/rest";
import config from "#config";

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

type Props = {
    onBillingAdded: () => void;
};

export function InlineStripeBillingForm({ onBillingAdded }: Props) {
    const store = useSelector(getActiveStore);
    const [sessionLoading, setSessionLoading] = useState(false);
    const [secret, setSecret] = useState<string | null>(null);

    useEffect(() => {
        if (store?._id) {
            setSessionLoading(true);
            setSecret(null);
            void api.billing
                .getCheckout(store._id)
                .then(({ data }) => {
                    setSessionLoading(false);
                    if (!_.isNil(data.clientSecret)) {
                        setSecret(data.clientSecret);
                    } else {
                        toast.error("Unable to retrieve checkout session.");
                    }
                })
                .catch((error) => {
                    setSessionLoading(false);
                    toast.error("Unable to retrieve checkout session.", {
                        description: error.message
                    });
                });
        }
    }, [store?._id]);

    if (sessionLoading) {
        return <div className="mt-2 w-full">Loading...</div>;
    }

    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{
                    clientSecret: secret,
                    onComplete: onBillingAdded
                }}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    );
}
