import React, { useState } from "react";
import { SystemColors } from "@snackpass/design-system";
import { IProduct } from "@snackpass/snackpass-types";
import styled from "@emotion/styled";

import OpenBookIcon from "src/assets/icons/menu-black.png";
import { Title } from "#guestbook/components/styled-components";

import { CategoryWithProducts } from "./lib";
import { ProductRow } from "./product-row";

type OnSelect = (product: IProduct) => void;

type CategoryProps = { categories: CategoryWithProducts[]; onSelect: OnSelect };

export const Categories = React.memo(
    ({ categories, onSelect }: CategoryProps) => (
        <div style={{ marginTop: 10, width: "100%" }}>
            <Title icon={OpenBookIcon} label="Categories" />

            <CategoriesContainer>
                {categories.map((c, index) => (
                    <Category
                        isLast={categories.length - 1 === index}
                        key={c.label}
                        category={c}
                        onSelect={onSelect}
                    />
                ))}
            </CategoriesContainer>
        </div>
    )
);

const Category = ({
    isLast,
    category,
    onSelect
}: {
    isLast: boolean;
    category: any;
    onSelect: OnSelect;
}) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Wrapper noBorder={isLast}>
            <CategoryDetails
                category={category}
                setOpen={setOpen}
                isOpen={isOpen}
                isLast={isLast}
            />
            <CategoryProducts
                onSelect={onSelect}
                isOpen={isOpen}
                category={category}
            />
        </Wrapper>
    );
};

type CategoryDetailsProps = {
    isLast: boolean;
    isOpen: boolean;
    setOpen: (o: boolean) => void;
    category: CategoryWithProducts;
};

const CategoryDetails = ({
    category,
    isLast,
    isOpen,
    setOpen
}: CategoryDetailsProps) => (
    <CategoryContainer isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
        <div style={{ flex: 1 }}>
            <CategoryLabel>{category.label}</CategoryLabel>
            <CategorySubtext>
                {category.products.length} items in category
            </CategorySubtext>
        </div>

        <CategoryIconContainer>
            <i
                className={
                    isOpen ? "fas fa-chevron-up" : "fas fa-chevron-right"
                }
                style={{ color: SystemColors.v1.gray30 }}
            />
        </CategoryIconContainer>
    </CategoryContainer>
);

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    border-bottom: ${({ noBorder }: { noBorder: boolean }) =>
        noBorder ? "none" : "1px solid " + SystemColors.v1.gray80};
`;

type CategoryProductsProps = {
    isOpen: boolean;
    onSelect: (p: IProduct) => void;
    category: CategoryWithProducts;
};

const CategoryProducts = ({
    onSelect,
    isOpen,
    category
}: CategoryProductsProps) => {
    if (!isOpen) {
        return null;
    }

    return (
        <CategoryProductsWrapper>
            {category.products.map((p: IProduct, index: number) => (
                <ProductRow
                    key={p._id}
                    product={p}
                    index={index}
                    onClick={() => onSelect(p)}
                    isLast={category.products.length - 1 === index}
                />
            ))}
        </CategoryProductsWrapper>
    );
};

const CategoryProductsWrapper = styled.div`
    padding: 0 15px;
    border: 1px solid ${SystemColors.v1.gray80};
    border-radius: 8px;
    margin: 0px 10px 14px 10px;
`;

const CategoriesContainer = styled.div`
    padding: 0 15px;
    border: 1px solid ${SystemColors.v1.gray80};
    border-radius: 16px;
`;

interface CategoryContainerProps {
    isOpen?: boolean;
}
const CategoryContainer = styled.div<CategoryContainerProps>`
    cursor: pointer;
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 15px;
    margin: 7px 0px;

    &:hover {
        border-radius: 10px;
        background: ${({ isOpen }) => (isOpen ? "" : SystemColors.v1.gray90)};
    }
`;

const CategoryLabel = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: ${SystemColors.v1.black};
    margin-bottom: 0;
`;

const CategorySubtext = styled.div`
    font-size: 16px;
    color: ${SystemColors.v1.gray30};
`;

const CategoryIconContainer = styled.div`
    font-weight: bold;
    font-size: 16px;
`;
