const allowedToBeLowercase = [
    "or",
    "and",
    "with",
    "the",
    "w/",
    "oz",
    "de",
    "of",
    "pcs"
];

export const isAllLetterUpperCase = (words: string[]): boolean => {
    for (const word of words) {
        if (
            word.charAt(0) !== word.charAt(0).toUpperCase() &&
            !allowedToBeLowercase.includes(word)
        ) {
            return false;
        }
    }
    return true;
};
