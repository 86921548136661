import React from "react";

import Text from "#payouts/components/shared/Text";
import theme from "#payouts/utils/theme";
import { ReactComponent as Good } from "#payouts/assets/checkmark.svg";
import { ReactComponent as Bad } from "#payouts/assets/x.svg";

export const RequirementMet = ({ good }: { good: boolean }) => (
    <div className="my-auto flex flex-row">{good ? <Good /> : <Bad />}</div>
);

export const RequirementBlock = ({
    good,
    message
}: {
    good: boolean;
    message: React.ReactNode;
}) => (
    <Text.Subtitle style={{ margin: `${theme.spacing.half} 0` }}>
        <div className="flex flex-row">
            <div>
                <RequirementMet good={good} />
            </div>
            <span className="my-auto">{message}</span>
        </div>
    </Text.Subtitle>
);
