import moment from "moment";
import { getActiveStore } from "@snackpass/accounting";
import { useContext, useEffect, useMemo } from "react";
import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { REPORTS_REQUEST_TIMEOUT } from "src/api/rest";
import { client } from "src/api/rest/client";
import { ReportsContext } from "#app/reports-context-provider";
import { ErrorWithCause, sendError } from "src/utils/errors";
import { GiftCardReportEndpoints } from "src/api/rest/report-hooks/types";

type GiftCardReportParams = {
    endpoint: GiftCardReportEndpoints;
    storeIds: string;
    startDate?: string;
    endDate?: string;
    cursorDate?: string;
    cursorId?: string;
    limit?: number;
    storeId?: string;
};

type GiftCardReportQuery = GiftCardReportParams[];

export const fetchGiftCardReport = async <T>({
    queryKey: [{ endpoint, storeIds, startDate, endDate, storeId }]
}: QueryFunctionContext<GiftCardReportQuery>): Promise<T> =>
    client
        .get(`/rdb-reports/gift-card/${endpoint}`, {
            params: {
                storeIds,
                startDate,
                endDate,
                storeId
            },
            timeout: REPORTS_REQUEST_TIMEOUT
        })
        .catch((cause) => {
            throw new ErrorWithCause(
                `GET to /rdb-reports/gift-card/${endpoint} failed`,
                cause
            );
        })
        .then((res) => res.data);

const useStoreIdsDateParams = ({
    useCustomDateRange = false,
    customStartDate,
    customEndDate
}: {
    useCustomDateRange?: boolean;
    customStartDate?: string;
    customEndDate?: string;
}) => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState } = useContext(ReportsContext);
    const { dateRanges, filter, stores } = reportsState;

    const startDate = reportsState.allTime
        ? undefined
        : dateRanges[0][0].format("YYYY-MM-DD");
    const endDate = (reportsState.allTime ? moment() : dateRanges[0][1]).format(
        "YYYY-MM-DD"
    );

    let startDateParam = startDate;
    let endDateParam = endDate;

    if (useCustomDateRange) {
        startDateParam = customStartDate;
        endDateParam = customEndDate ?? endDate;
    }

    return {
        storeIds: JSON.stringify(
            stores.length ? filter.storeIds : [activeStore?._id ?? ""]
        ),
        startDateParam,
        endDateParam
    };
};

export const useGiftCardReportQuery = <T>({
    endpoint,
    useCustomDateRange = false,
    customStartDate,
    customEndDate
}: {
    endpoint: GiftCardReportEndpoints;
    useCustomDateRange?: boolean;
    customStartDate?: string;
    customEndDate?: string;
}) => {
    const activeStore = useSelector(getActiveStore);
    const { storeIds, startDateParam, endDateParam } = useStoreIdsDateParams({
        useCustomDateRange,
        customStartDate,
        customEndDate
    });

    const { data, error, isError, isLoading } = useQuery({
        queryKey: [
            {
                endpoint,
                storeIds,
                startDate: startDateParam,
                endDate: endDateParam,
                storeId: activeStore?._id
            }
        ],
        queryFn: fetchGiftCardReport
    });

    useEffect(() => {
        if (isError) {
            sendError(error);
        }
    }, [error, isError]);

    return useMemo(
        () => ({
            data: (data as T) ?? null,
            isLoading,
            isError
        }),
        [data, isLoading, isError]
    );
};
