import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { SystemColors } from "@snackpass/design-system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getActiveStore } from "src/redux/selectors";
import {
    getInventoryEndDate,
    getInventoryTableRows,
    getTopUpPersistence
} from "src/redux/selectors/inventory";
import { clearTopUpOnHand, InventoryReport } from "src/redux/slices";
import { colors } from "#utils/colors";
import { FeatureBadge } from "#navigation/FeatureBadge";

import { EditItemForm } from "./components/edit-item-form";
import { InventoryTable } from "./components/inventory-table";
import { NewItemForm } from "./components/new-item-form";
import { useGetStartDate, useInitializeInventory } from "./lib";
import TopUpButton from "./components/top-up-button";

const momentFormat = "MMMM Do YYYY, h:mm a";

export const Inventory = () => {
    const [showNewItemModal, setShowNewItemModal] = useState(false);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [editItem, setEditItem] = useState<InventoryReport | null>(null);
    const [showTopUp, setShowTopUp] = useState(false);
    const activeStore = useSelector(getActiveStore);
    const [exportCSV, isFetching] = useInitializeInventory();
    const endDate = useSelector(getInventoryEndDate);
    const startDate = useGetStartDate(endDate || new Date());
    const rows = useSelector(getInventoryTableRows);

    const dispatch = useDispatch();
    const topUp = useSelector(getTopUpPersistence);
    const [isTopUpChanged, setIsTopUpChanged] = useState(false);

    useEffect(() => {
        setIsTopUpChanged(Object.keys(topUp).length > 0);
    }, [Object.keys(topUp).length]);

    const _openNewItemModal = () => {
        setShowNewItemModal(true);
    };

    const _closeNewItemModal = () => {
        setShowNewItemModal(false);
    };

    const _handleClickEdit = (item: InventoryReport) => {
        setEditItem(item);
        setShowEditItemModal(true);
    };
    const _onCloseEditModal = () => {
        setShowEditItemModal(false);
    };
    const _toggleTopUp = () => {
        if (showTopUp) {
            dispatch(clearTopUpOnHand());
        }
        setShowTopUp(!showTopUp);
    };

    return (
        <div style={styles.view}>
            <div style={styles.header}>
                <div>
                    <div className="flex items-center space-x-2">
                        <span className="text-large font-bold">
                            Ingredients
                        </span>
                        <FeatureBadge variant="beta" />
                    </div>
                    <p className="mb-4">
                        {moment(startDate).format(momentFormat)} -
                        {moment(endDate).format(momentFormat)}
                    </p>
                </div>

                <div>
                    {showTopUp ? null : (
                        <button
                            style={{
                                ...styles.button,
                                ...styles.buttonGray
                            }}
                            onClick={exportCSV}
                        >
                            <span
                                style={styles.icon}
                                className="fas fa-download"
                            />
                            Export
                        </button>
                    )}
                    {showTopUp ? (
                        <span>
                            {isTopUpChanged ? "changes made" : "no changes"}
                        </span>
                    ) : null}
                    {showTopUp && isTopUpChanged && (
                        <TopUpButton
                            topUp={topUp}
                            toggleTopUp={_toggleTopUp}
                            showTopUp={showTopUp}
                        />
                    )}
                    <button
                        style={{
                            ...styles.button,
                            ...styles.buttonGray
                        }}
                        onClick={_toggleTopUp}
                    >
                        <span
                            style={styles.icon}
                            className={showTopUp ? "" : "fas fa-plus"}
                        />
                        {showTopUp ? "Cancel" : "Top Up"}
                    </button>

                    {showTopUp ? null : (
                        <button
                            onClick={_openNewItemModal}
                            style={{
                                ...styles.button,
                                ...styles.buttonBlue
                            }}
                        >
                            <span style={styles.icon} className="fas fa-plus" />
                            New Item
                        </button>
                    )}
                </div>
            </div>
            <NewItemForm show={showNewItemModal} onHide={_closeNewItemModal} />
            <EditItemForm
                show={showEditItemModal}
                onHide={_onCloseEditModal}
                editItem={editItem}
            />
            <InventoryTable
                showTopUp={showTopUp}
                onClickEdit={_handleClickEdit}
                inventory={rows}
                loading={isFetching}
            />
        </div>
    );
};

const styles = {
    view: { padding: 30, flex: 1 },
    button: {
        borderRadius: 56,
        marginLeft: 6,
        marginRight: 6,
        paddingLeft: 20,
        paddingRight: 20
    },
    buttonGray: {
        background: SystemColors.v1.white,
        border: SystemColors.v1.gray60,
        borderWidth: 1,
        borderStyle: "solid",
        boxShadow: "none"
    },
    buttonBlue: {
        backgroundColor: SystemColors.v1.candy50,
        color: SystemColors.v1.white,
        boxShadow: "none"
    },

    alert: {
        zIndex: 0,
        width: "100%",
        position: "relative",
        border: "2px solid " + colors.blue,
        borderRadius: 10,
        backgroundColor: SystemColors.v1.white,
        textAlign: "center",
        fontSize: "1.4rem",
        maxWidth: "50%"
    } as CSSProperties,
    alertContainer: {
        display: "flex",
        flexDirection: "row",
        padding: 20,
        justifyContent: "center"
    } as CSSProperties,
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: 20,
        alignItems: "center",
        display: "flex"
    } as CSSProperties,
    search: { width: 300, borderRadius: 20 } as CSSProperties,
    icon: { marginRight: 5 }
};
