/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { SystemColors } from "@snackpass/design-system";
import { IPurchase, IStore, PurchaseStatus } from "@snackpass/snackpass-types";
import { Modal, Statistic } from "antd";
import fp from "lodash/fp";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { show } from "redux-modal";
import styled from "styled-components";
import { toast } from "sonner";

import API, { UpdateStatusParams } from "src/api/rest";
import { ReactComponent as Bell } from "src/assets/icons/bell.svg";
import { ReactComponent as Clock } from "src/assets/icons/clock.svg";
import constants from "#core/constants";
import { StyledWrapper } from "#css";
import { colors } from "#utils/colors";
import { setActivePurchase } from "src/redux/slices";
import { getActiveStore } from "src/redux/selectors";
import { purchaseIsThirdPartyDeliverect } from "#utils/helpers";
import { ErrorWithCause } from "src/utils/errors";

import DelayModal from "./delay-modal";

const PickupOptionsStyle = css`
    display: flex;
    flex-direction: column;

    .title {
        font-weight: 700;
    }
    .time-large {
        font-weight: 800;
        font-size: 32px;
    }
    .pickup-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .sub-header {
        color: ${SystemColors.v1.gray30};
    }
    .delay-button {
        width: 100%;
        margin-right: 10px;
        padding: 6px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-radius: 20px;
        border: 1px solid ${SystemColors.v1.melon50};
        color: ${SystemColors.v1.melon50};
        cursor: pointer;
    }
    .finish-early-button {
        width: 100%;
        padding: 6px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-radius: 20px;
        border: 1px solid ${SystemColors.v1.candy50};
        background-color: ${SystemColors.v1.candy50};
        color: ${SystemColors.v1.milkfoam};
        cursor: pointer;
    }
    .button-icon {
        margin-left: 5px;
    }
    .button-text {
        margin-left: 10px;
    }
`;

type Props = {
    purchase: IPurchase | null;
};

export const CustomerPickupOptions = ({ purchase }: Props) => {
    const dispatch = useDispatch();
    const store = useSelector(getActiveStore);
    const _setActivePurchase = (purchase: IPurchase) =>
        dispatch(setActivePurchase(purchase));

    useEffect(() => {
        if (!purchase) {
            return;
        }

        const query = new URLSearchParams(window.location.search);
        const pickupTimeDuration = query.get("pickupTimeDuration");
        const _onUpdateTime = onUpdateTime(purchase, _setActivePurchase);

        // Prompt user to confirm update pickup time
        // if it was passed in query param and it is not already set
        if (pickupTimeDuration && !purchase.pickupTimeDuration) {
            _onUpdateTime(pickupTimeDuration);
        }
    }, [purchase]);

    if (
        !purchase ||
        (purchase.refund && !purchase.partialRefund) ||
        !store ||
        purchase.catering.isCatering
    ) {
        return null;
    }

    const activePickupTimeDuration = purchase.pickupTimeDuration;
    const pickupTimes = getPickupTimes(purchase, store);

    return (
        <GridWrap css={PickupOptionsStyle}>
            <div className="title">Pickup Time</div>
            {!fp.isNil(activePickupTimeDuration) ? (
                <PickupTime purchase={purchase} />
            ) : (
                <PickupOptions purchase={purchase} pickupTimes={pickupTimes} />
            )}
        </GridWrap>
    );
};

const GridWrap = styled(StyledWrapper)`
    grid-area: PickupOptions;
`;

//=== Components ===//
const PickupTime = ({ purchase }: { purchase: IPurchase }) => {
    const dispatch = useDispatch();
    const _setActivePurchase = fp.compose(dispatch, setActivePurchase);
    const _showModal = fp.compose(dispatch, show);

    const currentStatus: PurchaseStatus = fp.compose(
        fp.prop("type"),
        fp.last
    )(purchase.status);
    const isCompleted = currentStatus === "COMPLETED";
    const pickupTime = purchase.pickupTime && moment(purchase.pickupTime);

    return (
        <div>
            {pickupTime && (
                <div className="time-large">{pickupTime.format("h:mm a")}</div>
            )}

            {/* Only show the count down if the pickup time is not in the past */}
            {!isCompleted && (
                <div className="pickup-row">
                    <div className="sub-header">Time Remaining</div>
                    <div>
                        <Statistic.Countdown
                            format={"mm:ss"}
                            valueStyle={{
                                fontSize: 16
                            }}
                            style={{ marginBottom: 20 }}
                            value={pickupTime?.toDate().getTime()}
                        />
                    </div>
                </div>
            )}

            {/* Only let restaurant update time if the purchase is not in the past */}
            {!isCompleted && (
                <div className="pickup-row">
                    <DelayModal />
                    {!purchaseIsThirdPartyDeliverect(purchase) && (
                        <div
                            className="delay-button"
                            onClick={() => {
                                _showModal("DelayModal", { purchase });
                            }}
                        >
                            <Clock
                                className="button-icon"
                                fill={SystemColors.v1.melon50}
                            />
                            <div className="button-text">Delay</div>
                        </div>
                    )}
                    <div
                        className="finish-early-button"
                        onClick={async () =>
                            finishEarly(purchase, _setActivePurchase)
                        }
                    >
                        <Bell
                            className="button-icon"
                            fill={SystemColors.v1.milkfoam}
                        />
                        <div className="button-text">Finished Early</div>
                    </div>
                </div>
            )}
        </div>
    );
};

const PickupTimesStyles = css`
    .title {
        font-size: 16px;
        font-weight: 700;
    }
    .sub-header {
        color: ${colors.gray30};
        font-size: 14px;
        margin-bottom: 10px;
    }
    .pickup-time {
        cursor: pointer;
        border-radius: 20px;
        margin: 5px;
        font-size: 18px;
        color: ${SystemColors.v1.sesame};
        background-color: ${SystemColors.v1.gray90};
        padding: 5px 35px;
        display: inline-block;
    }
`;

const PickupOptions = ({
    purchase,
    pickupTimes
}: {
    purchase: IPurchase;
    pickupTimes: string[];
}) => {
    const dispatch = useDispatch();
    const _setActivePurchase = fp.compose(dispatch, setActivePurchase);
    const _onUpdateTime = onUpdateTime(purchase, _setActivePurchase);

    return (
        <div css={PickupTimesStyles}>
            <div className="sub-header">Tell customer when to pickup</div>
            {pickupTimes.map((time, index) => (
                <div
                    className="pickup-time"
                    key={index}
                    onClick={async () => _onUpdateTime(time)}
                >
                    {time} min
                </div>
            ))}
        </div>
    );
};

//=== Helpers ===//
const getTimes = (field: string, defaults: readonly string[]) =>
    fp.compose((times: string[]) => {
        const hasTimes = !!fp.prop("length")(times);
        return hasTimes ? times : defaults;
    }, fp.prop(field));

const getPickupTimes = (purchase: IPurchase, store: IStore): string[] =>
    fp.compose(
        (fulfillment) =>
            fulfillment === "DELIVERY"
                ? getTimes(
                      "customDeliveryTimes",
                      constants.DEFAULT_POS_DELIVERY_TIMES
                  )(store)
                : getTimes(
                      "customPickUpTimes",
                      constants.DEFAULT_POS_PICKUP_TIMES
                  )(store),
        fp.prop("fulfillment")
    )(purchase);

//=== API Calls ===//
const onUpdateTime =
    (purchase: IPurchase, _setActivePurchase: (purchase: IPurchase) => void) =>
    async (selectedPickupTime: string) => {
        const pickupTime = moment()
            .add(selectedPickupTime, "minutes")
            .format("h:mm a");

        Modal.confirm({
            icon: null,
            title: `Are you sure you want to set the pickup time to ${selectedPickupTime} minutes (${pickupTime})?`,
            onOk: async () => {
                const body: UpdateStatusParams = {
                    status: PurchaseStatus.started,
                    pickupTimeDuration: parseInt(selectedPickupTime),
                    isKioskPurchase: purchase.transactionSource === "kiosk"
                };

                const res = await API.purchases
                    .updateStatus(purchase?._id as string, body)
                    .catch((cause) => {
                        throw new ErrorWithCause(
                            "onUpdateTime: error updating purchase status",
                            cause
                        );
                    });
                _setActivePurchase(res.data.purchase);

                toast.success(
                    `Successfully set pickup time for ${selectedPickupTime} minutes (${pickupTime})`
                );
            },
            onCancel() {}
        });
    };

const finishEarly = async (
    purchase: IPurchase,
    _setActivePurchase: (purchase: IPurchase) => void
) => {
    const body: UpdateStatusParams = {
        status: PurchaseStatus.completed,
        finishedEarly: true
    };

    const res = await API.purchases
        .updateStatus(purchase?._id as string, body)
        .catch((cause) => {
            throw new ErrorWithCause(
                "finishEarly: error updating purchase status",
                cause
            );
        });
    _setActivePurchase(res.data.purchase);
};
