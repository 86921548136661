const message = "Unable to Submit";
const description =
    "Please contact Snackpass support if you continue to experience this issue.";

export const messages = {
    setup: {
        initiate: {
            message: "Unable to Start",
            description
        }
    },
    modal: {
        fileSize: {
            message,
            description:
                "File size is too large. Please reduce the image size and try again. File size limit is 4.0 MB per image."
        },
        account: {
            message,
            description
        },
        company: {
            message,
            description
        },
        representative: {
            message,
            description
        },
        submit: {
            message,
            description
        }
    }
};
