enum SubscriptionCurrency {
    USD = "usd"
}

enum SubscriptionUnits {
    CENTS = "cents"
}

export enum InvoiceStatus {
    draft = "draft",
    open = "open",
    void = "void",
    paid = "paid",
    uncollectible = "uncollectible"
}

// Color-indicative status
export const invoiceStatusColor = {
    [InvoiceStatus.draft]: "bg-blue-500",
    [InvoiceStatus.open]: "bg-yellow-500",
    [InvoiceStatus.paid]: "bg-green-500",
    [InvoiceStatus.uncollectible]: "bg-red-500",
    [InvoiceStatus.void]: "bg-neutral-500"
};

// Customer-friendly status
export const invoiceStatusText = {
    [InvoiceStatus.draft]: "Draft",
    [InvoiceStatus.open]: "Due",
    [InvoiceStatus.paid]: "Paid",
    [InvoiceStatus.uncollectible]: "Unpaid",
    [InvoiceStatus.void]: "Voided"
};

type UnitAmount = {
    value: number;
    currency: SubscriptionCurrency;
    units: SubscriptionUnits;
};

export type PaymentMethod = {
    default: boolean;
    value: string;
    label: string;
    fingerprint: string;
};

export enum BillingMethodType {
    EXISTING = "existing",
    NEW = "new"
}

export type ExchangeAccount = {
    id: string;
};

export type Invoice = {
    id?: string;
    amountDue: UnitAmount;
    amountPaid: UnitAmount;
    description: string;
    invoicePdf: string;
    paidAt: string | null;
    dueDate: string | null;
    periodEnd: string;
    periodStart: string;
    status: InvoiceStatus;
    showHeader: boolean;
};

export type Summary = {
    headerText: string;
    bodyText: string;
    isLast: boolean;
};

const Formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: SubscriptionCurrency.USD,
    minimumFractionDigits: 2
});

export const formatCurrencyFromCents = (cents: number) =>
    Formatter.format(cents / 100);

export const formatCurrencyFromDollars = (dollars: number) =>
    Formatter.format(dollars);

// TODO: Clean these types up
export type InvoiceArrayResponse = {
    data: {
        invoices: Invoice[];
        success: boolean;
    };
};

export enum RestrictionStatus {
    NONE = "NO_RESTRICTION",
    WARN = "WARNING",
    RESTRICTED = "RESTRICTED"
}

export type GetRestrictDashboardAccessResponse = {
    data: {
        restricted: RestrictionStatus;
        success: boolean;
    };
};

export type AddCardResponse = {
    data: {
        client_secret: string;
        success: boolean;
    };
};
export type CreateTokenResponse = {
    data: {
        link_token: string;
        success: boolean;
    };
};
export type ExchangeTokenResponse = {
    data: {
        id: string;
        success: boolean;
    };
};
