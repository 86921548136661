import React, { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { clsx } from "clsx";
import { Link } from "react-router-dom";

import { ReportsContext } from "#app/reports-context-provider";
import {
    formatNumber,
    formatRange,
    toDollarFormatted
} from "#reports/sales-summary/lib";
import { ComparisonType } from "#reports/sales-summary/types";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import { useReportsLaborTiles } from "#navigation/utils";
import { useSalesSummary } from "#reports/hooks";

export const SalesSummaryTiles = () => {
    const { data, isLoading, error } = useSalesSummary();

    const laborTilesEnabled = useReportsLaborTiles();

    const formatDollarValue = (value: string | undefined) =>
        toDollarFormatted(Number.parseFloat(value || "0"));

    const formatNumberValue = (value: string | undefined) =>
        formatNumber(Number.parseFloat(value || "0"));

    const formatPercentValue = (value: string | undefined) =>
        `${(Number.parseFloat(value || "0") * 100).toFixed(2)}%`;

    // TODO: how should this field actually be fetched?
    const hasLaborTilesSupport = data?.laborCost != undefined;

    const laborTiles = hasLaborTilesSupport
        ? [
              <SummaryTile
                  title={"Labor Cost"}
                  value={formatDollarValue(data?.laborCost)}
                  valueChange={data?.laborCostChange}
                  key={1}
              />,
              <SummaryTile
                  title={"Labor Cost Percentage"}
                  value={formatPercentValue(data?.laborCostPercentage)}
                  valueChange={data?.laborCostPercentageChange}
                  key={2}
              />
          ]
        : [
              <SummaryTile
                  title={<span className="text-neutral-500">Labor Cost</span>}
                  value={"--"}
                  valueChangeOverride={
                      <Link
                          to="/settings-employees"
                          className="text-small no-underline"
                      >
                          Setup Team Management →
                      </Link>
                  }
                  key={1}
                  className="border-dashed"
              />,
              <SummaryTile
                  title={<span className="text-neutral-500">Labor Cost</span>}
                  value={"--"}
                  valueChangeOverride={
                      <Link
                          to="/settings-employees"
                          className="text-small no-underline"
                      >
                          Setup Team Management →
                      </Link>
                  }
                  key={2}
                  className="border-dashed"
              />
          ];

    return (
        <div
            className={clsx(
                "mb-10 grid grid-cols-1 justify-center gap-4",
                laborTilesEnabled ? "md:grid-cols-3" : "md:grid-cols-2"
            )}
        >
            <SummaryTile
                title={"Net Sales"}
                value={formatDollarValue(data?.netSales)}
                valueChange={data?.netSalesChange}
            />
            {laborTilesEnabled && laborTiles}
            <SummaryTile
                title={"Items"}
                value={formatNumberValue(data?.items)}
                valueChange={data?.itemsChange}
            />
            <SummaryTile
                title={"Orders"}
                value={formatNumberValue(data?.orders)}
                valueChange={data?.ordersChange}
            />
            <SummaryTile
                title={"Average Order Size"}
                value={formatDollarValue(data?.averageOrderSize)}
                valueChange={data?.averageOrderSizeChange}
            />
        </div>
    );
};

type SummaryTileProps = {
    title: string | JSX.Element;
    value: string | number | JSX.Element;
    valueChange?: string | number;
    valueChangeOverride?: string | JSX.Element;
    className?: string;
};
const SummaryTile = ({
    title,
    value,
    valueChange,
    valueChangeOverride,
    className
}: SummaryTileProps) => {
    const { isLoading, error } = useSalesSummary();
    const { reportsState } = useContext(ReportsContext);
    const { comparison, dateRanges, granularity } = reportsState;
    const dateRange = formatRange(dateRanges[1], granularity);

    const hasFailed = !!error;
    const shouldCompare = comparison != ComparisonType.NONE;

    const formatChangeSubtitle = (value?: string | number) => {
        if (value == undefined) return "--";
        const toNumber = Number.parseFloat(value as string);
        return (
            <p className="mt-2 text-small">
                <span
                    className={
                        toNumber < 0
                            ? "text-critical-light"
                            : "text-success-light"
                    }
                >
                    {toNumber >= 0 && "+"}
                    {toNumber.toFixed(2)}%
                </span>{" "}
                compared to {dateRange}
            </p>
        );
    };

    if (hasFailed) return <ErrorChart className="h-32" />;

    if (isLoading) return <Skeleton className="h-32" />;

    return (
        <div
            className={clsx(
                "rounded-xl border border-neutral-400 p-6",
                className
            )}
        >
            <p className="text-small">{title}</p>
            <h1 className="mb-0 text-xlarge">{value}</h1>
            {valueChangeOverride ||
                (shouldCompare && formatChangeSubtitle(valueChange))}
        </div>
    );
};
