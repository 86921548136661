import { Text } from "#reusable/text/index";

import {
    PickupTimeMaxRow,
    PickupTimeMinRow,
    PickupTimeSpecificRow,
    PickupTimeTypeRow,
    ToggleRow
} from "./rows";
import { OrdersFormFieldEnum, OrdersType } from "./types";

type OrdersTypeKeys = keyof OrdersType;
export interface Props {
    values: OrdersType;
    setFieldValue: <T extends OrdersTypeKeys>(
        field: T,
        value: OrdersType[T]
    ) => void;
    setFieldTouched: (field: OrdersTypeKeys) => void;
    disabled?: boolean;
}

export const ManagementSubForm = ({
    setFieldValue,
    setFieldTouched,
    values,
    disabled
}: Props) => (
    <>
        <Text size="l" weight="bold" spacing="xl">
            Management
        </Text>
        <ToggleRow
            field={OrdersFormFieldEnum.autoAccept}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            value={values.autoAccept}
            disabled={disabled}
        />

        <PickupTimeTypeRow
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            value={values.pickupTimeType}
            disabled={disabled}
        />
        <div style={{ paddingLeft: "40px" }}>
            {values.pickupTimeType === "specific" ? (
                <PickupTimeSpecificRow
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    value={values.defaultPickupTime}
                />
            ) : (
                <>
                    <PickupTimeMinRow
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        value={values.defaultPickupMinTime}
                        disabled={disabled}
                    />
                    <PickupTimeMaxRow
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        value={values.defaultPickupMaxTime}
                        disabled={disabled}
                    />
                </>
            )}
        </div>
        <ToggleRow
            field={OrdersFormFieldEnum.autoComplete}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            value={values.autoComplete}
            disabled={!values.autoComplete}
        />
        <ToggleRow
            field={OrdersFormFieldEnum.hasEmployeeAuditTracking}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            value={values.hasEmployeeAuditTracking}
            disabled={disabled}
        />
    </>
);
