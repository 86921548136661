import React, { CSSProperties } from "react";
import { SystemColors } from "@snackpass/design-system";
import styled from "@emotion/styled";
import { IProduct } from "@snackpass/snackpass-types";
import { Radio } from "pretty-checkbox-react";

import { Money } from "src/utils";
import { useDiscountProduct } from "#guestbook/redux/campaign";
import { ReactComponent as CheckSVG } from "src/assets/icons/check-circular.svg";

type ProductRowProps = {
    product: any;
    index: number;
    style?: CSSProperties;
    onClick: (p: IProduct) => void;
    isLast?: boolean;
};

export function ProductRow({
    onClick,
    product,
    index,
    isLast,
    style
}: ProductRowProps) {
    const selectedProduct = useDiscountProduct();
    const isSelected = selectedProduct?._id === product?._id;
    const borderBottom = isLast
        ? "none"
        : "1px solid " + SystemColors.v1.gray80;

    return (
        <Wrapper
            style={{
                borderBottom,
                ...style
            }}
        >
            <Container onClick={() => onClick(product)} key={index}>
                <Radio
                    name="Fixed Range Picker"
                    variant="fill"
                    plain
                    bigger
                    icon={
                        isSelected ? (
                            <CheckSVG className="svg" data-type="svg" />
                        ) : (
                            <div></div>
                        )
                    }
                    key={product._id}
                    className="radio-choice"
                ></Radio>
                <div style={{ flex: 1 }}>
                    <ProductName>{product.name}</ProductName>
                    <ProductPrice>
                        {Money.from.dollars(product.price).toFormat()}
                    </ProductPrice>
                </div>
                {product.image ? (
                    <ProductThumbnail src={product.image || ""} />
                ) : (
                    <div
                        style={{
                            width: 60
                        }}
                    />
                )}
            </Container>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const Container = styled.div`
    cursor: pointer;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 10px;
    margin: 7px 0px;

    &:hover {
        border-radius: 10px;
        background: ${SystemColors.v1.gray90};
    }
`;

const ProductName = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: ${SystemColors.v1.black};
    margin-bottom: 0;
`;

const ProductPrice = styled.div`
    font-size: 16px;
    color: ${SystemColors.v1.gray30};
`;

const ProductThumbnail = styled.img`
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 15px;
`;
