import { z } from "zod";

const SeniorSupportSchema = z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    isSnackpassEmployee: z.boolean(),
    isSeniorSupport: z.boolean()
});

export const parseData = (d: SeniorSupportUser[]) =>
    z.array(SeniorSupportSchema).parse(d);

export type SeniorSupportUser = z.infer<typeof SeniorSupportSchema>;
