import styled from "@emotion/styled";
import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";
import { InputNumber } from "antd";
import { CSSProperties } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FormFieldDescriptor } from "#promotion/components/shared/form-field-descriptor";
import { FormFieldName } from "#promotion/components/shared/form-field-name";
import { FIELD_NAMES } from "#promotion/utils/types";
import colors from "#reusable/colors/colors.json";

type ValueType = string | number;

type Props = {
    fieldName: FIELD_NAMES;
    name?: string;
    descriptor?: string;
    inputLabel?: string;
    min?: number;
    max?: number;
    format?: "number" | "percent" | "dollar";
    style?: CSSProperties;
    required?: boolean;
    recommended?: boolean;
    autofilled?: boolean;
};

export const FormNumberInput = ({
    fieldName,
    name,
    descriptor,
    inputLabel,
    min = 1,
    max,
    format = "number",
    style = {},
    required,
    recommended,
    autofilled
}: Props) => {
    const { control, getFieldState } = useFormContext();
    const { error } = getFieldState(fieldName);

    const numberFormatter = (value?: ValueType) => (value ? `${value}` : "");
    const numberParser = (value?: ValueType) =>
        Number(value) ? Number(value) : 0;
    const dollarFormatter = (value?: ValueType) =>
        value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
    const dollarParser = (value?: ValueType) =>
        value ? value.toString().replace(/\s?|(,*)/g, "") : "";
    const dollarPrefix = <Prefix className="ms-2">$</Prefix>;
    const percentFormatter = (value?: ValueType) => (value ? `${value}` : "");
    const percentParser = (value?: ValueType) =>
        value ? value.toString().replace("%", "") : "";
    const percentPrefix = <Prefix className="ms-2">%</Prefix>;

    return (
        <Container>
            {name || descriptor ? (
                <TitleContainer>
                    <FormFieldName
                        name={name}
                        required={required}
                        recommended={recommended}
                        autofilled={autofilled}
                    />
                    <FormFieldDescriptor descriptor={descriptor} />
                </TitleContainer>
            ) : null}
            <InputContainer>
                {inputLabel ? (
                    <Label error={!!error}>{inputLabel}</Label>
                ) : null}
                <Controller
                    control={control}
                    name={fieldName}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <NumberInput
                            type={"number"}
                            min={min}
                            max={max}
                            prefix={
                                format === "number"
                                    ? null
                                    : format === "dollar"
                                      ? dollarPrefix
                                      : percentPrefix
                            }
                            onChange={onChange}
                            formatter={
                                format === "number"
                                    ? numberFormatter
                                    : format === "dollar"
                                      ? dollarFormatter
                                      : percentFormatter
                            }
                            parser={
                                format === "number"
                                    ? numberParser
                                    : format === "dollar"
                                      ? dollarParser
                                      : percentParser
                            }
                            value={value}
                            style={style}
                            size="middle"
                            onBlur={onBlur}
                            status={error ? "error" : ""}
                        />
                    )}
                />
                {error?.message ? (
                    <ErrorMessage>{error?.message}</ErrorMessage>
                ) : null}
            </InputContainer>
        </Container>
    );
};

const Container = styled.div`
    flex: 1;
    display: flex;
    width: 100%;
    margin-bottom: 24px;
    @media ${ScreenState.MOBILE} {
        flex-direction: column;
        gap: 16px;
        margin-bottom: 16px;
    }
`;

const TitleContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 8px;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    width: 100%;
    justify-content: center;
`;

const Label = styled.p<{ error: boolean }>`
    position: absolute;
    left: 12px;
    top: -6px;
    margin: 0;
    padding: 0 2px 0 2px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: ${SystemColors.v1.gray50};
    background-color: white;
    z-index: 5;
    ${(props) => props.error && `color: ${SystemColors.v1.melon50}`}
`;

const Prefix = styled.p`
    margin: 0;
`;

const NumberInput = styled(InputNumber)`
    width: 100%;
    min-height: 48px;
    border-radius: 8px;
    border-color: ${colors["neutral-400"]};
    .ant-input-number {
        height: 48px;
        background-color: transparent;
    }
    .ant-input-number-input-wrap {
        height: 100%;
        border-radius: 8px;
        overflow: hidden;
    }
    .ant-input-number-input {
        height: 100%;
    }
`;

const ErrorMessage = styled.p`
    margin: 0;
    margin-top: 4px;
    color: ${SystemColors.v1.melon50};
    font-size: 14px;
`;
