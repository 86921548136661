import { useFormContext } from "react-hook-form";

import {
    INTEGRATION_FIELD_NAMES,
    IntegrationAppPlaceholders,
    IntegrationApps,
    IntegrationLinkOrAccountNumber,
    AccountNumberIntegrations
} from "#settings-integrations/utils/types";
import { FormTextInput } from "#settings-integrations/components/shared/form-text-input";

interface IntegrationContainerProps {
    integration: IntegrationApps;
    integrations: IntegrationApps[];
    index: number;
}
const IntegrationContainer = ({ integration }: IntegrationContainerProps) => {
    const { watch } = useFormContext();
    const links: IntegrationLinkOrAccountNumber[] = watch(
        INTEGRATION_FIELD_NAMES.INTEGRATION_LINK_OR_ACCOUNT_NUMBERS
    );

    const linkObjectIndex = links.findIndex(
        (link) => link.integrationApp == integration
    );

    return (
        <>
            <div className="my-2 text-large font-bold">{integration}</div>
            <div className="my-1 text-body font-normal text-neutral-600">
                {!AccountNumberIntegrations.includes(integration)
                    ? "Add your online ordering link."
                    : "Add your Merchant Store ID. This can be accessed by contacting Hungry Panda directly."}
            </div>
            <FormTextInput
                inputLabel={`${integration} Link`}
                fieldName={`${INTEGRATION_FIELD_NAMES.INTEGRATION_LINK_OR_ACCOUNT_NUMBERS}.${linkObjectIndex}.linkOrAccountNumber`}
                placeholder={
                    IntegrationAppPlaceholders[integration]
                        ? IntegrationAppPlaceholders[integration]
                        : "Enter link here."
                }
                httpPlaceholder={
                    !AccountNumberIntegrations.includes(integration)
                }
            />
        </>
    );
};

export default IntegrationContainer;
