import { IProduct, IStore } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";

class ProductNameCheck implements IMenuAutomationCheck<IProduct> {
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "ProductNameCheck";
    }

    get itemToCheck(): ItemType {
        return "product";
    }

    checkItems() {
        return [];
    }

    checkItem(item: IProduct): MenuIssue<IProduct> | null {
        if (item.name === "New Product") {
            return new MenuIssue(
                "InvalidProductName",
                "Products cannot have the name 'New Product'",
                "critical",
                item
            );
        }
        return null;
    }
}

export { ProductNameCheck };
