import {
    EditFont,
    HideSoldOut,
    PinnedCategories,
    PinnedProducts
} from "#devices/components/EditDrawer/Settings";
import {
    HeaderColorPicker,
    MenuBoardBackgroundColorPicker,
    MenuBoardProductBackgroundColorPicker
} from "#devices/components/EditDrawer/Settings/ColorPicker";

type Props = {
    disableBackgroundColorPicker?: boolean;
};

export const InfiniteScroll = ({ disableBackgroundColorPicker }: Props) => (
    <>
        <PinnedCategories />
        <PinnedProducts hideBadge />
        <HideSoldOut />
        <EditFont />
        <HeaderColorPicker />
        {!disableBackgroundColorPicker ? (
            <MenuBoardBackgroundColorPicker />
        ) : null}
        <MenuBoardProductBackgroundColorPicker />
    </>
);
