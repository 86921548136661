import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaymentProvider } from "@snackpass/snackpass-types";

import {
    getDashboardPaymentType,
    setDashboardFilterPaymentType
} from "src/redux/slices";
import { genHash } from "#core/utils";
import { DashboardPaymentTypes } from "#payment-type-picker/lib";

const calculatePaymentProviders = (
    globalPaymentType: DashboardPaymentTypes
): Array<PaymentProvider> => {
    switch (globalPaymentType) {
        case DashboardPaymentTypes.ALL:
            return [];
        case DashboardPaymentTypes.ALL_EXCEPT_CASH_AND_OTHER:
            return [
                PaymentProvider.stripe,
                PaymentProvider.checkout,
                PaymentProvider.paypal,
                PaymentProvider.adjustment,
                PaymentProvider.square,
                PaymentProvider.ocmp,
                PaymentProvider.thirdParty
            ];
        case DashboardPaymentTypes.CASH_AND_OTHER:
            return [PaymentProvider.inStore];
        default:
            return [];
    }
};

export const usePaymentTypePicker = () => {
    //globalSource need to come before useState hook to prevent payment type to be undefined
    const globalPaymentType = useSelector(getDashboardPaymentType);
    const [paymentType, setPaymentType] =
        useState<DashboardPaymentTypes>(globalPaymentType);
    //default for paymentProviders is an empty array, signaling to query all paymentTypes
    const [paymentProviders, setPaymentProviders] = useState<
        Array<PaymentProvider>
    >(calculatePaymentProviders(globalPaymentType));

    useEffect(() => {
        setPaymentProviders(calculatePaymentProviders(globalPaymentType));
        setPaymentType(globalPaymentType);
    }, [globalPaymentType]);

    const dispatch = useDispatch();
    const setGlobalPaymentType = (paymentType: DashboardPaymentTypes) => {
        dispatch(setDashboardFilterPaymentType(paymentType));
    };

    return {
        paymentType: paymentType,
        paymentProviders: paymentProviders,
        setGlobalPaymentType,
        hash: genHash(paymentType)
    };
};
