import * as yup from "yup";

import {
    defaultMenuItem,
    DISCOUNT_TYPES,
    DOLLAR_OPTIONS,
    EXPIRATION_DAY_OPTIONS,
    PERCENT_OPTIONS,
    POINTS_OPTIONS
} from "#promotion/utils/constants";
import {
    FIELD_NAMES,
    QualifierValues,
    RewardPromo
} from "#promotion/utils/types";
import {
    nameValidation,
    discountTypeValidation,
    discountAmountPercentValidation,
    discountAmountDollarValidation,
    discountQualiferValidation,
    pointsValidation,
    discountedItemsValidation,
    toggleValidation,
    expirationDaysValidation
} from "#promotion/utils/validation/field-schemas";

export const RewardDefaultValues = {
    [FIELD_NAMES.NAME]: "",

    [FIELD_NAMES.DISCOUNT_TYPE]: DISCOUNT_TYPES.PERCENT_OFF,
    [FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT]: Number(PERCENT_OPTIONS[0].value),
    [FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS]: Number(DOLLAR_OPTIONS[0].value),

    [FIELD_NAMES.POINTS_REQUIRED]: Number(POINTS_OPTIONS[0].value),
    [FIELD_NAMES.EXPIRATION_DAYS]: EXPIRATION_DAY_OPTIONS[0].value,

    [FIELD_NAMES.DISCOUNT_QUALIFIER]: QualifierValues.ANY_ITEM,
    [FIELD_NAMES.DISCOUNTED_ITEMS]: [defaultMenuItem],
    [FIELD_NAMES.DISCOUNT_ADDONS]: false
} as RewardPromo;

export const RewardPromoValidationSchema = yup.object().shape({
    [FIELD_NAMES.NAME]: nameValidation,

    [FIELD_NAMES.DISCOUNT_TYPE]: discountTypeValidation,
    [FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT]: discountAmountPercentValidation,
    [FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS]: discountAmountDollarValidation,

    [FIELD_NAMES.POINTS_REQUIRED]: pointsValidation,
    [FIELD_NAMES.EXPIRATION_DAYS]: expirationDaysValidation,

    [FIELD_NAMES.DISCOUNT_QUALIFIER]: discountQualiferValidation,
    [FIELD_NAMES.DISCOUNTED_ITEMS]: discountedItemsValidation,
    [FIELD_NAMES.DISCOUNT_ADDONS]: toggleValidation
});
