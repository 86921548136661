import moment from "moment-timezone";

import { DefinedDates } from "../../date-picker/lib";

export const definedDatesToDuration = {
    [DefinedDates.TODAY]: moment.duration(0, "days"),
    [DefinedDates.YESTERDAY]: moment.duration(0, "days"),
    [DefinedDates.LAST7DAYS]: moment.duration(6, "days"),
    [DefinedDates.LAST30DAYS]: moment.duration(29, "days"),
    [DefinedDates.LAST90DAYS]: moment.duration(89, "days"),
    [DefinedDates.LAST365DAYS]: moment.duration(364, "days"),
    [DefinedDates.CUSTOM]: moment.duration(6, "days")
};

/**
 * Calculates the previous time range for a time pre-defined time range
 * these durations are defined in `definedDatesToDuration`. This function
 * addresses the following edgecases
 *
 * ## Global duration is "today"
 *    return the same time range but last week
 *    previous for (10/05 - 10/06) is (9/28 - 9/29), i.e. (tue-wed) this week vs.
 *    (tue-wed) last week
 * ## Greater than a week of length
 *    return the same length of time transposed before the current time range
 */
export const calculatePreviousDefined = ({
    globalDuration,
    relativeEndDate
}: {
    globalDuration: DefinedDates;
    relativeEndDate: moment.Moment;
}) => {
    const currentDuration = definedDatesToDuration[globalDuration];
    const startDate = relativeEndDate.clone().subtract(currentDuration);

    if (globalDuration === DefinedDates.TODAY) {
        const previousStartDate = startDate
            .clone()
            .subtract(7, "days")
            .startOf("day");

        const previousEndDate = startDate
            .clone()
            .subtract(7, "day")
            .endOf("day");

        return { previousStartDate, previousEndDate };
    }

    const previousStartDate = startDate
        .clone()
        .subtract(currentDuration)
        .subtract(1, "day")
        .startOf("day");

    const previousEndDate = startDate.clone().subtract(1, "day").endOf("day");

    return { previousStartDate, previousEndDate };
};

/**
 * Calculates the previous time range for a time pre-defined time range
 * these durations are defined in `definedDatesToDuration`. This function
 * addresses the following edgecases
 *
 * ## difference between start & end is less than a week
 *    return the same time range but last week; e.g. previous for (10/05 - 10/06) is (9/28 - 9/29),
 *    i.e. (tue-wed) this week vs. (tue-wed) last week
 * ## Greater than a week of length
 *    return the same length of time transposed before the current time range
 */
export const calculatePreviousCustom = ({
    relativeEndDate,
    relativeStartDate
}: {
    relativeEndDate: moment.Moment;
    relativeStartDate: moment.Moment;
}) => {
    const diff = relativeEndDate.diff(relativeStartDate, "days");

    if (diff > 6) {
        const previousStartDate = relativeStartDate
            .clone()
            .subtract(Math.abs(diff), "days")
            .subtract(1, "day")
            .startOf("day");

        const previousEndDate = relativeStartDate
            .clone()
            .subtract(1, "day")
            .endOf("day");

        return {
            previousStartDate,
            previousEndDate
        };
    }

    const previousStartDate = relativeStartDate
        .clone()
        .subtract(7, "days")
        .startOf("day");

    const previousEndDate = relativeEndDate
        .clone()
        .subtract(7, "day")
        .endOf("day");

    return {
        previousStartDate,
        previousEndDate
    };
};

/**
 * Sets the global start and end dates s.t. it updates as expected when they
 * are clicked.
 */
export const calculateCurrentTimeFromDuration = (
    globalDuration: DefinedDates
) => {
    const currentDuration = definedDatesToDuration[globalDuration];
    if (globalDuration === DefinedDates.TODAY) {
        const startDate = moment();
        const endDate = moment();
        return { startDate, endDate };
    }
    if (globalDuration === DefinedDates.YESTERDAY) {
        const startDate = moment().subtract(1, "day");
        const endDate = moment().subtract(1, "day");
        return { startDate, endDate };
    }

    const startDate = moment().subtract(currentDuration);
    const endDate = moment();

    return { startDate, endDate };
};
