import {
    Accounting,
    IConditions,
    IDealItem,
    IDiscount,
    IPromotion,
    IStore,
    ITimeRangeSchema,
    PromoTarget,
    PromoTypes,
    RewardExpiration,
    ObjectId
} from "@snackpass/snackpass-types";
import moment from "moment";
import _, { Dictionary } from "lodash";

import api from "src/api/rest";
import {
    DayOfWeek,
    GenericPromo,
    HourItemTime,
    HoursItem,
    ScheduleDay,
    Time,
    FulfillmentValues,
    PlatformValues,
    RewardPromo,
    DealPromo,
    FIELD_NAMES,
    QualifierValues,
    FormUsageTypes,
    DiscountPromo,
    GiftCardPromo
} from "#promotion/utils/types";
import {
    dayMap,
    DAYS_OF_WEEK,
    DISCOUNT_TYPES,
    HOURS_PER_DAY,
    MINUTES_PER_DAY,
    MINUTES_PER_HOUR,
    SECONDS_PER_MINUTE
} from "#promotion/utils/constants";
import { CategoryWithProducts } from "#menu/domain";
import { logAndSendError } from "src/utils/errors";

export type NormalizedWeekMinuteOffset = number;

export function computeTime(time: Time): NormalizedWeekMinuteOffset {
    return (
        time.day * MINUTES_PER_DAY +
        time.hours * MINUTES_PER_HOUR +
        time.minutes
    );
}

const computeDaysToSeconds = (days: number) =>
    days * HOURS_PER_DAY * MINUTES_PER_HOUR * SECONDS_PER_MINUTE;

export const format = "h:mm a";

export function hourItemTimeToLennyTime(hourItemTime: HourItemTime): Time {
    const m = moment(hourItemTime.time);
    return {
        day: DAYS_OF_WEEK.indexOf(hourItemTime.dayOfWeek),
        hours: m.hours(),
        minutes: m.minutes(),
        seconds: m.seconds()
    };
}

export function hourItemTimeToNormalizedWeekOffset(
    hourItemTime: HourItemTime
): NormalizedWeekMinuteOffset {
    return computeTime(hourItemTimeToLennyTime(hourItemTime));
}

export function formatHourItem(hoursItem: HoursItem): ITimeRangeSchema {
    return {
        start: hourItemTimeToNormalizedWeekOffset(hoursItem.startTime),
        end: hourItemTimeToNormalizedWeekOffset(hoursItem.endTime)
    };
}

export function transformFormHoursVersionToHours(
    local: HoursItem[]
): ITimeRangeSchema[] {
    return local
        .map((hoursItem) => formatHourItem(hoursItem))
        .sort((a: ITimeRangeSchema, b: ITimeRangeSchema) => {
            if (a.start > b.start) {
                return 1;
            } else if (a.start === b.start && a.end > b.end) {
                return 1;
            }
            return -1;
        });
}

// ______________________________________________________
// Most everything above this line is ported from Snackface

const scheduleDayToLennyTime = (
    scheduleDay: ScheduleDay[],
    hours: ITimeRangeSchema[],
    dayOfWeek: DayOfWeek
) => {
    const daySchedule = scheduleDay.map(
        (schedule: ScheduleDay): HoursItem => ({
            startTime: {
                dayOfWeek,
                time: new Date(schedule.start)
            },
            endTime: {
                dayOfWeek,
                time: new Date(schedule.end)
            }
        })
    );

    hours.push(...transformFormHoursVersionToHours(daySchedule));
};

/**
 * Uses code as a discriminator for whether promotion data matches that of a Promo Code
 *  - pointsRequired will be non-zero in Reward promo
 */
const isPromoCodePromo = (
    promoData: GenericPromo | RewardPromo
): promoData is RewardPromo =>
    !!(promoData as GenericPromo)[FIELD_NAMES.PROMO_CODE];

/**
 * Uses pointsRequired as a discriminator for whether promotion data matches that of a Reward
 *  - pointsRequired will be non-zero in Reward promo
 */
const isRewardPromo = (
    promoData: GenericPromo | RewardPromo | GiftCardPromo
): promoData is RewardPromo =>
    !!(promoData as RewardPromo)[FIELD_NAMES.POINTS_REQUIRED];

/**
 * Uses the length of discounted_items | required_purchase as a discriminator for whether promotion data matches that of a Deal
 *  - length will be > 1 in Deal promo for discounted_items
 *  - or required_purchase will be true
 */
const isDealPromo = (
    promoData: GenericPromo | RewardPromo | GiftCardPromo
): promoData is DealPromo => {
    if (isGiftCardPromo(promoData)) {
        return false;
    }
    if (
        (promoData as DealPromo)[FIELD_NAMES.DISCOUNTED_ITEMS].length > 1 ||
        ((promoData as DealPromo)[FIELD_NAMES.REQUIRED_PURCHASE] &&
            (promoData as DealPromo)[FIELD_NAMES.REQUIRED_PURCHASE_ITEMS]
                .length)
    ) {
        return true;
    }
    return false;
};

/**
 * Gift cards explicitly state that they are of the gift card promo type.
 */
const isGiftCardPromo = (
    promoData: GenericPromo | RewardPromo | GiftCardPromo
): promoData is GiftCardPromo => {
    if (
        (promoData as GiftCardPromo)[FIELD_NAMES.PROMOTION_TYPE] ===
        PromoTypes.GiftCard
    ) {
        return true;
    }
    return false;
};

/**
 * No great way to determine discount other than just discrimnating based on negative of other type guards
 */
const isDiscountPromo = (
    promoData: GenericPromo | RewardPromo | GiftCardPromo
): promoData is DiscountPromo => {
    if (
        !isDealPromo(promoData) &&
        !isRewardPromo(promoData) &&
        !isGiftCardPromo(promoData)
    ) {
        return true;
    }
    return false;
};

const getDealDiscount = (promoData: DealPromo) => {
    const discount: IDiscount = {};

    if (promoData.DISCOUNT_TYPE === DISCOUNT_TYPES.PERCENT_OFF) {
        discount.percentOff = promoData.DISCOUNT_AMOUNT_PERCENT;
    } else if (promoData.DISCOUNT_TYPE === DISCOUNT_TYPES.DOLLARS_OFF) {
        discount.dollarsOff = promoData.DISCOUNT_AMOUNT_DOLLARS;
    } else if (promoData.DISCOUNT_TYPE === DISCOUNT_TYPES.FREE_ITEM) {
        // If user specifies free item, the promotion automatically has 100% off
        discount.percentOff = 100;
    }
    return discount;
};

/**
 *
 * @param productIds List of product ids selected
 * @param categories List of category ids selected
 * @param storeCategories List of categories at store
 * @returns List of productIds that are **not** already accounted for by a category being selected
 */
const filterProductIdsBasedOnCategories = (
    productIds: string[],
    categories: string[],
    categoryMap: Dictionary<CategoryWithProducts>
) =>
    productIds.filter((p_id: string) => {
        const includedCategoryProducts = categories.flatMap(
            (id) => categoryMap[id]?.productIds || []
        );
        return !includedCategoryProducts.includes(p_id);
    });

const derivePromoDataTypeFromFields = (
    promoData: GenericPromo | RewardPromo | GiftCardPromo
): PromoTypes => {
    const type = PromoTypes.Discount;

    if (isRewardPromo(promoData)) {
        return PromoTypes.Reward;
    }

    if (isDealPromo(promoData)) {
        return PromoTypes.Deal;
    }

    if (isGiftCardPromo(promoData)) {
        return PromoTypes.GiftCard;
    }

    if (isPromoCodePromo(promoData)) {
        return PromoTypes.PromoCode;
    }

    return type;
};

const derivePromotionFromFormData = (
    store: IStore,
    categoryMap: Dictionary<CategoryWithProducts>,
    formData: GenericPromo | RewardPromo | DealPromo | GiftCardPromo
): Partial<IPromotion> => {
    let promoImage = null;
    let conditions: IConditions = {};
    let targets: PromoTarget[] = [];
    let multiuse = true;
    let maximumUses: number | null = null;
    let activeTimePeriod: {
        startTime: Date | null;
        endTime: Date | null;
    } | null = null;
    let isEmployeeMode = false;
    let isKioskEligible = false;
    let showInFreeStuff = true; // All discounts should display in discounts section on app by default
    let pointsRequired = 0;
    let dealItems: IDealItem[] = [];
    let categories: string[] = [];
    let productIds: ObjectId[] = [];
    let rewardExpiration: RewardExpiration | null = null;
    let code = "";
    const name = `${formData[FIELD_NAMES.NAME]}${
        isGiftCardPromo(formData) && formData[FIELD_NAMES.MINIMUM_PRICE]
            ? ` ($${formData[FIELD_NAMES.MINIMUM_PRICE]} Min)`
            : ""
    }`;
    const nameForStore = name;
    const marketingName = name;
    const hours: ITimeRangeSchema[] = [];

    let discount: IDiscount = {};
    const accounting: Accounting = {
        commissionVoidItem: false,
        totalAmountSpent: {
            snackpass: 0,
            store: 0
        },
        contributionPolicies: [
            {
                commissionVoid: false,
                snackpassSubsidizationDollars: null,
                snackpassSubsidizationPercent: null,
                conditions: {
                    dollarsDiscounted: null,
                    redemptions: null
                }
            }
        ],
        voidCommissionOnPurchase: false
    };

    const type = derivePromoDataTypeFromFields(formData);

    // Discounted Items
    let shouldDiscountAddons = false;
    const storewide = isGiftCardPromo(formData)
        ? true
        : formData[FIELD_NAMES.DISCOUNT_QUALIFIER] === QualifierValues.ANY_ITEM;
    if (formData.DISCOUNT_TYPE === DISCOUNT_TYPES.PERCENT_OFF) {
        discount.percentOff = formData.DISCOUNT_AMOUNT_PERCENT;

        // Is this not applicable for Deals?
        if (isDiscountPromo(formData) || isRewardPromo(formData)) {
            // Only applicable to this type of discount
            shouldDiscountAddons = formData.DISCOUNT_ADDONS;
        }
    } else if (formData.DISCOUNT_TYPE === DISCOUNT_TYPES.DOLLARS_OFF) {
        discount.dollarsOff = formData.DISCOUNT_AMOUNT_DOLLARS;
    } else if (formData.DISCOUNT_TYPE === DISCOUNT_TYPES.FREE_ITEM) {
        // If user specifies free item, the promotion automatically has 100% off
        discount.percentOff = 100;

        if (isDiscountPromo(formData) || isRewardPromo(formData)) {
            shouldDiscountAddons = formData.DISCOUNT_ADDONS;
        }
    }

    /**
     * Discount only supports one MenuItem, thus the explicit 0 index
     * If promoData has more than one MenuItem in Discounted_Items, it will be treated as a Deal
     * and categories and productIds will be set to [] later.
     */
    const selectedCategories = isGiftCardPromo(formData)
        ? []
        : formData[FIELD_NAMES.DISCOUNTED_ITEMS][0].categories;
    const selectedProducts = isGiftCardPromo(formData)
        ? []
        : formData[FIELD_NAMES.DISCOUNTED_ITEMS][0].products;
    categories = selectedCategories;
    productIds = filterProductIdsBasedOnCategories(
        selectedProducts,
        selectedCategories,
        categoryMap
    );

    if (isDealPromo(formData) || isDiscountPromo(formData)) {
        promoImage = formData.IMAGE || null; // No image === "", will get set to null
        // Cart Rules
        conditions = {
            cartMin: formData[FIELD_NAMES.CART_MINIMUM],
            cartMax: formData[FIELD_NAMES.CART_MAXIMUM],
            deliveryOnly:
                formData[FIELD_NAMES.FULFILLMENT_METHODS] ===
                FulfillmentValues.Delivery,
            onePerCart: formData[FIELD_NAMES.ONE_PER_CART],
            pickupOnly:
                formData[FIELD_NAMES.FULFILLMENT_METHODS] ===
                FulfillmentValues.PickupAndDineIn
        };

        // Customer Rules
        targets =
            formData.AUDIENCE !== PromoTarget.All ? [formData.AUDIENCE] : [];
        multiuse = !formData.SINGLE_USE;
        maximumUses = formData.LIMIT_USES ? formData.TOTAL_USES : null;

        // Schedule and Duration
        const startTime = formData[FIELD_NAMES.DURATION_START_DATE];
        const endTime = formData[FIELD_NAMES.DURATION_END_DATE];

        if (formData[FIELD_NAMES.DURATION_ENABLED]) {
            activeTimePeriod = {
                startTime: startTime ? moment(startTime).toDate() : null,
                endTime: endTime ? moment(endTime).toDate() : null
            };
        }

        for (const day of dayMap) {
            if (formData[day.enabled]) {
                scheduleDayToLennyTime(
                    formData[day.field],
                    hours,
                    day.day as DayOfWeek
                );
            }
        }

        /**
         * OnlyRegister: needs kiosk, needs employeeMode, should not show in app (freeStuff false)
         * AppAndKiosk: needs kiosk
         * AppOnly: is default, should show in app (freeStuff true)
         */
        if (formData.PLATFORMS === PlatformValues.OnlyRegister) {
            isKioskEligible = true; // In order to display on register, this also needs to be true
            isEmployeeMode = true;
            showInFreeStuff = false;
        }

        if (formData.PLATFORMS === PlatformValues.AppAndKioskAndRegister) {
            isKioskEligible = true;
        }

        if (formData.PLATFORMS === PlatformValues.AppAndKiosk) {
            isKioskEligible = true;
        }

        if (isPromoCodePromo(formData)) {
            code = formData.PROMO_CODE;
        }
    }

    if (isGiftCardPromo(formData)) {
        if (
            formData[FIELD_NAMES.MAXIMUM_DISCOUNT] &&
            formData.DISCOUNT_TYPE === DISCOUNT_TYPES.PERCENT_OFF
        ) {
            discount.maximumDiscount = formData[FIELD_NAMES.MAXIMUM_DISCOUNT];
        }
        if (formData[FIELD_NAMES.MINIMUM_PRICE]) {
            conditions.minimumPrice = formData[FIELD_NAMES.MINIMUM_PRICE];
        }
        promoImage = formData.IMAGE || null;
        isKioskEligible = true;
    }

    if (isRewardPromo(formData)) {
        const days =
            formData[FIELD_NAMES.EXPIRATION_DAYS] > 0
                ? formData[FIELD_NAMES.EXPIRATION_DAYS]
                : null;

        formData.EXPIRATION_DAYS > 0 ? formData.EXPIRATION_DAYS : null;
        pointsRequired = formData.POINTS_REQUIRED;
        //@ts-expect-error _id is not necessary field now
        rewardExpiration = days
            ? { seconds: computeDaysToSeconds(days) }
            : null;
    }

    if (isDealPromo(formData)) {
        // Override some of the generic promo fields that don't apply to deals
        discount = {};
        categories = [];
        productIds = [];

        const dealDiscount = getDealDiscount(formData);

        //@ts-expect-error Wants _id, but that's for Mongo to assign
        const discountedItems: IDealItem[] =
            formData.DISCOUNT_QUALIFIER === QualifierValues.SPECIFIC
                ? formData.DISCOUNTED_ITEMS.map((item, n) => {
                      const categories = item.categories;
                      const productIds = filterProductIdsBasedOnCategories(
                          item.products,
                          categories,
                          categoryMap
                      );
                      return {
                          name: item.label || `Discounted Item ${n + 1}`,
                          productIds,
                          categories,
                          discount: dealDiscount
                      };
                  })
                : [];
        //@ts-expect-error Wants _id, but that's for Mongo to assign
        const requiredItems: IDealItem[] = formData.REQUIRED_PURCHASE
            ? formData.REQUIRED_PURCHASE_ITEMS.map((item, n) => {
                  const categories = item.categories;
                  const productIds = filterProductIdsBasedOnCategories(
                      item.products,
                      categories,
                      categoryMap
                  );
                  return {
                      name: item.label || `Required Item ${n + 1}`,
                      productIds,
                      categories,
                      discount: null
                  };
              })
            : [];

        dealItems = requiredItems.concat(discountedItems);
    }

    // Put all the pieces together
    const promotion: Partial<IPromotion> & { storeId: string } = {
        accounting,
        activeTimePeriod,
        categories,
        code: code,
        codes: [],
        conditions,
        dealItems,
        description: "",
        discount,
        hours: !_.isEmpty(hours)
            ? {
                  local: hours,
                  zone: store.hours.zone
              }
            : null,
        imageUrl: promoImage,
        isEmployeeMode,
        isKioskEligible,
        marketingName,
        maximumUses,
        multiuse,
        name,
        nameForStore,
        pointsRequired,
        productIds,
        rewardExpiration,
        shouldDiscountAddons,
        storeId: store._id,
        storewide,
        targets,
        type,
        showInFreeStuff
    };

    return promotion;
};

const submitNewPromo = async (
    store: IStore,
    categoryMap: Dictionary<CategoryWithProducts>,
    promoData: GenericPromo | RewardPromo | GiftCardPromo
) => {
    const body = derivePromotionFromFormData(store, categoryMap, promoData);
    return api.promotions.create(body);
};

const submitEditedPromo = async (
    store: IStore,
    categoryMap: Dictionary<CategoryWithProducts>,
    promoData: GenericPromo | RewardPromo | GiftCardPromo,
    promoId: string
) => {
    const body = derivePromotionFromFormData(store, categoryMap, promoData);
    return api.promotions.update(promoId, body);
};

export const submitPromo = async (
    formUsage: FormUsageTypes,
    activeStore: IStore,
    categoryMap: Dictionary<CategoryWithProducts>,
    data: GenericPromo | RewardPromo | GiftCardPromo,
    promoId?: string
) => {
    if (formUsage === FormUsageTypes.Edit) {
        if (!promoId) {
            logAndSendError("No promoId provided for edit promo");
            return;
        }
        return await submitEditedPromo(activeStore, categoryMap, data, promoId);
    }

    return await submitNewPromo(activeStore, categoryMap, data);
};
