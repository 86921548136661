import React, { useContext, useMemo, useState } from "react";
import { Container, Col, Form, Row, Modal } from "react-bootstrap";
import { debounce } from "radash";
import { Maybe } from "@snackpass/snackpass-types/src/utils/types";
import { useSelector } from "react-redux";
import { AxiosError } from "axios";
import { z } from "zod";

import { Button } from "src/@/components/ui/button";
import Text from "#devices/components/Text";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";
import { getActiveStore } from "src/redux/selectors";
import api from "src/api/rest";
import {
    BaseStoreDevice,
    DeviceType,
    MDMProvider
} from "#devices/utils/deviceTypes";
import theme from "#devices/components/theme";
import { CenteredSpin } from "#devices/components/Loading";
import { ErrorWithCause } from "src/utils/errors";

const InvalidFeedback = ({ children }: { children: React.ReactNode }) => (
    <Form.Control.Feedback
        type="invalid"
        style={{ fontSize: theme.typography.body.fontSize }}
    >
        {children}
    </Form.Control.Feedback>
);

const ConfirmationRow = ({
    label,
    value
}: {
    label: string;
    value: Maybe<string>;
}) => (
    <Row>
        <Col className="col-5">
            <Text.Label>{`${label}: `}</Text.Label>
        </Col>
        <Col>
            <Text.LargeBody className="fw-normal">{value}</Text.LargeBody>
        </Col>
    </Row>
);

const formatNameBeforeCreate = (name: string) => name.replaceAll("`", "'");

const Step4 = (): JSX.Element => {
    const {
        storeDevices,
        addNewDeviceName,
        addNewScreenSerialValue,
        addNewDeviceTypeValue,
        previousStep,
        setStoreDevices,
        setAddNewDeviceName,
        setAddNewDevicesModalCurrentStep,
        setAddNewDevicesModalOpen,
        setAddNewScreenSerialValue,
        setAddNewDeviceTypeValue,
        setDeviceToView,
        setIsDetailsDrawerVisible,
        refetch
    } = useContext(DevicesPageContext);

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

    const resetState = () => {
        setAddNewDeviceName("");
        setAddNewDevicesModalOpen(false);
        setAddNewDevicesModalCurrentStep(1);
        setAddNewScreenSerialValue("");
        setAddNewDeviceTypeValue("");
        setErrorMessage(null);
        setIsSaving(false);
    };

    const createDeviceRequest = (storeId: string) => {
        setIsSaving(true);
        const name = formatNameBeforeCreate(addNewDeviceName);
        const deviceType = addNewDeviceTypeValue as DeviceType;

        // Update this if other providers become available
        const mdmProvider = [
            DeviceType.Printer,
            DeviceType.CashDrawer
        ].includes(deviceType)
            ? null
            : MDMProvider.Esper;

        (deviceType === DeviceType.StripeTerminal
            ? api.storeDevices.createStripeTerminal({
                  registrationCode: addNewScreenSerialValue,
                  deviceName: name,
                  storeId
              })
            : api.storeDevices.createStoreDevice({
                  id: addNewScreenSerialValue.toUpperCase(),
                  deviceType,
                  name,
                  storeId,
                  mdmProvider
              })
        )
            .then(({ data: { device } }) => {
                resetState();

                setStoreDevices(
                    storeDevices ? [device, ...storeDevices] : [device]
                );
                setDeviceToView(device);
                setIsDetailsDrawerVisible(true);
            })
            .catch((error) => {
                let message = "Unable to create device at this time";
                if (
                    error instanceof ErrorWithCause &&
                    error.cause instanceof AxiosError
                ) {
                    const parseResult = z
                        .object({
                            message: z.string()
                        })
                        .safeParse(error.cause.response?.data);
                    if (parseResult.success) {
                        message = parseResult.data.message;
                    }
                }
                setErrorMessage(message);
            })
            .finally(() => {
                setIsSaving(false);
                void refetch();
            });
    };

    const debouncedCreateDevice = useMemo(
        () => debounce({ delay: 300 }, createDeviceRequest),
        []
    );

    const onSubmit = () => {
        if (activeStore?._id && !isSaving) {
            debouncedCreateDevice(activeStore._id);
        }
    };

    const deviceType = getDeviceTypeName(addNewDeviceTypeValue);
    const activeStore = useSelector(getActiveStore);

    if (!activeStore) return <div>No active store selected.</div>;

    const locale = `${activeStore.addressComponents.city}, ${activeStore.addressComponents.state} ${activeStore.addressComponents.zip}`;

    const deviceIdentifier = ((t: string) => {
        const defaultIdentifier = {
            label: "Code",
            value: addNewScreenSerialValue
        };
        if (!storeDevices) return defaultIdentifier;

        switch (t) {
            case DeviceType.Printer:
                return { ...defaultIdentifier, label: "Serial" };
            case DeviceType.CashDrawer: {
                const value =
                    storeDevices.find(
                        (d: BaseStoreDevice) => d.id === addNewScreenSerialValue
                    )?.serial ?? addNewScreenSerialValue;

                const printerValue =
                    addNewScreenSerialValue === "NONE_SELECTED"
                        ? "No Printer Selected"
                        : value;

                return { label: "Printer", value: printerValue };
            }
            case DeviceType.StripeTerminal:
                return {
                    label: "Registration Code",
                    value: addNewScreenSerialValue
                };
            default:
                return defaultIdentifier;
        }
    })(addNewDeviceTypeValue);

    return (
        <>
            <Modal.Body>
                <Form.Group controlId="addNewScreen" className="mb-4">
                    <ConfirmationRow label="Device Type" value={deviceType} />
                    <ConfirmationRow
                        label={deviceIdentifier.label}
                        value={deviceIdentifier.value}
                    />
                    <ConfirmationRow
                        label="Device Name"
                        value={addNewDeviceName}
                    />
                    <ConfirmationRow label="Store" value={activeStore.name} />
                    <ConfirmationRow
                        label="Street"
                        value={activeStore.addressComponents.line1}
                    />
                    <ConfirmationRow label="City, State" value={locale} />
                    <Form.Control
                        style={{ display: "none" }}
                        isInvalid={!!errorMessage}
                    />
                    <InvalidFeedback>{errorMessage}</InvalidFeedback>
                </Form.Group>
            </Modal.Body>

            <Modal.Footer>
                <Text.LargeBody
                    style={{
                        color: theme.typography.largeBody.color,
                        fontWeight: theme.typography.largeBody.fontWeight,
                        textAlign: "left"
                    }}
                >
                    By clicking "Submit" you confirm the accuracy of the
                    information above.
                </Text.LargeBody>
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            <Button
                                variant="outline"
                                className="mt-3 w-full md:mt-0"
                                onClick={previousStep}
                                disabled={isSaving}
                            >
                                Back
                            </Button>
                        </Col>
                        <Col sm={6}>
                            <Button
                                className="mt-3 w-full md:mt-0"
                                onClick={onSubmit}
                                disabled={isSaving}
                            >
                                {isSaving ? <CenteredSpin small /> : "Submit"}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </>
    );
};

export default Step4;
