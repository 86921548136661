import { useState, useCallback } from "react";
import { NumberInput } from "@tremor/react";
import { toast } from "sonner";
import { ReloadIcon } from "@radix-ui/react-icons";

import api from "src/api/rest";
import { Button } from "src/@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "src/@/components/ui/dialog";
import { CardTitle } from "src/@/components/ui/card";

const PointBalanceInput = ({
    punchcardId,
    points,
    modalOpen,
    setModalOpen,
    refetch
}: {
    punchcardId: string;
    points: number;
    modalOpen: boolean;
    setModalOpen: (isOpen: boolean) => void;
    refetch: () => void;
}) => {
    const [pointBalance, setPointBalance] = useState<number | null>(points);
    const [inputChanged, setInputChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const modifyPoints = useCallback(
        async (points: number) => {
            if (!punchcardId) {
                toast.error("Couldn't update points. No punchcard ID found.");
                return;
            }
            try {
                setIsLoading(true);
                await api.punchCards.update(punchcardId, {
                    pointsBalance: Math.round(points)
                });
                toast.success("Points updated successfully");
            } catch (e) {
                console.error("Error updating points:", e);
                toast.error("Could not update points. Please try again later.");
            } finally {
                setIsLoading(false);
                setPointBalance(Math.round(points));
                await refetch();
            }
        },
        [punchcardId, refetch]
    );

    const handleSaveClick = useCallback(async () => {
        try {
            if (!inputChanged) {
                toast.error("Nothing was changed.");
                return;
            }
            if (pointBalance !== null) {
                await modifyPoints(pointBalance);
            } else {
                setPointBalance(points);
                toast.info("No new point balance value entered.");
            }
            setInputChanged(false);
        } catch (error) {
            console.error("Error in handleSaveClick:", error);
            toast.error(
                "An error occurred while saving. Please try again later."
            );
        }
    }, [points, inputChanged, modifyPoints, pointBalance]);

    const handleNewBalance = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value.trim();
            if (inputValue === "") {
                setPointBalance(null);
                setInputChanged(points !== null ? true : false);
            } else if (!isNaN(Number(inputValue)) && points !== null) {
                setPointBalance(Math.round(Number(inputValue)));
                setInputChanged(Math.round(Number(inputValue)) !== points);
            }
        },
        [points]
    );

    const handleDeltaBalance = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value.trim();
            if (inputValue === "") {
                setPointBalance(points);
                setInputChanged(false);
            } else if (!isNaN(Number(inputValue)) && points !== null) {
                const newBalance = points + Number(inputValue);
                setPointBalance(Math.round(newBalance));
                setInputChanged(Math.round(newBalance) !== points);
            }
        },
        [points]
    );

    const editModalOpenChange = useCallback(
        (open: boolean) => {
            setModalOpen(open);
            setPointBalance(points);
            setInputChanged(false);
        },
        [points, setModalOpen]
    );

    return (
        <Dialog open={modalOpen} onOpenChange={editModalOpenChange}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Adjust Point Balance</DialogTitle>
                    <DialogDescription>
                        Please enter an adjustment amount or a new value.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex items-center space-x-4">
                    <div className="flex w-full items-start">
                        <CardTitle className="text-base font-medium">
                            Adjust By
                        </CardTitle>
                    </div>
                    <NumberInput
                        icon={() => (
                            <div className="absolute right-0 mr-20 flex shrink-0 items-center text-micro">
                                points
                            </div>
                        )}
                        placeholder="0"
                        min={-points}
                        step={1}
                        className="ml-96 min-w-48 flex-1 [&>input]:pl-2"
                        value={
                            pointBalance !== null && pointBalance - points
                                ? pointBalance - points
                                : ""
                        }
                        onChange={(e) => {
                            handleDeltaBalance(e);
                        }}
                        tabIndex={-1} // prevents autofocus when modal opens
                    />
                </div>
                <div className="flex items-center space-x-4">
                    <div className="flex w-full items-start">
                        <CardTitle className="text-base font-medium">
                            New Balance
                        </CardTitle>
                    </div>
                    <NumberInput
                        icon={() => (
                            <div className="absolute right-0 mr-20 flex shrink-0 items-center text-micro">
                                points
                            </div>
                        )}
                        min={0}
                        placeholder="0"
                        step={1}
                        className="ml-12 min-w-48 flex-1 [&>input]:pl-2"
                        value={String(pointBalance) ?? ""}
                        onChange={(e) => handleNewBalance(e)}
                        tabIndex={-1} // prevents autofocus when modal opens
                    />
                </div>
                <DialogFooter>
                    <Button
                        disabled={isLoading}
                        onClick={async () => {
                            await handleSaveClick();
                            setModalOpen(false);
                        }}
                        className="w-full"
                    >
                        {isLoading && (
                            <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Adjust Point Balance
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
export default PointBalanceInput;
