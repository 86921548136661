import { zodResolver } from "@hookform/resolvers/zod";
import { IStore } from "@snackpass/snackpass-types";
import { FormProvider, useForm } from "react-hook-form";

import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "src/@/components/ui/form";
import { Header } from "#settings/settings-fees/components/header";
import { ConvenienceFormSchema } from "#settings/settings-fees/utils/schema";
import { ConvenienceFormValues } from "#settings/settings-fees/utils/types";
import { FeeInput } from "#settings/settings-fees/components/fee-input";
import { convenienceFormDefaults } from "#settings/settings-fees/forms/convenience/convenience-defaults";
import { useUpdateConvenience } from "#settings/settings-fees/utils/hooks/useUpdateConvenience";
import { ConvenienceFooter } from "#settings/settings-fees/forms/convenience/convenience-footer";

export const ConvenienceFeeForm = ({ store }: { store: IStore }) => {
    const form = useForm<ConvenienceFormValues>({
        defaultValues: convenienceFormDefaults(store),
        resolver: zodResolver(ConvenienceFormSchema),
        mode: "all"
    });

    const updateStore = useUpdateConvenience({
        storeId: store._id,
        reset: form.reset
    });

    return (
        <>
            <form
                onSubmit={form.handleSubmit(async (values) =>
                    updateStore(values)
                )}
                className="relative h-full w-full overflow-hidden"
            >
                <FormProvider {...form}>
                    <Header
                        title="Service Fees (Customer pays Store)"
                        titleSubtext="Convenience Fee"
                        subtitle={`
                            When a purchase is made from a particular platform (app,
                            kiosk, or online ordering), we use the customer to store
                            convenience fee policy entered in the corresponding row
                            below. These fees go to the STORE. To disable any fee,
                            simply set the fee to 0.
                    `}
                    />
                    <div className="mx-auto mb-2 flex w-fit flex-col sm:flex-row">
                        <FormField
                            control={form.control}
                            name="app"
                            render={({ field }) => (
                                <FormItem className="mr-4 mt-2 flex w-[200px] flex-col">
                                    <FormLabel>App</FormLabel>
                                    <FormControl>
                                        <FeeInput
                                            value={field.value}
                                            prefix="$"
                                            step={0.01}
                                            placeholder="0.0"
                                            min={0}
                                            max={1}
                                            onChange={(e) =>
                                                field.onChange(
                                                    parseFloat(e.target.value)
                                                )
                                            }
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="kiosk"
                            render={({ field }) => (
                                <FormItem className="mr-4 mt-2 flex w-[200px] flex-col">
                                    <FormLabel>Kiosk</FormLabel>
                                    <FormControl>
                                        <FeeInput
                                            value={field.value}
                                            prefix="$"
                                            step={0.01}
                                            placeholder="0.0"
                                            min={0}
                                            max={1}
                                            onChange={(e) =>
                                                field.onChange(
                                                    parseFloat(e.target.value)
                                                )
                                            }
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="online"
                            render={({ field }) => (
                                <FormItem className="mr-4 mt-2 flex w-[220px] flex-col">
                                    <FormLabel>Online Ordering</FormLabel>
                                    <FormControl>
                                        <FeeInput
                                            value={field.value}
                                            prefix="$"
                                            step={0.01}
                                            placeholder="0.0"
                                            min={0}
                                            max={1}
                                            onChange={(e) =>
                                                field.onChange(
                                                    parseFloat(e.target.value)
                                                )
                                            }
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <ConvenienceFooter />
                </FormProvider>
            </form>
        </>
    );
};
