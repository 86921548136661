import * as yup from "yup";
import { DefaultTipType } from "@snackpass/snackpass-types";

export enum FormFieldEnum {
    collectTip = "collectTip",
    showNoTip = "showNoTip",
    defaultTipType = "defaultTipType",
    defaultTipValue = "defaultTipValue",
    defaultTips = "defaultTips"
}

export type TippingType = {
    collectTip: boolean;
    showNoTip: boolean;
    defaultTipType: DefaultTipType;
    defaultTipValue: number;
    defaultTips: Array<number>;
};

export const tippingInitialValues = {
    collectTip: true,
    showNoTip: true,
    defaultTipType: DefaultTipType.Flat,
    defaultTipValue: 0,
    defaultTips: []
};

export const tippingValidationSchema = yup.object({
    collectTip: yup.boolean(),
    showNoTip: yup.boolean(),
    defaultTipType: yup
        .string()
        .oneOf([DefaultTipType.Flat, DefaultTipType.Percent]),
    defaultTipValue: yup.number().min(0),
    defaultTips: yup.array().of(
        yup
            .number()
            .typeError("use “Show no tip option” instead")
            .test({
                name: "Tip preset can not be 0",
                message: "use “Show no tip option” instead",
                test: (value: number | undefined | null) =>
                    typeof value === "number" && value > 0
            })
            .test({
                name: "Tip preset can not be above 100%",
                message: "Tip cannot be above 100%",
                test: (value: number | undefined | null, ctx: any) => {
                    if (
                        ctx?.from[0]?.value?.defaultTipType ===
                        DefaultTipType.Percent
                    ) {
                        return typeof value === "number" && value <= 100;
                    }
                    return true;
                }
            })
            .required()
    )
});

export const tipTypeOptions = [
    { value: DefaultTipType.Flat, label: "Flat rate" },
    { value: DefaultTipType.Percent, label: "Percentage" }
];

export type FormValues = {
    collectTip: boolean;
    showNoTip: boolean;
    defaultTipType: DefaultTipType;
    defaultTipValue: number;
    defaultTips: Array<number>;
};

export type SelectOption = {
    value: any;
    label: string;
};
