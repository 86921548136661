import { CashDrawerWithPrinter } from "@snackpass/snackpass-types";
import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import api from "src/api/rest";
import { useActiveStore } from "#guestbook/redux/campaign";
import { sendError } from "src/utils/errors";

type CashDrawersQuery = [
    {
        label: string;
        storeId: string;
    }
];

const fetchCashDrawers = async ({
    queryKey: [{ storeId }]
}: QueryFunctionContext<CashDrawersQuery>): Promise<CashDrawerWithPrinter[]> =>
    api.cashDrawers.getCashDrawers(storeId).then((e) => e.data.cashDrawers);

export const useCashDrawers = () => {
    const store = useActiveStore();

    const key = {
        storeId: store?._id || "",
        label: "cash-drawer"
    };

    const query = useQuery({
        queryKey: [key],
        queryFn: fetchCashDrawers,
        // No need to be constantly refetching
        staleTime: Infinity,
        gcTime: Infinity
    });

    useEffect(() => {
        if (query.isError) {
            sendError(query.error);
        }
    }, [query.isError, query.error]);

    return query;
};
