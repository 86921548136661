import { Col, Row } from "antd";
import { CSSProperties } from "react";

import { Button } from "#menu-editor/multi-menus/styled-components/button";
import { Text } from "#menu-editor/multi-menus/styled-components/text";

type NoContentMessageProps = {
    title: string;
    subtitle?: string;
    buttonText?: string;
    onClick?: () => void;
};

export const NoContentMessage: React.FC<NoContentMessageProps> = ({
    title,
    subtitle,
    buttonText,
    onClick
}) => (
    <Row justify="center" align="middle" gutter={[8, 16]}>
        <Col span={24}>
            <Row justify="center" align="middle">
                <Text type="body-bold" style={styles.mainText}>
                    {title}
                </Text>
            </Row>
        </Col>
        {subtitle ? (
            <Col span={24}>
                <Row justify="center" align="middle">
                    <Text type="body-regular" style={styles.subText}>
                        {subtitle}
                    </Text>
                </Row>
            </Col>
        ) : null}
        {buttonText ? (
            <Col span={24}>
                <Row justify="center" align="middle">
                    <Button type="primary" onClick={onClick}>
                        {buttonText}
                    </Button>
                </Row>
            </Col>
        ) : null}
    </Row>
);

const styles = {
    mainText: {
        fontSize: "24px",
        lineHeight: "32px"
    } as CSSProperties,

    subText: {
        fontSize: "18px",
        lineHeight: "24px",
        color: "#606C76"
    } as CSSProperties
};
