export const descriptions = {
    summary: {
        header: {
            title: "Welcome to Snackpass!",
            title2: "Let's get your store up and running",
            subtitle: "This should take about 15 minutes to complete."
        },
        location: {
            title: "Store information",
            subtitle:
                "This information includes your store address, hours, and branding"
        },
        business: {
            title: "Legal Entity information",
            subtitle:
                "This information deals with the legal business entity for the store and is used for payouts setup"
        },
        representative: {
            title: "Personal details",
            subtitle: "Confirm contact information for the owner"
        },
        billing: {
            title: "Billing",
            subtitle: "Add a billing method to your account"
        }
    },
    buttons: {
        start: "Start",
        edit: "Edit",
        save: "Save for Later",
        continue: "Save & Continue",
        continueNoChange: "Confirm",
        finish: "Submit for Review",
        changeImage: "Change Image",
        addImage: "Add Image",
        changeVideo: "Change Video",
        addVideo: "Add Video",
        mobile: {
            start: "Start",
            edit: "Edit",
            save: "Save",
            continue: "Save & Continue",
            continueNoChange: "Confirm",
            finish: "Submit for Review",
            changeImage: "Change Image",
            addImage: "Add Image",
            changeVideo: "Change Video",
            addVideo: "Add Video"
        }
    },
    file: {
        error: "Please upload a logo file"
    },
    hours: {
        subtitle: "Time zone",
        error: "Please add at least one hours period"
    },
    address: {
        country: "United States",
        line1: "Address Line 1",
        line2: "Address Line 2 (optional)",
        city: "City",
        state: "State",
        postalCode: "Postal code"
    },
    business: {},
    location: {},
    payouts: {},
    representative: {},
    steps: {
        header: "Activate Store",
        summary: {
            title: undefined,
            subtitle: undefined,
            warn: false
        },
        location: {
            title: "Store information",
            subtitle:
                "This information is about the physical location of the store and how you wish it to appear to customers. You can update this information later.",
            warn: false
        },
        business: {
            title: "Legal Entity information",
            subtitle:
                "This information deals with the legal business entity for the store and is used for payouts setup.",
            warn: true
        },
        representative: {
            title: "Personal details",
            subtitle:
                "Please confirm the contact information for your account.",
            warn: false
        },
        billing: {
            title: "Billing",
            subtitle:
                "Add a billing method to your account by submitting the form below.",
            warn: false
        }
    }
};
