import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { captureException } from "@sentry/react";
import styled from "@emotion/styled";
import useDeepCompareEffect from "use-deep-compare-effect";
import { toast } from "sonner";

import { PromoFormContainer } from "#promotion/components/shared/form-container";
import { FormSectionTitle } from "#promotion/components/shared/form-section-title";
import { PromoDiscountType } from "#promotion/components/promo-discount-type";
import { TitleBreadcrumbs } from "#promotion/components/title-bread-crumbs";
import { FormDropdownSelect } from "#promotion/components/shared/form-dropdown-select";
import {
    FIELD_NAMES,
    FormPromoCodeState,
    FormSubmitState,
    FormUsageTypes,
    GenericPromo,
    PlatformValues
} from "#promotion/utils/types";
import { ReviewModal } from "#promotion/components/review-modal";
import { PromoName } from "#promotion/components/promo-name";
import {
    AUDIENCE_OPTIONS,
    DISCOUNT_TYPES,
    DOLLAR_OPTIONS,
    Forms,
    PERCENT_OPTIONS
} from "#promotion/utils/constants";
import { FormSubmit } from "#promotion/components/shared/form-submit";
import { QualifyingItems } from "#promotion/components/qualifying-items";
import { ScheduleSelector } from "#promotion/components/schedule-selector";
import { FormToggle } from "#promotion/components/shared/form-toggle";
import { AdvancedContainer } from "#promotion/components/shared/form-advanced-container";
import { CartSize } from "#promotion/components/cart-size";
import { LimitTotalUses } from "#promotion/components/limit-total-uses";
import { Duration } from "#promotion/components/duration";
import { isPromoTypeChangeError } from "#promotion/lib";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { BackButton } from "#promotion/components/back-button";
import { GenericDefaultValues } from "#promotion/utils/validation";
import { FormTextInput } from "#promotion/components/shared/form-text-input";
import {
    PromoCodeDefaultValues,
    PromoValidationSchema
} from "#promotion/utils/validation/form-schemas/promo-code";
import { Platforms } from "#promotion/components/platforms";
import { ErrorWithCause } from "src/utils/errors";

import { useSubmitPromo } from "./useSubmitPromo";

type Props = RouteComponentProps<any, any, FormPromoCodeState>;

export const PromoCodePromotionForm = (props: Props) => {
    const { isMobile } = useWindowDimensions();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { state } = props.location;
    const history = useHistory();
    const methods = useForm<GenericPromo>({
        // @ts-expect-error see https://stackoverflow.com/questions/69271892/react-hook-form-resolver-type-error-using-yup
        resolver: yupResolver(PromoValidationSchema),
        mode: "all",
        defaultValues: PromoCodeDefaultValues
    });
    const submitPromo = useSubmitPromo();
    const { handleSubmit, watch, reset } = methods;
    const formDataState = watch();

    let formData: Partial<GenericPromo> = {};
    let formUsage =
        state.formUsage === "Create"
            ? FormUsageTypes.Create
            : FormUsageTypes.Edit; // This form is only used for creating, not editing
    let promoId: string | undefined = state?.promoId || undefined;

    useDeepCompareEffect(() => {
        // If pushed to form with default values, use them!
        // This happens if the user is editing an existing promo
        // or using a shortcut to create a new promo.
        if (state) {
            formData = state.formData;
            formUsage =
                state.formUsage === "Create"
                    ? FormUsageTypes.Create
                    : FormUsageTypes.Edit;
            promoId = state.promoId;
            reset(formData); // This will set new defaultValues
        } else {
            // If no incoming values, then this is a new promo creation, use default values for promo type
            reset(PromoCodeDefaultValues);
        }
    }, [state]);

    const openReviewModal = () => {
        setIsModalOpen(true);
    };

    // Will only be called if the form submission is valid based on the validation schema
    const onSubmitConfirmed = async () => {
        const submitState: FormSubmitState = {
            formUsage,
            success: false
        };
        if (!isSubmitting) {
            setIsSubmitting(true);
            try {
                await submitPromo(formUsage, formDataState, promoId);
                // Set submit state to true so that notification shows up
                submitState.success = true;
                // Reset the form
                reset(GenericDefaultValues);
                // Go back to list of promos on success
                history.push("/promotion", submitState);
            } catch (err: any) {
                if (err instanceof ErrorWithCause) {
                    const cause: string = err.cause.response?.data?.message;
                    // Handle Promo type change attempt/validation error
                    // This occurs when a promotion's derived type during submit is different from its existing type
                    if (cause && isPromoTypeChangeError(cause)) {
                        toast.error("Promo Type Change Detected", {
                            description:
                                "Fields were changed in a way that resulted in a different promo type being saved, which is not currently supported. \n\nPlease contact Support for help with editing this promotion."
                        });
                    } else if (cause) {
                        toast.error(cause, { duration: 10000 });
                    } else {
                        toast.error("An error has occurred.");
                        // Only log to Sentry for non-handled errors
                        captureException(Error(JSON.stringify(err)));
                    }
                } else {
                    toast.error("An error has occurred.");
                    // Only log to Sentry for non-handled errors
                    captureException(Error(JSON.stringify(err)));
                }
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const discountType = watch(FIELD_NAMES.DISCOUNT_TYPE);

    return (
        // Using ts-ignore due to error "Type instantiation is excessively deep and possibly infinite"
        //@ts-ignore
        <FormProvider {...methods}>
            <TitleContainer>
                {isMobile ? <BackButton /> : null}
                <TitleBreadcrumbs
                    title={`Create a Promo Code`}
                    breadcrumbs={[
                        ["Promos", "promotion"],
                        ["New Promo", ""]
                    ]}
                />
            </TitleContainer>
            <form onSubmit={handleSubmit(openReviewModal)}>
                <PromoFormContainer>
                    <FormSectionTitle title="Details" />
                    <PromoName
                        placeholder="Enter a promotion name ⚡️"
                        descriptor="This name will appear in reports and customer receipts"
                    />
                    <PromoDiscountType />
                    {discountType === DISCOUNT_TYPES.PERCENT_OFF && (
                        <FormDropdownSelect
                            name="Discount Amount (%)"
                            descriptor="Most restaurants start with 20% - 50% off"
                            fieldName={FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT}
                            options={PERCENT_OPTIONS}
                            divider
                            allowCustom
                            customClearValue={0}
                            formatOptionLabel={(val) => `${val}%`}
                            required
                        />
                    )}
                    {discountType === DISCOUNT_TYPES.DOLLARS_OFF && (
                        <FormDropdownSelect
                            name="Discount Amount ($)"
                            descriptor="Most restaurants start with $1 - $5 off"
                            fieldName={FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS}
                            options={DOLLAR_OPTIONS}
                            divider
                            allowCustom
                            customClearValue={0}
                            formatOptionLabel={(val) => `$${val}`}
                            required
                        />
                    )}
                    <FormToggle
                        name="Redeem with Promo Code"
                        descriptor="Promotions with codes will not be visible in the discount section. Customers can redeem promo with a code at checkout. Currently only available on the App and Kiosk."
                        fieldName={FIELD_NAMES.PROMO_CODE_ENABLED}
                        autofilled
                    />
                    <FormTextInput
                        inputLabel={"Promo Code"}
                        fieldName={FIELD_NAMES.PROMO_CODE}
                        placeholder={"Name of code"}
                        required
                    />
                    <FormSectionTitle title="Discounted Items" />
                    <QualifyingItems
                        labeled={false}
                        multipleDiscountedItems={false}
                    />
                    {/* Snackface only shows this field for promos of type discount, with discount type percent off */}
                    {discountType === DISCOUNT_TYPES.PERCENT_OFF && (
                        <FormToggle
                            name="Apply Discount to Add-ons"
                            descriptor="Include add-ons when applying discount"
                            fieldName={FIELD_NAMES.DISCOUNT_ADDONS}
                            divider
                        />
                    )}
                    {/** Students and FirstTime use the same advancedFields, so doesn't matter which one goes here */}
                    <AdvancedContainer fields={Forms.STUDENTS.advancedFields}>
                        <SectionContainer>
                            <FormSectionTitle title="Cart Rules" level={2} />
                            <CartSize />
                            <FormToggle
                                name="One Per Cart"
                                descriptor="Customers can only redeem this promo once per order"
                                fieldName={FIELD_NAMES.ONE_PER_CART}
                                divider
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <FormSectionTitle
                                title="Customer Rules"
                                level={2}
                            />
                            <FormDropdownSelect
                                name="Audience (App Only)"
                                descriptor="Choose who can use this promo"
                                fieldName={FIELD_NAMES.AUDIENCE}
                                options={AUDIENCE_OPTIONS}
                                divider
                            />
                            <FormToggle
                                name="Single-use (App Only)"
                                descriptor="Customers can only redeem this promo once"
                                fieldName={FIELD_NAMES.SINGLE_USE}
                                divider
                            />
                            <LimitTotalUses />
                        </SectionContainer>
                        <SectionContainer>
                            <FormSectionTitle title="Duration" level={2} />
                            <ScheduleSelector />
                            <Duration />
                        </SectionContainer>
                        <SectionContainer>
                            <FormSectionTitle title="Availability" level={2} />
                            <Platforms
                                include={[
                                    PlatformValues.OnlyApp,
                                    PlatformValues.AppAndKiosk
                                ]}
                                isPromoCode
                            />
                        </SectionContainer>
                    </AdvancedContainer>
                    <FormSubmit
                        isEdit={formUsage === FormUsageTypes.Edit}
                        submitting={isSubmitting}
                    />
                </PromoFormContainer>
            </form>
            <ReviewModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onClick={onSubmitConfirmed}
                state={formDataState}
                formUsage={formUsage}
            />
        </FormProvider>
    );
};

const SectionContainer = styled.div`
    margin-bottom: 48px;
`;

const TitleContainer = styled.div`
    position: relative;
`;
