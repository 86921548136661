import { isNull, isUndefined } from "lodash";
import { Primitive } from "type-fest";

import { Maybe } from "src/api/graphql/generated/types";

export const genHash = <T extends (Omit<Primitive, "symbol"> | undefined)[]>(
    ...props: T
) => {
    let hash = "";
    for (const prop of props) {
        hash += prop;
    }
    return hash;
};

type ExtractMaybe<T> = T extends Maybe<infer Inner> ? Inner : T;

export const isNotUndefined = <T>(
    item: T | undefined | null
    // @ts-expect-error ts being annoying as usual
): item is ExtractMaybe<T> => !(isUndefined(item) || isNull(item));

export const removeNulls = <T>(arr: (T | null)[]) =>
    arr.filter((x: T | null): x is T => Boolean(x));
