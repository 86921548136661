import { configureStore } from "@reduxjs/toolkit";

import { rootReducer } from "src/redux/reducers";

/**
 * Application Redux store.
 */
export const store = configureStore({
    reducer: rootReducer,
    // Disables immutability and serializability checks for dev performance.
    // Unfortunately, our Redux store in this kiosk is somewhat quite large: about 229kb of formatted JSON for
    // just Hogwarts (~7,800 lines).
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false
        })
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

/**
 * Exported dispatch directly from store. This allows us to not have to use `useDispatch`.
 * @deprecated Use `useDispatch` instead. Accessing `dispatch` directly from the store is an anti-pattern.
 */
export const dispatch: AppDispatch = (action: any) =>
    (store.dispatch as AppDispatch)(action);
