import React, {
    createContext,
    useMemo,
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
    useCallback
} from "react";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";

import { checkValidName } from "#devices/utils/checkDeviceName";
import {
    BaseStoreDevice,
    DeviceType,
    NetworkReportPeriod,
    SnackTvDevice,
    StoreDevice,
    DEVICE_TYPE_ENUM
} from "#devices/utils/deviceTypes";
import { CashDrawerDeviceDetails } from "#devices/utils/deviceTypes/CashDrawerDevice";
import { PrepStationDeviceDetails } from "#devices/utils/deviceTypes/PrepStationDevice";
import {
    ChannelOption,
    SnackTVDeviceDetails
} from "#devices/utils/deviceTypes/SnackTVDevice";
import { FilterModalOption } from "#devices/utils/filterModalOptions";
import { useStoreDevices } from "#devices/hooks/useStoreDevices";
import { BaseStoreDeviceWithNetworkStatus } from "src/api/rest";

type Filters = {
    search: string;
    deviceType: DeviceType[];
    status: FilterModalOption;
};

type Detail = Date | string | boolean | number | string[] | ChannelOption[];

type UpdatedDeviceType = Partial<{
    name: string;
    deviceType: DeviceType;
    deviceDetails: Record<string, Detail>;
}>;

type Addon = {
    details: CashDrawerDeviceDetails | PrepStationDeviceDetails | undefined;
    type: DeviceType.CashDrawer | DeviceType.PrepStation;
    isCreate: boolean;
};

const defaultFilters = {
    search: "",
    deviceType: DEVICE_TYPE_ENUM,
    status: FilterModalOption.All
};

type DevicesPageContextType = {
    storeDevices: BaseStoreDeviceWithNetworkStatus[] | undefined;
    setStoreDevices: Dispatch<
        SetStateAction<BaseStoreDeviceWithNetworkStatus[] | undefined>
    >;
    isLoading: boolean;
    refetch: (
        options?: RefetchOptions | undefined
    ) => Promise<
        QueryObserverResult<BaseStoreDeviceWithNetworkStatus[], Error>
    >;
    addNewDeviceName: string;
    setAddNewDeviceName: Dispatch<SetStateAction<string>>;
    isDetailsDrawerVisible: boolean;
    setIsDetailsDrawerVisible: Dispatch<SetStateAction<boolean>>;
    isEditDrawerVisible: boolean;
    setIsEditDrawerVisible: Dispatch<SetStateAction<boolean>>;
    isAddonDrawerVisible: boolean;
    setIsAddonDrawerVisible: Dispatch<SetStateAction<boolean>>;
    addNewDevicesModalOpen: boolean;
    setAddNewDevicesModalOpen: Dispatch<SetStateAction<boolean>>;
    addNewDevicesModalCurrentStep: number;
    setAddNewDevicesModalCurrentStep: Dispatch<SetStateAction<number>>;
    addNewScreenSerialValue: string;
    setAddNewScreenSerialValue: Dispatch<SetStateAction<string>>;
    addNewScreenPrinterSerial: string;
    setAddNewScreenPrinterSerial: Dispatch<SetStateAction<string>>;
    addNewDeviceTypeValue: string;
    setAddNewDeviceTypeValue: Dispatch<SetStateAction<string>>;
    showFilterModal: boolean;
    setShowFilterModal: Dispatch<SetStateAction<boolean>>;
    currentFilters: Filters;
    setCurrentFilters: Dispatch<SetStateAction<Filters>>;
    resetFilters: () => void;
    deviceToView: BaseStoreDevice | undefined;
    setDeviceToView: Dispatch<SetStateAction<BaseStoreDevice | undefined>>;
    device: StoreDevice | undefined;
    handleSetDevice: (d?: StoreDevice) => void;
    snackTvDevice: SnackTvDevice | undefined;
    setSnackTvDevice: Dispatch<SetStateAction<SnackTvDevice | undefined>>;
    deviceHasChanges: boolean;
    setDeviceHasChanges: Dispatch<SetStateAction<boolean>>;
    savingDevice: boolean;
    setSavingDevice: Dispatch<SetStateAction<boolean>>;
    deletingDevice: boolean;
    setDeletingDevice: Dispatch<SetStateAction<boolean>>;
    rebootingDevice: boolean;
    setRebootingDevice: Dispatch<SetStateAction<boolean>>;
    updatedDevice: Partial<UpdatedDeviceType>;
    setUpdatedDevice: Dispatch<SetStateAction<UpdatedDeviceType>>;
    validName: boolean | undefined;
    setValidName: Dispatch<SetStateAction<boolean | undefined>>;
    validPinned: boolean | undefined;
    setValidPinned: Dispatch<SetStateAction<boolean | undefined>>;
    updateDeviceField: (key: string, value: Detail) => void;
    previousStep: () => void;
    nextStep: () => void;
    openDeleteModal: boolean;
    setOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
    showRebootDeviceModal: boolean;
    setShowRebootDeviceModal: Dispatch<SetStateAction<boolean>>;
    addon: Addon | undefined;
    setAddon: Dispatch<SetStateAction<Addon | undefined>>;
    showRestartAppModal: boolean;
    setShowRestartAppModal: Dispatch<SetStateAction<boolean>>;
    restartingDevice: boolean;
    setRestartingDevice: Dispatch<SetStateAction<boolean>>;
    loadingNetworkReport: boolean;
    setLoadingNetworkReport: Dispatch<SetStateAction<boolean>>;
    networkReport: NetworkReportPeriod[] | undefined;
    setNetworkReport: Dispatch<
        SetStateAction<NetworkReportPeriod[] | undefined>
    >;
};

export const DevicesPageContext = createContext<DevicesPageContextType>(
    {} as DevicesPageContextType
);

export type DeviceProviderProps = {
    children: React.ReactNode;
};

const DevicesProvider = ({ children }: DeviceProviderProps) => {
    const { data, isLoading, refetch } = useStoreDevices();
    const [isDetailsDrawerVisible, setIsDetailsDrawerVisible] =
        useState<boolean>(false);
    const [isEditDrawerVisible, setIsEditDrawerVisible] =
        useState<boolean>(false);
    const [isAddonDrawerVisible, setIsAddonDrawerVisible] =
        useState<boolean>(false);
    const [addNewDevicesModalOpen, setAddNewDevicesModalOpen] =
        useState<boolean>(false);
    const [addNewDevicesModalCurrentStep, setAddNewDevicesModalCurrentStep] =
        useState(1);
    // step 1
    const [addNewDeviceTypeValue, setAddNewDeviceTypeValue] = useState("");
    // step 2
    const [addNewScreenSerialValue, setAddNewScreenSerialValue] = useState("");
    const [addNewScreenPrinterSerial, setAddNewScreenPrinterSerial] =
        useState("");
    // step 3
    const [addNewDeviceName, setAddNewDeviceName] = useState<string>("");
    const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
    const [currentFilters, setCurrentFilters] =
        useState<Filters>(defaultFilters);

    // devices
    const [storeDevices, setStoreDevices] = useState<
        BaseStoreDeviceWithNetworkStatus[] | undefined
    >(undefined);
    const [device, setDevice] = useState<StoreDevice | undefined>(undefined);
    const [loadingNetworkReport, setLoadingNetworkReport] =
        useState<boolean>(false);
    const [networkReport, setNetworkReport] = useState<
        NetworkReportPeriod[] | undefined
    >(undefined);
    const [deviceToView, setDeviceToView] = useState<
        BaseStoreDevice | undefined
    >(undefined);
    const [savingDevice, setSavingDevice] = useState<boolean>(false);
    const [deletingDevice, setDeletingDevice] = useState<boolean>(false);

    // snack TV specifics
    const [snackTvDevice, setSnackTvDevice] = useState<
        SnackTvDevice | undefined
    >(undefined);
    const [deviceHasChanges, setDeviceHasChanges] = useState<boolean>(false);
    const [updatedDevice, setUpdatedDevice] = useState<
        Partial<UpdatedDeviceType>
    >({});

    // Printer addons (i.e. cash drawer, prep station).
    const [addon, setAddon] = useState<Addon | undefined>(undefined);

    // esper devices (for now)
    const [rebootingDevice, setRebootingDevice] = useState<boolean>(false);

    const [restartingDevice, setRestartingDevice] = useState(false);

    useEffect(() => {
        setStoreDevices(data ?? []);
    }, [data]);

    const handleSetDevice = useCallback((d?: StoreDevice) => {
        if (d?.deviceType === DeviceType.SnackTV) {
            const snackTv = { ...d } as SnackTvDevice;
            snackTv.deviceDetails = d.deviceDetails as SnackTVDeviceDetails;
            setSnackTvDevice(snackTv);
            setDevice(snackTv);
        } else {
            setSnackTvDevice(undefined);
            setDevice(d);
        }
    }, []);

    const resetFilters = useCallback(() => {
        setCurrentFilters({ ...defaultFilters });
    }, []);

    const isCurrentDeviceNameValid = device
        ? checkValidName(storeDevices ?? [], device.name, device.id).valid
        : false;

    const [validName, setValidName] = useState<boolean | undefined>(
        isCurrentDeviceNameValid
    );
    const [validPinned, setValidPinned] = useState<boolean | undefined>(true);

    const updateDeviceField = useCallback(
        (key: string, value: Detail) => {
            const name = updatedDevice.name ?? device?.name;
            const validName = checkValidName(
                storeDevices,
                name,
                device?.id
            ).valid;
            if (["name", "deviceType"].includes(key)) {
                // name and deviceType live on BaseStoreDevice level
                setUpdatedDevice({
                    ...updatedDevice,
                    [key]: value
                });
            } else {
                // deviceDetails otherwise are one level down
                setUpdatedDevice({
                    ...updatedDevice,
                    deviceDetails: {
                        ...updatedDevice.deviceDetails,
                        [key]: value
                    }
                });
            }
            setValidName(validName);
            setDeviceHasChanges(true);
        },
        [device?.id, device?.name, storeDevices, updatedDevice]
    );

    const previousStep = useCallback(() => {
        setAddNewDevicesModalCurrentStep(addNewDevicesModalCurrentStep - 1);
    }, [addNewDevicesModalCurrentStep]);

    const nextStep = useCallback(() => {
        setAddNewDevicesModalCurrentStep(addNewDevicesModalCurrentStep + 1);
    }, [addNewDevicesModalCurrentStep]);

    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [showRebootDeviceModal, setShowRebootDeviceModal] = useState(false);
    const [showRestartAppModal, setShowRestartAppModal] = useState(false);

    const value = useMemo(
        () => ({
            storeDevices,
            setStoreDevices,
            isLoading,
            refetch,
            addNewDeviceName,
            setAddNewDeviceName,
            isDetailsDrawerVisible,
            setIsDetailsDrawerVisible,
            isEditDrawerVisible,
            setIsEditDrawerVisible,
            isAddonDrawerVisible,
            setIsAddonDrawerVisible,
            addNewDevicesModalOpen,
            setAddNewDevicesModalOpen,
            addNewDevicesModalCurrentStep,
            setAddNewDevicesModalCurrentStep,
            addNewScreenSerialValue,
            setAddNewScreenSerialValue,
            addNewScreenPrinterSerial,
            setAddNewScreenPrinterSerial,
            addNewDeviceTypeValue,
            setAddNewDeviceTypeValue,
            showFilterModal,
            setShowFilterModal,
            currentFilters,
            setCurrentFilters,
            resetFilters,
            deviceToView,
            setDeviceToView,
            device,
            handleSetDevice,
            snackTvDevice,
            setSnackTvDevice,
            deviceHasChanges,
            setDeviceHasChanges,
            savingDevice,
            setSavingDevice,
            deletingDevice,
            setDeletingDevice,
            rebootingDevice,
            setRebootingDevice,
            updatedDevice,
            setUpdatedDevice,
            validName,
            setValidName,
            validPinned,
            setValidPinned,
            updateDeviceField,
            previousStep,
            nextStep,
            openDeleteModal,
            setOpenDeleteModal,
            showRebootDeviceModal,
            setShowRebootDeviceModal,
            addon,
            setAddon,
            showRestartAppModal,
            setShowRestartAppModal,
            restartingDevice,
            setRestartingDevice,
            loadingNetworkReport,
            setLoadingNetworkReport,
            networkReport,
            setNetworkReport
        }),
        [
            storeDevices,
            setStoreDevices,
            isLoading,
            refetch,
            addNewDeviceName,
            setAddNewDeviceName,
            isDetailsDrawerVisible,
            setIsDetailsDrawerVisible,
            isEditDrawerVisible,
            setIsEditDrawerVisible,
            isAddonDrawerVisible,
            setIsAddonDrawerVisible,
            addNewDevicesModalOpen,
            setAddNewDevicesModalOpen,
            addNewDevicesModalCurrentStep,
            setAddNewDevicesModalCurrentStep,
            addNewScreenSerialValue,
            setAddNewScreenSerialValue,
            addNewScreenPrinterSerial,
            setAddNewScreenPrinterSerial,
            addNewDeviceTypeValue,
            setAddNewDeviceTypeValue,
            showFilterModal,
            setShowFilterModal,
            currentFilters,
            setCurrentFilters,
            resetFilters,
            deviceToView,
            setDeviceToView,
            device,
            handleSetDevice,
            snackTvDevice,
            setSnackTvDevice,
            deviceHasChanges,
            setDeviceHasChanges,
            savingDevice,
            setSavingDevice,
            deletingDevice,
            setDeletingDevice,
            rebootingDevice,
            setRebootingDevice,
            updatedDevice,
            setUpdatedDevice,
            validName,
            setValidName,
            validPinned,
            setValidPinned,
            updateDeviceField,
            previousStep,
            nextStep,
            openDeleteModal,
            setOpenDeleteModal,
            showRebootDeviceModal,
            setShowRebootDeviceModal,
            addon,
            setAddon,
            showRestartAppModal,
            setShowRestartAppModal,
            restartingDevice,
            setRestartingDevice,
            loadingNetworkReport,
            setLoadingNetworkReport,
            networkReport,
            setNetworkReport
        ]
    );
    return (
        <DevicesPageContext.Provider value={value}>
            {children}
        </DevicesPageContext.Provider>
    );
};

export default DevicesProvider;
