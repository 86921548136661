import { IStore } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";
import { AdjustedAddonGroup } from "../adjusted-addon";

class RequiredGroupsHaveFreeAddonsCheck
    implements IMenuAutomationCheck<AdjustedAddonGroup>
{
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "RequiredGroupsHaveFreeAddonsCheck";
    }

    get itemToCheck(): ItemType {
        return "addonGroup";
    }

    checkItems(): MenuIssue<AdjustedAddonGroup>[] {
        return [];
    }

    checkItem(item: AdjustedAddonGroup): MenuIssue<AdjustedAddonGroup> | null {
        if (item.required) {
            // A flag that will be set to true when a free add on is found.
            let zeroPriceFound = false;
            for (let i = 0; i < item.addons.length; i++) {
                const currentAddon = item.addons[i];
                if (currentAddon.price === 0) {
                    zeroPriceFound = true;
                }
            }
            return zeroPriceFound
                ? null
                : new MenuIssue<AdjustedAddonGroup>(
                      "RequiredGroupDoesNotHaveFreeAddons",
                      "Any required modifier group should have at least one free modifier option.",
                      "critical",
                      item
                  );
        }
        // If the add on group isn't required, then there is no issue.
        return null;
    }
}

export { RequiredGroupsHaveFreeAddonsCheck };
