import EditSection from "#devices/components/EditDrawer/EditSection";
import { DeleteSection } from "#devices/components/EditDrawer/DeleteSection";
import { EditDeviceName } from "#devices/components/EditDrawer/Settings";
import { ToggleField } from "#devices/components/EditDrawer/Settings/ToggleField";

export default (): JSX.Element => (
    <>
        <EditSection heading="Info">
            <EditDeviceName />
        </EditSection>

        <EditSection heading="Settings">
            <ToggleField
                id="registerModeToggle"
                label="Register Mode"
                description="Turn kiosk into employee-facing register to take orders manually"
                field="employeeMode"
            />
            <ToggleField
                id="cardPaymentsToggle"
                label="Allow Card Payment"
                description="Customers can pay with a debit/credit card when ordering from this kiosk"
                field="allowCardPayments"
            />
            <ToggleField
                id="cashModeToggle"
                label="Allow Cash Payment"
                description="Customers can pay with cash when ordering from this kiosk"
                field="allowCashPayments"
            />
            <ToggleField
                id="otherPaymentsToggle"
                label="Allow Other Payment"
                description="Customers can pay with 'other' when ordering from this kiosk"
                field="allowOtherPayments"
            />
            <ToggleField
                id="isPinDisabled"
                label="Disable Pin Login"
                description="Disables required pin login to SnackOS apps"
                field="isPinDisabled"
            />
        </EditSection>

        <EditSection heading="Danger Zone">
            <DeleteSection />
        </EditSection>
    </>
);
