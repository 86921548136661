import * as yup from "yup";

import {
    AccountNumberIntegrations,
    CreateIntegration,
    INTEGRATION_FIELD_NAMES,
    IntegrationLinkOrAccountNumber,
    IntegrationApps,
    PERCENT_OPTIONS,
    DOLLAR_OPTIONS,
    MARKUP_TYPES
} from "#settings-integrations/utils/types";

export const GenericCreateIntegrationValues: CreateIntegration = {
    [INTEGRATION_FIELD_NAMES.INTEGRATIONS]: [],
    [INTEGRATION_FIELD_NAMES.INTEGRATION_LINK_OR_ACCOUNT_NUMBERS]: [],
    [INTEGRATION_FIELD_NAMES.NAME]: "",
    [INTEGRATION_FIELD_NAMES.EMAIL]: "",
    [INTEGRATION_FIELD_NAMES.COMMENTS]: "",
    [INTEGRATION_FIELD_NAMES.PAYMENT_AGREEMENT]: false,
    [INTEGRATION_FIELD_NAMES.SHOULD_MARKUP_PRICES]: false,
    [INTEGRATION_FIELD_NAMES.MARKUP_TYPE]: MARKUP_TYPES.PERCENT,
    [INTEGRATION_FIELD_NAMES.MARKUP_PERCENT]: Number(PERCENT_OPTIONS[0].value), // 100%
    [INTEGRATION_FIELD_NAMES.MARKUP_DOLLARS]: Number(DOLLAR_OPTIONS[0].value), // $10
    [INTEGRATION_FIELD_NAMES.MARKUP_COMMENTS]: ""
};

const URLre =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#\-%]+)*(\/[\w_\-%]+\?[\w+_\-%]+=\w+(&[a-zA-Z0-9_\-%]+=[\w%\-_]+)*)?$/gm;

const integrationAppValidator = yup.string().required();
const integrationAppsValidator = yup.array().of(integrationAppValidator).min(1);

const integrationsLinkOrAccountNumber: yup.SchemaOf<IntegrationLinkOrAccountNumber> =
    yup.object().shape({
        integrationApp: yup.string().required(),
        linkOrAccountNumber: yup
            .mixed()
            .when(["integrationApp"], (integrationApp: IntegrationApps) => {
                if (AccountNumberIntegrations.includes(integrationApp)) {
                    return yup
                        .number()
                        .typeError("You must input an account number.")
                        .required("You must input an account number.");
                }
                return yup
                    .string()
                    .required("You must input a link.")
                    .matches(URLre, "You must input a valid link.");
            })
    });
const integrationLinkOrAccountNumbersValidator = yup
    .array()
    .of(integrationsLinkOrAccountNumber);

const nameValidator = yup.string().required("Please enter a name.");

const emailValidator = yup
    .string()
    .email("Please enter a valid email.")
    .required("Please enter an email.");

const commentsValidator = yup.string();

const paymentAgreementValidator = yup.bool().isTrue().required();

const CreateIntegrationsValidation = {
    [INTEGRATION_FIELD_NAMES.INTEGRATIONS]: integrationAppsValidator,
    [INTEGRATION_FIELD_NAMES.INTEGRATION_LINK_OR_ACCOUNT_NUMBERS]:
        integrationLinkOrAccountNumbersValidator,
    [INTEGRATION_FIELD_NAMES.NAME]: nameValidator,
    [INTEGRATION_FIELD_NAMES.EMAIL]: emailValidator,
    [INTEGRATION_FIELD_NAMES.COMMENTS]: commentsValidator,
    [INTEGRATION_FIELD_NAMES.PAYMENT_AGREEMENT]: paymentAgreementValidator
};

export const CreateIntegrationValidationSchema = yup
    .object()
    .shape({ ...CreateIntegrationsValidation });
