import { Color } from "@tremor/react";

import {
    SalesByChannel,
    TableChannelDataRow
} from "#reports/sales-channels/types";

export const channelLabels: {
    key: string;
    label: string;
    color: Color;
    className: string;
}[] = [
    {
        key: "snackpass",
        label: "Snackpass",
        color: "blue",
        className: "bg-blue-500"
    },
    {
        key: "uberEats",
        label: "UberEats",
        color: "green",
        className: "bg-green-500"
    },
    {
        key: "doordash",
        label: "Doordash",
        color: "red",
        className: "bg-red-500"
    },
    {
        key: "grubhub",
        label: "Grubhub",
        color: "orange",
        className: "bg-orange-500"
    },
    {
        key: "uberPostmates",
        label: "Postmates",
        color: "yellow",
        className: "bg-yellow-500"
    },
    {
        key: "easi",
        label: "HungryPanda (Easi)",
        color: "teal",
        className: "bg-teal-500"
    },
    {
        key: "hungrypanda",
        label: "HungryPanda",
        color: "teal",
        className: "bg-teal-500"
    },
    {
        key: "fantuan",
        label: "Fantuan",
        color: "pink",
        className: "bg-pink-500"
    },
    {
        key: "other",
        label: "Other",
        color: "neutral",
        className: "bg-neutral-500"
    }
];

export const tooltipStyles = {
    displayColors: true,
    cornerRadius: 16,
    xPadding: 15,
    yPadding: 12,
    titleFontSize: 22,
    bodyFontSize: 18,
    bodySpacing: 4,
    footerFontStyle: "normal",
    footerFontSize: 14,
    titleFontStyle: "normal"
};

export const emptySalesByChannel: SalesByChannel = {
    total: 0,
    doordash: 0,
    easi: 0,
    fantuan: 0,
    grubhub: 0,
    other: 0,
    snackpass: 0,
    uberEats: 0,
    uberPostmates: 0,
    hungrypanda: 0
};

export const emptyTableChannelDataRow = {
    date: "",
    netSales: emptySalesByChannel,
    orders: emptySalesByChannel,
    tips: emptySalesByChannel,
    taxesYouOwe: emptySalesByChannel
};

export const addSalesByChannel = (
    sales1: SalesByChannel,
    sales2: SalesByChannel
): SalesByChannel => ({
    total: sales1.total + sales2.total,
    deliverect: sales1.deliverect + sales2.deliverect,
    doordash: sales1.doordash + sales2.doordash,
    easi: sales1.easi + sales2.easi,
    fantuan: sales1.fantuan + sales2.fantuan,
    grubhub: sales1.grubhub + sales2.grubhub,
    other: sales1.other + sales2.other,
    snackpass: sales1.snackpass + sales2.snackpass,
    uberEats: sales1.uberEats + sales2.uberEats,
    uberPostmates: sales1.uberPostmates + sales2.uberPostmates,
    hungrypanda: sales1.hungrypanda + sales2.hungrypanda
});

export const addTableChannelDataRows = (
    dataRow1: TableChannelDataRow,
    dataRow2: TableChannelDataRow
): TableChannelDataRow => ({
    date: dataRow1.date,
    orders: addSalesByChannel(dataRow1.orders, dataRow2.orders),
    netSales: addSalesByChannel(dataRow1.netSales, dataRow2.netSales),
    tips: addSalesByChannel(dataRow1.tips, dataRow2.tips),
    taxesYouOwe: addSalesByChannel(dataRow1.taxesYouOwe, dataRow2.taxesYouOwe)
});
