import * as React from "react";
import { DateRange, SelectRangeEventHandler } from "react-day-picker";

import { ReactComponent as CalendarIcon } from "src/assets/icons/calendar.svg";

import { cn } from "../lib/utils";

import { Button } from "./ui/button";
import { Calendar } from "./ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

export function DateRangePicker({
    className,
    selected,
    onSelect,
    disableFutureDates = false,
    open,
    onOpenChange
}: {
    className?: string;
    selected: DateRange;
    onSelect: SelectRangeEventHandler;
    disableFutureDates?: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
}) {
    return (
        <div className={cn("grid gap-2", className)}>
            <Popover onOpenChange={onOpenChange} open={open}>
                <PopoverTrigger asChild>
                    <Button
                        id="date"
                        variant={"default"}
                        className={"justify-start text-left font-normal"}
                    >
                        <CalendarIcon className="h-4 w-4" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    className="w-auto p-0"
                    align="start"
                    avoidCollisions={false}
                    onFocusOutside={(onFocusOutsideEvent) => {
                        onFocusOutsideEvent.preventDefault();
                    }}
                >
                    <Calendar
                        initialFocus
                        defaultMonth={selected.from || new Date()}
                        mode="range"
                        showOutsideDays={false}
                        selected={selected}
                        onSelect={onSelect}
                        numberOfMonths={2}
                        disabled={
                            disableFutureDates
                                ? {
                                      after: new Date()
                                  }
                                : undefined
                        }
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
}
