import React from "react";
import { SystemColors } from "@snackpass/design-system";
import { useDispatch, useSelector } from "react-redux";

import { getMessageInput, setMessageInputMessage } from "../../redux";

import {
    CharacterMetadata,
    DraftEntityType,
    DraftHandleValue,
    Editor,
    EditorCommand,
    EditorState,
    EntityInstance
} from "draft-js";
import { inRange } from "lodash";
import "draft-js/dist/Draft.css";
import styled from "@emotion/styled";
import { compose } from "lodash/fp";

import {
    GuestbookInputType,
    useInitializeEditorState
} from "#guestbook/components/message-input/libs";

import { SelectedGifts } from "./selected-gifts";

type EntityRangeInstance = {
    entity: EntityInstance;
    blockKey: string;
    // start and end are the selection region
    start: number;
    end: number;
};

// values are from the DraftHandleValue type
enum HandleKeyValue {
    Handled = "handled",
    Default = "not-handled"
}

const findEntitiesByType = (
    type: DraftEntityType,
    editorState: EditorState
) => {
    const contentState = editorState.getCurrentContent();
    const entities: EntityRangeInstance[] = [];

    contentState.getBlocksAsArray().forEach((block) => {
        let selectedEntity: Pick<
            EntityRangeInstance,
            "entity" | "blockKey"
        > | null = null;

        // findEntityRanges does not return so using selectedEntity is needed
        block.findEntityRanges(
            (character: CharacterMetadata) => {
                const entityKey = character.getEntity();
                if (
                    entityKey !== null &&
                    contentState.getEntity(entityKey).getType() === type
                ) {
                    selectedEntity = {
                        entity: contentState.getEntity(entityKey),
                        blockKey: block.getKey()
                    };
                    return true;
                }
                return false;
            },
            (start, end) => {
                if (selectedEntity !== null) {
                    entities.push({ ...selectedEntity, start, end });
                }
            }
        );
    });

    return entities;
};

export const Input = () => {
    const dispatch = useDispatch();
    const editorState = useSelector(getMessageInput);
    const setEditorState = compose(dispatch, setMessageInputMessage);

    useInitializeEditorState();

    const handleKeyCommand = (
        command: EditorCommand,
        editorState: EditorState,
        eventTimeStamp: number
    ): DraftHandleValue => {
        const selectionState = editorState.getSelection();

        const entities = findEntitiesByType("STORE", editorState);

        // shouldHandleInput will be true if the input cursor is within the
        // immutable entity or at the end (within the same block) the 'split-block'
        // condition allows a new line to be added after the sign-off
        const shouldHandleCommand = entities.some(
            ({ blockKey, start, end }) => {
                if (
                    selectionState.getFocusKey() === blockKey &&
                    inRange(selectionState.getFocusOffset(), start + 1, end + 1)
                ) {
                    if (command !== "split-block") {
                        return true;
                    }
                }
            }
        );

        // Returning handled will not execute the incoming command
        return shouldHandleCommand
            ? HandleKeyValue.Handled
            : HandleKeyValue.Default;
    };

    const handleBeforeInput = (
        chars: string,
        editorState: EditorState,
        eventTimeStamp: number
    ): DraftHandleValue => {
        const selectionState = editorState.getSelection();

        const entities = findEntitiesByType("STORE", editorState);

        // shouldHandleInput will be true if the input cursor is within the
        // immutable entity or at the end (within the same block)
        const shouldHandleInput = entities.some(({ blockKey, start, end }) => {
            if (
                selectionState.getFocusKey() === blockKey &&
                inRange(selectionState.getFocusOffset(), start + 1, end + 1)
            ) {
                return true;
            }
        });

        // Returning handled will not execute the incoming command
        return shouldHandleInput
            ? HandleKeyValue.Handled
            : HandleKeyValue.Default;
    };

    return (
        <InputContainer>
            <SelectedGifts inputType={GuestbookInputType.MESSSAGE} />
            {editorState && (
                <Editor
                    placeholder="Message"
                    editorState={editorState}
                    onChange={setEditorState}
                    handleKeyCommand={handleKeyCommand}
                    handleBeforeInput={handleBeforeInput}
                />
            )}
        </InputContainer>
    );
};

const InputContainer = styled.div`
    position: relative;
    width: 100%;
    border: 1px solid ${SystemColors.v1.gray90};
    border-radius: 25px;
    overflow: hidden;
`;
