import { ColumnDef } from "@tanstack/react-table";
import { formatNumber } from "libphonenumber-js";
import { z } from "zod";
import { sendPasswordResetEmail } from "firebase/auth";
import { toast } from "sonner";

import {
    AdminColumn,
    snackpassPermissionsSchema
} from "#settings/settings-permissions/types";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "src/@/components/ui/dropdown-menu";
import { ReactComponent as EditIcon } from "src/assets/icons/pencil.svg";
import { ReactComponent as TrashCan } from "src/assets/icons/trash-can.svg";
import { ReactComponent as Mail } from "src/assets/icons/mail-letter.svg";
import { ReactComponent as StoreIcon } from "src/assets/icons/Store.svg";
import { toDollarFormatted } from "#reports/sales-summary/lib";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { firebaseAuth } from "#app/firebase";
import {
    HybridTooltip,
    HybridTooltipContent,
    HybridTooltipTrigger
} from "src/@/components/ui/HybridTooltip";
import { TooltipProvider } from "src/@/components/ui/tooltip";

type OpenAdminPopover = (userId: string) => void;
type OpenAlertType = React.Dispatch<React.SetStateAction<string>>;

const getRoleDescription = (
    snackpassPermissions?: z.infer<typeof snackpassPermissionsSchema>,
    storeId?: string
) => {
    if (!snackpassPermissions || !storeId) return "";

    const role = snackpassPermissions.storeRoles.find(
        (role) => role.storeId === storeId
    );
    return role ? role.role : "";
};

const compareWages = (aWage?: number | null, bWage?: number | null): number => {
    if (aWage && !bWage) return -1;
    if (!aWage && bWage) return 1;
    if (!aWage && !bWage) return 0;
    return aWage! - bWage!;
};

const sendPasswordReset = async (email?: string | null) => {
    if (email) {
        try {
            await sendPasswordResetEmail(firebaseAuth, email);
        } catch (e) {
            toast.error("Password reset email failed.");
            return;
        }
        toast.success("Password reset email sent!");
    }
};

export const getAdminColumns = (
    openAdminPopover: OpenAdminPopover,
    setOpenAlertOnUserId: OpenAlertType,
    canEdit: boolean,
    storeId?: string
): ColumnDef<AdminColumn>[] => [
    {
        id: "actions",
        cell: ({ row }) => (
            <DropdownMenu>
                <DropdownMenuTrigger className="rounded-md bg-transparent shadow-none hover:bg-neutral-300">
                    <div className="flex justify-center">···</div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="bg-white shadow-md">
                    <DropdownMenuItem
                        onClick={() => {
                            openAdminPopover(row.original._id);
                        }}
                    >
                        <div className="flex min-w-full cursor-pointer items-center rounded-sm p-2">
                            <EditIcon
                                role="img"
                                fill="black"
                                className="mr-2"
                            />
                            {canEdit ? "Edit" : "View"}
                        </div>
                    </DropdownMenuItem>
                    {canEdit && (
                        <>
                            <DropdownMenuItem
                                onClick={() =>
                                    setOpenAlertOnUserId(row.original._id)
                                }
                            >
                                <div className="flex min-w-full cursor-pointer items-center rounded-sm p-2">
                                    <TrashCan className="mr-2 fill-black" />
                                    Remove
                                </div>
                            </DropdownMenuItem>
                            {row.original.email && (
                                <DropdownMenuItem
                                    onClick={() => {
                                        void sendPasswordReset(
                                            row.original.email
                                        );
                                    }}
                                >
                                    <div className="flex min-w-full cursor-pointer items-center rounded-sm p-2">
                                        <Mail
                                            fill="black"
                                            className="mr-2 h-3 w-3"
                                        />
                                        Send Password Reset Email
                                    </div>
                                </DropdownMenuItem>
                            )}
                        </>
                    )}
                </DropdownMenuContent>
            </DropdownMenu>
        ),
        enableSorting: false,
        enableHiding: false
    },
    {
        id: "name",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Name" />
        ),
        accessorKey: "name",
        cell: ({ row }) => (
            <div className="whitespace-nowrap text-small font-normal">
                {row.original.name}
                {row.original.permissions?.isOwner?.includes(storeId ?? "") && (
                    <TooltipProvider>
                        <HybridTooltip>
                            <HybridTooltipTrigger className="bg-transparent">
                                <StoreIcon className="ml-3 h-4 w-4" />
                            </HybridTooltipTrigger>
                            <HybridTooltipContent>
                                Account Owner
                            </HybridTooltipContent>
                        </HybridTooltip>
                    </TooltipProvider>
                )}
            </div>
        )
    },
    {
        id: "identifier",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Identifier" />
        ),
        accessorKey: "identifier",
        cell: ({ row }) => (
            <div className="text-small font-normal">
                {row.original.employee?.identifier}
            </div>
        )
    },
    {
        id: "role",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Job Title" />
        ),
        accessorKey: "role",
        cell: ({ row }) => (
            <div className="text-small font-normal">
                {getRoleDescription(row.original.snackpassPermissions, storeId)}
            </div>
        )
    },
    {
        id: "email",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Email" />
        ),
        accessorKey: "email",
        cell: ({ row }) => (
            <div className="text-small font-normal">{row.original.email}</div>
        )
    },
    {
        id: "number",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Phone" />
        ),
        accessorKey: "number",
        cell: ({ row }) => (
            <div className="whitespace-nowrap text-small font-normal">
                {formatNumber(row.original.number ?? "", "US", "NATIONAL")}
            </div>
        )
    },
    {
        id: "pin",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Pin" />
        ),
        sortingFn: (a, b) =>
            parseInt(a.original.employee?.pin ?? "0") -
            parseInt(b.original.employee?.pin ?? "0"),
        accessorKey: "pin",
        cell: ({ row }) => (
            <div className="text-small font-normal">
                {row.original.employee?.pin}
            </div>
        )
    },
    {
        id: "wage",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Wage" />
        ),
        sortingFn: (a, b) =>
            compareWages(a.original.employee?.wage, b.original.employee?.wage),
        accessorKey: "wage",
        cell: ({ row }) => (
            <div className="text-small font-normal">
                {row.original.employee?.wage ? (
                    <>{toDollarFormatted(row.original.employee?.wage)}</>
                ) : (
                    <></>
                )}
            </div>
        )
    }
];
