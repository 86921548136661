import React, { useContext, useState } from "react";
import { Checkbox, Col, Form, Row, Select, Tooltip } from "antd";
import { debounce } from "radash";

import CloseButton from "#payouts/components/shared/CloseButton";
import { Header, StyledModal } from "#payouts/components/shared/Modal";
import api from "src/api/rest";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { Button } from "#payouts/components/shared/Button";
import { VerificationsResponse } from "#payouts/domain/types";
import { appendToBody } from "#payouts/utils/appendToBody";
import { notifyFailure, notifySuccess } from "#payouts/utils/notify";
import { handleException } from "#payouts/utils/handleException";
import { messages } from "#payouts/utils/messages";
import { Label } from "#payouts/components/shared/ModalComponents";
import { descriptions } from "#payouts/utils/descriptions";
import { SegmentEvents, trackSegmentEvent } from "#utils/segment";
import { ReactComponent as Info } from "#payouts/assets/info.svg";
import { useRepresentative } from "#payouts/utils/hooks/useRepresentative";

const statement = "I Accept the terms of the";
const snackpassAgreement = "Snackpass Services Agreement";
const snackpassUrl =
    "https://legal.snackpass.co/snackpass-restaurant-terms-of-service";

const stripeAgreement = "Stripe Account Agreement";
const stripeUrl = "https://stripe.com/legal/connect-account";

const AgreementsString = () => (
    <>
        {statement}{" "}
        <a href={snackpassUrl} target="_blank">
            {snackpassAgreement}
        </a>
        {" and the "}
        <a href={stripeUrl} target="_blank">
            {stripeAgreement}
        </a>
        {"."}
    </>
);

export const SubmitModal = () => {
    const [form] = Form.useForm();

    const { user, storeId, termsModal, setVerification, handleModalChange } =
        useContext(PayoutsSettingsContext);

    const { hasEligiblePrimary, eligiblePrimary } = useRepresentative();

    const [hasChange, setHasChange] = useState(false);
    const [loading, setLoading] = useState(false);

    const [ownersProvided, setOwnersProvided] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [attest, setAttest] = useState(false);
    const [primary, setPrimary] = useState<string | undefined>(
        hasEligiblePrimary ? eligiblePrimary[0].id : undefined
    );

    const resetState = () => {
        setHasChange(false);
        setLoading(false);
        handleModalChange();
        setOwnersProvided(false);
        setAcceptTerms(false);
        setAttest(false);
        setPrimary(undefined);
    };

    const handleSubmit = async (formData: FormData): VerificationsResponse => {
        if (loading || disabled) {
            return Promise.reject();
        }
        setLoading(true);
        trackSegmentEvent(SegmentEvents.PayoutsSettings.CLICKED_SUBMIT, {
            storeId,
            userId: user?._id
        });
        return api.verifications.finish(storeId, formData);
    };

    const debouncedSubmit = debounce({ delay: 300 }, async (u: FormData) =>
        handleSubmit(u)
            .then((response) => {
                const { data } = response;
                if (data?.success) {
                    setVerification(data.verification);
                    resetState();
                    notifySuccess({
                        message: "🎉 Successfully Submitted 🎉",
                        description:
                            "It may take some time for your submission to be reviewed. There is no further action required from you at this time."
                    });
                } else {
                    notifyFailure(messages.modal.submit);
                    handleException(data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                notifyFailure({
                    message: messages.modal.submit.message,
                    description:
                        error.cause?.response?.data?.error ??
                        messages.modal.submit.description
                });
                handleException(error);
                setLoading(false);
            })
    );

    const error = !ownersProvided || !acceptTerms || !attest || !primary;
    const disabled = !hasChange || !!error || !storeId || loading;

    if (!termsModal) return <></>;

    return (
        <StyledModal
            open={termsModal}
            setOpen={() => handleModalChange()}
            header={
                <Header
                    left={<CloseButton onClose={() => resetState()} />}
                    center={"Finish & Submit"}
                />
            }
            footer={
                <>
                    <Button
                        block
                        smallRadius
                        variant="tertiary"
                        size="regular"
                        children={<>Cancel</>}
                        onClick={() => handleModalChange()}
                        disabled={loading}
                    />
                    <Button
                        block
                        smallRadius
                        variant="primary"
                        size="regular"
                        disabled={disabled}
                        loading={loading}
                        children={<>Submit</>}
                        onClick={async () => {
                            const body = new FormData();
                            appendToBody(body, "primaryId", primary);
                            appendToBody(
                                body,
                                "ownersProvided",
                                ownersProvided
                            );
                            void debouncedSubmit(body);
                        }}
                    />
                </>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{ ownersProvided, acceptTerms, attest }}
            >
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name="ownersProvided"
                            label={<Label required label={"Owners Provided"} />}
                        >
                            <Checkbox
                                checked={ownersProvided}
                                children={
                                    <>
                                        All owners of 25% or more have been
                                        added
                                    </>
                                }
                                onChange={(e) => {
                                    const value = e.target.checked;
                                    setHasChange(true);
                                    setOwnersProvided(value);
                                    form.setFieldValue("ownersProvided", value);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name="acceptTerms"
                            label={
                                <Label required label={"Terms Acceptance"} />
                            }
                        >
                            <Checkbox
                                children={<AgreementsString />}
                                onChange={(e) => {
                                    const value = e.target.checked;
                                    setHasChange(true);
                                    setAcceptTerms(value);
                                    form.setFieldValue("acceptTerms", value);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name="attest"
                            label={
                                <Label
                                    required
                                    label="Signature"
                                    subtitle={descriptions.primary}
                                />
                            }
                        >
                            <div className="flex">
                                <div className="my-auto flex">
                                    <Checkbox
                                        checked={attest}
                                        children={
                                            <div>
                                                I attest that I am:{" "}
                                                <Tooltip
                                                    color="black"
                                                    title={`Only representatives with this login email can digitally sign and submit: ${user?.email}`}
                                                >
                                                    <Info />
                                                </Tooltip>
                                            </div>
                                        }
                                        onChange={(e) => {
                                            const value = e.target.checked;
                                            setHasChange(true);
                                            setAttest(value);
                                            form.setFieldValue("attest", value);
                                        }}
                                    />
                                </div>
                                <div className="flex w-full flex-1">
                                    <Select
                                        value={primary}
                                        className="full"
                                        placeholder="Select Person"
                                        onChange={(value) => {
                                            setHasChange(true);
                                            setPrimary(value);
                                        }}
                                    >
                                        {eligiblePrimary.map((r) => (
                                            <Select.Option
                                                key={r.id}
                                                value={r.id}
                                            >
                                                {[r.firstName, r.lastName].join(
                                                    " "
                                                )}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledModal>
    );
};
