import React, { useCallback } from "react";
import { Drawer as AntdDrawer } from "antd";
import { compose } from "lodash/fp";
import { useDispatch } from "react-redux";
import { connectModal, InjectedProps } from "redux-modal";
import { useMediaQuery } from "react-responsive";

import { DiscountTemplate } from "#guestbook/components/incentives/constants";
import { CloseButton } from "#guestbook/components/styled-components";
import useWindowDimensions from "#hooks/use-window-dimensions";

import {
    addConversationInputVariable,
    DiscountData,
    TemplateVariableType,
    updateConversationInputVariableData
} from "../../redux";

import { Incentives } from "./gifts-list";

type DrawerProps = {
    variableId?: string;
} & InjectedProps;

const GiftsDrawerComponent = ({
    handleHide,
    show,
    variableId
}: DrawerProps) => {
    const dispatch = useDispatch();
    const _updateVariable = compose(
        dispatch,
        updateConversationInputVariableData
    );
    const _addVariable = compose(dispatch, addConversationInputVariable);

    const isMobile = useMediaQuery({
        query: `(max-width: 990px)`
    });

    const { width } = useWindowDimensions();

    const _onSelect = useCallback(
        (template: DiscountTemplate) => {
            const data: DiscountData = {
                type: "discount",
                label: template.label,
                template
            };

            if (variableId) {
                _updateVariable({
                    id: variableId,
                    data
                });
            } else {
                _addVariable({
                    data,
                    id: Date.now().toString(),
                    placeholder: template.label,
                    markup: "",
                    label: template.label,
                    isInvisible: true,
                    type: TemplateVariableType.Reward
                });
            }

            handleHide();
        },
        [variableId]
    );

    return (
        <AntdDrawer
            width={isMobile ? width : 450}
            placement="right"
            onClose={handleHide}
            open={show}
            closable={false}
            destroyOnClose={false}
            // so the first animation when the drawer is triggered is smooth
            forceRender={true}
        >
            <CloseButton onClick={handleHide} />
            <Incentives onSelect={_onSelect} />
        </AntdDrawer>
    );
};

export const GiftsDrawer = connectModal({
    name: "GiftsDrawer",
    destroyOnHide: false
})(GiftsDrawerComponent);
