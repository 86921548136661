import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Collapse } from "antd";

import Text from "#payouts/components/shared/Text";
import { PersonStatuses, PersonVerification } from "#payouts/domain/types";
import { Representative } from "#payouts/components/summary/owner/Representative";
import { CollapseHeader } from "#payouts/components/summary/owner/CollapseHeader";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import HeaderInfo from "#table/HeaderInfo";

export const Representatives = ({
    start = false,
    title,
    people = [],
    action,
    tooltip
}: {
    start?: boolean;
    title: React.ReactNode;
    people: PersonVerification[];
    action: JSX.Element;
    tooltip?: string;
}) => {
    const { verification } = useContext(PayoutsSettingsContext);

    const activeList: string[] =
        verification?.representatives?.reduce(
            (acc, r, i) =>
                r.status === PersonStatuses.unverified
                    ? [...acc, i.toString()]
                    : [...acc],
            [] as string[]
        ) ?? [];

    const [active, setActive] = useState<string[]>(activeList);

    const onChange = (key: string | string[]) => {
        const active = typeof key === "string" ? [key] : key;
        setActive(active);
    };

    return (
        <Container>
            <div className="representatives-header">
                <div className="representatives-title">
                    <Text.Title3>{title}</Text.Title3>
                    {tooltip ? (
                        <HeaderInfo multiline linegap message={tooltip} />
                    ) : null}
                </div>
                {action}
            </div>
            {start || !people?.length ? null : (
                <Collapse onChange={onChange} activeKey={active}>
                    {people?.map((r, i) => (
                        <Collapse.Panel
                            key={i}
                            showArrow={false}
                            header={
                                <CollapseHeader
                                    r={r}
                                    isActive={active.includes(i.toString())}
                                />
                            }
                            children={<Representative r={r} key={r.id} />}
                        />
                    ))}
                </Collapse>
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    .representatives-header {
        display: flex;
        flex-direction: row;
        flex: 1;
        width: 100%;
        justify-content: space-between;
        margin-bottom: ${({ theme }) => theme.spacing.base};
    }

    .representatives-title {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: flex-start;
        height: 100%;
        padding-left: 0;
    }
`;
