import React from "react";
import { SystemColors } from "@snackpass/design-system";
import { Spinner } from "react-activity";
import styled from "styled-components";

type SpinProps = {
    small?: boolean;
    large?: boolean;
    color?: string;
};

const sizeMap = {
    small: 18,
    default: 26,
    large: 32
};

export const CenteredSpin = ({ small, large, color }: SpinProps) => (
    <SpinContainer>
        <Spinner
            color={color || SystemColors.v1.candy50}
            size={
                small ? sizeMap.small : large ? sizeMap.large : sizeMap.default
            }
        />
    </SpinContainer>
);

const SpinContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 50%;
    align-items: center;
    justify-content: center;
`;
