import { z } from "zod";

import { GetTablesQuery } from "src/api/graphql/generated/types";

export type CurrentTable = NonNullable<GetTablesQuery["storeTables"]>[number];

export const TableNameSchema = z.object({
    name: z.string().min(1).max(100),
    numSeats: z.coerce.number().min(0).max(100)
});
