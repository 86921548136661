import { toast } from "sonner"; // Ensure correct import path
import { CSSProperties } from "react";
import { ReactComponent as Alert } from "src/assets/icons/alert.svg";

export const enum NotificationPosition {
    TopLeft = "top-left",
    TopRight = "top-right",
    BottomLeft = "bottom-left",
    BottomRight = "bottom-right",
    TopCenter = "top-center",
    BottomCenter = "bottom-center"
}

type NotificationProps = {
    message: string;
    description: string;
    position: NotificationPosition;
};

const CloseButton = ({ onClose }: { onClose: () => void }) => (
    <button style={closeButtonStyle} onClick={onClose}>
        ×
    </button>
);

export const openNotification = ({
    message,
    description,
    position
}: NotificationProps) => {
    const id = `open${Date.now()}`;

    const handleClose = () => {
        toast.dismiss(id);
    };

    toast(
        <div style={contentStyle}>
            <Alert fill="#ff3929" width="35px" style={iconStyle} />
            <div>
                <div style={messageStyle}>{message}</div>
                <div style={descriptionStyle}>{description}</div>
            </div>
            <CloseButton onClose={handleClose} />
        </div>,
        {
            position,
            id,
            duration: 0,
            style: notificationStyle
        }
    );
};

const notificationStyle: CSSProperties = {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    borderRadius: "16px",
    border: "1px solid #ff3929",
    display: "flex",
    alignItems: "center",
    padding: "15px"
};

const contentStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    flex: 1
};

const iconStyle: CSSProperties = {
    marginRight: "13px"
};

const messageStyle: CSSProperties = {
    fontSize: "18px",
    fontWeight: "600"
};

const descriptionStyle: CSSProperties = {
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "20px"
};

const closeButtonStyle: CSSProperties = {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "16px",
    cursor: "pointer",
    fontWeight: "bold"
};
