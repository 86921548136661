import React from "react";

import {
    AlertDialog,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogCancel,
    AlertDialogAction,
    AlertDialogContent
} from "src/@/components/ui/alert-dialog";
import { buttonVariants } from "src/@/components/ui/button";

type ConfirmReplaceAlertProps = {
    deviceName?: string;
    connectedDrawerName?: string;
    open: boolean;
    close: () => void;
    onContinue: () => void;
};

/**
 * A confirm dialog that appears when a user is trying to replace the device a cash drawer is connected to.
 */
export const ConfirmReplaceAlert = ({
    deviceName,
    connectedDrawerName,
    open,
    close,
    onContinue
}: ConfirmReplaceAlertProps) => (
    <AlertDialog open={open} onOpenChange={close}>
        <AlertDialogContent className="z-[9999]" overlayClassName="z-[9998]">
            <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>
                    {connectedDrawerName} is already connected to device:{" "}
                    {deviceName}
                </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
                <AlertDialogCancel onClick={close}>Cancel</AlertDialogCancel>
                <AlertDialogAction
                    onClick={() => {
                        onContinue();
                        close();
                    }}
                    className={buttonVariants({ variant: "destructive" })}
                >
                    Continue
                </AlertDialogAction>
            </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>
);
