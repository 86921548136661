import { SystemColors } from "@snackpass/design-system";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { Spinner } from "react-activity";
import { useSelector } from "react-redux";
import { useConfig } from "statsig-react";
import styled from "styled-components";

import api from "src/api/rest";
import Text from "#payouts/components/shared/Text";
import { StyledRow } from "#settings/components/shared-component";
import { getActiveStoreId } from "src/redux/slices";

const _Button = styled(Button)`
    border-radius: ${({ theme }) => theme.spacing.half};
`;

export const Download = () => {
    const storeId = useSelector(getActiveStoreId);
    const [loading, setLoading] = useState(false);
    const [taxFormId, setTaxFormId] = useState<string | null>(null);
    const [hitError, setHitError] = useState(false);
    const { config: taxFormStatus } = useConfig("2023_1099k_status");
    const status = taxFormStatus.get<
        "not_applicable" | "no_w9" | "ineligible" | "eligible"
    >("status", "not_applicable");

    useEffect(() => {
        if (status !== "eligible" || loading) return;
        setLoading(true);
        setHitError(false);

        api.verifications
            .listTaxForms(storeId)
            .then(({ data }) => {
                if (data.forms.length === 0) {
                    setHitError(true);
                    return;
                }
                setTaxFormId(data.forms[0]);
            })
            .catch(() => setHitError(true))
            .finally(() => setLoading(false));
    }, [status, storeId]);

    if (status !== "eligible") {
        return null;
    }

    return (
        <StyledRow>
            <Text.Title3>2023 1099-K Form</Text.Title3>
            {hitError ? (
                <Text.Small>
                    ⚠️ There was an error retrieving your 1099-K form. Please
                    contact customer support.
                </Text.Small>
            ) : loading ? (
                <Spinner color={SystemColors.v1.candy50} size={16} />
            ) : (
                <_Button
                    type="primary"
                    onClick={() => {
                        api.verifications
                            .downloadTaxForm(storeId, taxFormId!)
                            .then(async (response) => {
                                const filename = `snackpass-2023-1099k-${storeId}.pdf`;
                                if (window.PartnerAppJsApi) {
                                    const reader = new FileReader();
                                    const dataUri = new Promise<string>(
                                        (resolve) => {
                                            reader.onload = () =>
                                                resolve(
                                                    reader.result as string
                                                );
                                        }
                                    );
                                    reader.readAsDataURL(response.data);
                                    window.PartnerAppJsApi.openDataUri(
                                        await dataUri,
                                        filename
                                    );
                                } else {
                                    const href = URL.createObjectURL(
                                        response.data
                                    );
                                    const link = document.createElement("a");
                                    link.href = href;
                                    link.setAttribute("download", filename);
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                    URL.revokeObjectURL(href);
                                }
                            });
                    }}
                >
                    Download
                </_Button>
            )}
        </StyledRow>
    );
};
