// eslint-disable-next-line import/named
import Select, {
    FormatOptionLabelMeta,
    InputActionMeta,
    StylesConfig
} from "react-select";
import CreatableSelect from "react-select/creatable";
import React from "react";
import styled from "styled-components";
import { SystemColors } from "@snackpass/design-system";

const Wrapper = styled.span<Props>`
    margin-left: ${(props) => (props.ml ? `${props.ml}px` : "0")};
    margin-right: ${(props) => (props.mr ? `${props.mr}px` : "0")};
    width: 100%;
`;

export type Options = {
    label: string;
    value: number | string;
};

interface Props {
    placeholder?: string;
    options?: Options[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    style?: any;
    width?: string;
    height?: string;
    fontSize?: string;
    ml?: number;
    mr?: number;
    square?: boolean;
    fontWeight?: string;
    color?: string;
    placeHolderColor?: string;
    className?: string;
    handleInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
    formatCreateLabel?: string;
    /** Custom function to format option labels in control and menu */
    formatOptionLabel?: (
        option: Options,
        labelMeta: FormatOptionLabelMeta<Options, false>
    ) => React.ReactNode;
    isCreatable?: boolean;
    defaultValue?: Options | readonly Options[] | null | undefined;
    isMobile?: boolean;
    isSearchable?: boolean;
    isLoading?: boolean;
    disabled?: boolean;
}

const DropDownSelect = React.forwardRef<HTMLSpanElement, Props>(
    (
        {
            placeholder,
            options,
            value,
            onChange,
            style,
            fontSize,
            height,
            width,
            ml,
            mr,
            square,
            fontWeight,
            color,
            placeHolderColor,
            className,
            handleInputChange,
            formatCreateLabel,
            formatOptionLabel,
            defaultValue,
            isMobile,
            isLoading,
            isSearchable = true,
            isCreatable = false,
            disabled = false
        },
        ref
    ) => {
        const customStyles: StylesConfig<Options, false> = {
            option: (provided, state) => ({
                ...provided,
                fontSize: fontSize ? fontSize : "16px",
                backgroundColor: state.data.color,
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: fontWeight ? fontWeight : "400",
                color: SystemColors.v1.sesame,
                "&:hover": {
                    background: "rgba(0, 0, 0, 0.035)"
                },
                cursor: "pointer"
            }),

            singleValue: (provided) => ({
                ...provided,
                fontSize: fontSize ? fontSize : "16px",
                fontWeight: fontWeight ? fontWeight : "400",
                fontFamily: "Inter",
                color: disabled
                    ? SystemColors.v1.gray60
                    : SystemColors.v1.sesame,
                padding: "5px 0px",
                bottom: "20",
                left: "10"
            }),
            menu: (base) => ({
                ...base,
                width: width || "100%",
                zIndex: 9999,
                fontFamily: "Inter",
                fontWeight: fontWeight ? fontWeight : "400",
                color: color ? color : SystemColors.v1.sesame
            }),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            control: (base: any, state) => ({
                ...base,
                backgroundColor: "white",
                borderRadius: square ? "8px" : "20px",
                height: isMobile ? "34px" : height ? height : "36px",
                width: width || "100%",
                minHeight: "30px",
                paddingLeft: ".2rem",
                fontFamily: "Inter",
                fontWeight: fontWeight ? fontWeight : "300",
                color: color ? color : SystemColors.v1.sesame,
                boxShadow: state.isFocused
                    ? " 0 0 5px 1px rgba(0, 0, 0, 0.1)"
                    : 0,
                border: state.isFocused ? "1px solid rgb(220, 220, 220)" : "",
                "&:hover": {
                    border: state.isFocused ? "solid 1px #efefef" : ""
                },
                cursor: "pointer"
            }),

            container: (base) => ({
                ...base,
                marginRight: 1
            }),
            placeholder: (defaultStyles) => ({
                ...defaultStyles,
                color: placeHolderColor ? placeHolderColor : "#c1c4c8",
                fontSize: fontSize ?? "16px",
                fontFamily: "Inter",
                fontWeight: "400!important",
                lineHeight: "24px",
                paddingBottom: "2px"
            })
        };

        return (
            <Wrapper ml={ml} mr={mr} ref={ref}>
                {isCreatable ? (
                    <CreatableSelect
                        isClearable
                        formatCreateLabel={
                            formatCreateLabel
                                ? (userInput) =>
                                      `${formatCreateLabel} "${userInput}"`
                                : undefined
                        }
                        formatOptionLabel={formatOptionLabel || undefined}
                        placeholder={placeholder}
                        onInputChange={handleInputChange}
                        options={options}
                        styles={style ? style : customStyles}
                        square={square}
                        value={value}
                        onChange={onChange}
                        myFontSize={fontSize}
                        className={className}
                        defaultValue={defaultValue}
                        isDisabled={disabled}
                    />
                ) : (
                    <Select
                        placeholder={placeholder}
                        options={options}
                        styles={style ? style : customStyles}
                        square={square}
                        value={value}
                        onChange={onChange}
                        myFontSize={fontSize}
                        className={className}
                        defaultValue={defaultValue}
                        isLoading={
                            isLoading !== undefined
                                ? isLoading
                                : options && options.length === 0
                        }
                        isSearchable={isSearchable}
                        isDisabled={disabled}
                    />
                )}
            </Wrapper>
        );
    }
);

export default DropDownSelect;
