import { SystemColors } from "@snackpass/design-system";
import React, { useState } from "react";
import { css } from "@emotion/css";

import { Button } from "../styled-components";

const CAMPAIGN_BANNER_KEY = "guestbook/campaign_banner";
const storage = window.sessionStorage;

const openTutorial = () =>
    void window.open(
        "https://www.loom.com/share/a6ac09095b9b4fde9496fbf1959f3414",
        "_blank"
    );

function CampaignInfoBanner() {
    const [banner, setBanner] = useState(storage.getItem(CAMPAIGN_BANNER_KEY));

    const hideBanner = () => {
        storage.setItem(CAMPAIGN_BANNER_KEY, "hidden");
        setBanner("hidden");
    };

    if (banner === "hidden") {
        return null;
    }

    return (
        <div className={styles}>
            <div className="yellow-bar" />

            <div className="message-container ">
                When messaging groups of customers, it gets added to{" "}
                <span
                    style={{
                        color: SystemColors.v1.macaroni40
                    }}
                >
                    Campaigns
                </span>{" "}
                where you can to see engagement metrics like open rate and promo
                usage.
            </div>

            <Button
                className="learn-more-btn"
                label="Learn More"
                onClick={openTutorial}
            />

            <Button className="okay-btn" label="Okay" onClick={hideBanner} />
        </div>
    );
}

const styles = css`
    background-color: ${SystemColors.v1.toastedSesame};
    display: flex;
    flex-direction: row;
    align-items: center;

    .yellow-bar {
        background-color: ${SystemColors.v1.macaroni40};
        width: 25px;
        height: 100%;
    }

    .learn-more-btn {
        background-color: ${SystemColors.v1.milkfoam};
        color: ${SystemColors.v1.toastedSesame};
        width: 200px;
        margin-right: 10px;
    }

    .okay-btn {
        background-color: transparent;
        border: 1px solid ${SystemColors.v1.gray20};
        color: ${SystemColors.v1.milkfoam};
    }

    .message-container {
        color: ${SystemColors.v1.milkfoam};
        padding: 8px 15px;
        font-size: 15px;
    }
`;

export { CampaignInfoBanner };
