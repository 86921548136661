import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Spinner } from "react-activity";
import clsx from "clsx";
import { sort } from "radash";
import { toast } from "sonner";
import { IStore } from "@snackpass/snackpass-types";

import { DataTable } from "src/@/components/ui/data-table";
import { getUser } from "src/redux/selectors";
import { Button } from "src/@/components/ui/button";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { ReactComponent as FilterIcon } from "src/assets/icons/filter-sort.svg";
import { Input } from "src/@/components/ui/input";
import { useAppSelector } from "src/redux/hooks";
import {
    SeniorSupportUser,
    parseData
} from "#settings/settings-senior-support/utils";
import api from "src/api/rest";
import { getSeniorSupportColumns } from "#settings/settings-senior-support/users/users-table-columns";
import { UserEditPopover } from "#settings/settings-senior-support/users/user-edit-popover";

import { UserPermissionAlert } from "./user-permission-alert";

const RoleOptions = [
    { label: "Senior Support", value: "senior-support" },
    { label: "Not Senior Support", value: "not-senior-support" }
];

export const UsersTable = ({
    store,
    canEdit
}: {
    store: IStore;
    canEdit: boolean;
}) => {
    const user = useAppSelector(getUser);

    const [allData, setAllData] = useState<SeniorSupportUser[]>([]);
    const [tableData, setTableData] = useState<SeniorSupportUser[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasFetched, setHasFetched] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredRoles, setFilteredRoles] = useState<Set<string>>(new Set());

    const [employee, setEmployee] = useState<SeniorSupportUser | null>(null);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const handleClose = () => {
        setPopoverOpen(false);
        setEmployee(null);
    };

    const _reset = () => {
        setEmployee(null);
        setPopoverOpen(false);
        setFilteredRoles(new Set());
        setSearchTerm("");
        setIsLoading(false);
    };

    const handleSubmit = (u: SeniorSupportUser) => {
        const newData = sort(
            allData.map((d) => (d.id === u.id ? u : d)),
            (d) => (d.isSeniorSupport ? 0 : 1)
        );
        setAllData(parseData(newData));
        setTableData(parseData(newData));
        _reset();
    };

    const handlePopoverOpen = (userId: string) => {
        const user = allData.find((u) => u.id === userId);
        if (user) {
            setEmployee(user);
            setPopoverOpen(true);
        }
    };

    const columns = getSeniorSupportColumns(
        canEdit,
        handlePopoverOpen,
        user?._id
    );

    const onFilteredRoleSelected = useCallback(
        async (value: string) => {
            const newSet = new Set(filteredRoles);
            if (newSet.has(value)) {
                newSet.delete(value);
            } else {
                newSet.add(value);
            }

            setFilteredRoles(newSet);
            if (newSet.size === 1) {
                const filteredData: SeniorSupportUser[] = [];
                allData.forEach((data) => {
                    if (newSet.has("senior-support") && data.isSeniorSupport) {
                        filteredData.push(data);
                        return;
                    }
                    if (
                        newSet.has("not-senior-support") &&
                        !data.isSeniorSupport
                    ) {
                        filteredData.push(data);
                        return;
                    }
                });
                setTableData(parseData(filteredData));
            } else {
                setTableData(parseData(allData));
            }
        },
        [filteredRoles, setFilteredRoles, allData, setTableData]
    );

    const handleFetch = useCallback(async () => {
        if (!store?._id || isLoading) {
            return;
        }
        setSearchTerm("");
        setFilteredRoles(new Set());
        setIsLoading(true);
        try {
            const response = await api.users.getEmployees();
            setAllData(parseData(response.data.employees));
            setTableData(parseData(response.data.employees));
        } catch (e) {
            toast.error("Unable to retrieve employees");
            setAllData([]);
            setTableData([]);
        } finally {
            setHasFetched(true);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (store?._id) {
            void handleFetch();
        }
    }, [store?._id]);

    useEffect(() => {
        if (searchTerm.length === 0) {
            setTableData(parseData(allData));
            return;
        } else {
            setFilteredRoles(new Set());
            const search = searchTerm.toLowerCase();
            const filteredData: SeniorSupportUser[] = [];
            allData.forEach((data) => {
                if (data.email.toLowerCase().includes(search)) {
                    filteredData.push(data);
                    return;
                }
                if (data.firstName.toLowerCase().includes(search)) {
                    filteredData.push(data);
                    return;
                }
                if (data.lastName.toLowerCase().includes(search)) {
                    filteredData.push(data);
                    return;
                }
            });
            setTableData(parseData(filteredData));
        }
    }, [searchTerm, allData, store?._id]);

    if (isLoading || !hasFetched) {
        return (
            <div className="my-12 flex flex-col justify-center overflow-y-scroll">
                <div className="mt-2 flex w-full flex-col items-center justify-center gap-2">
                    <Spinner speed={0.5} size={18} />
                    This may take a while...
                </div>
            </div>
        );
    }

    return (
        <div className="my-4 flex flex-col justify-center overflow-y-scroll">
            <div className="mx-2 mb-2">
                <UserPermissionAlert show />
            </div>
            <div>
                <div className="mb-2 flex items-center justify-between">
                    <Input
                        className="m-2 max-w-96 pl-1 pt-1"
                        placeholder="Search Name and Email"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setSearchTerm(e.target.value);
                        }}
                        value={searchTerm}
                    />
                    <div className="mr-2 flex space-x-2">
                        <FilterSelect
                            title={"Senior Support"}
                            selectedValues={filteredRoles}
                            options={RoleOptions}
                            customButton={
                                <Button
                                    variant="outline"
                                    size="sm"
                                    className={clsx(
                                        "flex items-center space-x-2",
                                        filteredRoles.size > 0 &&
                                            "border-neutral-800"
                                    )}
                                >
                                    <FilterIcon className="h-4 w-4" />
                                    <span>Role</span>
                                </Button>
                            }
                            onOptionSelected={onFilteredRoleSelected}
                        />
                    </div>
                </div>
                <DataTable
                    className="m-2"
                    showPagination
                    data={tableData}
                    columns={columns}
                    customPageSize={20}
                />
            </div>
            <UserEditPopover
                user={employee}
                canEdit={canEdit}
                isVisible={popoverOpen}
                onClose={handleClose}
                onSubmit={handleSubmit}
            />
        </div>
    );
};
