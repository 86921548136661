import React, { useMemo, useState } from "react";
import { ChainGiftCardPayoutType } from "@snackpass/snackpass-types";
import { useSelector } from "react-redux";
import { getActiveStore } from "@snackpass/accounting";

import FilterHeader, {
    FilterType
} from "#reports/sales-summary/shared-components/FilterHeader";
import { ReportType } from "#reports/sales-summary/types";
import { useTrackEvent } from "#reports/mixpanel-tracking/hooks";
import LiabilitiesTable from "#reports/gift-card-liabilities/components/LiabilitiesTable";
import useGetChainConfig from "#hooks/use-get-chain-config";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { Checkbox } from "src/@/components/ui/checkbox";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";

const GiftCardLiabilitiesReport = () => {
    const trackEvent = useTrackEvent();
    const activeStore = useSelector(getActiveStore);
    const { chainConfig, isLoadingChainConfig } = useGetChainConfig(
        activeStore?.chainId
    );

    const payoutMethod = useMemo(
        () =>
            isLoadingChainConfig
                ? chainConfig?.giftCardPayoutType ||
                  ChainGiftCardPayoutType.Separate
                : null,
        [chainConfig, isLoadingChainConfig]
    );

    const [isUsageStoreCoversDiscount, setIsUsageStoreCoversDiscount] =
        useState(false);

    return (
        <div>
            <FilterHeader
                hideFilters={[
                    FilterType.CHANNEL,
                    FilterType.GRANULARITY,
                    FilterType.FULFILLMENT,
                    FilterType.PLATFORM
                ]}
                reportType={ReportType.GIFT_CARD_PURCHASE_REPORT}
                showAllTimeRange
                showLocationFilter
            />
            <div className="px-6 md:px-24">
                <h4 className="my-5 ml-3 text-large sm:mr-5">
                    Gift Card Liabilities
                </h4>
                <div className="px-2">
                    {!isLoadingChainConfig ? (
                        <Alert>
                            <AlertTitle>
                                {payoutMethod ===
                                ChainGiftCardPayoutType.Separate
                                    ? "For Purchase Location Payout Method Chains"
                                    : "For Single Account Payout Method Chains"}
                            </AlertTitle>
                            <AlertDescription>
                                <div className="flex flex-col gap-4">
                                    {payoutMethod ===
                                    ChainGiftCardPayoutType.Separate ? (
                                        <>
                                            When a gift card is purchased, the
                                            purchase location receives the gift
                                            card funds up front. A location that
                                            redeems gift card value is owed
                                            sales value by the purchase
                                            location.
                                        </>
                                    ) : (
                                        <>
                                            When value is loaded onto a gift
                                            card, the Chain Head Store receives
                                            the gift card funds up front. A
                                            location that redeems gift card
                                            value is owed sales value by the
                                            Chain Head Store.
                                        </>
                                    )}
                                    <div className="flex min-w-72 space-x-2">
                                        <Checkbox
                                            id="usageStoreCoversDiscount"
                                            checked={isUsageStoreCoversDiscount}
                                            onCheckedChange={(value) =>
                                                setIsUsageStoreCoversDiscount(
                                                    Boolean(value)
                                                )
                                            }
                                        />
                                        <div className="grid gap-1.5 leading-none">
                                            <label
                                                htmlFor="usageStoreCoversDiscount"
                                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                            >
                                                Usage Store Covers Discount
                                            </label>
                                            <p className="text-sm text-muted-foreground">
                                                If checked, the liability we
                                                show will be reduced by how much
                                                the gift cards were discounted
                                                by, rather than having the Head
                                                store cover the discounts. All
                                                manual discounts applied to
                                                orders where a gift card is
                                                purchased are considered gift
                                                card discounts.
                                                <ReportsTooltip
                                                    body={
                                                        "If a gift card was purchased for $100 but was loaded with a value of $125 at Store A, then normally when $115 of the gift card is used at Store B, the head store owes store B $115 dollars. However, when this is checked, the head store will owe Store B $115 * $100 / $125 = $92."
                                                    }
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </AlertDescription>
                        </Alert>
                    ) : null}
                </div>
                <LiabilitiesTable
                    isUsageStoreCoversDiscount={isUsageStoreCoversDiscount}
                />
            </div>
        </div>
    );
};

export default GiftCardLiabilitiesReport;
