import React, { useContext } from "react";
import styled from "styled-components";

import Select from "#devices/components/FormControls/Select";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";

type EditScreenOrientationProps = {
    id: string;
    label: string;
    options: { value: string; label: string }[];
    disabled?: boolean;
};

export const EditScreenOrientation = ({
    id,
    label,
    options,
    disabled = false
}: EditScreenOrientationProps) => {
    const { updatedDevice, updateDeviceField } = useContext(DevicesPageContext);

    const value =
        updatedDevice?.deviceDetails?.orientation ??
        // snackTv?.deviceDetails?.orientation ??
        "Unknown";

    return (
        <SelectWrapper>
            <Select
                id={id}
                label={label}
                value={value as string}
                disabled={disabled}
                options={options}
                onChange={(e) => {
                    updateDeviceField("orientation", e.target.value);
                }}
            />
        </SelectWrapper>
    );
};

const SelectWrapper = styled.div`
    margin-bottom: 20px;
`;
