import React, {
    MouseEventHandler,
    useContext,
    useEffect,
    useState
} from "react";
import { styled } from "styled-components";
import { useSelector } from "react-redux";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import { matchPath } from "react-router";

import { ReactComponent as Briefcase } from "src/assets/icons/briefcase.svg";
import { ReactComponent as BarGraph } from "src/assets/icons/BarGraph.svg";
import { ReactComponent as DollarSign } from "src/assets/icons/dollar-sign-in-circle.svg";
import { ReactComponent as Home } from "src/assets/icons/home.svg";
import { ReactComponent as Message } from "src/assets/icons/message.svg";
import { ReactComponent as MenuIcon } from "src/assets/icons/open-book.svg";
import { ReactComponent as Kiosk } from "src/assets/icons/kiosk.svg";
import { ReactComponent as Help } from "src/assets/icons/help.svg";
import { ReactComponent as Settings } from "src/assets/icons/settings-gear.svg";
import { ReactComponent as TwoPeople } from "src/assets/icons/two-people.svg";
import { ReactComponent as PriceTag } from "src/assets/icons/price-tag.svg";
import { ReactComponent as Bell } from "src/assets/icons/bell.svg";
import { ReactComponent as WarningIcon } from "src/assets/icons/warning-alert.svg";
import { ReactComponent as PersonIcon } from "src/assets/icons/person.svg";
import { ReactComponent as LockedLock } from "src/assets/icons/locked-lock.svg";
import { ReactComponent as RocketIcon } from "src/assets/icons/rocket.svg";
import { Routes } from "#navigation/routes";
import {
    useMultiMenusEnabled,
    useGiftCardReportsEnabled,
    useSelfServeTaxRateEnabled,
    useTaxFormsEnabled,
    useInvoicesEnabled,
    usePrepStationsEnabled,
    useTablesideEnabled,
    useCateringEnabled
} from "#navigation/utils";
import { ReportsContext } from "#app/reports-context-provider";
import { getActiveStore, getUserTeamPermission } from "src/redux/selectors";
import api from "src/api/rest";
import { PayoutChannels } from "#payouts/domain/types";
import { getVerificationWarningStatus } from "#payouts/utils/get-verification-warning-status";
import { getActiveStoreNumUnreads } from "src/redux/slices";
import {
    useLoadActiveStoreNumUnreads,
    useDefaultReportsRoute,
    useInvites
} from "#navigation/hooks";
import { PaymentMethodStatus } from "#billing/domain/types";
import { FeatureBadge, FeatureBadgeProps } from "#navigation/FeatureBadge";

export type RouteDefinition = {
    name: string;
    path: Routes | string;
    icon?: React.ReactElement;
    children?: RouteDefinition[];
    condition?: boolean;
    rightComponent?: React.ReactElement;
    onClick?: MouseEventHandler<HTMLElement>;

    /**
     * Keywords for the page. User can search pages using these keywords.
     * Used in Global RDB Search feature -- add or remove keywords as necessary.
     */
    searchKeywords?: string[];

    /**
     * If true, this route will be accessible before the store finishes
     * onboarding.
     */
    availableBeforeOnboardingComplete?: boolean;
};

export const matchPathWithChildren =
    (locationPath: string) =>
    (route: RouteDefinition): boolean =>
        matchPath(locationPath, route.path)?.isExact ||
        route.children?.some(matchPathWithChildren(locationPath)) ||
        false;

const UnreadCounter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px 8px;
    gap: 8px;
    width: auto;
    height: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    background: #0077ff;
    border-radius: 56px;
    color: white;
    margin-left: auto;
`;

export const useNavMap = (): RouteDefinition[] => {
    const isSPEmployee = useSelector(getUserTeamPermission);
    const numUnreads = useSelector(getActiveStoreNumUnreads);
    const { reportsState } = useContext(ReportsContext);
    const hasMultipleAdminStores =
        !!reportsState.stores && reportsState.stores.length > 1;

    const multiMenusEnabled = useMultiMenusEnabled();
    const giftCardReportsEnabled = useGiftCardReportsEnabled();
    const useSelfServeTaxRate = useSelfServeTaxRateEnabled();
    const taxFormsEnabled = useTaxFormsEnabled();
    const invoicesEnabled = useInvoicesEnabled();
    const prepStationsEnabled = usePrepStationsEnabled();
    const tablesideEnabled = useTablesideEnabled();

    const cateringEnabled = useCateringEnabled();

    const store = useSelector(getActiveStore);
    const { pendingInvites } = useInvites(store?._id);
    useLoadActiveStoreNumUnreads();
    const defaultReportsRoute = useDefaultReportsRoute(hasMultipleAdminStores);

    const [payoutsWarning, setPayoutsWarning] = useState<
        null | "yellow" | "red"
    >(null);

    const [billingWarning, setBillingWarning] = useState<
        null | "yellow" | "red"
    >(null);

    useEffect(() => {
        const checkIfRoutable = async (storeId: string) =>
            api.verifications
                .channel(storeId)
                .then(({ data }) => {
                    const isRoutable =
                        data.success && data.channel !== PayoutChannels.stripe;
                    return isRoutable;
                })
                .catch(() => false);

        const checkIfVerified = async (storeId: string) =>
            api.verifications
                .retrieve(storeId)
                .then(({ data }) => {
                    const verificationWarningStatus =
                        getVerificationWarningStatus(data.verification);
                    switch (verificationWarningStatus) {
                        case "none":
                            return null;
                        case "error":
                        case "error_support":
                            return "red";
                        case "warning_future_requirements":
                        case "warning_future_requirements_support":
                        case "warning_future_eventual_requirements":
                        case "warning_future_eventual_requirements_support":
                        case "warning_admin_pause":
                        case "warning_pending":
                        case "warning_current_requirements":
                        case "warning_current_requirements_support":
                        case "warning_eventual_requirements":
                        case "warning_eventual_requirements_support":
                            return "yellow";
                    }
                })
                .catch(() => null);

        const checkAndSetPayoutsWarnState = async () => {
            if (store?._id) {
                const isRoutable = await checkIfRoutable(store._id);
                if (isRoutable) setPayoutsWarning("red");
                else {
                    const warning = await checkIfVerified(store._id);
                    setPayoutsWarning(warning);
                }
            }
        };

        const checkSaasAccount = async (storeId: string) =>
            api.billing
                .getSaasAccount(storeId)
                .then(({ data }) => {
                    if (data.success) {
                        if (
                            [
                                PaymentMethodStatus.none,
                                PaymentMethodStatus.methodAdded
                            ].includes(
                                data.account.onboardingPaymentMethodStatus
                            )
                        ) {
                            return null;
                        } else if (
                            [
                                PaymentMethodStatus.microdepositFailed,
                                PaymentMethodStatus.microdepositPending
                            ].includes(
                                data.account.onboardingPaymentMethodStatus
                            )
                        ) {
                            return "yellow";
                        }
                    }
                    return null;
                })
                .catch(() => null);

        const checkAndSetBillingWarnState = async () => {
            if (store?._id) {
                const warning = await checkSaasAccount(store._id);
                setBillingWarning(warning);
            }
        };

        void checkAndSetPayoutsWarnState();
        void checkAndSetBillingWarnState();
    }, [store?._id]);

    // While this can support infinite nesting, our nav can only distinguish 3 levels of nesting
    return [
        {
            name: "Dashboard",
            path: Routes.Index,
            icon: <Home />,
            searchKeywords: ["net sales", "ticket size", "dashboard"]
        },
        {
            name: "What's New",
            path: Routes.WhatsNew,
            icon: <Bell />,
            searchKeywords: ["updates", "tiktok", "news"]
        },
        {
            name: "Orders",
            path: Routes.BetaOrders,
            icon: <Briefcase />,
            children: [
                {
                    name: "All",
                    path: Routes.BetaOrders,
                    searchKeywords: ["order summary", "order history"]
                },

                {
                    name: "Catering",
                    condition: cateringEnabled,
                    path: Routes.Catering,
                    rightComponent: <RouteFeatureBadge variant="legacy" />,
                    searchKeywords: ["catering", "catering orders"]
                }
            ]
        },

        {
            name: "Menu",
            path: Routes.MenuEditor,
            icon: <MenuIcon />,
            availableBeforeOnboardingComplete: true,
            children: [
                {
                    name: "Items",
                    path: Routes.MenuEditor,
                    searchKeywords: ["new item", "menu edit", "menu editor"],
                    availableBeforeOnboardingComplete: true
                },
                {
                    name: "Third Party",
                    path: Routes.MultiMenus,
                    searchKeywords: [
                        "new menu",
                        "additional menus",
                        "third party",
                        "deliverect"
                    ],
                    rightComponent: <RouteFeatureBadge variant="beta" />,
                    condition: multiMenusEnabled,
                    availableBeforeOnboardingComplete: true
                },
                {
                    name: "Ingredients",
                    path: Routes.MenuInventoryManager,
                    searchKeywords: [
                        "inventory",
                        "inventory manager",
                        "ingredients"
                    ],
                    rightComponent: <RouteFeatureBadge variant="beta" />,
                    availableBeforeOnboardingComplete: true
                }
            ]
        },

        {
            name: "Guestbook",
            path: Routes.GuestbookConversations,
            icon: <Message />,
            rightComponent:
                numUnreads > 0 ? (
                    <UnreadCounter>{numUnreads}</UnreadCounter>
                ) : (
                    <></>
                ),
            children: [
                {
                    name: "Conversations",
                    path: Routes.GuestbookConversations,
                    searchKeywords: [
                        "conversations",
                        "message",
                        "guestbook",
                        "chat"
                    ],
                    rightComponent:
                        numUnreads > 0 ? (
                            <UnreadCounter>{numUnreads}</UnreadCounter>
                        ) : (
                            <></>
                        )
                },
                {
                    name: "Campaigns",
                    path: Routes.GuestbookCampaignsSMS,
                    children: [
                        {
                            name: "SMS",
                            path: Routes.GuestbookCampaignsSMS,
                            searchKeywords: [
                                "campaigns",
                                "sms",
                                "new campaign",
                                "guestbook"
                            ],
                            children: [
                                {
                                    name: "SMS Campaign details",
                                    path: Routes.GuestbookCampaignsSMSDetails,
                                    condition: false
                                }
                            ]
                        },
                        {
                            name: "Push",
                            path: Routes.GuestbookCampaignsPush,
                            searchKeywords: [
                                "push",
                                "push notifications",
                                "send messages",
                                "messages",
                                "guestbook"
                            ],
                            children: [
                                {
                                    name: "Push Campaign details",
                                    path: Routes.GuestbookCampaignsPushDetails,
                                    condition: false
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Customer Loyalty",
                    path: Routes.GuestbookCustomers,
                    searchKeywords: ["customers", "loyalty", "guestbook"]
                },
                {
                    name: "Audit Log",
                    path: Routes.GuestbookAuditLog,
                    searchKeywords: ["customers", "guestbook", "audit log"]
                }
            ]
        },
        {
            name: "Growth Suite",
            path: Routes.SettingsGiftCard,
            icon: <RocketIcon className="h-4 w-4" />,
            children: [
                {
                    name: "Hardware Store",
                    rightComponent: (
                        <ExternalLinkIcon className="my-auto ml-1" />
                    ),
                    path: "#hardware-store",
                    searchKeywords: ["hardware store"],
                    onClick: () => {
                        window.open("https://store.snackpass.co/");
                    }
                },
                {
                    name: "Online Ordering",
                    rightComponent: (
                        <ExternalLinkIcon className="my-auto ml-1" />
                    ),
                    path: "#online-ordering",
                    searchKeywords: ["online ordering"],
                    onClick: () => {
                        window.open(
                            "https://order.snackpass.co/" + store?.slug,
                            "_blank"
                        );
                    }
                },
                {
                    name: "Website",
                    rightComponent: (
                        <div className="flex flex-1 items-center justify-between">
                            <ExternalLinkIcon className="ml-1" />
                            <RouteFeatureBadge variant="beta" />
                        </div>
                    ),
                    path: "#snack-site",
                    searchKeywords: ["brand website"],
                    onClick: () => {
                        window.open(
                            "https://snack.site/" + store?.slug,
                            "_blank"
                        );
                    }
                },

                {
                    name: "Mobile App",
                    rightComponent: (
                        <ExternalLinkIcon className="my-auto ml-1" />
                    ),
                    path: "#branded-app",
                    onClick: () => {
                        window.open(
                            "https://airtable.com/appy8eQbMkVKrsnZ4/paglW8OzypSkSbqep/form",
                            "_blank"
                        );
                    }
                },
                {
                    name: "Gift Cards",
                    path: Routes.SettingsGiftCard,
                    searchKeywords: [
                        "gift card",
                        "digital gift card",
                        "sell gift card",
                        "physical gift card",
                        "order cards",
                        "order scanner",
                        "promotion",
                        "create promotion",
                        "participating locations",
                        "payout method",
                        "payout single",
                        "payout location",
                        "gift card liability"
                    ]
                },
                {
                    name: "QR Codes",
                    path: Routes.SettingsQrCode,
                    searchKeywords: [
                        "qr codes",
                        "qr",
                        "online ordering",
                        "dine-in qr"
                    ]
                },
                {
                    name: "Delivery Integrations",
                    path: Routes.SettingsIntegrations,
                    searchKeywords: ["delivery integration"]
                },
                {
                    name: "Catering",
                    path: Routes.SettingsCatering,
                    condition: cateringEnabled,
                    rightComponent: <RouteFeatureBadge variant="legacy" />,
                    searchKeywords: ["catering", "scheduled catering"]
                },
                {
                    name: "Invoices",
                    path: Routes.Invoices,
                    condition: invoicesEnabled,
                    rightComponent: <RouteFeatureBadge variant="beta" />,
                    searchKeywords: ["invoices", "new invoice"]
                }
            ]
        },

        {
            name: "Promos",
            path: Routes.Promotion,
            searchKeywords: [
                "bogo",
                "promotions",
                "promos",
                "buy one get one",
                "happy hour",
                "promo code",
                "first-time customer",
                "deal drop",
                "student",
                "gift cards",
                "punchcard",
                "referral",
                "group buy",
                "party",
                "current promos",
                "discounts"
            ],
            icon: <PriceTag />,
            availableBeforeOnboardingComplete: true
        },

        {
            name: "Financials",
            path: Routes.FinancialReportsEssentials,
            icon: <DollarSign />,

            children: [
                {
                    name: "Essentials",
                    path: Routes.FinancialReportsEssentials,
                    searchKeywords: [
                        "essentials",
                        "financial",
                        "financial essential"
                    ]
                },
                {
                    name: "Payout History",
                    path: Routes.FinancialReportsPayoutHistory,
                    searchKeywords: [
                        "payout",
                        "payout history",
                        "payout period"
                    ]
                },
                {
                    name: "Cash",
                    path: Routes.FinancialReportsCash,
                    searchKeywords: ["cash"]
                }
            ]
        },

        {
            name: "Reports",
            path: defaultReportsRoute,
            icon: <BarGraph />,
            children: [
                {
                    name: "Sales",
                    path: Routes.ReportsSalesSummary,
                    children: [
                        {
                            name: "Summary",
                            path: Routes.ReportsSalesSummary,
                            searchKeywords: [
                                "sales summary",
                                "sales",
                                "sales report"
                            ]
                        },
                        {
                            name: "Channels",
                            path: Routes.ReportsSalesChannels,
                            searchKeywords: [
                                "sales channel",
                                "channel",
                                "sales by channel",
                                "channel breakdown"
                            ]
                        }
                    ]
                },
                {
                    name: "Menu",
                    path: Routes.ReportsMenuItemInsights,
                    children: [
                        {
                            name: "Items",
                            path: Routes.ReportsMenuItemInsights,
                            searchKeywords: [
                                "menu items",
                                "menu report",
                                "top items",
                                "item performance"
                            ]
                        },
                        {
                            name: "Categories",
                            path: Routes.ReportsMenuCategoryInsights,
                            searchKeywords: [
                                "menu categories",
                                "categories",
                                "category report",
                                "top categories",
                                "top category",
                                "category performance"
                            ]
                        }
                    ]
                },
                {
                    name: "Customers",
                    path: Routes.ReportsCustomerDirectoryInsights
                },
                {
                    name: "Promotions",
                    path: Routes.ReportsPromotions,
                    searchKeywords: ["promotion", "promotion performance"]
                },
                {
                    name: "Gift Card",
                    path: Routes.ReportsGiftCardPurchases,
                    condition: giftCardReportsEnabled,
                    rightComponent: <RouteFeatureBadge variant="new" />,
                    children: [
                        {
                            name: "Overview",
                            path: Routes.ReportsGiftCardPurchases,
                            searchKeywords: [
                                "giftcard performance",
                                "giftcard",
                                "gift card",
                                "giftcard overview",
                                "activations",
                                "redemptions",
                                "outstanding"
                            ]
                        },
                        {
                            name: "Balances",
                            path: Routes.ReportsGiftCardBalances,
                            searchKeywords: [
                                "giftcard balances",
                                "gift card balances",
                                "balances"
                            ]
                        },
                        {
                            name: "Transactions",
                            path: Routes.ReportsGiftCardTransactions,
                            searchKeywords: [
                                "giftcard transactions",
                                "gift card transactions",
                                "transactions"
                            ]
                        },
                        {
                            name: "Liabilities",
                            path: Routes.ReportsGiftCardLiabilities,
                            searchKeywords: [
                                "giftcard liabilities",
                                "gift card liabilities",
                                "liabilities",
                                "liability",
                                "giftcard liability",
                                "gift card liability"
                            ]
                        }
                    ]
                },
                {
                    name: "Labor",
                    path: Routes.EmployeeLaborCost,
                    searchKeywords: ["labor", "labor cost"]
                },
                {
                    name: "Locations",
                    condition: hasMultipleAdminStores,
                    path: Routes.ReportsLocationSales,
                    children: [
                        {
                            name: "Sales",
                            path: Routes.ReportsLocationSales,
                            searchKeywords: [
                                "locations",
                                "location stats",
                                "location sales",
                                "sales by location",
                                "location breakdown",
                                "sales breakdown by location"
                            ]
                        },
                        {
                            name: "Items",
                            path: Routes.ReportsLocationMenuItems,
                            searchKeywords: [
                                "locations item",
                                "location item stats",
                                "location item sales",
                                "items by location"
                            ]
                        },
                        {
                            name: "Categories",
                            path: Routes.ReportsLocationMenuCategories,
                            searchKeywords: [
                                "locations categories",
                                "location categories stats",
                                "location categories sales",
                                "categories by location"
                            ]
                        }
                    ]
                }
            ]
        },
        {
            name: "Team",
            path: Routes.SettingsPermissions,
            icon: <TwoPeople />,
            availableBeforeOnboardingComplete: true,
            children: [
                {
                    name: "Users",
                    path: Routes.SettingsPermissions,
                    availableBeforeOnboardingComplete: true,
                    searchKeywords: [
                        "people",
                        "invite user",
                        "pending invites",
                        "permissions"
                    ]
                },
                {
                    name: "Time Cards",
                    path: Routes.EmployeeTimeCards,
                    availableBeforeOnboardingComplete: false,
                    rightComponent: <RouteFeatureBadge variant="beta" />,
                    searchKeywords: ["time", "time cards", "new time card"]
                }
            ]
        },
        {
            name: "Devices",
            path: Routes.Devices,
            icon: <Kiosk />,
            children: [
                {
                    name: "Devices",
                    path: Routes.Devices,
                    searchKeywords: [
                        "devices",
                        "add device",
                        "add devices",
                        "device id",
                        "device name",
                        "device type",
                        "device install"
                    ]
                },
                {
                    name: "Prep Stations",
                    path: Routes.PrepStations,
                    condition: prepStationsEnabled,
                    searchKeywords: ["prep stations"]
                }
            ]
        },

        {
            name: "Settings",
            path: Routes.SettingsBusinessInfo,
            icon: <Settings />,
            rightComponent: (
                <WarningAlert state={payoutsWarning || billingWarning} />
            ),
            children: [
                {
                    name: "Business Info",
                    path: Routes.SettingsBusinessInfo,
                    searchKeywords: [
                        "business info",
                        "manage information",
                        "display about store",
                        "store display",
                        "store id",
                        "copy store id",
                        "store location",
                        "store name",
                        "store appearance",
                        "customers",
                        "store address",
                        "ordering page",
                        "app page",
                        "store timezone",
                        "store type",
                        "snackpass employees",
                        "contact information",
                        "store phone number",
                        "phone number",
                        "contact restaurant",
                        "contact",
                        "store email address",
                        "account information",
                        "subscriptions",
                        "tax",
                        "schedule ahead orders",
                        "store hours",
                        "time zone",
                        "pacific time",
                        "store schedule",
                        "special hours",
                        "override store hours",
                        "cancel scheduled orders",
                        "third party delivery hours",
                        "holiday hours"
                    ]
                },
                {
                    name: "Branding",
                    path: Routes.SettingsBrandAndAppearance,
                    searchKeywords: [
                        "branding",
                        "customize brand",
                        "display",
                        "store logo",
                        "logo",
                        "change image",
                        "bio",
                        "change bio",
                        "store description",
                        "store emoji",
                        "choose emoji",
                        "change emoji",
                        "brand color",
                        "change colors",
                        "username",
                        "website link",
                        "online ordering link",
                        "online ordering link with id",
                        "brand image",
                        "change cover photo",
                        "loyalty",
                        "change receipt image",
                        "marketing screens",
                        "change marketing screens",
                        "kiosk splash screen",
                        "kiosk screen",
                        "change video",
                        "duo register splash screen",
                        "add media",
                        "tipping",
                        "tip screen image",
                        "corporate settings",
                        "corporate map",
                        "map icon",
                        "change map icon"
                    ]
                },
                {
                    name: "Tipping",
                    path: Routes.SettingsTipping,
                    searchKeywords: [
                        "tipping",
                        "collect tip",
                        "tip type",
                        "tip presets",
                        "default tip",
                        "no tip",
                        "show no tip option"
                    ]
                },
                {
                    name: "All Orders",
                    path: Routes.SettingsAllOrders,
                    searchKeywords: [
                        "all orders",
                        "order receipt",
                        "order cart",
                        "disable custom notes",
                        "automatically include bag",
                        "wait time",
                        "manual time",
                        "dynamic time"
                    ]
                },
                {
                    name: "Online Orders",
                    path: Routes.SettingsOnlineOrders,
                    searchKeywords: [
                        "online orders",
                        "menu store message",
                        "pickup directions",
                        "cart minimum",
                        "asap orders",
                        "hide popular items section",
                        "schedule ahead orders",
                        "schedule ahead",
                        "order hub fire time",
                        "first time slot buffer",
                        "time slot interval",
                        "enable max orders per time slot",
                        "max orders per time slot",
                        "delivery",
                        "delivery cart minimum",
                        "special delivery hours"
                    ]
                },
                {
                    name: "In-Store Orders",
                    path: Routes.SettingsPlatforms,
                    searchKeywords: [
                        "in-store orders",
                        "instore orders",
                        "in store orders",
                        "available fulfillments",
                        "pickup",
                        "dine-in",
                        "pickup instructions",
                        "rewards",
                        "kiosk rewards",
                        "register rewards",
                        "phone number blocklist",
                        "kiosk only",
                        "popular category",
                        "menu face",
                        "register only",
                        "available providers"
                    ]
                },

                {
                    name: "Back of House",
                    path: Routes.SettingsBackOfHouse,
                    searchKeywords: [
                        "back of house",
                        "use employee pin",
                        "use default pin",
                        "view total tips on register",
                        "receipt start number",
                        "auto accept orders",
                        "skip confirmation for payment type: other"
                    ]
                },
                {
                    name: "Table Assignment",
                    condition: tablesideEnabled,
                    path: Routes.SettingsStoreTables,
                    searchKeywords: ["table", "tables"]
                },
                {
                    name: "Time & Attendance",
                    path: Routes.SettingsEmployees,
                    searchKeywords: [
                        "time and attendance",
                        "sms and text notifcations",
                        "send on employee clock out",
                        "send on admin edit shift",
                        "pay period weekly",
                        "pay period bi-weekly",
                        "pay period semi-monthly",
                        "pay period monthly",
                        "period starts on",
                        "daily overtime rules",
                        "hourly pay",
                        "daily double overtime rules",
                        "weekly overtime rules",
                        "default break options"
                    ]
                },
                {
                    name: "Products",
                    path: Routes.SettingsProducts,
                    searchKeywords: [
                        "products",
                        "front of house",
                        "pickup board",
                        "custom branded app",
                        "snackpass app",
                        "menu board",
                        "back of house",
                        "kds",
                        "delivery marketplace integration",
                        "pos",
                        "time and attendance",
                        "marketing",
                        "alcohol payment processing"
                    ]
                },
                {
                    name: "Financials",
                    path: Routes.Payouts,
                    rightComponent: <WarningAlert state={payoutsWarning} />,
                    children: [
                        {
                            name: "Payouts",
                            path: Routes.Payouts,
                            searchKeywords: [
                                "payouts",
                                "payout schedule",
                                "company",
                                "business phone number",
                                "industry",
                                "legal business name",
                                "federal employer identification number",
                                "f-ein",
                                "registered business address",
                                "bank account",
                                "routing number",
                                "account number",
                                "representatives",
                                "login email"
                            ],
                            rightComponent: (
                                <WarningAlert state={payoutsWarning} />
                            )
                        },
                        {
                            name: "Tax Rates",
                            path: Routes.SettingsTax,
                            searchKeywords: [
                                "tax rates",
                                "specify tax rate by fulfillment"
                            ],
                            condition: useSelfServeTaxRate
                        },
                        {
                            name: "Tax Forms",
                            path: Routes.TaxForms,
                            condition: taxFormsEnabled
                        },

                        {
                            name: "Billing",
                            path: Routes.Billing,
                            searchKeywords: ["billing"],
                            rightComponent: (
                                <>
                                    <ExternalLinkIcon className="my-auto ml-1" />
                                    <WarningAlert state={billingWarning} />
                                </>
                            )
                        }
                    ]
                },

                {
                    name: "Resources",
                    path: Routes.SettingsLegal,
                    icon: <Help />,
                    children: [
                        {
                            name: "Help Articles",
                            path: "#resources",
                            searchKeywords: ["support", "help"],
                            onClick: () => {
                                window.open(
                                    "https://intercom.help/snackpass-restaurant-guides",
                                    "_blank"
                                );
                            }
                        },
                        {
                            name: "Order Supplies",
                            path: Routes.ResourcesOrderSupplies,
                            searchKeywords: [
                                "order supplies",
                                "open store",
                                "stickers",
                                "sticky labels",
                                "receipt paper"
                            ]
                        },

                        {
                            name: "Legal",
                            path: Routes.SettingsLegal,
                            searchKeywords: [
                                "legal",
                                "legal documents",
                                "privacy center",
                                "terms of service",
                                "hardware warranty"
                            ]
                        }
                    ]
                }
            ]
        },

        {
            name: "My Account",
            path: Routes.SettingsAccount,
            icon: <PersonIcon />,
            availableBeforeOnboardingComplete: true,
            rightComponent: (
                <WarningAlert
                    state={pendingInvites.length > 0 ? "yellow" : null}
                />
            ),
            children: [
                {
                    name: "Profile",
                    path: Routes.SettingsAccount,
                    searchKeywords: [
                        "my account",
                        "my profile",
                        "full name",
                        "change profile picture",
                        "change contact information",
                        "reset password",
                        "password reset"
                    ],
                    availableBeforeOnboardingComplete: true
                },
                {
                    name: "My Teams",
                    path: Routes.MyAccountTeams,
                    searchKeywords: ["my teams"],
                    rightComponent: (
                        <WarningAlert
                            state={pendingInvites.length > 0 ? "yellow" : null}
                        />
                    ),
                    availableBeforeOnboardingComplete: true
                },
                {
                    name: "Sign Out",
                    path: Routes.Logout,
                    searchKeywords: ["sign out"],
                    availableBeforeOnboardingComplete: true
                }
            ]
        },
        {
            name: "Snackpass Admin",
            path: Routes.InternalSettings,
            condition: isSPEmployee,
            icon: <LockedLock />,
            availableBeforeOnboardingComplete: true,
            children: [
                {
                    name: "General",
                    path: Routes.InternalSettings,
                    searchKeywords: [
                        "internal settings",
                        "integration auto-accepts orders",
                        "pos integrations",
                        "delete twilio registration"
                    ],
                    condition: isSPEmployee,
                    availableBeforeOnboardingComplete: true
                },
                {
                    name: "Fee Settings",
                    path: Routes.InternalFees,
                    condition: isSPEmployee,
                    searchKeywords: [
                        "money fee settings",
                        "commission settings",
                        "credit card fee settings",
                        "service fees",
                        "operating fees",
                        "miscellaneous fees",
                        "minimum charge amount"
                    ],
                    availableBeforeOnboardingComplete: true
                },
                {
                    name: "Senior Support",
                    path: Routes.InternalSeniorSupport,
                    condition: isSPEmployee,
                    searchKeywords: ["senior support", "payout"],
                    availableBeforeOnboardingComplete: true
                }
            ]
        }
    ];
};

const RouteFeatureBadge = (props: Omit<FeatureBadgeProps, "className">) => (
    <FeatureBadge className="ml-auto" {...props} />
);

const WarnBadge = styled.div<{ square?: boolean; background: string }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px;
    gap: 8px;
    width: auto;
    height: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    border-radius: ${({ square }) => (!square ? "5px" : "56px")};
    line-height: 16px;
    background: ${({ background }) => background};
    color: white;
    margin-left: auto;
`;

const warnColor = "#faaa00";
const holdColor = "red";

type WarningProps = {
    state: null | "red" | "yellow";
    text?: string;
};

const WarningAlert = ({ state }: WarningProps) =>
    state === null ? null : (
        <WarnBadge background={state === "yellow" ? warnColor : holdColor}>
            <WarningIcon />
        </WarnBadge>
    );

// Note: This is currently unused as-is, but ideally would fit next to nested subitems
//       to provide some better action text for due items
const WarningText = ({ state, text }: WarningProps) =>
    state === null ? null : (
        <WarnBadge
            square
            background={state === "yellow" ? warnColor : holdColor}
        >
            {text}
        </WarnBadge>
    );
