import { SystemColors } from "@snackpass/design-system";
import { differenceInMinutes } from "date-fns";

import { lastActiveTime } from "#devices/components/DevicesTable/lib";
import {
    BaseStoreDevice,
    DeviceType,
    PrinterDevice,
    StoreDevice,
    NetworkReportStatus
} from "#devices/utils/deviceTypes";

const OFFLINE_THRESHOLD_MINS = 30;

enum Status {
    Unknown = "Unknown",
    Offline = "Offline",
    Inactive = "Inactive",
    Poor = "Poor",
    Degraded = "Degraded",
    Stable = "Stable",
    Online = "Online"
}

type DeviceStatus = {
    /** color for styling an icon */
    color: string;
    /** Formatted last active time */
    lastActive: string;
    /** Human-readable value of device status */
    status: Status;
    /** Full status text with last active time if available, separated with a middot character (&middot;) */
    statusWithLastActiveTime: string;
};

const statusColor = {
    [Status.Unknown]: SystemColors.v1.gray50,
    [Status.Offline]: SystemColors.v1.melon50,
    [Status.Inactive]: SystemColors.v1.ube50,
    [Status.Poor]: SystemColors.v1.melon50,
    [Status.Degraded]: SystemColors.v1.macaroni50,
    [Status.Stable]: SystemColors.v1.parsley50,
    [Status.Online]: SystemColors.v1.parsley50
};

const lastPingAtFor = (d: BaseStoreDevice | StoreDevice) => {
    if (d.deviceType === DeviceType.Printer) {
        if ("deviceDetails" in d) {
            return (d as PrinterDevice).deviceDetails.lastPing;
        }
    }

    return d.lastPingAt;
};

const getIsActive = (lastPingAt: Date | null): boolean =>
    lastPingAt
        ? differenceInMinutes(new Date(), new Date(lastPingAt)) <
          OFFLINE_THRESHOLD_MINS
        : false;

export const getBaseStoreDeviceStatus = (
    device: BaseStoreDevice,
    latestNetworkStatus?: NetworkReportStatus
): {
    color: string;
    status: Status;
} | null => {
    if (device.deviceType === DeviceType.Printer) {
        // Printer status is not known without joining on full device data
        return null;
    }

    const lastPingAt = lastPingAtFor(device);
    const lastActive = lastActiveTime(lastPingAt);

    let status = Status.Unknown;
    if (
        [DeviceType.OrderHub, DeviceType.KDS, DeviceType.Kiosk].includes(
            device.deviceType
        )
    ) {
        if (!latestNetworkStatus || latestNetworkStatus === "unknown") {
            status = getIsActive(lastPingAt) ? Status.Stable : Status.Offline;
        } else if (latestNetworkStatus === "poor") {
            status = Status.Poor;
        } else if (latestNetworkStatus === "degraded") {
            status = Status.Degraded;
        } else {
            status = Status.Stable;
        }
    } else if (device.deviceType === DeviceType.SnackTV) {
        const lastPingAt = lastPingAtFor(device);
        const lastActive = lastActiveTime(lastPingAt);

        if (!getIsActive(lastPingAt)) {
            status = Status.Offline;
        } else {
            status = Status.Stable;
        }
    }

    return { color: statusColor[status], status };
};

const hasLastPing = [DeviceType.Kiosk, DeviceType.OrderHub, DeviceType.SnackTV];

const getDeviceStatusWithoutLatestNetworkStatus = (
    device: StoreDevice
): DeviceStatus => {
    const lastPingAt = lastPingAtFor(device);
    const isActive = getIsActive(lastPingAt);
    const lastActive = lastActiveTime(lastPingAt);

    let status;
    if (!hasLastPing.includes(device.deviceType)) {
        status = isActive ? Status.Online : Status.Offline;
    } else if (!device.stats?.isReachable) {
        status = Status.Offline;
    } else {
        status = isActive ? Status.Online : Status.Stable;
    }
    const color = statusColor[status];

    // build the full string with middot if we have a time
    const statusWithLastActiveTime = `${status}${
        lastActive ? ` · ${lastActive}` : ""
    }`;

    return {
        color,
        lastActive,
        status,
        statusWithLastActiveTime
    };
};

export const getDeviceStatus = (
    device: StoreDevice,
    latestNetworkStatus?: NetworkReportStatus
): DeviceStatus => {
    if (!latestNetworkStatus) {
        return getDeviceStatusWithoutLatestNetworkStatus(device);
    }

    const lastPingAt = lastPingAtFor(device);
    const lastActive = lastActiveTime(lastPingAt);
    const isReachableViaEsper = !!device.stats?.isReachable;

    let status = Status.Unknown;
    if (
        [DeviceType.OrderHub, DeviceType.KDS, DeviceType.Kiosk].includes(
            device.deviceType
        )
    ) {
        if (latestNetworkStatus === "unknown") {
            status = getIsActive(lastPingAt) ? Status.Stable : Status.Offline;
        } else if (!isReachableViaEsper) {
            status = Status.Inactive;
        } else if (latestNetworkStatus === "poor") {
            status = Status.Poor;
        } else if (latestNetworkStatus === "degraded") {
            status = Status.Degraded;
        } else {
            status = Status.Stable;
        }
    } else if (
        [DeviceType.Printer, DeviceType.SnackTV].includes(device.deviceType)
    ) {
        if (!getIsActive(lastPingAt)) {
            status = Status.Offline;
        } else if (!isReachableViaEsper) {
            status = Status.Inactive;
        } else {
            status = Status.Stable;
        }
    }

    const color = statusColor[status];
    const statusWithLastActiveTime = `${status}${
        lastActive ? ` · ${lastActive}` : ""
    }`;

    return {
        color,
        lastActive,
        status,
        statusWithLastActiveTime
    };
};
