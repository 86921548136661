import React, { CSSProperties } from "react";
import { IOrderItem } from "@snackpass/snackpass-types/src/v1";
import get from "lodash/get";
import { toDollar } from "@snackpass/accounting";

import { colors } from "#utils/colors";
import { Text, View } from "#legacy-components";
import { PurchaseItemGrouping } from "src/redux/Types";

import Addons from "./item_addons";

type ItemProps = {
    itemGrouping: PurchaseItemGrouping;
};

const flexRow: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export default function ItemGrouping({ itemGrouping }: ItemProps) {
    const firstItem = itemGrouping.items[0];

    return (
        <View style={{ marginBottom: 5 }}>
            <View
                style={{
                    flexDirection: "row" as const,
                    marginTop: 5,
                    paddingRight: 10
                }}
            >
                <View style={{ flex: 1 }}>
                    {renderQuantity(itemGrouping.quantity)}
                </View>
                {renderProduct(firstItem)}
                {renderTotalPrice(itemGrouping)}
            </View>
            <View style={{ ...flexRow }}>
                <View style={{ flex: 1 }}></View>
                <View style={{ paddingRight: 15, flex: 9 }}>
                    <Addons itemGrouping={itemGrouping} />
                    {renderNotes(firstItem)}
                </View>
                <View style={{ flex: 1 }}></View>
            </View>
        </View>
    );
}

const renderNotes = (firstItem: IOrderItem) => {
    if (!firstItem.notes) {
        return null;
    }

    return (
        <Text small color={colors.gray}>
            Notes: {firstItem.notes}
        </Text>
    );
};

const renderQuantity = (quantity: number) => (
    <View
        style={{
            ...flexRow,
            width: 20,
            height: 20,
            borderWidth: 1,
            borderColor: colors.lightGray
        }}
    >
        <Text>{quantity}x</Text>
    </View>
);

const renderProduct = (firstItem: IOrderItem) => {
    const name = `${get(firstItem, "product.name")}
        ${
            firstItem.weight
                ? ` (${get(firstItem, "weight.amount")}${get(
                      firstItem,
                      "weight.unit"
                  )})`
                : ""
        }
        `;

    return (
        <View style={{ flexDirection: "column", flex: 8, paddingRight: 15 }}>
            <Text small>{name}</Text>
            {firstItem.usingPromotion ? (
                <Text small color={colors.green}>
                    {get(firstItem, "promotion.name")}
                </Text>
            ) : firstItem.usingReward ? (
                <Text small color={colors.green}>
                    {get(firstItem, "reward.name")}
                </Text>
            ) : null}
        </View>
    );
};

const renderTotalPrice = (itemGrouping: PurchaseItemGrouping) => {
    const firstItem = itemGrouping.items[0];
    const isPriceDiscounted =
        firstItem.totalPriceAfterDiscount !== firstItem.totalPrice;

    return (
        <View
            style={{
                flex: 2,
                flexDirection: "column",
                alignItems: "flex-end"
            }}
        >
            {isPriceDiscounted && (
                <div className={"text-sm line-through"}>
                    {toDollar(firstItem.totalPrice)}
                </div>
            )}
            <div>{toDollar(firstItem.totalPriceAfterDiscount)}</div>
        </View>
    );
};
