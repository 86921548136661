export const descriptions = {
    name: "Company Name must exactly match the one listed on your Federal IRS document (e.g., W-9, Letter 147C or SS-4 confirmation letter), including capitalization and punctuation.",
    nameProvided:
        "Once verified the company name cannot be changed. Please contact Snackpass Support if you wish to change the business entity associated with this store.",
    nameClarifier:
        '  Company names typically end in "LLC", "INC", "CORP", or similar. Please make sure you are using your official entity name from your IRS SS-4 or similar document.',
    dba: "The operating name of the company, if it's different than the legal name.",
    taxId: "Federal EIN is nine (9) digits and must match the value on your IRS document (e.g., W-9, Letter 147C or SS-4 confirmation letter).",
    socialOption:
        "If you use your Social Security number for business tax purposes, you can enter that instead.",
    taxIdProvided:
        "Once the Federal EIN has been provided you cannot change it. Please contact Snackpass Support if you wish to change the business entity associated with this store.",
    address:
        "Please provide an address that matches the submitted tax document (P.O. Boxes are not permitted).",
    phone: "",
    url: "",
    bankName: "",
    bankNameProvided: "",
    routingNumber:
        "Use 'Paper & Electronic' if multiple routing numbers are available.",
    accountNumber: "Your bank account must be a checking account.",
    primary: "",
    documents: "",
    bankAccountDocument:
        "Upload an image of a voided check for the provided account. Max image size: 4MB.",
    taxIdDocument:
        "Please upload your company IRS Document (e.g., Letter 147C or SS-4 confirmation letter). File size limit is 4.0 MB per image.",
    missing: "Missing Required Information"
};

export const personDescriptions = {
    name: "Individual's name exactly as recorded with the IRS.",
    email: "Email address where the individual can be reached for verification requirements in the future.",
    address: "Individual's home address. P.O. Boxes are not permitted.",
    percentOwnership: "",
    representative:
        "Select any of the conditions that may apply to this individual.",
    owner: "This person is an owner of the company.",
    director: "This person is a member of the governing board of the company.",
    representativeClarifier:
        "  At least one person must be marked as owner or executive.",
    executive:
        "This person is an executive or senior manager with significant management responsibility.",
    dateOfBirth: "",
    phone: "",
    idNumber: "Please provide your full social security number.",
    idNumberProvided:
        "Once your social security number has been provided you cannot change it.",
    documents:
        "Please provide the front and back of an active identification card. File size limit is 4.0 MB per image.",
    documentsProvided:
        "Once your verification documents have been provided they cannot be changed."
};
