import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { DatePicker, TimePicker } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { ScreenState } from "@snackpass/snackpass-types";
import antMoment from "moment";
import moment from "moment-timezone";
import { SystemColors } from "@snackpass/design-system";

import { ReactComponent as CalendarIcon } from "src/assets/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "src/assets/icons/clock.svg";
import { ReactComponent as TrashIcon } from "src/assets/icons/trash-can.svg";
import { FormToggle } from "#promotion/components/shared/form-toggle";
import { FIELD_NAMES } from "#promotion/utils/types";
import { useActiveStore } from "#guestbook/redux/campaign";
import colors from "#reusable/colors/colors.json";

type Props = {
    required?: boolean;
    recommended?: boolean;
    autofilled?: boolean;
};

export const Duration = ({ required, recommended, autofilled }: Props) => {
    const store = useActiveStore();
    const { watch, control, setValue, clearErrors, trigger } = useFormContext();
    const durationEnabled = watch(FIELD_NAMES.DURATION_ENABLED);
    const startDate = watch(FIELD_NAMES.DURATION_START_DATE);
    const endDate = watch(FIELD_NAMES.DURATION_END_DATE);
    // Used to display what date/time it will be for the store, not for current user
    const storeOffset = store
        ? moment().tz(store?.hours.zone).utcOffset()
        : -300; // Defaults to Eastern

    useEffect(() => {
        if (!durationEnabled) {
            setValue(FIELD_NAMES.DURATION_START_DATE, "");
            setValue(FIELD_NAMES.DURATION_END_DATE, "");
            clearErrors([
                FIELD_NAMES.DURATION_ENABLED,
                FIELD_NAMES.DURATION_START_DATE,
                FIELD_NAMES.DURATION_END_DATE
            ]);
        }
        trigger(FIELD_NAMES.DURATION_ENABLED);
    }, [durationEnabled]);

    useEffect(() => {
        trigger([
            FIELD_NAMES.DURATION_ENABLED,
            FIELD_NAMES.DURATION_START_DATE,
            FIELD_NAMES.DURATION_END_DATE
        ]);
    }, [startDate, endDate]);

    return (
        <>
            <FormToggle
                name="Duration"
                descriptor="Run this promo for a limited time between specific dates (ie. Holiday Sale)"
                fieldName={FIELD_NAMES.DURATION_ENABLED}
                divider={!durationEnabled}
                required={required}
                recommended={recommended}
                autofilled={autofilled}
            />
            {durationEnabled ? (
                <Container>
                    <Controller
                        name={FIELD_NAMES.DURATION_START_DATE}
                        control={control}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <ErrorAndContentContainer>
                                <StartEndText>Start</StartEndText>
                                <ContentContainer>
                                    <PickerContainer>
                                        <CalendarIcon width={16} height={16} />
                                        <DatePicker
                                            style={styles.datePicker}
                                            onChange={(value) =>
                                                onChange(value?.seconds(0))
                                            }
                                            bordered={false}
                                            format={"ddd, MMM D, YYYY"}
                                            suffixIcon={null}
                                            placeholder={"Start Date"}
                                            allowClear={false}
                                            value={
                                                value &&
                                                antMoment(value).utcOffset(
                                                    storeOffset
                                                )
                                            }
                                            status={error ? "error" : ""}
                                        />
                                    </PickerContainer>
                                    <PickerContainer>
                                        <ClockIcon width={16} height={16} />
                                        <TimePicker
                                            style={styles.timePicker}
                                            onSelect={(value) =>
                                                onChange(value?.seconds(0))
                                            }
                                            bordered={false}
                                            format={"h:mm A"}
                                            suffixIcon={null}
                                            placeholder={"Start Time"}
                                            allowClear={false}
                                            value={
                                                value &&
                                                antMoment(value).utcOffset(
                                                    storeOffset
                                                )
                                            }
                                            status={error ? "error" : ""}
                                        />
                                    </PickerContainer>
                                    {value ? (
                                        <ClearDateButton
                                            onClick={() => onChange("")}
                                        >
                                            <TrashIcon
                                                width={20}
                                                height={20}
                                                fill={SystemColors.v1.melon50}
                                            />
                                        </ClearDateButton>
                                    ) : null}
                                </ContentContainer>
                                {error ? (
                                    <ErrorMessage>
                                        {`* ${error.message}`}
                                    </ErrorMessage>
                                ) : null}
                            </ErrorAndContentContainer>
                        )}
                    />
                    <Controller
                        name={FIELD_NAMES.DURATION_END_DATE}
                        control={control}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <ErrorAndContentContainer>
                                <StartEndText>End</StartEndText>
                                <ContentContainer>
                                    <PickerContainer>
                                        <CalendarIcon width={16} height={16} />
                                        <DatePicker
                                            style={styles.datePicker}
                                            onChange={(value) =>
                                                onChange(
                                                    value?.seconds(0)?.toDate()
                                                )
                                            }
                                            bordered={false}
                                            format={"ddd, MMM D, YYYY"}
                                            suffixIcon={null}
                                            placeholder={"End Date (Optional)"}
                                            allowClear={false}
                                            value={
                                                value &&
                                                antMoment(value).utcOffset(
                                                    storeOffset
                                                )
                                            }
                                            status={error ? "error" : ""}
                                        />
                                    </PickerContainer>
                                    <PickerContainer>
                                        <ClockIcon width={16} height={16} />
                                        <TimePicker
                                            style={styles.timePicker}
                                            onSelect={(value) =>
                                                onChange(
                                                    value?.seconds(0)?.toDate()
                                                )
                                            }
                                            bordered={false}
                                            format={"h:mm A"}
                                            suffixIcon={null}
                                            placeholder={"End Time"}
                                            allowClear={false}
                                            value={
                                                value &&
                                                antMoment(value).utcOffset(
                                                    storeOffset
                                                )
                                            }
                                            status={error ? "error" : ""}
                                        />
                                    </PickerContainer>
                                    {value ? (
                                        <ClearDateButton
                                            onClick={() => onChange("")}
                                        >
                                            <TrashIcon
                                                width={20}
                                                height={20}
                                                fill={SystemColors.v1.melon50}
                                            />
                                        </ClearDateButton>
                                    ) : null}
                                </ContentContainer>
                                {error ? (
                                    <ErrorMessage>
                                        {`* ${error.message}`}
                                    </ErrorMessage>
                                ) : null}
                            </ErrorAndContentContainer>
                        )}
                    />
                </Container>
            ) : null}
        </>
    );
};

const styles = {
    datePicker: { minWidth: 160, width: "100%", height: 48 },
    timePicker: { minWidth: 90, width: "100%", height: 48 }
};

const Container = styled.div`
    display: flex;
    flex-flow: column wrap;
    gap: 8px;
    padding-bottom: 24px;
    border-bottom: 1px solid ${colors["neutral-400"]};
    margin-bottom: 24px;
    @media ${ScreenState.MOBILE} {
        align-items: flex-start;
    }
`;

const ErrorAndContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media ${ScreenState.MOBILE} {
        width: 100%;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    gap: 8px;
    flex: 1;
    align-items: center;
    @media ${ScreenState.MOBILE} {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }
`;

const PickerContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 12px;
    border: 1px solid ${colors["neutral-400"]};
    border-radius: 8px;
    height: 48px;
    @media ${ScreenState.MOBILE} {
        width: 100%;
    }
`;

const ClearDateButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    border-radius: 8px;
    height: 48px;
    width: 48px;
    background-color: transparent;
    border: 1px solid ${colors["neutral-400"]};
`;

const StartEndText = styled.p`
    margin: 0;
`;

const ErrorMessage = styled.p`
    margin: 0;
    color: ${SystemColors.v1.melon50};
    font-size: 14px;
`;
