import { useFormContext } from "react-hook-form";

import {
    FormControl,
    FormField,
    FormItem,
    FormMessage
} from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";
import { Select, SelectTrigger, SelectValue } from "src/@/components/ui/select";

function PhysicalAddressInput() {
    const { control } = useFormContext();
    return (
        <div className="flex flex-col gap-3">
            <Select disabled>
                <SelectTrigger>
                    <SelectValue
                        placeholder="United States"
                        defaultValue={"US"}
                    />
                </SelectTrigger>
            </Select>
            <FormField
                control={control}
                name="address.line1"
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <Input {...field} placeholder="Address line 1" />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <FormField
                control={control}
                name="address.line2"
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <Input
                                {...field}
                                placeholder="Address line 2 (optional)"
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <div className="flex flex-row gap-3">
                <div className="w-1/3">
                    <FormField
                        control={control}
                        name="address.city"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input {...field} placeholder="City" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="w-1/3">
                    <FormField
                        control={control}
                        name="address.state"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input {...field} placeholder="State" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="w-1/3">
                    <FormField
                        control={control}
                        name="address.zip"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        {...field}
                                        placeholder="Postal Code"
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>
        </div>
    );
}

export default PhysicalAddressInput;
