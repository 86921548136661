import { Addon, IProduct } from "@snackpass/snackpass-types";
import { Space, Spin } from "antd";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { isNotUndefined } from "#core";
import constants from "#core/constants";
import { isProduct } from "#menu-editor/multi-menus/helpers";
import { commonStyles } from "#menu-editor/multi-menus/helpers/styles";
import { getMultiMenuLoadingState } from "#menu-editor/multi-menus/redux/selectors";
import { multiMenuThunks } from "#menu-editor/multi-menus/redux/thunks";
import { ItemTypes } from "#menu-editor/multi-menus/redux/types";
import { PriceOverrideMobileInput } from "#menu-editor/multi-menus/screens/edit-overrides-mobile/components/price-override-mobile-input";
import { TaxesOverrideMobileInput } from "#menu-editor/multi-menus/screens/edit-overrides-mobile/components/tax-override-mobile-input";
import { ScreenFooter } from "#menu-editor/multi-menus/shared-components/footer";
import { MobileHeader } from "#menu-editor/multi-menus/shared-components/mobile-header";
import {
    NotificationPosition,
    openNotification
} from "#menu-editor/multi-menus/shared-components/notification";
import { ScreenLayout } from "#menu-editor/multi-menus/styled-components/layout";
import { Text } from "#menu-editor/multi-menus/styled-components/text";
import { useAppDispatch } from "src/redux/hooks";
import { getLegacyProducts } from "src/redux/selectors";
import { logAndSendError } from "src/utils/errors";

type ItemState<T extends ItemTypes, V> = {
    type: T;
    value: V;
};

export const EditOverridesMobile = () => {
    const dispatch = useAppDispatch();
    const {
        params: { id: menuId, productId, addonId }
    } = useRouteMatch<{
        id: string;
        itemId: string;
        productId: string;
        addonId?: string;
    }>();

    const [item, setItem] = useState<
        ItemState<"addons", Addon> | ItemState<"products", IProduct> | null
    >(null);

    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`
    });

    const loadingStatusFetchStoreMenus = useSelector(
        getMultiMenuLoadingState("FetchStoreMenus")
    );
    const loadingStatusUpdateOverrides = useSelector(
        getMultiMenuLoadingState("UpdateOverrides")
    );

    const history = useHistory();
    const navigateToMenuOutline = useCallback(
        () =>
            // query params are used to restore table state
            history.push(
                `/multi-menus/${menuId}?productId=${productId}${
                    addonId ? `&addonId=${addonId}` : ""
                }`
            ),
        [menuId, productId, addonId]
    );

    const products = useSelector(getLegacyProducts);

    useEffect(() => {
        if (loadingStatusFetchStoreMenus === "succeeded")
            void dispatch(
                multiMenuThunks.selectMultiMenu({
                    menuId,
                    onMenuNotFound: navigateToMenuOutline
                })
            );
    }, [menuId, navigateToMenuOutline]);

    useEffect(() => {
        if (loadingStatusFetchStoreMenus === "succeeded") {
            const itemType = isNotUndefined(addonId) ? "addons" : "products";
            const activeProduct = products.find((p) => p._id === productId);
            if (!activeProduct) {
                return navigateToMenuOutline();
            }
            const activeAddon = isNotUndefined(addonId)
                ? activeProduct.addonGroups
                      .flatMap(({ addons }) => addons)
                      .find(({ _id }) => _id === addonId)
                : undefined;

            if (itemType === "addons") {
                if (!activeAddon) {
                    return navigateToMenuOutline();
                }
                setItem({ type: itemType, value: activeAddon });
            } else {
                setItem({ type: itemType, value: activeProduct });
            }
        }
    }, [
        loadingStatusFetchStoreMenus,
        addonId,
        productId,
        products,
        navigateToMenuOutline
    ]);

    useEffect(() => {
        if (!isMobile) {
            navigateToMenuOutline();
        }
    }, [isMobile, navigateToMenuOutline]);

    const onSubmit = async () => {
        if (item) {
            await dispatch(
                multiMenuThunks.updateOverrides({
                    itemId: item.value._id,
                    itemType: item.type
                })
            )
                .unwrap()
                .then(navigateToMenuOutline)
                .catch((err) => {
                    logAndSendError(err);
                    openNotification({
                        message: "Failed to update menu",
                        description:
                            "Failed to update current menu overrides, please try again later.",
                        position: NotificationPosition.TopCenter
                    });
                });
        }
    };

    if (!item) return null;

    return (
        <ScreenLayout
            header={
                <MobileHeader
                    title="Edit Item"
                    goBack={navigateToMenuOutline}
                />
            }
            content={
                <BodyWrapper>
                    <Spin
                        spinning={loadingStatusUpdateOverrides === "pending"}
                        tip="Please wait..."
                    >
                        <Space
                            direction="vertical"
                            size={"large"}
                            style={commonStyles.displayFlex}
                        >
                            <Space
                                direction="vertical"
                                size={"small"}
                                style={commonStyles.displayFlex}
                            >
                                <Text type="title-mobile" color="black">
                                    Overrides
                                </Text>
                                <Text type="body-regular" color="grey">
                                    {item.type === "products"
                                        ? "Adjust price and tax rates for this product on specific menu"
                                        : "Adjust price for this addon on specific menu"}
                                </Text>
                            </Space>
                            <Space
                                direction="vertical"
                                size={"small"}
                                style={commonStyles.displayFlex}
                            >
                                <Text
                                    type="large-regular"
                                    color="black"
                                    style={styles.centerText}
                                >
                                    New Price
                                </Text>
                                <PriceOverrideMobileInput item={item.value} />
                            </Space>
                            {isProduct(item.value) ? (
                                <Space
                                    direction="vertical"
                                    size={"small"}
                                    style={commonStyles.displayFlex}
                                >
                                    <Text
                                        type="large-regular"
                                        color="black"
                                        style={styles.centerText}
                                    >
                                        New Tax Rates
                                    </Text>
                                    <TaxesOverrideMobileInput
                                        product={item.value}
                                    />
                                </Space>
                            ) : null}
                        </Space>
                    </Spin>
                </BodyWrapper>
            }
            footer={
                <ScreenFooter
                    onCancel={navigateToMenuOutline}
                    onSubmit={onSubmit}
                    disabled={loadingStatusUpdateOverrides === "pending"}
                />
            }
            isLoading={loadingStatusFetchStoreMenus !== "succeeded"}
        />
    );
};

const styles = {
    centerText: {
        display: "flex",
        justifyContent: "center"
    } as CSSProperties
};

const BodyWrapper = styled.div`
    overflow-y: scroll;
`;
