import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAppSelector } from "src/redux/hooks";
import { getMfaPrecheck, getUser } from "src/redux/selectors";

/**
 * Sign-in flow:
 *  1. User signs in (phone or email/password)
 *  2. Firebase onAuthStateChanged fires, useLoadSnackpassUser runs, sets
 *     Redux User state. This in turn triggers showEmailVerification to be
 *     true.
 *  3. The user's auth token cookie is checked. If valid, skip to 5.
 *  4. If not, the user is emailed a passcode and submits it, then an auth
 *     token is issued and set as a browser cookie.
 *  5. `hasVerifiedEmail` is set to true
 */
export function useMFA() {
    const mfaPrecheck = useAppSelector(getMfaPrecheck);
    const [hasVerifiedEmail, setHasVerifiedEmail] = useState(mfaPrecheck);
    const user = useSelector(getUser);

    useEffect(() => {
        if (!user) {
            setHasVerifiedEmail(false);
        }
    }, [user?._id]);

    // Show the email verification is the user is signed in and hasn't verified
    const showEmailVerification = !mfaPrecheck && !!user && !hasVerifiedEmail;

    return {
        setHasVerifiedEmail,
        showEmailVerification,
        showSignIn: !mfaPrecheck && (!user || showEmailVerification)
    };
}
