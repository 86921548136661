import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { PlusCircledIcon, TrashIcon } from "@radix-ui/react-icons";
import { ColumnDef } from "@tanstack/react-table";

import {
    formatMinutes,
    timeToLuxon,
    getDayMinutesFromLuxon,
    getMinutesFromStartOfWeek,
    getMinutesFromStartOfDay,
    nextDay,
    defaultStartHour,
    defaultEndHour,
    getNewHours,
    getHoursWithout
} from "#settings/settings-online-orders/helpers";
import { DataTable } from "src/@/components/ui/data-table";
import {
    DAY_OPTIONS,
    formatDayOfWeek,
    getStoreOpenDays
} from "#reusable/special-hours/helper";
import { getActiveStore } from "src/redux/selectors";
import { CardDescription } from "src/@/components/ui/card";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger
} from "src/@/components/ui/tooltip";

const DAY_END = 24 * 60 - 1; // minute right before midnight
const NOON_HOURS = 12 * 60; // hour representing noon

type SpecialHourProps = {
    value: {
        start: number;
        end: number;
    }[];
    onChange: (val: { start: number; end: number }[] | null) => void;
    disabled: boolean;
};

type HourRange = {
    start: number;
    end: number;
};

type HoursByWeekday = {
    weekday: number;
    hours: HourRange[];
};

type TimeInputProps = {
    originalTime: string;
    onBlur: (
        rowWeekday: number,
        time: string,
        hour: HourRange,
        type: "start" | "end"
    ) => void;
    rowWeekday: number;
    hour: HourRange;
    type: "start" | "end";
    disabled: boolean;
};

const TimeInput = ({
    originalTime,
    onBlur,
    rowWeekday,
    hour,
    type,
    disabled
}: TimeInputProps) => {
    const [time, setTime] = useState(originalTime);

    const setNewTime = useCallback(() => {
        if (time !== originalTime) {
            onBlur(rowWeekday, time, hour, type); // modify hours function
        }
    }, [hour, onBlur, originalTime, rowWeekday, time, type]);
    return (
        <input
            className="h-9 cursor-pointer rounded-md border border-input bg-background text-small shadow-sm"
            type="time"
            value={time}
            onBlur={() => {
                setNewTime();
            }}
            onChange={(e) => {
                setTime(e.target.value);
            }}
            disabled={disabled}
        />
    );
};

export const SpecialHourRows = ({
    value,
    onChange,
    disabled
}: SpecialHourProps) => {
    const activeStore = useSelector(getActiveStore);
    const [storeOpenDays, setStoreOpenDays] = useState<number[]>([]);

    const createNewHours = useCallback(
        (startMinutes: number, endMinutes: number) => {
            const newHours = getNewHours(value, startMinutes, endMinutes);
            onChange(newHours);
        },
        [value, onChange]
    );

    const deleteHours = useCallback(
        (rowWeekday: number, hour: HourRange) => {
            // deleting hours that don't extend past midnight
            const newHours = getHoursWithout(value, rowWeekday, hour);
            onChange(newHours);
        },
        [value, onChange]
    );

    const modifyHours = useCallback(
        (
            rowWeekday: number,
            time: string,
            hour: HourRange,
            position: "start" | "end"
        ) => {
            const dateObject = timeToLuxon(time);
            if (!dateObject) return;
            const minutes = getDayMinutesFromLuxon(dateObject);
            const minutesFromWeek = getMinutesFromStartOfWeek(
                rowWeekday,
                minutes
            );
            const startMinutes =
                position === "start"
                    ? minutesFromWeek
                    : getMinutesFromStartOfWeek(rowWeekday, hour.start);
            const endMinutes =
                position === "end"
                    ? minutesFromWeek
                    : getMinutesFromStartOfWeek(rowWeekday, hour.end);

            // Delete existing hours first
            const filteredHours = getHoursWithout(value, rowWeekday, hour);

            // If hour is past midnight, add two hours for both days
            if (startMinutes > endMinutes) {
                const preMidnightHours = getNewHours(
                    filteredHours,
                    startMinutes,
                    getMinutesFromStartOfWeek(rowWeekday, DAY_END)
                );
                const midnightHours = getNewHours(
                    preMidnightHours,
                    getMinutesFromStartOfWeek(nextDay(rowWeekday), 0),
                    getMinutesFromStartOfWeek(
                        nextDay(rowWeekday),
                        getMinutesFromStartOfDay(endMinutes)
                    )
                );
                onChange(midnightHours);
            } else {
                // Normal hours
                const normalHours = getNewHours(
                    filteredHours,
                    startMinutes,
                    endMinutes
                );
                onChange(normalHours);
            }
        },
        [onChange, value]
    );

    const validOpenDays = useCallback(
        (day: number) => storeOpenDays.some((openDay) => openDay === day),
        [storeOpenDays]
    );

    useEffect(() => {
        if (activeStore) {
            const _storeOpenDays = getStoreOpenDays(activeStore?.hours);
            setStoreOpenDays(_storeOpenDays);
        }
    }, [activeStore]);

    const columns: ColumnDef<HoursByWeekday>[] = useMemo(
        () => [
            {
                header: () => <div>Day</div>,
                id: "weekday",
                accessorKey: "weekday",
                cell: ({ row }) => (
                    <div>{DAY_OPTIONS[row.original.weekday].label}</div>
                ),
                size: 500
            },
            {
                header: () => <div className="ml-4">Special Hours</div>,
                id: "hours",
                accessorKey: "hours",
                cell: ({ row }) => (
                    <div>
                        {row.original.hours && row.original.hours.length > 0 ? (
                            <div className="flex flex-col space-y-2">
                                {row.original.hours.map((hour, idx) => (
                                    <div
                                        key={idx}
                                        className="flex items-center justify-between"
                                    >
                                        <div>
                                            <TimeInput
                                                originalTime={formatMinutes(
                                                    hour.start
                                                )}
                                                onBlur={modifyHours}
                                                rowWeekday={
                                                    row.original.weekday
                                                }
                                                hour={hour}
                                                type={"start"}
                                                disabled={disabled}
                                            />
                                            <span className="mx-2">-</span>
                                            <TimeInput
                                                originalTime={formatMinutes(
                                                    hour.end
                                                )}
                                                onBlur={modifyHours}
                                                rowWeekday={
                                                    row.original.weekday
                                                }
                                                hour={hour}
                                                type={"end"}
                                                disabled={disabled}
                                            />
                                            {hour.end > DAY_END && (
                                                <span className="ml-2 text-xs text-red-500">
                                                    Next Day
                                                </span>
                                            )}
                                        </div>
                                        {!disabled &&
                                        validOpenDays(row.original.weekday) ? (
                                            <div className="flex">
                                                <div>
                                                    <TooltipProvider>
                                                        <Tooltip>
                                                            <TooltipTrigger
                                                                asChild
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    if (
                                                                        !disabled
                                                                    ) {
                                                                        deleteHours(
                                                                            row
                                                                                .original
                                                                                .weekday,
                                                                            hour
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <TrashIcon className="mr-1 h-4 w-4" />
                                                            </TooltipTrigger>
                                                            <TooltipContent>
                                                                <p className="text-sm">
                                                                    Delete Hours
                                                                </p>
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                </div>
                                                <div className="ml-2">
                                                    <TooltipProvider>
                                                        <Tooltip>
                                                            <TooltipTrigger
                                                                asChild
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    if (
                                                                        !disabled
                                                                    )
                                                                        createNewHours(
                                                                            defaultStartHour(
                                                                                row
                                                                                    .original
                                                                                    .weekday
                                                                            ),
                                                                            defaultEndHour(
                                                                                row
                                                                                    .original
                                                                                    .weekday
                                                                            )
                                                                        );
                                                                }}
                                                            >
                                                                <PlusCircledIcon className="mr-1 h-4 w-4" />
                                                            </TooltipTrigger>
                                                            <TooltipContent>
                                                                <p className="text-sm">
                                                                    Add Hours
                                                                </p>
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="flex items-center justify-between">
                                <CardDescription className="ml-4">
                                    {validOpenDays(row.original.weekday)
                                        ? "No Special Hours"
                                        : "Store is Closed"}
                                </CardDescription>
                                {validOpenDays(row.original.weekday) ? (
                                    <div>
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger
                                                    asChild
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        if (!disabled)
                                                            createNewHours(
                                                                defaultStartHour(
                                                                    row.original
                                                                        .weekday
                                                                ),
                                                                defaultEndHour(
                                                                    row.original
                                                                        .weekday
                                                                )
                                                            );
                                                    }}
                                                >
                                                    <PlusCircledIcon className="mr-1 h-4 w-4" />
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    <p className="text-sm">
                                                        Add Hours
                                                    </p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                ),
                size: 500
            }
        ],
        [createNewHours, deleteHours, disabled, modifyHours, validOpenDays]
    );

    const data: HoursByWeekday[] = useMemo(() => {
        const grouped: { [key: number]: HourRange[] } = DAY_OPTIONS.slice(
            0,
            -1
        ).reduce<Record<number, HourRange[]>>((acc, day) => {
            acc[day.value] = [];
            return acc;
        }, {});

        // Populate the grouped object with hours
        value.forEach((hours) => {
            const { start, end } = hours;
            const weekday = formatDayOfWeek(start);
            if (storeOpenDays.includes(weekday)) {
                grouped[weekday].push({
                    start: getMinutesFromStartOfDay(start), // get minutes starting from the day, not the week
                    end: getMinutesFromStartOfDay(end)
                });
            }
        });

        // Combine hours so that we allow for stores to have hours that run past midnight
        const midnightDays = new Set();
        for (let weekday = 0; weekday <= 6; weekday++) {
            if (!grouped || !(weekday in grouped)) {
                continue;
            }
            const hours = grouped[weekday];
            hours.forEach((hour, _) => {
                if (hour.start === 0) {
                    // find previous day
                    const prevWeekday = weekday === 0 ? 6 : weekday - 1;
                    const prevHours = grouped[prevWeekday];

                    // find an hour in previous day where it ended right before midnight
                    if (!prevHours?.length) {
                        return;
                    }
                    const prevHourIndex = prevHours.findIndex(
                        (h) => h.end === DAY_END
                    );

                    if (prevHourIndex !== -1) {
                        // Combine the hours
                        const prevHour = prevHours[prevHourIndex];
                        if (hour.end >= NOON_HOURS) {
                            return; // if the hours of the next day pass on to the afternoon, we just pass it as two separate store hour days.
                        }
                        const combinedHour = {
                            start: prevHour.start,
                            end: DAY_END + 1 + hour.end
                        };

                        // update the previous weekday's hours
                        grouped[prevWeekday][prevHourIndex] = combinedHour;
                        midnightDays.add(weekday);
                    }
                }
            });
        }
        const filteredData: { [key: string]: HourRange[] } = {};

        for (const [weekday, hours] of Object.entries(grouped)) {
            filteredData[weekday] = midnightDays.has(Number(weekday))
                ? hours.filter((hour) => hour.start !== 0)
                : hours;
        }

        // convert the grouped object to an array grouped in terms of days
        return Object.entries(filteredData).map(([weekday, hours]) => ({
            weekday: parseInt(weekday, 10),
            hours
        }));
    }, [storeOpenDays, value]);

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                emptyText="No Hours Available."
                customPageSize={
                    999999 // functionally infinite rows
                }
                className="border-0 border-y"
            />
        </>
    );
};
