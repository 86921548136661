export enum FilterModalOption {
    All = "All",
    Online = "Online",
    Offline = "Offline"
}

const filterModalOptions = [
    {
        label: FilterModalOption.All,
        value: FilterModalOption.All
    },
    {
        label: FilterModalOption.Online,
        value: FilterModalOption.Online
    },
    {
        label: FilterModalOption.Offline,
        value: FilterModalOption.Offline
    }
];

export default filterModalOptions;
