import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IProduct } from "@snackpass/snackpass-types";

type ProductsState = IProduct[];

const productsInitialState: ProductsState = [];

export const legacyProductsSlice = createSlice({
    name: "legacyProducts",
    initialState: productsInitialState,
    reducers: {
        setLegacyProducts: (_, action: PayloadAction<IProduct[]>) =>
            action.payload,
        updateLegacyProduct: (state, action: PayloadAction<IProduct>) =>
            state.map((product) => {
                if (product._id === action.payload._id) {
                    return action.payload;
                }
                return product;
            }),
        removeLegacyProduct: (state, action: PayloadAction<string>) =>
            state.filter((product) => product._id !== action.payload)
    }
});

export const { setLegacyProducts, updateLegacyProduct, removeLegacyProduct } =
    legacyProductsSlice.actions;
