import ConfirmModal from "#shared-components/ConfirmModal";

type Props = {
    setShowConfirmModal: (show: boolean) => void;
    showConfirmModal: boolean;
    confirmAction: (() => void) | null;
};

function GiftCardConfirmModal({
    setShowConfirmModal,
    showConfirmModal,
    confirmAction
}: Props) {
    return (
        <ConfirmModal
            setShowConfirmModal={setShowConfirmModal}
            showConfirmModal={showConfirmModal}
            confirmAction={confirmAction}
            title="Snackpass Gift Cards Terms"
            body={
                <>
                    <div>
                        <div className="pb-2 pt-4 text-base font-semibold leading-6 md:pt-2">
                            Sell everywhere
                        </div>
                        <div className="text-small leading-5">
                            Gift cards help bring in new customers and boost
                            sales for your business. With Snackpass gift cards,
                            you can effortlessly sell gift cards everywhere—from
                            your Register and Kiosk, website, social media,
                            Online Ordering platform, Snackpass App, or Custom
                            Branded App.
                        </div>
                    </div>
                    <div className="pt-6">
                        <div className="pb-2 text-base font-semibold leading-6">
                            Powerful analytics
                        </div>
                        <div className="text-small leading-5">
                            Our user-friendly gift card management tools allow
                            you to track and analyze gift card sales and
                            redemptions, enabling you to make data-driven
                            decisions to further enhance your business’s
                            success.
                        </div>
                    </div>
                    <div className="pb-2 pt-6">
                        <div className="pb-2 text-base font-semibold leading-6">
                            Transparent pricing
                        </div>
                        <div className="text-small leading-5">
                            <div className="pb-1 text-small leading-5">
                                Digital Gift Cards
                            </div>
                            Once the gift card module is enabled, selling
                            digital gift cards through Snackpass is free to set
                            up and offer. With no monthly subscription or costs
                            to create and maintain your gift card ordering site,
                            all you pay is processing when a customer buys a
                            gift card from you. Funds from gift card purchases
                            are included in your next payout.
                            <div className="pb-1 pt-4 text-small leading-5">
                                Physical Gift Cards
                            </div>
                            With the gift card module enabled, selling physical
                            gift cards through Snackpass is free to set up.
                            Plastic physical gift cards and accessories can be
                            purchased through our production partner eCard
                            Systems. The price of your gift card order will be
                            based on your order size, and is a one-time cost per
                            order for custom cards. When a customer pays for a
                            physical gift card with a card, all you pay is card
                            processing on that transaction. Funds from gift card
                            purchases are included in your next payout.
                        </div>
                    </div>
                    <div className="py-2">
                        <div className="text-small">
                            Standard Processing Rates
                        </div>
                        <div className="text-micro font-semibold  leading-6 text-[#A0A2A7]">
                            ONLINE ORDERING / SNACKPASS APP / CUSTOM BRANDED
                            APP:
                        </div>
                        <div className="text-small">
                            2.9% + 30 cents for processing
                        </div>
                    </div>
                    <div className="py-2">
                        <div className="text-micro font-semibold leading-6  text-[#A0A2A7]">
                            KIOSK / REGISTER:
                        </div>
                        <div className="text-small leading-5">
                            Swiped magstripe, inserted chip cards, or
                            contactless (NFC) Payments: 2.6% + 10 cents
                        </div>
                        <div className="text-small leading-5">
                            Manually keyed-in transactions: 3.5% + 15 cents
                        </div>
                    </div>
                    <div className="pt-6">
                        <div className="pb-2 text-base font-semibold leading-6">
                            Merchant Responsibilities
                        </div>
                        <div className="text-small leading-5">
                            The merchant, and not Snackpass, will be solely
                            responsible for their gift card, any transactions or
                            disputes relating to any gift card and any goods and
                            services redeemable by any gift card. The merchant
                            will be responsible for any sale of gift card and is
                            responsible for all funds credited to or deducted
                            from the gift card, as well as the failure, return,
                            chargeback, reversal or other adjustment to a gift
                            card transaction. Merchant is responsible for any
                            reconciliation or transfer of funds between the
                            merchant’s locations, and with any other entity
                            that’s permitted to accept the gift card as payment,
                            including franchise. Refunds can be made at the
                            discretion of the merchant on unused gift cards.
                        </div>
                    </div>
                    <div className="pt-6">
                        <div className="pb-2 text-base font-semibold leading-6">
                            Compliance with Laws
                        </div>
                        <div className="text-small leading-5">
                            Any gift card that’s offered must comply with all
                            federal, state, and local laws, rules, and
                            regulations, including those pertaining to product
                            liability, payments, taxes, privacy, data security,
                            gift cards/gift certificates, escheatment and
                            consumer protection. The merchant, and not
                            Snackpass, are solely responsible for ensuring that
                            the Gift Cards comply with all applicable law.
                        </div>
                    </div>
                    <div className="mt-6 text-small leading-6 text-[#A0A2A7]">
                        Effective November 15th, 2023. Pricing and terms subject
                        to change.
                    </div>
                    <div className="sticky inset-x-0 bottom-0 block  min-h-[60px] bg-gradient-to-t from-white to-transparent">
                        &nbsp;
                    </div>
                </>
            }
        />
    );
}

export default GiftCardConfirmModal;
