import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";

import {
    setInventoryEndDate,
    setInventoryIsFetching,
    setInventoryStartDate
} from "src/redux/slices";
import { getActiveStore, getActiveStoreId } from "src/redux/selectors";
import {
    getInventoryEndDate,
    getInventoryIsFetching,
    getInventoryStartDate,
    getInventoryTableRows
} from "src/redux/selectors/inventory";
import { fetchInventoryItems, fetchInventoryReports } from "src/redux/thunks";
import { useAppDispatch } from "src/redux/hooks";
import { ExportToCsv } from "#utils/export-to-csv";
import { logAndSendError } from "src/utils/errors";

const momentFormat = "MMMM Do YYYY, h:mm a";

// same as rows except with original stripped

const formatCSV = (rows: any) =>
    rows.map((d: any) => {
        const stripped = { ...d };
        delete stripped.original;
        return stripped;
    });

/* This regex tests for any string that is pure number with 3 decimal places. It can start with dot. */

export const regex = /^-?\d*\.?\d{0,3}$/;

export const baseUnits = [
    "",
    "cup",
    "dz",
    "ea",
    "fl-oz",
    "g",
    "gal",
    "kg",
    "l",
    "lb",
    "mg",
    "ml",
    "oz",
    "pnt",
    "qt",
    "Tbs",
    "tsp"
];

const getStartTime = () => {
    // HACK: starts at 6AM
    // TODO: start at store's start time
    const m = moment();
    m.set("hours", 6);
    m.set("minutes", 0);
    return m;
};

// this provides a function that can be called to fetch inventory
export function useFetchInventory() {
    const storeId = useSelector(getActiveStoreId);
    const startDate = useSelector(getInventoryStartDate);
    const isFetching = useSelector(getInventoryIsFetching);
    const dispatch = useAppDispatch();

    const fetchInventory = () => {
        if (!storeId || !startDate) {
            return;
        }

        // TODO: customize end date
        const endDate = moment().toDate();
        dispatch(setInventoryEndDate(endDate));
        dispatch(setInventoryIsFetching(true));
        try {
            dispatch(fetchInventoryReports(storeId, startDate, endDate));
            dispatch(fetchInventoryItems(storeId));
        } catch (err) {
            toast.error("Failed to fetch inventory.");
            logAndSendError(err);
        }
        dispatch(setInventoryIsFetching(false));
    };

    return [fetchInventory, isFetching] as const;
}

export function useGetStartDate(endDate: Date) {
    const normalStartDate = useSelector(getInventoryStartDate);
    if (!endDate || !normalStartDate || normalStartDate < endDate) {
        return normalStartDate;
    } else {
        return moment(normalStartDate)
            .subtract(1, "day")
            .startOf("day")
            .toDate()
            .setHours(6);
    }
}

// this should be used once in the inventory view to initialize
export function useInitializeInventory() {
    const dispatch = useDispatch();
    const activeStore = useSelector(getActiveStore);
    const storeId = useSelector(getActiveStoreId);
    const rows = useSelector(getInventoryTableRows);
    const [fetchInventory, isFetching] = useFetchInventory();
    const startDate = useSelector(getInventoryStartDate);
    const endDate = useSelector(getInventoryEndDate);

    // set start and end time
    useEffect(() => {
        const newStartDate = getStartTime();
        dispatch(setInventoryStartDate(newStartDate.toDate()));
        const now = moment().toDate();
        dispatch(setInventoryEndDate(now));
    }, [storeId]);

    useEffect(() => {
        // start loading
        dispatch(setInventoryIsFetching(true));
        if (startDate && storeId) {
            fetchInventory();
        }
    }, [startDate, storeId]);

    const exportCSV = () => {
        if (rows.length === 0) {
            toast.error("No inventory yet!");
            return;
        }
        const csvExporter = new ExportToCsv({
            filename: `${activeStore?.name} Inventory: ${moment(
                startDate
            ).format(momentFormat)} - ${moment(endDate).format(momentFormat)}`,
            fieldSeparator: ",",
            useKeysAsHeaders: true
        });

        csvExporter.generateCsv(formatCSV(rows));
    };

    return [exportCSV, isFetching] as const;
}
