import { Col, Form, Input } from "antd";

import { Label } from "#onboarding/components/shared/Label";

type FormInputProps = {
    field: string;
    value?: string;
    label?: string;
    subtitle?: string;
    placeholder: string;
    required?: boolean;
    omitRequired?: boolean;
    disabled?: boolean;
    noLabel?: boolean;
    onChange?: (v: string) => void;
    onBlur?: (v: string) => void;
    pretext?: string;
    postText?: string;
    className?: string;
    span?: number;
    suffix?: JSX.Element;
    tooltip?: string;
};

export const FormInput = ({
    field,
    value,
    label,
    subtitle,
    placeholder,
    required = false,
    omitRequired = false,
    disabled = false,
    noLabel = false,
    onChange,
    onBlur,
    pretext,
    postText,
    className = "",
    span = 24,
    suffix,
    tooltip
}: FormInputProps) => (
    <Col span={span}>
        <Form.Item
            name={field}
            label={
                noLabel ? null : (
                    <Label
                        required={required}
                        omitRequired={omitRequired}
                        label={label}
                        subtitle={subtitle}
                        tooltip={tooltip}
                    />
                )
            }
        >
            <Input
                value={value}
                className={className}
                disabled={disabled}
                placeholder={placeholder}
                onChange={(e) => onChange?.(e.target.value)}
                onBlur={(e) => onBlur?.(e.target.value)}
                addonBefore={pretext}
                addonAfter={postText}
                suffix={suffix}
            />
        </Form.Item>
    </Col>
);
