import React, { useState } from "react";
import { SystemColors } from "@snackpass/design-system";
import { isMobile } from "react-device-detect";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { Spinner } from "react-activity";

import { ReactComponent as BackArrow } from "src/assets/icons/chevron-left.svg";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-x.svg";
import {
    CreateIntegration,
    INTEGRATION_FIELD_NAMES,
    IntegrationApps
} from "#settings-integrations/utils/types";
import {
    CreateIntegrationValidationSchema,
    GenericCreateIntegrationValues
} from "#settings-integrations/utils/integration-form-validation";
import { FormTextInput } from "#settings-integrations/components/shared/form-text-input";
import DropDownSelectIntegrations from "#settings-integrations/components/shared/form-dropdown";
import IntegrationContainer from "#settings-integrations/components/integration-container";
import IntegrationFormButton from "#settings-integrations/components/integration-form-button";
import { CommentContainer } from "#settings-integrations/components/integration-comment-container";
import { PaymentAgreementContainer } from "#settings-integrations/components/payment-agreement-container";
import { ShouldMarkupPricesContainer } from "#settings-integrations/components/should-markup-prices";
import {
    Divider,
    DividerNoTopMargin
} from "#settings-integrations/components/shared/reusables";
import { submitIntegrationRequest } from "#settings-integrations/utils/submit";

type CreateIntegrationFormProps = {
    onHide: () => void;
    storeId: string;
    setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    setIsFailure: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateIntegrationForm = ({
    onHide,
    storeId,
    setIsSuccess,
    setIsFailure
}: CreateIntegrationFormProps) => {
    const methods = useForm<CreateIntegration>({
        //@ts-ignore
        resolver: yupResolver(CreateIntegrationValidationSchema),
        mode: "all",
        defaultValues: GenericCreateIntegrationValues
    });

    const handleBack = (): void => {
        methods.reset(GenericCreateIntegrationValues);
        void methods.trigger();
        onHide();
    };

    const [isSubmitting, setIsSubmitting] = useState(false);

    const selectedIntegrations: IntegrationApps[] = methods.watch(
        INTEGRATION_FIELD_NAMES.INTEGRATIONS
    );

    const onSubmit = async (data: CreateIntegration) => {
        if (!isSubmitting) {
            setIsSubmitting(true);

            try {
                const success = await submitIntegrationRequest(data, storeId);

                if (success) {
                    setIsSuccess(true);
                } else {
                    setIsFailure(true);
                }
                setIsSubmitting(false);
                handleBack();
            } catch (error) {
                setIsFailure(true);
            }
        }
    };

    const hasErrors = !_.isEmpty(methods.formState.errors);

    return (
        //@ts-ignore
        <FormProvider {...methods}>
            <div className="flex flex-col overflow-y-visible p-0 [-ms-overflow-style:'none'] [scrollbar-width:'none'] md:pb-6 md:pl-6 md:pr-4 md:pt-4 [&::-webkit-scrollbar]:hidden">
                <div className="flex items-center justify-between self-stretch">
                    <div className="cursor-pointer" onClick={handleBack}>
                        {isMobile ? (
                            <BackArrow
                                fill={SystemColors.v1.sesame}
                                width={20}
                                height={16}
                            />
                        ) : (
                            <CloseIcon
                                fill={SystemColors.v1.sesame}
                                width={24}
                                height={20}
                            />
                        )}
                    </div>
                    <div className="text-body font-semibold text-neutral-950">
                        Request New Integration
                    </div>
                    <div className="flex items-start p-2"></div>
                </div>
                <Divider />
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="flex flex-col p-0">
                        <div className="py-3 text-large font-bold text-neutral-950">
                            Contact Info
                        </div>
                        <FormTextInput
                            inputLabel="Name"
                            fieldName={INTEGRATION_FIELD_NAMES.NAME}
                            placeholder="Name"
                        />
                        <FormTextInput
                            inputLabel="Email"
                            fieldName={INTEGRATION_FIELD_NAMES.EMAIL}
                            placeholder="Email"
                        />
                    </div>
                    <Divider />
                    <div className="py-3 text-large font-bold text-neutral-950">
                        Integrations
                    </div>
                    <div className="py-1 text-small font-[300] text-neutral-600">
                        Please enter or select all the integrations you'd like
                        to add to your store.
                    </div>
                    <DropDownSelectIntegrations
                        fieldName={INTEGRATION_FIELD_NAMES.INTEGRATIONS}
                        square
                        placeholder="Select"
                    />
                    {selectedIntegrations.length !== 0 && (
                        <>
                            <Divider />
                            <div className="py-3 text-large font-bold text-neutral-950">
                                Connecting Your Account
                            </div>
                            {selectedIntegrations.map((integration, index) => (
                                <IntegrationContainer
                                    index={index}
                                    key={index}
                                    integration={integration}
                                    integrations={selectedIntegrations}
                                />
                            ))}
                            <ShouldMarkupPricesContainer />
                            <Divider />
                            <CommentContainer />
                            <div className="sticky bottom-14 flex w-full flex-col justify-between border-t-2 border-t-neutral-300 bg-neutral-50 lg:bottom-0">
                                <DividerNoTopMargin />
                                <PaymentAgreementContainer />
                                <Divider />
                                <div className="flex w-full flex-row justify-between pb-3">
                                    <IntegrationFormButton
                                        fullWidth
                                        variant="cancel"
                                        ml={6}
                                        mr={6}
                                        onClick={handleBack}
                                    >
                                        Cancel
                                    </IntegrationFormButton>
                                    <IntegrationFormButton
                                        fullWidth
                                        variant="request"
                                        disabled={hasErrors}
                                        ml={6}
                                        mr={6}
                                    >
                                        {isSubmitting ? (
                                            <Spinner
                                                color={SystemColors.v1.white}
                                            />
                                        ) : (
                                            "Request"
                                        )}
                                    </IntegrationFormButton>
                                </div>
                            </div>
                        </>
                    )}
                </form>
            </div>
        </FormProvider>
    );
};
