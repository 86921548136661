import styled from "@emotion/styled";
import noop from "lodash/noop";
import { PubNubPublisher } from "@snackpass/conversations.client";
import {
    useMessagesFlat,
    usePubNubWithToken,
    useSubscribe
} from "@snackpass/conversations.hooks";
import {
    chatChannel,
    eventChannel,
    EventType,
    MessageType,
    MessageEnvelope,
    storeUUID,
    textMessage
} from "@snackpass/conversations.types";
import { SystemColors } from "@snackpass/design-system";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { show } from "redux-modal";
import { getActiveStore } from "@snackpass/accounting";
import { Spin } from "antd";
import { ChannelMetadataObject, ObjectCustom } from "pubnub";
import { v4 as uuidv4 } from "uuid";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { ScreenState } from "@snackpass/snackpass-types";
import { toast } from "sonner";

import { ConversationInput } from "#guestbook/components/conversation-input/index";
import {
    CampaignEventMessage,
    ComplaintEventMessage,
    ComplimentEventMessage,
    FollowEventMessage,
    GiftEventMessage,
    MenuSuggestionEventMessage,
    PurchaseEventMessage,
    RefundEventMessage
} from "#guestbook/screens/conversations-details/custom-messages";
import { ReactComponent as Profile } from "src/assets/icons/profile-person.svg";
import { ReactComponent as Chevron } from "src/assets/icons/chevron-left.svg";
import {
    getFormattedTime,
    NUMBER_MESSAGES_PER_LOAD
} from "#guestbook/screens/conversations-details/lib";
import { OrderHistoryDrawer } from "#guestbook/components/order-history/order-history-drawer";
import { SegmentEvents, trackSegmentEvent } from "#utils/segment";
import { OrderDetailsDrawer } from "#guestbook/components/order-details";
import { ReactComponent as UnreadChat } from "src/assets/icons/chat-unread.svg";
import { sendError } from "src/utils/errors";

/* Creates and configures your PubNub instance. Be sure to replace "myPublishKey" and "mySubscribeKey"
  with your own keyset. If you wish, modify the default "myFirstUser" uuid value for the chat user. */

// FIXME: Export type from convos.types
type ChatChannelMetadata = {
    userLastMsg?: string;
    userNumOrders?: number;
    userLastOrder?: string;
    userFavProduct?: string;
    userFavProductId?: string;
};

type ConversationDetailsProps = {
    storeId: string;
    userId: string;
    activeChatChannel: ChannelMetadataObject<ObjectCustom> | undefined;
    setShowConversation?: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ConversationDetails({
    storeId,
    userId,
    activeChatChannel,
    setShowConversation
}: ConversationDetailsProps) {
    const activeStore = useSelector(getActiveStore);

    const pubnub = usePubNubWithToken();
    const uuid = pubnub.getUUID();
    const [publisher, setPublisher] = useState<PubNubPublisher>();

    useEffect(() => {
        pubnub &&
            setPublisher(
                new PubNubPublisher({ uuid, subscribeKey: "" }, pubnub)
            );
    }, [pubnub, uuid]);

    const ids = useMemo(() => ({ storeId, userId }), [storeId, userId]);
    const channels = useMemo(
        () => [chatChannel(ids), eventChannel(ids)],
        [ids]
    );
    useSubscribe(pubnub, { channels });

    const [userName, setUserName] = useState<string>();
    const [chatMetadata, setChatMetadata] = useState<ChatChannelMetadata>();

    useEffect(() => {
        if (activeChatChannel) {
            setUserName(activeChatChannel.name ?? undefined);
            setChatMetadata(activeChatChannel.custom ?? undefined);
        }
    }, [activeChatChannel]);

    const [messages, _fetchMore, fetchedAll] = useMessagesFlat(pubnub, {
        channels,
        count: 25
    });

    const publishMessage = useCallback(
        (input: string) => {
            const chat = publisher?.chat(ids);
            void chat?.publish(textMessage(input)).then(async (value) => {
                if (activeStore) {
                    trackSegmentEvent(
                        SegmentEvents.Guestbook.Conversations.SENT,
                        {
                            actor: "store",
                            store_id: activeStore._id,
                            store_name: activeStore.name
                        }
                    );
                }

                const custom = {
                    storeLastChatTT: value.timetoken,
                    storeLastChat: input
                };
                return chat.mergeMetadata({ custom });
            });
        },
        [publisher, ids, activeStore]
    );

    /**
     * Mark a channel as unread by the store. To do this, simply set the
     * store's last read time to be a bit before the user's last message
     * time.
     */
    const markConversationUnread = useCallback(async (): Promise<void> => {
        if (!publisher) return;
        await publisher
            .chat(ids)
            .getMetadata()
            .then(
                async (metadata) =>
                    metadata.custom?.userLastChatTT &&
                    Promise.all([
                        publisher
                            .store(storeUUID(ids.storeId))
                            .markChannelAsUnread(channels[0]),
                        publisher.chat(ids).mergeMetadata({
                            custom: {
                                storeLastReadTT:
                                    metadata.custom.userLastChatTT - 10_000_000 // One second
                            }
                        })
                    ])
            )
            .then(noop);
    }, [publisher, ids, channels]);

    if (!userId) {
        return <></>;
    }

    return (
        <>
            <OrderHistoryDrawer userId={userId} chatMetadata={chatMetadata} />
            <OrderDetailsDrawer />
            <PageContainer>
                <ConversationHeader
                    name={userName}
                    numOrders={chatMetadata?.userNumOrders}
                    lastOrder={chatMetadata?.userLastOrder}
                    setShowConversation={setShowConversation || undefined}
                    userHasSentChat={!!activeChatChannel?.custom?.userLastChat}
                    markConversationUnread={markConversationUnread}
                />
                <ConversationContent
                    messages={messages}
                    fetchedAll={fetchedAll}
                    userId={storeUUID(storeId)}
                />
                <ConversationInput
                    publishMessage={publishMessage}
                    userId={userId}
                    storeId={storeId}
                    storeName={activeStore?.name ?? ""}
                />
            </PageContainer>
        </>
    );
}

const ConversationContent = ({
    messages,
    userId,
    fetchedAll
}: {
    messages: MessageEnvelope[];
    userId: string;
    fetchedAll: boolean;
}) => {
    const [messagesToDisplay, setMessagesToDisplay] = useState(
        messages.slice(-NUMBER_MESSAGES_PER_LOAD)
    );
    const [isLoading, setIsLoading] = useState(false);
    const scrollableRootRef = React.useRef<HTMLDivElement | null>(null);
    const lastScrollDistanceToBottomRef = React.useRef<number>();
    const [pageNumber, setPageNumber] = useState(0);
    const hasNextPage = messagesToDisplay.length !== messages.length;

    const loadMore = () => {
        setIsLoading(true);
        const newMessagesToBeAdded = messages.slice(
            -NUMBER_MESSAGES_PER_LOAD * (pageNumber + 2),
            -NUMBER_MESSAGES_PER_LOAD * (pageNumber + 1)
        );
        setMessagesToDisplay([...newMessagesToBeAdded, ...messagesToDisplay]);
        setPageNumber(pageNumber + 1);
        setIsLoading(false);
    };

    const [infiniteRef, { rootRef }] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage,
        onLoadMore: loadMore,
        delayInMs: 500
    });

    React.useEffect(() => {
        const scrollableRoot = scrollableRootRef.current;
        const lastScrollDistanceToBottom =
            lastScrollDistanceToBottomRef.current ?? 0;
        if (scrollableRoot) {
            scrollableRoot.scrollTop =
                scrollableRoot.scrollHeight - lastScrollDistanceToBottom;
        }
    }, [messagesToDisplay, rootRef]);

    const rootRefSetter = React.useCallback(
        (node: HTMLDivElement) => {
            rootRef(node);
            scrollableRootRef.current = node;
        },
        [rootRef]
    );

    const handleRootScroll = React.useCallback(() => {
        const rootNode = scrollableRootRef.current;
        if (rootNode) {
            const scrollDistanceToBottom =
                rootNode.scrollHeight - rootNode.scrollTop;
            lastScrollDistanceToBottomRef.current = scrollDistanceToBottom;
        }
    }, []);

    useEffect(() => {
        setPageNumber(0);
        setMessagesToDisplay(messages.slice(-NUMBER_MESSAGES_PER_LOAD));
    }, [JSON.stringify(messages[messages.length - 1])]);

    let prevTimestamp = new Date(0);

    // Prevent reloading while useMessageFlat returns all messages
    if (!fetchedAll) {
        return (
            <SpinnerWrapper>
                <Spin tip="Loading..." />
            </SpinnerWrapper>
        );
    }

    return (
        <ConversationTextBoxWrapper
            ref={rootRefSetter}
            onScroll={handleRootScroll}
        >
            {hasNextPage && (
                <div ref={infiniteRef}>
                    <SpinnerWrapper>
                        <Spin />
                    </SpinnerWrapper>
                </div>
            )}
            {messagesToDisplay.map((env: MessageEnvelope) => {
                const timestamp =
                    env.message.ts ?? new Date(Number(env.timetoken) / 10_000);
                const div = (
                    <div key={uuidv4()}>
                        <TimeStamp
                            timestamp={timestamp}
                            prevTimestamp={prevTimestamp}
                        />
                        <IndividualMessage env={env} userId={userId} />
                    </div>
                );
                prevTimestamp = timestamp;
                return div;
            })}
        </ConversationTextBoxWrapper>
    );
};

const TimeStamp = ({
    timestamp,
    prevTimestamp
}: {
    timestamp: Date;
    prevTimestamp: Date;
}) => {
    const time = new Date(timestamp).getTime(),
        prevTime = new Date(prevTimestamp).getTime(),
        threshold = 6 * 60 * 60 * 1000, // 6 hours
        difference = time - prevTime;

    if (difference > threshold) {
        const displayTimeString = getFormattedTime(timestamp);
        return <Time>{displayTimeString}</Time>;
    }
    return null;
};

const IndividualMessage = ({
    env,
    userId
}: {
    env: MessageEnvelope;
    userId: string;
}) => {
    const { message, uuid, publisher } = env;
    let text = "";
    switch (message.type) {
        case EventType.Purchase:
            return (
                <LeftMessageBoxWrapper>
                    <PurchaseEventMessage message={message} />
                </LeftMessageBoxWrapper>
            );
        case EventType.Follow:
            return (
                <LeftMessageBoxWrapper>
                    <FollowEventMessage message={message} />
                </LeftMessageBoxWrapper>
            );
        case EventType.Complaint:
            return (
                <LeftMessageBoxWrapper>
                    <ComplaintEventMessage message={message} />
                </LeftMessageBoxWrapper>
            );
        case EventType.Compliment:
            return (
                <LeftMessageBoxWrapper>
                    <ComplimentEventMessage message={message} />
                </LeftMessageBoxWrapper>
            );
        case EventType.Refund:
            return (
                <RightMessageBoxWrapper>
                    <RefundEventMessage message={message} />
                </RightMessageBoxWrapper>
            );
        case EventType.StoreGift:
            return (
                <RightMessageBoxWrapper>
                    <GiftEventMessage message={message} />
                </RightMessageBoxWrapper>
            );
        case EventType.MenuSuggestion:
            return (
                <LeftMessageBoxWrapper>
                    <MenuSuggestionEventMessage message={message} />
                </LeftMessageBoxWrapper>
            );
        case EventType.Campaign:
            return (
                <RightMessageBoxWrapper>
                    <CampaignEventMessage message={message} />
                </RightMessageBoxWrapper>
            );
        case MessageType.Text:
            // Filter out redundant complaint text events from OO, which only have thumbs down rating without custom notes.
            // The client app requires selecting a complaint option and allows custom notes, making it more informative.
            if (message.text.startsWith("Issue —   (Order")) {
                return;
            }
            text = message.text;
            break;
        default:
            const _: never = message;
    }

    //if the message is sent from the channel user, render it no the right side
    if (!text) return null;
    if (userId === (uuid ?? publisher)) {
        return (
            <RightMessageBoxWrapper>
                <RightMessageBox>{text}</RightMessageBox>
            </RightMessageBoxWrapper>
        );
    } else {
        return (
            <LeftMessageBoxWrapper>
                <LeftMessageBox>{text}</LeftMessageBox>
            </LeftMessageBoxWrapper>
        );
    }
};

const ConversationHeader = ({
    name,
    lastOrder,
    numOrders,
    favItem,
    setShowConversation,
    userHasSentChat,
    markConversationUnread
}: {
    name?: string;
    lastOrder?: string;
    numOrders?: number;
    favItem?: string;
    setShowConversation?: React.Dispatch<React.SetStateAction<boolean>>;
    userHasSentChat: boolean;
    markConversationUnread: () => Promise<void>;
}) => {
    const dispatch = useDispatch();
    const onClickProfile = useCallback(() => {
        dispatch(show("OrderHistoryDrawer"));
    }, [dispatch]);

    const [isLoading, setIsLoading] = useState(false);

    const onClickMarkUnread = useCallback(async () => {
        if (isLoading) return;
        setIsLoading(true);
        try {
            await markConversationUnread();
            toast.success("Marked as unread");
        } catch (e) {
            toast;
            toast.error("Something went wrong", {
                description:
                    "We couldn't mark this conversation as unread.\n\nPlease try again; if this issue persists, please contact support!"
            });
            sendError(e);
        } finally {
            setIsLoading(false);
        }
    }, [markConversationUnread]);

    return (
        <ConversationHeaderContainer>
            <FirstRow>
                <ConversationHeaderTitle
                    onClick={() =>
                        setShowConversation ? setShowConversation(false) : {}
                    }
                >
                    <div className="chevron">
                        <Chevron
                            fill={SystemColors.v1.sesame}
                            className="chevron-icon"
                        />
                        <div>{name}</div>
                    </div>
                </ConversationHeaderTitle>

                <HeaderButtons>
                    {/* Hide mark unread button if user has not sent a chat */}
                    {userHasSentChat ? (
                        <MarkUnread onClick={onClickMarkUnread}>
                            {isLoading ? (
                                <SpinnerWrapper>
                                    <Spin />
                                </SpinnerWrapper>
                            ) : (
                                <UnreadChat />
                            )}
                            <span className="pl-2 font-semibold">
                                Mark Unread
                            </span>
                        </MarkUnread>
                    ) : null}

                    <OrderHistory className="hover" onClick={onClickProfile}>
                        <Profile fill={SystemColors.v1.sesame} />
                        <span className="pl-2 font-semibold">Profile</span>
                    </OrderHistory>
                </HeaderButtons>
            </FirstRow>

            <ConversationHeaderDetail>
                {lastOrder ? (
                    <>
                        <span className="detail-title">Last Order</span>
                        <span className="detail-data">
                            {new Date(lastOrder).toLocaleDateString()}
                        </span>
                    </>
                ) : null}
                {numOrders ? (
                    <>
                        <span className="divider">|</span>
                        <span className="detail-data">{numOrders}</span>
                        <span className="detail-title">Total Orders</span>
                    </>
                ) : null}
                {favItem ? (
                    <>
                        <span className="divider">|</span>
                        <span className="detail-title">Fav Item:</span>
                        <span className="detail-data">{favItem}</span>
                    </>
                ) : null}
            </ConversationHeaderDetail>
        </ConversationHeaderContainer>
    );
};

const PageContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: inherit;
    justify-content: space-between;
    @media ${ScreenState.MOBILE}, ${ScreenState.TABLET} {
        height: inherit;
    }
`;

const ConversationHeaderContainer = styled.div`
    padding: 20px;
    padding-top: 54px;
    height: fit-content;
    position: sticky;
    background-color: ${SystemColors.v1.white};
    top: 0;
    border-bottom: 1px solid ${SystemColors.v1.gray80};

    /* @media ${ScreenState.MOBILE} {
        overflow: auto;
    } */
`;

const FirstRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-flow: wrap;
    gap: 10px;
    word-break: break-word;
`;

const ConversationHeaderTitle = styled.div`
    font-weight: bold;
    font-size: 22px;
    margin: 0;
    .chevron {
        cursor: pointer;
        margin-right: 5px;
        font-size: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .chevron-icon {
        @media ${ScreenState.DESKTOP}, ${ScreenState.TABLET} {
            display: none;
        }
        margin-right: 10px;
    }
`;

const HeaderButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const MarkUnread = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    margin-right: 10px;
    border: 2px solid ${SystemColors.v1.sesame};
    border-radius: 8px;
    min-height: 35px;
    cursor: pointer;
    svg {
        width: 20px;
        height: 20px;
    }
`;

const OrderHistory = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 2px solid ${SystemColors.v1.sesame};
    border-radius: 8px;
    min-height: 35px;
    cursor: pointer;
    svg {
        width: 20px;
        height: 20px;
    }
`;

const ConversationHeaderDetail = styled.div`
    font-size: 14px;
    .detail-title {
        font-weight: 400;
        color: ${SystemColors.v1.gray30};
        padding-right: 4px;
    }
    .detail-data {
        font-weight: 700;
        padding-right: 4px;
    }
    .divider {
        color: ${SystemColors.v1.gray70};
        font-size: 20px;
        font-weight: 300;
        padding: 0px 2px;
    }
`;

const ConversationTextBoxWrapper = styled.div`
    height: 100%;
    padding-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;

    @media ${ScreenState.MOBILE} {
        height: 100%;
        width: 100vw;
    }
`;

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Time = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 5px;
    color: ${SystemColors.v1.gray30};
`;

const RightMessageBoxWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    padding-left: 15px;
`;
const RightMessageBox = styled.div`
    align-items: center;
    padding: 5px 15px;
    width: fit-content;
    height: fit-content;
    background: ${SystemColors.v1.candy50};
    border-radius: 16px;
    color: white;
    margin-right: 15px;
    margin-bottom: 10px;
    max-width: 70%;
`;

const LeftMessageBoxWrapper = styled.div`
    width: 100%;
    display: flex;
`;

const LeftMessageBox = styled.div`
    align-items: center;
    padding: 5px 15px;
    width: fit-content;
    height: fit-content;
    background: ${SystemColors.v1.gray80};
    border-radius: 16px;
    margin-left: 15px;
    margin-bottom: 10px;
    max-width: 70%;
`;
