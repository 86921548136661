import type { Moment } from "moment";
import moment from "moment";
import compose from "lodash/fp/compose";
import prop from "lodash/fp/prop";
import { toast } from "sonner";
import { IProduct, IStore } from "@snackpass/snackpass-types";

import api from "src/api/rest";
import { AppDispatch } from "src/redux/store";
import { setActiveStore, setLegacyProducts } from "src/redux/slices";
import { fetchLegacyMenu } from "#menu/api";

export const alertAxiosError = compose(
    toast,
    (msg) => `ERROR: ${msg}`,
    prop("response.data.message")
);

export const refreshStoreProducts = async (
    dispatch: AppDispatch,
    storeId: string
) => {
    const updateProductsFromApi = (response: { products: IProduct[] }) =>
        dispatch(setLegacyProducts(response.products));
    const updateStoreFromApi = (response: { data: { store: IStore } }) =>
        dispatch(setActiveStore(response.data.store));

    return Promise.all([
        fetchLegacyMenu(storeId)
            .then(updateProductsFromApi)
            .catch(alertAxiosError),
        api.stores
            .getOne(storeId)
            .then(updateStoreFromApi)
            .catch(alertAxiosError)
    ]);
};

const allowedToBeLowercase = [
    "or",
    "and",
    "with",
    "the",
    "w/",
    "oz",
    "de",
    "of",
    "pcs"
];

export const isAllLetterUpperCase = (sentence: string): boolean => {
    const words = sentence.split(" ");
    for (const word of words) {
        if (
            word.charAt(0) !== word.charAt(0).toUpperCase() &&
            !allowedToBeLowercase.includes(word)
        )
            return false;
    }
    return true;
};

export const format12 = "hh:mm a";

export const convertTimeStringToNumberFormat = (
    dayOfWeek: number,
    time: Moment
) => {
    const formattedTime =
        dayOfWeek * 24 * 60 + moment.duration(time.format("HH:mm")).asMinutes();
    return isNaN(formattedTime) ? 0 : formattedTime;
};

export const formatMenuDayOfWeek = (minutes: number): number =>
    moment.duration(minutes, "minutes").days();

export const formatMenuTime = (minutes: number) => {
    const time = moment().startOf("day").minutes(minutes).format(format12);
    return moment(time, format12);
};
