import React, { useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { compose } from "lodash/fp";
import { show } from "redux-modal";

import CacheBustingModal from "./components/CacheBustingModal";

type Props = {
    children?: React.ReactNode;
};

const fetchVersion = async () => {
    // prevents version.txt from getting cached.
    const minutesSinceEpoch = Math.round(Date.now() / 1000 / 60);
    return axios.get(`/version.txt?${minutesSinceEpoch}`, {
        baseURL: window.location.origin
    });
};

const CacheBusting = ({ children }: Props) => {
    const dispatch = useDispatch();
    const [currentVersion, setCurrentVersion] = useState<string>(
        process.env.REACT_APP_COMMIT_REF ?? ""
    );

    const showModal = compose(dispatch, show);

    const { data: version } = useQuery({
        queryKey: ["cache-busting"],
        queryFn: fetchVersion,
        refetchInterval: 15 * 60 * 1000,
        enabled: process.env.NODE_ENV !== "development" // disable useQuery during local development
    });

    useEffect(() => {
        if (version && version.data) {
            if (currentVersion === "") {
                setCurrentVersion(version.data);
            } else if (currentVersion !== version.data) {
                setCurrentVersion(version.data);
                showModal("CacheBustingModal");
            }
        }
    }, [version]);

    return (
        <>
            <CacheBustingModal />
            {children}
        </>
    );
};

export default CacheBusting;
