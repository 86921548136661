import { createSelector } from "@reduxjs/toolkit";
import {
    IProduct,
    IProductCategory,
    IStore,
    ProductIngredientT
} from "@snackpass/snackpass-types";
import _ from "lodash";

import { RootState } from "src/redux/store";
import { InventoryItem } from "src/redux/slices/inventory";

import { selectActiveMenu } from "./activeMenu";
import { getActiveStore } from "./activeStore";
import { getActiveProductCategory } from "./activeProductCategory";
import { getInventory } from "./inventory";

const getLegacyProductsState = (state: RootState) => state.legacyProducts;

export const getLegacyProducts = createSelector(
    [getLegacyProductsState],
    (products) => products.filter((p) => p.specialProductType == null)
);

// Most use cases except for gift card settings will want to avoid touching
// special product type products.
export const getLegacySpecialTypeProducts = createSelector(
    [getLegacyProductsState],
    (products) => products.filter((p) => p.specialProductType != null)
);

export const getActiveProductCategories = createSelector(
    [selectActiveMenu, getActiveStore, getLegacyProducts],
    (activeMenu, activeStore, products) => {
        if (_.isEmpty(activeStore) || !activeStore) {
            return [];
        }

        return getProductCategoriesForMenu(activeStore, activeMenu, products);
    }
);

export const getActiveProductIds = createSelector(
    [getActiveStore, getActiveProductCategory, selectActiveMenu],
    (activeStore, activeProductCategory, activeMenu) => {
        if (!activeStore?.productCategories?.length) {
            return [];
        }
        const activeCategory =
            activeMenu === "catering"
                ? activeStore.catering.productCategories.find(
                      (cat) => cat.name === activeProductCategory
                  )
                : activeStore.productCategories.find(
                      (cat) => cat.name === activeProductCategory
                  );
        if (!activeCategory) return [];
        return activeCategory.productIds;
    }
);

/**
 * Returns a list of pairs which link a product's ingredients with the ingredients InventoryItem data
 */
export const getCombinedIngredientsWithInventory = (
    state: RootState,
    ingredients: ProductIngredientT[]
) => {
    const inventory = getInventory(state);
    const inventoryItemsMap: Record<string, InventoryItem> = {};

    inventory.items.forEach((inventoryItem) => {
        inventoryItemsMap[inventoryItem.id] = inventoryItem;
    });

    return ingredients.map(
        (productIngredient) =>
            [
                inventoryItemsMap[productIngredient.inventoryItemId],
                productIngredient
            ] as [InventoryItem, ProductIngredientT]
    );
};

const getProductCategoriesForMenu = (
    store: IStore,
    menu: string,
    products?: IProduct[]
): IProductCategory[] => {
    const cateringCategoriesWithProducts = store.catering.productCategories
        .filter((c) => c.specialProductCategory == null)
        .map((category) => {
            const productForCatering: IProduct[] = [];
            category.productIds.map((productId) => {
                products?.map((product) => {
                    if (product._id === productId) {
                        productForCatering.push(product);
                    }
                });
            });
            return { ...category, products: productForCatering };
        });
    const regularCategories = store.productCategories
        .filter((c) => c.specialProductCategory == null)
        .map((category) => {
            const productForRegular: IProduct[] = [];
            category.productIds.map((productId) => {
                products?.map((product) => {
                    if (product._id === productId) {
                        productForRegular.push(product);
                    }
                });
            });
            return { ...category, products: productForRegular };
        });

    switch (menu) {
        case "regular":
            return regularCategories;
        case "catering":
            return cateringCategoriesWithProducts;
        default:
            throw new Error(`Menu type "${menu}" is not valid`);
    }
};
