import { toDollar } from "@snackpass/accounting";
import { SnackpassTimezoneEnum } from "@snackpass/snackpass-types";
import moment, { Moment } from "moment-timezone";

import { PayoutHistoryItem } from "src/api/rest";

export const getEarningsPeriodText = (
    data: PayoutHistoryItem,
    timezone: SnackpassTimezoneEnum
): string =>
    data.periodStart && data.periodEnd
        ? `${moment
              .tz(data.periodStart, timezone)
              .format("MM/DD/YY")} - ${moment
              .tz(data.periodEnd, timezone)
              .format("MM/DD/YY")}`
        : "n/a — see Details";

export const getPayoutDateText = (
    data: PayoutHistoryItem,
    timezone: SnackpassTimezoneEnum
): string => moment.tz(data.payoutDate, timezone).format("MM/DD/YY");

export const getArrivalDateText = (
    data: PayoutHistoryItem,
    timezone: SnackpassTimezoneEnum
): string =>
    moment.tz(data.payoutDate, timezone).add(2, "days").format("MM/DD/YY");

interface FilterOptions {
    startDate: Moment;
    endDate: Moment;
    timezone: "" | SnackpassTimezoneEnum;
}

const filterPayoutTableData = (
    data: PayoutHistoryItem[],
    options: FilterOptions
) =>
    data.filter(
        (item) =>
            item.status !== "unknown" &&
            moment
                .tz(item.payoutDate, options.timezone)
                .isBetween(options.startDate, options.endDate, "date", "[]")
    );

const sortPayoutTableData = (data: PayoutHistoryItem[]) => {
    data.sort((a, b) => moment(b.periodEnd).diff(moment(a.periodEnd)));
};

export const cleanPayoutTableData = (
    data: PayoutHistoryItem[],
    options: FilterOptions
) => {
    const filteredTableData = filterPayoutTableData(data, options);
    sortPayoutTableData(filteredTableData);
    return filteredTableData;
};

export const formatPresentablePayoutsData = (
    payouts: PayoutHistoryItem[],
    timezone: SnackpassTimezoneEnum
) =>
    payouts.map((payout) => ({
        "Payout Period": getEarningsPeriodText(payout, timezone),
        "Payout Date": moment
            .tz(payout.payoutDate, timezone)
            .format("MM/DD/YY"),
        "Net Payout": toDollar(payout.netPayout),
        Status: payout.status,
        Details: payout.description
    }));
