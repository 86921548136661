import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { AxiosResponse } from "axios";

import { ReportsContext } from "#app/reports-context-provider";
import { getStoreTimezone } from "#utils/helpers";
import { useAppSelector } from "src/redux/hooks";
import { getActiveStore } from "src/redux/selectors/activeStore";

/**
 * Helper function to DRY up useQuery boilerplate for our reports queries.
 * @param queryName should be a unique string for the useQuery cache.
 * @param queryFunction an axios rest function call
 */
export function useReportsQuery<Data, Params>(
    queryName: string,
    queryFunction: (
        params: Params,
        signal: AbortSignal
    ) => Promise<AxiosResponse<Data>>
) {
    const activeStore = useAppSelector(getActiveStore);
    const { reportsState } = useContext(ReportsContext);
    const { dateRanges, filter } = reportsState;

    const { isLoading, error, data, refetch } = useQuery({
        queryKey: [queryName, activeStore?._id, dateRanges, filter],
        queryFn: async (context) => {
            if (!activeStore?._id || !dateRanges || !filter) {
                return undefined;
            }
            const params = {
                storeId: activeStore._id,
                startDate: dateRanges[0][0].format("YYYY-MM-DD"),
                endDate: dateRanges[0][1].format("YYYY-MM-DD"),
                comparedToStartDate: dateRanges[1][0].format("YYYY-MM-DD"),
                comparedToEndDate: dateRanges[1][1].format("YYYY-MM-DD"),

                timezone: getStoreTimezone(activeStore),

                channel: JSON.stringify(filter.channel),
                source: JSON.stringify(filter.source),
                fulfillment: JSON.stringify(filter.fulfillment),
                ...(filter.storeIds.length
                    ? { storeIds: JSON.stringify(filter.storeIds) }
                    : {})
            } as Params;
            return queryFunction(params, context.signal);
        },
        enabled: !!activeStore?._id
    });
    return {
        isLoading,
        error,
        refetch,
        data
    };
}
