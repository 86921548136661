import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "src/@/components/ui/alert-dialog";

type Props = {
    userId: string;
    setOpenAlert: (open: boolean) => void;
    onConfirm: (userId: string) => void;
    onCancel?: () => void;
};

const RemoveAdminAlertDialog = ({
    userId,
    onConfirm,
    onCancel,
    setOpenAlert
}: Props) => (
    <AlertDialog open={userId.length > 0} onOpenChange={setOpenAlert}>
        <AlertDialogContent className="bg-white">
            <AlertDialogHeader>
                <AlertDialogTitle>Remove Admin?</AlertDialogTitle>
                <AlertDialogDescription>
                    Remove this admin from having any permissions on this store
                </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
                <AlertDialogCancel
                    onClick={() => {
                        if (onCancel) onCancel();
                    }}
                >
                    Cancel
                </AlertDialogCancel>
                <AlertDialogAction
                    onClick={() => {
                        onConfirm(userId);
                        setOpenAlert(false);
                    }}
                >
                    Continue
                </AlertDialogAction>
            </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>
);

export default RemoveAdminAlertDialog;
