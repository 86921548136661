import React, { useCallback, useMemo } from "react";
import { Col } from "antd";
import clsx from "clsx";

import { Text } from "#reusable/text/index";
import { Divider } from "#reusable/divider";
import { StyledRow } from "#settings/components/shared-component";
import Switch from "#reusable/input/toggle-input";
import { TOGGLE_ROWS_TITLES_AND_DESCRIPTIONS } from "#settings/settings-orders/rows/toggle-rows-titles-and-descriptions";

import { ToggleRowProps } from "../types";

export const ToggleRow = ({
    field,
    setFieldValue,
    setFieldTouched,
    value,
    disabled
}: ToggleRowProps) => {
    const onToggleChange = useCallback(
        (_value: boolean) => {
            setFieldTouched(field);
            setFieldValue(field, _value);
        },
        [setFieldValue, setFieldTouched, field, value]
    );

    const { description, title, deprecated } = useMemo(
        () => TOGGLE_ROWS_TITLES_AND_DESCRIPTIONS[field],
        [field]
    );

    return (
        <>
            <StyledRow>
                <Col span={20}>
                    <Text
                        size="m"
                        className={clsx({
                            "text-gray-400": deprecated
                        })}
                    >
                        {title}
                    </Text>
                    <Text
                        size="s"
                        color="light-grey"
                        weight="thin"
                        className={clsx({
                            "text-gray-400": deprecated
                        })}
                    >
                        {description}
                    </Text>
                </Col>
                <Switch
                    id={field}
                    onChange={onToggleChange}
                    isOn={value}
                    disabled={disabled}
                />
            </StyledRow>
            <Divider />
        </>
    );
};
