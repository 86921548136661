import { Spin } from "antd";
import { ScreenState } from "@snackpass/snackpass-types";
import styled from "styled-components";

import colors from "#reusable/colors/colors.json";

// Reusable layout for desktop and mobile view

const divider = `1px solid ${colors["neutral-400"]}`;
const ContentWrapper = styled.div`
    padding: 0px 88px;
    @media ${ScreenState.MOBILE} {
        padding: 0px 16px;
    }
    display: flex;
    overflow-y: hidden;
    flex: 1;
    flex-direction: column;
    justify-content: "center";
`;

export const ScreenHeaderWrapper = styled.div`
    display: flex;
    @media ${ScreenState.MOBILE} {
        height: auto;
    }
    height: "120px";
    flex-direction: column;
    border-bottom: ${divider};
    @media ${ScreenState.MOBILE} {
        padding: 24px 16px 20px;
    }
    padding: 20px 88px;
`;

export const ScreenFooterWrapper = styled.div`
    padding: 12px 96px 0px 88px;
    border-top: ${divider};
    height: 50px;
    @media ${ScreenState.MOBILE} {
        height: auto;
        padding: 16px;
    }
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const SpinnerWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
`;

const OuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    @media ${ScreenState.MOBILE} {
        height: 100%;
    }
    height: calc(100% - 22px);
`;

interface ScreenLayoutProps {
    header: React.ReactElement;
    description?: React.ReactElement | null;
    content: React.ReactElement;
    footer?: React.ReactElement | null;
    isLoading?: boolean;
}

export const ScreenLayout = ({
    header,
    description,
    content,
    footer,
    isLoading
}: ScreenLayoutProps) =>
    isLoading ? (
        <SpinnerWrapper>
            <Spin size="large" />
        </SpinnerWrapper>
    ) : (
        <OuterWrapper>
            <ScreenHeaderWrapper>
                {header}
                {description}
            </ScreenHeaderWrapper>
            <ContentWrapper>{content}</ContentWrapper>
            {footer ? (
                <ScreenFooterWrapper>{footer}</ScreenFooterWrapper>
            ) : null}
        </OuterWrapper>
    );
