import { useFormContext } from "react-hook-form";
import { useRecoilValue } from "recoil";

import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "src/@/components/ui/form";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "src/@/components/ui/select";
import { sharableBrandRegistrationsAtom } from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationAtoms";

function SharedBrandRegistrationSharedSelect() {
    const { control } = useFormContext();
    const shareableBrands = useRecoilValue(sharableBrandRegistrationsAtom);
    return (
        <FormField
            control={control}
            name="foreignStoreId"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>Location to share registration with</FormLabel>
                    <Select onValueChange={field.onChange}>
                        <FormControl>
                            <SelectTrigger className="min-w-44 text-left">
                                <SelectValue placeholder="Select location" />
                            </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                            {shareableBrands.map((brand) => (
                                <SelectItem
                                    value={brand.storeId}
                                    key={brand.storeId}
                                >
                                    {/* After selection this normally clamps to a single line with small width. Min w 44 provides a bit extra space to render the whole display name */}
                                    <div className="min-w-44 text-left">
                                        <p className="font-semibold">
                                            {brand.displayName ?? ""}
                                            {brand.storeName
                                                ? ` - ${brand.storeName}`
                                                : ""}
                                        </p>
                                        <p className="text-small">
                                            Marketing phone number:{" "}
                                            {brand.phoneNumber}
                                        </p>
                                    </div>
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
}

export default SharedBrandRegistrationSharedSelect;
