import { useMemo } from "react";

import { useAppSelector } from "src/redux/hooks";
import { getActiveProductCategories } from "src/redux/selectors";

/**
 * Provides an alphabetically sorted list of categories with its associated products.
 */
export function useCategoriesWithProducts() {
    const legacyCategories = useAppSelector(getActiveProductCategories);

    return useMemo(
        () =>
            legacyCategories
                .map((category) => ({
                    id: category._id,
                    name: category.name,
                    productIds: category.productIds
                }))
                .sort((a, b) => a.name.localeCompare(b.name)),
        [legacyCategories]
    );
}
