import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { CSSProperties } from "react";

import { ReactComponent as BackArrow } from "src/assets/icons/left-arrow.svg";
import colors from "#reusable/colors/colors.json";

import { Text } from "../text/index";

type HeaderProps = {
    title: string;
    right?: React.ReactElement;
    goBack?: () => void;
};

export const MobileHeader: React.FC<HeaderProps> = ({
    title,
    right = null,
    goBack
}) => {
    const history = useHistory();
    return (
        <HeaderWrapper className="parent">
            <div className="left">
                {goBack ? (
                    <RoundButton onClick={goBack ?? history.goBack}>
                        <BackArrow />
                    </RoundButton>
                ) : null}
            </div>
            <Text size="l" weight="bold" className="center">
                {title}
            </Text>
            {right ? right : null}
            {goBack ? (
                <RoundButton style={commonStyles.visibilityHidden} />
            ) : null}
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-self: center;
    .center {
        align-items: center;
    }
`;

const commonStyles = {
    displayFlex: {
        display: "flex"
    } as CSSProperties,
    displayNone: { display: "none" } as CSSProperties,
    visibilityHidden: { visibility: "hidden" } as CSSProperties,
    fillParentHeight: { height: "100%" } as CSSProperties,
    fillAllWidth: { width: "100%" } as CSSProperties
};

const RoundButton = styled.button`
    width: 36px;
    height: 36px;
    border: 1px solid ${colors["neutral-400"]};
    border-radius: 48px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
`;
