import { ThemeProvider } from "styled-components";

import {
    AddNewDeviceModal,
    PageHeader,
    PageWrapper,
    theme
} from "#devices/components";
import DevicesProvider from "#devices/utils/DevicesPageContext";
import DetailsDrawer from "#devices/components/DetailsDrawer";
import EditDrawer from "#devices/components/EditDrawer";
import { DevicesTable } from "#devices/components/DevicesTable";
import ModalStyles from "#devices/components/AddNewDeviceModal/ModalStyles";
import AddonDrawer from "#devices/components/AddonDrawer";
import DeviceFilter from "#devices/components/DeviceFilter";

const Devices = (): JSX.Element => (
    <ThemeProvider theme={theme}>
        <ModalStyles />
        <DevicesProvider>
            <PageWrapper>
                <PageHeader />
                <DeviceFilter />
                <DevicesTable />
                <DetailsDrawer />
                <EditDrawer />
                <AddonDrawer />
                <AddNewDeviceModal />
            </PageWrapper>
        </DevicesProvider>
    </ThemeProvider>
);

export default Devices;
