/** @jsxImportSource @emotion/react */
import React, { SetStateAction } from "react";
import { Addon } from "@snackpass/snackpass-types";

import { Table } from "./modifier-options-table";
import { Column } from "react-table-7";

type Props = {
    addons: Addon[];
    setAddons: React.Dispatch<SetStateAction<Addon[]>>;
    modifierOptions: Addon[];
    setDeletedAddonIds: React.Dispatch<SetStateAction<string[]>>;
};
const ModifierOptions: React.FC<Props> = ({
    addons,
    setAddons,
    setDeletedAddonIds
}) => {
    const handleAddOnReorder = (newAddonGroups: Addon[]) => {
        setAddons && setAddons(newAddonGroups);
    };
    const columns: Column<Addon>[] = React.useMemo(
        () => [
            {
                Header: "name",
                accessor: "name"
            }
        ],
        []
    );

    return (
        <Table
            columns={columns}
            addons={addons}
            handleAddOnReorder={handleAddOnReorder}
            setAddons={setAddons}
            setDeletedAddonIds={setDeletedAddonIds}
        ></Table>
    );
};

export default ModifierOptions;
