import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { ScreenState } from "@snackpass/snackpass-types";
import { SystemColors } from "@snackpass/design-system";

import { FormFieldName } from "#promotion/components/shared/form-field-name";
import { FormDropdownSelect } from "#promotion/components/shared/form-dropdown-select";
import { defaultMenuItem } from "#promotion/utils/constants";
import { Options } from "#reusable/select/dropdown";
import { FormItemsMultiSelector } from "#promotion/components/shared/form-items-multi-selector";
import { FIELD_NAMES, QualifierValues } from "#promotion/utils/types";

import { FormItemSelector } from "./shared/form-items-selector";

type Props = {
    required?: boolean;
    recommended?: boolean;
    autofilled?: boolean;
    multiselect?: boolean;
    labeled?: boolean;
    multipleDiscountedItems?: boolean;
};

const QualifierOptions: Options[] = [
    { label: "Any item", value: QualifierValues.ANY_ITEM },
    { label: "Specific items or categories", value: QualifierValues.SPECIFIC }
];

export const QualifyingItems = ({
    required,
    recommended,
    autofilled,
    multiselect = true,
    labeled = true,
    multipleDiscountedItems = true
}: Props) => {
    const { watch, getFieldState, clearErrors, trigger, setValue } =
        useFormContext();
    const discountQualifier = watch(FIELD_NAMES.DISCOUNT_QUALIFIER);
    const { error } = getFieldState(FIELD_NAMES.DISCOUNTED_ITEMS);

    // Clear product related errors if user selects any item
    useEffect(() => {
        if (discountQualifier === QualifierValues.ANY_ITEM) {
            clearErrors(FIELD_NAMES.DISCOUNTED_ITEMS);
            setValue(FIELD_NAMES.DISCOUNTED_ITEMS, [defaultMenuItem]);
        }
        void trigger(FIELD_NAMES.DISCOUNTED_ITEMS);
    }, [discountQualifier]);

    return (
        <Container>
            <FormFieldName
                name="Qualifying Items"
                required={required}
                recommended={recommended}
                autofilled={autofilled}
            />
            {error ? <ErrorMessage>* {error?.message}</ErrorMessage> : null}
            <InputContainer>
                <FormDropdownSelect
                    options={QualifierOptions}
                    fieldName={FIELD_NAMES.DISCOUNT_QUALIFIER}
                />
            </InputContainer>
            {discountQualifier === QualifierValues.SPECIFIC ? (
                multiselect ? (
                    <FormItemsMultiSelector
                        fieldName={FIELD_NAMES.DISCOUNTED_ITEMS}
                        itemLabelDescription={
                            "Choose something that represents the discounted item. (ex. 1/2 Sandwich, Free Drink)"
                        }
                        itemLabelPlaceholder={"Discounted Entrée"}
                        addItemLabel={"Add Additional Discounted Item"}
                        labeled={labeled}
                        multipleDiscountedItems={multipleDiscountedItems}
                    />
                ) : (
                    <FormItemSelector
                        fieldName={FIELD_NAMES.DISCOUNTED_ITEMS}
                    />
                )
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 24px;
    @media ${ScreenState.MOBILE} {
        width: 100%;
    }
`;

const InputContainer = styled.div`
    display: flex;
    margin-top: 16px;
    align-items: center;
    gap: 16px;
    min-width: 100px;
    @media ${ScreenState.MOBILE} {
        width: 100%;
        flex-direction: column;
        gap: 0;
    }
`;

const ErrorMessage = styled.p`
    margin: 0;
    margin-top: 4px;
    color: ${SystemColors.v1.melon50};
    font-size: 14px;
`;
