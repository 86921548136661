import { Col, Form } from "antd";
import React from "react";
import { ITimeRangeSchema } from "@snackpass/snackpass-types";

import { Label } from "#onboarding/components/shared/Label";
import { SpecialHourRows as HourRows } from "#reusable/special-hours";
import { SpecialHoursItemType } from "#reusable/special-hours/helper";
import { FormFieldEnum } from "#settings/settings-business-info/types";

type FormInputProps = {
    localHours: SpecialHoursItemType[];
    setLocalHours: React.Dispatch<React.SetStateAction<SpecialHoursItemType[]>>;
    onChange: (hrs: ITimeRangeSchema[]) => void;
    field: string;
    label?: string;
    subtitle?: string;
    required?: boolean;
    omitRequired?: boolean;
    disabled?: boolean;
    noLabel?: boolean;
    span?: number;
};

export const FormHours = ({
    localHours,
    setLocalHours,
    onChange,
    field,
    label,
    subtitle,
    required = false,
    omitRequired = true,
    noLabel = false,
    span = 24
}: FormInputProps) => {
    const handleField = (f: string, v: unknown) => {
        onChange(v as ITimeRangeSchema[]);
    };

    return (
        <Col span={span}>
            <Form.Item
                name={field}
                label={
                    noLabel ? null : (
                        <Label
                            required={required}
                            subtitle={subtitle}
                            omitRequired={omitRequired}
                            label={label}
                        />
                    )
                }
            >
                <HourRows
                    hasHours={true}
                    localSpecialHours={localHours}
                    setLocalSpecialHours={setLocalHours}
                    fieldName={FormFieldEnum.local}
                    setFieldValue={handleField}
                />
            </Form.Item>
        </Col>
    );
};
