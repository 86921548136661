import * as React from "react";
import { Column } from "@tanstack/react-table";

import { FilterSelect } from "src/@/components/ui/filter-select";

interface DataTableFacetedFilter<TData, TValue> {
    column?: Column<TData, TValue>;
    title?: string;
    search?: boolean;
    options: {
        label: string;
        value: string;
        icon?: React.ComponentType<{ className?: string }>;
    }[];
}

export function DataTableFacetedFilter<TData, TValue>({
    column,
    title,
    search,
    options
}: DataTableFacetedFilter<TData, TValue>) {
    const facets = column?.getFacetedUniqueValues();
    const selectedValues = new Set(column?.getFilterValue() as string[]);

    const optionsWithFacetDescriptions = options.map((option) => ({
        ...option,
        description: facets?.get(option.value)?.toString() ?? undefined
    }));

    return (
        <FilterSelect
            options={optionsWithFacetDescriptions}
            selectedValues={selectedValues}
            title={title}
            search={search}
            onOptionSelected={(value: string) => {
                const isSelected = selectedValues.has(value);
                if (isSelected) {
                    selectedValues.delete(value);
                } else {
                    selectedValues.add(value);
                }
                const filterValues = Array.from(selectedValues);
                column?.setFilterValue(
                    filterValues.length ? filterValues : undefined
                );
            }}
            onClearOptions={() => column?.setFilterValue(undefined)}
        />
    );
}
