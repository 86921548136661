/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import { css } from "@emotion/react";
import { IPurchase } from "@snackpass/snackpass-types";
import _ from "lodash";
import { Checkbox, useCheckboxState } from "pretty-checkbox-react";

import ItemGrouping from "#order-details/components/purchase-items/item_grouping";
import { PurchaseItemGrouping } from "src/redux/Types";
import { groupedPurchaseItems } from "#order-details/components/purchase-items";

import "@djthoms/pretty-checkbox";
import { ReactComponent as CheckRoundSVG } from "src/assets/icons/check-round.svg";
import { filterNulls } from "src/utils/filterNulls";

type Props = {
    purchase: IPurchase;
    setPartialRefundBuffer: React.Dispatch<React.SetStateAction<string>>;
    setSelectedProductsIds: (items: string[]) => void;
};

export const Items = ({
    purchase,
    setPartialRefundBuffer,
    setSelectedProductsIds
}: Props) => {
    const checkbox = useCheckboxState({ state: [] });
    const items = groupedPurchaseItems(purchase.items);

    useEffect(() => {
        const arr = checkbox.state as string[];
        const selectedGroupUuids = new Set(arr);
        const updateRefundAmount = () => {
            const sum = items
                .map((item) =>
                    _.includes(arr, item.groupUuid)
                        ? item.totalPriceAfterDiscount
                        : 0
                )
                .reduce((total, price) => total + price);
            setPartialRefundBuffer(sum.toFixed(2).toString());
        };

        updateRefundAmount();
        setSelectedProductsIds(
            filterNulls(
                purchase.items.map((item) => {
                    if (
                        item.groupUuid &&
                        selectedGroupUuids.has(item.groupUuid)
                    ) {
                        return item.product._id;
                    }
                })
            )
        );
    }, [checkbox.state]);

    if (!purchase) {
        return <></>;
    }

    return (
        <>
            {items.map((itemGrouping: PurchaseItemGrouping, index: number) => (
                <div key={index} css={ItemStyles}>
                    {/* @ts-expect-error it appears that the package has a type error, try upgrading periodically */}
                    <Checkbox
                        {...checkbox}
                        variant="fill"
                        shape="curve"
                        value={itemGrouping.groupUuid}
                        key={itemGrouping.groupUuid}
                        plain
                        bigger
                        icon={<CheckRoundSVG className="svg" data-type="svg" />}
                        className="checkbox-choice"
                    ></Checkbox>
                    <div className="item-grouping">
                        <ItemGrouping itemGrouping={itemGrouping} />
                    </div>
                </div>
            ))}
        </>
    );
};

const ItemStyles = css`
    display: -webkit-inline-box;
    width: 100%;
    .checkbox-choice {
        margin: 0.5em 0 0.8em 0;
    }
    .item-grouping {
        width: 100%;
        padding-left: 13px;
    }
`;
