import { getActiveStore } from "@snackpass/accounting";
import {
    PaymentProvider,
    PurchaseReportData,
    PurchaseReportTransactionSource,
    PurchaseSummaryData,
    ThirdPartySource
} from "@snackpass/snackpass-types";
import { useMemo } from "react";
import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { client } from "src/api/rest/client";
import { useGlobalDate } from "#hooks";
import { usePaymentTypePicker } from "#hooks/use-payment-type-picker";
import { TRANSACTION_SOURCES } from "#pickers/transaction-source-picker/lib";
import {
    getDashboardReportsTransactionSource,
    getDashboardTransactionChannel,
    getDashboardTransactionChannelOptions
} from "src/redux/slices";
import { getUserTeamPermission } from "src/redux/selectors/user";
import { ErrorWithCause } from "src/utils/errors";

type CombinedPurchaseReportData = {
    rows?: PurchaseReportData[];
    days: PurchaseSummaryData[];
    payouts?: PurchaseSummaryData[];
    weeks: PurchaseSummaryData[];
    months: PurchaseSummaryData[];
    total: PurchaseSummaryData;
};

type PurchaseReportsQuery = {
    storeId: string;
    since: Date | null;
    until: Date | null;
    timezone?: string;
    includeActualPayout: boolean;
    transactionSources?: PurchaseReportTransactionSource[];
    transactionChannels?: ThirdPartySource[];
    paymentProviders?: PaymentProvider[];
    noRows?: boolean;
    onlyRows?: boolean;
    onlyCatering?: boolean;
    includePurchase?: boolean;
};

const nullPurchaseReports: CombinedPurchaseReportData = {
    rows: [],
    days: [],
    weeks: [],
    months: [],
    total: {
        granularity: "total",
        date: new Date().toISOString(),
        endDate: new Date().toISOString(),
        count: 0,
        total: 0,
        earnings: 0,
        netSales: 0,
        storeCredit: 0,
        subtotal: 0,
        customSurchargeTotal: 0,
        customDiscountsTotal: 0,
        tip: 0,
        upChargeAmount: 0,
        customerToStoreFees: 0,
        allCustomerToStoreFees: 0,
        customerToSnackpassFees: 0,
        processingFee: 0,
        commission: 0,
        snackpassFees: 0,
        delivery1PFee: 0,
        delivery3PFee: 0,
        faxFee: 0,
        storeTaxesToRemit: 0,
        storeTaxesWithheld: 0,
        store3PTaxesToRemit: 0,
        expectedCashCollected: 0,
        expectedPayout: 0,
        expected3PPayout: 0,
        tip3P: 0,
        scheduledFor: null,
        snackpassCredit: 0,
        inStorePaymentType: null,
        snackpassContribution: 0,
        customerToSnackpass3PDeliveryFee: 0,
        customerToSnackpassServiceFee: 0,
        customerFees: 0,
        customFees: 0,
        amountPaidByCustomer: 0,
        storeFeesCredited: 0,
        expectedInStoreCollections: 0,
        amountDueToStore: 0,
        payoutAdjustments: 0,
        storeFeesDebited: 0,
        giftCardCredit: 0,
        thirdPartyFees: 0,
        thirdPartyDiscount: 0
    }
};

const fetchPurchaseReports = async ({
    queryKey: [params, shouldNoOp]
}: QueryFunctionContext<
    [PurchaseReportsQuery, shouldNoOp: boolean]
>): Promise<CombinedPurchaseReportData | void> =>
    shouldNoOp
        ? nullPurchaseReports
        : client
              .get<CombinedPurchaseReportData>(
                  `/reports/store/${params.storeId}/combined-purchase-report`,
                  {
                      params
                  }
              )
              .then((res) => res.data)
              .catch(async (cause) => {
                  const fetchError = new ErrorWithCause(
                      "Failed to fetch combined purchase report data",
                      cause
                  );
                  throw fetchError;
              });

const withoutTime = (dateTime: Date | null): Date | null => {
    if (dateTime === null) return null;

    const date = new Date(dateTime);
    date.setUTCHours(0, 0, 0, 0);
    return date;
};

export const usePurchaseReports = (args?: Partial<PurchaseReportsQuery>) => {
    const store = useSelector(getActiveStore);
    // NOTE: We currently include actual payout for all team members. This could be made toggleable
    const includeActualPayout = useSelector(getUserTeamPermission);
    const { startDate, endDate } = useGlobalDate();
    const transactionSources = useSelector(
        getDashboardReportsTransactionSource
    );
    const { paymentProviders } = usePaymentTypePicker();
    const transactionChannels = useSelector(getDashboardTransactionChannel);

    // HACK: useGlobalDate() initially returns a series of non-adjusted dates
    //       until it stabilizes after the activeStore timezone is known.
    //       Ignore these here and return null reports.
    const shouldNoOp =
        startDate.milliseconds() !== 0 ||
        startDate.seconds() !== 0 ||
        startDate.minutes() !== 0;

    // TODO: This is likely being re-instantiated too often due to unstable values
    const keys: PurchaseReportsQuery = useMemo(
        () => ({
            storeId: store?._id ?? "not-set",
            since: withoutTime(startDate.toDate()),
            until: withoutTime(endDate.toDate()),
            includeActualPayout,
            transactionSources,
            transactionChannels,
            paymentProviders,
            ...args
        }),
        [
            store?._id,
            startDate,
            endDate,
            includeActualPayout,
            transactionSources,
            transactionChannels,
            paymentProviders,
            args
        ]
    );

    const queryKey: [PurchaseReportsQuery, shouldNoOp: boolean] = useMemo(
        () => [keys, shouldNoOp],
        [keys, shouldNoOp]
    );
    const options = useMemo(
        () => (shouldNoOp ? { staleTime: Infinity } : { retry: false }),
        [shouldNoOp]
    );

    const query = useQuery({
        queryKey,
        queryFn: fetchPurchaseReports,
        ...options
    });

    return useMemo(
        () => ({
            ...keys,
            ...query
        }),
        [keys, query]
    );
};

export const usePurchaseReportTotals = () => {
    const query = usePurchaseReports({ noRows: true });
    return {
        ...query,
        days: query.data?.days,
        weeks: query.data?.weeks,
        months: query.data?.months,
        payouts: query.data?.payouts,
        total: query.data?.total
    };
};

export const usePurchaseReportRows = () => {
    const query = usePurchaseReports({
        onlyRows: true,
        includePurchase: true
    });
    return {
        ...query,
        data: query.data?.rows
    };
};

export const usePurchaseFromReport = (purchaseId?: string) => {
    const query = usePurchaseReportRows();
    return {
        ...query,
        data: query.data?.find((row) => row.purchaseId === purchaseId)
    };
};

export const useCateringPurchaseReportRows = () => {
    const allOptions = useSelector(getDashboardTransactionChannelOptions);
    return usePurchaseReports({
        onlyRows: true,
        onlyCatering: true,
        includePurchase: true,
        // Override global filter settings to show all purchases
        transactionSources: TRANSACTION_SOURCES,
        transactionChannels: allOptions,
        paymentProviders: []
    });
};
