export enum DashboardPaymentTypes {
    ALL = "All",
    ALL_EXCEPT_CASH_AND_OTHER = "All Except Cash and Other",
    CASH_AND_OTHER = "Cash and Other"
}

export const DefinedPaymentTypes = [
    {
        label: "All",
        value: "All"
    },
    {
        label: "All Except Cash and Other",
        value: "All Except Cash and Other"
    },
    {
        label: "Cash and Other",
        value: "Cash and Other"
    }
];
