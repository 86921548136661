import { useCallback, useState } from "react";
import { z } from "zod";
import { IUserWithEmployee } from "@snackpass/snackpass-types";

import {
    AdminColumn,
    adminColSchema
} from "#settings/settings-permissions/types";

export type UserEmployeePermission = keyof NonNullable<
    IUserWithEmployee["permissions"]
>;

export const useFilteredData = (
    allAdminData: IUserWithEmployee[],
    activeStore: { _id: string } | null,
    setTableData: (data: AdminColumn[]) => void
) => {
    const [filteredJobTitle, setFilteredJobTitle] = useState<Set<string>>(
        new Set()
    );
    const [jobTitleOptions, setJobTitleOptions] = useState([
        { value: "", label: "" }
    ]);
    const [filteredPermissions, setFilteredPermissions] = useState<
        Set<UserEmployeePermission>
    >(new Set());

    const onFilteredPermissionsSelected = useCallback(
        (value: UserEmployeePermission) => {
            const newSet = new Set<UserEmployeePermission>(filteredPermissions);
            if (newSet.has(value)) {
                newSet.delete(value);
            } else {
                newSet.add(value);
            }
            setFilteredPermissions(newSet);
            setFilteredJobTitle(new Set());
            const filteredData: IUserWithEmployee[] = [];
            if (newSet.size > 0) {
                allAdminData.forEach((admin: IUserWithEmployee) => {
                    const hasAllPermissions = Array.from(newSet).every(
                        (perm: UserEmployeePermission) =>
                            admin.permissions &&
                            (admin.permissions[perm] as string).includes(
                                activeStore?._id ?? ""
                            )
                    );
                    if (hasAllPermissions) {
                        filteredData.push(admin);
                    }
                });
                setTableData(z.array(adminColSchema).parse(filteredData));
            } else {
                setTableData(z.array(adminColSchema).parse(allAdminData));
            }
        },
        [
            filteredPermissions,
            setFilteredPermissions,
            allAdminData,
            activeStore,
            setTableData
        ]
    );

    const onFilteredJobTitleSelected = useCallback(
        (value: string) => {
            const newSet = new Set(filteredJobTitle);
            if (newSet.has(value)) {
                newSet.delete(value);
            } else {
                newSet.add(value);
            }
            setFilteredJobTitle(newSet);
            setFilteredPermissions(new Set());
            if (newSet.size > 0) {
                const filteredData: IUserWithEmployee[] = [];
                allAdminData.forEach((admin) => {
                    for (const roleEntry of admin.snackpassPermissions
                        .storeRoles) {
                        if (
                            roleEntry.storeId === activeStore?._id &&
                            newSet.has(roleEntry.role)
                        ) {
                            filteredData.push(admin);
                            return;
                        }
                    }
                });
                setTableData(z.array(adminColSchema).parse(filteredData));
            } else {
                setTableData(z.array(adminColSchema).parse(allAdminData));
            }
        },
        [
            filteredJobTitle,
            setFilteredJobTitle,
            allAdminData,
            activeStore,
            setTableData
        ]
    );

    return {
        jobTitleOptions,
        setJobTitleOptions,
        filteredJobTitle,
        setFilteredJobTitle,
        filteredPermissions,
        setFilteredPermissions,
        onFilteredJobTitleSelected,
        onFilteredPermissionsSelected
    };
};
