import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { ReactComponent as BackArrow } from "src/assets/icons/left-arrow.svg";
import { RoundButton } from "#menu-editor/multi-menus/styled-components/button";
import { Text } from "#menu-editor/multi-menus/styled-components/text";
import { commonStyles } from "#menu-editor/multi-menus/helpers/styles";

type HeaderProps = {
    title: string;
    right?: React.ReactElement;
    goBack?: () => void;
};

export const MobileHeader: React.FC<HeaderProps> = ({
    title,
    right = null,
    goBack
}) => {
    const history = useHistory();
    return (
        <HeaderWrapper className="parent">
            <div className="left">
                <RoundButton onClick={goBack ?? history.goBack}>
                    <BackArrow />
                </RoundButton>
            </div>
            <Text type="title-mobile" className="center">
                {title}
            </Text>
            {right ? (
                right
            ) : (
                // hidden button that occupies the same space as left button
                <RoundButton style={commonStyles.visibilityHidden} />
            )}
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    .center {
        align-items: center;
    }
`;
