import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Text from "#devices/components/Text";
import { Button } from "src/@/components/ui/button";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { DevicesModal } from "#devices/components/Modal";

type UnsavedChangesModalProps = {
    handleClose: () => void;
    handleSave: (b: boolean) => void;
    isOpen: boolean;
};

const UnsavedChangesModal = ({
    handleClose,
    handleSave,
    isOpen
}: UnsavedChangesModalProps): JSX.Element => {
    const returnToEdit = () => handleSave(false);

    const { isDesktop } = useWindowDimensions();
    return (
        <DevicesModal
            handleClose={handleClose}
            footer={
                <Container fluid>
                    <Row>
                        <Col xs={6}>
                            <Button
                                variant="outline"
                                className="w-full"
                                onClick={handleClose}
                            >
                                {isDesktop ? "Discard Changes" : "Discard"}
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button className="w-full" onClick={returnToEdit}>
                                {isDesktop ? "Go Back to Edit" : "Back to Edit"}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            }
            visible={isOpen}
        >
            <Text.Title2>You have unsaved changes</Text.Title2>
            <Text.Body>
                Are you sure you want to leave this screen and discard changes
                made to this device?
            </Text.Body>
        </DevicesModal>
    );
};

export default UnsavedChangesModal;
