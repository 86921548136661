import { SystemColors } from "@snackpass/design-system";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { show } from "redux-modal";
import { CompositeDecorator } from "draft-js";
import "draft-js/dist/Draft.css";

import { compose, keyBy } from "lodash/fp";
import styled from "@emotion/styled";

import { HANDLEBARS_REGEX } from "#guestbook/components/templates/constants";

import { BlackPopover } from "../styled-components";
import { getMessageInputVariables, TemplateVariableType } from "../../redux";

function findWithRegex(regex: RegExp, contentBlock: any, callback: any) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
    }
}

function handlebarsStrategy(
    contentBlock: any,
    callback: any,
    contentState: any
) {
    findWithRegex(HANDLEBARS_REGEX, contentBlock, callback);
}

const TypeToModal: Record<string, string> = {
    [TemplateVariableType.Product]: "MenuDrawer",
    [TemplateVariableType.Reward]: "IncentivesDrawer"
};

const HandleSpan = (props: any) => {
    const dispatch = useDispatch();
    const showDrawer = compose(dispatch, show);
    const variables = useSelector(getMessageInputVariables);
    const decoratedId = props.decoratedText?.replace(/[{}]/g, "");

    // create a mapping of the variables in redux and then replace
    // the rendered text in the component to be the item
    // so that it acts as a preview
    const variableMapping = React.useMemo(
        () => keyBy("id", variables),
        [variables]
    );

    const variableValue = variableMapping[decoratedId];
    const value =
        variableValue?.data?.label ||
        variableValue?.placeholder ||
        props.decoratedText;

    const onPickReward = useCallback(() => {
        const drawerName = TypeToModal[variableValue.type];

        showDrawer(drawerName, {
            variableId: decoratedId
        });
    }, [variableValue, decoratedId]);

    return (
        <BlackPopover onClick={onPickReward} label=" Tap to select item">
            <PopoverLabel {...props}>{value}</PopoverLabel>
        </BlackPopover>
    );
};

const PopoverLabel = styled.span`
    color: ${SystemColors.v1.candy50};
    font-weight: bold;
`;

export const DECORATORS = new CompositeDecorator([
    {
        strategy: handlebarsStrategy,
        component: HandleSpan
    }
]);
