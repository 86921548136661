import { useState, useEffect } from "react";
import { ChainFeatures, IStore } from "@snackpass/snackpass-types";

import api from "src/api/rest";
import { sendError } from "src/utils/errors";

type ChainStores = {
    id: string;
    name: string;
};

type UseChainStoresResult = {
    giftCardChainStores: ChainStores[];
    isLoadingGiftCardChainStores: boolean;
};

const useGiftCardChainStores = (
    chainId: string | null | undefined,
    activeStore: IStore | null
): UseChainStoresResult => {
    const [giftCardChainStores, setGiftCardChainStores] = useState<
        ChainStores[]
    >([]);
    const [isLoadingGiftCardChainStores, setIsLoadingGiftCardChainStores] =
        useState<boolean>(false);

    useEffect(() => {
        const chainConfigGiftcard = activeStore?.chainConfig?.find(
            (config) => config.feature === ChainFeatures.ChainWideGiftCard
        );
        if (chainConfigGiftcard == null) {
            // If your store does not have chain wide gift card enabled, then the active store does not share a chain with
            // any of the other stores in the chain even if they have chain gift card enabled, so it can be simplified as being treated
            // as a non-chain store.
            setGiftCardChainStores([]);
            return;
        }
        if (chainId) {
            setIsLoadingGiftCardChainStores(true);
            api.stores
                .getChainStores(chainId)
                .then(({ data }) => {
                    setGiftCardChainStores(
                        data.stores
                            .filter((store: IStore) =>
                                store?.chainConfig
                                    ? store?.chainConfig.some(
                                          (config) =>
                                              config.feature ===
                                              ChainFeatures.ChainWideGiftCard
                                      )
                                    : false
                            )
                            .map((store: IStore) => ({
                                id: store._id,
                                name: store.name
                            }))
                    );
                })
                .catch((err) => {
                    sendError(err);
                })
                .finally(() => setIsLoadingGiftCardChainStores(false));
        }
    }, [chainId, activeStore?._id]);

    return { giftCardChainStores, isLoadingGiftCardChainStores };
};

export default useGiftCardChainStores;
