import React from "react";
import { Col, Form } from "antd";
import { UploadFile as IUploadFile } from "antd/lib/upload";
import styled from "styled-components";

import { Label } from "#onboarding/components/shared/Label";
import { useCloudinaryUploadWidget } from "#reusable/upload/useCloudinaryUploadWidget";
import colors from "#reusable/colors/colors.json";
import { ReactComponent as MediaIcon } from "src/assets/icons/media.svg";
import { descriptions } from "#onboarding/utils/descriptions";
import { Button } from "src/@/components/ui/button";
import { cn } from "src/@/lib/utils";
import constants from "#core/constants";
import { Text } from "#reusable/text/index";
import Switch from "#reusable/input/toggle-input";

type FormInputProps = {
    value?: string;
    field: string;
    label: string;
    subtitle?: string;
    required?: boolean;
    disabled?: boolean;
    croppingAspectRatio?: number;
    height?: string;
    width?: string;
    onChange: (url: string) => void;
    accept?: string;
    fileList?: IUploadFile[];
    isRounded?: boolean;
    isKioskSplashVideo?: boolean;
    hasOverlayText?: boolean;
    onOverlayTextChange?: (val: boolean) => void;
};

const MB20 = 20000000; // 20MB
const MB30 = 31457280; // 30MB

export const FormFile = ({
    value,
    field,
    label,
    subtitle,
    required = false,
    disabled = false,
    croppingAspectRatio = undefined,
    height,
    width,
    onChange,
    isRounded,
    isKioskSplashVideo,
    hasOverlayText,
    onOverlayTextChange
}: FormInputProps) => {
    const { UploadButton } = useCloudinaryUploadWidget({
        preset: isKioskSplashVideo
            ? "rdb-kiosk-attract-screen"
            : "images-store",
        folder: isKioskSplashVideo ? "rdb-assets" : "store-images",
        clientAllowedFormats: isKioskSplashVideo
            ? ["mp4", "mov"]
            : ["jpg", "png"],
        maxImageFileSize: isKioskSplashVideo ? MB30 : MB20
    });

    return (
        <>
            <Col span={24}>
                <Form.Item
                    name={field}
                    label={
                        <Label
                            inline
                            required={required}
                            label={label}
                            subtitle={subtitle}
                        />
                    }
                >
                    <Container
                        imageHeight={height}
                        imageWidth={width}
                        url={value}
                    >
                        {isKioskSplashVideo ? (
                            <div className="relative my-2 flex w-full max-w-[360px] flex-col items-center gap-3">
                                <VideoContainer className="relative h-0 w-4/5 md:w-full">
                                    <video
                                        className="absolute left-0 top-0 h-full w-full"
                                        controls
                                        autoPlay
                                        muted
                                        loop
                                        playsInline
                                        src={
                                            value
                                                ? value
                                                : constants.DEFAULT_ATTRACT_SCREEN_URL
                                        }
                                    ></video>
                                </VideoContainer>
                            </div>
                        ) : (
                            <div className="flex max-w-[360px]">
                                <ImageContainer
                                    // max-width provides an 104:29 aspect ratio that we use in OO
                                    className={cn([
                                        "mx-auto my-2",
                                        height ? `h-[${height}]` : "",
                                        width ? `w-[${width}]` : "w-full",
                                        isRounded
                                            ? "w-[200px] h-[200px] rounded-full"
                                            : ""
                                    ])}
                                    url={value ? value : ""}
                                    style={{
                                        backgroundImage: `url(${value})`
                                    }}
                                >
                                    {value ? null : (
                                        <MediaIcon
                                            width={33}
                                            height={33}
                                            fill={colors["neutral-600"]}
                                        />
                                    )}
                                </ImageContainer>
                            </div>
                        )}
                        <div className="mt-2 flex w-full max-w-[360px] items-center justify-between gap-2">
                            <div className={value ? "w-4/5" : "w-full"}>
                                <UploadButton
                                    cropping
                                    croppingAspectRatio={croppingAspectRatio}
                                    disabled={disabled}
                                    onUpload={(info: {
                                        secure_url: string;
                                    }) => {
                                        onChange(info.secure_url);
                                    }}
                                >
                                    {value?.length
                                        ? isKioskSplashVideo
                                            ? descriptions.buttons.changeVideo
                                            : descriptions.buttons.changeImage
                                        : isKioskSplashVideo
                                          ? descriptions.buttons.addVideo
                                          : descriptions.buttons.addImage}
                                </UploadButton>
                            </div>
                            {value ? (
                                <Button
                                    onClick={() => onChange("")}
                                    variant={"destructive"}
                                >
                                    Remove
                                </Button>
                            ) : null}
                        </div>
                        {isKioskSplashVideo ? (
                            <div className="mt-2 flex grow-0 flex-col">
                                <ul>
                                    <Text size="m" weight="bold">
                                        Requirements:
                                    </Text>
                                    {constants.kioskSplashVideoRequirementText.map(
                                        (text, i) => (
                                            <li key={i}>
                                                <Text size="s" weight="thin">
                                                    {text}
                                                </Text>
                                            </li>
                                        )
                                    )}
                                </ul>
                                <ul>
                                    <Text size="m" weight="bold">
                                        Recommendations:
                                    </Text>
                                    {constants.kioskSplashVideoRecommendationText.map(
                                        (text, i) => (
                                            <li key={i}>
                                                <Text size="s" weight="thin">
                                                    {text}
                                                </Text>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        ) : null}
                    </Container>
                </Form.Item>
                {isKioskSplashVideo &&
                value &&
                value !== constants.DEFAULT_ATTRACT_SCREEN_URL ? (
                    <div className="mx-4 flex w-full flex-row">
                        <Form.Item
                            className="flex w-full flex-row"
                            name="hasOverlayText"
                            label={
                                <Label
                                    inline
                                    label="Overlay Text"
                                    subtitle="Add “Tap to Order” text overlay to Kiosk Splash Screen"
                                />
                            }
                        >
                            <Switch
                                id="hasBrandSplashOverlayText-onboarding"
                                isOn={hasOverlayText ?? false}
                                onChange={(checked: boolean) => {
                                    onOverlayTextChange &&
                                        onOverlayTextChange(checked);
                                }}
                            />
                        </Form.Item>
                    </div>
                ) : null}
            </Col>
        </>
    );
};

const Container = styled.div<{
    url?: string;
    imageHeight?: string;
    imageWidth?: string;
}>`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    justify-content: flex-start;

    .cloudinary-button {
        font: 16px Inter;
        color: black;
        margin: 0 auto;
        margin-left: 0;
    }
`;

type ImageProps = {
    url: string;
};

const ImageContainer = styled.div<ImageProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    border-radius: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${colors["neutral-200"]};
`;

const VideoContainer = styled.div`
    padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%);
`;
