import { Row } from "antd";
import styled from "styled-components";

import { ReactComponent as ChevronDown } from "src/assets/icons/chevron-down.svg";
import { Text } from "#menu-editor/multi-menus/styled-components/text";

type ItemHeaderProps = {
    getToggleAllRowsExpandedProps: Function;
    toggleAllRowsExpanded: (isExpanded?: boolean) => void;
    areAnyExpanded: boolean;
};

export const ItemHeader: React.FC<ItemHeaderProps> = ({
    getToggleAllRowsExpandedProps,
    toggleAllRowsExpanded,
    areAnyExpanded
}) => (
    <ItemHeaderRow align="middle">
        <Text type="body-bold" style={{ marginRight: "7px" }}>
            Item
        </Text>
        <span
            {...getToggleAllRowsExpandedProps()}
            onClick={() => toggleAllRowsExpanded(!areAnyExpanded)}
        >
            <ExpanderIconStyles isExpanded={areAnyExpanded}>
                <ChevronDown width={10} />
            </ExpanderIconStyles>
        </span>
    </ItemHeaderRow>
);

const ItemHeaderRow = styled(Row)`
    padding: 8px 4px 8px 16px;
`;

// Trying to style ChevronIcon component directly causes the app tests to fail :|
const ExpanderIconStyles = styled.span<{ isExpanded: boolean }>`
    > svg {
        transition: 0.3s;
        transform: ${({ isExpanded }) =>
            isExpanded ? undefined : "rotate(-0.25turn)"};
    }
`;
