import { DateTime } from "luxon";
import { useContext } from "react";

import { StyledAlert } from "#payouts/components/shared/Alert";
import { Page } from "#payouts/components/shared/Page";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { getVerificationWarningStatus } from "#payouts/utils/get-verification-warning-status";

const EnabledComponent = () => (
    <StyledAlert
        message={enabledAlert.message}
        description={enabledAlert.description}
        type="info"
    />
);

const EnabledWithFutureRequirementsComponent = ({
    dueDate
}: {
    dueDate?: number;
}) => {
    const dateFrom = dueDate ? DateTime.fromSeconds(dueDate) : DateTime.now();
    const date = dateFrom.setZone("UTC").toLocaleString(DateTime.DATE_MED);
    return (
        <StyledAlert
            message={enabledAlert.message}
            description={`More information required by ${date}. See below.`}
            type="warning"
        />
    );
};

const EnabledWithFutureRequirementsSupportComponent = ({
    dueDate
}: {
    dueDate?: number;
}) => {
    const dateFrom = dueDate ? DateTime.fromSeconds(dueDate) : DateTime.now();
    const date = dateFrom.setZone("UTC").toLocaleString(DateTime.DATE_MED);
    return (
        <StyledAlert
            message={enabledAlert.message}
            description={`More information required by ${date}. Please contact support to resolve.`}
            type="warning"
        />
    );
};

const EnabledWithFutureEventualRequirementsComponent = () => (
    <StyledAlert
        message={enabledAlert.message}
        description="More information will be required in the future. See below."
        type="warning"
    />
);

const EnabledWithFutureEventualRequirementsSupportComponent = () => (
    <StyledAlert
        message={enabledAlert.message}
        description="More information will be required in the future. Please contact support to resolve."
        type="warning"
    />
);

const AdminPausedComponent = ({ adminNote }: { adminNote?: string | null }) => (
    <StyledAlert
        message={adminHoldAlert.message}
        description={adminNote ?? adminHoldAlert.description}
        type="warning"
    />
);

const PendingComponent = () => (
    <StyledAlert
        message={pendingAlert.message}
        description={pendingAlert.description}
        type="warning"
    />
);

const EnabledWithCurrentRequirementsComponent = ({
    dueDate
}: {
    dueDate?: number;
}) => {
    const dateFrom = dueDate ? DateTime.fromSeconds(dueDate) : DateTime.now();
    const date = dateFrom.setZone("UTC").toLocaleString(DateTime.DATE_MED);
    return (
        <StyledAlert
            message={enabledAlert.message}
            description={`More information required by ${date}. See below.`}
            type="warning"
        />
    );
};

const EnabledWithCurrentRequirementsSupportComponent = ({
    dueDate
}: {
    dueDate?: number;
}) => {
    const dateFrom = dueDate ? DateTime.fromSeconds(dueDate) : DateTime.now();
    const date = dateFrom.setZone("UTC").toLocaleString(DateTime.DATE_MED);
    return (
        <StyledAlert
            message={enabledAlert.message}
            description={`More information required by ${date}. Please contact support to resolve.`}
            type="warning"
        />
    );
};

const EnabledWithEventualRequirementsComponent = () => (
    <StyledAlert
        message={enabledAlert.message}
        description="More information will be required in the future. See below."
        type="warning"
    />
);

const EnabledWithEventualRequirementsSupportComponent = () => (
    <StyledAlert
        message={enabledAlert.message}
        description="More information will be required in the future. Please contact support to resolve."
        type="warning"
    />
);

const ErrorComponent = () => (
    <StyledAlert
        message={pausedAlert.message}
        description={pausedAlert.description}
        type="error"
    />
);

const ErrorSupportComponent = () => (
    <StyledAlert
        message={pausedSupportAlert.message}
        description={pausedSupportAlert.description}
        type="error"
    />
);

export const StatusAlert = () => {
    const { storeId, verification } = useContext(PayoutsSettingsContext);
    if (!verification || !storeId) return null;

    const warningStatus = getVerificationWarningStatus(verification);

    const component = ((status: typeof warningStatus) => {
        switch (status) {
            case "none":
                return <EnabledComponent />;
            case "warning_future_requirements":
                return (
                    <EnabledWithFutureRequirementsComponent
                        dueDate={verification.futureDueDate}
                    />
                );
            case "warning_future_requirements_support":
                return (
                    <EnabledWithFutureRequirementsSupportComponent
                        dueDate={verification.futureDueDate}
                    />
                );
            case "warning_future_eventual_requirements":
                return <EnabledWithFutureEventualRequirementsComponent />;
            case "warning_future_eventual_requirements_support":
                return (
                    <EnabledWithFutureEventualRequirementsSupportComponent />
                );
            case "warning_admin_pause":
                return (
                    <AdminPausedComponent adminNote={verification.adminNote} />
                );
            case "warning_pending":
                return <PendingComponent />;
            case "warning_eventual_requirements":
                return <EnabledWithEventualRequirementsComponent />;
            case "warning_eventual_requirements_support":
                return <EnabledWithEventualRequirementsSupportComponent />;
            case "warning_current_requirements":
                return (
                    <EnabledWithCurrentRequirementsComponent
                        dueDate={verification.currentDueDate}
                    />
                );
            case "warning_current_requirements_support":
                return (
                    <EnabledWithCurrentRequirementsSupportComponent
                        dueDate={verification.currentDueDate}
                    />
                );
            case "error":
                return <ErrorComponent />;
            case "error_support":
                return <ErrorSupportComponent />;
        }
    })(warningStatus);

    return (
        <Page noVerticalMargin>
            <div className="my-auto flex flex-col justify-center">
                {component}
            </div>
        </Page>
    );
};

const enabledAlert = {
    message: "Payouts Enabled:",
    description: "Information has been verified."
};

const pendingAlert = {
    message: "Pending Review:",
    description: "Your submission is being reviewed."
};

const pausedAlert = {
    message: "Payouts Paused:",
    description: "Please complete all sections to resume payouts."
};

const pausedSupportAlert = {
    message: "Payouts Paused:",
    description: "Please contact support to resume payouts."
};

const adminHoldAlert = {
    message: "Payouts Paused:",
    description: "Payouts placed on hold by Snackpass Admin."
};
