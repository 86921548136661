import { Table } from "antd";
import React, { useContext, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

import { addTableChannelDataRows } from "#reports/sales-channels/lib";
import DownloadButton from "#reports/sales-summary/shared-components/DownloadButton";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";
import { ReportsContext } from "#app/reports-context-provider";
import {
    aggregateDatedRows,
    formatNumber,
    formatRange,
    toDollarFormatted
} from "#reports/sales-summary/lib";
import { getActiveStore } from "src/redux/selectors";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";

interface TableColumn {
    title: string;
    dataIndex: string;
    render?: (_: string, row: TableRow) => JSX.Element;
    key: string;
}
interface TableRow {
    [key: string]: string;
}

const tooltips: { [key: string]: string } = {
    Sales: "The subtotal of all sales plus any fees customers pay to your store such as service fees and bag fees.",
    Taxes: "Taxes you are responsible for remitting to the government. Your payout will include these. Check with third party platforms concerning taxes they may have remitted for you. "
};

const SPACER_CLASS_NAME =
    "bg-neutral-300 font-bold [&>.ant-table-cell]:py-1 [&>.ant-table-cell]:bg-neutral-300";

const ChannelReportTable = () => {
    const activeStore = useSelector(getActiveStore);

    const { reportsState } = useContext(ReportsContext);
    const { granularity, dateRanges } = reportsState;

    const renderRowTitle = (_: string, row: TableRow) => (
        <div className={clsx(row.isSubRow && "px-3")}>
            {row.title}
            {tooltips[row.title] && (
                <ReportsTooltip body={tooltips[row.title]} />
            )}
        </div>
    );

    const data = useMemo(
        () =>
            reportsState.channelsData?.salesChannelReportData
                ? aggregateDatedRows(
                      reportsState.channelsData.salesChannelReportData,
                      addTableChannelDataRows,
                      granularity
                  )
                : null,
        [reportsState, granularity]
    );

    const columns: TableColumn[] = useMemo(
        () =>
            data
                ? [
                      {
                          title: "",
                          render: renderRowTitle,
                          dataIndex: "title",
                          key: "title",
                          fixed: isMobile ? false : true
                      },
                      ...data.map((e) => ({
                          title: e.date,
                          dataIndex: e.date,
                          key: e.date
                      }))
                  ]
                : [],
        [data]
    );

    /*
    For this component, where it won't be uncommon for there to be more properties than dates that users will be looking at,
    we want to show dates as column headers, rendering each data row returned as a column.

    Creating a "vertical table" where data is rendered column by column is not typically supported by popular table libraries,
    as it is not a common use case.

    Because the table component requires that data be provided row by row and we want to show dates as the column headers,
    we must transpose the data from {date, property1, property2, ...} to {property1, date1:value, date2:value, ...}
    */

    const rows: TableRow[] = useMemo(() => {
        if (!data) return [];

        const dataTransposed = {
            netOrders: data.map((e) => ({
                date: e.date,
                value: formatNumber(e.orders.total),
                className: "font-bold",
                title: "Orders"
            })),
            snackpassOrders: data.map((e) => ({
                date: e.date,
                value: formatNumber(e.orders.snackpass),
                title: "Snackpass",
                isSubRow: true
            })),
            uberEatsOrders: data.map((e) => ({
                date: e.date,
                value: formatNumber(e.orders.uberEats),
                title: "Uber Eats",
                isSubRow: true
            })),
            doordashOrders: data.map((e) => ({
                date: e.date,
                value: formatNumber(e.orders.doordash),
                title: "DoorDash",
                isSubRow: true
            })),
            postmatesOrders: data.map((e) => ({
                date: e.date,
                value: formatNumber(e.orders.uberPostmates),
                title: "Postmates",
                isSubRow: true
            })),
            fantuanOrders: data.map((e) => ({
                date: e.date,
                value: formatNumber(e.orders.fantuan),
                title: "Fantuan",
                isSubRow: true
            })),
            easiOrders: data.map((e) => ({
                date: e.date,
                value: formatNumber(e.orders.easi),
                title: "Easi",
                isSubRow: true
            })),
            hungryPandaOrders: data.map((e) => ({
                date: e.date,
                value: formatNumber(e.orders.hungrypanda),
                title: "HungryPanda",
                isSubRow: true
            })),
            otherOrders: data.map((e) => ({
                date: e.date,
                value: formatNumber(e.orders.other),
                title: "Other",
                isSubRow: true
            })),

            spacer: [{ title: "", className: SPACER_CLASS_NAME }],

            netSales: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.netSales.total)}`,
                title: "Sales",
                className: "font-bold"
            })),
            snackpassSales: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.netSales.snackpass)}`,
                title: "Snackpass",
                isSubRow: true
            })),
            uberEatsSales: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.netSales.uberEats)}`,
                title: "Uber Eats",
                isSubRow: true
            })),
            doordashSales: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.netSales.doordash)}`,
                title: "DoorDash",
                isSubRow: true
            })),
            postmatesSales: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.netSales.uberPostmates)}`,
                title: "Postmates",
                isSubRow: true
            })),
            fantuanSales: data.map((e) => ({
                date: e.date,
                value: e.netSales.fantuan,
                title: "Fantuan",
                isSubRow: true
            })),
            easiSales: data.map((e) => ({
                date: e.date,
                value: toDollarFormatted(e.netSales.easi),
                title: "Easi",
                isSubRow: true
            })),
            hungryPandaSales: data.map((e) => ({
                date: e.date,
                value: toDollarFormatted(e.netSales.hungrypanda),
                title: "HungryPanda",
                isSubRow: true
            })),
            otherSales: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.netSales.other)}`,
                title: "Other",
                isSubRow: true
            })),

            spacer1: [{ title: "", className: SPACER_CLASS_NAME }],

            netTips: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.tips.total)}`,
                className: "font-bold",
                title: "Tips"
            })),
            snackpassTips: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.tips.snackpass)}`,
                title: "Snackpass",
                isSubRow: true
            })),
            uberEatsTips: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.tips.uberEats)}`,
                title: "Uber Eats",
                isSubRow: true
            })),
            doordashTips: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.tips.doordash)}`,
                title: "DoorDash",
                isSubRow: true
            })),
            postmatesTips: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.tips.uberPostmates)}`,
                title: "Postmates",
                isSubRow: true
            })),
            fantuanTips: data.map((e) => ({
                date: e.date,
                value: toDollarFormatted(e.tips.fantuan),
                title: "Fantuan",
                isSubRow: true
            })),
            easiTips: data.map((e) => ({
                date: e.date,
                value: toDollarFormatted(e.tips.easi),
                title: "Easi",
                isSubRow: true
            })),
            hungryPandaTips: data.map((e) => ({
                date: e.date,
                value: toDollarFormatted(e.tips.hungrypanda),
                title: "HungryPanda",
                isSubRow: true
            })),
            otherTips: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.tips.other)}`,
                title: "Other",
                isSubRow: true
            })),

            spacer2: [{ title: "", className: SPACER_CLASS_NAME }],

            netTaxesYouOwe: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.taxesYouOwe.total)}`,
                className: "font-bold",
                title: "Taxes"
            })),
            snackpassTaxesYouOwe: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.taxesYouOwe.snackpass)}`,
                title: "Snackpass",
                isSubRow: true
            })),
            uberEatsTaxesYouOwe: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.taxesYouOwe.uberEats)}`,
                title: "Uber Eats",
                isSubRow: true
            })),
            doordashTaxesYouOwe: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.taxesYouOwe.doordash)}`,
                title: "DoorDash",
                isSubRow: true
            })),
            postmatesTaxesYouOwe: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.taxesYouOwe.uberPostmates)}`,
                title: "Postmates",
                isSubRow: true
            })),
            fantuanTaxesYouOwe: data.map((e) => ({
                date: e.date,
                value: e.taxesYouOwe.fantuan,
                title: "Fantuan",
                isSubRow: true
            })),
            easiTaxesYouOwe: data.map((e) => ({
                date: e.date,
                value: e.taxesYouOwe.easi,
                title: "Easi",
                isSubRow: true
            })),
            hungryPandaTaxesYouOwe: data.map((e) => ({
                date: e.date,
                value: e.taxesYouOwe.hungrypanda,
                title: "HungryPanda",
                isSubRow: true
            })),
            otherTaxesYouOwe: data.map((e) => ({
                date: e.date,
                value: `${toDollarFormatted(e.taxesYouOwe.other)}`,
                title: "Other",
                isSubRow: true
            }))
        };

        return Object.entries(dataTransposed)
            .filter(([key, data]) =>
                (
                    data as {
                        value: string;
                        title: string;
                        isSubRow?: boolean;
                    }[]
                ).find(
                    (e) =>
                        !e.isSubRow ||
                        (e.value &&
                            e.value != toDollarFormatted(0) &&
                            e.value != "0")
                )
            )
            .map(([key, data]) => ({
                // Ignoring here to allow us to assign by string.
                ...data.reduce((acc, curr) => {
                    // @ts-expect-error
                    acc[curr.date] = curr.value;
                    // @ts-expect-error
                    acc.className = curr.className;
                    // @ts-expect-error
                    acc.title = curr.title;
                    // @ts-expect-error
                    acc.isSubRow = curr.isSubRow;
                    return acc;
                }, {})
            }));
    }, [data]);

    const getRowClassName = (record: TableRow) => record.className;

    return (
        <div>
            <div className="mb-4 flex items-center justify-between">
                <h4 className="text-large">Channel Breakdown</h4>
                <DownloadButton
                    rows={rows}
                    columns={columns}
                    filename={`${
                        activeStore?.name
                    } Sales Channels Breakdown ${granularity} ${formatRange(
                        dateRanges[0],
                        granularity
                    )}`}
                />
            </div>
            <div className="overflow-x-scroll">
                {!reportsState.channelsData?.salesChannelDataLoading &&
                (reportsState.channelsData?.salesChannelReportData ||
                    reportsState.channelsData?.salesChannelDataFailed) ? (
                    reportsState.channelsData?.salesChannelReportData ? (
                        <Table
                            className="whitespace-nowrap"
                            columns={columns}
                            dataSource={rows}
                            pagination={false}
                            rowClassName={getRowClassName}
                            scroll={{ x: true }}
                            sticky
                        />
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

export default ChannelReportTable;
