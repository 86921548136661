import { IStore } from "@snackpass/snackpass-types";

export enum OnboardingStep {
    Summary = "summary",
    Business = "business",
    Location = "location"
    // Representative = "representative"
    // Billing = "billing"
}

export interface IStep {
    number: number;
    name: OnboardingStep;
    title: string;
    subtitle?: string;
    completed: boolean;
    optional: boolean;
}

export type BusinessStep = Pick<
    IStore,
    | "name"
    | "slug"
    | "aboutUs"
    | "logoUrl"
    | "coverPhoto"
    | "phoneNumber"
    | "hours"
    | "addressComponents"
    | "email"
>;

export type LocationStep = Pick<IStore, "phoneNumber" | "email"> & {
    termsOfServiceDate?: string;
};
