import { Color, Legend } from "@tremor/react";
import { useContext } from "react";

import { ReportsContext } from "#app/reports-context-provider";
import { formatRange } from "#reports/sales-summary/lib";
import { ComparisonType } from "#reports/sales-summary/types";

export function SalesSummaryLegend({
    colors = ["blue", "yellow"]
}: {
    colors?: Color[];
}) {
    const { reportsState } = useContext(ReportsContext);
    const { comparison, dateRanges, granularity } = reportsState;
    const shouldCompare = comparison != ComparisonType.NONE;

    return (
        <Legend
            categories={
                !shouldCompare
                    ? [formatRange(dateRanges[0], granularity)]
                    : [
                          formatRange(dateRanges[0], granularity),
                          formatRange(dateRanges[1], granularity)
                      ]
            }
            colors={colors}
            className="mb-6 p-0"
        />
    );
}
