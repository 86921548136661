import _ from "lodash";
import {
    IHoursSchema,
    IStore,
    TransactionSourceTypeEnum,
    Menu as MultiMenu
} from "@snackpass/snackpass-types";
import {
    checkOverlappingHours,
    validateHoursStartTimeBeforeEndTime
} from "@snackpass/accounting/build/src/utils/Time";

import { isAllLetterUpperCase } from "#menu-editor/utils";
import { isThirdPartyMenu } from "#menu-editor/multi-menus/screens/multi-menu-settings/utils/platform-helpers";

import { MenuAlert, rulesDefaultValue } from "../components/rule-alerts";

export const checkValidationErrors = (
    currentMenus: MultiMenu[],
    currentMenu: Partial<MultiMenu>
): MenuAlert => {
    const detectedAlerts: MenuAlert = { ...rulesDefaultValue };
    const { hours, platforms, enabled, name, id } = currentMenu;

    if (!name) detectedAlerts.noName = true;

    if (platforms) {
        if (platforms.length === 0) detectedAlerts.noPlatform = true;
        else if (
            enabled &&
            platforms.includes(TransactionSourceTypeEnum.ThirdParty) &&
            currentMenus.filter(
                (menu) =>
                    menu.platforms.includes(
                        TransactionSourceTypeEnum.ThirdParty
                    ) &&
                    menu.enabled &&
                    menu.id !== id
            ).length > 0
        )
            detectedAlerts.noThirdParty = true;
    }

    if (!isThirdPartyMenu(platforms ?? []) && hours && hours.local.length === 0)
        detectedAlerts.noTime = true;

    const hasOverlappingHours = !checkOverlappingHours(
        hours ? _.cloneDeep(hours.local) : []
    );
    const hasHoursStartTimeBeforeEndTime = !validateHoursStartTimeBeforeEndTime(
        hours ? _.cloneDeep(hours.local) : []
    );

    if (hasOverlappingHours) {
        detectedAlerts.hasOverlappingHours = true;
    }

    if (hasHoursStartTimeBeforeEndTime) {
        detectedAlerts.hasHoursStartTimeBeforeEndTime = true;
    }

    return detectedAlerts;
};

export const checkValidationWarnings = (
    currentAlerts: MenuAlert,
    activeStore: IStore,
    hours?: IHoursSchema | null,
    name?: string
): MenuAlert => {
    const detectedAlerts: MenuAlert = { ...currentAlerts };

    if (name && !isAllLetterUpperCase(name))
        detectedAlerts.titleCaseName = true;

    // Check schedule ahead conflicts with the store hours
    if (hours)
        detectedAlerts.scheduleAhead = !_(activeStore.hours.local)
            .xorWith(hours.local, _.isEqual)
            .isEmpty();

    return detectedAlerts;
};
