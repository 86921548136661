import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { Spinner } from "react-activity";
import { useEffect } from "react";
import { toast } from "sonner";
import { AxiosError } from "axios";
import { signInWithEmailAndPassword } from "firebase/auth";

import { newAdminAccountSchema } from "#accept-invite/types";
import { UseAcceptInvite } from "#accept-invite/hooks/useAcceptInvite";
import { Routes } from "#navigation/routes";
import { Input } from "src/@/components/ui/input";
import { UserInvite } from "src/core/types";
import {
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormMessage,
    Form
} from "src/@/components/ui/form";
import { Card, CardContent, CardFooter } from "src/@/components/ui/card";
import { Button } from "src/@/components/ui/button";
import { firebaseAuth } from "#app/firebase";
import { logAndSendError, sendError } from "src/utils/errors";

type Props = {
    invite?: UserInvite;
};

export const CompleteProfile = ({ invite }: Props) => {
    const form = useForm<z.infer<typeof newAdminAccountSchema>>({
        resolver: zodResolver(newAdminAccountSchema),
        mode: "onTouched",
        defaultValues: {
            firstName: "",
            lastName: "",
            email: invite?.email,
            password: "",
            confirmPassword: ""
        }
    });
    const { isPending, isSuccess, error, mutate } = UseAcceptInvite();

    useEffect(() => {
        if (!invite) return;

        if (isSuccess) {
            void toast.success("Account created!");

            signInWithEmailAndPassword(
                firebaseAuth,
                invite.email,
                form.getValues().password
            )
                .catch((err: Error) => {
                    sendError(err);
                    logAndSendError(err);
                })
                .finally(() => {
                    window.location.href = Routes.SettingsAccount;
                });
        }
    }, [form, invite, isSuccess]);

    useEffect(() => {
        if (error) {
            const errMessage =
                // @ts-expect-error message does exist
                (error as AxiosError)?.response?.data?.message ||
                "Unknown error";
            toast.error("Responding to invite failed!", {
                description: errMessage
            });
        }
    }, [error]);

    if (!invite) {
        return null;
    }

    const onSubmit = async (
        formValues: z.infer<typeof newAdminAccountSchema>
    ) => {
        void mutate({
            nonce: invite.inviteNonce,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            password: formValues.password
        });
    };

    return (
        <div className="flex h-full w-full flex-col items-center justify-center px-4">
            <div className="my-4 text-2xl font-semibold">
                Complete Your Profile
            </div>
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="m-4 w-full lg:w-[60%]"
                >
                    <Card>
                        <CardContent>
                            <div className="mt-6 flex flex-col">
                                <div className="mb-6">
                                    <div className="mb-4 text-sm font-medium">
                                        Email
                                    </div>
                                    <FormField
                                        control={form.control}
                                        name="email"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        placeholder="email"
                                                        {...field}
                                                        disabled
                                                    />
                                                </FormControl>
                                                <FormDescription />
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>

                                <div className="mb-6 grid grid-cols-2 gap-4 max-md:grid-cols-1">
                                    <div>
                                        <div className="mb-4 text-sm font-medium">
                                            First Name
                                        </div>
                                        <FormField
                                            control={form.control}
                                            name="firstName"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <Input
                                                            placeholder="First Name"
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormDescription />
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <div className="mb-4 text-sm font-medium">
                                            Last Name
                                        </div>
                                        <FormField
                                            control={form.control}
                                            name="lastName"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <Input
                                                            placeholder="Last Name"
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormDescription />
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="mb-6 grid grid-cols-2 gap-4 max-md:grid-cols-1">
                                    <div>
                                        <div className="mb-4 text-sm font-medium">
                                            Password
                                        </div>
                                        <FormField
                                            control={form.control}
                                            name="password"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <Input
                                                            type="password"
                                                            placeholder="Password"
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormDescription />
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <div className="mb-4 text-sm font-medium">
                                            Confirm Password
                                        </div>
                                        <FormField
                                            control={form.control}
                                            name="confirmPassword"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <Input
                                                            type="password"
                                                            placeholder="Confirm Password"
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormDescription />
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                        <CardFooter className="flex flex-col items-center justify-center border border-x-0 border-b-0 p-4">
                            <Button
                                className="w-full"
                                type="submit"
                                disabled={isPending}
                            >
                                {isPending ? <Spinner /> : "Create Account"}
                            </Button>
                        </CardFooter>
                    </Card>
                </form>
            </Form>
        </div>
    );
};
