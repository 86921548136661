import React, { useContext, useState } from "react";
import styled from "styled-components";
import { captureException } from "@sentry/react";
import { debounce } from "radash";

import Text from "#payouts/components/shared/Text";
import theme from "#payouts/utils/theme";
import { Button } from "#payouts/components/shared/Button";
import { Page } from "#payouts/components/shared/Page";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { VerificationsResponse } from "#payouts/domain/types";
import api from "src/api/rest";
import { notifyFailure } from "#payouts/utils/notify";
import { messages } from "#payouts/utils/messages";
import { SegmentEvents, trackSegmentEvent } from "#utils/segment";
import { NoPayoutsAccess } from "#payouts/components/setup/NoPayoutsAccess";
import config from "#config";
import { EmployeeAccess } from "#payouts/components/setup/EmployeeAccess";

import { ReactComponent as Arrow } from "../../assets/arrow.svg";

export const AccountSetup = () => {
    const {
        editEnabled,
        isEmployee,
        storeId,
        verification,
        handleModalChange,
        setVerification,
        emailVerified,
        user
    } = useContext(PayoutsSettingsContext);

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (): VerificationsResponse => {
        if (loading || disabled) {
            return Promise.reject();
        }
        setLoading(true);
        trackSegmentEvent(SegmentEvents.PayoutsSettings.CLICKED_GET_STARTED, {
            storeId,
            userId: user?._id
        });
        return api.verifications.create(storeId);
    };

    const debouncedSubmit = debounce({ delay: 300 }, async () =>
        handleSubmit()
            .then(({ data }) => {
                if (data?.success) {
                    setVerification(data.verification);
                    handleModalChange({ company: true });
                } else {
                    notifyFailure(messages.setup.initiate);
                    captureException(Error(JSON.stringify(data)));
                }
            })
            .catch((error) => {
                // TODO: Handle 403
                notifyFailure(messages.setup.initiate);
                captureException(Error(JSON.stringify(error)));
            })
            .finally(() => {
                setLoading(false);
            })
    );

    const disabled = !storeId || !editEnabled || loading;
    const hasVerification = !!verification?.id;
    const hide = config.isProduction && !emailVerified && editEnabled;

    if (hasVerification || hide) {
        return <></>;
    }

    return (
        <Page>
            <TitleWrapper>
                <Text.Title3>{text.setup.title}</Text.Title3>
                <Text.Subtitle
                    style={{
                        marginLeft: 0,
                        lineHeight: theme.typography.body.lineHeight
                    }}
                >
                    {text.setup.message}
                </Text.Subtitle>
                {editEnabled && (
                    <>
                        <Text.Body
                            style={{
                                fontWeight: 600,
                                margin: `${theme.spacing.base} 0 0 0`,
                                color: theme.typography.title3.color
                            }}
                        >
                            What you'll need:
                        </Text.Body>
                        <List>
                            {basicRequirements.map((r) => (
                                <li key={r}>
                                    <Text.Subtitle children={<>{r}</>} />
                                </li>
                            ))}
                        </List>
                    </>
                )}
                {!editEnabled && !isEmployee && <NoPayoutsAccess />}
                {!editEnabled && isEmployee && <EmployeeAccess />}
            </TitleWrapper>
            {editEnabled ? (
                <ButtonWrapper>
                    <Button
                        block={false}
                        smallRadius
                        children={
                            <>
                                Get Started
                                <Arrow />
                            </>
                        }
                        loading={loading}
                        disabled={disabled}
                        variant="primary"
                        size="regular"
                        onClick={debouncedSubmit}
                    />
                </ButtonWrapper>
            ) : (
                <></>
            )}
        </Page>
    );
};

const basicRequirements = [
    "Federal Tax ID Number (F-EIN)",
    "Routing Number",
    "Account Number",
    "Personal ID Document(s)"
];

const text = {
    setup: {
        title: "Setup",
        message:
            "To receive payouts from Snackpass, you will need to add company information and link a bank account"
    },
    access: {
        title: "You do not have access",
        message:
            "An owner or eligible representative must go through this process. Please contact Snackpass support if you are encountering unexpected issues."
    }
};

const List = styled.ul`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0;

    .li {
        height: ${({ theme }) => theme.spacing.baseAndAHalf};
        margin: 0;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const ButtonWrapper = styled.div`
    display: flex;
    max-width: 150px;
    flex-direction: column;
    margin-top: ${({ theme }) => theme.spacing.baseAndAHalf};
`;
