import React, {
    createContext,
    useEffect,
    useMemo,
    useState,
    useRef,
    ReactNode
} from "react";
import { IStore } from "@snackpass/snackpass-types";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import useDeepCompareEffect from "use-deep-compare-effect";

import {
    MenuInsightsItemRow,
    MenuModifier
} from "#reports/menu-item-insights/types";
import { CustomerTableRow } from "#reports/customer-directory-insights/types";
import { getActiveStore } from "src/redux/selectors";
import api from "src/api/rest";
import { LocationMenuItemRow } from "#reports/location-menu-categories/types";
import { PromotionsReportRow } from "#reports/promotions/types";
import { GiftCardBalanceDataRow } from "#reports/gift-card-balances/types";
import { GiftCardTransactionDataRow } from "#reports/gift-card-transactions/types";
import { logAndSendError } from "src/utils/errors";
import { TableChannelDataRow } from "#reports/sales-channels/types";
import {
    QueryFilter,
    ComparisonType,
    GranularityType,
    TableSummaryDataRow,
    SummaryTilesData,
    SalesHourlyAggregateReport,
    SalesDailyAggregateReport,
    TableLocationData
} from "#reports/sales-summary/types";

export type ReportsContextType = {
    reportsState: ReportsStateType;
    setReportsState: (
        reportsContext:
            | ReportsStateType
            | ((prevState: ReportsStateType) => ReportsStateType)
    ) => void;
};

export const defaultFilter: QueryFilter = {
    source: [],
    channel: [],
    fulfillment: [],
    payment: [],
    storeIds: []
};

const initialWeekRange = [moment().startOf("isoWeek"), moment().endOf("day")];

export const defaultDateRanges: Moment[][] = [
    initialWeekRange,
    initialWeekRange
];

export const defaultReportsState: ReportsStateType = {
    allTime: true,
    granularity: GranularityType.DAILY,
    dateRanges: defaultDateRanges,
    comparison: ComparisonType.NONE,
    filter: defaultFilter,
    channels: [],
    stores: [],
    channelsData: undefined,
    menuInsightsData: undefined,
    locationMenuInsightsData: undefined,
    locationReportsData: undefined,
    customerInsightsData: undefined,
    promotionsReportData: undefined,
    giftCardBalanceReportData: undefined,
    giftCardTransactionReportData: undefined
};

export const ReportsContext = createContext<ReportsContextType>({
    reportsState: defaultReportsState,
    setReportsState: () => {}
});

export const ReportsContextProvider = ({
    children
}: {
    children: ReactNode;
}) => {
    const activeStore = useSelector(getActiveStore);

    const [reportsState, setReportsState] =
        useState<ReportsStateType>(defaultReportsState);

    const selectedStores = reportsState.filter.storeIds;

    const reportsContextValue = useMemo(
        () => ({ reportsState, setReportsState }),
        [reportsState, setReportsState]
    );

    const abortControllerChannels = useRef<AbortController | null>(null);
    const abortControllerStores = useRef<AbortController | null>(null);

    const fetchChannels = () => {
        if (!activeStore?._id) {
            return;
        }

        if (abortControllerChannels.current) {
            abortControllerChannels.current.abort();
        }

        abortControllerChannels.current = new AbortController();

        const params = {
            storeId: activeStore?._id,
            ...(selectedStores.length > 0
                ? { storeIds: JSON.stringify(selectedStores) }
                : {})
        };

        api.reports
            .getSalesChannels(params, abortControllerChannels.current)
            .then((res) => {
                setReportsState((reportsState) => ({
                    ...reportsState,
                    channels: res.data?.channels
                }));
            })
            .catch((e) => {
                if (axios.isCancel(e?.cause)) return;
                logAndSendError(e);
            });
    };

    const fetchAdminStores = () => {
        if (abortControllerStores.current) {
            abortControllerStores.current.abort();
        }

        abortControllerStores.current = new AbortController();

        setReportsState((reportsState) => ({ ...reportsState, stores: [] }));
        api.stores
            .getMyAdminStores(abortControllerStores.current)
            .then((res) => {
                const stores = res.data?.stores;
                setReportsState((reportsState) => ({
                    ...reportsState,
                    stores
                }));
            })
            .catch((e) => {
                logAndSendError(e);
            });
    };

    useEffect(() => {
        fetchChannels();
        fetchAdminStores();
    }, [activeStore?._id]);

    useEffect(() => {
        fetchChannels();
    }, [selectedStores]);

    useDeepCompareEffect(() => {
        setReportsState({
            ...reportsState,
            channelsData: undefined,
            menuInsightsData: undefined,
            locationMenuInsightsData: undefined,
            locationReportsData: undefined,
            customerInsightsData: undefined,
            promotionsReportData: undefined,
            giftCardBalanceReportData: undefined,
            giftCardTransactionReportData: undefined
        });
    }, [reportsState.filter, reportsState.dateRanges, reportsState.allTime]);

    return (
        <ReportsContext.Provider value={reportsContextValue}>
            {children}
        </ReportsContext.Provider>
    );
};

export type GiftCardBalancesDataType = {
    giftCardBalancesDataLoading?: boolean;
    giftCardBalancesDataFailed?: boolean;
    giftCardBalancesData: GiftCardBalanceDataRow[] | undefined;
};

export type GiftCardTransactionDataType = {
    giftCardTransactionsDataLoading?: boolean;
    giftCardTransactionsDataFailed?: boolean;
    giftCardTransactionsData: GiftCardTransactionDataRow[] | undefined;
};

export type LocationReportsDataType = {
    salesSummaryDataLoading?: boolean;
    salesSummaryDataFailed?: boolean;
    salesSummaryData: SummaryTilesData | undefined;

    salesReportAggregateDataLoading?: boolean;
    salesReportAggregateDataFailed?: boolean;
    salesReportAggregateData: TableSummaryDataRow[] | undefined;

    salesReportDataLoading?: boolean;
    salesReportDataFailed?: boolean;
    salesReportData: { [storeId: string]: TableLocationData } | undefined;
    salesHourlyAggregateReportData:
        | { [storeId: string]: SalesHourlyAggregateReport[] }
        | undefined;
    salesDailyAggregateReportData:
        | { [storeId: string]: SalesDailyAggregateReport[] }
        | undefined;
};

export type MenuInsightsDataType = {
    itemInsightsDataLoading?: boolean;
    itemInsightsData: MenuInsightsItemRow[] | undefined;
    itemInsightsDataFailed?: boolean;
    itemTableModifierData:
        | { [id: string]: MenuModifier[] | undefined }
        | undefined;
};

export type LocationMenuInsightsDataType = {
    itemInsightsDataLoading?: boolean;
    itemInsightsDataFailed?: boolean;
    itemInsightsData: { [storeId: string]: LocationMenuItemRow[] } | undefined;
};

export type CustomerInsightsDataType = {
    customerDataLoading?: boolean;
    customerInsightsDataFailed?: boolean;
    customerData: CustomerTableRow[] | undefined;
};

export type ChannelsDataType = {
    salesChannelDataLoading?: boolean;
    salesChannelDataFailed?: boolean;
    salesChannelReportData: TableChannelDataRow[] | undefined;
};

export type PromotionsReportDataType = {
    promotionsData: PromotionsReportRow[] | undefined;
    promotionsDataLoading?: boolean;
    promotionsDataFailed?: boolean;
};

export type ReportsStateType = {
    dateRanges: Moment[][];
    allTime: boolean;
    comparison: ComparisonType;
    filter: QueryFilter;
    granularity: GranularityType;
    channels: string[];
    stores: IStore[];

    // TODO: Remove us!
    // We shouldn't keep reports data the context state, it would be best held in the useQuery cache.
    // reportsData: ReportsDataType | undefined; // this one has been removed
    locationReportsData: LocationReportsDataType | undefined;
    channelsData: ChannelsDataType | undefined;
    menuInsightsData: MenuInsightsDataType | undefined;
    locationMenuInsightsData: LocationMenuInsightsDataType | undefined;
    customerInsightsData: CustomerInsightsDataType | undefined;
    promotionsReportData: PromotionsReportDataType | undefined;
    giftCardBalanceReportData: GiftCardBalancesDataType | undefined;
    giftCardTransactionReportData: GiftCardTransactionDataType | undefined;
};
