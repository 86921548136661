/** @jsxImportSource @emotion/react */
import { Button, Modal } from "react-bootstrap";
import { css } from "@emotion/react";
import React, { Dispatch, SetStateAction } from "react";
import { ScreenState } from "@snackpass/snackpass-types";

import { ReactComponent as XIcon } from "src/assets/icons/close-x.svg";
import { RoundButton } from "#menu-editor/multi-menus/styled-components/button";

type ModalProps = {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    setFailed?: Dispatch<SetStateAction<boolean>>;
    fail: boolean;
    isInProcess: boolean;
    onClick: () => Promise<void>;
    title: string;
    message: string;
    action?: string;
};

export const ConfirmModal: React.FC<ModalProps> = ({
    isModalOpen,
    setIsModalOpen,
    setFailed,
    fail,
    isInProcess,
    onClick,
    title,
    message,
    action
}) => (
    <Modal
        show={isModalOpen}
        css={warningModalStyles}
        dialogClassName="modal-width"
        centered
    >
        <Modal.Header>
            <RoundButton
                onClick={() => {
                    setIsModalOpen(false);
                    setFailed && setFailed(false);
                }}
            >
                <XIcon />
            </RoundButton>
            <Modal.Title>
                <p className="header-title">
                    {fail ? `Failed${action && ` to ${action}`}` : title}
                </p>
            </Modal.Title>
            <div className="header-subtitle-container">
                <p className={`header-subtitle ${fail && "failed"}`}>
                    {fail
                        ? `Failed${action && ` to ${action}`}, try again later.`
                        : message}
                </p>
            </div>
        </Modal.Header>
        <Modal.Footer>
            <Button
                className="okay-btn"
                onClick={onClick}
                disabled={isInProcess || fail}
            >
                {isInProcess ? "..." : "Okay"}
            </Button>
        </Modal.Footer>
    </Modal>
);

const warningModalStyles = css`
    font-family: "Inter";
    font-style: normal;

    .modal-content {
        border-radius: 16px;
    }
    .modal-header {
        display: flex;
        flex-direction: column;
        align-items: inherit;
    }
    .header-title {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        color: #282d32;
        margin: 24px;
        text-align: center;
    }
    .header-subtitle {
        color: #606c76;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        @media ${ScreenState.MOBILE} {
            margin: 0px 0px 24px 0px;
        }
        margin: 0px 24px 24px 24px;
    }
    .failed {
        color: #ff3929;
    }
    .okay-btn {
        flex: auto;
        background: #0077ff;
        border-radius: 8px;
    }
    .modal-footer {
        justify-content: start;
    }
`;
