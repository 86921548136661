import clsx from "clsx";
import React from "react";

import {
    RequiredLabel,
    AutofilledLabel,
    RecommendedLabel,
    OptionalLabel
} from "#settings-integrations/components/shared/reusables";

type Props = {
    name?: string;
    size?: "sm" | "md" | "lg";
    required?: boolean;
    recommended?: boolean;
    autofilled?: boolean;
    optional?: boolean;
};

type LabelProps = {
    required?: boolean;
    recommended?: boolean;
    autofilled?: boolean;
    optional?: boolean;
};

const FieldLabel = ({
    required,
    recommended,
    autofilled,
    optional
}: LabelProps) => {
    if (required) {
        return <RequiredLabel />;
    } else if (recommended) {
        return <RecommendedLabel />;
    } else if (autofilled) {
        return <AutofilledLabel />;
    } else if (optional) {
        return <OptionalLabel />;
    }
    return null;
};

export const FormFieldName = ({
    name,
    required = false,
    recommended = false,
    autofilled = false,
    optional = false,
    size = "sm"
}: Props) =>
    name ? (
        <div className="align-center mb-1 flex">
            <p
                className={clsx(
                    "m-0 font-medium",
                    size === "lg"
                        ? "text-xlarge"
                        : size === "md"
                          ? "text-large"
                          : "text-base"
                )}
            >
                {name}
            </p>
            <FieldLabel
                required={required}
                recommended={recommended}
                autofilled={autofilled}
                optional={optional}
            />
        </div>
    ) : null;
