import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import update from "immutability-helper";
import Select, { ValueType } from "react-select";
import { PlusIcon } from "@radix-ui/react-icons";
import produce from "immer";

import { ReactComponent as DeleteIcon } from "src/assets/icons/delete-red.svg";
import { ReactComponent as DragHandle } from "src/assets/icons/drag-dots.svg";
import Text from "#devices/components/Text";
import { Button } from "src/@/components/ui/button";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import {
    ChannelOption,
    ChannelType
} from "#devices/utils/deviceTypes/SnackTVDevice";
import { DndBackendProvider } from "#devices/utils/dndBackendProvider";
import { fetchLegacyMenu } from "#menu/api";
import { logAndSendError } from "src/utils/errors";

import {
    ActionRow,
    ButtonContainer,
    DeleteButton,
    DropdownIndicator,
    draggableSelectStyles,
    HandleContainer,
    IndicatorSeparator,
    SelectWrapper,
    StyledCol,
    StyledRow,
    TableStyles,
    ProductBadgeInput
} from "./styles";
import { DraggableBodyRow } from "./DraggableBodyRow";

interface DataType {
    key: number;
    productId: string;
    productName: string;
    badge: string;
}

type ProductOption = {
    readonly value: string;
    readonly label: string;
};

let itemCount = 0;

type Props = {
    hideBadge?: boolean;
};

// https://ant.design/components/table
export const PinnedProducts = ({ hideBadge }: Props): JSX.Element => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const deviceOptions = (updatedDevice?.deviceDetails?.channelOptions ??
        snackTvDevice?.deviceDetails?.channelOptions ??
        []) as ChannelOption[];

    const channel =
        updatedDevice?.deviceDetails?.channel ??
        snackTvDevice?.deviceDetails?.channel;

    const channelOptions = deviceOptions.find(
        (o: ChannelOption) => o.channel === channel
    );

    const [tableData, setTableData] = useState<DataType[]>([]);
    const [fetching, setFetching] = useState<boolean>(false);
    const [storeProducts, setStoreProducts] = useState<ProductOption[]>([]);

    useEffect(() => {
        if (!fetching && snackTvDevice) {
            fetchProducts(snackTvDevice.storeId);
        }
    }, []);

    const backend = useMemo(() => DndBackendProvider(), []);

    const handleTableChange = (data: DataType[]) => {
        const pinnedProducts = data.map((row, i) => ({
            productId: row.productId,
            badge: row.badge ?? "",
            order: i
        }));

        const updatedOptions = produce(deviceOptions, (draft) => {
            if (draft) {
                const channelOption = draft.find((o) => o.channel === channel);
                if (!channelOption) {
                    draft.push({
                        channel: channel as ChannelType,
                        options: {
                            pinnedProducts
                        }
                    });
                } else {
                    channelOption.options.pinnedProducts = pinnedProducts;
                }
            }
        });
        setTableData(data);
        updateDeviceField("channelOptions", updatedOptions);
    };

    const columns: ColumnsType<DataType> = [
        {
            title: "Drag",
            dataIndex: "drag",
            key: "drag",
            width: "5%",
            render: () => (
                <HandleContainer>
                    <DragHandle />
                </HandleContainer>
            )
        },
        {
            title: "Select",
            dataIndex: "select",
            key: "select",
            render: (value: string, record: DataType, index: number) => (
                <CategoryOptionSelect
                    value={{
                        value: record.productId,
                        label: record.productName
                    }}
                    onChange={(item) => {
                        if (item) {
                            const data = [...tableData];
                            data[index] = {
                                ...data[index],
                                productId: item.value,
                                productName: item.label
                            };
                            handleTableChange(data);
                        }
                    }}
                />
            )
        },
        !hideBadge
            ? {
                  title: "Badge",
                  dataIndex: "badge",
                  key: "badge",
                  width: "35%",
                  render: (value: string, record: DataType, index: number) => (
                      <ProductBadgeInput
                          placeholder={"Enter product badge"}
                          value={record.badge ?? ""}
                          onChange={(e) => {
                              const data = [...tableData];
                              data[index] = {
                                  ...data[index],
                                  badge: e.target.value
                              };
                              handleTableChange(data);
                          }}
                      />
                  )
              }
            : {},
        {
            title: "Delete",
            dataIndex: "delete",
            key: "delete",
            width: "5%",
            render: (value: string, record: DataType, index: number) => (
                <DeleteButton
                    onClick={() => {
                        const data = [...tableData];
                        data.splice(index, 1);
                        handleTableChange(data);
                    }}
                >
                    <DeleteIcon />
                </DeleteButton>
            )
        }
    ];

    const fetchProducts = (storeId: string) => {
        setFetching(true);
        fetchLegacyMenu(storeId)
            .then((menu) => {
                const products = menu.products.map((product) => ({
                    value: product._id,
                    label: product.name
                }));

                const initialData = channelOptions?.options.pinnedProducts?.map(
                    (product, key) => {
                        const productId = product.productId;
                        const productName =
                            products.find(
                                (p: ProductOption) => p.value === productId
                            )?.label ?? productId;

                        return {
                            key,
                            productId,
                            productName,
                            badge: product.badge
                        };
                    }
                );

                itemCount += initialData?.length ?? 0;

                setStoreProducts(products);
                setTableData(initialData ?? []);
            })
            .catch((error) => logAndSendError(error.message))
            .finally(() => setFetching(false));
    };

    const CategoryOptionSelect: FC<{
        onChange: (value: ValueType<ProductOption, false>) => void;
        value: ProductOption;
    }> = ({ onChange, value }) => (
        <SelectWrapper>
            <Select<ProductOption, false>
                value={value}
                styles={draggableSelectStyles}
                placeholder="Select to pin Product"
                options={storeProducts}
                onChange={onChange}
                components={{
                    DropdownIndicator,
                    IndicatorSeparator
                }}
            />
        </SelectWrapper>
    );

    const moveRow = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragRow = tableData[dragIndex];
            const newTableData = update(tableData, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRow]
                ]
            });
            handleTableChange(newTableData);
        },
        [tableData]
    );

    const components = {
        body: {
            row: DraggableBodyRow
        }
    };

    return (
        <TableStyles>
            <StyledRow>
                <StyledCol>
                    <Text.Label>Pinned Products</Text.Label>
                    <Text.Body>Only these products will be displayed</Text.Body>
                </StyledCol>
                <StyledCol>
                    <ActionRow>
                        <ButtonContainer>
                            <Button
                                className="px-2"
                                onClick={() => {
                                    const newData = [...tableData];
                                    newData.push({
                                        key: itemCount,
                                        productId: "",
                                        productName: "",
                                        badge: ""
                                    });
                                    itemCount += 1;
                                    handleTableChange(newData);
                                }}
                                disabled={fetching}
                            >
                                <PlusIcon className="mr-1 h-5 w-5" />
                                Add Product
                            </Button>
                        </ButtonContainer>
                    </ActionRow>
                </StyledCol>
            </StyledRow>

            <Table
                columns={columns}
                showHeader={false}
                dataSource={tableData}
                pagination={false}
                components={components}
                locale={{
                    emptyText: "No Products Selected"
                }}
                onRow={(_, index) => {
                    const attr = {
                        index,
                        moveRow
                    };
                    return attr as React.HTMLAttributes<unknown>;
                }}
            />
        </TableStyles>
    );
};
