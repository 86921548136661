import { useFormContext } from "react-hook-form";

import { CardDescription } from "src/@/components/ui/card";
import {
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";

function SharedBrandRegistrationDisplayNameInput() {
    const { control } = useFormContext();
    return (
        <FormField
            control={control}
            name="displayName"
            render={({ field }) => (
                <FormItem>
                    <div className="flex flex-col gap-y-2 pt-6">
                        <FormLabel>
                            <p className="font-semibold">Sender Name</p>
                        </FormLabel>
                        <CardDescription>
                            This sender name appears at the beginning of each
                            text. Something short and recognizable is
                            recommended. If you are registering a location
                            within a chain, we recommend identifying the
                            location in the sender name. This will not change
                            the sender name of the location you are sharing
                            registration with.
                        </CardDescription>
                        <FormControl>
                            <Input {...field} />
                        </FormControl>
                        <FormDescription className="w-full text-gray-500">
                            Please use no more than 24 characters. No special
                            characters.
                        </FormDescription>
                        <FormMessage />
                    </div>
                </FormItem>
            )}
        />
    );
}

export default SharedBrandRegistrationDisplayNameInput;
