import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { shake } from "radash";
import { ExclamationTriangleIcon, ReloadIcon } from "@radix-ui/react-icons";
import { toast } from "sonner";
import { NumberInput } from "@tremor/react";

import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";
import { initialValue } from "#settings/settings-tax/utils/initial";
import { getActiveStore } from "src/redux/selectors";
import { sendError } from "src/utils/errors";
import api from "src/api/rest";
import { setActiveStore } from "src/redux/slices";
import { useAppDispatch } from "src/redux/hooks";
import { Button } from "src/@/components/ui/button";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle
} from "src/@/components/ui/card";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage
} from "src/@/components/ui/form";
import { Switch } from "src/@/components/ui/switch";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { TaxFormFields } from "#settings/settings-tax/utils/types";
import { FormSchema } from "#settings/settings-tax/utils/schema";
import { taxRateMax, taxRateMin } from "#settings/settings-tax/utils/helper";
import { useUnsavedChangesPrompt } from "#settings/hooks/useUnsavedChangesPrompt";
import { UnsavedChangesModal } from "#settings/components/unsaved-changes";

export const SettingsTaxScreen = () => {
    const dispatch = useAppDispatch();
    const activeStore = useSelector(getActiveStore);
    const viewOnly = !useHasEditSettingsForActiveStore();

    const [isLoading, setIsLoading] = useState(false);

    const storeValues = shake({
        taxRate: activeStore?.taxRate,
        detail: activeStore?.specifyTaxRateByFulfillment,
        dineIn: activeStore?.taxRateDineIn,
        pickup: activeStore?.taxRatePickup,
        delivery: activeStore?.taxRateDelivery
    });

    const defaultValues = { ...initialValue, ...storeValues };

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues
    });

    const hasSpecifyDetail = form.watch(TaxFormFields.detail);

    useEffect(() => {
        if (activeStore) {
            form.reset(defaultValues);
        }
    }, [activeStore, form.reset]);

    const onSubmit = async (data: z.infer<typeof FormSchema>) => {
        if (!activeStore?._id) return;

        if (viewOnly) {
            toast.error("You do not have permission to edit this store");
            return;
        }

        try {
            setIsLoading(true);
            const response = await api.stores.update(activeStore._id, {
                taxRate: data.taxRate,
                specifyTaxRateByFulfillment: data.detail,
                taxRateDineIn: data.dineIn,
                taxRatePickup: data.pickup,
                taxRateDelivery: data.delivery
            });
            dispatch(setActiveStore(response.data.store));
            toast.success("Store updated successfully");
        } catch (e) {
            sendError(e);
            toast.error("Cannot update the store, please try again later");
        } finally {
            setIsLoading(false);
        }
    };

    const {
        showModal,
        handleConfirmNavigationClick,
        handleCancelNavigationClick
    } = useUnsavedChangesPrompt(form.formState.isDirty);

    const disableFulfillmentTax = viewOnly || isLoading || !hasSpecifyDetail;

    return (
        <div className="h-max overflow-auto bg-gray-50">
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="mb-24 space-y-8 p-8 sm:p-16"
                >
                    <UnsavedChangesModal
                        show={showModal}
                        onConfirm={handleConfirmNavigationClick}
                        onCancel={handleCancelNavigationClick}
                    />
                    <div>
                        <CardTitle className="text-2xl font-bold">
                            Tax Rates
                        </CardTitle>
                    </div>
                    <Alert
                        variant="warning"
                        className="max-w-4xl border-neutral-400"
                    >
                        <AlertTitle className="text-base">
                            <ExclamationTriangleIcon className="mr-2" />
                            Important
                        </AlertTitle>
                        <AlertDescription className="text-sm">
                            It is the duty of partners to configure tax rates
                            accurately, and it is important to consult local
                            regulations before modifying or updating these
                            rates.
                        </AlertDescription>
                    </Alert>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">Taxes</CardTitle>
                            <CardDescription>
                                Set the tax rates for your store.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name={TaxFormFields.taxRate}
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Default Tax Rate
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Choose a store wide tax rate
                                                between {taxRateMin}% -{" "}
                                                {taxRateMax}%
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        placeholder="10.25"
                                                        min={0}
                                                        max={15}
                                                        step={0.01}
                                                        className="w-[240px] flex-1 [&>input]:pl-2"
                                                        icon={PercentIcon}
                                                        {...field}
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            viewOnly ||
                                                            isLoading ||
                                                            hasSpecifyDetail
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name={TaxFormFields.detail}
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Specify Tax Rate by
                                                    Fulfillment
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Override the default tax
                                                    rate with
                                                    fulfillment-specific tax
                                                    rates.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            viewOnly ||
                                                            isLoading
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name={TaxFormFields.dineIn}
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Dine In
                                            </CardTitle>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        placeholder="10.25"
                                                        min={0}
                                                        max={15}
                                                        step={0.01}
                                                        className="w-[240px] flex-1 [&>input]:pl-2"
                                                        icon={PercentIcon}
                                                        {...field}
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            disableFulfillmentTax
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name={TaxFormFields.pickup}
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Pickup
                                            </CardTitle>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        placeholder="10.25"
                                                        min={0}
                                                        max={15}
                                                        step={0.01}
                                                        className="w-[240px] flex-1 [&>input]:pl-2"
                                                        icon={PercentIcon}
                                                        {...field}
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            disableFulfillmentTax
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name={TaxFormFields.delivery}
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Delivery
                                            </CardTitle>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        placeholder="10.25"
                                                        min={0}
                                                        max={15}
                                                        step={0.01}
                                                        className="w-[240px] flex-1 [&>input]:pl-2"
                                                        icon={PercentIcon}
                                                        {...field}
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            disableFulfillmentTax
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    {form.formState.isDirty && (
                        <div className="fixed bottom-0 left-0 flex w-full flex-row items-center justify-end gap-4 border-t bg-white p-2">
                            <Button
                                type="button"
                                variant={"outline"}
                                onClick={() => {
                                    form.reset(defaultValues);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button disabled={isLoading} type="submit">
                                {isLoading && (
                                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                Submit
                            </Button>
                        </div>
                    )}
                </form>
            </Form>
        </div>
    );
};

const PercentIcon = () => (
    <div className="absolute right-0 mr-20 flex shrink-0 items-center text-micro">
        %
    </div>
);
