import { captureException } from "@sentry/react";
import { SystemColors } from "@snackpass/design-system";
import { notification } from "antd";
import debounce from "lodash/debounce";
import { useContext, useState } from "react";
import styled from "styled-components";

import api from "src/api/rest";
import config from "#config";
import { ReactComponent as Arrow } from "#payouts/assets/arrow.svg";
import Text from "#payouts/components/shared/Text";
import theme from "#payouts/utils/theme";
import { Button } from "#payouts/components/shared/Button";
import { Page } from "#payouts/components/shared/Page";
import { TaxFormsContext } from "#tax-forms/Context";

import { PasscodeInputModal } from "./PasscodeInputModal";

const notifyFailure = () =>
    notification.error({
        placement: "top",
        message: "Update Failed",
        description:
            "Please contact Snackpass support if this issue continues to arise."
    });

export const EmailVerification = () => {
    const { storeId, emailVerified } = useContext(TaxFormsContext);
    const [loading, setLoading] = useState(false);
    const [passcodeModalIsOpen, setPasscodeModalIsOpen] = useState(false);

    // TODO: Add rate-limit server side too
    const handleSendVerificationEmail = debounce(
        async () => {
            if (!storeId || loading) {
                return;
            }
            setLoading(true);
            return api.verifications
                .sendVerificationCode(storeId)
                .then(() => {
                    setPasscodeModalIsOpen(true);
                })
                .catch((error) => {
                    notifyFailure();
                    captureException(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        1000,
        { leading: true, trailing: false }
    );

    if (emailVerified || !config.isProduction) {
        return null;
    }

    return (
        <>
            <PasscodeInputModal
                isOpen={passcodeModalIsOpen}
                setIsOpen={setPasscodeModalIsOpen}
            />
            <Page>
                <TitleWrapper>
                    <Text.Title3>Verify your identity</Text.Title3>
                    <Text.Subtitle
                        style={{
                            margin: `0 0 ${theme.spacing.base} 0`,
                            lineHeight: theme.typography.body.lineHeight
                        }}
                    >
                        This page contains sensitive information. For your
                        security, we want to make sure it's really you by
                        sending a code to your email address.
                    </Text.Subtitle>
                </TitleWrapper>
                <ButtonWrapper>
                    <Button
                        block={false}
                        smallRadius
                        children={
                            <>
                                <span>Send Verification Email</span>
                                <Arrow />
                            </>
                        }
                        loading={loading}
                        loadingColor={SystemColors.v1.white}
                        variant="primary"
                        size="regular"
                        onClick={handleSendVerificationEmail}
                    />
                </ButtonWrapper>
            </Page>
        </>
    );
};

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const ButtonWrapper = styled.div`
    display: flex;
    min-width: 240px;
    max-width: 240px;
    flex-direction: column;
    margin-top: ${({ theme }) => theme.spacing.baseAndAHalf};
`;
