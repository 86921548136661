window.global ||= window;
/** @jsxImportSource @emotion/react */
import { createRoot } from "react-dom/client";

// CSS
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-table/react-table.css";

// Prefer Tailwind over Bootstrap if there's conflict (i.e. p-3)
import "#css/postcss.css";
import "#css/tailwind-reset.css";

// Sentry
import * as Sentry from "@sentry/react";
import { extraErrorDataIntegration } from "@sentry/integrations";

import App from "#app/app";
import config from "#config";
import "#app/genericErrorHandler";
import { signinPreload } from "src/utils/signinPreload";
import { loadIntercom } from "src/utils/intercomSnippet";
import { beforeSend } from "src/utils/beforeSend";

import { unregister } from "./serviceWorker";

if (config.env === "production") {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        release: config.releaseVersion,
        normalizeDepth: 10,
        integrations: [extraErrorDataIntegration({ depth: 10 })],
        beforeSend
    });
}

// Unregister servicerWorker to bust browser cache on new builds
unregister();

async function bootstrap() {
    const container = document.getElementById("root");
    if (container) {
        const root = createRoot(container);
        try {
            await signinPreload();
        } catch (error) {
            console.warn("Error during signinPreload", error);
        }
        loadIntercom();
        root.render(<App />);
    }
}

bootstrap();
