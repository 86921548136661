import { AxiosError } from "axios";
import { useCallback, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { toast } from "sonner";

import api from "src/api/rest";
import {
    LOADING_STATUS_ERROR,
    LoadingStatus,
    brandRegistrationStatusAtom,
    sharableBrandRegistrationsAtom
} from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationAtoms";
import { storeDisplayNameAtom } from "#guestbook/screens/Campaigns/NewCampaign/NewCampaignAtoms";

export function useFetchTwilioBrand() {
    const setStatus = useSetRecoilState(brandRegistrationStatusAtom);
    const setShareableBrandRegistrations = useSetRecoilState(
        sharableBrandRegistrationsAtom
    );
    const setStoreDisplayName = useSetRecoilState(storeDisplayNameAtom);

    const fetchTwilioBrand = useCallback(
        async (storeId: string) => {
            try {
                const response =
                    await api.twilio.fetchTwilioBrandRegistrationStatus(
                        storeId
                    );
                if (response.data.status) {
                    setStatus(response.data.status);
                    setStoreDisplayName(
                        response.data.displayName ?? "Store name missing!"
                    );
                } else {
                    // A non-null status indicates the store is already registered, otherwise
                    // we fetch the chain stores to see if there's any stores to share with.
                    try {
                        const chainResponse =
                            await api.twilio.fetchTwilioBrandRegistrationChainStores(
                                storeId
                            );
                        setShareableBrandRegistrations(
                            chainResponse.data.brands
                        );
                        setStatus(null); // loading is done
                    } catch (e) {
                        if (e instanceof AxiosError) {
                            const message = `Failed to load chain brand registrations, please try reloading.`;
                            const details =
                                e.response?.data?.message ?? e.message;
                            toast.error(message, {
                                description: details
                            });
                            setStatus(
                                new LoadingStatus(
                                    LOADING_STATUS_ERROR,
                                    `${message}\n${details}`
                                )
                            );
                        }
                    }
                }
            } catch (e) {
                if (e instanceof AxiosError) {
                    const message = `Failed to load brand registration, please try reloading.`;
                    const details = e.response?.data?.message ?? e.message;
                    toast.error(message, {
                        description: details
                    });
                    setStatus(
                        new LoadingStatus(
                            LOADING_STATUS_ERROR,
                            `${message}\n${details}`
                        )
                    );
                }
            }
        },
        [setShareableBrandRegistrations, setStatus, setStoreDisplayName]
    );

    return useMemo(
        () => ({
            fetchTwilioBrand
        }),
        [fetchTwilioBrand]
    );
}
