import { IProduct, IStore } from "@snackpass/snackpass-types";

import { hasDuplicates } from "#menu-editor/mobile-friendly/helpers/utils";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";

class ProductUniqueModifierGroupCheck
    implements IMenuAutomationCheck<IProduct>
{
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "ProductUniqueModifierGroupCheck";
    }

    get itemToCheck(): ItemType {
        return "product";
    }

    checkItems() {
        return [];
    }

    checkItem(item: IProduct): MenuIssue<IProduct> | null {
        // if product modifier groups has unique names
        if (hasDuplicates(item.addonGroups, "name")) {
            return new MenuIssue(
                "NeedUniqueAddonGroupNames",
                "Modifier groups can not have duplicate names",
                "critical",
                item
            );
        }
        return null;
    }
}

export { ProductUniqueModifierGroupCheck };
