import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import config from "#config";

const firebaseConfig = {
    apiKey: config.firebaseApiKey,
    authDomain: config.firebaseAuthDomain,
    databaseURL: config.firebaseDatabaseUrl,
    projectId: config.firebaseProjectId,
    storageBucket: config.firebaseStorageBucket,
    messagingSenderId: config.firebaseMessagingSenderId
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Expose the Firebase Auth instance
export const firebaseAuth = getAuth(firebaseApp);
