import { EmployeePermissions } from "@snackpass/snackpass-types";
import { z } from "zod";

export type RoleEntry = {
    storeId: string;
    role: string;
};

export enum CheckboxState {
    checked = "checked",
    unchecked = "unchecked",
    indeterminate = "indeterminate"
}

export const permissionsSchema = z.object({
    hasDashboard: z.array(z.string()).optional(),
    hasOrders: z.array(z.string()).optional(),
    hasFinancialReports: z.array(z.string()).optional(),
    hasMenuEditing: z.array(z.string()).optional(),
    hasPromos: z.array(z.string()).optional(),
    hasGuestbook: z.array(z.string()).optional(),
    hasBilling: z.array(z.string()).optional(),
    hasBillingWrite: z.array(z.string()).optional(),
    hasEmployees: z.array(z.string()).optional(),
    hasSettings: z.array(z.string()).optional(),
    hasSalesReports: z.array(z.string()).optional(),
    hasSettingsWrite: z.array(z.string()).optional(),
    hasReportsMenu: z.array(z.string()).optional(),
    hasReportsCustomers: z.array(z.string()).optional(),
    hasReportsLocations: z.array(z.string()).optional(),
    hasReportsPromotions: z.array(z.string()).optional(),
    hasReportsLabor: z.array(z.string()).optional(),
    hasReportsGiftCards: z.array(z.string()).optional(),
    hasCatering: z.array(z.string()).optional(),
    hasDevices: z.array(z.string()).optional(),
    hasEditAdmin: z.array(z.string()).optional(),
    isOwner: z.array(z.string()).optional(),
    hasPayouts: z.array(z.string()).optional()
});

export const snackpassPermissionsSchema = z.object({
    storeRoles: z.array(
        z.object({
            role: z.string(),
            storeId: z.string()
        })
    )
});

export const adminColSchema = z.object({
    _id: z.string(),
    name: z.string().nullish(),
    number: z.string().nullish(),
    email: z.string().nullish(),
    role: z.string().nullish(),
    employee: z
        .object({
            pin: z.string().nullish(),
            wage: z.number().nullish(),
            identifier: z.string().nullish()
        })
        .optional(),
    permissions: permissionsSchema.optional(),
    snackpassPermissions: snackpassPermissionsSchema
});

export type AdminColumn = z.infer<typeof adminColSchema>;

export const pendingInviteColSchema = z.object({
    email: z.string(),
    jobTitle: z.string(),
    status: z.string(),
    createdAt: z.string(),
    identifier: z.string(),
    _id: z.string().optional()
});

export type PendingInviteColumn = z.infer<typeof pendingInviteColSchema>;

export type SnackpassPermissions = {
    hasDashboard?: boolean;
    hasOrders?: boolean;
    hasFinancialReports?: boolean;
    hasMenuEditing?: boolean;
    hasPromos?: boolean;
    hasGuestbook?: boolean;
    hasBilling?: boolean;
    hasBillingWrite?: boolean;
    hasEmployees?: boolean;
    hasSettings?: boolean;
    hasSalesReports?: boolean;
    hasSettingsWrite?: boolean;
    hasReportsMenu?: boolean;
    hasReportsCustomers?: boolean;
    hasReportsLocations?: boolean;
    hasReportsPromotions?: boolean;
    hasReportsLabor?: boolean;
    hasReportsGiftCards?: boolean;
    hasCatering?: boolean;
    hasDevices?: boolean;
    hasEditAdmin?: boolean;
    hasPayouts?: boolean;
};

export type AdminFields = {
    firstName?: string;
    lastName?: string;
    identifier?: string;
    email?: string;
    number?: string;
    pin?: string;
    role?: string;
    wage?: string;
    snackpassPermissions?: SnackpassPermissions;
};

export type InviteFields = {
    email: string;
    role: string;
    wage: string;
    pin?: string;
    identifier: string;
};

export const EMPTY_EMP_DATA = {
    cashDrawer: false,
    discounts: false,
    orders: false,
    sales: false
};

export const Roles: Record<string, SnackpassPermissions> = {
    adminRole: {
        hasDashboard: true,
        hasOrders: true,
        hasFinancialReports: true,
        hasMenuEditing: true,
        hasPromos: true,
        hasGuestbook: true,
        hasBilling: true,
        hasBillingWrite: true,
        hasEmployees: true,
        hasSettings: true,
        hasSalesReports: true,
        hasSettingsWrite: true,
        hasReportsMenu: true,
        hasReportsCustomers: true,
        hasReportsLocations: true,
        hasReportsLabor: true,
        hasReportsPromotions: true,
        hasReportsGiftCards: true,
        hasCatering: true,
        hasDevices: true,
        hasEditAdmin: true,
        hasPayouts: false
    },
    managerRole: {
        hasDashboard: true,
        hasOrders: true,
        hasFinancialReports: false,
        hasMenuEditing: true,
        hasPromos: true,
        hasGuestbook: true,
        hasBilling: true,
        hasBillingWrite: false,
        hasEmployees: true,
        hasSettings: true,
        hasSalesReports: true,
        hasSettingsWrite: true,
        hasReportsMenu: true,
        hasReportsCustomers: true,
        hasReportsLabor: true,
        hasReportsLocations: true,
        hasReportsPromotions: true,
        hasReportsGiftCards: true,
        hasCatering: true,
        hasDevices: true,
        hasEditAdmin: false,
        hasPayouts: false
    },
    staffRole: {
        hasDashboard: false,
        hasOrders: true,
        hasFinancialReports: false,
        hasMenuEditing: false,
        hasPromos: false,
        hasGuestbook: false,
        hasBilling: false,
        hasBillingWrite: false,
        hasEmployees: false,
        hasSettings: false,
        hasSalesReports: false,
        hasSettingsWrite: false,
        hasReportsMenu: false,
        hasReportsCustomers: false,
        hasReportsLabor: false,
        hasReportsLocations: false,
        hasReportsPromotions: false,
        hasReportsGiftCards: false,
        hasCatering: true,
        hasDevices: false,
        hasEditAdmin: false,
        hasPayouts: false
    },
    marketerRole: {
        hasDashboard: false,
        hasOrders: true,
        hasFinancialReports: false,
        hasMenuEditing: false,
        hasPromos: true,
        hasGuestbook: true,
        hasBilling: false,
        hasBillingWrite: false,
        hasEmployees: false,
        hasSettings: false,
        hasSalesReports: false,
        hasSettingsWrite: false,
        hasReportsMenu: true,
        hasReportsCustomers: true,
        hasReportsLocations: true,
        hasReportsLabor: false,
        hasReportsPromotions: true,
        hasReportsGiftCards: false,
        hasCatering: false,
        hasDevices: false,
        hasEditAdmin: false,
        hasPayouts: false
    },
    accountantRole: {
        hasDashboard: false,
        hasOrders: true,
        hasFinancialReports: true,
        hasMenuEditing: false,
        hasPromos: false,
        hasGuestbook: false,
        hasBilling: true,
        hasBillingWrite: false,
        hasEmployees: false,
        hasSettings: true,
        hasSalesReports: true,
        hasSettingsWrite: false,
        hasReportsMenu: true,
        hasReportsCustomers: true,
        hasReportsLabor: true,
        hasReportsLocations: true,
        hasReportsPromotions: true,
        hasReportsGiftCards: true,
        hasCatering: false,
        hasDevices: false,
        hasEditAdmin: false,
        hasPayouts: false
    },
    allFalseRole: {
        hasDashboard: false,
        hasOrders: false,
        hasFinancialReports: false,
        hasMenuEditing: false,
        hasPromos: false,
        hasGuestbook: false,
        hasBilling: false,
        hasBillingWrite: false,
        hasEmployees: false,
        hasSettings: false,
        hasSalesReports: false,
        hasSettingsWrite: false,
        hasReportsMenu: false,
        hasReportsCustomers: false,
        hasReportsLabor: false,
        hasReportsLocations: false,
        hasReportsPromotions: false,
        hasReportsGiftCards: false,
        hasCatering: false,
        hasDevices: false,
        hasEditAdmin: false,
        hasPayouts: false
    },
    reportsRoles: {
        hasReportsMenu: false,
        hasReportsCustomers: false,
        hasReportsLocations: false,
        hasReportsPromotions: false,
        hasReportsLabor: false,
        hasReportsGiftCards: false,
        hasSalesReports: false,
        hasFinancialReports: false
    }
};

export type StoreRoleTypes = {
    [key in
        | "EXEC"
        | "BACK_OF_HOUSE"
        | "FRONT_OF_HOUSE"
        | "SUPPORT"
        | "ADDDITIONAL"]: {
        name: string;
        roles: {
            [roleKey: string]: string;
        };
    };
};
// These are used to report user type to Segment (and they show up in the UI for searching etc)
export const StoreRole = {
    EXEC: {
        name: "Executive and Management",
        roles: {
            PROPRIETOR: "Proprietor",
            OPERATOR: "Operator",
            GENERAL_MANAGER: "General Manager",
            STORE_MANAGER: "Store Manager",
            SHIFT_LEADER: "Shift Leader",
            SUPERVISOR: "Supervisor"
        }
    },
    BACK_OF_HOUSE: {
        name: "Kitchen and Food Preparation",
        roles: {
            CHEF: "Chef",
            COOK: "Cook",
            BAKER: "Baker",
            KITCHEN_ASSISTANT: "Kitchen Assistant",
            PREP_COOK: "Prep Cook"
        }
    },
    FRONT_OF_HOUSE: {
        name: "Service and Front-of-House",
        roles: {
            SERVER: "Server",
            BARISTA: "Barista",
            CASHIER: "Cashier",
            BARTENDER: "Bartender",
            HOST: "Host/Hostess"
        }
    },
    SUPPORT: {
        name: "Support and Maintenance",
        roles: {
            MAINTENANCE: "Maintenance/Janitorial Staff",
            DISHWASHER: "Dishwasher",
            ADMIN_ASSIST: "Administrative Assistant"
        }
    },
    ADDDITIONAL: {
        name: "Additional",
        roles: {
            PART_TIME: "Part-Time Employee",
            TRAINEE: "Trainee/Intern",
            STAFF: "Staff",
            OTHER: "Other"
        }
    }
};

export const PermissionsPresets = {
    admin: {
        name: "Admin",
        desc: "Access full dashboard, manage team members, and perform kitchenOS actions."
    },
    manager: {
        name: "Manager",
        desc: "Access orders, reports, catering, guestbook, promos, team, devices, and settings."
    },
    staff: {
        name: "Staff",
        desc: "View-only access to orders, catering, devices, and store settings."
    },
    marketer: {
        name: "Marketer",
        desc: "View-only access to orders, customers, promos, guestbook, and store settings."
    },
    accountant: {
        name: "Accountant",
        desc: "View-only access to orders, reports, billing & payouts, and store settings."
    },
    employee: {
        name: "Time Clocking Only",
        desc: "Can use time card system but no access to this site."
    }
};

export interface PendingAdminInfo {
    adminFields: AdminFields;
    permissionUpdates: Record<string, boolean>;
    employeePermissions?: EmployeePermissions;
}

export const PermissionsList = [
    { label: "Dashboard Page", value: "hasDashboard" },
    { label: "Guestbook", value: "hasGuestbook" },
    { label: "Reports → Locations", value: "hasReportsLocations" },
    { label: "Reports → Sales", value: "hasSalesReports" },
    { label: "Reports → Menu", value: "hasReportsMenu" },
    { label: "Reports → Customers", value: "hasReportsCustomers" },
    { label: "Reports → Promotions", value: "hasReportsPromotions" },
    { label: "Reports → Financial", value: "hasFinancialReports" },
    { label: "Reports → Labor", value: "hasReportsLabor" },
    { label: "Reports → Gift Cards", value: "hasReportsGiftCards" },
    { label: "Orders", value: "hasOrders" },
    { label: "Menu", value: "hasMenuEditing" },
    { label: "Catering", value: "hasCatering" },
    { label: "Promos", value: "hasPromos" },
    { label: "Team", value: "hasEmployees" },
    { label: "Devices", value: "hasDevices" },
    {
        label: "Create, Edit, and Change Permissions for All Users",
        value: "hasEditAdmin"
    },
    { label: "Payouts", value: "hasPayouts" },
    { label: "Billing (Read)", value: "hasBilling" },
    { label: "Billing (Write)", value: "hasBillingWrite" },
    { label: "Store Settings (Read)", value: "hasSettings" },
    { label: "Store Settings (Write)", value: "hasSettingsWrite" }
];
