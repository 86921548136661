import { PaginationState, SortingState } from "@tanstack/react-table";
import { toast } from "sonner";
import Skeleton from "react-loading-skeleton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AccessorKeyColumnDef } from "@tanstack/react-table";
import { IPunchcard } from "@snackpass/snackpass-types";
import { getActiveStore } from "@snackpass/accounting";
import { useDispatch, useSelector } from "react-redux";
import { show } from "redux-modal/lib/actions";
import moment from "moment";
import { formatNumber as formatPhoneNumber } from "libphonenumber-js";
import { useLocation } from "react-router-dom";

import { OrderHistoryDrawer } from "#guestbook/components";
import { usePunchcardPagination } from "#reports/customer-directory-insights/hooks/use-punchcard-pagination";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import { PaginatedDataTable } from "src/@/components/ui/data-table";
import { DataTableToolbarOptions } from "src/@/components/ui/data-table/table-toolbar";
import useDebounce from "#reports/customer-directory-insights/hooks/use-debounce";
import EditLoyaltyInput from "#reports/customer-directory-insights/components/EditLoyaltyModal";
import { formatNumber, toDollarFormatted } from "#reports/sales-summary/lib";
import { Button } from "src/@/components/ui/button";
import { AddCustomerPage } from "#reports/customer-directory-insights/components/AddCustomerPage";
import { OrderDetailsDrawer } from "#guestbook/components/order-details";
import { Input } from "src/@/components/ui/input";

interface TableRow {
    name: string;
    credit: number;
    points: number;
    phone: string;
    punchcardId: string;
    userId: string;
    orders: number;
    totalSpent: number;
    lastOrder: Date;
}

function CustomerLoyalty() {
    const activeStore = useSelector(getActiveStore);
    const location = useLocation<{
        search: string;
        userId: string;
    }>();
    const [sorting, setSorting] = useState<SortingState>([]);
    const [search, setSearch] = useState(location?.state?.search ?? "");
    const [autoSidebarOpenOccured, setAutoSidebarOpenOccured] = useState(false);
    const debouncedSearch = useDebounce(search, 500);
    const [pagination, setPagination] = useState<PaginationState>({
        pageSize: 10,
        pageIndex: 0
    });
    const { data, isLoading, error, refetch } = usePunchcardPagination(
        pagination,
        sorting,
        debouncedSearch
    );

    const [showAddPage, setShowAddPage] = useState(false);

    useEffect(() => {
        if (error) {
            toast.error(`Error Loading Data`);
            console.error(error?.message);
        }
    }, [error]);

    const [selectedUserRow, setSelectedUserRow] = useState<
        TableRow | undefined
    >();

    const dispatch = useDispatch();
    const handleClickUser = useCallback(
        (row: TableRow) => {
            setSelectedUserRow(row);
            dispatch(show("OrderHistoryDrawer"));
        },
        [dispatch]
    );

    const renderName = (name: string, row: TableRow) => (
        <span
            className="ml-1 cursor-pointer text-success-light"
            onClick={() => handleClickUser(row)}
        >
            {name}
        </span>
    );

    const columns: AccessorKeyColumnDef<TableRow, string | number>[] = [
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2"
                    column={column}
                    title="Name"
                />
            ),
            accessorKey: "name",
            cell: (props) =>
                renderName(props.getValue() as string, props.row.original),
            id: "name"
        },
        {
            header: ({ column }) => (
                <>
                    <DataTableColumnHeader
                        className="my-2 ml-2"
                        column={column}
                        title="Phone Number"
                        disableSort={true}
                    />
                </>
            ),
            accessorKey: "phone",
            id: "Phone Number",
            cell: (props) => (
                <div className="ml-2">
                    {formatPhoneNumber(
                        props.row.original.phone,
                        "US",
                        "NATIONAL"
                    )}
                </div>
            )
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2 ml-24"
                    column={column}
                    title="Store Credit"
                />
            ),
            accessorKey: "credit",
            id: "credit",
            cell: (props) => (
                <div className="ml-24 pl-1">
                    {toDollarFormatted(props.row.original.credit)}
                </div>
            )
        },
        {
            header: ({ column }) => (
                <DataTableColumnHeader
                    className="my-2 ml-24"
                    column={column}
                    title="Points Balance"
                />
            ),
            accessorKey: "points",
            id: "points Balance",
            cell: (props) => (
                <div className="ml-24 pl-1">
                    {formatNumber(props.row.original.points)}
                </div>
            )
        },
        {
            accessorKey: "",
            id: "edit options",
            cell: (props) => (
                <div className="flex justify-end">
                    <EditLoyaltyInput
                        punchcardId={props.row.original.punchcardId}
                        points={props.row.original.points}
                        credit={props.row.original.credit}
                        refetch={refetch}
                    />
                </div>
            )
        }
    ];

    const rows = useMemo(() => {
        if (data?.punchcards?.length > 0) {
            return data?.punchcards.map((punchcard: IPunchcard) => ({
                name: punchcard?.user?.name ?? "",
                credit: punchcard.credit,
                points: punchcard.pointsBalance,
                punchcardId: punchcard._id,
                userId: punchcard?.user?._id,
                orders: punchcard.totalPurchases,
                totalSpent: punchcard.totalAmountSpent,
                lastOrder: punchcard.lastPurchaseDate,
                phone: punchcard?.user?.number ?? ""
            }));
        } else {
            return [];
        }
    }, [data]);

    useEffect(() => {
        if (
            location?.state?.userId &&
            rows?.length > 0 &&
            !autoSidebarOpenOccured
        ) {
            const userRow = rows.find(
                (row: TableRow) => row.userId === location?.state?.userId
            );
            setSelectedUserRow(userRow);
            dispatch(show("OrderHistoryDrawer"));
            setAutoSidebarOpenOccured(true);
        }
    }, [dispatch, location?.state?.userId, rows, autoSidebarOpenOccured]);

    // Reset pagination to first page when search or sorting is applied, or store changes
    useEffect(() => {
        if (setPagination) {
            setPagination((pagination) => ({
                pageIndex: 0,
                pageSize: pagination.pageSize
            }));
        }
        void refetch();
    }, [debouncedSearch, sorting, setPagination, refetch, activeStore]);

    const toolbarOptions: DataTableToolbarOptions = {
        showColumnFilter: true
    };

    return showAddPage ? (
        <AddCustomerPage setPage={setShowAddPage} />
    ) : (
        <div className="flex flex-col">
            <div className="relative p-6 md:px-24">
                <h4 className="pb-4 text-large">Customer Loyalty</h4>
                <div className="mb-4">
                    <Button
                        className=" mr-2 border"
                        variant={"outline"}
                        onClick={() => {
                            setShowAddPage(true);
                        }}
                    >
                        Add Customer
                    </Button>
                </div>
                {selectedUserRow && (
                    <>
                        <OrderHistoryDrawer
                            userId={selectedUserRow?.userId}
                            chatMetadata={{
                                points: selectedUserRow?.points,
                                userLastPurchase: moment(
                                    selectedUserRow?.lastOrder
                                ).format("M/DD/YY")
                            }}
                            nameOverride={selectedUserRow?.name}
                            showGuestbookLink
                            showPurchaseReportLink
                            showContactInfo
                        />
                        <OrderDetailsDrawer />
                    </>
                )}

                <div className="relative">
                    <Input
                        className="absolute h-8 w-72"
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                    />
                    {isLoading ? (
                        <Skeleton className="mt-12 h-96 rounded-md" />
                    ) : data?.punchcards ? (
                        <PaginatedDataTable
                            columns={columns}
                            data={rows}
                            showPagination
                            manualPagination={true}
                            manualFiltering={true}
                            manualSorting={true}
                            toolbar={toolbarOptions}
                            pageCount={-1}
                            pagination={pagination}
                            setPagination={setPagination}
                            sorting={sorting}
                            setSorting={setSorting}
                        />
                    ) : (
                        <ErrorChart className="absolute z-20 h-96 w-full rounded-md" />
                    )}
                </div>
            </div>
            <div className="px-6 md:px-24"></div>
        </div>
    );
}

export default CustomerLoyalty;
