import React from "react";
import { IProduct } from "@snackpass/snackpass-types";
import { Drawer as AntdDrawer } from "antd";
import { compose } from "lodash/fp";
import { useDispatch } from "react-redux";
import { connectModal, InjectedProps } from "redux-modal";
import { useMediaQuery } from "react-responsive";

import useWindowDimensions from "#hooks/use-window-dimensions";

import { updateMessageInputVariableData } from "../../redux";
import { ProductsList } from "../products";
import { CloseButton } from "../styled-components";

type DrawerProps = {
    variableId: string;
} & InjectedProps;

const MenuDrawerComponent = ({ handleHide, show, variableId }: DrawerProps) => {
    const dispatch = useDispatch();
    const _updateMessageInput = compose(
        dispatch,
        updateMessageInputVariableData
    );

    const isMobile = useMediaQuery({
        query: `(max-width: 990px)`
    });

    const { width } = useWindowDimensions();

    const _onSelect = (product: IProduct) => {
        _updateMessageInput({
            id: variableId,
            data: {
                type: "product",
                label: product.name,
                product: product
            }
        });

        handleHide();
    };

    return (
        <AntdDrawer
            width={isMobile ? width : 450}
            placement="right"
            onClose={handleHide}
            open={show}
            closable={false}
            destroyOnClose={false}
            // so the first animation when the drawer is triggered is smooth
            forceRender={true}
        >
            <CloseButton onClick={handleHide} />

            <div
                style={{
                    paddingBottom: 100
                }}
            >
                <ProductsList onSelect={_onSelect} />
            </div>
        </AntdDrawer>
    );
};

export const MenuDrawer = connectModal({
    name: "MenuDrawer",
    destroyOnHide: false
})(MenuDrawerComponent);
