import React from "react";

export const Header = ({
    title,
    titleSubtext,
    subtitle
}: {
    title: string;
    titleSubtext?: string;
    subtitle?: string;
}) => (
    <div>
        <div className="flex flex-col gap-2 sm:flex-row">
            <div className="pb-2 text-large font-semibold">{title}</div>
            {titleSubtext && <div className="opacity-40">{titleSubtext}</div>}
        </div>
        {subtitle && (
            <div className="mt-0 text-body text-[#585B5F]">{subtitle}</div>
        )}
    </div>
);
