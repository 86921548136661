import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { IPromotion } from "@snackpass/snackpass-types";
import { useSelector } from "react-redux";

import { togglePromotionActive } from "#promotion/utils/fetch";
import { getActiveStore } from "src/redux/selectors/activeStore";
import { PROMOTION_QUERY_KEY } from "#promotion/hooks/usePromotions";

export const useTogglePromotionActive = () => {
    const activeStore = useSelector(getActiveStore);
    const queryClient = useQueryClient();

    const { mutate: toggleActive, isPending } = useMutation({
        onError: (err) => {
            toast.error("Couldn't toggle promotion active", {
                description:
                    (err as AxiosError)?.message ?? "Unknown error. Try again."
            });
        },
        mutationFn: async ({
            promotionId,
            isRunning
        }: {
            promotionId: string;
            isRunning: boolean;
        }) => {
            const editedPromo = await togglePromotionActive(
                promotionId,
                isRunning
            );
            return editedPromo;
        },
        onSuccess(data: IPromotion) {
            queryClient.setQueryData(
                [activeStore?._id, PROMOTION_QUERY_KEY],
                (promotions: IPromotion[]) =>
                    promotions.map((e) => (e._id === data._id ? data : e))
            );
            toast.success(
                `Toggled ${data.name} to ${
                    data.isRunning ? "active" : "inactive"
                }`
            );
        }
    });

    return {
        toggleActive,
        isPending
    };
};
