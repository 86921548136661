import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";
import { getActiveStore } from "@snackpass/accounting";
import clsx from "clsx";

import { Button } from "src/@/components/ui/button";
import Text from "#devices/components/Text";
import VisuallyHidden from "#devices/components/VisuallyHidden";
import {
    deviceOptions,
    DeviceOptionsType,
    getDeviceCreateLabel
} from "#devices/utils/deviceOptions";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { DeviceType } from "#devices/utils/deviceTypes";
import useWindowDimensions from "#hooks/use-window-dimensions";
import colors from "#reusable/colors/colors.json";

const DeviceOption = styled.label<{ checked: boolean }>`
    // use box-shadow instead of border because stroke width changes when checked,
    // so items would shift layout when checked
    box-shadow: ${({ checked }) =>
        checked
            ? `0px 0px 0px 2px ${SystemColors.v2.salt80.light}`
            : `0px 0px 0px 1px ${colors["neutral-400"]}`};
    border-radius: ${({ theme }) => theme.spacing.base};
    padding: ${({ theme }) => `${theme.spacing.base}`};
    height: 100%;

    cursor: pointer;

    display: flex;
    align-items: center;
    flex-direction: row;
    transition: background 0.2s ease-in-out;

    &:hover {
        background: ${colors["neutral-200"]};
    }

    & svg {
        fill: currentColor;
        width: ${({ theme }) => theme.spacing.base};
        height: ${({ theme }) => theme.spacing.base};
        margin-right: ${({ theme }) => theme.spacing.base};
    }

    @media ${ScreenState.MOBILE} {
        flex-direction: column;

        & svg {
            margin-right: 0;
        }
    }
`;

const Step1 = (): JSX.Element => {
    const { addNewDeviceTypeValue, setAddNewDeviceTypeValue, nextStep } =
        useContext(DevicesPageContext);
    const activeStore = useSelector(getActiveStore);
    const storeName = activeStore?.name;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddNewDeviceTypeValue(event.target.value);
    };

    const { isMobile } = useWindowDimensions();

    const disabled = addNewDeviceTypeValue.length === 0;

    const Footer = () => (
        <Modal.Footer>
            <div className="flex flex-1 flex-col items-center justify-center gap-2">
                <span>{storeName}</span>
                <Button
                    className="w-full"
                    onClick={() => nextStep()}
                    disabled={disabled}
                >
                    Continue
                </Button>
            </div>
        </Modal.Footer>
    );

    const RenderDevice = ({ device }: { device: DeviceOptionsType }) => (
        <div key={device.value}>
            <DeviceOption
                checked={addNewDeviceTypeValue === device.value}
                htmlFor={device.value}
                className={clsx(
                    device.label.includes("Legacy") && "bg-neutral-200"
                )}
            >
                <div>{device.icon}</div>
                <VisuallyHidden>
                    <input
                        type="radio"
                        name="deviceType"
                        id={device.value}
                        value={device.value}
                        checked={addNewDeviceTypeValue === device.value}
                        onChange={handleChange}
                    />
                </VisuallyHidden>
                <Text.Label>
                    {getDeviceCreateLabel(device.value, isMobile)}
                </Text.Label>
            </DeviceOption>
        </div>
    );

    return (
        <>
            <Modal.Body>
                <form>
                    <Text.Title2>Add Device</Text.Title2>
                    <Text.LargeBody>
                        Choose how you will use this device.
                    </Text.LargeBody>
                    <div className="grid auto-rows-fr grid-cols-2 gap-4">
                        {deviceOptions
                            .filter((o) => o.value !== DeviceType.All)
                            .map((device) => (
                                <RenderDevice
                                    device={device}
                                    key={device.value}
                                />
                            ))}
                    </div>
                </form>
            </Modal.Body>
            <Footer />
        </>
    );
};

export default Step1;
