import { CateringStatusType, IPurchase } from "@snackpass/snackpass-types";
import { Spinner } from "react-activity";
import { SystemColors } from "@snackpass/design-system";
import { useCallback, useState } from "react";
import moment from "moment-timezone";

import { useCateringPurchaseReportRows } from "src/api/rest/usePurchaseReports";
import api from "src/api/rest";

import { BorderContainer, Button, Title } from "./ui";

interface Props {
    purchase: IPurchase;
}

export const ConfirmCatering = ({ purchase }: Props) => {
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);
    const { refetch } = useCateringPurchaseReportRows();

    const handleAccept = useCallback(async () => {
        if (acceptLoading) {
            return;
        }
        try {
            setAcceptLoading(true);
            await api.purchases.updateCateringStatus(
                purchase._id,
                CateringStatusType.confirmed
            );
            refetch();
        } finally {
            setAcceptLoading(false);
        }
    }, [acceptLoading]);

    const handleReject = useCallback(async () => {
        if (rejectLoading) {
            return;
        }
        try {
            setRejectLoading(true);
            await api.purchases.updateCateringStatus(
                purchase._id,
                CateringStatusType.rejected
            );
            refetch();
        } finally {
            setRejectLoading(false);
        }
    }, [rejectLoading]);

    if (
        !purchase?.catering?.isCatering ||
        purchase.catering.status !== CateringStatusType.unconfirmed
    )
        return null;

    return (
        <BorderContainer>
            <Title>Confirm Scheduled Time</Title>
            <Button onClick={handleAccept}>
                {acceptLoading ? (
                    <Spinner color={SystemColors.v1.white} size={16} />
                ) : (
                    <Title style={{ color: SystemColors.v1.white }}>
                        Confirm {"("}
                        {moment(purchase.scheduledDate).format(
                            "MMM Do, hh:mm A z"
                        )}
                        {")"}
                    </Title>
                )}
            </Button>
            <Button
                onClick={handleReject}
                style={{ backgroundColor: SystemColors.v1.gray80 }}
            >
                {rejectLoading ? (
                    <Spinner color={SystemColors.v1.sesame} size={16} />
                ) : (
                    <Title>Reject Order</Title>
                )}
            </Button>
        </BorderContainer>
    );
};
