import { Button } from "src/@/components/ui/button";

export const OrderSupplies = () => (
    <div className="m-12">
        <h5 className="mb-4 font-bold">Order Supplies</h5>
        <div className="flex w-1/2 flex-col md:w-1/4">
            <Button
                className="m-4 w-full"
                onClick={() => {
                    window.open(
                        "https://snackpass-customer-store.myshopify.com/",
                        "_blank"
                    );
                }}
            >
                Open Store
            </Button>

            <Button
                className="m-4 w-full"
                onClick={() => {
                    window.open(
                        "https://snackpass-customer-store.myshopify.com/products/snackpass-round-sticker-roll",
                        "_blank"
                    );
                }}
            >
                Stickers
            </Button>
            <Button
                className="m-4 w-full"
                onClick={() => {
                    window.open(
                        "https://support.snackpass.co/en/articles/7180969-printing#h_8294a0c6c0",
                        "_blank"
                    );
                }}
            >
                Sticky Labels
            </Button>
            <Button
                className="m-4 w-full"
                onClick={() => {
                    window.open(
                        "https://snackpass-customer-store.myshopify.com/products/receipt-paper-roll",
                        "_blank"
                    );
                }}
            >
                Receipt Paper
            </Button>
        </div>
    </div>
);
