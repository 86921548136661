import { formatDayOfWeek } from "#reusable/special-hours/helper";
import { DateTime } from "luxon";

type HourRange = {
    start: number;
    end: number;
};
const DAY_END = 24 * 60 - 1; // minute right before midnight

export const formatMinutes = (minutes: number): string =>
    DateTime.now().startOf("day").plus({ minutes: minutes }).toFormat("HH:mm");

export const timeToLuxon = (newTime: string) => {
    const [hours, minutes] = newTime.split(":");
    const newMinutes = parseInt(hours) * 60 + parseInt(minutes);
    return DateTime.now().startOf("day").plus({ minutes: newMinutes });
};

export const getDayMinutesFromLuxon = (dateObject: DateTime) =>
    dateObject.diff(dateObject.startOf("day")).as("minutes");

export const getMinutesFromStartOfWeek = (day: number, minutes: number) =>
    (24 * 60 * day + minutes) % (60 * 24 * 7); // minutes in a week

export const getMinutesFromStartOfDay = (rawMinutes: number) =>
    rawMinutes % (24 * 60);

export const nextDay = (day: number) => (day + 1) % 7;

export const defaultStartHour = (day: number) =>
    getMinutesFromStartOfWeek(day, 60 * 9); // 9am
export const defaultEndHour = (day: number) =>
    getMinutesFromStartOfWeek(day, 60 * (12 + 5)); // 5pm

export const getNewHours = (val: HourRange[], start: number, end: number) => [
    ...val,
    {
        start: start,
        end: end
    }
];

export const getHoursWithout = (
    value: HourRange[],
    rowWeekday: number,
    hour: HourRange
) => {
    if (hour.end <= DAY_END) {
        const rowHours = {
            start: getMinutesFromStartOfWeek(rowWeekday, hour.start),
            end: getMinutesFromStartOfWeek(rowWeekday, hour.end)
        };

        return value.filter(
            (item) => item.start !== rowHours.start || item.end !== rowHours.end
        );
    } else {
        // if hours extend past midnight
        const beforeMidnightHours = {
            start: getMinutesFromStartOfWeek(rowWeekday, hour.start),
            end: getMinutesFromStartOfWeek(rowWeekday, DAY_END)
        };
        const afterMidnightHours = {
            start: getMinutesFromStartOfWeek(nextDay(rowWeekday), 0),
            end: getMinutesFromStartOfWeek(
                nextDay(rowWeekday),
                getMinutesFromStartOfDay(hour.end)
            )
        };

        return value.filter(
            (item) =>
                !(
                    item.start === beforeMidnightHours.start &&
                    item.end === beforeMidnightHours.end
                ) &&
                !(
                    item.start === afterMidnightHours.start &&
                    item.end === afterMidnightHours.end
                )
        );
    }
};

export const filterOutClosedHours = (value: HourRange[], openDays: number[]) =>
    value.filter((hour) => openDays.includes(formatDayOfWeek(hour.start)));
