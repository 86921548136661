import React, { useEffect, useRef, useState } from "react";

import { Button } from "src/@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "src/@/components/ui/dialog";
import { cn } from "src/@/lib/utils";

interface ConfirmModalProps {
    setShowConfirmModal: (show: boolean) => void;
    showConfirmModal: boolean;
    confirmAction: (() => void) | null;
    title: string;
    body: React.ReactNode;
}

// see https://stackoverflow.com/questions/876115/how-can-i-determine-if-a-div-is-scrolled-to-the-bottom, some ideas suggest not to exact match and to allow a few pixels of tolerance.
const SCROLL_TOLERANCE_PIXELS = 5;

const ConfirmModal: React.FC<ConfirmModalProps> = ({
    setShowConfirmModal,
    showConfirmModal,
    confirmAction,
    title,
    body
}) => {
    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
    const scrollAreaRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        const scrollElement = scrollAreaRef.current;
        if (scrollElement) {
            const atBottom =
                scrollElement.scrollHeight -
                    scrollElement.scrollTop -
                    scrollElement.clientHeight <
                SCROLL_TOLERANCE_PIXELS;
            if (atBottom) {
                setHasScrolledToBottom(true);
            }
        }
    };

    useEffect(() => {
        if (!showConfirmModal) {
            // Reset state when modal is closed
            setHasScrolledToBottom(false);
        }
    }, [showConfirmModal]);

    useEffect(() => {
        const scrollElement = scrollAreaRef.current;
        if (scrollElement) {
            scrollElement.addEventListener("scroll", handleScroll);
            return () => {
                scrollElement.removeEventListener("scroll", handleScroll);
            };
        }
    }, []);

    const handleConfirm = () => {
        if (confirmAction) {
            confirmAction();
        }
        setShowConfirmModal(false);
    };

    return (
        <Dialog open={showConfirmModal} onOpenChange={setShowConfirmModal}>
            <DialogContent
                className={cn(
                    `gap-0 bg-white w-[100%] h-[100%] pb-2 lg:pb-4 md:min-w-[50%] md:min-h-[300px] md:max-h-[718px] px-0 overflow-hidden z-[9999]`
                )}
            >
                <DialogHeader className="h-[40px] w-full items-center justify-center  border-b border-neutral-400 ">
                    <DialogTitle className="mb-6 text-body font-semibold">
                        {title}
                    </DialogTitle>
                </DialogHeader>
                <div
                    className="relative z-10 flex max-h-[760px] min-h-[300px] flex-col overflow-y-scroll px-6 pt-2"
                    ref={scrollAreaRef}
                    onScroll={handleScroll}
                >
                    {body}
                </div>
                {confirmAction ? (
                    <DialogFooter className="items-center gap-1 border-t px-6 py-3 sm:self-end md:mb-0 md:pb-0 md:pt-4">
                        <Button
                            className="w-[100%] sm:w-fit"
                            variant="secondary"
                            onClick={() => setShowConfirmModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="w-[100%]"
                            variant={
                                hasScrolledToBottom ? "default" : "secondary"
                            }
                            onClick={handleConfirm}
                            disabled={!hasScrolledToBottom}
                        >
                            {hasScrolledToBottom
                                ? "Accept & Confirm"
                                : "Scroll to Accept"}
                        </Button>
                    </DialogFooter>
                ) : null}
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmModal;
