import { useCallback, useEffect, useState } from "react";
import { Primitive } from "type-fest";

/**
 * this should be used to separate any async
 *  methods from stateful component updates
 *
 * for example:
 * ```ts
 *     useEffect(
 () => {
            (async () => {
                if (isSnackpassTeam) {
                    fp.compose(
                        setAvailableStores,
                        _formatOptions,
                        _filterArchivedStores
                    )(stores);
                } else if (user.hasOwnProperty("snackpassPermissions")) {
                    const myStores = await _getMyStores(user);
                    fp.compose(
                        setAvailableStores,
                        _formatOptions,
                        _filterArchivedStores
                    )(myStores);
                }
            })();
        },
 [
 isSnackpassTeam,
 user._id,
 stores.map((store) => store._id).join(""),
 ] as Primitive[]
 );
 * ```
 * becomes
 * ```ts
 * // for some reason this needs to be async and await
 * // you can't just pass back the promise 🤷‍♀️
 * const fetch = async () => {
        if (isSnackpassTeam) {
            return;
        } else if (user.hasOwnProperty("snackpassPermissions")) {
            return await _getMyStores(user);
        }
    };

 * useAsync(
 fetch,
 (res) => {
            if (!res) {
                fp.compose(
                    setAvailableStores,
                    _formatOptions,
                    _filterArchivedStores
                )(stores);
            } else {
                fp.compose(
                    setAvailableStores,
                    _formatOptions,
                    _filterArchivedStores
                )(res);
            }
        },
 [
 isSnackpassTeam,
 user._id,
 stores.map((store) => store._id).join(""),
 ] as Primitive[]
 );
 * ```
 *
 * this separates the actual fetching of stores
 * and the following state update using setAvailableStores
 *
 * if this is not done then if the component is unmounted
 * while fetching stores it will perform a state update
 * after unmounting. Which, is a memory leak and should
 * be avoided.
 */
export const useAsync = <
    DependencyList extends (Primitive | ReturnType<typeof useCallback>)[],
    AsyncReturn,
    AsyncProps
>(
    asyncFn: (...props: AsyncProps[]) => PromiseLike<AsyncReturn> | undefined,
    onSuccess: ((props: AsyncReturn | undefined) => any) | null,
    dependencyList: DependencyList
) => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        let isActive = true;
        try {
            asyncFn()?.then((data: AsyncReturn) => {
                if (isActive) {
                    if (onSuccess) onSuccess(data);
                    setLoading(false);
                }
            });
        } catch (err) {
            if (isActive) {
                setLoading(false);
            }
            throw err;
        }

        return () => {
            isActive = false;
        };
    }, [...dependencyList]);
    return { loading };
};
