import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import { DataTable } from "src/@/components/ui/data-table";
import { GiftCardLiabilitiesRow } from "#reports/gift-card-liabilities/types";
import { columns } from "#reports/gift-card-liabilities/lib";
import { useGiftCardReportQuery } from "src/api/rest/report-hooks";
import {
    GiftCardLiabilityReport,
    GiftCardReportEndpoints
} from "src/api/rest/report-hooks/types";

type Props = {
    isUsageStoreCoversDiscount: boolean;
};
const LiabilitiesTable = ({ isUsageStoreCoversDiscount }: Props) => {
    const { data, isLoading, isError } =
        useGiftCardReportQuery<GiftCardLiabilityReport>({
            endpoint: GiftCardReportEndpoints.Liabilities
        });

    const giftCardLiabilities = data?.giftCardLiabilityReport ?? null;

    const rows: GiftCardLiabilitiesRow[] = useMemo(() => {
        if (!giftCardLiabilities || giftCardLiabilities === null) return [];

        // Group rows with the same store
        const rows: GiftCardLiabilitiesRow[] = giftCardLiabilities.reduce<
            GiftCardLiabilitiesRow[]
        >((acc, row) => {
            const receivable = isUsageStoreCoversDiscount
                ? row.receivableAmountCentsUsageStoreCovers
                : row.receivableAmountCents;
            const payable = isUsageStoreCoversDiscount
                ? row.payableAmountCentsUsageStoreCovers
                : row.payableAmountCents;
            const existingRow = acc.find((r) => r.storeId === row.storeId);
            if (existingRow) {
                existingRow.receivable += receivable;
                existingRow.payable += payable;
                existingRow.net = existingRow.receivable - existingRow.payable;

                // Find subrow and accumulate
                const subRow = existingRow.subRows?.find(
                    (r) => r.storeId === row.otherStoreId
                );
                if (subRow) {
                    subRow.receivable += receivable;
                    subRow.payable += payable;
                    subRow.net = subRow.receivable - subRow.payable;
                } else {
                    existingRow.subRows?.push({
                        storeId: row.otherStoreId,
                        storeName: row.otherStoreName,
                        isHeadstore: row.otherStoreHeadstore,
                        receivable: receivable,
                        payable: payable,
                        net: receivable - payable
                    });
                }
            } else {
                acc.push({
                    storeId: row.storeId,
                    storeName: row.storeName,
                    isHeadstore: row.storeHeadstore,
                    receivable: receivable,
                    payable: payable,
                    net: receivable - payable,
                    subRows: [
                        {
                            storeId: row.otherStoreId,
                            storeName: row.otherStoreName,
                            isHeadstore: row.otherStoreHeadstore,
                            receivable: payable,
                            payable: receivable,
                            net: payable - receivable
                        }
                    ]
                });
            }
            return acc;
        }, []);

        return rows;
    }, [giftCardLiabilities, isUsageStoreCoversDiscount]);

    return (
        <div className="m-2 mb-20">
            <div>
                {!isLoading && (isError || data) ? (
                    data ? (
                        <DataTable
                            columns={columns}
                            data={rows}
                            customPageSize={100}
                        />
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

export default LiabilitiesTable;
