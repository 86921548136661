import React from "react";

import { Text } from "#reusable/text/index";
import { StyledRow } from "#settings/components/shared-component";

import { Input } from "./Input";

type Props = {
    title: string;
    subtitle: string;
    inputProps: React.InputHTMLAttributes<HTMLInputElement>;
};

export const SettingsEmailInput = ({ title, subtitle, inputProps }: Props) => (
    <StyledRow>
        <div className="flex flex-col">
            <div className="mb-2">
                <Text size="m">{title}</Text>
                <Text size="s" color="light-grey" weight="thin">
                    {subtitle}
                </Text>
            </div>
            <div className="w-72">
                <Input {...inputProps} />
            </div>
        </div>
    </StyledRow>
);
