/** @jsxImportSource @emotion/react */
import { SystemColors } from "@snackpass/design-system";
import React from "react";
import { Spinner } from "react-activity";
import styled from "styled-components";

const LoadingPage = () => (
    <Container>
        <Spinner color={SystemColors.v1.candy50} size={32} />
    </Container>
);

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default LoadingPage;
