import { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";

import { Badge } from "src/@/components/ui/badge";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import {
    formatPromoDetails,
    formatPromoDiscount,
    formatPromoType
} from "#promotion/lib";
import { PromotionsReportRow } from "#reports/promotions/types";
import { formatNumber, toDollarFormatted } from "#reports/sales-summary/lib";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";

const typeAmountDescription = (row: PromotionsReportRow) => {
    const promo = row.promotionContext;

    const type = formatPromoType(promo);
    const discount = formatPromoDiscount(promo);
    const details = formatPromoDetails(promo);

    return (
        <>
            <div>
                {type} · {discount} {details}
            </div>
        </>
    );
};

export const columns: ColumnDef<PromotionsReportRow>[] = [
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="mx-2 my-3"
                column={column}
                title="Promotion Name"
            />
        ),
        id: "name",
        accessorKey: "name",
        accessorFn: (row) => row.promotionContext?.name,
        cell: (info) => (
            <div className="ml-2">
                <div className="font-bold">
                    <>{info.getValue()}</>
                </div>
                {typeAmountDescription(info.row.original)}
            </div>
        ),
        enableHiding: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader className="" column={column} title="Type" />
        ),
        accessorFn: (e) => e.promotionContext?.type,
        id: "type",
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        enableHiding: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="Customers"
            />
        ),
        accessorKey: "totalUniqueCustomers",
        id: "Customers",
        cell: (info) => formatNumber(info.row.original.totalUniqueCustomers)
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title={
                    <>
                        First Time Customers
                        <ReportsTooltip
                            body={
                                "Number of customers who used this promotion on their first order."
                            }
                        />
                    </>
                }
            />
        ),
        accessorKey: "firstTimeCustomers",
        id: "First Time Customers",
        cell: (info) => formatNumber(info.row.original.firstTimeCustomers)
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader className="" column={column} title="Uses" />
        ),
        accessorKey: "totalUses",
        id: "Uses",
        cell: (info) => formatNumber(info.row.original.totalUses)
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="Gross Sales"
            />
        ),
        accessorKey: "discountGrossSales",
        id: "Gross Sales",
        cell: (info) => toDollarFormatted(info.row.original.discountGrossSales)
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="Discounts"
            />
        ),
        accessorKey: "discountCost",
        id: "Discounts",
        cell: (info) => `-${toDollarFormatted(info.row.original.discountCost)}`
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title={
                    <>
                        Net Sales
                        <ReportsTooltip
                            contentClassName="w-max"
                            body={"Sales after discounts."}
                        />
                    </>
                }
            />
        ),
        accessorKey: "discountNetSales",
        id: "Net Sales",
        cell: (info) => toDollarFormatted(info.row.original.discountNetSales)
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title={
                    <>
                        Avg. Order Size
                        <ReportsTooltip
                            body={
                                "The average number of items in orders where this promotion applied."
                            }
                        />
                    </>
                }
            />
        ),
        accessorKey: "averageOrderSize",
        id: "Average Order Size",
        cell: (info) => info.row.original.averageOrderSize.toFixed(2)
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="Status"
            />
        ),
        accessorFn: (e) => !!e.promotionContext?.isRunning,
        id: "Status",
        cell: (info) => (
            <>
                <Badge
                    variant="outline"
                    className={clsx(
                        info.getValue() ? "border-green-medium" : ""
                    )}
                >
                    {info.getValue() ? "Live" : "Ended"}
                </Badge>
            </>
        ),
        filterFn: (row, id, value) => value.includes(row.getValue(id))
    }
    /*
    {
        id: "Action",
        cell: info => <PromotionPerformanceTableDialog row={info.row.original} />
    }
    */
];

export const columnsForExport = [
    {
        dataIndex: "name",
        title: "Name"
    },
    {
        dataIndex: "type",
        title: "Type"
    },
    {
        dataIndex: "discount",
        title: "Details"
    },
    {
        dataIndex: "totalUses",
        title: "Usage"
    },
    {
        dataIndex: "firstTimeCustomers",
        title: "First Time Customers"
    },
    {
        dataIndex: "totalUniqueCustomers",
        title: "Customers"
    },
    {
        dataIndex: "discountGrossSales",
        title: "Gross Sales"
    },
    {
        dataIndex: "discountCost",
        title: "Discounted"
    },
    {
        dataIndex: "discountNetSales",
        title: "Net Sales"
    },
    {
        dataIndex: "averageOrderSize",
        title: "Avg. Order Size"
    },
    {
        dataIndex: "active",
        title: "Status"
    }
];

export const formatRowsForExport = (
    rows: PromotionsReportRow[]
): { [key: string]: string }[] =>
    rows.map((e) => ({
        name: e.promotionContext.name ?? "",
        type: formatPromoType(e.promotionContext),
        discount: e.promotionContext
            ? `${formatPromoDiscount(e.promotionContext)} ${formatPromoDetails(
                  e.promotionContext
              )}`
            : "",
        totalUses: formatNumber(e.totalUses),
        totalUniqueCustomers: formatNumber(e.totalUniqueCustomers),
        firstTimeCustomers: formatNumber(e.firstTimeCustomers),
        discountGrossSales: toDollarFormatted(e.discountGrossSales),
        discountCost: toDollarFormatted(e.discountCost),
        discountNetSales: toDollarFormatted(e.discountNetSales),
        averageOrderSize: toDollarFormatted(e.averageOrderSize),
        active: e.promotionContext.isRunning ? "Live" : "Ended"
    }));
