import { Divider } from "antd";
import React, { useContext } from "react";
import styled from "styled-components";

import useWindowDimensions from "#hooks/use-window-dimensions";
import { SettingsBlock } from "#payouts/components/shared/SettingsBlock";
import { Setting } from "#payouts/domain/types";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { fieldMapper } from "#payouts/utils/fieldMapper";
import { formatPhone } from "#payouts/utils/formatPhone";
import { useCompany } from "#payouts/utils/hooks/useCompany";

export const CompanySummary = () => {
    const { isDesktop } = useWindowDimensions();
    const { editEnabled, verification, handleModalChange } = useContext(
        PayoutsSettingsContext
    );

    const company = useCompany();

    const settings: Setting[] = company.started
        ? [
              {
                  field: "name",
                  label: "Legal Business Name",
                  value:
                      verification?.name === "pending"
                          ? "Pending"
                          : verification?.name
              },
              {
                  field: "taxId",
                  label: isDesktop
                      ? "Federal Employer Identification Number (F-EIN)"
                      : "Employer Identification Number (EIN)",
                  value: verification?.taxId,
                  encrypted: true
              },
              {
                  field: verification?.address?.pending
                      ? "address-pending"
                      : "address",
                  label: "Registered Business Address",
                  value: verification?.address?.pending
                      ? "Pending"
                      : verification?.address
              },
              {
                  field: "url",
                  label: "Business Website",
                  value:
                      verification?.url === "pending"
                          ? "Pending"
                          : verification?.url
              },
              {
                  field: "phone",
                  label: "Business Phone Number",
                  value:
                      verification?.phone === "pending"
                          ? "Pending"
                          : formatPhone(verification?.phone)
              }
          ]
        : [];

    if (verification?.dba) {
        settings.splice(2, 0, {
            field: "dba",
            label: "Doing Business As",
            value:
                verification?.dba === "pending" ? "Pending" : verification?.dba
        });
    }

    // Map reason strings for any verification errors
    const fieldsWithErrors =
        verification?.verificationErrors?.reduce(
            (acc, e) => {
                const field = fieldMapper(e.requirement);
                acc[field] = e.reason;
                return acc;
            },
            {} as { [key: string]: string }
        ) ?? {};

    settings.forEach((s) => (s.error = fieldsWithErrors[s.field]));

    return (
        <SummaryDiv>
            <SettingsBlock
                disabled={!editEnabled}
                start={!company.started}
                title={
                    <div className="my-auto flex flex-row justify-center gap-2">
                        <span className="my-auto flex flex-col">Company</span>
                    </div>
                }
                onClick={() => handleModalChange({ company: true })}
                settings={settings}
            />
            {company.started ? <Divider /> : <></>}
        </SummaryDiv>
    );
};

const SummaryDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
`;
