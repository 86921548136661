import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EditorState } from "draft-js";
import { DiscountType, IProduct } from "@snackpass/snackpass-types";

import { Audience, CampaignState, CampaignType, PromoType } from "../types";

export const campaignInitialState: CampaignState = {
    title: null,
    message: null,
    audience: null,
    type: null,
    discount: {
        type: DiscountType.Percent,
        promoType: PromoType.Storewide,
        percentOff: 25,
        dollarsOff: 1,
        expiresInDays: 7,
        purchaseRequired: false,
        maximumUses: undefined,
        product: null
    },
    variables: [],
    activeCampaignId: ""
};

export const campaignSlice = createSlice({
    name: "campaign",
    initialState: campaignInitialState,
    reducers: {
        setCampaignMessage: (state, action: PayloadAction<EditorState>) => {
            state.message = action.payload;
        },
        setCampaignAudience: (state, action: PayloadAction<Audience>) => {
            state.audience = action.payload;
        },
        setCampaignType: (state, action: PayloadAction<CampaignType>) => {
            if (state.type !== action.payload) {
                state.message = null;
            }
            state.type = action.payload;
        },
        setDiscountPromoType: (state, action: PayloadAction<PromoType>) => {
            if (state.discount) {
                state.discount.promoType = action.payload;
            }
        },
        setDiscountType: (state, action: PayloadAction<DiscountType>) => {
            if (state.discount) {
                state.discount.type = action.payload;
            }
        },
        setDiscountPercentOff: (
            state,
            action: PayloadAction<number | undefined>
        ) => {
            if (state.discount) {
                state.discount.percentOff = action.payload;
            }
        },
        setDiscountDollarsOff: (
            state,
            action: PayloadAction<number | undefined>
        ) => {
            if (state.discount) {
                state.discount.dollarsOff = action.payload;
            }
        },
        setDiscountExpiresInDays: (
            state,
            action: PayloadAction<number | undefined>
        ) => {
            if (state.discount) {
                state.discount.expiresInDays = action.payload;
            }
        },
        setDiscountPurchaseRequired: (
            state,
            action: PayloadAction<boolean>
        ) => {
            if (state.discount) {
                state.discount.purchaseRequired = action.payload;
            }
        },
        setDiscountMaximumUses: (
            state,
            action: PayloadAction<number | undefined>
        ) => {
            if (state.discount) {
                state.discount.maximumUses = action.payload;
            }
        },
        setDiscountProduct: (state, action: PayloadAction<IProduct | null>) => {
            if (state.discount) {
                state.discount.product = action.payload;
            }
        },
        setCampaignTitle: (state, action: PayloadAction<EditorState>) => {
            state.title = action.payload;
        },
        setActiveCampaignID: (state, action: PayloadAction<string>) => {
            state.activeCampaignId = action.payload;
        },
        clearCampaignInputs: () => campaignInitialState
    }
});

export const {
    setActiveCampaignID,
    setCampaignAudience,
    setCampaignMessage,
    setCampaignTitle,
    setCampaignType,
    setDiscountDollarsOff,
    setDiscountExpiresInDays,
    setDiscountMaximumUses,
    setDiscountPercentOff,
    setDiscountProduct,
    setDiscountPromoType,
    setDiscountPurchaseRequired,
    setDiscountType,
    clearCampaignInputs
} = campaignSlice.actions;
