import React, { useContext } from "react";
import styled from "styled-components";

import Input from "#devices/components/FormControls/Input";
import { checkValidName } from "#devices/utils/checkDeviceName";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";

const FormControlStyles = styled.div`
    padding-top: ${({ theme }) => theme.spacing.base};

    input::placeholder {
        opacity: 0.5;
    }
`;

export const EditDeviceName = () => {
    const { storeDevices, device, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const value = updatedDevice.name ?? device?.name;

    const { valid, error } = checkValidName(
        storeDevices ?? [],
        value ?? "",
        device?.id ?? ""
    );
    return (
        <FormControlStyles>
            <Input
                id="deviceName"
                label="Device Name"
                helpText="Give the device a friendly name to help identify it
                in a list"
                value={value}
                onChange={(e) => {
                    updateDeviceField("name", e.target.value);
                }}
                isInvalid={!valid}
                error={error}
            />
        </FormControlStyles>
    );
};
