import { DAY_END_MINUTES } from "#settings/settings-business-info/utils/constants";
import { formatDateWithNextDay } from "#settings/settings-business-info/utils/formatTime";
import { format } from "date-fns";

export const parenthesizeNumber = (phoneNumber: string) => {
    const cleaned = phoneNumber.replace(/[^\d]/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const countryCode = match[1] ? "+1 " : ""; // add country code if present
        return `${countryCode}(${match[2]}) ${match[3]}-${match[4]}`;
    }
    return cleaned;
};

type HourRange = {
    start: number;
    end: number;
};

type ValueType = {
    weekday: number;
    range: HourRange;
}[];

export const getHoursWithNewDay = (
    value: ValueType,
    rowWeekday: number,
    start: number,
    end: number
) => [
    ...(value ?? []),
    {
        weekday: rowWeekday,
        range: {
            start: start,
            end: end
        }
    }
];

export const getHoursWithoutDay = (
    value: ValueType,
    rowWeekday: number,
    hour: HourRange
) => {
    if (hour.end <= DAY_END_MINUTES) {
        return value.filter(
            (val) =>
                val.weekday !== rowWeekday ||
                val.range.start !== hour.start ||
                val.range.end !== hour.end
        );
    } else {
        const nextDay = rowWeekday === 7 ? 1 : rowWeekday + 1;
        return value.filter(
            (val) =>
                !(
                    val.weekday === rowWeekday &&
                    val.range.start === hour.start &&
                    val.range.end === DAY_END_MINUTES
                ) &&
                !(
                    val.weekday === nextDay &&
                    val.range.start === 0 &&
                    val.range.end === hour.end - DAY_END_MINUTES - 1
                ) // One extra minute for 12am
        );
    }
};

type SpecialValueType = {
    date: Date;
    isClosed: boolean;
    range: HourRange;
}[];

export const formatDate = (date: Date) => format(date, "PPP");

export const getSpecialHoursWithNewDay = (
    value: SpecialValueType,
    date: Date,
    start: number,
    end: number
) => [
    ...(value ?? []),
    {
        date: date,
        isClosed: false,
        range: {
            start: start,
            end: end
        }
    }
];

export const getSpecialHoursWithoutDay = (
    value: SpecialValueType,
    rowDay: Date,
    hour: HourRange
) => {
    if (hour.end <= DAY_END_MINUTES) {
        return value.filter(
            (val) =>
                formatDate(val.date) !== formatDate(rowDay) ||
                val.range.start !== hour.start ||
                val.range.end !== hour.end
        );
    } else {
        const nextDay = formatDateWithNextDay(formatDate(rowDay));

        return value.filter(
            (val) =>
                !(
                    formatDate(val.date) === formatDate(rowDay) &&
                    val.range.start === hour.start &&
                    val.range.end === DAY_END_MINUTES
                ) &&
                !(
                    formatDate(val.date) === nextDay &&
                    val.range.start === 0 &&
                    val.range.end === hour.end - DAY_END_MINUTES - 1
                ) // One extra minute for 12am
        );
    }
};
