import styled from "@emotion/styled";
import { SystemColors } from "@snackpass/design-system";
import { ScreenState } from "@snackpass/snackpass-types";
import { Link } from "react-router-dom";

import { ReactComponent as PlusIcon } from "src/assets/icons/plus.svg";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { Routes } from "#navigation/routes";

export const CreatePromoButton = () => {
    const { isMobile } = useWindowDimensions();

    const PromoLink = styled(Link)`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 145px;
        background-color: ${SystemColors.v1.candy50};
        color: white;
        border-radius: 8px;
        text-decoration: none;
        &:hover {
            opacity: 100;
            color: white;
            box-shadow: inset 999px 4px 0px rgba(0, 0, 0, 0.2);
        }

        @media ${ScreenState.MOBILE} {
            z-index: 100;
            padding: 20px;
            width: auto;
            height: auto;
            border-radius: 50%;
            position: fixed;
            right: 16px;
            bottom: 92px;
            box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.16);
            border: 1px solid #0077ff;
        }
    `;

    const ButtonText = styled.p`
        margin: 0 0 0 8px;
        font-weight: 600;
        font-size: 16px;
    `;

    return (
        <PromoLink to={Routes.CreatePromotionGeneric}>
            <PlusIcon width={16} height={16} fill={SystemColors.v1.white} />
            {isMobile ? null : <ButtonText>New Promo</ButtonText>}
        </PromoLink>
    );
};
