import { DateTime } from "luxon";
import { IDiscount, IPromotion, PromoTypes } from "@snackpass/snackpass-types";
import moment from "moment-timezone";

import { ReactComponent as BogoIcon } from "src/assets/icons/duplicate.svg";
import { ReactComponent as HappyHourIcon } from "src/assets/icons/hourglass.svg";
import { ReactComponent as PromoCodeIcon } from "src/assets/icons/enter-code.svg";
import { ReactComponent as FirstTimeIcon } from "src/assets/icons/add-friend.svg";
import { ReactComponent as DealDropIcon } from "src/assets/icons/lightning-bolt.svg";
import { ReactComponent as StudentIcon } from "src/assets/icons/graduation-cap.svg";
import { ReactComponent as ReferralIcon } from "src/assets/icons/friends.svg";
import { ReactComponent as RewardIcon } from "src/assets/icons/punchcard.svg";
import { ReactComponent as PartyIcon } from "src/assets/icons/party-popper.svg";
import { ReactComponent as GiftCardIcon } from "src/assets/icons/credit-card.svg";
import { PromoShortcut } from "#promotion/utils/types";
import { useSelfServePromoCodeEnabled } from "#navigation/utils";

export const useInfoForShortcutType = (
    type: PromoShortcut,
    iconSize: number
): [React.ReactElement, string, string, boolean, string] => {
    const hasuSelfServePromoCodeEnabled = useSelfServePromoCodeEnabled();
    switch (type) {
        case PromoShortcut.Bogo:
            return [
                <BogoIcon width={iconSize} height={iconSize} />,
                "Buy One, Get One",
                "Offer a discount to customers that make a qualifying purchase.",
                true,
                ""
            ];
        case PromoShortcut.HappyHour:
            return [
                <HappyHourIcon width={iconSize} height={iconSize} />,
                "Happy Hour",
                "Boost sales during slower times of the day with a recurring discount.",
                true,
                ""
            ];
        case PromoShortcut.PromoCode:
            return [
                <PromoCodeIcon width={iconSize} height={iconSize} />,
                "Promo Code",
                "Create a discount code to share on social media or marketing materials.",
                hasuSelfServePromoCodeEnabled,
                ""
            ];
        case PromoShortcut.FirstTime:
            return [
                <FirstTimeIcon width={iconSize} height={iconSize} />,
                "First-time Customer",
                "Attract new business and convert curious guests into customers.",
                true,
                ""
            ];
        case PromoShortcut.DealDrop:
            return [
                <DealDropIcon width={iconSize} height={iconSize} />,
                "Deal Drop",
                "Create hype and drive sales with a max-use, limited-time promotion.",
                true,
                ""
            ];
        case PromoShortcut.Student:
            return [
                <StudentIcon width={iconSize} height={iconSize} />,
                "Student",
                "Become a campus favorite and an easy choice for young consumers.",
                true,
                ""
            ];
        case PromoShortcut.Referral:
            return [
                <ReferralIcon width={iconSize} height={iconSize} />,
                "Referral",
                "Go viral and get your customers to market to their friends for you.",
                false, // TODO: Remove this once we have the new Referral flow
                "Please reach out to support via the Help Center in the Resources tab (above Settings) to create this type of promotion." // Error text when disabled
            ];
        case PromoShortcut.Reward:
            return [
                <RewardIcon width={iconSize} height={iconSize} />,
                "Punchcard Reward",
                "Increase customer retention with a loyalty points program.",
                true,
                ""
            ];
        case PromoShortcut.Party:
            return [
                <PartyIcon width={iconSize} height={iconSize} />,
                "Party (Group Buy)",
                "Encourage customers to invite friends for a discount.",
                false, // TODO: Remove this once we have the new Party flow
                "Please reach out to support via the Help Center in the Resources tab (above Settings) to create this type of promotion." // Error text when disabled
            ];
        case PromoShortcut.GiftCard:
            return [
                <GiftCardIcon width={iconSize} height={iconSize} />,
                "Gift Cards",
                "Offer discounts on gift cards to drive sales.",
                true,
                ""
            ];
    }
};

export const convertScheduleDateToLuxon = (date: string): string =>
    DateTime.fromJSDate(moment(date).toDate()).toFormat("HH:mm");

export const isPromoTypeChangeError = (message: string) =>
    /**
     * There is a very specific validation error thrown for the 'type' field
     * when a promo's derived type is different from the promo's current type
     * If/when support for changing a promo's type after creation is added, then this can be removed.
     */
    message.includes("Can't change promotion type!");

export const formatPromoType = (row: IPromotion) => {
    let type = "";

    switch (row.type) {
        case PromoTypes.Discount:
            if (row.hours) {
                type = `Discount · Happy Hour`;
                break;
            }
            type = "Discount";
            break;
        case PromoTypes.Deal:
            if (row.hours) {
                type = `Deal · Happy Hour`;
                break;
            }
            type = "Deal";
            break;
        case PromoTypes.PromoCode:
            type = `Promo Code · ${row.code}`;
            break;
        case PromoTypes.Reward:
            type = `Reward · ${row.pointsRequired} points`;
            break;
        case PromoTypes.Referral:
            type = "Referral";
            break;
        case PromoTypes.Birthday:
            type = "Birthday";
            break;
        case PromoTypes.Retargeting:
            type = "Retargeting";
            break;
        case PromoTypes.TimeBomb:
            type = "Time Bomb";
            break;
        case PromoTypes.Party:
            type = "Party";
            break;
    }

    return type;
};

export const formatPromoDetails = (row: IPromotion) => {
    if (row.type === PromoTypes.Deal) {
        const discountedDealItems = row.dealItems.filter(
            (deal) => deal.discount !== undefined && !!deal.discount
        );
        return `${discountedDealItems.length} item(s)`;
    }
    if (row.type === PromoTypes.GiftCard) {
        return "Gift Cards";
    }

    return row.storewide
        ? `All items`
        : `${
              row.categories.length
                  ? `${row.categories.length} ${
                        row.categories.length > 1 ? "categories" : "category"
                    } ${row.productIds.length ? "· " : ""}`
                  : ""
          } ${
              row.productIds.length
                  ? `${row.productIds.length} ${
                        row.productIds.length > 1 ? "products" : "product"
                    }`
                  : ""
          }`;
};

const getDiscount = (discount: IDiscount | null | undefined) => {
    if (discount?.dollarsOff !== undefined) {
        return `$${discount?.dollarsOff} off`;
    } else if (discount?.percentOff !== undefined) {
        return `${discount?.percentOff}% off`;
    } else if (discount?.newPrice !== undefined) {
        return `$${discount?.newPrice} each`;
    } else if (discount?.isDoublePoints !== undefined) {
        return "Double Points";
    }
};

export const formatPromoDiscount = (row: IPromotion) => {
    if (row.type === PromoTypes.Deal) {
        const discountedDealItems = row.dealItems.filter(
            (deal) => deal.discount !== undefined && !!deal.discount
        );
        if (discountedDealItems.length > 1) {
            return "Multiple discounts";
        }
        return getDiscount(discountedDealItems[0]?.discount) || "";
    }

    return getDiscount(row.discount) || "";
};
