import { Label } from "src/@/components/ui/label";
import { InvoicePreview } from "#invoices/components/invoice-preview";
import { GetInvoicesQuery } from "src/api/graphql/generated/types";
import { formatDate } from "#invoices/utils";

import { Button } from "src/@/components/ui/button";

type InvoiceDetailProps = {
    invoice: GetInvoicesQuery["invoices"][number];
};

export const InvoiceDetail = ({ invoice }: InvoiceDetailProps) => {
    if (!invoice) {
        return null;
    }

    const salesTaxAmount = Math.round(
        (invoice.amount * invoice.salesTaxPercentage) / 100
    );

    const totalAmount = invoice.amount + salesTaxAmount;

    return (
        <div className="space-y-6">
            <div className="space-y-2">
                <h1 className="text-2xl font-bold">Invoice Detail</h1>
                <p className="text-gray-500 dark:text-gray-400">
                    Details of the invoice sent to the customer.
                </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label>Invoice Number</Label>
                    <p>{invoice.stripeInvoiceNumber}</p>
                </div>
                <div>
                    <Label>Invoice Sent At</Label>
                    <p>{formatDate(invoice.createdAt)}</p>
                </div>
                <div>
                    <Label>Customer Paid At</Label>
                    <p>
                        {invoice.customerPaidAt
                            ? formatDate(invoice.customerPaidAt)
                            : "Unpaid"}
                    </p>
                </div>
                <div>
                    <Label>Payout Processed At</Label>
                    <p>
                        {invoice.payoutProcessedAt
                            ? formatDate(invoice.payoutProcessedAt)
                            : "Unpaid"}
                    </p>
                </div>
                <div>
                    <Label>Invoice URL</Label>
                    <div>
                        {invoice.stripeInvoiceUrl ? (
                            <Button variant="outline" className="mt-1">
                                <a
                                    href={invoice.stripeInvoiceUrl}
                                    target="_blank"
                                    className="text-black no-underline"
                                >
                                    View Invoice
                                </a>
                            </Button>
                        ) : (
                            <p>Not Available</p>
                        )}
                    </div>
                </div>
                <div>
                    <Label>Invoice PDF</Label>
                    <div>
                        {invoice.stripeInvoicePdf ? (
                            <Button variant="outline" className="mt-1">
                                <a
                                    href={invoice.stripeInvoicePdf}
                                    target="_blank"
                                    className="text-black no-underline"
                                >
                                    Download Invoice PDF
                                </a>
                            </Button>
                        ) : (
                            <p>Not Available</p>
                        )}
                    </div>
                </div>
            </div>
            <InvoicePreview
                customerName={invoice.customerName}
                customerEmail={invoice.customerEmail}
                description={invoice.description}
                amount={invoice.amount}
                salesTaxPercentage={invoice.salesTaxPercentage}
                salesTaxAmount={salesTaxAmount}
                totalAmount={totalAmount}
            />
        </div>
    );
};
