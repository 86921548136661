import { SystemColors } from "@snackpass/design-system";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TimePicker } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { RangeValue } from "rc-picker/lib/interface";
import moment, { Moment } from "moment";
import { ScreenState } from "@snackpass/snackpass-types";
import MobileTimePicker from "react-time-picker";

import { dayMap } from "#promotion/utils/constants";
import { FIELD_NAMES, ScheduleDay } from "#promotion/utils/types";
import { ReactComponent as TrashIcon } from "src/assets/icons/trash-can.svg";
import { ReactComponent as ChevronRightIcon } from "src/assets/icons/chevron-right.svg";
import { ReactComponent as ChevronDownIcon } from "src/assets/icons/chevron-down.svg";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { FormFieldDescriptor } from "#promotion/components/shared/form-field-descriptor";
import { FormToggle } from "#promotion/components/shared/form-toggle";
import colors from "#reusable/colors/colors.json";

type Props = {
    autofilled?: boolean;
};

type RangePickerContainerProps = {
    fieldName: string;
    enabled: boolean;
};

type RangePickerStyleProps = {
    enabled: boolean;
};

type OnChangeHandler = {
    onChangeValue: RangeValue<Moment>;
    index?: number;
    newEntry?: boolean;
};

type MobileOnChangeHandler = {
    time: string;
    index: number;
    start?: boolean;
    end?: boolean;
    newEntry?: boolean;
    currRange?: ScheduleDay;
};

type DayNameProps = {
    checked: boolean;
};

type DayRowProps = {
    day: string;
    scheduleFieldName: string;
    scheduleEnabledFieldName: string;
};

type TimePickerRowProps = {
    index: number;
    enabled: boolean;
    onChange: ({}: MobileOnChangeHandler) => void;
    onRemove: (index: number) => void;
    range?: ScheduleDay;
};

type TrashProps = {
    enabled: boolean;
};

const DesktopRangePicker = ({
    fieldName,
    enabled
}: RangePickerContainerProps) => {
    const { setValue, getValues } = useFormContext();
    const value = getValues(fieldName) as ScheduleDay[];

    useEffect(() => {
        if (!enabled) {
            setValue(fieldName, []);
        }
    }, [enabled]);

    const onChangeHandler = ({
        onChangeValue,
        index,
        newEntry
    }: OnChangeHandler) => {
        if (newEntry) {
            const newValue = value ? [...value] : [];
            newValue.push({
                start: onChangeValue?.[0]?.toString() ?? "",
                end: onChangeValue?.[1]?.toString() ?? ""
            });
            setValue(fieldName, newValue);
            return;
        } else if (index !== undefined && onChangeValue) {
            const newValue = [...value];
            newValue[index] = {
                start: onChangeValue?.[0]?.toString() ?? "",
                end: onChangeValue?.[1]?.toString() ?? ""
            };

            setValue(fieldName, newValue);
            return;
        } else if (index !== undefined && onChangeValue === null) {
            const newValue = [...value];

            newValue.splice(index, 1);
            setValue(fieldName, newValue);
            return;
        }

        const newValue = [
            {
                start: onChangeValue?.[0] ?? "",
                end: onChangeValue?.[1] ?? ""
            }
        ];

        setValue(fieldName, newValue);
    };

    const renderRangePickers = value?.map((scheduleDay, index) => (
        <RangePickerContainer key={index} enabled={enabled}>
            <TimePicker.RangePicker
                clearIcon={
                    <TrashIcon
                        width={15}
                        height={15}
                        fill={SystemColors.v1.melon50}
                    />
                }
                use12Hours
                format="h:mm a"
                bordered={false}
                onChange={(onChangeValue) =>
                    onChangeHandler({ onChangeValue, index })
                }
                value={
                    scheduleDay?.start
                        ? [
                              moment(scheduleDay.start),
                              scheduleDay.end ? moment(scheduleDay.end) : null
                          ]
                        : undefined
                }
                style={{ width: "100%" }}
                disabled={!enabled}
            />
        </RangePickerContainer>
    ));

    return (
        <RangePickerGroupContainer>
            {renderRangePickers}
            <RangePickerContainer enabled={enabled}>
                <TimePicker.RangePicker
                    size="large"
                    use12Hours
                    format="h:mm a"
                    bordered={false}
                    onChange={(onChangeValue) =>
                        onChangeHandler({ onChangeValue, newEntry: true })
                    }
                    defaultValue={[null, null]}
                    value={[null, null]}
                    style={{ width: "100%" }}
                    disabled={!enabled}
                />
            </RangePickerContainer>
        </RangePickerGroupContainer>
    );
};

const MobileRangePicker = ({
    fieldName,
    enabled
}: RangePickerContainerProps) => {
    const { setValue, getValues } = useFormContext();
    const ranges = (getValues(fieldName) as ScheduleDay[]) || [];
    const length = ranges.length;

    useEffect(() => {
        if (!enabled) {
            setValue(fieldName, []);
        }
    }, [enabled]);

    const onChangeHandler = ({
        time,
        index,
        start,
        end,
        currRange
    }: MobileOnChangeHandler) => {
        // short circuit incomplete time
        if (time === null) {
            return;
        }
        let newRanges = ranges;
        const [hours, minutes] = time.split(":").map((t) => Number(t));
        const newTime = moment(new Date().setHours(hours, minutes, 0));
        const range = currRange ?? { start: "", end: "" }; // if undefined, start a new object

        // Check if should swap start and end
        if (end) {
            const startTime = range.start ? moment(range.start) : null;
            // Check if start is after end
            if (startTime && startTime.isAfter(newTime)) {
                range.end = range.start;
                range.start = newTime.format();
            } else {
                // otherwise just assign the new time
                range.end = newTime.format();
            }
        } else {
            const endTime = range.end ? moment(range.end) : null;
            // Check if end is before start
            if (endTime && endTime.isBefore(newTime)) {
                range.start = range.end;
                range.end = newTime.format();
            } else {
                // otherwise just assign the new time
                range.start = newTime.format();
            }
        }

        // Update list of ranges
        // If editing new entry, should add to list of ranges instead of overwriting
        if (index === length) {
            newRanges = newRanges.concat(range);
        } else {
            newRanges[index] = range;
        }

        setValue(fieldName, newRanges);
    };

    const onRemoveRow = (index: number) => {
        const newRanges = ranges;
        newRanges.splice(index, 1);
        setValue(fieldName, newRanges);
    };

    return (
        <RangePickerGroupContainer>
            {ranges.map((range, index) => (
                <TimePickerRow
                    key={index}
                    enabled={enabled}
                    index={index}
                    range={range}
                    onChange={onChangeHandler}
                    onRemove={onRemoveRow}
                />
            ))}
            <TimePickerRow
                key={length}
                enabled={enabled}
                index={length}
                onChange={onChangeHandler}
                onRemove={onRemoveRow}
            />
        </RangePickerGroupContainer>
    );
};

const TimePickerRow = ({
    enabled,
    index,
    range,
    onChange,
    onRemove
}: TimePickerRowProps) => {
    const startTime = range?.start ? moment(range.start).format("HH:mm") : "";
    const endTime = range?.end ? moment(range.end).format("HH:mm") : "";

    return (
        <RangePickerContainer enabled={enabled}>
            <MobileTimePicker
                //@ts-expect-error not using Date
                onChange={(time: string) =>
                    onChange({
                        time,
                        start: true,
                        index,
                        currRange: range
                    })
                }
                value={startTime}
                hourPlaceholder={"12"}
                minutePlaceholder={"00"}
                pl
                disableClock
                clearIcon={null}
                disabled={!enabled}
                format={"hh:mm a"}
            />
            <MobileTimePicker
                //@ts-expect-error not using Date
                onChange={(time: string) =>
                    onChange({
                        time,
                        end: true,
                        index,
                        currRange: range
                    })
                }
                value={endTime}
                hourPlaceholder={"12"}
                minutePlaceholder={"00"}
                disableClock
                clearIcon={null}
                disabled={!enabled}
                format={"hh:mm a"}
            />
            <TrashContainer enabled={!!range} onClick={() => onRemove(index)}>
                {range ? (
                    <TrashIcon
                        width={24}
                        height={24}
                        fill={SystemColors.v1.melon50}
                    />
                ) : null}
            </TrashContainer>
        </RangePickerContainer>
    );
};

const DayRow = ({
    day,
    scheduleFieldName,
    scheduleEnabledFieldName
}: DayRowProps) => {
    const methods = useFormContext();
    const { watch, control, trigger } = methods;
    const { isMobile } = useWindowDimensions();

    const [isOpen, setIsOpen] = useState(false);
    const enabled = watch(scheduleEnabledFieldName);
    const times = watch(scheduleFieldName);

    useEffect(() => {
        void trigger(scheduleEnabledFieldName);
        void trigger(scheduleFieldName);
    }, [scheduleEnabledFieldName, scheduleFieldName, times]);

    return (
        <DayRowContainer>
            <DayTitleContainer
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <Controller
                    name={scheduleEnabledFieldName}
                    control={control}
                    render={({ field: { onBlur, onChange, ref, value } }) => (
                        <DayNameContainer onClick={(e) => e.stopPropagation()}>
                            <Checkbox
                                checked={value}
                                type="checkbox"
                                ref={ref}
                                onChange={(e) => onChange(e)}
                                onBlur={onBlur}
                                id={scheduleEnabledFieldName}
                            />
                            <DayName
                                checked={value}
                                htmlFor={scheduleEnabledFieldName}
                            >
                                {day}
                            </DayName>
                        </DayNameContainer>
                    )}
                />

                {isMobile &&
                    (isOpen ? (
                        <ChevronDownIcon
                            width={20}
                            height={11}
                            fill={SystemColors.v1.gray50}
                        />
                    ) : (
                        <ChevronRightIcon
                            width={20}
                            height={16}
                            fill={SystemColors.v1.gray50}
                        />
                    ))}
            </DayTitleContainer>

            {isMobile ? (
                isOpen ? (
                    <MobileRangePicker
                        fieldName={scheduleFieldName}
                        enabled={enabled}
                    />
                ) : null
            ) : (
                <DesktopRangePicker
                    fieldName={scheduleFieldName}
                    enabled={enabled}
                />
            )}
        </DayRowContainer>
    );
};

export const ScheduleSelector = ({ autofilled = false }: Props) => {
    const {
        formState: { errors },
        watch,
        clearErrors,
        setValue,
        trigger
    } = useFormContext();

    const scheduleEnabled = watch(FIELD_NAMES.SCHEDULE_ENABLED);
    const mondayEnabledWatch = watch(FIELD_NAMES.SCHEDULE_MONDAY_ENABLED);
    const tuesdayEnabledWatch = watch(FIELD_NAMES.SCHEDULE_TUESDAY_ENABLED);
    const wednesdayEnabledWatch = watch(FIELD_NAMES.SCHEDULE_WEDNESDAY_ENABLED);
    const thursdayEnabledWatch = watch(FIELD_NAMES.SCHEDULE_THURSDAY_ENABLED);
    const fridayEnabledWatch = watch(FIELD_NAMES.SCHEDULE_FRIDAY_ENABLED);
    const saturdayEnabledWatch = watch(FIELD_NAMES.SCHEDULE_SATURDAY_ENABLED);
    const sundayEnabledWatch = watch(FIELD_NAMES.SCHEDULE_SUNDAY_ENABLED);

    // Clear schedule when enabled is toggled
    useEffect(() => {
        if (!scheduleEnabled) {
            setValue(FIELD_NAMES.SCHEDULE_MONDAY_ENABLED, false);
            setValue(FIELD_NAMES.SCHEDULE_TUESDAY_ENABLED, false);
            setValue(FIELD_NAMES.SCHEDULE_WEDNESDAY_ENABLED, false);
            setValue(FIELD_NAMES.SCHEDULE_THURSDAY_ENABLED, false);
            setValue(FIELD_NAMES.SCHEDULE_FRIDAY_ENABLED, false);
            setValue(FIELD_NAMES.SCHEDULE_SATURDAY_ENABLED, false);
            setValue(FIELD_NAMES.SCHEDULE_SUNDAY_ENABLED, false);
        }
    }, [scheduleEnabled]);

    // Clear schedule enabled error when any day is enabled
    useEffect(() => {
        trigger([
            FIELD_NAMES.SCHEDULE_ENABLED,
            FIELD_NAMES.SCHEDULE_MONDAY_ENABLED,
            FIELD_NAMES.SCHEDULE_TUESDAY_ENABLED,
            FIELD_NAMES.SCHEDULE_WEDNESDAY_ENABLED,
            FIELD_NAMES.SCHEDULE_THURSDAY_ENABLED,
            FIELD_NAMES.SCHEDULE_FRIDAY_ENABLED,
            FIELD_NAMES.SCHEDULE_SATURDAY_ENABLED,
            FIELD_NAMES.SCHEDULE_SUNDAY_ENABLED
        ]);
    }, [
        mondayEnabledWatch,
        tuesdayEnabledWatch,
        wednesdayEnabledWatch,
        thursdayEnabledWatch,
        fridayEnabledWatch,
        saturdayEnabledWatch,
        sundayEnabledWatch
    ]);

    const error =
        errors[FIELD_NAMES.SCHEDULE_MONDAY] ||
        errors[FIELD_NAMES.SCHEDULE_TUESDAY] ||
        errors[FIELD_NAMES.SCHEDULE_WEDNESDAY] ||
        errors[FIELD_NAMES.SCHEDULE_THURSDAY] ||
        errors[FIELD_NAMES.SCHEDULE_FRIDAY] ||
        errors[FIELD_NAMES.SCHEDULE_SATURDAY] ||
        errors[FIELD_NAMES.SCHEDULE_SUNDAY] ||
        errors[FIELD_NAMES.SCHEDULE_MONDAY_ENABLED] ||
        errors[FIELD_NAMES.SCHEDULE_TUESDAY_ENABLED] ||
        errors[FIELD_NAMES.SCHEDULE_WEDNESDAY_ENABLED] ||
        errors[FIELD_NAMES.SCHEDULE_THURSDAY_ENABLED] ||
        errors[FIELD_NAMES.SCHEDULE_FRIDAY_ENABLED] ||
        errors[FIELD_NAMES.SCHEDULE_SATURDAY_ENABLED] ||
        errors[FIELD_NAMES.SCHEDULE_SUNDAY_ENABLED];

    return (
        <>
            <FormToggle
                name="Schedule"
                descriptor="Choose the days and times of the day customers can use this discount (ie. Happy Hour)"
                fieldName={FIELD_NAMES.SCHEDULE_ENABLED}
                divider={!scheduleEnabled}
                autofilled={autofilled}
            />
            <FormFieldDescriptor error={error?.message as string} />

            {scheduleEnabled ? (
                <Container>
                    {dayMap.map((day) => (
                        <DayRow
                            day={day.day}
                            scheduleFieldName={day.field}
                            scheduleEnabledFieldName={day.enabled}
                        />
                    ))}
                </Container>
            ) : null}
        </>
    );
};

const Container = styled.div`
    overflow: hidden;
    border: 1px solid ${colors["neutral-400"]};
    border-radius: 16px;
    margin: 24px 0;

    div:last-child {
        border-bottom: none;
    }

    @media ${ScreenState.MOBILE} {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid ${colors["neutral-400"]};
    }
`;

const DayRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${SystemColors.v1.gray80};
    @media ${ScreenState.MOBILE} {
        flex-direction: column;
    }
`;

const DayTitleContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    min-width: 30%;
    border-right: 1px solid ${SystemColors.v1.gray80};
    padding: 24px 16px;
    @media ${ScreenState.MOBILE} {
        border-right: none;
        width: auto;
        &:hover {
            cursor: pointer;
            background-color: ${SystemColors.v1.gray90};
        }
    }
`;

const DayNameContainer = styled.div`
    display: flex;
    align-items: center;
    @media ${ScreenState.MOBILE} {
        .ant-checkbox {
            top: 1px;
        }
    }
`;

const DayName = styled.label<DayNameProps>`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    ${(props) =>
        !props.checked &&
        `
        color: ${SystemColors.v1.gray60};
    `}
    @media ${ScreenState.MOBILE} {
        font-size: 20px;
        line-height: 28px;
        margin-left: 16px;
    }
`;

const RangePickerGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    div:last-child {
        border-bottom: none;
    }
`;

const RangePickerContainer = styled.div<RangePickerStyleProps>`
    display: flex;
    padding: 16px;
    border-bottom: 1px solid ${SystemColors.v1.gray80};
    ${({ enabled }) =>
        enabled &&
        `.ant-picker-input input::placeholder {
        color: ${SystemColors.v1.gray20};
    }`}
    @media ${ScreenState.MOBILE} {
        .react-time-picker {
            flex: 1;
        }
        .react-time-picker__wrapper {
            border: none;
        }
        .react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute,
        .react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour {
            width: auto !important;
            max-width: 20px;
        }
    }
`;

const TrashContainer = styled.div<TrashProps>`
    padding: 6px;
    width: 36px;
    border-radius: 4px;
    ${(props) =>
        props.enabled &&
        `&:hover {
        cursor: pointer;
        background-color: ${SystemColors.v1.melon90};
    }`}
`;

const Checkbox = styled.input`
    border-color: ${colors["neutral-400"]};
    border-radius: 12px;
    height: 24px;
    width: 24px;
    margin-right: 16px;
    @media ${ScreenState.MOBILE} {
        margin: 0;
    }
`;
