import React, { useEffect, useState } from "react";
import styled from "styled-components";

import DropDownSelect from "#reusable/select/dropdown";
import { ReactComponent as Plus } from "src/assets/icons/plus.svg";
import { ReactComponent as DeleteRed } from "src/assets/icons/delete-red.svg";

import { TimePicker, Tooltip } from "antd";
import type { Moment } from "moment";
import moment from "moment";
import "antd/dist/antd.css";
import { IHoursSchema } from "@snackpass/snackpass-types";

import colors from "#reusable/colors/colors.json";
import { SpecialHoursItemType, format12 } from "#reusable/special-hours/helper";
import { DAY_OPTIONS } from "#menu-editor/multi-menus/screens/multi-menu-settings/utils/time-helpers";

type SelectedDayOfWeekOptionType = {
    label: string;
    value: number;
};

type IconProps = {
    onClick: Function;
};

const IconWrapper = styled.div<IconProps>`
    display: flex;
    margin-left: 0.1rem;
    border: 1px solid ${colors["neutral-400"]};
    height: 36px;
    width: 36px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`;

type HourProps = {
    hours?: IHoursSchema | null;
    menuHours: SpecialHoursItemType[];
    setMenuHours: React.Dispatch<React.SetStateAction<SpecialHoursItemType[]>>;
    removeRow: Function;
    updateRowHours: Function;
    id: number;
    dayOfWeek: number;
    start?: Moment | null;
    end?: Moment | null;
};

export const DesktopMenuHourRow: React.FC<HourProps> = ({
    menuHours,
    setMenuHours,
    removeRow,
    updateRowHours,
    id,
    dayOfWeek,
    start,
    end
}) => {
    const [selectedDay, setSelectedDay] = React.useState(DAY_OPTIONS[0]);
    const [startTime, setStartTime] = useState<Moment | null>(null);
    const [endTime, setEndTime] = useState<Moment | null>(null);

    useEffect(() => {
        setSelectedDay(
            dayOfWeek
                ? DAY_OPTIONS.filter((option) => option.value === dayOfWeek)[0]
                : DAY_OPTIONS[0]
        );
        setStartTime(start ?? null);
        setEndTime(end ?? null);
    }, [menuHours]);

    const handleStartTime = (dateObject: moment.Moment | null): void => {
        setStartTime(moment(dateObject, format12));
        updateRowHours(id, moment(dateObject, format12), endTime);
    };
    const handleEndTime = (dateObject: moment.Moment | null): void => {
        setEndTime(moment(dateObject, format12));
        updateRowHours(id, start, moment(dateObject, format12));
    };

    return (
        <div className="flex flex-row items-start justify-between">
            <div className="ml-2 mr-1 max-w-[12rem] flex-1">
                <DropDownSelect
                    mr={3}
                    square
                    placeholder={"Day"}
                    options={DAY_OPTIONS}
                    value={selectedDay}
                    onChange={(selectedOption: SelectedDayOfWeekOptionType) => {
                        setSelectedDay(selectedOption);
                        setMenuHours((existingMenus: SpecialHoursItemType[]) =>
                            existingMenus.map(
                                (menu: SpecialHoursItemType, j: number) =>
                                    j === id
                                        ? {
                                              ...menu,
                                              dayOfWeek: selectedOption.value
                                          }
                                        : menu
                            )
                        );
                    }}
                ></DropDownSelect>
            </div>
            <TimePicker
                size="large"
                placeholder="Start Time"
                value={moment(startTime, format12)}
                format={format12}
                style={{ borderRadius: "8px", flex: "1" }}
                onChange={handleStartTime}
                allowClear={false}
            />
            <div className="flex h-9 items-center justify-center px-1">-</div>
            <TimePicker
                size="large"
                placeholder="End Time"
                value={moment(endTime, format12)}
                format={format12}
                style={{ borderRadius: "8px", flex: "1" }}
                onChange={handleEndTime}
                allowClear={false}
            />

            <div className="flex">
                <IconWrapper
                    onClick={() => {
                        setMenuHours([
                            ...menuHours,
                            {
                                id: menuHours[menuHours.length - 1].id + 1,
                                dayOfWeek:
                                    (selectedDay.value + 1) %
                                    DAY_OPTIONS.length,
                                time: menuHours[id].time
                            }
                        ]);
                    }}
                >
                    <Tooltip title="Add">
                        <Plus className="h-3 w-3" />
                    </Tooltip>
                </IconWrapper>
                <IconWrapper
                    onClick={() => {
                        removeRow(id);
                    }}
                >
                    {" "}
                    <Tooltip title="Delete">
                        <DeleteRed />
                    </Tooltip>
                </IconWrapper>
            </div>
        </div>
    );
};
