import { debounce } from "lodash";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import api from "src/api/rest";
import ChannelDonut from "#reports/sales-channels/components/ChannelDonut";
import ChannelReportTable from "#reports/sales-channels/components/ChannelReportTable";
import FilterHeader from "#reports/sales-summary/shared-components/FilterHeader";
import { ReportsContext } from "#app/reports-context-provider";
import { getStoreTimezone } from "#utils/helpers";
import { getActiveStore } from "src/redux/selectors";
import { ReportType } from "#reports/sales-summary/types";
import { useTrackEvent } from "#reports/mixpanel-tracking/hooks";
import { useMultiStoreSalesSummary } from "#navigation/utils";
import EmptyStateUpsell from "#reports/sales-channels/components/EmptyStateUpsell";
import { logAndSendError } from "src/utils/errors";

const Channels = () => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState, setReportsState } = useContext(ReportsContext);
    const { dateRanges, filter, channels } = reportsState;
    const trackEvent = useTrackEvent();

    const abortController = useRef<AbortController | null>(null);

    useEffect(() => {
        // We flush all of the data when we change anything in the filters to be re-fetched.
        // So, we only need to fetch when reportsData is undefined.
        fetchDataRef.current = fetchData;
        if (!reportsState.channelsData) {
            // set loading
            setReportsState((reportsState) => ({
                ...reportsState,
                channelsData: {
                    salesChannelDataLoading: true,
                    salesChannelDataFailed: false,
                    salesChannelReportData:
                        reportsState.channelsData?.salesChannelReportData
                }
            }));

            fetchDataDebounced();
        }
    }, [activeStore?._id, reportsState]);

    const fetchData = () => {
        if (!activeStore?._id || !dateRanges) {
            return;
        }

        if (abortController.current) {
            abortController.current.abort();
        }

        abortController.current = new AbortController();

        const params = {
            storeId: activeStore?._id,
            startDate: dateRanges[0][0].format("YYYY-MM-DD"),
            endDate: dateRanges[0][1].format("YYYY-MM-DD"),

            timezone: getStoreTimezone(activeStore),

            channel: JSON.stringify(filter.channel),
            source: JSON.stringify(filter.source),
            fulfillment: JSON.stringify(filter.fulfillment),
            ...(filter.storeIds.length
                ? { storeIds: JSON.stringify(filter.storeIds) }
                : {})
        };

        api.reports
            .getSalesChannelsReport(params, abortController.current)
            .then((res) => {
                setReportsState((reportsState) => ({
                    ...reportsState,
                    channelsData: {
                        salesChannelDataLoading: false,
                        salesChannelDataFailed: false,
                        salesChannelReportData: res.data?.salesReport
                    }
                }));
            })
            .catch((e) => {
                if (axios.isCancel(e?.cause)) return;
                logAndSendError(e);
                setReportsState((reportsState) => ({
                    ...reportsState,
                    channelsData: {
                        salesChannelDataLoading: false,
                        salesChannelDataFailed: true,
                        salesChannelReportData: undefined
                    }
                }));
            });
    };

    const fetchDataRef = useRef(fetchData);

    const fetchDataDebounced = useCallback(
        debounce(() => fetchDataRef.current?.(), 600),
        []
    );

    const multiStoreSalesSummaryEnabled = useMultiStoreSalesSummary();

    if (channels.length <= 1) return <EmptyStateUpsell />;

    return (
        <div>
            <FilterHeader
                reportType={ReportType.SALES_CHANNELS}
                showLocationFilter={multiStoreSalesSummaryEnabled}
            />
            <div className="px-6 md:px-24">
                <ChannelDonut />
                <ChannelReportTable />
            </div>
        </div>
    );
};

export default Channels;
