import { useContext, useState } from "react";
import { PrinterFormat } from "@snackpass/snackpass-types";
import { range } from "lodash";
import { Pencil2Icon, PlusIcon } from "@radix-ui/react-icons";

import { DeviceType, PrinterDevice } from "#devices/utils/deviceTypes";
import EditSection from "#devices/components/EditDrawer/EditSection";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";
import {
    EditDeviceName,
    EditDeviceType,
    ToggleField
} from "#devices/components/EditDrawer/Settings";
import { DeleteSection } from "#devices/components/EditDrawer/DeleteSection";
import { SelectField } from "#devices/components/EditDrawer/Settings/SelectField";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { getPrinterFormatOptions } from "#devices/utils/getPrinterFormats";
import SettingsSection from "#devices/components/EditDrawer/SettingsSection";
import { PrepStationDeviceDetails } from "#devices/utils/deviceTypes/PrepStationDevice";
import { PREP_STATION_FORMATS } from "#devices/utils/deviceTypes/PrinterDevice";
import { CashDrawerEditSection } from "#devices/components/CashDrawerEditSection";
import { Button } from "src/@/components/ui/button";
import { usePrepStations } from "#prep-stations/hooks";
import { usePrepStationsEnabled } from "#navigation/utils";

const deviceTypeOptions = [DeviceType.Printer].map((option) => ({
    value: option,
    label: getDeviceTypeName(option) || "--"
}));

const copiesOptions = range(1, 4).map((option) => ({
    value: option,
    label: `${option}`
}));

const generateOptions = (options: { value: string; label: string }[]) =>
    [{ value: "NONE_SELECTED", label: "None Selected" }].concat(options);

const PrinterEdit = () => {
    const {
        device,
        deviceHasChanges,
        setAddon,
        setIsAddonDrawerVisible,
        setIsEditDrawerVisible
    } = useContext(DevicesPageContext);

    const { prepStations } = usePrepStations();
    const prepStationEnabled = usePrepStationsEnabled();

    const printerDevice = device as PrinterDevice;
    const prepStationFormat = PREP_STATION_FORMATS.includes(
        printerDevice.deviceDetails.format
    );

    const [selectedPrinterFormat, setSelectedPrinterFormat] = useState<string>(
        printerDevice.deviceDetails.format
    );

    const handlePrepStationClick = (params = { isCreate: false }) => {
        if (params.isCreate || printerDevice.deviceDetails.prepStationDetails) {
            setAddon({
                details: params.isCreate
                    ? undefined
                    : (printerDevice.deviceDetails
                          .prepStationDetails as PrepStationDeviceDetails),
                type: DeviceType.PrepStation,
                isCreate: params.isCreate
            });
            setIsEditDrawerVisible(false);
            setIsAddonDrawerVisible(true);
        }
    };

    const formatOptions = getPrinterFormatOptions(
        printerDevice.deviceDetails.printerModel
    );

    const prepStationOptions = generateOptions(
        prepStations?.map((d) => ({ value: d.id, label: d.name })) ?? []
    );

    return (
        <>
            <EditSection heading="Info">
                <EditDeviceName />
                <EditDeviceType disabled options={deviceTypeOptions} />
            </EditSection>

            <EditSection heading="Settings">
                <ToggleField
                    id="autoPrintToggle"
                    label="Auto Print"
                    description="Automatically print when the order is accepted"
                    field="isAutoPrint"
                />
                <SelectField
                    id="printCopiesSelect"
                    label="Print Copies"
                    description="How many copies are printed per order"
                    options={copiesOptions}
                    field="numberOfPrints"
                />
                <SelectField
                    id="receiptOptionSelect"
                    label="Print Format"
                    description="Choose between preset print layouts"
                    options={formatOptions}
                    field="format"
                    handleSelect={setSelectedPrinterFormat}
                />
                <ToggleField
                    id="displayModifierGroupsToggle"
                    label="Display Modifier Groups"
                    description="Show modifier group name above modifiers - only compatible with the Full labels print format"
                    field="displayModifierGroups"
                    disabled={selectedPrinterFormat !== PrinterFormat.LABEL_V2}
                />
            </EditSection>

            <CashDrawerEditSection />

            <EditSection heading="Prep Station Settings">
                <SelectField
                    id="prepStationSelect"
                    label="Choose Prep Station"
                    description="Select the prep station to modify printer behavior - print format must be in Chit"
                    options={prepStationOptions}
                    field="prepStation"
                    prepStationFormat={prepStationFormat}
                />
                {!prepStationEnabled && (
                    <SettingsSection
                        label="Additional Settings"
                        description="Change settings or create a new prep station"
                    >
                        <Button
                            className="mr-2 px-2"
                            onClick={() => handlePrepStationClick()}
                            disabled={
                                deviceHasChanges ||
                                !printerDevice.deviceDetails.prepStation
                            }
                        >
                            <Pencil2Icon className="mr-1" />
                            Edit
                        </Button>
                        <Button
                            variant="outline"
                            className="px-2"
                            onClick={() =>
                                handlePrepStationClick({ isCreate: true })
                            }
                        >
                            <PlusIcon className="mr-1" />
                            Create
                        </Button>
                    </SettingsSection>
                )}
            </EditSection>

            <EditSection heading="Danger Zone">
                <DeleteSection />
            </EditSection>
        </>
    );
};

export default PrinterEdit;
