import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { Button, Col, Row, Typography } from "antd";
import { useMediaQuery } from "react-responsive";
import { connectModal, InjectedProps } from "redux-modal";

const CacheBustingModal: FC<InjectedProps> = ({ show, handleHide }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 990px)` });

    const onClickReload = () => window.location.reload();

    return (
        <Modal show={show} onHide={handleHide}>
            <Modal.Body>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Row style={{ marginBottom: "10px" }}>
                        <Typography
                            style={{ fontWeight: 700, fontSize: "1.2rem" }}
                        >
                            Dashboard Update
                        </Typography>
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                        <Typography
                            style={{ fontWeight: "normal", fontSize: "1rem" }}
                        >
                            A new update is available. Would you like to refresh
                            the page?
                        </Typography>
                    </Row>
                    <Row
                        justify={isMobile ? "center" : "end"}
                        gutter={4}
                        style={{ marginRight: "3px", marginTop: "15px" }}
                    >
                        <Col span={5}>
                            <Button
                                type="default"
                                shape="round"
                                onClick={handleHide}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col span={5}>
                            <Button
                                type="primary"
                                shape="round"
                                onClick={onClickReload}
                            >
                                Refresh
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default connectModal({ name: "CacheBustingModal" })(CacheBustingModal);
