import React, { useContext } from "react";
import styled from "styled-components";
import { Dropdown, Menu } from "antd";
import { startCase, truncate } from "lodash";
import Tippy from "@tippyjs/react";

import { ReactComponent as AlertRules } from "src/assets/icons/rules-alert.svg";
import {
    PersonVerification,
    PersonStatusColorMap,
    PersonStatuses
} from "#payouts/domain/types";
import { ReactComponent as StatusCircleIcon } from "src/assets/icons/status-circle.svg";
import { ReactComponent as Chevron } from "#payouts/assets/chevron.svg";
import { ReactComponent as Primary } from "#payouts/assets/primary.svg";
import { ReactComponent as ThreeDots } from "#payouts/assets/three-dots.svg";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { Button } from "#payouts/components/shared/Button";
import { ReactComponent as Pencil } from "src/assets/icons/pencil-over-paper.svg";

const NAME_OMISSION = "...";

export const CollapseHeader = ({
    r,
    isActive = false
}: {
    r: PersonVerification;
    isActive?: boolean;
}) => {
    const { isDesktop } = useWindowDimensions();

    const {
        verification,
        editEnabled,
        handleModalChange,
        setPerson,
        setRemovePerson,
        setNewPrimary
    } = useContext(PayoutsSettingsContext);

    const trimmedName = ({ first, last }: { first: string; last: string }) =>
        [
            truncate(startCase(first), {
                length:
                    (isDesktop ? 30 : 18) - NAME_OMISSION.length - last.length,
                omission: NAME_OMISSION
            }),
            startCase(last)
        ].join(" ");

    const name = trimmedName({
        first: r.firstName?.toLowerCase() ?? "",
        last: r.lastName?.toLowerCase() ?? ""
    });
    const isPrimary = r.representative;

    const hasPrimary =
        (verification?.representatives?.filter((r) => r.representative) ?? [])
            .length === 1;

    const isVerified = r.status === PersonStatuses.verified;
    const hasFutureRequirements = !!r.futureEventualRequirements?.length;
    const isVerifiedWithFutureRequirements =
        isVerified && hasFutureRequirements;
    const status = isVerifiedWithFutureRequirements
        ? PersonStatuses.unknown
        : r.status || PersonStatuses.unknown;
    const statusMessage = startCase(
        isVerifiedWithFutureRequirements ? "Incomplete" : status
    );

    const disabled = !editEnabled;
    const disabledRemove = disabled || isPrimary;
    const disableMakePrimary = disabledRemove || !hasPrimary || !isVerified;

    const onClickEdit = () => {
        handleModalChange({ person: true });
        setPerson({ ...r });
    };

    const menu = (
        <Menu>
            <Menu.Item
                key="makePrimary"
                disabled={disableMakePrimary}
                children={<>Make Primary</>}
                onClick={(e) => {
                    e.domEvent.stopPropagation();
                    handleModalChange({
                        makePrimary: true
                    });
                    setNewPrimary({ ...r });
                }}
            />
            <Menu.Item
                danger
                key="remove"
                disabled={disabledRemove}
                children={<>Remove</>}
                onClick={(e) => {
                    e.domEvent.stopPropagation();
                    handleModalChange({
                        removePerson: true
                    });
                    setRemovePerson({ ...r });
                }}
            />
        </Menu>
    );

    return (
        <HeaderContainer>
            <div className="left">
                <div className="first-primary">
                    {r.representative ? (
                        <div className="px-2">
                            <Tippy content="Primary">
                                <Primary />
                            </Tippy>
                        </div>
                    ) : null}
                    {isVerifiedWithFutureRequirements ? (
                        <div className="px-2">
                            <AlertRules />
                        </div>
                    ) : null}
                </div>
                <div className="first-name">{name}</div>
            </div>
            <div className="center">
                <StatusCircleIcon
                    width={8}
                    height={8}
                    style={{ marginRight: "8px" }}
                    fill={PersonStatusColorMap(status)}
                />
                {statusMessage}
            </div>
            <div className="right">
                <Button
                    disabled={disabled}
                    size="small"
                    smallRadius
                    variant={
                        isVerifiedWithFutureRequirements
                            ? "dangerOutline"
                            : "tertiary"
                    }
                    iconLeft={<Pencil />}
                    onClick={onClickEdit}
                >
                    Edit
                </Button>
                <div>
                    <Dropdown overlay={menu}>
                        <ThreeDots
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        />
                    </Dropdown>
                </div>
                <div className="last">
                    <div>
                        <Chevron
                            className={`chevron-${
                                isActive ? "active" : "inactive"
                            }`}
                        />
                    </div>
                </div>
            </div>
        </HeaderContainer>
    );
};

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    .left {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: flex-start;

        .first-primary {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }
        .first-name {
            font-size: 16px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
    }

    .center {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: center;
        align-items: center;
    }

    .right {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-between;

        .last {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
        .last svg.chevron-inactive {
            transform: rotate(0deg);
        }
        .last svg.chevron-active {
            transition: rotate 0.3s ease-out;
            transform: rotate(180deg);
        }
    }
`;
