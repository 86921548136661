import { useMutation } from "@tanstack/react-query";
import { UseFormReset } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "sonner";

import { setActiveStore } from "src/redux/slices";
import api from "src/api/rest";
import {
    FeesSettingsMutation,
    OperatingFormValues
} from "#settings/settings-fees/utils/types";
import { buildOperatingPolicies } from "#settings/settings-fees/utils/build-operating-policy";
import { operatingFormDefaults } from "#settings/settings-fees/forms/operating/operating-defaults";

export const useUpdateOperating = ({
    storeId,
    reset
}: {
    storeId: string;
    reset: UseFormReset<OperatingFormValues>;
}) => {
    const dispatch = useDispatch();
    const { mutateAsync } = useMutation({
        mutationKey: [FeesSettingsMutation.Operating, storeId],
        async mutationFn(values: OperatingFormValues) {
            const policies = buildOperatingPolicies(values);
            const response = await api.stores.updateServiceFeePolicy(
                storeId,
                policies
            );
            dispatch(setActiveStore(response.data.store));
            reset(operatingFormDefaults(response.data.store));
            return response.data.store.name;
        },
        async onSuccess(storeName) {
            toast.success(`${storeName} saved`, {
                className: "bg-success-light",
                duration: 2000,
                id: "store-saved"
            });
        },
        onError() {
            toast.error(`Update failed`, {
                className: "bg-critical-light",
                duration: 2000,
                id: "store-update-failed"
            });
        }
    });
    return mutateAsync;
};
