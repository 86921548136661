/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { IPromotion } from "@snackpass/snackpass-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import Skeleton from "react-loading-skeleton";
import { BarChartIcon, Pencil1Icon, CopyIcon } from "@radix-ui/react-icons";
import { useSelector } from "react-redux";
import { Spinner } from "react-activity";

import {
    HybridTooltip,
    HybridTooltipContent,
    HybridTooltipTrigger
} from "src/@/components/ui/HybridTooltip";
import { usePromotionForm } from "#promotion/utils/edit";
import { Switch } from "src/@/components/ui/switch";
import {
    formatPromoDetails,
    formatPromoDiscount,
    formatPromoType
} from "#promotion/lib";
import { DataTable } from "src/@/components/ui/data-table";
import { usePromotions, useTogglePromotionActive } from "#promotion/hooks";
import { Button } from "src/@/components/ui/button";
import { getActiveStore } from "src/redux/selectors";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger
} from "src/@/components/ui/tooltip";
import { DataTableToolbarOptions } from "src/@/components/ui/data-table/table-toolbar";
import { Routes } from "#navigation/routes";

const PromoTable = () => {
    const activeStore = useSelector(getActiveStore);

    const { promotions, isPending, isFetching, refetch } = usePromotions();
    const { toggleActive, isPending: isToggling } = useTogglePromotionActive();
    const { editPromotionForm, duplicatePromotionForm } = usePromotionForm();
    const togglePromoActive = async (
        promotionId: string,
        isRunning: boolean
    ) => {
        await toggleActive({ promotionId, isRunning });
    };

    // Column data must be memoized, i.e. stored in function component.
    const columns: ColumnDef<IPromotion>[] = useMemo(
        () => [
            {
                id: "actions",
                cell: (props) => (
                    <div className="flex space-x-2">
                        <TooltipProvider delayDuration={100}>
                            <HybridTooltip>
                                <HybridTooltipTrigger asChild>
                                    <Button
                                        variant="outline"
                                        onClick={() =>
                                            editPromotionForm(
                                                props.row.original,
                                                activeStore
                                            )
                                        }
                                    >
                                        <Pencil1Icon className="h-3 w-3" />
                                    </Button>
                                </HybridTooltipTrigger>
                                <HybridTooltipContent
                                    className={
                                        "z-50 w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-900 px-3 py-2 text-center font-normal text-neutral-50"
                                    }
                                    sideOffset={5}
                                    side="bottom"
                                >
                                    <p>Edit Promo</p>
                                </HybridTooltipContent>
                            </HybridTooltip>
                            <HybridTooltip>
                                <HybridTooltipTrigger asChild>
                                    <Button
                                        variant="outline"
                                        onClick={() =>
                                            duplicatePromotionForm(
                                                props.row.original,
                                                activeStore
                                            )
                                        }
                                    >
                                        <CopyIcon className="h-3 w-3" />
                                    </Button>
                                </HybridTooltipTrigger>
                                <HybridTooltipContent
                                    className={
                                        "z-50 w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-900 px-3 py-2 text-center font-normal text-neutral-50"
                                    }
                                    sideOffset={5}
                                    side="bottom"
                                >
                                    <p>Duplicate Promo</p>
                                </HybridTooltipContent>
                            </HybridTooltip>
                            <HybridTooltip>
                                <HybridTooltipTrigger asChild>
                                    <Link
                                        to={{
                                            pathname: Routes.ReportsPromotions,
                                            search: `?promoName=${encodeURIComponent(
                                                props.row.original.name
                                            )}`
                                        }}
                                    >
                                        <Button variant="outline">
                                            <BarChartIcon className="h-3 w-3" />
                                        </Button>
                                    </Link>
                                </HybridTooltipTrigger>
                                <HybridTooltipContent
                                    className={
                                        "z-50 w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-900 px-3 py-2 text-center font-normal text-neutral-50"
                                    }
                                    sideOffset={5}
                                    side="bottom"
                                >
                                    <p>View Promotion Performance</p>
                                </HybridTooltipContent>
                            </HybridTooltip>
                        </TooltipProvider>
                    </div>
                )
            },
            {
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        title="Promotion Name"
                        buttonClassName="bg-transparent"
                    />
                ),
                id: "promoName",
                accessorKey: "name",
                filterFn: (row, _, value) =>
                    row.original.name
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    formatPromoDiscount(row.original).includes(value),
                cell: (props) => (
                    <>
                        <div className="font-semibold">
                            {props.row.original.name}
                        </div>
                        <div>{formatPromoType(props.row.original)}</div>
                    </>
                )
            },
            {
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        title="Discount"
                        buttonClassName="bg-transparent"
                    />
                ),
                id: "discount",
                accessorFn: formatPromoDiscount,
                cell: (props) => {
                    const details = formatPromoDetails(props.row.original);
                    return (
                        <>
                            <div className="font-semibold">
                                {formatPromoDiscount(props.row.original)}
                            </div>
                            <div>{details}</div>
                        </>
                    );
                }
            },
            {
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        title="Start"
                        buttonClassName="bg-transparent"
                    />
                ),
                id: "start",
                accessorFn: (row) =>
                    row.activeTimePeriod?.startTime
                        ? row?.activeTimePeriod.startTime
                        : row.createdAt,
                cell: (props) =>
                    props.getValue()
                        ? moment(props.getValue() as string).format(
                              "MMM D[,] YYYY"
                          )
                        : "--"
            },
            {
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        title="End"
                        buttonClassName="bg-transparent"
                    />
                ),
                id: "end",
                accessorKey: "activeTimePeriod.endTime",
                cell: (props) =>
                    props.getValue()
                        ? moment(props.getValue() as string).format(
                              "MMM D[,] YYYY"
                          )
                        : "--"
            },
            {
                header: ({ column }) => (
                    <DataTableColumnHeader
                        column={column}
                        title="Active"
                        buttonClassName="bg-transparent"
                    />
                ),
                id: "active",
                accessorKey: "isRunning",
                cell: (props) => {
                    if (props.row.original.isArchived)
                        return (
                            <TooltipProvider delayDuration={100}>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Switch disabled />
                                    </TooltipTrigger>

                                    <TooltipContent
                                        className={
                                            "z-50 whitespace-normal rounded-md bg-neutral-900 px-3 py-2 text-center text-neutral-50"
                                        }
                                        sideOffset={5}
                                    >
                                        This promotion is archived.
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        );
                    return (
                        <Switch
                            disabled={isToggling}
                            checked={props.row.original.isRunning}
                            onCheckedChange={async (val: boolean) => {
                                await togglePromoActive(
                                    props.row.original._id,
                                    val
                                );
                            }}
                        />
                    );
                },
                // active -> inactive -> archived
                sortingFn: (a, b) =>
                    (a.original.isRunning ? 1 : 0) -
                    (a.original.isArchived ? 1 : 0) -
                    ((b.original.isRunning ? 1 : 0) -
                        (b.original.isArchived ? 1 : 0))
            }
        ],
        []
    );

    if (isPending) return <Skeleton className="h-72 w-full" />;

    return (
        <DataTable
            columns={columns}
            data={promotions ?? []}
            showPagination
            toolbar={toolbar}
            footerElement={
                <div className="flex items-center">
                    <Button
                        variant="outline"
                        onClick={async () => await refetch()}
                        disabled={isFetching}
                    >
                        {isFetching ? <Spinner size={16} /> : "Refresh"}
                    </Button>
                </div>
            }
        />
    );
};

const toolbar: DataTableToolbarOptions = {
    search: {
        key: "promoName",
        placeholder: "Search by name or discount"
    }
};

export default PromoTable;
