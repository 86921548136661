import { IAddress, IStore, IUser } from "@snackpass/snackpass-types";
import { AxiosResponse } from "axios";
import _ from "lodash";
import fp from "lodash/fp";

import Api from "src/api/rest";
import { MinimalStore, OptionType } from "#store-selector/types";
import { filterNulls } from "src/utils/filterNulls";

export const _showStoreSelector = (user: IUser) => {
    const isTeam = fp.get("snackpassPermissions.isSnackpassEmployee")(user);
    const hasMultipleStores =
        fp.prop("snackpassPermissions.storeIds.length")(user) > 1;

    return hasMultipleStores || isTeam;
};

const _isDefault = (component?: string | null) =>
    /default/.test(component ?? "");

const _defaultAddress = (address: IAddress) =>
    _isDefault(address?.line1) ||
    _isDefault(address?.city) ||
    _isDefault(address?.state);

export const _getMyStores = async (user: IUser): Promise<IStore[]> => {
    const storePromises: Promise<AxiosResponse<{ store: IStore }>>[] = [];
    const storeIds: string[] = user.snackpassPermissions.storeIds || [];

    // TODO: note, we should really make an endpoint to return all of a user's stores
    // so we do not have this n+1 API call. Will punt till later though.
    for (const storeId of storeIds) {
        storePromises.push(Api.stores.getOne(storeId));
    }

    return (await Promise.all(storePromises)).map((res) => res.data.store);
};

export const _formatOption = (store: MinimalStore): OptionType => {
    const label = store.name.trim();
    const address = store.addressComponents;

    const hasLocation = !_.isNil(
        address?.line1 && address.city && address.state
    );

    const defaultLocation = _defaultAddress(address);

    const subtitle =
        hasLocation && !defaultLocation
            ? `${address.line1} ${address.city}, ${address.state}`
            : undefined;

    return {
        label,
        subtitle,
        value: store._id
    };
};

export const filterOption = (input: string, value?: string) =>
    value?.toLowerCase().includes(input.toLowerCase()) ?? false;

export const filterSort = (a = "", b = "") =>
    a?.toLowerCase().localeCompare(b.toLowerCase());

// see https://github.com/krisk/Fuse/issues/235
// Split the provided string by spaces (ignoring spaces within "quoted text")
export const _tokeniseStringWithQuotesBySpaces = (string: string): string[] =>
    string.match(/("[^"]*?"|[^"\s]+)+(?=\s*|\s*$)/g) ?? [];

export const _filterArchivedStores = (
    stores: (MinimalStore | null)[]
): MinimalStore[] =>
    filterNulls(stores).filter((store) => store.isArchived !== true);
