import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { getPersistedActiveStoreId } from "#hooks/initial/use-persist-store-id";
import { useAppDispatch } from "src/redux/hooks";
import { getUser } from "src/redux/selectors";
import api from "src/api/rest";
import { setActiveStoreId, setIsLoading, setStores } from "src/redux/slices";
import Constants from "#core/constants";

export function useLoadSnackpassStores() {
    const user = useSelector(getUser);
    const dispatch = useAppDispatch();

    const fetchMyAdminStores = useCallback(async () => {
        try {
            const response = await api.stores.get();
            const stores = response.data.stores;
            if (stores) {
                dispatch(setStores(stores));
            }
        } catch (err) {
            // handle error
        } finally {
            dispatch(setIsLoading(false));
        }
    }, [dispatch]);

    useEffect(() => {
        if (!user) {
            return;
        }
        // [set stores]
        dispatch(setIsLoading(true));
        const isAdmin =
            user.snackpassPermissions.isSnackpassEmployee ||
            user.snackpassPermissions.storeIds.length > 1;
        if (isAdmin) {
            void fetchMyAdminStores();
        }
        // either use the cached store or the user's store (of which they're an admin)
        const storeId =
            getPersistedActiveStoreId() ||
            user.snackpassPermissions.storeIds[0];

        if (!storeId && user.snackpassPermissions.isSnackpassEmployee) {
            dispatch(setActiveStoreId(Constants.HOGWARTS_STORE_ID));
            return;
        } else {
            dispatch(setActiveStoreId(storeId ?? null));
        }
    }, [fetchMyAdminStores, user]);
}
