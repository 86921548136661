/** @jsxImportSource @emotion/react */
import moment, { Moment } from "moment-timezone";
import React, { useCallback, useState } from "react";
import { min } from "date-fns";
import { SnackpassTimezoneEnum } from "@snackpass/snackpass-types";
import { useSelector } from "react-redux";

import { FilterSelect } from "src/@/components/ui/filter-select";
import { DateRangePicker } from "src/@/components/DateRangePicker";
import { Button } from "src/@/components/ui/button";
import { ReactComponent as CalendarIcon } from "src/assets/icons/calendar.svg";
import { getStoreTimezone } from "#utils/helpers";
import { getActiveStore } from "src/redux/selectors";

import { useGlobalDate } from "../hooks";

import { DefinedDates } from "./lib";

type Props = {
    subtitle: string;
    showYear?: boolean;
};

const DateRangeSelector = ({ subtitle, showYear }: Props) => {
    const {
        currentDuration: globalDuration,
        startDate: adjGlobalStartDate,
        endDate: adjGlobalEndDate,
        setGlobalTimeRange,
        setGlobalDuration
    } = useGlobalDate();

    const [selectedDates, setSelectedDate] = useState<{ from: Date; to: Date }>(
        {
            from: adjGlobalStartDate.toDate(),
            to: min([adjGlobalEndDate.toDate(), new Date()])
        }
    );

    const [isOpen, setIsOpen] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const store = useSelector(getActiveStore);
    const tz = getStoreTimezone(store);

    const handleDateChange = useCallback(
        (value: { from?: Date; to?: Date }) => {
            setGlobalTimeRange({
                startDate: alterDateForTimezone(moment(value.from), tz),
                endDate: alterDateForTimezone(moment(value.to), tz)
            });
            setSelectedDate({
                from: value.from ?? selectedDates.from,
                to: value.to ?? selectedDates.to
            });
        },
        [setGlobalTimeRange]
    );

    const options = Object.values(DefinedDates)
        .filter((e) => e !== DefinedDates.LAST365DAYS || showYear)
        .map((e) => ({
            label: e,
            value: e
        }));

    return (
        <div className="flex items-center">
            <DateRangePicker
                disableFutureDates
                onSelect={(value) => {
                    if (!value) {
                        // TODO: When react-day-picker supports the "required" prop for range pickers, this case can be removed.
                        // by default, react-day-picker un-selects the range when you try to click the start date of the selected range.
                        // we can interpret this as the user trying to select only the start date.
                        handleDateChange({
                            from: selectedDates.from,
                            to: selectedDates.from
                        });
                    } else if (!value?.to) {
                        // for just a single day, we set our range to be both of one day.
                        handleDateChange({ from: value.from, to: value.from });
                    } else {
                        const { from, to } = value;
                        handleDateChange({ from, to });
                    }
                }}
                selected={selectedDates}
                open={isDatePickerOpen}
                onOpenChange={setIsDatePickerOpen}
                className="invisible w-0"
            />
            <FilterSelect
                customButton={
                    <Button
                        variant="outline"
                        size="sm"
                        className="flex items-center space-x-2"
                    >
                        <CalendarIcon className="h-4 w-4" />
                        <span className="hidden md:block">
                            {globalDuration}
                        </span>
                    </Button>
                }
                title={`${subtitle} ${globalDuration}`}
                selectedValues={new Set([globalDuration])}
                options={options}
                onOptionSelected={function (value: string): void {
                    if (value === DefinedDates.CUSTOM) {
                        setIsDatePickerOpen(true);
                        setIsOpen(false);
                    }
                    setGlobalDuration(value as DefinedDates);
                }}
                open={isOpen}
                onOpenChange={setIsOpen}
            />
        </div>
    );
};

const alterDateForTimezone = (
    theDate: Moment,
    tz: SnackpassTimezoneEnum | string
): Moment =>
    moment.tz(theDate.clone().format("L").toString(), "MM-DD-YYYY", tz);

export default DateRangeSelector;
