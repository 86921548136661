import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "sonner";

import { fetchPromotions } from "#promotion/utils/fetch";
import { getActiveStore } from "src/redux/selectors";

export const PROMOTION_QUERY_KEY = "promotions";

export function usePromotions() {
    const activeStore = useSelector(getActiveStore);
    const {
        data: promotions,
        isPending,
        isFetching,
        error,
        refetch
    } = useQuery({
        queryKey: [activeStore?._id, PROMOTION_QUERY_KEY],
        queryFn: async (context) => {
            if (!context.queryKey[0]) return [];
            return (
                (await fetchPromotions(context.queryKey[0])).sort(
                    // sort initially by active -> inactive -> archived
                    // else, sort by name.
                    (a, b) => {
                        const bComp =
                            (b.isRunning ? 1 : 0) - (b.isArchived ? 1 : 0);
                        const aComp =
                            (a.isRunning ? 1 : 0) - (a.isArchived ? 1 : 0);
                        if (bComp - aComp !== 0) return bComp - aComp;
                        return a.name.localeCompare(b.name);
                    }
                ) ?? []
            );
        },

        // refetching on window focus can be annoying because it resets the page you are on.
        refetchOnWindowFocus: false,

        // don't refetch after getting the data, because it's disruptive as you are scrolling through pages
        gcTime: Infinity,
        enabled: !!activeStore?._id
    });

    useEffect(() => {
        if (error) {
            toast.error(`Error fetching promotions`, {
                description: error.message
            });
        }
    }, [error]);

    return {
        promotions,
        isPending,
        isFetching,
        error,
        refetch
    };
}
