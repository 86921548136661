import { useContext } from "react";

import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";

const BANK_ACCOUNT_DOCUMENT = "bank_account_ownership_verification";

export const useAccount = () => {
    const { verification } = useContext(PayoutsSettingsContext);

    const started = !!(
        verification?.routingNumber || verification?.accountNumber
    );

    const provided = !!verification?.document?.name.includes(
        BANK_ACCOUNT_DOCUMENT
    );

    const eventual = verification?.eventualRequirements?.includes(
        `documents.${BANK_ACCOUNT_DOCUMENT}.files`
    );

    const error = !!verification?.verificationErrors?.some(
        (e) => e.requirement === `documents.${BANK_ACCOUNT_DOCUMENT}.files`
    );

    const required = eventual || error;
    const resubmit = provided && required;

    const done = started && !required;

    return { started, provided, eventual, error, required, resubmit, done };
};
