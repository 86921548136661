import React from "react";
import { Drawer as AntdDrawer } from "antd";
import { connectModal, InjectedProps } from "redux-modal";
import styled from "@emotion/styled";
import "antd/dist/antd.css";
import { useMediaQuery } from "react-responsive";

import useWindowDimensions from "#hooks/use-window-dimensions";
import OrderDetails from "#order-details";

type DrawerProps = { purchaseId: string } & InjectedProps;

const OrderDetailsDrawerComponent = ({
    handleHide,
    show,
    purchaseId
}: DrawerProps) => {
    const isMobile = useMediaQuery({
        query: `(max-width: 990px)`
    });

    const { width } = useWindowDimensions();

    return (
        <AntdDrawer
            width={isMobile ? width : 800}
            placement="right"
            onClose={handleHide}
            visible={show}
            closable={false}
            destroyOnClose={false}
            // so the first animation when the drawer is triggered is smooth
            forceRender={true}
            zIndex={9001}
        >
            <OrderDetailsWrapper>
                <OrderDetails purchaseId={purchaseId} handleHide={handleHide} />
            </OrderDetailsWrapper>
        </AntdDrawer>
    );
};

const OrderDetailsWrapper = styled.div`
    //removes large margins on original order details page
    .order-details-modal {
        margin: 0 !important;
    }
    height: 100%;
`;

export const OrderDetailsDrawer = connectModal({
    name: "OrderDetailsDrawer",
    destroyOnHide: true
})(OrderDetailsDrawerComponent);
