import React from "react";
import Skeleton from "react-loading-skeleton";

import { CampaignType } from "#guestbook/redux";

import * as P from "./styles";
export interface IFooterProps {
    footer?: React.ReactNode;
    children?: React.ReactNode;
    campaignType?: CampaignType | null;
}

export const Footer: React.FC<IFooterProps> = ({
    footer,
    children,
    campaignType
}) => (
    <P.Footer>
        {children ? (
            children
        ) : footer ? (
            <P.MessageWrapper>{footer}</P.MessageWrapper>
        ) : (
            <P.SkeletonWrapper>
                <Skeleton count={4} className="skeleton" />
            </P.SkeletonWrapper>
        )}
    </P.Footer>
);
