import React from "react";
import { Spin } from "antd";
import compose from "lodash/fp/compose";
import { DateTime } from "luxon";
import { toDollarFormatted } from "#reports/sales-summary/lib";

type UserStatsType = {
    customerSince: DateTime | undefined;

    userNumOrders: number | undefined;
    amountSpent: string;

    frequency?: string | undefined;

    lastOrder: DateTime | undefined;

    storeCredit: number;
    pointsBalance: number | undefined;

    numRefunds: number;
    amountRefunded: string;
};

const getOrderFrequency = (
    userNumOrders: number,
    customerSince: DateTime
): string => {
    const today = DateTime.local();
    const difference = today.diff(customerSince).as("days");
    const numberOfWeeks = Math.round(difference / 7);
    if (numberOfWeeks === 0) {
        return "0/week"; // return '0.00' if the number of weeks is 0
    }
    const orderFrequencyPerWeek = userNumOrders / numberOfWeeks;
    if (orderFrequencyPerWeek >= 1) {
        return `${orderFrequencyPerWeek.toFixed(2)} /week`;
    } else {
        const numberOfMonths = Math.round(difference / 30); // assuming a month has 30 days
        const orderFrequencyPerMonth = userNumOrders / numberOfMonths;
        if (orderFrequencyPerMonth <= 1) {
            return `<1 /month`;
        } else {
            return `${orderFrequencyPerMonth.toFixed(2)} /month`;
        }
    }
};

export const alertAxiosError = compose(
    alert,
    (msg) => `ERROR: ${msg}`,
    ({ response }) => response.data.message
);

export const UserStats = ({
    userStats,
    isLoading
}: {
    userStats: UserStatsType | undefined;
    isLoading: boolean;
}) =>
    isLoading ? (
        <div className="my-[10px] text-center">
            <Spin tip="Loading..." />
        </div>
    ) : userStats ? (
        <div className="grid grid-cols-2 justify-center">
            {userStats.userNumOrders ? (
                <div className="mb-2">
                    <div className="text-neutral-500">Orders</div>
                    <div className="font-semibold">
                        {userStats.userNumOrders}
                    </div>
                </div>
            ) : null}
            {userStats.amountSpent ? (
                <div className="mb-2">
                    <div className="text-neutral-500">Total Spent</div>
                    <div className="font-semibold">{userStats.amountSpent}</div>
                </div>
            ) : null}
            {userStats.frequency ? (
                <div className="mb-2">
                    <div className="text-neutral-500">Order Frequency</div>
                    <div className="font-semibold">{userStats.frequency}</div>
                </div>
            ) : userStats.userNumOrders && userStats.customerSince ? (
                <div className="mb-2">
                    <div className="text-neutral-500">Order Frequency</div>
                    <div className="font-semibold">
                        {getOrderFrequency(
                            userStats.userNumOrders,
                            userStats.customerSince
                        )}
                    </div>
                </div>
            ) : null}
            {userStats.lastOrder ? (
                <div className="mb-2">
                    <div className="text-neutral-500">Last Order</div>
                    <div className="font-semibold">{`${userStats.lastOrder.monthLong} ${userStats.lastOrder.day} ${userStats.lastOrder.year}`}</div>
                </div>
            ) : null}

            {userStats.storeCredit ?? 0 > -1 ? (
                <div className="mb-2">
                    <div className="text-neutral-500">Store Credit</div>
                    <div className="font-semibold">
                        {toDollarFormatted(userStats.storeCredit)}
                    </div>
                </div>
            ) : null}
            {userStats.pointsBalance ?? 0 > -1 ? (
                <div className="mb-2">
                    <div className="text-neutral-500">Points</div>
                    <div className="font-semibold">
                        {userStats.pointsBalance}
                    </div>
                </div>
            ) : null}

            <div className="mb-2">
                <div className="text-neutral-500">Refunds</div>
                <div className="font-semibold">
                    {userStats.numRefunds || 0} (
                    {userStats.amountRefunded || "$0.00"})
                </div>
            </div>
        </div>
    ) : (
        <div>No User Info</div>
    );
