import React from "react";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { toast } from "sonner";

import CheckBox from "#settings/settings-permissions/checkbox";
import {
    PermissionsPresets,
    Roles
} from "#settings/settings-permissions/types";
import { ToggleReadWriteNone } from "#settings/settings-permissions/toggle-read-write-none";
import { useHasEditAdminForActiveStore } from "#hooks/use-has-edit-admin-for-active-store";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from "src/@/components/ui/select";
import AdminRoleSelectItem from "#settings/settings-permissions/select-item";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { useAppSelector } from "src/redux/hooks";
import { getUserSeniorSupportPermission } from "src/redux/selectors";

type DashboardProps = {
    toggles: typeof Roles.allFalseRole;
    setToggles: React.Dispatch<React.SetStateAction<typeof Roles.allFalseRole>>;
    hasEmail: boolean;
    hidePayouts?: boolean;
};

export const DashboardPermissions = ({
    toggles,
    setToggles,
    hasEmail,
    hidePayouts = false
}: DashboardProps) => {
    const canEdit = useHasEditAdminForActiveStore();
    const canEditPayouts = useAppSelector(getUserSeniorSupportPermission);

    const _setRole = (role: string) => {
        switch (role) {
            case "admin":
                setToggles(Roles.adminRole);
                break;
            case "manager":
                setToggles(Roles.managerRole);
                break;
            case "staff":
                setToggles(Roles.staffRole);
                break;
            case "marketer":
                setToggles(Roles.marketerRole);
                break;
            case "accountant":
                setToggles(Roles.accountantRole);
                break;
            case "employee":
                setToggles(Roles.allFalseRole);
                break;
            default:
                break;
        }
    };

    const handleToggle = (permissionKey: keyof typeof toggles) => {
        setToggles((toggles) => ({
            ...toggles,
            [permissionKey]: !toggles[permissionKey]
        }));
    };

    const handle3WayToggleChange = (
        readPermKey: string,
        writePermKey: string,
        read: boolean,
        write: boolean
    ) => {
        setToggles((toggles) => ({
            ...toggles,
            [readPermKey]: read,
            [writePermKey]: write
        }));
    };

    return (
        <>
            <div className="pt-4 font-medium">Presets</div>
            <div className="flex flex-col justify-center border-b border-neutral-400 pb-6">
                <div className="mb-4 text-small text-neutral-500">
                    Assign a permissions preset and/or you can adjust individual
                    permissions below
                </div>
                <Select
                    onValueChange={(value) => {
                        _setRole(value);
                    }}
                >
                    <SelectTrigger className="border-neutral-400">
                        <SelectValue placeholder="Choose Preset" />
                    </SelectTrigger>
                    <SelectContent className="bg-white">
                        <SelectGroup>
                            <SelectLabel>Presets</SelectLabel>
                            {Object.keys(PermissionsPresets).map((roleName) => {
                                const key =
                                    roleName as keyof typeof PermissionsPresets;
                                return (
                                    <AdminRoleSelectItem
                                        key={key}
                                        value={key}
                                        name={PermissionsPresets[key].name}
                                        description={
                                            PermissionsPresets[key].desc
                                        }
                                    />
                                );
                            })}
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
            {!hasEmail && (
                <Alert variant="warning">
                    <AlertTitle className="text-base">
                        <ExclamationTriangleIcon className="mr-2" />
                        Security Notice
                    </AlertTitle>
                    <AlertDescription className="text-sm">
                        Users must have an email address to be assigned
                        permissions
                    </AlertDescription>
                </Alert>
            )}
            <div className="py-4 font-medium">Dashboard</div>
            <CheckBox
                title="Dashboard Page"
                onClick={() => handleToggle("hasDashboard")}
                isChecked={!!toggles.hasDashboard}
            />
            <CheckBox
                title="Guestbook"
                onClick={() => handleToggle("hasGuestbook")}
                isChecked={!!toggles.hasGuestbook}
            />
            <CheckBox
                title="Reports → Locations"
                onClick={() => handleToggle("hasReportsLocations")}
                isChecked={!!toggles.hasReportsLocations}
            />
            <CheckBox
                title="Reports → Sales"
                onClick={() => handleToggle("hasSalesReports")}
                isChecked={!!toggles.hasSalesReports}
            />
            <CheckBox
                title="Reports → Menu"
                onClick={() => handleToggle("hasReportsMenu")}
                isChecked={!!toggles.hasReportsMenu}
            />
            <CheckBox
                title="Reports → Customers"
                onClick={() => handleToggle("hasReportsCustomers")}
                isChecked={!!toggles.hasReportsCustomers}
            />
            <CheckBox
                title="Reports → Promotions"
                onClick={() => handleToggle("hasReportsPromotions")}
                isChecked={!!toggles.hasReportsPromotions}
            />
            <CheckBox
                title="Reports → Financial"
                onClick={() => handleToggle("hasFinancialReports")}
                isChecked={!!toggles.hasFinancialReports}
            />
            <CheckBox
                title="Reports → Labor"
                onClick={() => handleToggle("hasReportsLabor")}
                isChecked={!!toggles.hasReportsLabor}
            />
            <CheckBox
                title="Reports → Gift Cards"
                onClick={() => handleToggle("hasReportsGiftCards")}
                isChecked={!!toggles.hasReportsGiftCards}
            />
            <CheckBox
                title="Orders"
                onClick={() => handleToggle("hasOrders")}
                isChecked={!!toggles.hasOrders}
            />
            <CheckBox
                title="Menu"
                onClick={() => handleToggle("hasMenuEditing")}
                isChecked={!!toggles.hasMenuEditing}
            />
            <CheckBox
                title="Catering"
                onClick={() => handleToggle("hasCatering")}
                isChecked={!!toggles.hasCatering}
            />
            <CheckBox
                title="Promos"
                onClick={() => handleToggle("hasPromos")}
                isChecked={!!toggles.hasPromos}
            />
            <CheckBox
                title="Team"
                onClick={() => handleToggle("hasEmployees")}
                isChecked={!!toggles.hasEmployees}
            />
            <CheckBox
                title="Devices"
                onClick={() => handleToggle("hasDevices")}
                isChecked={!!toggles.hasDevices}
            />
            <CheckBox
                title="Create, Edit, and Change Permissions for All Users"
                onClick={() => handleToggle("hasEditAdmin")}
                isChecked={toggles.hasEditAdmin ?? false}
            />
            {!hidePayouts && (
                <CheckBox
                    title="Payouts"
                    isChecked={!!toggles.hasPayouts}
                    disabled={!canEditPayouts}
                    tooltip={
                        canEditPayouts
                            ? undefined
                            : "Please contact partners@snackpass.co to change this setting."
                    }
                    onClick={() => {
                        if (!canEditPayouts) {
                            toast.warning(
                                "Please contact partners@snackpass.co to change this setting."
                            );
                            return;
                        }
                        handleToggle("hasPayouts");
                    }}
                />
            )}
            <ToggleReadWriteNone
                title="Billing"
                readPerm={!!toggles.hasBilling}
                writePerm={!!toggles.hasBillingWrite}
                onSetNone={() =>
                    handle3WayToggleChange(
                        "hasBilling",
                        "hasBillingWrite",
                        false,
                        false
                    )
                }
                onSetRead={() =>
                    handle3WayToggleChange(
                        "hasBilling",
                        "hasBillingWrite",
                        true,
                        false
                    )
                }
                onSetWrite={() =>
                    handle3WayToggleChange(
                        "hasBilling",
                        "hasBillingWrite",
                        true,
                        true
                    )
                }
                disabled={!canEdit}
            />
            <ToggleReadWriteNone
                title="Store Settings"
                readPerm={!!toggles.hasSettings}
                writePerm={!!toggles.hasSettingsWrite}
                onSetNone={() =>
                    handle3WayToggleChange(
                        "hasSettings",
                        "hasSettingsWrite",
                        false,
                        false
                    )
                }
                onSetRead={() =>
                    handle3WayToggleChange(
                        "hasSettings",
                        "hasSettingsWrite",
                        true,
                        false
                    )
                }
                onSetWrite={() =>
                    handle3WayToggleChange(
                        "hasSettings",
                        "hasSettingsWrite",
                        true,
                        true
                    )
                }
                disabled={!canEdit}
            />
        </>
    );
};
