import React, { useState } from "react";
import { z } from "zod";
import {
    FormProvider,
    UseFormReset,
    useForm,
    useFormContext
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useIsMutating, useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { IStore } from "@snackpass/snackpass-types";

import { Button } from "src/@/components/ui/button";
import { PayoutAlert } from "#settings/settings-senior-support/one-off-payout/payout-alert";
import api from "src/api/rest";
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "src/@/components/ui/form";
import { AmountInput } from "#settings/settings-senior-support/components/amount-input";
import { Input } from "src/@/components/ui/input";
import { CreatePayoutPopover } from "#settings/settings-senior-support/one-off-payout/create-payout-popover";

const FormSchema = z.object({
    amountDollars: z.number().positive(),
    description: z
        .string()
        .min(5, { message: "Description must be at least 5 characters" })
});

export type FormValues = z.infer<typeof FormSchema>;

const formDefaults = (): FormValues => ({
    amountDollars: 0,
    description: ""
});

function useCreatePayout({
    storeId,
    reset
}: {
    storeId: string;
    reset: UseFormReset<FormValues>;
}) {
    const { mutateAsync } = useMutation({
        mutationKey: ["createPayout", storeId],
        async mutationFn(values: FormValues) {
            const response = await api.payouts.createPayout(storeId, {
                description: values.description,
                amountCents: values.amountDollars * 100
            });
            reset(formDefaults());
            return response.data.payoutId;
        },
        async onSuccess(payoutId: string) {
            toast.success(`Payout ${payoutId} Created`, {
                duration: 5000,
                id: "payout-succeeded"
            });
        },
        onError() {
            toast.error("Payout Failed", {
                className: "bg-critical-light",
                duration: 5000,
                id: "payout-failed"
            });
        }
    });
    return mutateAsync;
}

export const OneOffPayout = ({
    store,
    canEdit
}: {
    store: IStore;
    canEdit: boolean;
}) => {
    const form = useForm<FormValues>({
        defaultValues: formDefaults(),
        resolver: zodResolver(FormSchema),
        mode: "all"
    });

    const createPayout = useCreatePayout({
        storeId: store._id,
        reset: form.reset
    });

    const [isVisible, setIsVisible] = useState(false);

    const handleClose = () => {
        setIsVisible(false);
    };

    const handleSubmit = (v: FormValues) => {
        setIsVisible(false);
        void createPayout(v);
    };

    const Footer = () => {
        const {
            formState: { isDirty, isValid }
        } = useFormContext<FormValues>();
        const isMutating = !!useIsMutating({
            mutationKey: ["createPayout", store._id]
        });
        const disabled = !canEdit || !isDirty || isMutating || !isValid;
        return (
            <div className="mt-4 flex flex-row justify-end gap-2">
                <Button
                    className="mt-2"
                    variant="outline"
                    type="reset"
                    disabled={disabled}
                    onClick={() => form.reset(formDefaults())}
                >
                    Reset
                </Button>
                <Button className="mt-2" type="submit" disabled={disabled}>
                    {isMutating ? "Creating" : "Confirm"}
                </Button>
            </div>
        );
    };

    return (
        <div className="my-4 flex flex-col justify-center overflow-y-scroll">
            <div className="mx-2">
                <PayoutAlert />
            </div>
            <div className="m-2">
                <div className="my-2 flex items-center justify-between">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            setIsVisible(true);
                        }}
                        className="relative h-full w-full overflow-hidden"
                    >
                        <FormProvider {...form}>
                            <FormField
                                control={form.control}
                                name="amountDollars"
                                render={({ field }) => (
                                    <FormItem className="mb-4 flex w-[300px] flex-col pl-2">
                                        <FormLabel className="h-[20px] text-sm">
                                            Amount
                                            <span className="ml-[1px] text-red-700">
                                                *
                                            </span>
                                        </FormLabel>
                                        <FormControl>
                                            <AmountInput
                                                prefix="$"
                                                disabled={!canEdit}
                                                value={field.value}
                                                placeholder="0.00"
                                                onChange={(e) =>
                                                    field.onChange(
                                                        parseFloat(
                                                            e.target.value
                                                        )
                                                    )
                                                }
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="description"
                                render={({ field }) => (
                                    <FormItem className="mb-4 flex w-[400px] flex-col pl-2">
                                        <FormLabel className="h-[20px] text-sm">
                                            Description
                                            <span className="ml-[1px] text-red-700">
                                                *
                                            </span>
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                disabled={!canEdit}
                                                value={field.value}
                                                placeholder="Description (e.g., Cleanup for Incident-XXX)"
                                                onChange={(e) =>
                                                    field.onChange(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <Footer />
                        </FormProvider>
                    </form>
                </div>
                <CreatePayoutPopover
                    store={store}
                    values={form.getValues()}
                    isVisible={isVisible}
                    canEdit={canEdit}
                    onClose={handleClose}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    );
};
