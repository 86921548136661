import {
    IPromotion,
    IStore,
    ITimeRangeSchema,
    PromoTypes
} from "@snackpass/snackpass-types";
import { match, P } from "ts-pattern";
import _ from "lodash";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

import {
    dayMap,
    DAYS_OF_WEEK,
    DISCOUNT_TYPES,
    Forms,
    HOURS_PER_DAY,
    SECONDS_PER_MINUTE
} from "#promotion/utils/constants";
import {
    DayOfWeek,
    DealPromo,
    DiscountPromo,
    FIELD_NAMES,
    FormDefaultState,
    FormUsageTypes,
    FulfillmentValues,
    GenericPromo,
    GiftCardPromo,
    HappyHourPromo,
    HoursItem,
    MenuItem,
    PlatformValues,
    PromoCodePromo,
    PromoShortcut,
    QualifierValues,
    RewardPromo
} from "#promotion/utils/types";
import { GenericDefaultValues } from "#promotion/utils/validation";
import { RewardDefaultValues } from "#promotion/utils/validation/form-schemas/reward";
import { Routes } from "#navigation/routes";
import { BogoDefaultValues } from "#/promotion/utils/validation/form-schemas/bogo";
import { HappyHourDefaultValues } from "#/promotion/utils/validation/form-schemas/happy-hour";
import { DealDropDefaultValues } from "#promotion/utils/validation/form-schemas/deal-drop";
import {
    FirstTimeDefaultValues,
    StudentsDefaultValues
} from "#promotion/utils/validation/form-schemas/audience-specific";
import { PromoCodeDefaultValues } from "#promotion/utils/validation/form-schemas/promo-code";
import { GiftCardDefaultValues } from "#promotion/utils/validation/form-schemas/gift-card";
import { useCategoriesWithProducts } from "#menu/hooks";
import { CategoryWithProducts } from "#menu/domain";

export type Time = {
    day: number;
    hours: number;
    minutes: number;
    seconds?: number;
    am?: boolean;
    pm?: boolean;
};

const MINUTES_PER_HOUR = 60;
const MINUTES_PER_DAY = MINUTES_PER_HOUR * 24;

export function parseTime(time: number): Time {
    const day = Math.floor(time / MINUTES_PER_DAY);
    const hours = Math.floor(time / MINUTES_PER_HOUR) % 24;
    const minutes = time % MINUTES_PER_HOUR;

    return { day, hours, minutes };
}

export function formatTimeRangeAsHourItem(
    timeRange: ITimeRangeSchema
): HoursItem {
    const parseStartTime = parseTime(timeRange.start);
    const parseEndTime = parseTime(timeRange.end);
    return {
        startTime: {
            time: moment()
                .hour(parseStartTime.hours)
                .minute(parseStartTime.minutes)
                .toDate(),
            dayOfWeek: DAYS_OF_WEEK[parseTime(timeRange.start).day] as DayOfWeek
        },
        endTime: {
            time: moment()
                .hour(parseEndTime.hours)
                .minute(parseEndTime.minutes)
                .toDate(),
            dayOfWeek: DAYS_OF_WEEK[parseTime(timeRange.end).day] as DayOfWeek
        }
    };
}

export function transformHoursToFormHoursVersion(
    local: ITimeRangeSchema[]
): HoursItem[] {
    return local.map((timeRange) => formatTimeRangeAsHourItem(timeRange));
}

const convertSecondsToDays = (seconds: number) =>
    seconds / SECONDS_PER_MINUTE / MINUTES_PER_HOUR / HOURS_PER_DAY;

/**
 *
 * @param productIds List of product ids selected
 * @param categories List of category ids selected
 * @param storeCategories List of categories at store
 * @returns List of all productIds, including those already accounted for by a selected category
 */
const gatherProductIdsBasedOnCategories = (
    productIds: string[],
    categories: string[],
    storeCategories: CategoryWithProducts[]
) =>
    productIds.concat(
        categories.flatMap(
            (id: string) =>
                storeCategories.find((cat) => cat.id === id)?.productIds || []
        )
    );

// TypeGuards
const isDealPromo = (
    promo: IPromotion,
    formData: DiscountPromo | RewardPromo | DealPromo
): formData is DealPromo => promo.type === PromoTypes.Deal;

const isDiscountPromo = (
    promo: IPromotion,
    formData: DiscountPromo | RewardPromo | DealPromo
): formData is DiscountPromo => promo.type === PromoTypes.Discount;

const isPromoCodePromo = (
    promo: IPromotion,
    formData: DiscountPromo | RewardPromo | DealPromo | PromoCodePromo
): formData is PromoCodePromo => promo.type === PromoTypes.PromoCode;

const isGiftCardPromo = (
    promo: IPromotion,
    formData: DiscountPromo | RewardPromo | DealPromo | GiftCardPromo
): formData is GiftCardPromo => promo.type === PromoTypes.GiftCard;

// ______________________________________________________
// Most everything above this line is pulled from Snackface

const deriveFormRouteFromPromotion = (promotion: IPromotion) => {
    let formRoute: Routes = Forms.GENERIC.route;

    // Reward Promo is determined by promo type
    if (promotion.type === PromoTypes.Reward) {
        formRoute = Forms.REWARD.route;
    }

    if (promotion.type === PromoTypes.PromoCode) {
        formRoute = Forms.PROMOCODE.route;
    }

    if (promotion.type === PromoTypes.GiftCard) {
        formRoute = Forms.GIFTCARD.route;
    }
    // Return generic (Discount) form if no other specific form matches
    return formRoute;
};

const deriveFormDataFromPromotion = (
    promotion: IPromotion,
    store: IStore,
    categories: CategoryWithProducts[]
): DiscountPromo | RewardPromo | DealPromo | HappyHourPromo => {
    let formData: DiscountPromo | RewardPromo | DealPromo = {
        ...GenericDefaultValues
    };
    const type = promotion.type;
    const products = gatherProductIdsBasedOnCategories(
        promotion.productIds,
        promotion.categories,
        categories
    );

    if (type === PromoTypes.Reward) {
        formData = { ...RewardDefaultValues };
    }

    // ==========================================
    // Promotion Fields present on all PromoTypes
    // ==========================================

    // Name
    formData[FIELD_NAMES.NAME] = promotion.name;

    // Discount Type
    formData = {
        ...formData,
        ...match(promotion)
            .with({ dealItems: P.when((items) => items.length > 1) }, () => {
                // This is a bit "hacky" b/c this assumes that all deal items have the same discount amount
                const discountedItem = promotion.dealItems.find(
                    (item) => item.discount !== null
                );
                if (discountedItem) {
                    return match(discountedItem.discount)
                        .with({ dollarsOff: P.number }, () => ({
                            [FIELD_NAMES.DISCOUNT_TYPE]:
                                DISCOUNT_TYPES.DOLLARS_OFF,
                            // discount is defined with { dollarsOff: number }
                            [FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS]:
                                discountedItem.discount!.dollarsOff
                        }))
                        .with({ percentOff: P.number }, () => ({
                            [FIELD_NAMES.DISCOUNT_TYPE]:
                                discountedItem.discount!.percentOff === 100
                                    ? DISCOUNT_TYPES.FREE_ITEM
                                    : DISCOUNT_TYPES.PERCENT_OFF,
                            // discount is defined with { percentOff: number }
                            [FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT]:
                                discountedItem.discount!.percentOff
                        }))
                        .otherwise(() => ({}));
                } else {
                    return {};
                }
            })
            .with({ discount: { dollarsOff: P.number } }, () => ({
                [FIELD_NAMES.DISCOUNT_TYPE]: DISCOUNT_TYPES.DOLLARS_OFF,
                [FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS]:
                    promotion.discount?.dollarsOff
            }))
            // 100% Percent Off == Free_Item
            .with({ discount: { percentOff: P.number } }, () =>
                promotion.discount?.percentOff === 100
                    ? {
                          [FIELD_NAMES.DISCOUNT_TYPE]: DISCOUNT_TYPES.FREE_ITEM,
                          [FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT]: 100,
                          [FIELD_NAMES.DISCOUNT_ADDONS]:
                              promotion.shouldDiscountAddons
                      }
                    : {
                          [FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT]:
                              promotion.discount?.percentOff,
                          [FIELD_NAMES.DISCOUNT_ADDONS]:
                              promotion.shouldDiscountAddons
                      }
            )
            .otherwise(() => ({}))
    };

    // Discounted Items
    // Default Discount_Qualifier is already Any_Item
    if (!promotion.storewide) {
        formData[FIELD_NAMES.DISCOUNT_QUALIFIER] = QualifierValues.SPECIFIC;
        formData[FIELD_NAMES.DISCOUNTED_ITEMS] = [
            { label: "", products, categories: promotion.categories }
        ];
    }

    // ============================
    // Reward Only Promotion Fields
    // ============================

    formData = {
        ...formData,
        ...match(promotion)
            .with({ type: PromoTypes.Reward }, () => ({
                [FIELD_NAMES.POINTS_REQUIRED]: promotion.pointsRequired,
                [FIELD_NAMES.EXPIRATION_DAYS]: promotion.rewardExpiration
                    ?.seconds
                    ? convertSecondsToDays(promotion.rewardExpiration.seconds)
                    : -1 // -1 == NEVER
            }))
            .otherwise(() => ({}))
    };

    // ==========================
    // Deal Only Promotion Fields
    // ==========================

    // Required Purchase
    if (isDealPromo(promotion, formData) && promotion.dealItems?.length) {
        const [required, discounted]: [MenuItem[], MenuItem[]] =
            // dealItems that are discounted are part of discounted items
            // dealItems that are not discounted are required for purchase
            promotion.dealItems.reduce(
                (arr, item) => {
                    const newArr = arr;
                    const products = gatherProductIdsBasedOnCategories(
                        item.productIds,
                        item.categories || [],
                        categories
                    );
                    // Found required item
                    if (_.isEmpty(item.discount)) {
                        newArr[0].push({
                            label: item.name,
                            products,
                            categories: item.categories || []
                        });
                    } else {
                        // Found discounted item
                        newArr[1].push({
                            label: item.name,
                            products,
                            categories: item.categories || []
                        });
                    }
                    return newArr;
                },
                [[], []] as [MenuItem[], MenuItem[]]
            );
        formData[FIELD_NAMES.REQUIRED_PURCHASE_ITEMS] = required;
        formData[FIELD_NAMES.DISCOUNTED_ITEMS] = discounted;
        // Set Required Purchase to true if there are non-discounted items in the deal
        if (required.length) {
            formData[FIELD_NAMES.REQUIRED_PURCHASE] = true;
        }
    }

    // ==========================
    // Promo Code Only Promotion Fields
    // ==========================
    if (isPromoCodePromo(promotion, formData)) {
        if (promotion.code) {
            formData[FIELD_NAMES.PROMO_CODE] = promotion.code;
            formData[FIELD_NAMES.PROMO_CODE_ENABLED] = true;
            formData[FIELD_NAMES.DISCOUNT_ADDONS] =
                promotion.shouldDiscountAddons || false;
            formData[FIELD_NAMES.SINGLE_USE] = !promotion.multiuse;
        }
    }

    // ==========================
    // Gift Card Only Promotion Fields
    // ==========================
    if (isGiftCardPromo(promotion, formData)) {
        formData[FIELD_NAMES.PROMOTION_TYPE] = PromoTypes.GiftCard;
        const maxDiscount = promotion?.discount?.maximumDiscount;
        formData[FIELD_NAMES.MAXIMUM_DISCOUNT] = maxDiscount;
        formData[FIELD_NAMES.MINIMUM_PRICE] =
            promotion.conditions?.minimumPrice;
        if (promotion.imageUrl) {
            formData[FIELD_NAMES.IMAGE] = promotion.imageUrl;
        }
    }

    // ===============================
    // Misc. Discount Promotion Fields
    // ===============================

    if (
        isDiscountPromo(promotion, formData) ||
        isDealPromo(promotion, formData) ||
        isPromoCodePromo(promotion, formData)
    ) {
        if (promotion.imageUrl) {
            // Promo Image
            formData[FIELD_NAMES.IMAGE] = promotion.imageUrl;
        }

        // Cart Rules
        formData[FIELD_NAMES.CART_MINIMUM] = promotion.conditions
            ?.cartMin as number;
        formData[FIELD_NAMES.CART_MAXIMUM] = promotion.conditions
            ?.cartMax as number;
        formData[FIELD_NAMES.ONE_PER_CART] =
            promotion.conditions?.onePerCart || false;

        // Customer Rules
        if (promotion?.targets?.length) {
            formData[FIELD_NAMES.AUDIENCE] = promotion.targets[0];
        }
        formData[FIELD_NAMES.SINGLE_USE] = !promotion.multiuse;
        if (promotion?.maximumUses && promotion?.maximumUses > 0) {
            formData[FIELD_NAMES.LIMIT_USES] = true;
            formData[FIELD_NAMES.TOTAL_USES] = promotion.maximumUses;
        }

        // Schedule
        if (promotion.hours?.local) {
            const transformedHours = transformHoursToFormHoursVersion(
                promotion.hours?.local
            );

            if (transformedHours.length > 0) {
                formData[FIELD_NAMES.SCHEDULE_ENABLED] = true;
            }

            for (const hours of transformedHours) {
                const { startTime, endTime } = hours;

                const relevantDayMap = dayMap.find(
                    (day) => day.day === startTime.dayOfWeek
                );

                if (relevantDayMap && relevantDayMap.enabled) {
                    formData[relevantDayMap.enabled] = true;

                    const hours = formData[relevantDayMap.field];
                    formData[relevantDayMap.field] = hours.concat({
                        start: startTime.time.toString(),
                        end: endTime.time.toString()
                    });
                }
            }
        }

        // Duration
        if (promotion.activeTimePeriod !== null) {
            const startTime = promotion.activeTimePeriod.startTime; // These two can also be in Moment format
            const endTime = promotion.activeTimePeriod.endTime;

            formData[FIELD_NAMES.DURATION_ENABLED] = true;
            formData[FIELD_NAMES.DURATION_START_DATE] = startTime
                ? moment(startTime).tz(store.hours.zone).format()
                : "";
            formData[FIELD_NAMES.DURATION_END_DATE] = endTime
                ? moment(endTime).tz(store.hours.zone).format()
                : "";
        }

        // Platforms
        if (promotion.isEmployeeMode) {
            formData[FIELD_NAMES.PLATFORMS] = PlatformValues.OnlyRegister;
        } else if (promotion.isKioskEligible) {
            formData[FIELD_NAMES.PLATFORMS] =
                PlatformValues.AppAndKioskAndRegister;
            if (isPromoCodePromo(promotion, formData)) {
                formData[FIELD_NAMES.PLATFORMS] = PlatformValues.AppAndKiosk;
            }
        } else {
            formData[FIELD_NAMES.PLATFORMS] = PlatformValues.OnlyApp;
        }

        // Fulfillment
        if (promotion.conditions?.pickupOnly) {
            formData[FIELD_NAMES.FULFILLMENT_METHODS] =
                FulfillmentValues.PickupAndDineIn;
        } else if (promotion.conditions?.deliveryOnly) {
            formData[FIELD_NAMES.FULFILLMENT_METHODS] =
                FulfillmentValues.Delivery;
        } else {
            formData[FIELD_NAMES.FULFILLMENT_METHODS] = FulfillmentValues.All;
        }
    }

    return formData;
};

export const usePromotionForm = () => {
    const history = useHistory();
    const categories = useCategoriesWithProducts();

    const editPromotionForm = (
        promotion: IPromotion,
        store: IStore | null
    ): void => {
        if (store) {
            const formRoute = deriveFormRouteFromPromotion(promotion);
            const formData = deriveFormDataFromPromotion(
                promotion,
                store,
                categories
            );

            const state: FormDefaultState = {
                promoId: promotion._id,
                formData,
                formUsage: FormUsageTypes.Edit
            };
            history.push(formRoute, state);
        } else {
            throw Error("Trying to edit a promo without an active store");
        }
    };

    const duplicatePromotionForm = (
        promotion: IPromotion,
        store: IStore | null
    ): void => {
        if (store) {
            const formRoute = deriveFormRouteFromPromotion(promotion);
            const formData = deriveFormDataFromPromotion(
                promotion,
                store,
                categories
            );

            formData.NAME += " Copy";
            const state: FormDefaultState = {
                formData,
                formUsage: FormUsageTypes.Create
            };
            history.push(formRoute, state);
        } else {
            throw Error("Trying to duplicate a promo without an active store");
        }
    };

    const editPromotionFormFromShortcut = (shortcutType: PromoShortcut) => {
        let formData:
            | GenericPromo
            | DiscountPromo
            | RewardPromo
            | HappyHourPromo
            | GiftCardPromo = {
            ...GenericDefaultValues
        };
        let formUsage = FormUsageTypes.Create;
        let route = Routes.CreatePromotionGeneric;

        switch (shortcutType) {
            case PromoShortcut.FirstTime:
                formData = {
                    ...FirstTimeDefaultValues
                };
                formUsage = FormUsageTypes.CreateFromTemplate;
                route = Forms.FIRSTTIME.route;
                break;

            case PromoShortcut.Student:
                formData = {
                    ...StudentsDefaultValues
                };
                formUsage = FormUsageTypes.CreateFromTemplate;
                route = Forms.STUDENTS.route;
                break;
            case PromoShortcut.Bogo:
                formData = {
                    ...BogoDefaultValues
                };
                formUsage = FormUsageTypes.CreateFromTemplate;
                route = Forms.BOGO.route;
                break;
            case PromoShortcut.HappyHour:
                formData = {
                    ...HappyHourDefaultValues
                };
                formUsage = FormUsageTypes.CreateFromTemplate;
                route = Forms.HAPPYHOUR.route;
                break;
            case PromoShortcut.PromoCode:
                formData = {
                    ...PromoCodeDefaultValues
                };
                formUsage = FormUsageTypes.CreateFromTemplate;
                route = Forms.PROMOCODE.route;
                break;
            case PromoShortcut.Reward:
                formData = {
                    ...RewardDefaultValues
                };
                formUsage = FormUsageTypes.CreateFromTemplate;
                route = Forms.REWARD.route;
                break;
            case PromoShortcut.DealDrop:
                formData = {
                    ...DealDropDefaultValues
                };
                formUsage = FormUsageTypes.CreateFromTemplate;
                route = Forms.DEALDROP.route;
                break;
            case PromoShortcut.GiftCard:
                formData = {
                    ...GiftCardDefaultValues
                };
                formUsage = FormUsageTypes.CreateFromTemplate;
                route = Forms.GIFTCARD.route;
                break;

            default:
                break;
        }

        const state: FormDefaultState = {
            formData,
            formUsage:
                formUsage === FormUsageTypes.Create ||
                formUsage === FormUsageTypes.CreateFromTemplate
                    ? "Create"
                    : "Edit"
        };

        history.push(route, state);
    };

    return {
        editPromotionForm,
        editPromotionFormFromShortcut,
        duplicatePromotionForm
    };
};

export const canEditPromo = (promotion: IPromotion): boolean =>
    (promotion.type === PromoTypes.Discount &&
        typeof promotion.discount?.newPrice === "undefined") ||
    promotion.type === PromoTypes.Reward ||
    promotion.type === PromoTypes.PromoCode ||
    promotion.type === PromoTypes.Deal;

export const updatePromotionState = (
    updatedPromo: IPromotion,
    promoState: IPromotion[]
) => {
    const updatedPromoState = promoState.map((promo) => {
        if (promo._id === updatedPromo._id) {
            return updatedPromo;
        } else {
            return promo;
        }
    });

    return updatedPromoState;
};
