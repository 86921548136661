/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { IOrderItem, IPurchase } from "@snackpass/snackpass-types";
import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { StyledWrapper } from "#css";
import { PurchaseItemGrouping } from "src/redux/Types";

import ItemGrouping from "./item_grouping";

const ItemsStyle = css`
    display: flex;
    flex-direction: column;

    .title {
        font-size: 16px;
        font-weight: 700;
    }
`;

type Props = {
    purchase: IPurchase | null;
};

export const PurchaseItems = ({ purchase }: Props) => {
    if (!purchase) return null;

    const items = groupedPurchaseItems(purchase.items);

    return (
        <GridWrap css={ItemsStyle}>
            <div className="title">{purchase.items.length} Items</div>
            {items.map((itemGrouping: PurchaseItemGrouping, index: number) => (
                <ItemGrouping itemGrouping={itemGrouping} key={index} />
            ))}
        </GridWrap>
    );
};

const GridWrap = styled(StyledWrapper)`
    grid-area: Items;
`;

export const groupedPurchaseItems = _.memoize((items: IOrderItem[]) => {
    const groupedCartItems = _.groupBy(items, (item) =>
        _.get(item, "groupUuid", uuid())
    ) as { [groupUuid: string]: IOrderItem[] };

    return Object.entries(groupedCartItems).map(
        ([groupUuid, cartItems]) =>
            ({
                groupUuid,
                items: cartItems,
                quantity: cartItems.length,
                points: _.sum(cartItems.map((item) => item.points || 0)),
                totalPrice: _.sum(cartItems.map((item) => item.totalPrice)),
                totalPriceAfterDiscount: _.sum(
                    cartItems.map((item) => item.totalPriceAfterDiscount)
                ),
                basePrice: _.sum(cartItems.map((item) => item.basePrice)),
                basePriceAfterDiscount: _.sum(
                    cartItems.map(
                        (item) => item.basePriceAfterDiscount || item.basePrice
                    )
                )
            }) as PurchaseItemGrouping
    );
});
