import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import { EditorState } from "draft-js";

import {
    DiscountData,
    ProductData,
    TemplateVariableType
} from "#guestbook/redux/message-input";

type ConversationVariableData = DiscountData | ProductData;

export type ConversationVariable = {
    data: ConversationVariableData | null;
    type: TemplateVariableType;
    placeholder: string;
    label: string;
    markup: string;
    id: string;
    // if the message variable is invisible / does not show up
    // in the template (ex. a gift added at the end)
    isInvisible: boolean;
};

type ConversationInputState = {
    message: EditorState | null;
    variables: ConversationVariable[];
};

const initialState: ConversationInputState = {
    message: null,
    variables: []
};

export const setConversationInput = createAction<
    Pick<ConversationInputState, "message" | "variables">
>("SET_CONVERSATION_INPUT");

export const setConversationInputMessage = createAction<EditorState>(
    "SET_CONVERSATION_INPUT_MESSAGE"
);

export const addConversationInputVariable = createAction<ConversationVariable>(
    "ADD_CONVERSATION_INPUT_VARIABLE"
);

export const updateConversationInputVariableData = createAction<{
    id: string;
    data: ConversationVariableData | null;
}>("UPDATE_CONVERSATION_INPUT_VARIABLE");

export const removeConversationInputVariable = createAction<{
    id: string;
}>("REMOVE_CONVERSATION_INPUT_VARIABLE");

export const clearConversationInput = createAction("CLEAR_CONVERSATION_INPUT");

export const conversationInputReducer = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase(setConversationInput, (state, action) => {
                state.message = action.payload.message;
                state.variables = action.payload.variables;
            })
            .addCase(setConversationInputMessage, (state, action) => {
                state.message = action.payload;
            })
            .addCase(updateConversationInputVariableData, (state, action) => {
                state.variables = state.variables.map((v) => {
                    // update the variable data
                    if (v.id === action.payload.id) {
                        return {
                            ...v,
                            data: action.payload.data
                        };
                    }

                    return v;
                });
            })
            .addCase(addConversationInputVariable, (state, action) => {
                state.variables = [...state.variables, action.payload];
            })
            .addCase(removeConversationInputVariable, (state, action) => {
                state.variables = state.variables.filter(
                    (v) => v.id !== action.payload.id
                );
            })
            .addCase(clearConversationInput, (state) => {
                state.message = null;
                state.variables = [];
            });
    }
);

export const getFullConversationInput = (state: any): ConversationInputState =>
    state.conversationInput;

export const getConversationInput = createSelector(
    getFullConversationInput,
    (m) => m.message
);

export const getConversationInputVariables = createSelector(
    getFullConversationInput,
    (m) => m.variables
);
