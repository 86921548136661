import {
    DeliveryFee3PStrategy,
    SnackpassConvenienceFeeStrategy
} from "@snackpass/snackpass-types";
import { z } from "zod";

export const CommissionFormSchema = z.object({
    defaultPercent: z.number().min(0),
    defaultFixed: z.number().min(0),
    appPickupOverride: z.boolean().default(false),
    appPickupFixed: z.number().min(0).optional(),
    appPickupPercent: z.number().min(0).optional(),
    appDeliveryOverride: z.boolean().default(false),
    appDeliveryFixed: z.number().min(0).optional(),
    appDeliveryPercent: z.number().min(0).optional(),
    appDineInOverride: z.boolean().default(false),
    appDineInFixed: z.number().min(0).optional(),
    appDineInPercent: z.number().min(0).optional(),
    kioskPickupOverride: z.boolean().default(false),
    kioskPickupFixed: z.number().min(0).optional(),
    kioskPickupPercent: z.number().min(0).optional(),
    kioskDineInOverride: z.boolean().default(false),
    kioskDineInFixed: z.number().min(0).optional(),
    kioskDineInPercent: z.number().min(0).optional(),
    onlinePickupOverride: z.boolean().default(false),
    onlinePickupFixed: z.number().min(0).optional(),
    onlinePickupPercent: z.number().min(0).optional(),
    onlineDeliveryOverride: z.boolean().default(false),
    onlineDeliveryFixed: z.number().min(0).optional(),
    onlineDeliveryPercent: z.number().min(0).optional(),
    onlineDineInOverride: z.boolean().default(false),
    onlineDineInFixed: z.number().min(0).optional(),
    onlineDineInPercent: z.number().min(0).optional()
});

export const ChargeFormSchema = z.object({
    defaultPercent: z.number().min(0),
    defaultFixed: z.number().min(0),
    defaultAllowRefunds: z.boolean().default(false),
    appPickupOverride: z.boolean().default(false),
    appPickupFixed: z.number().min(0).optional(),
    appPickupPercent: z.number().min(0).optional(),
    appDeliveryOverride: z.boolean().default(false),
    appDeliveryFixed: z.number().min(0).optional(),
    appDeliveryPercent: z.number().min(0).optional(),
    appDineInOverride: z.boolean().default(false),
    appDineInFixed: z.number().min(0).optional(),
    appDineInPercent: z.number().min(0).optional(),
    kioskPickupOverride: z.boolean().default(false),
    kioskPickupFixed: z.number().min(0).optional(),
    kioskPickupPercent: z.number().min(0).optional(),
    kioskDineInOverride: z.boolean().default(false),
    kioskDineInFixed: z.number().min(0).optional(),
    kioskDineInPercent: z.number().min(0).optional(),
    onlinePickupOverride: z.boolean().default(false),
    onlinePickupFixed: z.number().min(0).optional(),
    onlinePickupPercent: z.number().min(0).optional(),
    onlineDeliveryOverride: z.boolean().default(false),
    onlineDeliveryFixed: z.number().min(0).optional(),
    onlineDeliveryPercent: z.number().min(0).optional(),
    onlineDineInOverride: z.boolean().default(false),
    onlineDineInFixed: z.number().min(0).optional(),
    onlineDineInPercent: z.number().min(0).optional()
});

export const ConvenienceFormSchema = z.object({
    app: z.number().min(0).max(1).default(0),
    kiosk: z.number().min(0).max(1).default(0),
    online: z.number().min(0).max(1).default(0)
});

export const MinimumFormSchema = z.object({
    minimumChargeAmountCents: z.number().int().min(1)
});

export const MiscellaneousFormSchema = z.object({
    flat: z.number().min(0).max(100).default(0),
    isTaxable: z.boolean().default(false)
});

const FlatSchema = z.number().min(0);
const PercentSchema = z.number().min(0);
const StrategySchema = z.union([
    z.literal(SnackpassConvenienceFeeStrategy.LesserOfFlatAndPercent),
    z.literal(SnackpassConvenienceFeeStrategy.GreaterOfFlatAndPercent),
    z.literal(SnackpassConvenienceFeeStrategy.FlatAndPercent),

    // Note: Unused in options, but required for type alignment
    //       Related: settings-fees > components > strategy-options.tsx
    z.literal(SnackpassConvenienceFeeStrategy.Flat),
    z.literal(SnackpassConvenienceFeeStrategy.Percent),
    z.literal(DeliveryFee3PStrategy.LesserOfFlatAndPercent),
    z.literal(DeliveryFee3PStrategy.GreaterOfFlatAndPercent),
    z.literal(DeliveryFee3PStrategy.FlatAndPercent),
    z.literal(DeliveryFee3PStrategy.Flat),
    z.literal(DeliveryFee3PStrategy.Percent)
]);

export const OperatingFormSchema = z.object({
    appFlat: FlatSchema,
    appPercent: PercentSchema,
    appStrategy: StrategySchema,
    kioskFlat: FlatSchema,
    kioskPercent: PercentSchema,
    kioskStrategy: StrategySchema,
    onlineFlat: FlatSchema,
    onlinePercent: PercentSchema,
    onlineStrategy: StrategySchema
});

export const CustomFeeLabelFormSchema = z.object({
    customFeeLabels: z.object({
        snackpassConvenienceLabel: z.string().optional(),
        snackpassConvenienceDescription: z.string().optional(),
        convenienceStoreLabel: z.string().optional(),
        convenienceStoreDescription: z.string().optional(),
        tipLabelPrefix: z.string().optional()
    })
});
