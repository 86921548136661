import React, { CSSProperties } from "react";
import { PurchaseStatus } from "@snackpass/snackpass-types/src/v1";
import { SystemColors } from "@snackpass/design-system";

import { colors } from "#utils/colors";

type Props = {
    status?: PurchaseStatus;
    text: string | number | any;
    style?: CSSProperties;
};

const statusToColor: { [type in PurchaseStatus]: string } = {
    RECEIVED: SystemColors.v1.candy50,
    COMPLETED: SystemColors.v1.parsley50,
    CANCELED: SystemColors.v1.melon50,
    STARTED: SystemColors.v1.macaroni50
};

export default function StatusTag({ style, text, status }: Props) {
    if (!text) {
        return null;
    }
    return (
        <>
            <span
                style={{
                    fontWeight: 900,
                    padding: "5px 10px",
                    color: status ? statusToColor[status] : colors.gray,
                    ...style
                }}
            >
                {text === "CANCELED" ? "REFUNDED" : text}
            </span>
        </>
    );
}
