import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IPurchase } from "@snackpass/snackpass-types";

type ActivePurchase = IPurchase | null;

const activePurchaseInitialState: ActivePurchase = null;

// TODO: Redux is likely not needed for this state. Consider moving.
export const activePurchaseSlice = createSlice({
    name: "activePurchase",
    initialState: activePurchaseInitialState as ActivePurchase,
    reducers: {
        setActivePurchase: (_, action: PayloadAction<IPurchase | null>) =>
            action.payload
    }
});

export const { setActivePurchase } = activePurchaseSlice.actions;
