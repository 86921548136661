import React from "react";

import { TooltipProvider } from "src/@/components/ui/tooltip";
import { cn } from "src/@/lib/utils";
import {
    HybridTooltip,
    HybridTooltipContent,
    HybridTooltipTrigger
} from "src/@/components/ui/HybridTooltip";

type Props = {
    children: React.ReactNode;
    tooltip?: string | null;
    className?: string;
    disabled?: boolean;
};

function OnboardingTooltip({ children, tooltip, className, disabled }: Props) {
    if (!disabled || tooltip == null) {
        return children;
    }
    return (
        <TooltipProvider delayDuration={100}>
            <HybridTooltip>
                <HybridTooltipTrigger asChild>{children}</HybridTooltipTrigger>

                <HybridTooltipContent
                    className={cn(
                        "z-50 w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-900 px-3 py-2 text-center font-normal text-neutral-50",
                        className
                    )}
                    sideOffset={5}
                >
                    <p className="text-small">{tooltip}</p>
                </HybridTooltipContent>
            </HybridTooltip>
        </TooltipProvider>
    );
}

export default OnboardingTooltip;
