import { IHoursSchema, ITimeRangeSchema } from "@snackpass/snackpass-types";
import _ from "lodash";

import {
    SpecialHoursItemType,
    formatDayOfWeek,
    formatTime,
    hoursHasCrossedDayRanges,
    isEverydaySpecialHoursIsSame,
    parseCrossDayRanges
} from "#reusable/special-hours/helper";

export const handleSetStoreHours = (hours?: IHoursSchema) => {
    if (hours) {
        if (!_.isEmpty(hours?.local) && isEverydaySpecialHoursIsSame(hours)) {
            const specialHours: SpecialHoursItemType[] = [
                {
                    id: 0,
                    dayOfWeek: 7,
                    time: {
                        start: formatTime(hours.local[0].start),
                        end: formatTime(hours.local[0].end)
                    }
                }
            ];

            return specialHours;
        } else if (!_.isEmpty(hours.local)) {
            // check if each time range is within the same day
            // if not separate it to different days
            // this is necessary since RDB time range within a day, Snackface can cross days
            const newHours: ITimeRangeSchema[] = [];
            hours.local.forEach((item) => {
                if (hoursHasCrossedDayRanges(item)) {
                    newHours.push(...parseCrossDayRanges(item));
                } else {
                    newHours.push(item);
                }
            });

            const specialHours: SpecialHoursItemType[] = newHours.map(
                (item, i) => ({
                    id: i,
                    dayOfWeek: formatDayOfWeek(item.start),
                    time: {
                        start: formatTime(item.start),
                        end: formatTime(item.end)
                    }
                })
            );
            return specialHours;
        }
    }
    return [] as SpecialHoursItemType[];
};
