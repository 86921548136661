import { LifebuoyIcon } from "@heroicons/react/24/outline";

import { Button } from "src/@/components/ui/button";

interface GetHelpButtonParams {
    buttonText?: string;
}

export const IntercomGetHelpButton = ({
    buttonText = "Get Help"
}: GetHelpButtonParams) => (
    <Button
        onClick={() => {
            window.Intercom("show");
        }}
        variant={"outline"}
    >
        <LifebuoyIcon className="mr-2 h-4 w-4" />
        {buttonText}
    </Button>
);
