"use client";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import { z } from "zod";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";
import { NumberInput } from "@tremor/react";

import { Routes } from "#navigation/routes";
import { useUnsavedChangesPrompt } from "#settings/hooks/useUnsavedChangesPrompt";
import { UnsavedChangesModal } from "#settings/components/unsaved-changes";
import { Button } from "src/@/components/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage
} from "src/@/components/ui/form";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle
} from "src/@/components/ui/card";
import { Switch } from "src/@/components/ui/switch";
import { getActiveStore } from "src/redux/selectors";
import {
    buildOrderStatusConfiguration,
    isAutoAccept,
    isAutoComplete
} from "#settings/settings-orders/helper";
import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";
import api from "src/api/rest";
import { setActiveStore } from "src/redux/slices";
import { sendError } from "src/utils/errors";
import { Input } from "src/@/components/ui/input";
import ForceTermsConfirmToToggleOn from "#shared-components/ForceTermsConfirmToToggleOn";
import OfflinePaymentTerms from "#settings/settings-back-of-house/OfflinePaymentTerms";
import { useIsOfflineModeEligiblityEnabled } from "#navigation/utils";

const formSchema = z.object({
    hasEmployeeAuditTracking: z.boolean(),
    initialReceiptNumber: z.coerce.number().optional(),
    autoAccept: z.boolean(),
    autoComplete: z.boolean(),
    skipOtherPaymentConfirmation: z.boolean(),
    allowViewEarningsOnTablet: z.boolean(),
    defaultPin: z
        .string()
        .regex(/^(\d{4})?$/, { message: "Pin must be 4 numbers" })
        .optional(),
    isOfflineModeEligible: z.boolean().optional()
});

export function SettingsBackOfHouse() {
    const [isLoading, setIsLoading] = useState(false);
    const canEdit = useHasEditSettingsForActiveStore();
    const activeStore = useSelector(getActiveStore);
    const dispatch = useDispatch();

    const isOfflineModeEnabled = useIsOfflineModeEligiblityEnabled();

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            hasEmployeeAuditTracking: false,
            initialReceiptNumber: 100,
            autoAccept: true,
            skipOtherPaymentConfirmation: false,
            autoComplete: false,
            allowViewEarningsOnTablet: true,
            defaultPin: undefined,
            isOfflineModeEligible: undefined
        }
    });
    const defaultValues = useMemo(
        () => ({
            hasEmployeeAuditTracking:
                activeStore?.hasEmployeeAuditTracking ?? false,
            initialReceiptNumber: activeStore?.initialReceiptNumber ?? 100,
            autoAccept:
                isAutoAccept(activeStore?.orderStatusConfiguration) ?? true,
            autoComplete:
                isAutoComplete(activeStore?.orderStatusConfiguration) ?? false,
            skipOtherPaymentConfirmation:
                !!activeStore?.skipOtherPaymentConfirmation,
            allowViewEarningsOnTablet: !!activeStore?.allowViewEarningsOnTablet,
            defaultPin: activeStore?.defaultPin,
            isOfflineModeEligible: activeStore?.isOfflineModeEligible
        }),
        [activeStore]
    );

    const resetForm = useCallback(() => {
        form.reset(defaultValues);
    }, [defaultValues, form]);

    useEffect(() => {
        if (activeStore) {
            resetForm();
        }
    }, [activeStore, defaultValues, resetForm]);

    const onSubmit = useCallback(
        async (values: z.infer<typeof formSchema>) => {
            if (!activeStore) {
                return;
            }

            if (!canEdit) {
                toast.error("You do not have permission to edit this store");
                return;
            }

            try {
                setIsLoading(true);
                const { data } = await api.stores.update(activeStore._id, {
                    hasEmployeeAuditTracking: values.hasEmployeeAuditTracking,
                    initialReceiptNumber: values.initialReceiptNumber,
                    // unfortunate backwards compatibility
                    // auto complete is now deprecated
                    orderStatusConfiguration: buildOrderStatusConfiguration(
                        values.autoAccept,
                        values.autoComplete
                    ),
                    skipOtherPaymentConfirmation:
                        values.skipOtherPaymentConfirmation,
                    allowViewEarningsOnTablet: values.allowViewEarningsOnTablet,
                    defaultPin: values.defaultPin,
                    isOfflineModeEligible: values.isOfflineModeEligible
                });

                dispatch(setActiveStore(data.store));
                toast.success("Store updated successfully");
            } catch (e) {
                sendError(e);
                toast.error("Can not update the store, please try again later");
            } finally {
                setIsLoading(false);
            }
        },
        [activeStore, canEdit, setIsLoading, dispatch]
    );

    const employeeAuditTrackingEnabled = useWatch({
        control: form.control,
        name: "hasEmployeeAuditTracking",
        defaultValue: activeStore?.hasEmployeeAuditTracking ?? false
    });

    const editDefaultPin = (
        e: React.ChangeEvent<HTMLInputElement>,
        onChange: (value: string) => void
    ) => {
        if (e.target.value.length > 0 && isNaN(parseFloat(e.target.value)))
            return;
        const digitsOnlyValue = e.target.value.replace(/\D/g, "").slice(0, 4);
        onChange(digitsOnlyValue);
    };

    const resetPin = useCallback(
        (checked: boolean, onChange: (value: boolean) => void) => {
            // if we are turning it back on make sure we reset the value in default pin
            // so we don't leave them in a scenario where its got invalid data but they can't
            // edit it
            if (!employeeAuditTrackingEnabled) {
                form.setValue("defaultPin", activeStore?.defaultPin);
            }
            onChange(checked);
        },
        [activeStore?.defaultPin, employeeAuditTrackingEnabled, form]
    );

    const {
        showModal,
        handleConfirmNavigationClick,
        handleCancelNavigationClick
    } = useUnsavedChangesPrompt(form.formState.isDirty);

    return (
        <div className="h-max overflow-auto bg-gray-50">
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="mb-24 space-y-8 p-8 sm:p-16"
                >
                    <UnsavedChangesModal
                        show={showModal}
                        onConfirm={handleConfirmNavigationClick}
                        onCancel={handleCancelNavigationClick}
                    />
                    <div>
                        <CardTitle className="text-2xl font-bold">
                            Back of House
                        </CardTitle>
                        <div className="text-gray-600">
                            Configurations for staff and back of house.
                        </div>
                        <hr className="border-gray-300" />
                    </div>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Employee Pin
                            </CardTitle>
                            <CardDescription>
                                Decide which type of secure pin you want to use.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="hasEmployeeAuditTracking"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Require Employee Pin Entry
                                                </CardTitle>
                                                <CardDescription className="pr-6 pt-0.5">
                                                    Requires employees to enter
                                                    a unique pin each time they
                                                    execute auditable tasks,
                                                    such as issuing refunds.
                                                    Activation of this setting
                                                    is a prerequisite for
                                                    enabling custom PINs.
                                                    Employee pins are set{" "}
                                                    <Link
                                                        to={
                                                            Routes.SettingsPermissions
                                                        }
                                                        className="no-underline"
                                                    >
                                                        here.
                                                    </Link>{" "}
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={(
                                                            e
                                                        ) => {
                                                            resetPin(
                                                                e,
                                                                field.onChange
                                                            );
                                                        }}
                                                        disabled={
                                                            !canEdit ||
                                                            field.value // can only turn on, not off, so we can deprecate default pin.
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="defaultPin"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Use Default Pin (Deprecated)
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                When 'Require Employee Pin
                                                Entry' is off there will still
                                                be a single pin to protect
                                                employee only screens from being
                                                seen by customers.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <Input
                                                        className="max-w-60"
                                                        placeholder={
                                                            employeeAuditTrackingEnabled
                                                                ? "Default Pin"
                                                                : "1234"
                                                        }
                                                        value={
                                                            employeeAuditTrackingEnabled
                                                                ? ""
                                                                : field.value
                                                        }
                                                        onChange={(e) => {
                                                            editDefaultPin(
                                                                e,
                                                                field.onChange
                                                            );
                                                        }}
                                                        disabled={
                                                            !canEdit ||
                                                            employeeAuditTrackingEnabled
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">Orders</CardTitle>
                            <CardDescription>
                                Manage logistics for new orders.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="allowViewEarningsOnTablet"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    View Total Tips on Register
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Daily tip totals will be
                                                    made visible in register
                                                    settings.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="initialReceiptNumber"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Receipt Start Number
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Set the starting number for
                                                receipts. By default, receipts
                                                start at 100. Changes take
                                                affect the following day.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        className="max-w-60"
                                                        placeholder="100"
                                                        min={1}
                                                        {...field}
                                                        disabled={!canEdit}
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="autoAccept"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Auto Accept Orders
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Order Hub tablet will
                                                    automatically start and
                                                    print new orders as they are
                                                    received.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="skipOtherPaymentConfirmation"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Skip Confirmation for
                                                    Payment Type: Other
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Purchases paid with payment
                                                    type: other will be
                                                    automatically confirmed.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="autoComplete"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Use ETA To Mark Orders Ready
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    This setting is deprecated
                                                    and can only be turned off.
                                                    This setting will mark
                                                    orders as ready for pickup
                                                    based on the estimated
                                                    pickup time.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        disabled={
                                                            !canEdit ||
                                                            !field.value
                                                        } /* can only turn off, never turn on */
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            {isOfflineModeEnabled && (
                                <>
                                    <hr />
                                    <FormField
                                        control={form.control}
                                        name="isOfflineModeEligible"
                                        render={({ field }) => (
                                            <FormItem className="flex justify-between">
                                                <div>
                                                    <CardTitle className="text-base font-medium">
                                                        Offline Mode Eligibility
                                                    </CardTitle>
                                                    <CardDescription className="pt-0.5">
                                                        A new setting will
                                                        appear on your devices
                                                        allowing you to reboot a
                                                        recently online device
                                                        into offline mode. In
                                                        offline mode, you can
                                                        take orders on the
                                                        register and process
                                                        credit card payments.
                                                        Please note that you
                                                        assume all risks
                                                        associated with any
                                                        offline payments that
                                                        fail.
                                                    </CardDescription>
                                                </div>
                                                <div className="my-auto">
                                                    <FormControl>
                                                        <ForceTermsConfirmToToggleOn
                                                            checked={
                                                                field.value
                                                            }
                                                            onCheckedChange={
                                                                field.onChange
                                                            }
                                                            title="Snackpass Offline Payment Terms"
                                                            body={
                                                                <OfflinePaymentTerms />
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </div>
                                            </FormItem>
                                        )}
                                    />
                                </>
                            )}
                        </CardContent>
                    </Card>

                    {form.formState.isDirty && (
                        <div className="fixed bottom-0 left-0 flex w-full flex-row items-center justify-end gap-4 border-t bg-white p-2">
                            <Button
                                type="button"
                                variant={"outline"}
                                onClick={resetForm}
                            >
                                Cancel
                            </Button>
                            <Button disabled={isLoading} type="submit">
                                {isLoading && (
                                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                Submit
                            </Button>
                        </div>
                    )}
                </form>
            </Form>
        </div>
    );
}
