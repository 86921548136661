/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import styled from "@emotion/styled";
import { IPurchase } from "@snackpass/snackpass-types";

import "tippy.js/dist/tippy.css";
import { toDollar } from "@snackpass/accounting";

import HeaderInfo from "#table/HeaderInfo";
import { NoticeText } from "#refund-modal/components/notice-text";
import { AdjustmentAmount, Subtitle } from "#refund-modal/lib";

const _parseAdjustmentAmount = (adjustment: string) => {
    const adjustmentVal = parseFloat(adjustment);
    const adjustedTotal =
        isNaN(adjustmentVal) || adjustmentVal <= 0 ? 0 : adjustmentVal;
    return adjustedTotal.toFixed(2);
};

const _calcAdjustedTotal = (
    total: number,
    adjustment: string,
    taxRate: number
) => {
    const adjustmentVal = parseFloat(adjustment);
    const adjustedTotal =
        isNaN(adjustmentVal) || adjustmentVal <= 0
            ? total
            : (total + adjustmentVal) * (taxRate / 100 + 1);
    return adjustedTotal.toFixed(2);
};

type Props = {
    purchase: IPurchase;
    isKioskOrder: boolean;
    upchargeBuffer: string;
    setUpchargeBuffer: React.Dispatch<React.SetStateAction<string>>;
};

export const Upcharge = ({
    purchase,
    isKioskOrder,
    setUpchargeBuffer
}: Props) => {
    const [adjustment, setAdjustment] = useState("0");
    const [adjustedTotal, setAdjustedTotal] = useState("0");

    const _updateValues = (evt: any) => {
        const adjustmentAmount = evt.target.value;
        setUpchargeBuffer(_parseAdjustmentAmount(adjustmentAmount));
        setAdjustment(adjustmentAmount);
        setAdjustedTotal(
            _calcAdjustedTotal(
                purchase?.amountPaidByCustomer || 0,
                adjustmentAmount,
                purchase?.salesTaxRate || 0
            )
        );
    };

    if (isKioskOrder) {
        return (
            <NoticeText
                text={"Upcharge cannot be performed on kiosk orders."}
            />
        );
    }
    const total = toDollar(purchase?.amountPaidByCustomer || 0);

    return (
        <UpchargeWrapper>
            <Subtitle> Upcharge</Subtitle>
            <UpchargeRow>
                <UpchargeRowTitle>
                    Current Total
                    <HeaderInfo
                        message={"Total after subtracting store credit spent"}
                    />
                </UpchargeRowTitle>
                <UpchargeRowAmount>{total}</UpchargeRowAmount>
            </UpchargeRow>
            <UpchargeRow>
                <UpchargeRowTitle>Adjustment Amount</UpchargeRowTitle>
                <AdjustmentAmount width="100px">
                    <input
                        type="text"
                        name="currency"
                        value={adjustment}
                        onChange={_updateValues}
                    />
                </AdjustmentAmount>
            </UpchargeRow>
            <UpchargeRow>
                <UpchargeRowTitle>Tax Rate</UpchargeRowTitle>
                <UpchargeRowAmount>
                    {" "}
                    {purchase?.salesTaxRate}%{" "}
                </UpchargeRowAmount>
            </UpchargeRow>
            <UpchargeRow>
                <UpchargeRowTitle>Adjusted Total</UpchargeRowTitle>
                <UpchargeRowAmount>
                    {toDollar(parseFloat(adjustedTotal))}
                </UpchargeRowAmount>
            </UpchargeRow>
        </UpchargeWrapper>
    );
};

const UpchargeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    place-content: space-between;
`;

const UpchargeRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 36px;
`;

const UpchargeRowTitle = styled.div``;

const UpchargeRowAmount = styled.div``;
