import { z } from "zod";

import { StoreVerificationAddress } from "#payouts/domain/types";
import {
    AddressSchema,
    EmailSchema,
    PhoneSchema,
    TitleSchema,
    ValidNameRegex,
    CompanyTaxIdSchema,
    Line1Schema,
    Line2Schema,
    CitySchema,
    StateSchema,
    CountrySchema,
    PostalCodeSchema,
    CompanyDbaSchema,
    CompanyNameSchema,
    RoutingNumberSchema,
    AccountNumberSchema,
    BirthYearSchema,
    BirthDaySchema,
    BirthMonthSchema,
    DateOfBirthSchema,
    FrontSchema,
    BackSchema,
    IdNumberSchema,
    ConfirmStoreSchema,
    AdminNoteSchema,
    ConfirmSchema,
    PersonMongoIdSchema,
    CompanyUrlSchema
} from "#payouts/utils/validation/schema";

/**
 * Admin
 */
export const adminNoteValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = AdminNoteSchema.safeParse(value);
    return success ? [] : ["Please provide a valid note to partners"];
};

export const confirmStoreValidation = (value?: boolean, disabled = false) => {
    if (disabled) return [];
    const { success } = ConfirmStoreSchema.safeParse(value);
    return success ? [] : ["Please confirm store"];
};

/**
 * Shared
 */
export const phoneValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = PhoneSchema.safeParse(value);
    return success ? [] : ["Please provide a valid phone number"];
};

export const frontDocumentValidation = (value?: string) => {
    const { success } = FrontSchema.safeParse(value);
    return success ? [] : ["Please provide a valid front image"];
};

export const backDocumentValidation = (value?: string) => {
    const { success } = BackSchema.safeParse(value);
    return success ? [] : ["Please provide a valid back image"];
};

export const addressValidation = (
    value?: Partial<StoreVerificationAddress>,
    disabled = false
) => {
    if (disabled) return [];
    const { success } = AddressSchema.safeParse(value);
    return success ? [] : ["Invalid address"];
};

export const line1Validation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = Line1Schema.safeParse(value);
    return success ? [] : ["Please provide a valid street address"];
};

export const line2Validation = (value?: string | null, disabled = false) => {
    if (disabled) return [];
    const { success } = Line2Schema.safeParse(value);
    return success ? [] : ["Please provide a valid street address"];
};

export const cityValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = CitySchema.safeParse(value);
    return success ? [] : ["Please provide a valid city"];
};

export const stateValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = StateSchema.safeParse(value);
    return success ? [] : ["Required"];
};

export const postalCodeValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = PostalCodeSchema.safeParse(value);
    return success ? [] : ["Invalid code"];
};

export const countryValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = CountrySchema.safeParse(value);
    return success ? [] : ["Invalid country"];
};

/**
 * Company Validation
 */
export const companyNameValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = CompanyNameSchema.safeParse(value);
    return success ? [] : ["Please provide a valid company name"];
};

export const companyDbaValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = CompanyDbaSchema.safeParse(value);
    return success ? [] : ["Please provide a valid DBA"];
};

export const companyTaxIdValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = CompanyTaxIdSchema.safeParse(value);
    return success ? [] : ["Please provide a valid tax ID"];
};

export const urlValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = CompanyUrlSchema.safeParse(value);
    return success ? [] : ["Please provide a valid website"];
};

/**
 * Account Validation
 */
export const routingNumberValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = RoutingNumberSchema.safeParse(value);
    return success ? [] : ["Please provide a valid, 9-digit number"];
};

export const accountNumberValidation = (value?: string, disabled = false) => {
    if (disabled) return [];
    const { success } = AccountNumberSchema.safeParse(value);
    return success ? [] : ["Please provide a valid account number"];
};

export const confirmAccountNumberValidation = (
    {
        account,
        confirm
    }: {
        account?: string;
        confirm?: string;
    },
    disabled = false
) => {
    if (disabled) return [];
    const { success } = z.string().min(1).safeParse(confirm);
    if (!success) {
        return ["Please confirm account number"];
    }
    return account === confirm ? [] : ["Must match account number"];
};

/**
 * Representative
 */
export const firstNameValidation = (value?: string) => {
    const length = z.string().min(1).safeParse(value);
    if (!length.success) {
        return ["Please provide a valid first name"];
    }
    const characters = z.string().regex(ValidNameRegex).safeParse(value);
    if (!characters.success) {
        return ["Must contain valid characters"];
    }
    return [];
};

export const lastNameValidation = (value?: string) => {
    const length = z.string().min(1).safeParse(value);
    if (!length.success) {
        return ["Please provide a valid last name"];
    }
    const characters = z.string().regex(ValidNameRegex).safeParse(value);
    if (!characters.success) {
        return ["Must contain valid characters"];
    }
    return [];
};

export const emailValidation = (value?: string) => {
    const { success } = EmailSchema.safeParse(value);
    return success ? [] : ["Please provide a valid email"];
};

export const titleValidation = (value?: string) => {
    const { success } = TitleSchema.safeParse(value);
    return success ? [] : ["Please provide a valid title"];
};

export const ownershipValidation = (ownership?: number) => {
    if (!ownership) {
        return ["If checked, specify a percent"];
    } else if (ownership < 25) {
        return ["Must be no less than 25%"];
    } else if (ownership > 100) {
        return ["Cannot own more than 100%"];
    }
    return [];
};

export const birthYearValidation = (value?: number) => {
    const { success } = BirthYearSchema.safeParse(value);
    return success ? [] : ["Required"];
};
export const birthMonthValidation = (value?: number) => {
    const { success } = BirthMonthSchema.safeParse(value);
    return success ? [] : ["Required"];
};
export const birthDayValidation = (value?: number) => {
    const { success } = BirthDaySchema.safeParse(value);
    return success ? [] : ["Required"];
};

export const dateOfBirthValidation = (value?: {
    year?: number;
    month?: number;
    day?: number;
}): string[] => {
    const { success } = DateOfBirthSchema.safeParse(value);
    return success ? [] : ["Please provide a valid birthday"];
};

export const idNumberValidation = (value?: string) => {
    const { success } = IdNumberSchema.safeParse(value);
    return success ? [] : ["Social security numbers must be 9 digits"];
};

export const removePersonIdValidation = (value?: string) => {
    const { success } = PersonMongoIdSchema.safeParse(value);
    return success ? [] : ["Must provide a user to remove"];
};

export const confirmRemoveValidation = (value?: boolean) => {
    const { success } = ConfirmSchema.safeParse(value);
    return success ? [] : ["Must confirm removal"];
};

export const confirmSetPrimaryValidation = (value?: boolean) => {
    const { success } = ConfirmSchema.safeParse(value);
    return success ? [] : ["Must confirm new primary"];
};
