import { zodResolver } from "@hookform/resolvers/zod";
import { PayoutPolicy } from "@snackpass/snackpass-types";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { z } from "zod";

import { Button } from "#payouts/components/shared/Button";
import { Page } from "#payouts/components/shared/Page";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import {
    Dialog,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
    DialogContent
} from "src/@/components/ui/dialog";
import { buttonVariants } from "src/@/components/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel
} from "src/@/components/ui/form";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "src/@/components/ui/select";
import Api from "src/api/rest";
import { ReactComponent as Pencil } from "src/assets/icons/pencil-over-paper.svg";
import { setActiveStore } from "src/redux/slices";
import { RootState } from "src/redux/store";

function readablePayoutPolicyType(type: PayoutPolicyType | undefined): string {
    switch (type) {
        case "WeeklyPayoutPolicy":
            return "Weekly";
        case "DailyPayoutPolicy":
            return "Daily";
        case "ExpeditedDailyPayoutPolicy":
            return "Expedited";
        default:
            return "None";
    }
}

type PayoutPolicyType = PayoutPolicy["__t"];
const PAYOUT_POLICY_TYPES_ENUM = [
    "WeeklyPayoutPolicy",
    "DailyPayoutPolicy",
    "ExpeditedDailyPayoutPolicy"
] as const;

const FormSchema = z.object({
    type: z.enum(PAYOUT_POLICY_TYPES_ENUM).nullable()
});

type FormValues = z.infer<typeof FormSchema>;

const INITIAL_FORM_VALUES: FormValues = {
    type: null
};

type ConfirmationDialogProps = {
    close: () => void;
    isOpen: boolean;
    payoutPolicy: PayoutPolicy | null;
    pendingPayoutPolicy: PayoutPolicy | null;
};

function ConfirmationDialog({
    close,
    isOpen,
    payoutPolicy,
    pendingPayoutPolicy
}: ConfirmationDialogProps) {
    const form = useForm<FormValues>({
        resolver: zodResolver(FormSchema),
        defaultValues: INITIAL_FORM_VALUES
    });
    const selectedType = form.watch("type");
    const storeId = useSelector((state: RootState) => state.activeStore?._id);
    const dispatch = useDispatch();

    useEffect(() => {
        if (pendingPayoutPolicy?.__t)
            form.reset({ type: pendingPayoutPolicy?.__t });
    }, [form, pendingPayoutPolicy?.__t]);

    const allowSubmit =
        form.formState.isValid &&
        form.formState.isDirty &&
        !form.formState.isSubmitting &&
        selectedType !== null &&
        selectedType !== (pendingPayoutPolicy?.__t || "") &&
        (selectedType !== (payoutPolicy?.__t || "") || !!pendingPayoutPolicy);

    async function handleSubmit() {
        if (!storeId || selectedType === undefined) return;

        const changeToType =
            selectedType === payoutPolicy?.__t ? null : selectedType;

        try {
            const response = await Api.stores.updatePendingPayoutPolicy(
                storeId,
                { type: changeToType }
            );
            dispatch(setActiveStore(response.data.store));
            toast.success("Payout schedule updated");
        } catch (e) {
            console.error(e);
            toast.error("Failed to update payout schedule");
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={close}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Update Payout Schedule?</DialogTitle>
                    <DialogDescription>
                        This change will not take place immediately. Please see
                        the{" "}
                        <a
                            href="https://support.snackpass.co/en/articles/9160716-payouts#h_9885b3ee9a"
                            target="_blank"
                            rel="noreferrer"
                        >
                            payout schedule support page
                        </a>{" "}
                        for more information on when payout schedule changes
                        take effect.
                        <Form {...form}>
                            <form onSubmit={() => {}}>
                                <FormItem>
                                    <FormField
                                        control={form.control}
                                        name="type"
                                        render={({ field }) => (
                                            <FormItem className="mt-3">
                                                <FormLabel>
                                                    Switch to Payout Schedule:
                                                </FormLabel>
                                                <Select
                                                    onValueChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={
                                                        field.value || undefined
                                                    }
                                                >
                                                    <FormControl>
                                                        <SelectTrigger>
                                                            <SelectValue />
                                                        </SelectTrigger>
                                                    </FormControl>
                                                    <SelectContent>
                                                        {PAYOUT_POLICY_TYPES_ENUM.map(
                                                            (type) => (
                                                                <SelectItem
                                                                    key={type}
                                                                    value={type}
                                                                >
                                                                    {readablePayoutPolicyType(
                                                                        type
                                                                    )}
                                                                </SelectItem>
                                                            )
                                                        )}
                                                    </SelectContent>
                                                </Select>
                                            </FormItem>
                                        )}
                                    />
                                </FormItem>
                                {form.formState.errors.type && (
                                    <div className="text-red-600">
                                        {JSON.stringify(
                                            form.formState.errors.type
                                        )}
                                    </div>
                                )}
                            </form>
                        </Form>
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <Button
                        size="small"
                        variant="secondary"
                        onClick={close}
                        className={buttonVariants({ variant: "outline" })}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        variant="tertiary"
                        onClick={() => {
                            handleSubmit();
                            close();
                        }}
                        disabled={!allowSubmit}
                        className={buttonVariants({ variant: "default" })}
                    >
                        Confirm
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

export function PayoutPolicyManagement() {
    const { payoutPolicy, pendingPayoutPolicy } = useSelector(
        (state: RootState) =>
            state.activeStore || {
                payoutPolicy: null,
                pendingPayoutPolicy: null
            }
    );
    const [dialogOpen, setDialogOpen] = useState(false);
    const { verification } = useContext(PayoutsSettingsContext);

    // Require users to start verification process before changing payout policy
    if (!verification) return null;

    return (
        <Page>
            <ConfirmationDialog
                close={() => setDialogOpen(false)}
                isOpen={dialogOpen}
                payoutPolicy={payoutPolicy}
                pendingPayoutPolicy={pendingPayoutPolicy || null}
            />
            <div className="mb-4">
                <div className="flex flex-row items-center justify-between">
                    <div className="text-[20px] font-semibold leading-7">
                        Payout Schedule
                    </div>
                    <Button
                        size="small"
                        smallRadius
                        variant="tertiary"
                        iconLeft={<Pencil />}
                        onClick={() => setDialogOpen(true)}
                    >
                        Edit
                    </Button>
                </div>
                <p className="mt-3">
                    A payout schedule defines how frequently a store is paid
                    out. Please read the{" "}
                    <a
                        href="https://support.snackpass.co/en/articles/9160716-payouts#h_9885b3ee9a"
                        target="_blank"
                        rel="noreferrer"
                    >
                        payout schedule support page
                    </a>{" "}
                    prior to making changes.
                </p>
                <p className="mt-3">
                    <b>Current Payout Schedule:</b>
                    {" " + readablePayoutPolicyType(payoutPolicy?.__t)}
                </p>
                <p className="mt-3">
                    <b>Pending Payout Schedule:</b>
                    {" " + readablePayoutPolicyType(pendingPayoutPolicy?.__t)}
                </p>
            </div>
        </Page>
    );
}
