import { useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "sonner";

import { useCloudinaryUploadWidget } from "#reusable/upload/useCloudinaryUploadWidget";
import { cn } from "src/@/lib/utils";
import { optimizedImageURL } from "src/utils/image";
import { getUser } from "src/redux/selectors";
import { Button } from "src/@/components/ui/button";
import { ReactComponent as UploadImg } from "src/assets/icons/upload.svg";

const MAX_FILE_SIZE = 10000 * 1024; // 10 MB

type ProductImageProps = {
    imageURL: string | null | undefined;
    setImage: React.Dispatch<React.SetStateAction<string | null | undefined>>;
    showImageUploadOnly?: boolean;
    updateProduct?: (imageURL: string) => void;
    disableEdit?: boolean;
};

const ProductImage: React.FC<ProductImageProps> = ({
    imageURL,
    setImage,
    showImageUploadOnly,
    updateProduct,
    disableEdit
}) => {
    const uploadPreset = "menu-image";
    const user = useSelector(getUser);
    const userIsTeam = user?.snackpassPermissions.isSnackpassEmployee || false;
    const _onUpload = (info: any) => {
        setImage(info.secure_url);
        updateProduct && updateProduct(info.secure_url);
    };
    const removeImage = () => setImage("");

    const { UploadButton, openWidget } = useCloudinaryUploadWidget({
        preset: uploadPreset,
        folder: "menu-image",
        clientAllowedFormats: ["png", "jpg"],
        maxImageFileSize: MAX_FILE_SIZE
    });

    if (showImageUploadOnly) {
        return imageURL ? (
            <Button
                onClick={() => openWidget(_onUpload, true)}
                className={cn(
                    "h-12 w-12 border flex items-center justify-center rounded-xl cursor-pointer bg-center bg-no-repeat bg-cover"
                )}
                style={{
                    backgroundImage: `url("${optimizedImageURL(imageURL, {
                        size: { h: 48 }
                    })}")`
                }}
                disabled={disableEdit}
            />
        ) : (
            <Button
                onClick={() => openWidget(_onUpload, true)}
                className={cn(
                    " h-12 w-12 border border-dotted flex items-center justify-center rounded-xl cursor-pointer bg-center bg-no-repeat bg-cover"
                )}
                size="icon"
                variant="outline"
                disabled={disableEdit}
            >
                <UploadImg className="p-1" />
            </Button>
        );
    }

    return (
        <div className="relative">
            <div
                className={cn(
                    "h-[100px] md:h-[200px] border flex items-center justify-center rounded-xl bg-center bg-no-repeat bg-cover"
                )}
                style={{
                    backgroundImage: `url("${optimizedImageURL(imageURL, {
                        size: { h: 200 }
                    })}")`
                }}
            >
                <div className={`flex items-center justify-center`}>
                    <UploadButton onUpload={_onUpload} disabled={disableEdit}>
                        <div className="px-2 py-1 text-primary">
                            {imageURL ? "Change Image" : "Add Image"}
                        </div>
                    </UploadButton>
                </div>
            </div>
            {userIsTeam && imageURL ? (
                <div
                    className="absolute right-0 top-[-32px] cursor-pointer rounded-2xl leading-6"
                    onClick={removeImage}
                >
                    <Button
                        id="copy-to-clipboard"
                        className="font-sm"
                        size="sm"
                        variant="outline"
                        onClick={(e) => {
                            //prevent modal to be closed
                            e.preventDefault();
                        }}
                    >
                        Remove Image
                    </Button>
                </div>
            ) : (
                <></>
            )}
            {userIsTeam && imageURL ? (
                <div className="absolute bottom-[-32px] right-0 cursor-pointer rounded-2xl leading-6">
                    <CopyToClipboard
                        text={imageURL}
                        onCopy={(e) => {
                            toast.success("Copied to clipboard");
                        }}
                    >
                        <Button
                            id="copy-to-clipboard"
                            className="font-sm"
                            size="sm"
                            variant="outline"
                            onClick={(e) => {
                                //prevent modal to be closed
                                e.preventDefault();
                            }}
                        >
                            copy image url
                        </Button>
                    </CopyToClipboard>
                </div>
            ) : null}
        </div>
    );
};
export default ProductImage;
