import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { IProduct } from "@snackpass/snackpass-types";

import { Categories, PopularItems, useProductRewards } from "../products";

import { DiscountTemplate } from "./constants";
import { Discounts } from "./discounts";

type OnSelect = (data: DiscountTemplate) => void;

type TemplatesComponentProps = {
    onSelect: OnSelect;
};

export const Incentives = ({ onSelect }: TemplatesComponentProps) => {
    const { categoriesWithProducts } = useProductRewards();

    const _onSelectProduct = useCallback((product: IProduct) => {
        onSelect(_getProductDiscount(product));
    }, []);

    return (
        <div>
            <GiftTitle>Send Gift</GiftTitle>

            <div style={{ paddingBottom: 100 }}>
                <Discounts onSelect={onSelect} />
                <PopularItems onSelect={_onSelectProduct} />
                <Categories
                    categories={categoriesWithProducts}
                    onSelect={_onSelectProduct}
                />
            </div>
        </div>
    );
};

const _getProductDiscount = (product: IProduct): DiscountTemplate => ({
    expiresInDays: 7,
    label: product.name,
    discount: {
        type: "percent",
        percentOff: 100,
        dollarsOff: null,
        newPrice: null
    },
    storewide: false,
    products: [
        {
            id: product._id,
            label: product.name,
            imageUrl: product.image || ""
        }
    ],
    subtext: product.description || ""
});

const GiftTitle = styled.h3`
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 30px;
    margin-top: 20px;
`;
