import { MenuChannelKind } from "#graph/types";
import { match } from "ts-pattern";

// XXX: https://developers.deliverect.com/reference/get-channel-links-for-location
export enum ChannelStatus {
    INACTIVE,
    SUSPENDED,
    TESTING,
    SUBSCRIBED,
    ONBOARDING
}

// XXX: https://developers.deliverect.com/reference/get-channel-links-for-location
export enum ChannelIdentifier {
    DELIVERECT = 1,
    UBEREATS = 7,
    DOORDASH = 12,
    GRUBHUB = 42,
    UBER_POSTMATES = 35,
    TAKEAWAY_COM = 8,
    FANTUAN = 10152,
    CHOWNOW = 37,
    HUNGRY_PANDA = 10141,
    RITUAL = 13,
    EASI = 47,
    POSTMATES = 18
}

export const mapChannelKindToChannelIdentifier = (
    channelKind: MenuChannelKind
) =>
    match(channelKind)
        .with(MenuChannelKind["3_P_UBEREATS"], () => ChannelIdentifier.UBEREATS)
        .with(MenuChannelKind["3_P_DOORDASH"], () => ChannelIdentifier.DOORDASH)
        .with(MenuChannelKind["3_P_GRUBHUB"], () => ChannelIdentifier.GRUBHUB)
        .with(MenuChannelKind["3_P_FANTUAN"], () => ChannelIdentifier.FANTUAN)
        .with(
            MenuChannelKind["3_P_HUNGRYPANDA"],
            () => ChannelIdentifier.HUNGRY_PANDA
        )
        .with(MenuChannelKind["3_P_RITUAL"], () => ChannelIdentifier.RITUAL)
        .with(
            MenuChannelKind["3_P_POSTMATES"],
            () => ChannelIdentifier.POSTMATES
        )
        .otherwise(() => ChannelIdentifier.DELIVERECT);

export const mapChannelIdentifierToChannelKind = (
    channelIdentifier: ChannelIdentifier
) =>
    match(channelIdentifier)
        .with(ChannelIdentifier.UBEREATS, () => MenuChannelKind["3_P_UBEREATS"])
        .with(ChannelIdentifier.DOORDASH, () => MenuChannelKind["3_P_DOORDASH"])
        .with(ChannelIdentifier.GRUBHUB, () => MenuChannelKind["3_P_GRUBHUB"])
        .with(ChannelIdentifier.FANTUAN, () => MenuChannelKind["3_P_FANTUAN"])
        .with(
            ChannelIdentifier.HUNGRY_PANDA,
            () => MenuChannelKind["3_P_HUNGRYPANDA"]
        )
        .with(ChannelIdentifier.RITUAL, () => MenuChannelKind["3_P_RITUAL"])
        .with(
            ChannelIdentifier.POSTMATES,
            () => MenuChannelKind["3_P_POSTMATES"]
        )
        .otherwise(() => MenuChannelKind["3_P_DELIVERECT"]);

export type ChannelLink = {
    _id: string;
    name: string;
    status: number;
    channel: number;
};

export type GetChannelLinksRequest = {
    storeId: string | undefined;
};

export type GetChannelLinksResponse = {
    data: {
        channels: ChannelLink[];
        success: boolean;
    };
};
