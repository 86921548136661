import { IUser } from "@snackpass/snackpass-types";

export const hasEmployeePermission = (user: IUser | null): boolean =>
    !user?.isBanned && !!user?.snackpassPermissions.isSnackpassEmployee;

export const hasSeniorSupportPermission = (user: IUser | null): boolean =>
    hasEmployeePermission(user) && !!user?.snackpassPermissions.isSeniorSupport;

export const hasPayoutPermission = (
    user: IUser | null,
    storeId?: string
): boolean => !!user?.permissions?.hasPayouts?.find((s) => s === storeId);

export const hasEditPermission = (
    user: IUser | null,
    storeId?: string
): boolean =>
    hasSeniorSupportPermission(user) || hasPayoutPermission(user, storeId);

const Hogwarts = "5b4a618f326bd9777aa5dc90";
const Warehouse = "61f1c1acdb423e013d35ff61";
const CafeBond = "61d8d034b1ddde014575b4da";
const SouthParkBistro = "62c8a12adfb3af00f1611b53";
const PlayfulPepperJack = "610c83d943c42c00f299d773";
const ThreeBroomsticks = "6525896b61e69ce99909f0d2";
const internalStores = [
    Hogwarts,
    Warehouse,
    CafeBond,
    SouthParkBistro,
    PlayfulPepperJack,
    ThreeBroomsticks
];
export const isInternalStore = (storeId: string) =>
    internalStores.includes(storeId);
