import React, { useContext } from "react";

import Text from "#payouts/components/shared/Text";
import theme from "#payouts/utils/theme";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { useRepresentative } from "#payouts/utils/hooks/useRepresentative";
import { RequirementMet } from "#payouts/components/shared/RequirementBlock";

export const EmailRequirementBlock = () => {
    const { user, editEnabled } = useContext(PayoutsSettingsContext);

    const { hasEligiblePrimary, verifiedOwnersAndExecutives } =
        useRepresentative();

    const emails = verifiedOwnersAndExecutives.map((r) => ({
        name: `${r.firstName} ${r.lastName}`,
        email: r.email
    }));

    const message = editEnabled
        ? `You (${user?.email}) must be logged in using the email of at least one verified owner or executive representative to finish.`
        : `You (${user?.email}) do not have the permission to complete this form`;

    return (
        <Text.Subtitle style={{ margin: `${theme.spacing.half} 0` }}>
            <div className="flex flex-row">
                <div>
                    <RequirementMet good={hasEligiblePrimary} />
                </div>
                <div className="flex flex-col">
                    {editEnabled ? (
                        <>
                            <span>{message} Verified emails:</span>
                            <ul>
                                {emails.length ? (
                                    emails.map((e) => (
                                        <li>
                                            {e.name} ({e.email})
                                        </li>
                                    ))
                                ) : (
                                    <li>None</li>
                                )}
                            </ul>
                        </>
                    ) : (
                        <span>{message}</span>
                    )}
                </div>
            </div>
        </Text.Subtitle>
    );
};
