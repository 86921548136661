import { Controller, useFormContext } from "react-hook-form";
import { Checkbox } from "pretty-checkbox-react";
import { useEffect } from "react";

import { INTEGRATION_FIELD_NAMES } from "#settings-integrations/utils/types";

export const PaymentAgreementContainer = () => {
    const { control, register, trigger } = useFormContext();

    useEffect(() => {
        void trigger(INTEGRATION_FIELD_NAMES.PAYMENT_AGREEMENT);
    }, [trigger]);

    return (
        <div className="flex flex-row items-center py-4 pl-6 pr-3">
            <Controller
                control={control}
                name={INTEGRATION_FIELD_NAMES.PAYMENT_AGREEMENT}
                render={() => (
                    <Checkbox
                        animation="pulse"
                        {...register(
                            INTEGRATION_FIELD_NAMES.PAYMENT_AGREEMENT,
                            { required: true }
                        )}
                    />
                )}
            />
            <div className="text-body font-normal md:text-body">
                I understand that I will be charged $120/month to enable
                integrations. This charge is not on a per integration basis.
            </div>
        </div>
    );
};
