import { IProduct, ProductOverrideFields } from "@snackpass/snackpass-types";
import { capitalize } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import {
    getTaxOverrideSelector,
    isProductSelected
} from "#menu-editor/multi-menus/redux/selectors";

import {
    NumericOverrideInput,
    NumericOverrideInputProps
} from "./override-input";

type ProductWithTaxes = Omit<IProduct, "taxInfo"> & {
    taxInfo: NonNullable<IProduct["taxInfo"]>;
};

interface TaxOverrideProps
    extends Pick<
        NumericOverrideInputProps,
        "inputComponent" | "inputComponentProps"
    > {
    item: ProductWithTaxes;
    fulfillment: keyof NonNullable<ProductOverrideFields["taxes"]>;
}

const formatLabel = (fulfillment: TaxOverrideProps["fulfillment"]) => {
    switch (fulfillment) {
        case "delivery":
        case "pickup":
            return capitalize(fulfillment);
        case "dineIn":
            return "Dine In";
    }
};

export const TaxOverrideInput = ({
    item,
    fulfillment,
    inputComponent,
    inputComponentProps
}: TaxOverrideProps) => {
    const taxesOverrideSelector = getTaxOverrideSelector(item._id);
    const taxesOverride = useSelector(taxesOverrideSelector);
    const fulfillmentTaxOverride = taxesOverride?.[fulfillment];
    const baseRate = item.taxInfo.rate;
    const disabled = !useSelector(isProductSelected(item._id));

    const dispatch = useDispatch();

    const setTaxesOverride = (rate: number) =>
        void dispatch(
            multiMenuActions.setTaxOverride({
                itemId: item._id,
                taxes: { ...taxesOverride, [fulfillment]: rate }
            })
        );

    const resetTaxesOverride = () =>
        void dispatch(
            multiMenuActions.setTaxOverride({
                itemId: item._id,
                taxes: { ...taxesOverride, [fulfillment]: undefined }
            })
        );

    return (
        <NumericOverrideInput
            baseValue={baseRate}
            overrideValue={fulfillmentTaxOverride}
            suffix=" %"
            max={100}
            setOverride={setTaxesOverride}
            resetOverride={resetTaxesOverride}
            disabled={disabled}
            width={7}
            inputComponent={inputComponent}
            inputComponentProps={{
                ...inputComponentProps,
                label: formatLabel(fulfillment)
            }}
        />
    );
};
