import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { getStoreMultiMenus } from "src/redux/selectors";
import { ReactComponent as LeftIcon } from "src/assets/icons/chevron-menu-left.svg";
import { ReactComponent as RightIcon } from "src/assets/icons/chevron-menu-right.svg";
import colors from "#reusable/colors/colors.json";

type SelectorProps = {
    menuId: string | undefined;
};

export const MenuNavigation: React.FC<SelectorProps> = ({ menuId }) => {
    const history = useHistory();
    const currentMenus = useSelector(getStoreMultiMenus);
    const menuIndex = currentMenus.findIndex((menu) => menu.id === menuId);
    if (menuIndex === -1) return null;

    const onClick = ({ right, left }: { right?: boolean; left?: boolean }) => {
        if (
            (menuIndex !== 0 && left) ||
            (right && menuIndex !== currentMenus.length - 1)
        )
            history.push(
                `/multi-menus-settings/${
                    currentMenus[menuIndex + (right ? 1 : -1)].id
                }`
            );
    };

    return (
        <ButtonsWrapper>
            <ArrowButton
                disabled={menuIndex === 0}
                onClick={() => onClick({ left: true })}
            >
                <LeftIcon
                    fill={menuIndex === 0 ? "#A0ADBA" : "#282D32"}
                    width={40}
                    height={15}
                />
            </ArrowButton>
            <ArrowButton
                right
                disabled={menuIndex === currentMenus.length - 1}
                onClick={() => onClick({ right: true })}
            >
                <RightIcon
                    fill={
                        menuIndex === currentMenus.length - 1
                            ? "#A0ADBA"
                            : "#282D32"
                    }
                    width={40}
                    height={15}
                />
            </ArrowButton>
        </ButtonsWrapper>
    );
};

const ButtonsWrapper = styled.div`
    display: flex;
`;

type ArrowButtonProps = {
    right?: boolean;
    disabled: boolean;
};

const ArrowButton = styled.div<ArrowButtonProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors["neutral-400"]};
    border-radius: ${({ right = false }) =>
        right ? "0px 8px 8px 0px" : "8px 0px 0px 8px"};
    background: #fff;
    color: ${({ disabled = false }) => (disabled ? "#A0ADBA" : "#FFF")};
    cursor: ${({ disabled = false }) => (disabled ? "not-allowed" : "pointer")};
    &:hover {
        box-shadow: ${({ disabled = false }) =>
            disabled
                ? undefined
                : "inset 999px 4px 0px rgba(241, 245, 248, 0.5)"};
    }
`;
