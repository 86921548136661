import styled, { css } from "styled-components";
import React, { useContext } from "react";
import { SystemColors } from "@snackpass/design-system";
import { Tooltip } from "antd";
import { noop } from "lodash";

type Props = {
    variant: "blue" | "outline" | "danger" | "dark" | "noBorder";
    className?: string;
    text?: string;
    square?: boolean;
    sm?: boolean;
    width?: "xxs" | "xs" | "s" | "m" | "l" | "xl" | string;
    height?: "xxs" | "xs" | "s" | "m" | "l" | "xl" | string;
    mobileFullWidth?: boolean;
    mobileNone?: boolean;
    mobileIconBtn?: boolean;
    underline?: boolean;
    ml?: number;
    mr?: number;
    checkBox?: boolean;
    checked?: boolean;
    fontSize?: number;
    disabled?: boolean;
    desktop?: boolean;
    mobile?: boolean;
    tooltip?: string;
    tooltipProps?: { visible?: boolean; color?: string };
    prefixIcon?: React.ReactNode;
    suffixIcon?: React.ReactNode;
    onClick: Function;
    children?: React.ReactNode;
};

type TextProp = {
    prefixIcon?: boolean;
    suffixIcon?: boolean;
    mobileIconBtn?: boolean;
};

const Context = React.createContext<Props>({
    variant: "blue",
    className: "",
    text: "",
    square: true,
    sm: true,
    width: "s",
    height: "s",
    mobileFullWidth: false,
    mobileNone: false,
    mobileIconBtn: false,
    underline: false,
    ml: 0,
    mr: 0,
    checkBox: false,
    checked: false,
    fontSize: 0,
    disabled: false,
    desktop: false,
    mobile: false,
    tooltip: "",
    tooltipProps: { visible: false, color: "" },
    prefixIcon: null,
    suffixIcon: null,
    onClick: noop,
    children: null
});

const StyledButton = styled.span<Props>`
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${(props) =>
        props.variant === "outline"
            ? `1px solid ${SystemColors.v2.salt100.light}`
            : "none"};
    border-radius: ${(props) => (props.square ? "8px" : "56px")};
    background: ${(props) =>
        props.variant === "blue" && !props.disabled
            ? SystemColors.v1.candy50
            : props.variant === "blue" && props.disabled
              ? SystemColors.v2.apricot5.dark
              : ""};
    font-weight: 600;
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "18px")};
    margin-left: ${(props) => (props.ml ? `${props.ml}px` : "0")};
    margin-right: ${(props) => (props.mr ? `${props.mr}px` : "0")};
    cursor: pointer;
    height: ${(props) =>
        props.height === "xxs"
            ? "28px"
            : props.height === "xs"
              ? "38px"
              : props.height === "s"
                ? "48px"
                : props.height === "m"
                  ? "58px"
                  : props.height === "l"
                    ? "68px"
                    : props.height === "xl"
                      ? "88px"
                      : props.height
                        ? props.height
                        : "fit-content"};
    width: ${(props) =>
        props.width === "xxs"
            ? "28px"
            : props.width === "xs"
              ? "48px"
              : props.width === "s"
                ? "68px"
                : props.width === "m"
                  ? "88px"
                  : props.width === "l"
                    ? "98px"
                    : props.width === "xl"
                      ? "108px"
                      : props.width
                        ? props.width
                        : "auto"};
    line-height: 24px;
    text-decoration-line: ${(props) => (props.underline ? "underline" : "")};
    color: ${(props) =>
        props.variant === "outline" || props.variant === "noBorder"
            ? SystemColors.v2.salt100
            : SystemColors.v2.salt10.light};
    &:hover {
        opacity: 0.8;
    }
    ${(props) =>
        props.mobileFullWidth &&
        css`
            @media only screen and (max-width: 768px) {
                width: 100%;
            }
        `}

    ${(props) =>
        props.mobileNone &&
        css`
            @media only screen and (max-width: 768px) {
                display: none;
            }
        `}

    ${(props) =>
        props.mobileIconBtn &&
        css`
            @media only screen and (max-width: 768px) {
                width: 40px;
            }
        `}
`;

const StyledText = styled.span<TextProp>`
    padding-left: ${(props) => (props.prefixIcon ? `8px` : "0")};
    padding-right: ${(props) => (props.suffixIcon ? `8px` : "0")};
    ${(props) =>
        props.mobileIconBtn &&
        css`
            @media only screen and (max-width: 768px) {
                display: none;
            }
        `}
`;

const ButtonText = () => {
    const { text, prefixIcon, suffixIcon, mobileIconBtn } = useContext(Context);
    return (
        <StyledText
            mobileIconBtn={mobileIconBtn}
            suffixIcon={suffixIcon !== undefined ? true : false}
            prefixIcon={prefixIcon !== undefined ? true : false}
        >
            {text}
        </StyledText>
    );
};

const Button: React.FC<Props> = ({
    variant,
    className,
    text,
    square,
    sm,
    width,
    height,
    underline,
    mobileFullWidth,
    mobileNone,
    mobileIconBtn,
    ml,
    mr,
    checkBox,
    checked,
    fontSize,
    disabled,
    desktop,
    mobile,
    tooltip,
    tooltipProps,
    prefixIcon,
    suffixIcon,
    onClick,
    children
}) => (
    <Context.Provider
        value={{
            variant,
            className,
            text,
            square,
            sm,
            width,
            height,
            underline,
            mobileFullWidth,
            mobileNone,
            mobileIconBtn,
            prefixIcon,
            suffixIcon,
            ml,
            mr,
            checkBox,
            checked,
            fontSize,
            disabled,
            desktop,
            mobile,
            tooltip,
            tooltipProps,
            onClick,
            children
        }}
    >
        {tooltip ? (
            <Tooltip
                title={tooltip}
                color={tooltipProps?.color}
                defaultOpen={false}
                open={tooltipProps?.visible}
            >
                <StyledButton
                    variant={variant}
                    className={className}
                    square={square}
                    sm={sm}
                    width={width}
                    height={height}
                    underline={underline}
                    mobileFullWidth={mobileFullWidth}
                    mobileNone={mobileNone}
                    mobileIconBtn={mobileIconBtn}
                    ml={ml}
                    mr={mr}
                    checkBox={checkBox}
                    checked={checked}
                    fontSize={fontSize}
                    disabled={disabled}
                    desktop={desktop}
                    mobile={mobile}
                    onClick={() => {
                        disabled ? null : onClick();
                    }}
                >
                    {prefixIcon ?? null}
                    <ButtonText />
                    {suffixIcon ?? null}
                </StyledButton>
            </Tooltip>
        ) : (
            <StyledButton
                variant={variant}
                className={className}
                square={square}
                sm={sm}
                width={width}
                height={height}
                underline={underline}
                mobileFullWidth={mobileFullWidth}
                mobileNone={mobileNone}
                mobileIconBtn={mobileIconBtn}
                ml={ml}
                mr={mr}
                checkBox={checkBox}
                checked={checked}
                fontSize={fontSize}
                disabled={disabled}
                desktop={desktop}
                mobile={mobile}
                onClick={() => {
                    disabled ? null : onClick();
                }}
            >
                {prefixIcon ?? null}
                {<ButtonText />}
                {suffixIcon ?? null}
            </StyledButton>
        )}
        {children}
    </Context.Provider>
);

export { Button };
