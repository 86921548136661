import React, { useContext, useMemo, useState } from "react";
import { LineChart } from "@tremor/react";
import Skeleton from "react-loading-skeleton";
import { match } from "ts-pattern";

import { ReportsContext } from "#app/reports-context-provider";
import {
    formatHour,
    formatNumber,
    formatRange,
    toDollarFormatted
} from "#reports/sales-summary/lib";
import { ComparisonType } from "#reports/sales-summary/types";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import {
    ChartTypeSelect,
    ChartType
} from "#reports/sales-summary/shared-components/ChartTypeSelect";
import { SalesSummaryLegend } from "#reports/sales-summary/components/SalesSummaryLegend";
import { SalesSummaryTooltip } from "#reports/sales-summary/components/SalesSummaryTooltip";
import { calculateChartWidth } from "#utils/helpers";
import { useSalesReport } from "#reports/hooks";

export const SalesHourlyChart = () => {
    const { reportsState } = useContext(ReportsContext);
    const { comparison, dateRanges, granularity } = reportsState;
    const { data: reportsData, isLoading, error } = useSalesReport();
    const data = reportsData?.salesHourlyAggregateReport;
    const compareToData = reportsData?.salesHourlyAggregateReportComparedTo;
    const shouldCompare = comparison != ComparisonType.NONE;

    const [graphType, setGraphType] = useState(ChartType.NET_SALES);

    const chartData = useMemo(
        () =>
            data
                ? data.map((row, idx) => ({
                      label: formatHour(idx),
                      range: formatRange(dateRanges[0], granularity),
                      netSales: row?.netSales,
                      orders: row?.orders,

                      ...(shouldCompare
                          ? {
                                compareToRange: formatRange(
                                    dateRanges[1],
                                    granularity
                                ),
                                compareToNetSales:
                                    compareToData?.[idx]?.netSales,
                                compareToOrders: compareToData?.[idx]?.orders
                            }
                          : {})
                  }))
                : [],
        [data, compareToData, shouldCompare, dateRanges, granularity]
    );

    const displayedCategories = useMemo(
        () =>
            match({ shouldCompare, graphType })
                .with(
                    { shouldCompare: false, graphType: ChartType.NET_SALES },
                    () => ["netSales"]
                )
                .with(
                    { shouldCompare: false, graphType: ChartType.ORDERS },
                    () => ["orders"]
                )
                .with(
                    { shouldCompare: true, graphType: ChartType.NET_SALES },
                    () => ["netSales", "compareToNetSales"]
                )
                .with(
                    { shouldCompare: true, graphType: ChartType.ORDERS },
                    () => ["order", "compareToOrders"]
                )
                .otherwise(() => []),
        [shouldCompare, graphType]
    );

    const yWidth = (): number => {
        const maxNetSales = Math.max(...chartData.map((item) => item.netSales));
        return calculateChartWidth(maxNetSales, true);
    };

    const valueFormatter =
        graphType == ChartType.NET_SALES ? toDollarFormatted : formatNumber;

    return (
        <div className="my-10">
            <div className="flex items-center justify-between">
                <h4 className="mb-2 text-large">Time of Day</h4>
                <ChartTypeSelect value={graphType} onChange={setGraphType} />
            </div>
            <SalesSummaryLegend />
            <div className="h-96 w-full">
                {match({ isLoading, hasError: !!error })
                    .with({ isLoading: true }, () => (
                        <Skeleton className="h-96" />
                    ))
                    .with({ hasError: true }, () => (
                        <ErrorChart className="h-96 rounded-md" />
                    ))
                    .otherwise(() => (
                        <LineChart
                            data={chartData}
                            categories={displayedCategories}
                            valueFormatter={valueFormatter}
                            index={"label"}
                            colors={["blue", "yellow"]}
                            yAxisWidth={yWidth()}
                            customTooltip={SalesSummaryTooltip}
                            minValue={0}
                            showLegend={false}
                        />
                    ))}
            </div>
        </div>
    );
};
