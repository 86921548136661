import React, { useEffect, useState } from "react";
import { toast } from "sonner";
import { useSelector } from "react-redux";
import { captureException } from "@sentry/react";
import { isMobile } from "react-device-detect";

import { getActiveStore } from "src/redux/selectors";
import { ScreenLayout } from "#reusable/layout";
import { MobileHeader } from "#reusable/layout/header";
import api from "src/api/rest";
import { LiveIntegrations } from "#settings-integrations/components/live-integrations";
import { IntegrationOverviewInformation } from "#settings-integrations/components/integration-overview-information";
import { ErrorWithCause } from "src/utils/errors";
import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";
import { ChannelLink } from "#settings-integrations/modules/deliverect";

export const Integrations = () => {
    const canEdit = useHasEditSettingsForActiveStore();
    const activeStore = useSelector(getActiveStore);
    const [isLoading, setIsLoading] = useState(true);
    const [showCreatingIntegrationView, setShowCreatingIntegrationView] =
        useState<boolean>(false);
    const [channels, setChannels] = useState<ChannelLink[]>([]);

    useEffect(() => {
        setIsLoading(true);
        if (activeStore && activeStore._id) {
            const getIntegrations = async () => {
                try {
                    const res = await api.deliverect.getChannelLinks({
                        storeId: activeStore._id
                    });

                    if (res.data.success) {
                        const nonTestChannels = res.data.channels.filter(
                            ({ name }) => !name.toLowerCase().includes("test")
                        );

                        setChannels(nonTestChannels);
                    } else {
                        throw new Error("Failed to load enabled integrations.");
                    }
                } catch (err) {
                    if (err instanceof ErrorWithCause) {
                        // Handle GET Deliverect Channel Request not working
                        toast.error("Failed to load enabled integrations.");
                    } else {
                        toast.error("An error has occurred.");
                        // Only log to Sentry for non-handled errors
                        captureException(Error(JSON.stringify(err)));
                    }
                    setChannels([]);
                } finally {
                    setIsLoading(false);
                }
            };
            void getIntegrations();
        }
    }, []);

    if (activeStore?._id) {
        return (
            <div className="h-full p-2 font-bold">
                <ScreenLayout
                    header={
                        isMobile ? (
                            <MobileHeader title="Integrations" />
                        ) : (
                            <div className="text-xlarge font-semibold text-neutral-950">
                                Integrations
                            </div>
                        )
                    }
                    isLoading={isLoading || !channels}
                    description={
                        <div className="text-body font-normal text-neutral-600">
                            Connect third party platforms to see all of your
                            orders in one place, powered by Deliverect.
                        </div>
                    }
                    content={
                        channels.length ? (
                            <LiveIntegrations
                                channels={channels}
                                storeId={activeStore._id}
                                storeName={activeStore.name}
                                showCreatingIntegrationView={
                                    showCreatingIntegrationView
                                }
                                setShowCreatingIntegrationView={
                                    setShowCreatingIntegrationView
                                }
                            />
                        ) : (
                            <IntegrationOverviewInformation
                                canEdit={canEdit}
                                showCreatingIntegrationView={
                                    showCreatingIntegrationView
                                }
                                setShowCreatingIntegrationView={
                                    setShowCreatingIntegrationView
                                }
                            />
                        )
                    }
                />
            </div>
        );
    }

    return <div>Hit end...</div>;
};
