import React, { useContext } from "react";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import styled from "styled-components";

import Text from "#devices/components/Text";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { Button } from "src/@/components/ui/button";
import theme from "#devices/components/theme";
import { checkValidName } from "#devices/utils/checkDeviceName";
import { DeviceType } from "#devices/utils/deviceTypes";

const Gap = styled.div`
    margin-bottom: 10px;
`;

const InvalidFeedback = ({ children }: { children: React.ReactNode }) => (
    <Form.Control.Feedback
        type="invalid"
        style={{ fontSize: theme.typography.body.fontSize }}
    >
        {children}
    </Form.Control.Feedback>
);

const Step3 = (): JSX.Element => {
    const {
        storeDevices,
        addNewDeviceName,
        setAddNewDeviceName,
        addNewDeviceTypeValue,
        nextStep,
        previousStep
    } = useContext(DevicesPageContext);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddNewDeviceName(event.target.value);
    };

    const deviceType = getDeviceTypeName(addNewDeviceTypeValue);

    const { valid, error } = checkValidName(
        storeDevices ?? [],
        addNewDeviceName,
        "no-id"
    );

    return (
        <>
            <Modal.Body>
                <Text.Title2>Setup {deviceType}</Text.Title2>
                <Text.LargeBody>
                    Pick a name that is easy to identify. You can also configure
                    it within settings from an existing device.
                </Text.LargeBody>
                <Form.Group controlId="addNewScreen" className="mb-4">
                    <Text.Label>
                        {deviceType === DeviceType.PrepStation
                            ? "Station Name"
                            : "Device Name"}
                        :
                    </Text.Label>
                    <Gap />
                    <Form.Control
                        autoFocus
                        size="lg"
                        type="text"
                        placeholder={`${deviceType} (1)`}
                        required
                        value={addNewDeviceName}
                        onChange={handleChange}
                        minLength={5}
                        maxLength={20}
                        isValid={valid}
                        isInvalid={!valid}
                        className="mb-4"
                        onKeyDown={(e) => {
                            if (e.key.toLowerCase() === "enter" && valid) {
                                nextStep();
                            }
                        }}
                    />
                    <InvalidFeedback>{error}</InvalidFeedback>
                </Form.Group>
            </Modal.Body>

            <Modal.Footer>
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            <Button
                                variant="outline"
                                className="mt-3 w-full md:mt-0"
                                onClick={previousStep}
                            >
                                Back
                            </Button>
                        </Col>
                        <Col sm={6}>
                            <Button
                                className="mt-3 w-full md:mt-0"
                                onClick={nextStep}
                                disabled={!valid}
                            >
                                Next
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </>
    );
};

export default Step3;
