import { captureException } from "@sentry/react";

import api from "src/api/rest";
import { notifyFailure, notifySuccess } from "#onboarding/utils/notify";
import { StoreVerification } from "#payouts/domain/types";

export const buildFetchVerification =
    (setVerification: (v: StoreVerification) => void) => async (id: string) =>
        api.verifications
            .retrieve(id)
            .then(({ data }) => {
                if (data?.success) {
                    /**
                     * Note: If a store provides name and tax_id, and that fails verification
                     *       before they've submitted the onboarding page, the default
                     *       response from server will prevent them from moving forward
                     *       since name and tax_id are removed when there's an error
                     *       with the tax_id.
                     *
                     *       One way to handle this case would be to change the server
                     *       response for onboarding to NOT remove the name and tax_id.
                     *
                     *       However, I think handling on RDB is sufficient for now
                     *       since this is such a rare case. We can just indicate "Provided"
                     *       and then let the user continue to the dashboard.
                     */
                    const verification = data.verification;
                    if (verification.verificationErrors?.length) {
                        const errors = verification.verificationErrors;
                        if (
                            errors.find(
                                (e) => e.requirement === "company.tax_id"
                            )
                        ) {
                            verification.name = "Provided";
                            verification.taxId = true;
                        }
                    }
                    setVerification(verification);
                } else {
                    notifyFailure({
                        description: "Unable to save payouts information"
                    });
                    captureException(Error(JSON.stringify(data)));
                }
            })
            .catch(() => {
                notifyFailure({
                    description: "Unable to save payouts information"
                });
            });

export const buildPatchVerification =
    (
        setVerification: (v: StoreVerification) => void,
        setLoading: (l: boolean) => void
    ) =>
    async (
        id: string,
        body: {
            name?: string;
            taxId?: string;
            merchantCategoryCode?: string;
            phone?: string;
        },
        cb?: () => void
    ) => {
        setLoading(true);
        return api.verifications
            .updateBusinessInformation(id, body)
            .then(({ data }) => {
                setLoading(false);
                if (data?.success) {
                    notifySuccess({
                        description: "Payouts information saved successfully"
                    });
                    setVerification(data.verification);
                    cb ? cb() : null;
                } else {
                    notifyFailure({
                        description: "Unable to save payouts information"
                    });
                    captureException(Error(JSON.stringify(data)));
                }
            })
            .catch(() => {
                setLoading(false);
                notifyFailure({
                    description: "Unable to save payouts information"
                });
            });
    };
