import { ScreenState } from "@snackpass/snackpass-types";
import { Radio, Space } from "antd";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { FormFieldDescriptor } from "#promotion/components/shared/form-field-descriptor";
import { FormFieldName } from "#promotion/components/shared/form-field-name";
import { DISCOUNT_TYPES } from "#promotion/utils/constants";
import { FIELD_NAMES } from "#promotion/utils/types";
import colors from "#reusable/colors/colors.json";

type Props = {
    hideFreeItemOption?: boolean;
};

export const PromoDiscountType = ({ hideFreeItemOption }: Props) => {
    const { control } = useFormContext();

    return (
        <Container>
            <FormFieldName name="Discount Type" required />
            <FormFieldDescriptor descriptor="Choose how items are discounted" />
            <Controller
                control={control}
                name={FIELD_NAMES.DISCOUNT_TYPE}
                render={({ field: { onChange, value } }) => (
                    <Radio.Group onChange={onChange} value={value}>
                        <Space direction="vertical">
                            <Radio value={DISCOUNT_TYPES.PERCENT_OFF}>
                                Percentage Off (%)
                            </Radio>
                            <Radio value={DISCOUNT_TYPES.DOLLARS_OFF}>
                                Amount Off ($)
                            </Radio>
                            {hideFreeItemOption !== true ? (
                                <Radio value={DISCOUNT_TYPES.FREE_ITEM}>
                                    Free Item
                                </Radio>
                            ) : null}
                        </Space>
                    </Radio.Group>
                )}
            />
        </Container>
    );
};

const Container = styled.div`
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid ${colors["neutral-400"]};
    input {
        width: 75%;
        margin-top: 15px;
        @media ${ScreenState.MOBILE} {
            width: 100%;
        }
    }
    .ant-radio-group {
        margin-top: 15px;
    }
    .ant-radio-wrapper {
        font-size: 16px;
    }
`;
