import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { TransactionSource } from "@snackpass/snackpass-types";
import { parse } from "graphql";
import { gql } from "graphql-request";

import { GQL } from "src/api/graphql/client";
import {
    OrderReportTransactionSource,
    PaySummaryByDateRange,
    QueryPaySummaryByDateRangeArgs
} from "src/api/graphql/generated/types";
import { logAndSendError } from "src/utils/errors";

export const toOrderReportTransactionSource = (
    x: TransactionSource
): OrderReportTransactionSource => {
    switch (x) {
        case "app":
            return OrderReportTransactionSource.App;
        case "kiosk":
            return OrderReportTransactionSource.Kiosk;
        case "online":
            return OrderReportTransactionSource.Online;
        default:
            return OrderReportTransactionSource.ThirdParty;
    }
};

class Stores {
    static async getTimecards({
        storeId,
        startDate,
        endDate
    }: QueryPaySummaryByDateRangeArgs): Promise<PaySummaryByDateRange> {
        const query: TypedDocumentNode<{
            paySummaryByDateRange: PaySummaryByDateRange;
        }> = parse(gql`
            query paySummaryByDateRange(
                $storeId: ID!
                $startDate: Date!
                $endDate: Date!
            ) {
                paySummaryByDateRange(
                    storeId: $storeId
                    startDate: $startDate
                    endDate: $endDate
                ) {
                    payPeriodId
                    employeeShiftDetails {
                        employee {
                            id
                            name
                            firstName
                            lastName
                            role
                            wage
                            identifier
                        }
                        shifts {
                            id
                            cashTips
                            employeeId
                            clockedInAt
                            clockedOutAt
                            overtimeHours
                            regularHours
                            doubleOvertimeHours
                            totalHours
                            estWages
                            wage
                            note
                            breaks {
                                id
                                type
                                start
                                end
                            }
                        }
                        regularHours
                        overtimeHours
                        doubleOvertimeHours
                        totalHours
                        estWages
                        blendedWage
                    }
                    employeesWithOngoingShifts {
                        name
                    }
                    regularHours
                    overtimeHours
                    doubleOvertimeHours
                    totalHours
                    estWages
                    totalHours
                    regularHours
                    timeCards
                    netSales
                    startDate
                    endDate
                }
            }
        `);

        return GQL.request(query, {
            storeId,
            startDate,
            endDate
        })
            .then((res) => res.paySummaryByDateRange)
            .catch((err) => {
                logAndSendError(err);
                throw err;
            });
    }
}

const api = {
    stores: Stores
};

export default api;
