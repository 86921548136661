import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSelector } from "react-redux";
import { formatNumber as formatPhoneNumber } from "libphonenumber-js";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "react-activity";

import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";
import { FormCard } from "src/@/components/form-card";
import { getActiveStore, getUser } from "src/redux/selectors";
import api from "src/api/rest";
import { buttonVariants } from "src/@/components/ui/button";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "src/@/components/ui/alert-dialog";
import {
    VERIFY_RESULT,
    sendVerificationCodeForAddingPhone
} from "#settings/helper";

export const ContactInfoSchema = z.object({
    email: z.string().optional(),
    phone: z.string()
});

export function ShowEditContactInfo() {
    const [showWarning, setShowWarning] = useState(false);
    const [phoneNum, setPhoneNum] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [phoneAvailable, setPhoneAvailable] = useState(false);

    const user = useSelector(getUser);
    const store = useSelector(getActiveStore);

    const form = useForm<z.infer<typeof ContactInfoSchema>>({
        resolver: zodResolver(ContactInfoSchema),
        defaultValues: {
            email: user?.email ?? "",
            phone: user?.number
                ? formatPhoneNumber(user.number, "US", "NATIONAL")
                : ""
        }
    });

    const isValidPhone = (phone: string) =>
        // Server can do better phone validation so simply check the length.
        // We have run the input through formatPhoneNumber so it should match
        // the format (111) 222-3456
        phone.length === "(111) 222-3456".length;

    const { isFetching: phoneAvailableFetching, error: phoneAvailableError } =
        useQuery({
            queryKey: ["phone-number-check", phoneNum],
            queryFn: async ({ queryKey }) => {
                const phoneNum = queryKey[1];
                if (!phoneNum || !isValidPhone(phoneNum)) {
                    return false;
                }

                const result =
                    await api.users.checkPhoneNumberAvailable(phoneNum);
                setPhoneAvailable(result.data.available);
                return result.data.available;
            }
        });

    useEffect(() => {
        if (phoneAvailableError) {
            toast.error(
                "Something went wrong checking if phone number is available",
                {
                    description: phoneAvailableError.message
                }
            );
        }
    }, [phoneAvailableError]);

    useEffect(() => {
        setIsLoading(phoneAvailableFetching);
    }, [phoneAvailableFetching]);

    const onSubmit = async (values: z.infer<typeof ContactInfoSchema>) => {
        setIsLoading(true);
        if (phoneAvailable) {
            await showPhonePrompt(values.phone);
        } else {
            setShowWarning(true);
        }
    };

    if (!store?._id) {
        return null;
    }

    const showPhonePrompt = async (phone: string) => {
        const loading = toast.loading("Setting phone number...");
        const result = await sendVerificationCodeForAddingPhone(
            phone,
            store._id
        );
        if (result === VERIFY_RESULT.SUCCESS) {
            toast.success("Phone number saved!");
            // we need to reload the site to get user updates
            // currently refetching the user leaves the site blank
            window.location.reload();
        } else if (result === VERIFY_RESULT.ERROR) {
            toast.error(
                "Something went wrong. Please contact Snackpass Support for help"
            );
        }
        setIsLoading(false);
        toast.dismiss(loading);
    };

    return (
        <>
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="w-full max-w-[900px]"
                >
                    <FormCard
                        title="Contact Information"
                        subtitle="Please enter the email address and phone number you want to use to receive notifications about your account.
                            To change your email contact Snackpass Support"
                        showSaveButton
                        disabled={
                            !form.formState.isDirty ||
                            isLoading ||
                            !isValidPhone(phoneNum)
                        }
                    >
                        <div className="flex items-center justify-between max-md:flex-col md:space-x-3">
                            <FormField
                                control={form.control}
                                name="email"
                                disabled
                                render={({ field }) => (
                                    <FormItem className="w-full">
                                        <FormLabel>Email</FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder="Email address"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormDescription />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="phone"
                                render={({ field }) => (
                                    <FormItem className="w-full">
                                        <FormLabel>
                                            Phone Number{" "}
                                            {phoneAvailableFetching && (
                                                <Spinner size={8} />
                                            )}
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder="Phone number"
                                                {...field}
                                                onBlur={() => {
                                                    form.setValue(
                                                        "phone",
                                                        formatPhoneNumber(
                                                            field.value,
                                                            "US",
                                                            "NATIONAL"
                                                        )
                                                    );
                                                }}
                                                onChange={(e) => {
                                                    setPhoneNum(
                                                        formatPhoneNumber(
                                                            e.target.value,
                                                            "US",
                                                            "NATIONAL"
                                                        )
                                                    );
                                                    field.onChange(e);
                                                }}
                                            />
                                        </FormControl>
                                        <FormDescription />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </FormCard>
                </form>
            </Form>
            <AlertDialog
                open={showWarning}
                onOpenChange={(open: boolean) => {
                    setShowWarning(open);
                }}
            >
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                        <AlertDialogDescription>
                            You are about to add a phone number that is already
                            associated with another account in our system. By
                            clicking 'Ok' you will remove the phone number from
                            that account and assign it to this account. Note
                            that this action will not make changes to point
                            balances or purchase history, you might also lose
                            access to the previous account.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel
                            onClick={() => {
                                setShowWarning(false);
                                setIsLoading(false);
                            }}
                        >
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            onClick={async () => {
                                await showPhonePrompt(phoneNum);
                                setShowWarning(false);
                            }}
                            className={buttonVariants({
                                variant: "destructive"
                            })}
                        >
                            Ok
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
}
