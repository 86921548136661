/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "sonner";

import { DownloadCsvButton } from "#financial-reports/components/download-csv-button";
import DateRangeSelector from "#date-picker/date-range-selector";
import { usePayoutHistoryData } from "#financial-reports/screens/payout-history/hooks/usePayoutHistoryData";
import { formatPresentablePayoutsData } from "#financial-reports/screens/payout-history/lib";
import { getActiveStore } from "src/redux/selectors";
import { getStoreTimezone } from "#utils/helpers";

import { PayoutHistoryTable } from "./payout-history-table";

export const PayoutHistoryReports = () => {
    const { data, isLoading, error, errorMessage } = usePayoutHistoryData();
    const activeStore = useSelector(getActiveStore);
    const timezone = getStoreTimezone(activeStore);
    const getPresentableData = formatPresentablePayoutsData.bind(
        null,
        data,
        timezone
    );
    const TODAY = moment().format("YYYY-MMM-DD");
    useEffect(() => {
        if (error) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    return (
        <div className="p-4 md:p-6">
            <h4 className="mb-6 text-large">Payout History</h4>
            <div className="mb-4 flex flex-row items-center gap-2 overflow-auto whitespace-nowrap">
                <DateRangeSelector
                    subtitle="Currently showing "
                    showYear={true}
                />
                <div className="flex flex-1 justify-end">
                    <DownloadCsvButton
                        getData={getPresentableData}
                        filename={`${activeStore?.name} - Snackpass Payout History Reports ${TODAY}`}
                    />
                </div>
            </div>
            <PayoutHistoryTable data={data} isLoading={isLoading} />
        </div>
    );
};
