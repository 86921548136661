// This is taken from https://github.com/shadcn-ui/ui/issues/2402
// It allows tooltips to be popovers on mobile instead, which allows them to still give
// information even on mobile. As is, raw shadcn tooltips are impossible cannot be seen on mobile.

"use client";

import { useSyncExternalStore } from "react";
import {
    PopoverContentProps,
    PopoverProps,
    PopoverTriggerProps
} from "@radix-ui/react-popover";
import {
    TooltipContentProps,
    TooltipProps,
    TooltipTriggerProps
} from "@radix-ui/react-tooltip";

import { Tooltip, TooltipTrigger, TooltipContent } from "./tooltip";
import { Popover, PopoverTrigger, PopoverContent } from "./popover";

const query = window.matchMedia("(pointer: coarse)");

function subscribe(onChange: () => void) {
    query.addEventListener("change", onChange);
    return () => {
        query.removeEventListener("change", onChange);
    };
}

function getSnapshot() {
    return query.matches;
}

function useIsTouchCursor() {
    return useSyncExternalStore(subscribe, getSnapshot);
}

// we use both Tooltip and Popover to allow switching between them
// in runtime using devtools, otherwise it fails due to render order
// and portal trickery
export const HybridTooltip = ({
    children,
    ...props
}: TooltipProps & PopoverProps) => (
    <Popover {...props}>
        <Tooltip {...props}>{children}</Tooltip>
    </Popover>
);

export const HybridTooltipTrigger = (
    props: TooltipTriggerProps & PopoverTriggerProps
) => {
    const isTouchCursor = useIsTouchCursor();

    return isTouchCursor ? (
        <PopoverTrigger {...props} />
    ) : (
        <TooltipTrigger {...props} />
    );
};

export const HybridTooltipContent = (
    props: TooltipContentProps & PopoverContentProps
) => {
    const isTouchCursor = useIsTouchCursor();

    return isTouchCursor ? (
        <PopoverContent {...props} />
    ) : (
        <TooltipContent {...props} />
    );
};
