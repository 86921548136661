import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { Routes } from "#navigation/routes";
import { getUser } from "src/redux/selectors";

const NoAccessPage = () => {
    const user = useSelector(getUser);
    const userIdentifier = user?.email || user?.number || "No user";

    return (
        <div className="mt-8 flex w-full flex-col items-center">
            <div>You do not have access to this page.</div>
            <div>
                Update your permissions on the{" "}
                <Link to={Routes.SettingsPermissions}>Users Page</Link>.
            </div>
            <div>
                Please contact your store administrator if you need further
                assistance.
            </div>
            <div>You are logged in as: {userIdentifier}</div>
        </div>
    );
};

export default NoAccessPage;
