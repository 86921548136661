import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger
} from "src/@/components/ui/tooltip";
import { ReactComponent as InfoIcon } from "src/assets/icons/Info.svg";

export const SummaryTooltip = ({ body }: { body: string }) => (
    <TooltipProvider delayDuration={100}>
        <Tooltip>
            <TooltipTrigger asChild>
                <InfoIcon className={"mx-1 h-3 w-3 cursor-pointer"} />
            </TooltipTrigger>

            <TooltipContent
                className="z-50 w-max max-w-sm overflow-auto break-words rounded-md bg-neutral-900 px-3 py-2 text-center font-normal text-neutral-50"
                sideOffset={5}
            >
                {body}
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
);
