import { SystemColors } from "@snackpass/design-system";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "sonner";

import api from "src/api/rest";
import { getActiveStoreId } from "src/redux/selectors";
import { Text } from "#legacy-components";
import { baseUnits, useFetchInventory } from "#inventory/lib";

type Props = {
    show: boolean;
    onHide: () => void;
};

export const NewItemForm = ({ show, onHide }: Props) => {
    const { register, handleSubmit, formState, reset } = useForm();
    const [fetchInventory, isFetching] = useFetchInventory();
    const [debounce, setDebounce] = useState(false);

    const storeId = useSelector(getActiveStoreId);

    const _postNewItem = async (data: any) => {
        const costPerUnitCents = Math.round(
            parseFloat(data.costPerUnitCents) * 100
        );
        if (debounce) {
            return;
        }
        setDebounce(true);
        await api.inventory.create({
            storeId: storeId,
            name: data.name,
            sku: data.sku,
            baseUnit: data.baseUnit,
            costPerUnitCents: costPerUnitCents
        });
        // success
        toast.success("Your item has been created");
        fetchInventory();
        onHide();
        reset();
        setDebounce(false);
    };

    return (
        <Modal onHide={onHide} show={show}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Text bold large>
                        New Item
                    </Text>
                </Modal.Title>
            </Modal.Header>
            <form
                onSubmit={(e) => {
                    void handleSubmit(_postNewItem)(e);
                }}
            >
                <Modal.Body>
                    <input
                        style={styles.input}
                        placeholder="Item Name"
                        {...register("name", {
                            required: true
                        })}
                    />
                    {formState.errors.name && (
                        <span className="text-critical-light">
                            Name is required
                        </span>
                    )}
                    <br />
                    <input
                        style={styles.input}
                        placeholder="SKU"
                        {...register("sku", {
                            required: true
                        })}
                    />
                    {formState.errors.sku && (
                        <span className="text-critical-light">
                            SKU is required
                        </span>
                    )}
                    <br />
                    <span style={{ margin: 5 }}>UOM</span>
                    <select
                        id="UOM"
                        {...register("baseUnit", {
                            required: true
                        })}
                        style={{
                            ...styles.input,
                            borderRadius: 56,
                            padding: 5,
                            border: "1px solid rgb(220, 220, 220)"
                        }}
                    >
                        {baseUnits.map((o) => (
                            <option value={o} key={o}>
                                {o}
                            </option>
                        ))}
                    </select>
                    {formState.errors.baseUnit && (
                        <span className="text-critical-light">
                            UOM is required
                        </span>
                    )}
                    <br />
                    <input
                        style={styles.input}
                        type="number"
                        step="0.01"
                        placeholder="Price"
                        {...register("costPerUnitCents", {
                            required: true
                        })}
                    />
                    {formState.errors.costPerUnitCents && (
                        <span className="text-critical-light">
                            Price is required
                        </span>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="-snackpass-btn"
                        type="submit"
                        style={{
                            backgroundColor: SystemColors.v1.candy50,
                            color: SystemColors.v1.white
                        }}
                    >
                        Submit
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

const styles = {
    input: {
        borderRadius: 56,
        margin: 5,
        paddingVertical: 5
    }
};
