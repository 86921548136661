import { DefaultTipType } from "@snackpass/snackpass-types";
import { z } from "zod";
import { toast } from "sonner";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { ReloadIcon } from "@radix-ui/react-icons";
import { getActiveStore } from "@snackpass/accounting";
import { useSelector } from "react-redux";

import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage
} from "src/@/components/ui/form";
import { Button } from "src/@/components/ui/button";
import { NumberInput } from "@tremor/react";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle
} from "src/@/components/ui/card";
import { Switch } from "src/@/components/ui/switch";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "src/@/components/ui/select";
import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";
import { useAppDispatch } from "src/redux/hooks";
import { tipTypeOptions } from "#settings-tipping/helper";
import api from "src/api/rest";
import { setActiveStore } from "src/redux/slices";
import { sendError } from "src/utils/errors";
import { useUnsavedChangesPrompt } from "#settings/hooks/useUnsavedChangesPrompt";
import { UnsavedChangesModal } from "#settings/components/unsaved-changes";

const TippingSchema = z.object({
    collectTip: z.boolean(),
    showNoTip: z.boolean(),
    defaultTipType: z.enum([DefaultTipType.Flat, DefaultTipType.Percent]),
    // Shadcn's Select Component requires a string
    // defaultTipValue is an index of a 1-indexed array of default tips or equal to "0" for no tip.
    defaultTipValue: z.string(),
    defaultTips: z
        .array(z.coerce.number().min(0))
        .refine((items) => new Set(items).size === items.length, {
            message: "Cannot have duplicate tips"
        })
        .refine((items) => !items.includes(0), {
            message: "Show no tip instead."
        })
});

const SettingsTippingScreen = () => {
    const canEdit = useHasEditSettingsForActiveStore();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const activeStore = useSelector(getActiveStore);

    const form = useForm<z.infer<typeof TippingSchema>>({
        resolver: zodResolver(TippingSchema),
        defaultValues: {
            collectTip: false,
            showNoTip: false,
            defaultTipType: DefaultTipType.Flat,
            defaultTipValue: "1", // Index of first tip of defaultTips if we let index 0 be reserved for no tip option
            defaultTips: [1, 2, 3, 4]
        }
    });

    const onSubmit = useCallback(
        async (values: z.infer<typeof TippingSchema>) => {
            if (!activeStore) {
                return;
            }

            if (!canEdit) {
                toast.error("You do not have permission to edit this store");
                return;
            }
            try {
                setIsLoading(true);
                const { data } = await api.stores.update(activeStore._id, {
                    collectTip: values.collectTip,
                    ...(values.collectTip && {
                        showNoTip: values.showNoTip,
                        defaultTip: {
                            defaultTipType: values.defaultTipType,
                            // We convert the index from the zod schema back into the actual default tip value for the store update
                            defaultTipValue:
                                Number(values.defaultTipValue) > 0
                                    ? values.defaultTips[
                                          Number(values.defaultTipValue) - 1
                                      ]
                                    : 0,
                            defaultTips: values.defaultTips
                        }
                    })
                });
                dispatch(setActiveStore(data.store));
                toast.success("Store updated successfully");
            } catch (e) {
                sendError(e);
                toast.error("Can not update the store, please try again later");
            } finally {
                setIsLoading(false);
            }
        },
        [activeStore, canEdit, dispatch]
    );

    const valuesFromActiveStore: z.infer<typeof TippingSchema> = useMemo(() => {
        const hasMatch =
            activeStore?.defaultTip?.defaultTips &&
            activeStore?.defaultTip?.defaultTips.some(
                (tip) => tip === activeStore?.defaultTip?.defaultTipValue
            );
        const defaultTipIsZero =
            activeStore?.defaultTip?.defaultTipValue === 0 &&
            !!activeStore?.showNoTip;
        return {
            collectTip: !!activeStore?.collectTip,
            showNoTip: !!activeStore?.showNoTip,
            defaultTipType:
                activeStore?.defaultTip?.defaultTipType ?? DefaultTipType.Flat,
            defaultTipValue: hasMatch
                ? String(
                      activeStore?.defaultTip?.defaultTips.indexOf(
                          activeStore?.defaultTip?.defaultTipValue
                      ) + 1
                  )
                : defaultTipIsZero
                  ? "0"
                  : "1",
            defaultTips: activeStore?.defaultTip?.defaultTips ?? []
        };
    }, [activeStore]);

    useEffect(() => {
        if (activeStore) {
            form.reset(valuesFromActiveStore);
        }
    }, [activeStore, form, valuesFromActiveStore]);

    const resetForm = useCallback(() => {
        form.reset(valuesFromActiveStore);
    }, [form, valuesFromActiveStore]);

    const updateTip = useCallback(
        (
            e: React.ChangeEvent<HTMLInputElement>,
            value: (number | string)[],
            onChange: (value: (number | string)[]) => void,
            index: number
        ) => {
            onChange([
                ...value.slice(0, index),
                e.target.value !== "" ? Number(e.target.value) : "",
                ...value.slice(index + 1)
            ]);
        },
        []
    );

    const updateNoTip = useCallback(
        (checked: boolean, onChange: (checked: boolean) => void) => {
            if (!checked && form.watch("defaultTipValue") === "0")
                form.setValue("defaultTipValue", "1");

            onChange(checked);
        },
        [form]
    );

    const tipIcon = useCallback(
        () => (
            <div className="absolute right-0 mr-20 flex shrink-0 items-center text-micro">
                {form.watch("defaultTipType") === DefaultTipType.Flat
                    ? "$"
                    : "%"}
            </div>
        ),
        [form]
    );

    const disableTipEdit = !form.watch("collectTip") || !canEdit;

    const {
        showModal,
        handleConfirmNavigationClick,
        handleCancelNavigationClick
    } = useUnsavedChangesPrompt(form.formState.isDirty);

    return (
        <div className="h-max overflow-auto bg-gray-50">
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="mb-24 space-y-8 p-8 sm:p-16"
                >
                    <UnsavedChangesModal
                        show={showModal}
                        onConfirm={handleConfirmNavigationClick}
                        onCancel={handleCancelNavigationClick}
                    />
                    <div>
                        <CardTitle className="text-2xl font-bold">
                            Tipping
                        </CardTitle>
                        <div className="text-gray-600">
                            Specify tipping rules.
                        </div>
                        <hr className="border-gray-300" />
                    </div>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">Rules</CardTitle>
                            <CardDescription>
                                Tipping rules will apply to all channels
                                (mobile, online, kiosk, and register).
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="collectTip"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Collect Tip
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Allow customers to tip when
                                                    ordering.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="defaultTipType"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Tip Type
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Choose between percentage or
                                                flat rate tip.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <Select
                                                        value={field.value}
                                                        onValueChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            disableTipEdit
                                                        }
                                                    >
                                                        <SelectTrigger className="w-[180px]">
                                                            <SelectValue placeholder="Select Tip Type" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            {tipTypeOptions.map(
                                                                (option) => (
                                                                    <SelectItem
                                                                        key={
                                                                            option.value
                                                                        }
                                                                        value={
                                                                            option.value
                                                                        }
                                                                    >
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </SelectItem>
                                                                )
                                                            )}
                                                        </SelectContent>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="showNoTip"
                                render={({ field: { value, onChange } }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Show "No tip" option
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Customers can select “No
                                                    Tip” when checking out.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={value}
                                                        onCheckedChange={(
                                                            checked
                                                        ) => {
                                                            updateNoTip(
                                                                checked,
                                                                onChange
                                                            );
                                                        }}
                                                        disabled={
                                                            disableTipEdit
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Preset Amounts
                            </CardTitle>
                            <CardDescription>
                                Set percentage amounts customers can select.
                                Customers will also be able to enter a custom
                                amount.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="defaultTips"
                                render={({ field: { value, onChange } }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Tip Presets
                                            </CardTitle>
                                            <FormControl>
                                                <div className="space-y-4 pt-4">
                                                    {value?.length > 0
                                                        ? value.map(
                                                              (val, index) => (
                                                                  <NumberInput
                                                                      placeholder="0"
                                                                      min={0}
                                                                      max={
                                                                          form.watch(
                                                                              "defaultTipType"
                                                                          ) ===
                                                                          DefaultTipType.Flat
                                                                              ? Infinity
                                                                              : 100
                                                                      }
                                                                      key={`${form.watch("defaultTipType")} ${index}`}
                                                                      className="w-[180px] flex-1 [&>input]:pl-2"
                                                                      icon={
                                                                          tipIcon
                                                                      }
                                                                      value={
                                                                          val
                                                                      }
                                                                      onChange={(
                                                                          e
                                                                      ) => {
                                                                          updateTip(
                                                                              e,
                                                                              value,
                                                                              onChange,
                                                                              index
                                                                          );
                                                                      }}
                                                                      disabled={
                                                                          disableTipEdit
                                                                      }
                                                                  />
                                                              )
                                                          )
                                                        : null}
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="defaultTipValue"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Default Tip
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                This option will be pre-selected
                                                for customers.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <Select
                                                        onValueChange={
                                                            field.onChange
                                                        }
                                                        value={field.value}
                                                        disabled={
                                                            disableTipEdit
                                                        }
                                                    >
                                                        <SelectTrigger className="w-[180px]">
                                                            <SelectValue placeholder="Select Default Tip" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectItem
                                                                value="0"
                                                                disabled={
                                                                    !form.watch(
                                                                        "showNoTip"
                                                                    )
                                                                }
                                                            >
                                                                No Tip
                                                            </SelectItem>

                                                            {form.watch(
                                                                "defaultTips"
                                                            )?.length > 0
                                                                ? form
                                                                      .watch(
                                                                          "defaultTips"
                                                                      )
                                                                      .map(
                                                                          (
                                                                              val,
                                                                              index
                                                                          ) => (
                                                                              <SelectItem
                                                                                  key={`${val} ${index}`}
                                                                                  value={String(
                                                                                      index +
                                                                                          1
                                                                                  )}
                                                                              >
                                                                                  {form.watch(
                                                                                      "defaultTipType"
                                                                                  ) ===
                                                                                  DefaultTipType.Flat
                                                                                      ? `$${val}`
                                                                                      : `${val}%`}
                                                                              </SelectItem>
                                                                          )
                                                                      )
                                                                : null}
                                                        </SelectContent>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    {form.formState.isDirty ? (
                        <div className="fixed bottom-0 left-0 flex w-full flex-row items-center justify-end gap-4 border-t bg-white p-2">
                            <Button
                                type="button"
                                variant={"outline"}
                                onClick={resetForm}
                            >
                                Cancel
                            </Button>
                            <Button disabled={isLoading} type="submit">
                                {isLoading && (
                                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                Submit
                            </Button>
                        </div>
                    ) : null}
                </form>
            </Form>
        </div>
    );
};
export default SettingsTippingScreen;
