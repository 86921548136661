import React, { useCallback, useContext, useMemo } from "react";

import { Button } from "src/@/components/ui/button";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { ReportsContext } from "#app/reports-context-provider";
import {
    formatRange,
    getComparisonRange,
    periodOptionLabels
} from "#reports/sales-summary/lib";
import { ComparisonType } from "#reports/sales-summary/types";

export const ComparisonFilterPicker = ({
    handleComparisonChange
}: {
    handleComparisonChange: (e: ComparisonType) => void;
}) => {
    const { reportsState } = useContext(ReportsContext);
    const { comparison, granularity, dateRanges } = reportsState;

    const getDateLabel = useCallback(
        (comparison: ComparisonType) =>
            formatRange(
                getComparisonRange(dateRanges[0], comparison),
                granularity
            ),
        [dateRanges, granularity]
    );

    const options = useMemo(
        () => [
            {
                label: "No Comparison",
                value: ComparisonType.NONE
            },
            {
                value: ComparisonType.WEEK,
                label: (
                    <ComparisonRow
                        label="Previous Week"
                        dateLabel={getDateLabel(ComparisonType.WEEK)}
                    />
                )
            },
            {
                value: ComparisonType.MONTH,
                label: (
                    <ComparisonRow
                        label="Previous Month"
                        dateLabel={getDateLabel(ComparisonType.MONTH)}
                    />
                )
            },
            {
                value: ComparisonType.YEAR,
                label: (
                    <ComparisonRow
                        label="Previous Year"
                        dateLabel={getDateLabel(ComparisonType.YEAR)}
                    />
                )
            },
            {
                value: ComparisonType.PERIOD,
                label: (
                    <ComparisonRow
                        label="Previous Period"
                        dateLabel={getDateLabel(ComparisonType.PERIOD)}
                    />
                )
            }
        ],
        [dateRanges]
    );

    const selectedValues = useMemo(
        () => new Set([comparison as string]),
        [comparison]
    );

    return (
        <FilterSelect
            title="Comparison"
            customButton={
                <Button variant="outline" size="sm">
                    {periodOptionLabels[comparison]}
                </Button>
            }
            contentClassName="w-min"
            selectedValues={selectedValues}
            options={options}
            onOptionSelected={(value: string) =>
                handleComparisonChange(value as ComparisonType)
            }
        />
    );
};

type ComparisonRowProps = {
    label: string;
    dateLabel: string;
};

const ComparisonRow = ({ label, dateLabel }: ComparisonRowProps) => (
    <div className="flex w-full justify-between whitespace-nowrap">
        <div className="w-[150px]">{label}</div>
        <div className="w-[150px] text-right text-neutral-600">{dateLabel}</div>
    </div>
);
