import React, { useCallback, useMemo } from "react";
import "@djthoms/pretty-checkbox";

import { useDispatch, useSelector } from "react-redux";
import {
    READABLE_THIRD_PARTY_MAPPING,
    ThirdPartySource
} from "@snackpass/snackpass-types";

import {
    setDashboardFilterTransactionChannel,
    getDashboardTransactionChannel,
    getDashboardTransactionChannelOptions
} from "src/redux/slices";
import { ReactComponent as FilterIcon } from "src/assets/icons/filter-sort.svg";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { Button } from "src/@/components/ui/button";

const TransactionChannelPicker = () => {
    const dispatch = useDispatch();
    const transactionChannels = useSelector(getDashboardTransactionChannel);
    const channelOptions = useSelector(getDashboardTransactionChannelOptions);

    const setChannels = useCallback(
        (selectedChannels: ThirdPartySource[]) => {
            dispatch(setDashboardFilterTransactionChannel(selectedChannels));
        },
        [dispatch, setDashboardFilterTransactionChannel]
    );

    const selectedChannels = useMemo(
        () => new Set(transactionChannels as string[]),
        [transactionChannels]
    );

    return (
        <FilterSelect
            customButton={
                <Button
                    variant="outline"
                    size="sm"
                    className="flex items-center space-x-2"
                >
                    <FilterIcon className="h-4 w-4" />
                    <span className="hidden md:block">Channel</span>
                </Button>
            }
            title="Channel"
            selectedValues={selectedChannels}
            options={channelOptions.map((e) => ({
                label: READABLE_THIRD_PARTY_MAPPING[e],
                value: e
            }))}
            onOptionSelected={function (value: string): void {
                if (selectedChannels.has(value)) {
                    selectedChannels.delete(value);
                } else {
                    selectedChannels.add(value);
                }
                setChannels(Array.from(selectedChannels) as ThirdPartySource[]);
            }}
        />
    );
};

export default TransactionChannelPicker;
