import React from "react";

import * as H from "./styles";

export interface IHeaderProps {
    lead?: React.ReactNode;
    center?: React.ReactNode;
    tail?: React.ReactNode;
    children?: React.ReactNode;
}

export const Header: React.FC<IHeaderProps> = ({
    lead,
    center,
    tail,
    children
}) => (
    <H.Header>
        {children ? (
            children
        ) : (
            <H.PhoneHeaderWrapper>
                <H.PhoneHeaderLead>{lead ?? <H.Space />}</H.PhoneHeaderLead>
                <H.PhoneHeaderCenter>
                    {center ?? <H.Space />}
                </H.PhoneHeaderCenter>
                <H.PhoneHeaderTail>{tail ?? <H.Space />}</H.PhoneHeaderTail>
            </H.PhoneHeaderWrapper>
        )}
    </H.Header>
);
