import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    Popconfirm,
    Row,
    Select,
    notification
} from "antd";
import { DateTime } from "luxon";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { z } from "zod";

import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";
import { Alert, AlertDescription } from "src/@/components/ui/alert";
import { logAndSendError } from "src/utils/errors";

type Props = {
    isOpen: boolean;
    closeModal: () => void;
};

type FormData = {
    mode: "ownership_transfer" | "entity_transfer";
    activeAt: string;
    ownerEmail: string;
};

export const OwnershipTransferModal = ({ isOpen, closeModal }: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const store = useSelector(getActiveStore);

    const handleSubmit = async (formData: FormData) => {
        if (loading || !store?._id) return;
        setLoading(true);

        const handleFailure = (error: unknown) => {
            const parsedError = z
                .object({ message: z.string() })
                .safeParse(error);
            notification.error({
                placement: "top",
                message: "Error",
                description: parsedError.success
                    ? parsedError.data.message
                    : undefined
            });
        };

        const handleSuccess = () => {
            notification.success({
                placement: "top",
                message: "Ownership transfer scheduled"
            });
            closeModal();
        };

        try {
            await api.stores.scheduleOwnershipTransfer(store?._id, {
                ...formData,
                activeAt: DateTime.fromISO(formData.activeAt, {
                    zone: store.hours.zone
                }).toJSDate()
            });
            handleSuccess();
        } catch (e) {
            logAndSendError(e);
            handleFailure(e);
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <Modal
            open={isOpen}
            closable={false}
            onCancel={closeModal}
            title="Transfer Ownership"
            footer={
                <>
                    <StyledButton onClick={closeModal}>Cancel</StyledButton>
                    <Popconfirm
                        title={`Are you sure you want to schedule transfer of ownership for ${store?.name}?`}
                        onConfirm={form.submit}
                        okText="Yes"
                        cancelText="No"
                        placement="bottom"
                    >
                        <StyledButton type="primary" loading={loading}>
                            Submit
                        </StyledButton>
                    </Popconfirm>
                </>
            }
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <div className="mb-4 flex w-full">
                    <Alert variant="warning">
                        <AlertDescription>
                            <strong>April 25, 2024:</strong> This approach to
                            ownership transfer may be changing soon, please
                            ensure this is the correct process to use at this
                            time.
                        </AlertDescription>
                    </Alert>
                </div>
                <Row>
                    <Form.Item
                        name="mode"
                        label="Transfer Type"
                        extra={
                            <ul>
                                <li>
                                    Entity transfer: create a new Stripe Account
                                    for the store and deactivate the current
                                    one. Store admin will need to fill out
                                    onboarding form in RDB again.
                                </li>
                                <li>
                                    Ownership transfer: perform everything
                                    entity transfer does, but also remove all
                                    current store owner permissions and assign
                                    owner permissions only to one specified
                                    user.
                                </li>
                            </ul>
                        }
                        rules={[
                            {
                                required: true,
                                message: "Please select a transfer type"
                            }
                        ]}
                    >
                        <Select>
                            <Select.Option value="entity_transfer">
                                Entity Transfer
                            </Select.Option>
                            <Select.Option value="ownership_transfer">
                                Ownership Transfer
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Row>
                <Row>
                    <Form.Item
                        name="activeAt"
                        label="Transfer Date & Time"
                        extra="* in the store's timezone"
                        rules={[
                            {
                                required: true,
                                message: "Please enter a date and time"
                            },
                            {
                                validator: async (_, value) =>
                                    DateTime.fromISO(value).diffNow()
                                        .milliseconds < 0
                                        ? Promise.reject(
                                              "Date must be in the future"
                                          )
                                        : Promise.resolve()
                            }
                        ]}
                    >
                        <Input type="datetime-local" />
                    </Form.Item>
                </Row>
                <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.mode !== currentValues.mode
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue("mode") === "ownership_transfer" ? (
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="ownerEmail"
                                        label="New Owner Email"
                                        extra="* a user account with this email must exist at the time of transfer. It will NOT be automatically created."
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter an email"
                                            }
                                        ]}
                                    >
                                        <Input type="email" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : null
                    }
                </Form.Item>
            </Form>
        </Modal>
    );
};

const StyledButton = styled(Button)`
    border-radius: 8px;
    margin: 0px 8px;
`;
