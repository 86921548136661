import { QueryObserverResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { Spinner } from "react-activity";
import { toast } from "sonner";

import { Alert, AlertDescription } from "src/@/components/ui/alert";
import { Button } from "src/@/components/ui/button";
import api from "src/api/rest";
import { UserInvite } from "src/core/types";

type Props = {
    invites?: UserInvite[];
    refetch: () => Promise<QueryObserverResult<UserInvite[], Error>>;
    showPromptText?: boolean;
};

export const PendingInvites = ({
    invites,
    refetch,
    showPromptText = true
}: Props) => {
    const [loadingStates, setLoadingStates] = useState(() =>
        invites ? new Array(invites.length).fill(false) : []
    );

    if (!invites || invites.length <= 0) {
        return null;
    }

    const handleRespond = async (i: number, accept: boolean) => {
        const updated = [...loadingStates];
        updated[i] = true;
        setLoadingStates(updated);
        try {
            await api.adminUserInvite.respond(invites[i].inviteNonce, accept);
            toast.success("Joined the team!");
            // need to refresh the page so we will see the store in store selector, etc
            window.location.reload();
        } catch (e) {
            console.log(e);
            const message =
                e instanceof AxiosError ? e.response?.data.message : "Failed";
            toast.error("Could not respond to invite", {
                description: message
            });
        } finally {
            updated[i] = false;
            setLoadingStates(updated);
            void refetch();
        }
    };

    return (
        <div className="my-4 w-full max-w-[900px]">
            {invites.map((invite, i) => (
                <div className="mb-4" key={i}>
                    <Alert variant="warning" className="border-yellow-200">
                        <AlertDescription>
                            {loadingStates[i] ? (
                                <div className="flex items-center justify-center">
                                    <Spinner size={12} />
                                </div>
                            ) : (
                                <div className="flex items-center justify-between max-md:flex-col">
                                    <div className="mb-4 mr-4 md:mb-0">
                                        {showPromptText && (
                                            <span className="text-sm font-semibold text-warning-a11y-light">
                                                Pending Invite:
                                            </span>
                                        )}
                                        <span>
                                            {" "}
                                            {invite.storeName} invited you to
                                            join the team.
                                        </span>
                                    </div>
                                    <div className="flex flex-nowrap">
                                        <Button
                                            onClick={() => {
                                                void handleRespond(i, true);
                                            }}
                                        >
                                            Accept
                                        </Button>
                                        <Button
                                            className="ml-4 border border-critical-accent-light bg-white text-critical-accent-light"
                                            onClick={() => {
                                                void handleRespond(i, false);
                                            }}
                                        >
                                            Decline
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </AlertDescription>
                    </Alert>
                </div>
            ))}
        </div>
    );
};
