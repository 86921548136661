import { LineChartProps } from "@tremor/react";
import clsx from "clsx";

import { formatNumber, toDollarFormatted } from "#reports/sales-summary/lib";

export const SalesSummaryTooltip: LineChartProps["customTooltip"] = ({
    payload,
    active,
    label
}) => {
    if (!active || !payload) return null;
    return (
        <div className="flex w-56 flex-col space-y-2 rounded-md border border-neutral-200 bg-neutral-50 p-2 text-small shadow-tremor-dropdown">
            <p className="font-semibold">{label}</p>
            {payload.map((category, idx) => (
                <div key={idx} className="flex flex-1 space-x-2.5">
                    <div
                        className={clsx(
                            `flex w-1 flex-col rounded`,
                            idx === 0 ? "bg-blue-500" : "bg-yellow-500"
                        )}
                    />
                    <div className="space-y-1">
                        <p className="font-semibold">
                            {idx === 0
                                ? category.payload.range
                                : category.payload.compareToRange}
                        </p>
                        <p className="text-neutral-600">Net Sales</p>
                        <p className="font-medium text-neutral-800">
                            {toDollarFormatted(
                                idx === 0
                                    ? category.payload.netSales
                                    : category.payload.compareToNetSales
                            )}
                        </p>
                        <p className="text-neutral-600">Orders</p>
                        <p className="font-medium text-neutral-800">
                            {formatNumber(
                                idx === 0
                                    ? category.payload.orders
                                    : category.payload.compareToOrders
                            )}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};
