import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { usePubNubWithToken, useUser } from "@snackpass/conversations.hooks";
import { utils } from "@snackpass/conversations.client";

import api from "src/api/rest";
import { UserInvite } from "src/core/types";
import { Routes } from "#navigation/routes";
import { getActiveStore, getUser } from "src/redux/selectors";
import { setActiveStoreNumUnreadChannels } from "src/redux/slices";

/**
 * Now that different report submenus have their own permissions
 * we need to be careful about which path we send a user to when they first click on
 * 'reports' because if we send them to a path they don't have access to they won't get to see
 * the whole menu and will be unable to choose any others
 *
 * @param hasMultipleAdminStores
 * @returns Which of the many reports routes to choose
 */
export const useDefaultReportsRoute = (hasMultipleAdminStores: boolean) => {
    const user = useSelector(getUser);
    const activeStore = useSelector(getActiveStore);

    const hasPermission = useCallback(
        (storeIds: string[] | undefined): boolean => {
            if (!storeIds || !storeIds.length || !activeStore?._id) {
                return false;
            }
            return storeIds.includes(activeStore?._id ?? "");
        },
        [activeStore?._id]
    );

    // setup our default
    let route = hasMultipleAdminStores
        ? Routes.ReportsLocationSales
        : Routes.ReportsSalesSummary;

    if (!activeStore?._id) return route;
    if (user?.snackpassPermissions.isSnackpassEmployee) return route;
    if (!user?.permissions) return route;

    // order is important! We walk through reports from bottom to top to select
    // the top most report they can see based on their permissions
    if (hasPermission(user.permissions.hasReportsLabor)) {
        route = Routes.EmployeeLaborCost;
    }
    if (hasPermission(user.permissions.hasReportsGiftCards)) {
        route = Routes.ReportsGiftCardPurchases;
    }
    if (hasPermission(user.permissions.hasReportsPromotions)) {
        route = Routes.ReportsPromotions;
    }
    if (hasPermission(user.permissions.hasReportsCustomers)) {
        route = Routes.ReportsCustomerDirectoryInsights;
    }
    if (hasPermission(user.permissions.hasReportsMenu)) {
        route = Routes.ReportsMenuItemInsights;
    }
    if (hasPermission(user.permissions.hasSalesReports)) {
        route = Routes.ReportsSalesSummary;
    }
    if (
        hasPermission(user.permissions.hasReportsLocations) &&
        hasMultipleAdminStores
    ) {
        route = Routes.ReportsLocationSales;
    }

    return route;
};

export const useInvites = (storeId?: string) => {
    const [pendingInvites, setPendingInvites] = useState<UserInvite[]>([]);

    const fetchInvites = async (storeId?: string) => {
        if (!storeId) {
            return;
        }
        try {
            const invites = await api.adminUserInvite.getInvites(storeId);
            setPendingInvites(invites.data.invites);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        void fetchInvites(storeId);
    }, [storeId]);

    return {
        pendingInvites
    };
};

export const useLoadActiveStoreNumUnreads = () => {
    const dispatch = useDispatch();
    const pubnub = usePubNubWithToken();
    const [pnUser] = useUser(pubnub, { uuid: pubnub.getUUID() });
    const isFirstLoadRef = useRef(true);

    if (pnUser && isFirstLoadRef.current) {
        dispatch(
            setActiveStoreNumUnreadChannels(utils.getUnreadChannels(pnUser))
        );
        isFirstLoadRef.current = false;
    }
};
