import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useFormContext } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { z } from "zod";

import { Card, CardContent, CardDescription } from "src/@/components/ui/card";
import { GetStoreAudienceResponse } from "src/api/graphql/queries";
import { storeDisplayNameAtom } from "#guestbook/screens/Campaigns/NewCampaign/NewCampaignAtoms";
import { constructFinalSMSMessage } from "#guestbook/screens/Campaigns/NewCampaign/constructFinalSMSMessage";
import { REACHABLE_AUDIENCE_TOOLTIP } from "#guestbook/screens/Campaigns/NewCampaign/components/reachableAudienceTooltipMessage";
import {
    COST_PER_CUSTOMER,
    getLabelForAudienceType,
    getNumberCustomersFromAudienceType,
    getNumberCustomersFromAudienceTypeIncludingUnreachable,
    newCampaignSMSFormSchema
} from "#guestbook/screens/Campaigns/NewCampaign/newCampaignFormSchema";
import CustomerPreview from "#guestbook/shared-components/phone-preview";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";
import { pluralizeNumber } from "src/utils/pluralizeNumber";
import EstimatedCostTooltip from "#guestbook/screens/Campaigns/NewCampaign/components/EstimatedCostTooltip";
import { formatCurrencyFromCents } from "#billing/domain";

type Props = {
    audienceData?: GetStoreAudienceResponse;
};

function Subtitle({ text }: { text: string }) {
    return <p className="text-muted-foreground">{text}</p>;
}

function NewSMSFormReview({ audienceData }: Props) {
    const { getValues } =
        useFormContext<z.infer<typeof newCampaignSMSFormSchema>>();
    const values = getValues();
    const storeDisplayName = useRecoilValue(storeDisplayNameAtom);
    return (
        <div className="flex flex-row flex-wrap gap-x-12 gap-y-4 px-4">
            <div className="flex max-w-lg flex-row">
                <Card>
                    <CardContent>
                        <div className="flex flex-col gap-3">
                            <p className="pt-4 text-xl font-semibold lg:pt-6">
                                Review & send your campaign
                            </p>
                            <CardDescription>
                                Make sure everything looks correct. You will not
                                be able to make changes after the campaign is
                                sent.
                            </CardDescription>
                        </div>
                        <div>
                            <div className="flex flex-wrap">
                                <div className="w-1/2 border-b py-6">
                                    <p className="font-semibold">Send Date</p>
                                    <Subtitle text="Immediately" />
                                </div>
                                <div className="w-1/2 border-b py-6">
                                    <p className="font-semibold">Segment</p>
                                    <Subtitle
                                        text={getLabelForAudienceType(
                                            values.segment
                                        )}
                                    />
                                </div>
                                <div className="w-1/2 border-b py-6">
                                    <p className="font-semibold">
                                        Segment Size{" "}
                                        <ReportsTooltip body="Segment size includes members who have not signed up for marketing consent for your store, and hence cannot be messaged" />
                                    </p>
                                    <Subtitle
                                        text={`${getNumberCustomersFromAudienceTypeIncludingUnreachable(
                                            values.segment,
                                            audienceData
                                        )}`}
                                    />
                                </div>
                                <div className="w-1/2 border-b py-6">
                                    <p className="font-semibold">
                                        Reachable Audience
                                        <ReportsTooltip
                                            body={REACHABLE_AUDIENCE_TOOLTIP}
                                        />
                                    </p>
                                    <Subtitle
                                        text={`${getNumberCustomersFromAudienceType(
                                            values.segment,
                                            audienceData
                                        )}`}
                                    />
                                </div>
                                <div className="w-1/2 border-b py-6">
                                    <p className="font-semibold">
                                        Target Audience
                                        <ReportsTooltip
                                            body={`${values.percentTarget}% of your Reachable Audience will be sent this campaign.`}
                                        />
                                    </p>
                                    <Subtitle
                                        text={pluralizeNumber(
                                            Math.round(
                                                values.estimatedCost /
                                                    COST_PER_CUSTOMER
                                            ),
                                            "customer"
                                        )}
                                    />
                                </div>
                                <div className="w-1/2 border-b py-6">
                                    <p className="font-semibold">
                                        Estimated Cost
                                        <EstimatedCostTooltip
                                            estimatedCost={values.estimatedCost}
                                        />
                                    </p>
                                    <Subtitle
                                        text={`${formatCurrencyFromCents(values.estimatedCost * 100)}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div className="flex min-w-60 max-w-md">
                <CustomerPreview>
                    <CustomerPreview.Header>
                        <div className="flex w-full flex-col items-center">
                            <div className="hidden rounded-full bg-gray-100 p-0 min-[450px]:flex md:p-1">
                                <UserCircleIcon className="h-6 w-6 stroke-gray-400" />
                            </div>
                            <span className="w-full border-b border-gray-300 p-0 text-center text-sm font-semibold md:p-1">
                                {getLabelForAudienceType(values.segment)} (
                                {Math.round(
                                    values.estimatedCost / COST_PER_CUSTOMER
                                )}
                                )
                            </span>
                        </div>
                    </CustomerPreview.Header>
                    <CustomerPreview.Body>
                        <div className="flex p-2">
                            <div className="whitespace-pre-wrap rounded-lg bg-gray-100 p-2 text-sm">
                                {constructFinalSMSMessage(
                                    storeDisplayName,
                                    values.message
                                )}
                            </div>
                        </div>
                    </CustomerPreview.Body>
                </CustomerPreview>
            </div>
        </div>
    );
}

export default NewSMSFormReview;
