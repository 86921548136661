import {
    ExportableMenuTableRow,
    MenuInsightsItemRow,
    MenuItem,
    MenuTableRow
} from "#reports/menu-item-insights/types";
import { formatNumber, toDollarFormatted } from "#reports/sales-summary/lib";

export const aggregateItemsByCategory = (
    itemInsights: MenuInsightsItemRow[] | undefined
): MenuInsightsItemRow[] | undefined =>
    itemInsights &&
    itemInsights.map((row) => {
        const aggregatedItems: MenuItem[] = row.items.reduce(
            (acc: MenuItem[], item: MenuItem) => {
                const existingItem = acc.find(
                    (aggregate) => aggregate.categoryName === item.categoryName
                );

                if (existingItem) {
                    existingItem.orders += item.orders;
                    existingItem.sales += item.sales;
                } else {
                    acc.push({
                        id: item.categoryName,
                        name: item.categoryName,
                        categoryName: item.categoryName,
                        sales: item.sales,
                        orders: item.orders
                    });
                }

                return acc;
            },
            []
        );

        return {
            date: row.date,
            items: aggregatedItems
        };
    });

export const formatForTableExport = (
    rows: MenuTableRow[]
): ExportableMenuTableRow[] =>
    rows
        .filter((e) => e.index)
        .map((e) => ({
            id: e.id,
            name: e.name as string,
            categoryName: e.categoryName as string,
            orders: formatNumber(e.orders as number),
            sales: toDollarFormatted(e.sales as number),
            index: e.index as number
        }));
