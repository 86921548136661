import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends " $fragmentName" | "__typename"
              ? T[P]
              : never;
      };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** A date and time, represented as an ISO-8601 string */
    Date: { input: string; output: string };
    DateTime: { input: any; output: any };
};

export type Addon = {
    __typename?: "Addon";
    _id: Scalars["ID"]["output"];
    hiddenForThirdParty?: Maybe<Scalars["Boolean"]["output"]>;
    integrationIds?: Maybe<IntegrationIds>;
    inventoryItems: Array<Maybe<ProductIngredientT>>;
    isArchived?: Maybe<Scalars["Date"]["output"]>;
    name: Scalars["String"]["output"];
    price: Scalars["Float"]["output"];
    soldOut: Scalars["Boolean"]["output"];
    soldOutDates?: Maybe<SoldOutRange>;
    soldOutToday: Scalars["Boolean"]["output"];
    thirdPartyPrice?: Maybe<Scalars["Float"]["output"]>;
};

export type AddonGroup = {
    __typename?: "AddonGroup";
    _id: Scalars["ID"]["output"];
    addons: Array<Maybe<Addon>>;
    hiddenForThirdParty?: Maybe<Scalars["Boolean"]["output"]>;
    integrationIds?: Maybe<IntegrationIds>;
    isArchived?: Maybe<Scalars["Date"]["output"]>;
    limit?: Maybe<Scalars["Int"]["output"]>;
    name: Scalars["String"]["output"];
    required: Scalars["Boolean"]["output"];
    supportsMultiple?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AverageSalesForHour = {
    __typename?: "AverageSalesForHour";
    averageTotalSales: Scalars["Float"]["output"];
    hour: Scalars["Int"]["output"];
};

export type Break = {
    __typename?: "Break";
    actualDuration?: Maybe<Scalars["Float"]["output"]>;
    end?: Maybe<Scalars["Date"]["output"]>;
    expectedDuration: Scalars["Int"]["output"];
    id: Scalars["ID"]["output"];
    isDeleted: Scalars["Boolean"]["output"];
    start: Scalars["Date"]["output"];
    type: BreakType;
};

export type BreakInput = {
    end?: InputMaybe<Scalars["Date"]["input"]>;
    expectedDuration: Scalars["Int"]["input"];
    id: Scalars["ID"]["input"];
    start: Scalars["Date"]["input"];
    type: BreakType;
};

export enum BreakType {
    PaidBreak = "PAID_BREAK",
    UnpaidBreak = "UNPAID_BREAK"
}

export type Campaign = {
    __typename?: "Campaign";
    approvedBy?: Maybe<CampaignApprovedBy>;
    audienceType: CampaignAudienceType;
    createdAt: Scalars["Date"]["output"];
    deliveryStatus?: Maybe<CampaignDeliveryStatus>;
    expirationDate?: Maybe<Scalars["Date"]["output"]>;
    history: Array<CampaignHistoryEvent>;
    id: Scalars["ID"]["output"];
    maxUses?: Maybe<Scalars["Int"]["output"]>;
    message: CampaignMessageData;
    metrics: CampaignMetrics;
    numberOfUsers: Scalars["Int"]["output"];
    openRate: Scalars["Float"]["output"];
    promotion?: Maybe<Scalars["ID"]["output"]>;
    reachableSizeAtCreation: Scalars["Int"]["output"];
    sections: Array<TemplateSection>;
    segmentSizeAtCreation: Scalars["Int"]["output"];
    status: CampaignStatus;
    store: Store;
    template: Template;
    type?: Maybe<CampaignType>;
    updatedAt: Scalars["Date"]["output"];
    userBreakdown: CampaignUserBreakdown;
    uses: Scalars["Int"]["output"];
};

export type CampaignApprovedBy = {
    __typename?: "CampaignApprovedBy";
    id: Scalars["String"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
};

export enum CampaignAudienceType {
    Followers = "FOLLOWERS",
    NeedsAttention = "NEEDS_ATTENTION",
    NewCustomers = "NEW_CUSTOMERS",
    RecentCustomers = "RECENT_CUSTOMERS",
    Test = "TEST",
    Vips = "VIPS"
}

export enum CampaignDeliveryStatus {
    Finished = "FINISHED",
    InProgress = "IN_PROGRESS"
}

export type CampaignEvent = {
    __typename?: "CampaignEvent";
    campaignId: Scalars["String"]["output"];
    createdAt: Scalars["Date"]["output"];
    eventType: CampaignEventType;
    platform?: Maybe<CampaignEventPlatform>;
    storeId: Scalars["String"]["output"];
    timeZone: Scalars["String"]["output"];
    user: CampaignEventUser;
};

export enum CampaignEventPlatform {
    Push = "push",
    Sms = "sms"
}

export enum CampaignEventType {
    Claimed = "claimed",
    Delivered = "delivered",
    Failed = "failed",
    Notified = "notified",
    Optout = "optout",
    Sent = "sent",
    Used = "used",
    Viewed = "viewed"
}

export type CampaignEventUser = {
    __typename?: "CampaignEventUser";
    id: Scalars["String"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
};

export type CampaignHistoryEvent = {
    __typename?: "CampaignHistoryEvent";
    createdAt?: Maybe<Scalars["Date"]["output"]>;
    type: CampaignHistoryEventType;
    updatedAt?: Maybe<Scalars["Date"]["output"]>;
    userId?: Maybe<Scalars["String"]["output"]>;
};

export enum CampaignHistoryEventType {
    Approved = "APPROVED",
    Completed = "COMPLETED",
    Created = "CREATED",
    Rejected = "REJECTED",
    Sent = "SENT"
}

export type CampaignMessage = {
    __typename?: "CampaignMessage";
    createdAt: Scalars["Date"]["output"];
    expiresAt?: Maybe<Scalars["Date"]["output"]>;
    id: Scalars["ID"]["output"];
    status?: Maybe<CampaignMessageStatus>;
    store?: Maybe<CampaignMessageStore>;
    template: Template;
    timeZone: Scalars["String"]["output"];
};

export type CampaignMessageData = {
    __typename?: "CampaignMessageData";
    body?: Maybe<Scalars["String"]["output"]>;
    title?: Maybe<Scalars["String"]["output"]>;
};

export enum CampaignMessageStatus {
    Claimed = "Claimed",
    Sent = "Sent",
    Used = "Used",
    Viewed = "Viewed"
}

export type CampaignMessageStore = {
    __typename?: "CampaignMessageStore";
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    thumbnailImageUrl?: Maybe<Scalars["String"]["output"]>;
};

export type CampaignMetrics = {
    __typename?: "CampaignMetrics";
    attributedOrders: Scalars["Int"]["output"];
    attributedSales: Scalars["Float"]["output"];
    claimCount: Scalars["Int"]["output"];
    numberOf: CampaignMetricsEventTypeCluster;
    numberOfUsers: Scalars["Int"]["output"];
    sendCount: Scalars["Int"]["output"];
    usedCount: Scalars["Int"]["output"];
    viewCount: Scalars["Int"]["output"];
};

export type CampaignMetricsChannelCluster = {
    __typename?: "CampaignMetricsChannelCluster";
    none: Scalars["Int"]["output"];
    push: Scalars["Int"]["output"];
    sms: Scalars["Int"]["output"];
};

export type CampaignMetricsEventTypeCluster = {
    __typename?: "CampaignMetricsEventTypeCluster";
    claimed: CampaignMetricsChannelCluster;
    delivered: CampaignMetricsChannelCluster;
    failed: CampaignMetricsChannelCluster;
    notified: CampaignMetricsChannelCluster;
    optout: CampaignMetricsChannelCluster;
    sent: CampaignMetricsChannelCluster;
    used: CampaignMetricsChannelCluster;
    viewed: CampaignMetricsChannelCluster;
};

export type CampaignRewardData = {
    __typename?: "CampaignRewardData";
    description: Scalars["String"]["output"];
    discount?: Maybe<Scalars["String"]["output"]>;
    expiresAt?: Maybe<Scalars["Date"]["output"]>;
    imageUrl?: Maybe<Scalars["String"]["output"]>;
    title: Scalars["String"]["output"];
};

export enum CampaignStatus {
    Approved = "APPROVED",
    Pending = "PENDING",
    Rejected = "REJECTED"
}

export enum CampaignType {
    Announcement = "ANNOUNCEMENT",
    Discount = "DISCOUNT",
    Product = "PRODUCT"
}

export type CampaignUserBreakdown = {
    __typename?: "CampaignUserBreakdown";
    users: Array<CampaignUserMetric>;
};

export type CampaignUserMetric = {
    __typename?: "CampaignUserMetric";
    events: Array<CampaignUserMetricEvent>;
    name?: Maybe<Scalars["String"]["output"]>;
};

export type CampaignUserMetricEvent = {
    __typename?: "CampaignUserMetricEvent";
    date: Scalars["Date"]["output"];
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    type: CampaignEventType;
};

export type CampaignWithReward = {
    __typename?: "CampaignWithReward";
    campaign: Campaign;
    reward?: Maybe<CampaignRewardData>;
};

export type CampaignsFilters = {
    ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    newStyle?: InputMaybe<Scalars["Boolean"]["input"]>;
    storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ClaimCampaignRewardResponse = {
    __typename?: "ClaimCampaignRewardResponse";
    rewardId: Scalars["String"]["output"];
};

export type CreateCampaignInput = {
    audienceType: CampaignAudienceType;
    dealItems?: InputMaybe<Array<InputMaybe<DealItem>>>;
    expirationDate?: InputMaybe<Scalars["Date"]["input"]>;
    isNewCampaignStyle?: InputMaybe<Scalars["Boolean"]["input"]>;
    maxUses?: InputMaybe<Scalars["Int"]["input"]>;
    numberOfUsers: Scalars["Int"]["input"];
    template: TemplateInput;
    title: Scalars["String"]["input"];
    type?: InputMaybe<CampaignType>;
};

export type CreateGiftCardInput = {
    cardLast4: Scalars["String"]["input"];
    cardTokenId: Scalars["String"]["input"];
    customerId?: InputMaybe<Scalars["String"]["input"]>;
    fromEmail: Scalars["String"]["input"];
    fromName: Scalars["String"]["input"];
    isManuallyEntered?: InputMaybe<Scalars["Boolean"]["input"]>;
    maxCreditCents: Scalars["Int"]["input"];
    note?: InputMaybe<Scalars["String"]["input"]>;
    storeId: Scalars["String"]["input"];
    toEmail?: InputMaybe<Scalars["String"]["input"]>;
    toName: Scalars["String"]["input"];
    toPhone: Scalars["String"]["input"];
    transactionSource?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateInvoiceInput = {
    amount: Scalars["Int"]["input"];
    customerEmail: Scalars["String"]["input"];
    customerName: Scalars["String"]["input"];
    description: Scalars["String"]["input"];
    salesTaxPercentage: Scalars["Float"]["input"];
};

export type CreateTableInput = {
    name: Scalars["String"]["input"];
    numSeats: Scalars["Int"]["input"];
};

export type DealItem = {
    _id?: InputMaybe<Scalars["String"]["input"]>;
    categories?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    discount?: InputMaybe<Discount>;
    name: Scalars["String"]["input"];
    productIds: Array<Scalars["String"]["input"]>;
};

export type Discount = {
    dollarsOff?: InputMaybe<Scalars["Float"]["input"]>;
    isDoublePoints?: InputMaybe<Scalars["Boolean"]["input"]>;
    newPrice?: InputMaybe<Scalars["Float"]["input"]>;
    percentOff?: InputMaybe<Scalars["Float"]["input"]>;
};

export enum DiscountType {
    DollarsOff = "DOLLARS_OFF",
    NewPrice = "NEW_PRICE",
    Percent = "PERCENT"
}

export type DiscountedProductRewardData = {
    __typename?: "DiscountedProductRewardData";
    imageUrl?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    productId: Scalars["String"]["output"];
};

export type EmployeeShiftDetails = {
    __typename?: "EmployeeShiftDetails";
    blendedWage: Scalars["Float"]["output"];
    doubleOvertimeHours: Scalars["Float"]["output"];
    employee: PartialEmployee;
    estWages: Scalars["Float"]["output"];
    overtimeHours: Scalars["Float"]["output"];
    regularHours: Scalars["Float"]["output"];
    shifts: Array<ShiftSummary>;
    totalHours: Scalars["Float"]["output"];
    weeklyOvertimeHours?: Maybe<Scalars["Float"]["output"]>;
};

export type EmployeeShiftInput = {
    breakDuration?: InputMaybe<Scalars["Int"]["input"]>;
    employeeId: Scalars["ID"]["input"];
    eventType: ShiftEventType;
    payPeriodId: Scalars["ID"]["input"];
};

export type Feature = {
    __typename?: "Feature";
    displayName: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    imageUrl?: Maybe<Scalars["String"]["output"]>;
    insertionId?: Maybe<Scalars["String"]["output"]>;
    promotion?: Maybe<Promotion>;
    source: FeatureSource;
    store?: Maybe<Store>;
    style: FeatureStyle;
    type: FeatureType;
};

export enum FeatureSource {
    Manual = "MANUAL",
    Promotion = "PROMOTION"
}

export type FeatureStyle = {
    __typename?: "FeatureStyle";
    textColor: Scalars["String"]["output"];
};

export enum FeatureType {
    Promotion = "PROMOTION",
    Store = "STORE"
}

export type FeedCampaignMessageMetadata = {
    __typename?: "FeedCampaignMessageMetadata";
    campaignId: Scalars["String"]["output"];
    campaignMessageId: Scalars["String"]["output"];
    expiresAt?: Maybe<Scalars["Date"]["output"]>;
    products?: Maybe<Array<ProductMessageData>>;
    rewards?: Maybe<Array<RewardMessageData>>;
    status?: Maybe<CampaignMessageStatus>;
    template: Template;
    type: FeedMetadataType;
};

export type FeedElement = {
    __typename?: "FeedElement";
    createdAt: Scalars["Date"]["output"];
    creator: FeedElementCreator;
    description: Scalars["String"]["output"];
    imageUrl?: Maybe<Scalars["String"]["output"]>;
    insertionId?: Maybe<Scalars["String"]["output"]>;
    metadata?: Maybe<FeedElementMetadata>;
    title: Scalars["String"]["output"];
};

export type FeedElementCreator = {
    __typename?: "FeedElementCreator";
    emoji?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["String"]["output"];
    logoUrl?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    thumbnailImageUrl?: Maybe<Scalars["String"]["output"]>;
    type: FeedElementCreatorType;
};

export enum FeedElementCreatorType {
    Store = "Store"
}

export type FeedElementMetadata =
    | FeedCampaignMessageMetadata
    | FeedFeatureMetadata;

export type FeedFeatureMetadata = {
    __typename?: "FeedFeatureMetadata";
    feature: Feature;
    type: FeedMetadataType;
};

export enum FeedMetadataType {
    CampaignMessage = "CampaignMessage",
    Feature = "Feature"
}

export type FriendStats = {
    __typename?: "FriendStats";
    /** Number of chickens hatched with friend */
    chickens: Scalars["Int"]["output"];
    /** Number of gifts user has sent to this friend */
    giftsSentToFriend: Scalars["Int"]["output"];
    /** Number of gifts friend has sent to user */
    giftsSentToMe: Scalars["Int"]["output"];
};

export type FriendStatsChain = {
    __typename?: "FriendStatsChain";
    /** Number of chickens hatched with friend */
    chickens: Scalars["Int"]["output"];
    /** Number of gifts user has sent to this friend */
    giftsSentToFriend: Scalars["Int"]["output"];
    /** Number of gifts friend has sent to user */
    giftsSentToMe: Scalars["Int"]["output"];
};

export type FulfillmentMethods = {
    __typename?: "FulfillmentMethods";
    isDelivery: Scalars["Boolean"]["output"];
    isDineIn: Scalars["Boolean"]["output"];
    isPickup: Scalars["Boolean"]["output"];
};

export type GiftCard = {
    __typename?: "GiftCard";
    activationDate?: Maybe<Scalars["DateTime"]["output"]>;
    chainId?: Maybe<Scalars["String"]["output"]>;
    code: Scalars["String"]["output"];
    createdAt: Scalars["DateTime"]["output"];
    designURL?: Maybe<Scalars["String"]["output"]>;
    encryptedPin?: Maybe<Scalars["String"]["output"]>;
    expirationDate?: Maybe<Scalars["DateTime"]["output"]>;
    firstUseDate?: Maybe<Scalars["DateTime"]["output"]>;
    fromEmail: Scalars["String"]["output"];
    fromName: Scalars["String"]["output"];
    fromUserId?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    lastUseDate?: Maybe<Scalars["DateTime"]["output"]>;
    maxCreditCents: Scalars["Int"]["output"];
    note?: Maybe<Scalars["String"]["output"]>;
    purchaseId?: Maybe<Scalars["String"]["output"]>;
    remainingCreditCents: Scalars["Int"]["output"];
    status: GiftCardStatus;
    storeId: Scalars["String"]["output"];
    stripeChargeId: Scalars["String"]["output"];
    toEmail?: Maybe<Scalars["String"]["output"]>;
    toName: Scalars["String"]["output"];
    toPhone: Scalars["String"]["output"];
    toUserId?: Maybe<Scalars["String"]["output"]>;
    type?: Maybe<GiftCardDiscriminatorGraph>;
    updatedAt: Scalars["DateTime"]["output"];
};

export enum GiftCardDiscriminatorGraph {
    DigitalGiftCard = "DIGITAL_GIFT_CARD",
    PhysicalGiftCard = "PHYSICAL_GIFT_CARD"
}

export enum GiftCardStatus {
    Active = "ACTIVE",
    Inactive = "INACTIVE",
    OutOfCredit = "OUT_OF_CREDIT",
    Redeemed = "REDEEMED",
    Refunded = "REFUNDED",
    Sent = "SENT"
}

export type IndividualMarketingConsent = {
    __typename?: "IndividualMarketingConsent";
    /** mongo id */
    id: Scalars["ID"]["output"];
    /**
     * whether a user consented to receiving SMS messages
     * from the store
     */
    marketingConsent: Scalars["Boolean"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
    referredFromId?: Maybe<Scalars["String"]["output"]>;
    referredFromType?: Maybe<Scalars["String"]["output"]>;
};

export type IntegrationIds = {
    __typename?: "IntegrationIds";
    chowly?: Maybe<Scalars["String"]["output"]>;
};

export type InventoryQuantityObject = {
    __typename?: "InventoryQuantityObject";
    amount: Scalars["Int"]["output"];
    precision: Scalars["Int"]["output"];
    unit: Scalars["String"]["output"];
};

export type Invoice = {
    __typename?: "Invoice";
    /** Amount in cents entered by restaurant partner. Customer's invoice will have this and sales tax line item. */
    amount: Scalars["Int"]["output"];
    /** Can be used to determine when Invoice was sent since creation and email sending happens simultaneously. */
    createdAt: Scalars["DateTime"]["output"];
    /** Email specified at invoice creation which is used to create Stripe Customer. Invoice sends to this email. */
    customerEmail: Scalars["String"]["output"];
    /** Name specified at invoice creation which is used to create Stripe Customer */
    customerName: Scalars["String"]["output"];
    /** Timestamp when invoice.paid webhook is received. */
    customerPaidAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Used to set description of Stripe Invoice Line Item that shows up on invoice */
    description: Scalars["String"]["output"];
    /** ID of invoice document in database */
    id: Scalars["ID"]["output"];
    /** Timestamp when Snackpass pays out the restaurant partner. Payout is amount minus agreed upon value. */
    payoutProcessedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Sales tax percentage (i.e. 7.25) provided by the restaurant partner at time of invoice creation */
    salesTaxPercentage: Scalars["Float"]["output"];
    /** ID of the corresponding invoice in Stripe Invoice API */
    stripeInvoiceId: Scalars["String"]["output"];
    /** Invoice number generated by Stripe that's present in PDF, email and payment page (i.e. EE744C66-0001) */
    stripeInvoiceNumber: Scalars["String"]["output"];
    /** Link to the PDF of the invoice */
    stripeInvoicePdf?: Maybe<Scalars["String"]["output"]>;
    /** URL of the stripe invoice */
    stripeInvoiceUrl?: Maybe<Scalars["String"]["output"]>;
    /** Total amount in cents used to create Stripe Invoice. Total amount customer sees on invoice. */
    totalAmount: Scalars["Int"]["output"];
};

export type Item = {
    __typename?: "Item";
    /**
     * An item's product can be null since products are hard-deleted.
     * When they are changed to be archived, and the products are
     * backfilled, this can be changed to a required field.
     */
    product?: Maybe<Product>;
};

export type MessageData =
    | ProductMessageData
    | RewardMessageData
    | TextMessageData;

export type MessageVariable = {
    __typename?: "MessageVariable";
    placeholder: Scalars["String"]["output"];
    type: VariableType;
    variableData: Variable;
    variableId: Scalars["String"]["output"];
};

export type MessageVariableInput = {
    placeholder: Scalars["String"]["input"];
    type: VariableType;
    variable: VariableInput;
    variableId: Scalars["String"]["input"];
};

export type MultiplePayPeriodSummaries = {
    __typename?: "MultiplePayPeriodSummaries";
    summaries: Array<PayPeriodSummary>;
};

export type Mutation = {
    __typename?: "Mutation";
    _empty?: Maybe<Scalars["String"]["output"]>;
    claimReward: ClaimCampaignRewardResponse;
    createCampaign: Campaign;
    createGiftCard: GiftCard;
    createInvoice: Invoice;
    createShiftByAdmin: Shift;
    createStoreTable: Table;
    deleteShiftByAdmin?: Maybe<Shift>;
    deleteStoreTable: Scalars["ID"]["output"];
    employeeShift: Shift;
    /**
     * Set user's consent status to IN. PurchaseId and auth token provide an
     * alternative login mechanism for users on smart receipt
     */
    optInToMarketing: Scalars["Boolean"]["output"];
    /**
     * Set user's consent status to OUT. PurchaseId and auth token provide an
     * alternative login mechanism for users on smart receipt
     */
    optOutOfMarketing: Scalars["Boolean"]["output"];
    redeemGiftCard: GiftCard;
    refundGiftCard: GiftCard;
    updateShiftByAdmin?: Maybe<Shift>;
    updateStoreTable: Table;
    verifyGiftCard: GiftCard;
    viewCampaignMessages: Array<CampaignEvent>;
};

export type MutationClaimRewardArgs = {
    campaignId: Scalars["ID"]["input"];
    campaignMessageId: Scalars["ID"]["input"];
};

export type MutationCreateCampaignArgs = {
    data: CreateCampaignInput;
    storeId: Scalars["ID"]["input"];
};

export type MutationCreateGiftCardArgs = {
    data: CreateGiftCardInput;
};

export type MutationCreateInvoiceArgs = {
    input: CreateInvoiceInput;
    storeId: Scalars["ID"]["input"];
};

export type MutationCreateShiftByAdminArgs = {
    breaks?: InputMaybe<Array<BreakInput>>;
    cashTips?: InputMaybe<Scalars["Float"]["input"]>;
    clockedInAt: Scalars["Date"]["input"];
    clockedOutAt?: InputMaybe<Scalars["Date"]["input"]>;
    employeeId: Scalars["ID"]["input"];
    note?: InputMaybe<Scalars["String"]["input"]>;
    payPeriodId: Scalars["ID"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type MutationCreateStoreTableArgs = {
    input: CreateTableInput;
    storeId: Scalars["ID"]["input"];
};

export type MutationDeleteShiftByAdminArgs = {
    shiftId: Scalars["ID"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type MutationDeleteStoreTableArgs = {
    storeId: Scalars["ID"]["input"];
    tableId: Scalars["ID"]["input"];
};

export type MutationEmployeeShiftArgs = {
    data: EmployeeShiftInput;
    shiftId: Scalars["ID"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type MutationOptInToMarketingArgs = {
    authToken?: InputMaybe<Scalars["String"]["input"]>;
    purchaseId?: InputMaybe<Scalars["String"]["input"]>;
    storeId: Scalars["ID"]["input"];
};

export type MutationOptOutOfMarketingArgs = {
    authToken?: InputMaybe<Scalars["String"]["input"]>;
    purchaseId?: InputMaybe<Scalars["String"]["input"]>;
    storeId: Scalars["ID"]["input"];
};

export type MutationRedeemGiftCardArgs = {
    code: Scalars["String"]["input"];
};

export type MutationRefundGiftCardArgs = {
    id: Scalars["ID"]["input"];
};

export type MutationUpdateShiftByAdminArgs = {
    breaks?: InputMaybe<Array<BreakInput>>;
    cashTips?: InputMaybe<Scalars["Float"]["input"]>;
    clockedInAt?: InputMaybe<Scalars["Date"]["input"]>;
    clockedOutAt?: InputMaybe<Scalars["Date"]["input"]>;
    note?: InputMaybe<Scalars["String"]["input"]>;
    shiftId: Scalars["ID"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type MutationUpdateStoreTableArgs = {
    input: UpdateTableInput;
    tableId: Scalars["ID"]["input"];
};

export type MutationVerifyGiftCardArgs = {
    code: Scalars["String"]["input"];
    pin: Scalars["String"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type MutationViewCampaignMessagesArgs = {
    campaignMessageIds: Array<Scalars["ID"]["input"]>;
};

export enum OrderReportTransactionSource {
    App = "app",
    Kiosk = "kiosk",
    Online = "online",
    ThirdParty = "thirdParty"
}

export type PartialEmployee = {
    __typename?: "PartialEmployee";
    firstName?: Maybe<Scalars["String"]["output"]>;
    id?: Maybe<Scalars["ID"]["output"]>;
    identifier?: Maybe<Scalars["String"]["output"]>;
    lastName?: Maybe<Scalars["String"]["output"]>;
    name?: Maybe<Scalars["String"]["output"]>;
    role?: Maybe<Scalars["String"]["output"]>;
    wage?: Maybe<Scalars["Float"]["output"]>;
};

export type PayPeriod = {
    __typename?: "PayPeriod";
    dailyDoubleOvertimeMultiplier?: Maybe<Scalars["Float"]["output"]>;
    dailyDoubleOvertimeThreshold?: Maybe<Scalars["Float"]["output"]>;
    dailyOvertimeMultiplier?: Maybe<Scalars["Float"]["output"]>;
    dailyOvertimeThreshold?: Maybe<Scalars["Float"]["output"]>;
    endDate: Scalars["Date"]["output"];
    id: Scalars["ID"]["output"];
    startDate: Scalars["Date"]["output"];
    storeId: Scalars["String"]["output"];
    weeklyOvertimeMultiplier?: Maybe<Scalars["Float"]["output"]>;
    weeklyOvertimeThreshold?: Maybe<Scalars["Float"]["output"]>;
};

export type PayPeriodSummary = {
    __typename?: "PayPeriodSummary";
    doubleOvertimeHours: Scalars["Float"]["output"];
    employeeShiftDetails: Array<EmployeeShiftDetails>;
    employeesWithOngoingShifts: Array<PartialEmployee>;
    endDate: Scalars["Date"]["output"];
    estWages: Scalars["Float"]["output"];
    netSales: Scalars["Float"]["output"];
    overtimeHours: Scalars["Float"]["output"];
    payPeriodId: Scalars["ID"]["output"];
    regularHours: Scalars["Float"]["output"];
    startDate: Scalars["Date"]["output"];
    timeCards: Scalars["Float"]["output"];
    totalHours: Scalars["Float"]["output"];
    weeklyOvertimeHours?: Maybe<Scalars["Float"]["output"]>;
};

export type PaySummaryByDateRange = {
    __typename?: "PaySummaryByDateRange";
    doubleOvertimeHours: Scalars["Float"]["output"];
    employeeShiftDetails: Array<EmployeeShiftDetails>;
    employeesWithOngoingShifts: Array<PartialEmployee>;
    endDate: Scalars["Date"]["output"];
    estWages: Scalars["Float"]["output"];
    netSales: Scalars["Float"]["output"];
    overtimeHours: Scalars["Float"]["output"];
    payPeriodId: Scalars["ID"]["output"];
    regularHours: Scalars["Float"]["output"];
    startDate: Scalars["Date"]["output"];
    timeCards: Scalars["Float"]["output"];
    totalHours: Scalars["Float"]["output"];
    weeklyOvertimeHours?: Maybe<Scalars["Float"]["output"]>;
};

export type PriceByWeight = {
    __typename?: "PriceByWeight";
    perUnit: Scalars["Float"]["output"];
    unit: Scalars["String"]["output"];
};

export type Product = {
    __typename?: "Product";
    addonGroups: Array<Maybe<AddonGroup>>;
    category: Scalars["String"]["output"];
    description?: Maybe<Scalars["String"]["output"]>;
    fulfillmentMethods?: Maybe<FulfillmentMethods>;
    hiddenForThirdParty?: Maybe<Scalars["Boolean"]["output"]>;
    hours?: Maybe<SnackpassHours>;
    id: Scalars["ID"]["output"];
    image?: Maybe<Scalars["String"]["output"]>;
    integrationIds?: Maybe<IntegrationIds>;
    inventoryItems?: Maybe<Array<Maybe<ProductIngredientT>>>;
    isArchived?: Maybe<Scalars["Date"]["output"]>;
    isCatering: Scalars["Boolean"]["output"];
    isTemplate: Scalars["Boolean"]["output"];
    minimumQuantity: Scalars["Int"]["output"];
    name: Scalars["String"]["output"];
    numberFeeds: Scalars["Int"]["output"];
    points?: Maybe<Scalars["Int"]["output"]>;
    pointsAfterPromotion?: Maybe<Scalars["Int"]["output"]>;
    price?: Maybe<Scalars["Float"]["output"]>;
    priceByWeight?: Maybe<PriceByWeight>;
    pun?: Maybe<Scalars["String"]["output"]>;
    purchaseCount: Scalars["Float"]["output"];
    soldOut: Scalars["Boolean"]["output"];
    soldOutDates: SoldOutRange;
    soldOutToday: Scalars["Boolean"]["output"];
    store: StoreEmbedded;
    tags: Array<Maybe<Tag>>;
    taxInfo?: Maybe<SalesTax>;
    thirdPartyPrice?: Maybe<Scalars["Float"]["output"]>;
    upsell: Scalars["Boolean"]["output"];
    viewCount: Scalars["Int"]["output"];
};

export type ProductIngredientT = {
    __typename?: "ProductIngredientT";
    inventoryItemId: Scalars["String"]["output"];
    unitsConsumed?: Maybe<InventoryQuantityObject>;
};

export type ProductMessageData = {
    __typename?: "ProductMessageData";
    imageUrl?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    productId: Scalars["String"]["output"];
};

export type ProductTemplateInput = {
    imageUrl?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    productId: Scalars["String"]["input"];
};

export type Promotion = {
    __typename?: "Promotion";
    activeTimePeriod?: Maybe<PromotionActiveTimePeriod>;
    clientShowsRemainingUses: Scalars["Boolean"]["output"];
    createdAt: Scalars["Date"]["output"];
    hours?: Maybe<PromotionHours>;
    id: Scalars["ID"]["output"];
    imageUrl?: Maybe<Scalars["String"]["output"]>;
    inWaitlistMode: Scalars["Boolean"]["output"];
    lastUsedAt?: Maybe<Scalars["Date"]["output"]>;
    maximumUses?: Maybe<Scalars["Int"]["output"]>;
    targets: Array<Maybe<PromotionTarget>>;
    totalPurchases: Scalars["Int"]["output"];
    totalUses: Scalars["Int"]["output"];
    type: PromotionType;
};

export type PromotionActiveTimePeriod = {
    __typename?: "PromotionActiveTimePeriod";
    endTime?: Maybe<Scalars["Date"]["output"]>;
    startTime?: Maybe<Scalars["Date"]["output"]>;
};

export type PromotionHours = {
    __typename?: "PromotionHours";
    eastern?: Maybe<Array<Maybe<PromotionTimeRange>>>;
    local: Array<Maybe<PromotionTimeRange>>;
    offset?: Maybe<Scalars["Int"]["output"]>;
    zone: Scalars["String"]["output"];
};

export enum PromotionTarget {
    All = "ALL",
    Campaign = "CAMPAIGN",
    FirstTime = "FIRST_TIME",
    SecondTime = "SECOND_TIME",
    Students = "STUDENTS",
    Subscribers = "SUBSCRIBERS"
}

export type PromotionTimeRange = {
    __typename?: "PromotionTimeRange";
    end?: Maybe<Scalars["Int"]["output"]>;
    start?: Maybe<Scalars["Int"]["output"]>;
};

export enum PromotionType {
    Birthday = "BIRTHDAY",
    Deal = "DEAL",
    Discount = "DISCOUNT",
    GiftCard = "GIFT_CARD",
    Party = "PARTY",
    PromoCode = "PROMO_CODE",
    Referral = "REFERRAL",
    Retargeting = "RETARGETING",
    Reward = "REWARD",
    TimeBomb = "TIME_BOMB"
}

export type Punchcard = {
    __typename?: "Punchcard";
    id: Scalars["ID"]["output"];
    pointsTotal: Scalars["Int"]["output"];
    store: Store;
};

export type Purchase = {
    __typename?: "Purchase";
    createdAt: Scalars["Date"]["output"];
    id: Scalars["ID"]["output"];
    items: Array<Maybe<Item>>;
    user: User;
};

export type Query = {
    __typename?: "Query";
    _empty?: Maybe<Scalars["String"]["output"]>;
    campaignById: CampaignWithReward;
    campaignMetrics: CampaignMetrics;
    campaignUserBreakdown: CampaignUserBreakdown;
    campaigns: Array<Campaign>;
    featureById?: Maybe<Feature>;
    featureFeed: Array<Maybe<Feature>>;
    featureFeedOLD: Array<Maybe<Feature>>;
    getMyFeed: Array<FeedElement>;
    giftCardById: GiftCard;
    giftCardByToken: GiftCard;
    giftCardsActiveByPhoneAndStore: Array<GiftCard>;
    giftCardsActiveByUser: Array<GiftCard>;
    giftCardsActiveByUserAndStore: Array<GiftCard>;
    giftCardsByStore: Array<GiftCard>;
    giftCardsByUser: Array<GiftCard>;
    giftCardsByUserAndStore: Array<GiftCard>;
    individualMarketingConsentInfo?: Maybe<IndividualMarketingConsent>;
    invoices: Array<Maybe<Invoice>>;
    me?: Maybe<User>;
    multiplePayPeriodSummaries: MultiplePayPeriodSummaries;
    payPeriodSummary: PayPeriodSummary;
    payPeriods: Array<PayPeriod>;
    paySummaryByDateRange: PaySummaryByDateRange;
    promotionById?: Maybe<Promotion>;
    storeAudience: StoreAudience;
    storeById?: Maybe<Store>;
    storeCampaigns: Array<Campaign>;
    storeOrderReports: Array<Maybe<StoreOrderReport>>;
    storeOrderReportsDashboard: StoreOrderReportDashboard;
    storeOrderReportsExport: StoreOrderReportExport;
    storeTable?: Maybe<Table>;
    storeTables?: Maybe<Array<Table>>;
    topFriendsAtChain?: Maybe<Array<Maybe<User>>>;
    topFriendsAtStore?: Maybe<Array<Maybe<User>>>;
    userById?: Maybe<User>;
    warmFeatureFeedCache?: Maybe<Scalars["String"]["output"]>;
};

export type QueryCampaignByIdArgs = {
    campaignId: Scalars["ID"]["input"];
};

export type QueryCampaignMetricsArgs = {
    campaignId: Scalars["ID"]["input"];
};

export type QueryCampaignUserBreakdownArgs = {
    campaignId: Scalars["ID"]["input"];
};

export type QueryCampaignsArgs = {
    filters: CampaignsFilters;
};

export type QueryFeatureByIdArgs = {
    id: Scalars["ID"]["input"];
};

export type QueryFeatureFeedArgs = {
    lat?: InputMaybe<Scalars["Float"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    lng?: InputMaybe<Scalars["Float"]["input"]>;
    logUserId?: InputMaybe<Scalars["String"]["input"]>;
    sessionId?: InputMaybe<Scalars["String"]["input"]>;
    viewId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryFeatureFeedOldArgs = {
    lat?: InputMaybe<Scalars["Float"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    lng?: InputMaybe<Scalars["Float"]["input"]>;
    logUserId?: InputMaybe<Scalars["String"]["input"]>;
    sessionId?: InputMaybe<Scalars["String"]["input"]>;
    viewId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetMyFeedArgs = {
    before?: InputMaybe<Scalars["Date"]["input"]>;
    lat?: InputMaybe<Scalars["Float"]["input"]>;
    limit: Scalars["Int"]["input"];
    lng?: InputMaybe<Scalars["Float"]["input"]>;
    logUserId?: InputMaybe<Scalars["String"]["input"]>;
    sessionId?: InputMaybe<Scalars["String"]["input"]>;
    since?: InputMaybe<Scalars["Date"]["input"]>;
    viewId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGiftCardByIdArgs = {
    id: Scalars["ID"]["input"];
};

export type QueryGiftCardByTokenArgs = {
    id: Scalars["ID"]["input"];
    token: Scalars["String"]["input"];
};

export type QueryGiftCardsActiveByPhoneAndStoreArgs = {
    phone: Scalars["String"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type QueryGiftCardsActiveByUserAndStoreArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryGiftCardsByStoreArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryGiftCardsByUserAndStoreArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryIndividualMarketingConsentInfoArgs = {
    authToken?: InputMaybe<Scalars["String"]["input"]>;
    purchaseId?: InputMaybe<Scalars["String"]["input"]>;
    storeId: Scalars["ID"]["input"];
};

export type QueryInvoicesArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryMultiplePayPeriodSummariesArgs = {
    endDate: Scalars["Date"]["input"];
    startDate: Scalars["Date"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type QueryPayPeriodSummaryArgs = {
    payPeriodId: Scalars["ID"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type QueryPayPeriodsArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryPaySummaryByDateRangeArgs = {
    endDate: Scalars["Date"]["input"];
    startDate: Scalars["Date"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type QueryPromotionByIdArgs = {
    id: Scalars["ID"]["input"];
};

export type QueryStoreAudienceArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryStoreByIdArgs = {
    id: Scalars["ID"]["input"];
};

export type QueryStoreCampaignsArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryStoreOrderReportsArgs = {
    endDate: Scalars["Date"]["input"];
    granularity: ReportGranularity;
    startDate: Scalars["Date"]["input"];
    storeId: Scalars["ID"]["input"];
    transactionSources?: InputMaybe<Array<OrderReportTransactionSource>>;
};

export type QueryStoreOrderReportsDashboardArgs = {
    endDate: Scalars["Date"]["input"];
    granularity: ReportGranularity;
    prevEndDate: Scalars["Date"]["input"];
    prevStartDate: Scalars["Date"]["input"];
    startDate: Scalars["Date"]["input"];
    storeId: Scalars["ID"]["input"];
    transactionSources?: InputMaybe<Array<OrderReportTransactionSource>>;
};

export type QueryStoreOrderReportsExportArgs = {
    endDate: Scalars["Date"]["input"];
    exportType: ReportExportType;
    startDate: Scalars["Date"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type QueryStoreTableArgs = {
    id: Scalars["ID"]["input"];
};

export type QueryStoreTablesArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryTopFriendsAtChainArgs = {
    id: Scalars["ID"]["input"];
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    storeIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type QueryTopFriendsAtStoreArgs = {
    id: Scalars["ID"]["input"];
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    storeId: Scalars["ID"]["input"];
};

export type QueryUserByIdArgs = {
    id: Scalars["ID"]["input"];
};

export enum ReportExportType {
    Csv = "csv",
    Xlsx = "xlsx"
}

export enum ReportGranularity {
    Daily = "daily",
    Hourly = "hourly",
    Monthly = "monthly",
    Weekly = "weekly",
    Yearly = "yearly"
}

export type RewardDiscountInput = {
    dollarsOff?: InputMaybe<Scalars["Float"]["input"]>;
    newPrice?: InputMaybe<Scalars["Float"]["input"]>;
    percentOff?: InputMaybe<Scalars["Float"]["input"]>;
    type: DiscountType;
};

export type RewardMessageData = {
    __typename?: "RewardMessageData";
    discount: RewardMessageDiscountData;
    products: Array<DiscountedProductRewardData>;
    storewide: Scalars["Boolean"]["output"];
};

export type RewardMessageDiscountData = {
    __typename?: "RewardMessageDiscountData";
    dollarsOff?: Maybe<Scalars["Float"]["output"]>;
    newPrice?: Maybe<Scalars["Float"]["output"]>;
    percentOff?: Maybe<Scalars["Float"]["output"]>;
    type: DiscountType;
};

export type RewardTemplateDiscountedProductInput = {
    imageUrl?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    productId: Scalars["String"]["input"];
};

export type RewardTemplateInput = {
    discount: RewardDiscountInput;
    products: Array<RewardTemplateDiscountedProductInput>;
    storewide: Scalars["Boolean"]["input"];
};

export type SalesData = {
    __typename?: "SalesData";
    interval: Scalars["String"]["output"];
    ordersCount: Scalars["Int"]["output"];
    totalSales: Scalars["Float"]["output"];
};

export type SalesTax = {
    __typename?: "SalesTax";
    rate: Scalars["Float"]["output"];
};

export type Shift = {
    __typename?: "Shift";
    breaks: Array<Break>;
    cashTips?: Maybe<Scalars["Float"]["output"]>;
    clockedInAt: Scalars["Date"]["output"];
    clockedOutAt?: Maybe<Scalars["Date"]["output"]>;
    employeeId: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    isDeleted: Scalars["Boolean"]["output"];
    note?: Maybe<Scalars["String"]["output"]>;
    payPeriodId: Scalars["String"]["output"];
    wage: Scalars["Float"]["output"];
};

export enum ShiftEventType {
    ClockIn = "CLOCK_IN",
    ClockOut = "CLOCK_OUT",
    PaidBreak = "PAID_BREAK",
    UnpaidBreak = "UNPAID_BREAK"
}

export type ShiftSummary = {
    __typename?: "ShiftSummary";
    breaks: Array<Break>;
    cashTips?: Maybe<Scalars["Float"]["output"]>;
    clockedInAt: Scalars["Date"]["output"];
    clockedOutAt?: Maybe<Scalars["Date"]["output"]>;
    doubleOvertimeHours: Scalars["Float"]["output"];
    employeeId: Scalars["String"]["output"];
    estWages: Scalars["Float"]["output"];
    id: Scalars["ID"]["output"];
    isDeleted: Scalars["Boolean"]["output"];
    note?: Maybe<Scalars["String"]["output"]>;
    overtimeHours: Scalars["Float"]["output"];
    payPeriodId: Scalars["String"]["output"];
    regularHours: Scalars["Float"]["output"];
    totalHours: Scalars["Float"]["output"];
    wage: Scalars["Float"]["output"];
    weeklyOvertimeHours?: Maybe<Scalars["Float"]["output"]>;
};

export type SnackpassHours = {
    __typename?: "SnackpassHours";
    eastern?: Maybe<Array<SnackpassTimeRange>>;
    local: Array<SnackpassTimeRange>;
    offset?: Maybe<Scalars["Int"]["output"]>;
    zone: Scalars["String"]["output"];
};

export type SnackpassTimeRange = {
    __typename?: "SnackpassTimeRange";
    end: Scalars["Int"]["output"];
    start: Scalars["Int"]["output"];
};

export type SoldOutRange = {
    __typename?: "SoldOutRange";
    from?: Maybe<Scalars["Date"]["output"]>;
    until?: Maybe<Scalars["Date"]["output"]>;
};

export type Store = {
    __typename?: "Store";
    address: Scalars["String"]["output"];
    delivery: Scalars["Boolean"]["output"];
    id: Scalars["ID"]["output"];
    isLive: Scalars["Boolean"]["output"];
    name: Scalars["String"]["output"];
    regionSlug: Scalars["String"]["output"];
    stats?: Maybe<StoreStats>;
    timeZone?: Maybe<Scalars["String"]["output"]>;
};

export type StoreAudience = {
    __typename?: "StoreAudience";
    followers: Scalars["Int"]["output"];
    followersWithIndividualConsent: Scalars["Int"]["output"];
    needAttentionCustomers: Scalars["Int"]["output"];
    needAttentionCustomersWithIndividualConsent: Scalars["Int"]["output"];
    newCustomers: Scalars["Int"]["output"];
    newCustomersWithIndividualConsent: Scalars["Int"]["output"];
    recentCustomers: Scalars["Int"]["output"];
    recentCustomersWithIndividualConsent: Scalars["Int"]["output"];
    testCustomers?: Maybe<Scalars["Int"]["output"]>;
    testCustomersWithIndividualConsent?: Maybe<Scalars["Int"]["output"]>;
    vipCustomers: Scalars["Int"]["output"];
};

export type StoreEmbedded = {
    __typename?: "StoreEmbedded";
    _id: Scalars["ID"]["output"];
    color: Scalars["String"]["output"];
    emoji: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    pod?: Maybe<Scalars["String"]["output"]>;
    region?: Maybe<Scalars["String"]["output"]>;
};

export type StoreOrderReport = {
    __typename?: "StoreOrderReport";
    endDate: Scalars["Date"]["output"];
    numberOfOrders: Scalars["Int"]["output"];
    snackpassCommission: Scalars["Int"]["output"];
    snackpassCreditUsed: Scalars["Int"]["output"];
    snackpassDeliveryCommission: Scalars["Int"]["output"];
    snackpassFaxFee: Scalars["Int"]["output"];
    snackpassFeesBreakdown: Array<Maybe<StoreOrderReportFee>>;
    snackpassOtherFees: Scalars["Int"]["output"];
    snackpassProcessingFee: Scalars["Int"]["output"];
    startDate: Scalars["Date"]["output"];
    storeConvenienceFee: Scalars["Int"]["output"];
    storeCreditUsed: Scalars["Int"]["output"];
    storeDeliveryFee: Scalars["Int"]["output"];
    storeDiscount: Scalars["Int"]["output"];
    storeEarnings: Scalars["Int"]["output"];
    storeFeesBreakdown: Array<Maybe<StoreOrderReportFee>>;
    storeOtherFees: Scalars["Int"]["output"];
    storePayout: Scalars["Int"]["output"];
    storeRefundAmount: Scalars["Int"]["output"];
    storeSubtotal: Scalars["Int"]["output"];
    storeTaxCollected: Scalars["Int"]["output"];
    storeTaxRemitted: Scalars["Int"]["output"];
    storeTaxWithheld: Scalars["Int"]["output"];
    storeTip: Scalars["Int"]["output"];
    storeTotal: Scalars["Int"]["output"];
    storeUpchargeAmount: Scalars["Int"]["output"];
};

export type StoreOrderReportDashboard = {
    __typename?: "StoreOrderReportDashboard";
    averageTicketPrice: Scalars["Float"]["output"];
    currSales: Scalars["Float"]["output"];
    granularData: Array<Maybe<SalesData>>;
    orders: Scalars["Int"]["output"];
    peakSalesHour: Scalars["Int"]["output"];
    percentDiffPeakSales?: Maybe<Scalars["Int"]["output"]>;
    percentDiffSales?: Maybe<Scalars["Int"]["output"]>;
    prevPeakSalesHour: Scalars["Int"]["output"];
    prevSales: Scalars["Float"]["output"];
    prevSalesDataByHour: Array<Maybe<AverageSalesForHour>>;
    salesDataByHour: Array<Maybe<AverageSalesForHour>>;
};

export type StoreOrderReportExport = {
    __typename?: "StoreOrderReportExport";
    url: Scalars["String"]["output"];
};

export type StoreOrderReportFee = {
    __typename?: "StoreOrderReportFee";
    amount: Scalars["Int"]["output"];
    name: Scalars["String"]["output"];
    quantity: Scalars["Int"]["output"];
    type: Scalars["String"]["output"];
};

export type StoreStats = {
    __typename?: "StoreStats";
    totalFollowers: Scalars["Int"]["output"];
    totalOrders: Scalars["Int"]["output"];
    totalReachableSMSCustomers: Scalars["Int"]["output"];
    totalVIPs: Scalars["Int"]["output"];
};

export type Table = {
    __typename?: "Table";
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    numSeats: Scalars["Int"]["output"];
    storeId: Scalars["ID"]["output"];
};

export type Tag = {
    __typename?: "Tag";
    _id: Scalars["ID"]["output"];
    description?: Maybe<Scalars["String"]["output"]>;
    emoji: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    scopes?: Maybe<Array<Maybe<TagScope>>>;
};

export enum TagScope {
    Products = "products",
    Stores = "stores"
}

export type Template = {
    __typename?: "Template";
    sections: Array<TemplateSection>;
    variables: Array<MessageVariable>;
};

export type TemplateDataInput = {
    body?: InputMaybe<Scalars["String"]["input"]>;
    title?: InputMaybe<Scalars["String"]["input"]>;
};

export type TemplateInput = {
    sections: Array<TemplateSectionInput>;
    variables: Array<MessageVariableInput>;
};

export type TemplateSection = {
    __typename?: "TemplateSection";
    text: Scalars["String"]["output"];
    type: TemplateSectionType;
};

export type TemplateSectionInput = {
    text: Scalars["String"]["input"];
    type: TemplateSectionType;
};

export enum TemplateSectionType {
    Body = "BODY",
    Title = "TITLE"
}

export type TextMessageData = {
    __typename?: "TextMessageData";
    message: Scalars["String"]["output"];
};

export type TextMessageInput = {
    message: Scalars["String"]["input"];
};

export type TopProduct = {
    __typename?: "TopProduct";
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
};

export type UpdateTableInput = {
    name?: InputMaybe<Scalars["String"]["input"]>;
    numSeats?: InputMaybe<Scalars["Int"]["input"]>;
};

export type User = {
    __typename?: "User";
    /** stores where this user is an admin */
    adminStores?: Maybe<Array<Maybe<Store>>>;
    email?: Maybe<Scalars["String"]["output"]>;
    firstName?: Maybe<Scalars["String"]["output"]>;
    /** Data for a friend */
    friendStats?: Maybe<FriendStats>;
    /** Data for a friend at chain */
    friendStatsChain?: Maybe<FriendStatsChain>;
    /** mongo id */
    id?: Maybe<Scalars["ID"]["output"]>;
    lastName?: Maybe<Scalars["String"]["output"]>;
    /** user's last purchase made at a store */
    lastPurchase?: Maybe<Purchase>;
    /** user's last purchase made at a chain */
    lastPurchaseChain?: Maybe<Purchase>;
    name?: Maybe<Scalars["String"]["output"]>;
    number?: Maybe<Scalars["String"]["output"]>;
    profilePicUrl?: Maybe<Scalars["String"]["output"]>;
    /** user's punchcard for a store */
    punchcard?: Maybe<Punchcard>;
    /** user's top products at a given store */
    topProducts?: Maybe<Array<Maybe<TopProduct>>>;
    /** user's top products at a given chain */
    topProductsChain?: Maybe<Array<Maybe<TopProduct>>>;
    /** firebase id */
    uid?: Maybe<Scalars["ID"]["output"]>;
};

export type UserFriendStatsArgs = {
    friendId: Scalars["ID"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type UserFriendStatsChainArgs = {
    friendId: Scalars["ID"]["input"];
    storeIds: Array<Scalars["ID"]["input"]>;
};

export type UserLastPurchaseArgs = {
    storeId: Scalars["ID"]["input"];
};

export type UserLastPurchaseChainArgs = {
    storeIds: Array<Scalars["ID"]["input"]>;
};

export type UserPunchcardArgs = {
    storeId: Scalars["ID"]["input"];
};

export type UserTopProductsArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    storeId: Scalars["ID"]["input"];
};

export type UserTopProductsChainArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    storeIds: Array<Scalars["ID"]["input"]>;
};

export type Variable = {
    __typename?: "Variable";
    data: MessageData;
    kind: VariableKind;
    label: Scalars["String"]["output"];
};

export type VariableDataInput = {
    product?: InputMaybe<ProductTemplateInput>;
    reward?: InputMaybe<RewardTemplateInput>;
    text?: InputMaybe<TextMessageInput>;
};

export type VariableInput = {
    data: VariableDataInput;
    kind: VariableKind;
    label: Scalars["String"]["input"];
};

export enum VariableKind {
    Product = "PRODUCT",
    Reward = "REWARD",
    Text = "TEXT"
}

export enum VariableType {
    Announcement = "ANNOUNCEMENT",
    Discount = "DISCOUNT",
    Product = "PRODUCT"
}

export type BaseCampaignFieldsFragment = {
    __typename?: "Campaign";
    id: string;
    status: CampaignStatus;
    deliveryStatus?: CampaignDeliveryStatus | null;
    createdAt: string;
    audienceType: CampaignAudienceType;
    numberOfUsers: number;
    message: {
        __typename?: "CampaignMessageData";
        title?: string | null;
        body?: string | null;
    };
    template: {
        __typename?: "Template";
        variables: Array<{
            __typename?: "MessageVariable";
            type: VariableType;
        }>;
    };
    metrics: { __typename?: "CampaignMetrics"; attributedSales: number };
};

export type BaseCampaignMetricsClusterFieldsFragment = {
    __typename?: "CampaignMetricsChannelCluster";
    none: number;
    push: number;
    sms: number;
};

export type BaseCampaignMetricsFieldsFragment = {
    __typename?: "CampaignMetrics";
    numberOfUsers: number;
    sendCount: number;
    viewCount: number;
    claimCount: number;
    usedCount: number;
    numberOf: {
        __typename?: "CampaignMetricsEventTypeCluster";
        claimed: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
        delivered: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
        failed: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
        notified: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
        optout: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
        sent: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
        used: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
        viewed: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
    };
};

export type BaseCampaignUserBreakdownFieldsFragment = {
    __typename?: "CampaignUserBreakdown";
    users: Array<{
        __typename?: "CampaignUserMetric";
        name?: string | null;
        events: Array<{
            __typename?: "CampaignUserMetricEvent";
            type: CampaignEventType;
            date: string;
            errorMessage?: string | null;
        }>;
    }>;
};

export type BaseCampaignRewardFieldsFragment = {
    __typename?: "CampaignRewardData";
    title: string;
    description: string;
    discount?: string | null;
    expiresAt?: string | null;
    imageUrl?: string | null;
};

export type CampaignWithMetricsFieldsFragment = {
    __typename?: "Campaign";
    id: string;
    status: CampaignStatus;
    deliveryStatus?: CampaignDeliveryStatus | null;
    createdAt: string;
    audienceType: CampaignAudienceType;
    numberOfUsers: number;
    metrics: {
        __typename?: "CampaignMetrics";
        attributedSales: number;
        numberOfUsers: number;
        sendCount: number;
        viewCount: number;
        claimCount: number;
        usedCount: number;
        numberOf: {
            __typename?: "CampaignMetricsEventTypeCluster";
            claimed: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            delivered: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            failed: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            notified: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            optout: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            sent: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            used: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            viewed: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
        };
    };
    message: {
        __typename?: "CampaignMessageData";
        title?: string | null;
        body?: string | null;
    };
    template: {
        __typename?: "Template";
        variables: Array<{
            __typename?: "MessageVariable";
            type: VariableType;
        }>;
    };
};

export type CampaignWithMetricsAndUserBreakdownFieldsFragment = {
    __typename?: "Campaign";
    segmentSizeAtCreation: number;
    reachableSizeAtCreation: number;
    id: string;
    status: CampaignStatus;
    deliveryStatus?: CampaignDeliveryStatus | null;
    createdAt: string;
    audienceType: CampaignAudienceType;
    numberOfUsers: number;
    metrics: {
        __typename?: "CampaignMetrics";
        attributedOrders: number;
        attributedSales: number;
        numberOfUsers: number;
        sendCount: number;
        viewCount: number;
        claimCount: number;
        usedCount: number;
        numberOf: {
            __typename?: "CampaignMetricsEventTypeCluster";
            claimed: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            delivered: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            failed: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            notified: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            optout: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            sent: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            used: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            viewed: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
        };
    };
    userBreakdown: {
        __typename?: "CampaignUserBreakdown";
        users: Array<{
            __typename?: "CampaignUserMetric";
            name?: string | null;
            events: Array<{
                __typename?: "CampaignUserMetricEvent";
                type: CampaignEventType;
                date: string;
                errorMessage?: string | null;
            }>;
        }>;
    };
    store: { __typename?: "Store"; id: string; name: string };
    message: {
        __typename?: "CampaignMessageData";
        title?: string | null;
        body?: string | null;
    };
    template: {
        __typename?: "Template";
        variables: Array<{
            __typename?: "MessageVariable";
            type: VariableType;
        }>;
    };
};

export type GetStoreCampaignsWithMetricsQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    newStyle?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetStoreCampaignsWithMetricsQuery = {
    __typename?: "Query";
    campaigns: Array<{
        __typename?: "Campaign";
        id: string;
        status: CampaignStatus;
        deliveryStatus?: CampaignDeliveryStatus | null;
        createdAt: string;
        audienceType: CampaignAudienceType;
        numberOfUsers: number;
        metrics: {
            __typename?: "CampaignMetrics";
            attributedSales: number;
            numberOfUsers: number;
            sendCount: number;
            viewCount: number;
            claimCount: number;
            usedCount: number;
            numberOf: {
                __typename?: "CampaignMetricsEventTypeCluster";
                claimed: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
                delivered: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
                failed: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
                notified: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
                optout: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
                sent: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
                used: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
                viewed: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
            };
        };
        message: {
            __typename?: "CampaignMessageData";
            title?: string | null;
            body?: string | null;
        };
        template: {
            __typename?: "Template";
            variables: Array<{
                __typename?: "MessageVariable";
                type: VariableType;
            }>;
        };
    }>;
};

export type GetCampaignWithRewardAndMetricsQueryVariables = Exact<{
    campaignId: Scalars["ID"]["input"];
}>;

export type GetCampaignWithRewardAndMetricsQuery = {
    __typename?: "Query";
    campaignById: {
        __typename?: "CampaignWithReward";
        campaign: {
            __typename?: "Campaign";
            segmentSizeAtCreation: number;
            reachableSizeAtCreation: number;
            id: string;
            status: CampaignStatus;
            deliveryStatus?: CampaignDeliveryStatus | null;
            createdAt: string;
            audienceType: CampaignAudienceType;
            numberOfUsers: number;
            metrics: {
                __typename?: "CampaignMetrics";
                attributedOrders: number;
                attributedSales: number;
                numberOfUsers: number;
                sendCount: number;
                viewCount: number;
                claimCount: number;
                usedCount: number;
                numberOf: {
                    __typename?: "CampaignMetricsEventTypeCluster";
                    claimed: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                    delivered: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                    failed: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                    notified: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                    optout: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                    sent: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                    used: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                    viewed: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                };
            };
            userBreakdown: {
                __typename?: "CampaignUserBreakdown";
                users: Array<{
                    __typename?: "CampaignUserMetric";
                    name?: string | null;
                    events: Array<{
                        __typename?: "CampaignUserMetricEvent";
                        type: CampaignEventType;
                        date: string;
                        errorMessage?: string | null;
                    }>;
                }>;
            };
            store: { __typename?: "Store"; id: string; name: string };
            message: {
                __typename?: "CampaignMessageData";
                title?: string | null;
                body?: string | null;
            };
            template: {
                __typename?: "Template";
                variables: Array<{
                    __typename?: "MessageVariable";
                    type: VariableType;
                }>;
            };
        };
        reward?: {
            __typename?: "CampaignRewardData";
            title: string;
            description: string;
            discount?: string | null;
            expiresAt?: string | null;
            imageUrl?: string | null;
        } | null;
    };
};

export type GetStoreStatsQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
}>;

export type GetStoreStatsQuery = {
    __typename?: "Query";
    storeById?: {
        __typename?: "Store";
        stats?: {
            __typename?: "StoreStats";
            totalReachableSMSCustomers: number;
        } | null;
    } | null;
};

export type PaySummaryByDateRangeQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    startDate: Scalars["Date"]["input"];
    endDate: Scalars["Date"]["input"];
}>;

export type PaySummaryByDateRangeQuery = {
    __typename?: "Query";
    paySummaryByDateRange: {
        __typename?: "PaySummaryByDateRange";
        payPeriodId: string;
        regularHours: number;
        overtimeHours: number;
        doubleOvertimeHours: number;
        totalHours: number;
        estWages: number;
        timeCards: number;
        netSales: number;
        startDate: string;
        endDate: string;
        employeeShiftDetails: Array<{
            __typename?: "EmployeeShiftDetails";
            regularHours: number;
            overtimeHours: number;
            doubleOvertimeHours: number;
            totalHours: number;
            estWages: number;
            blendedWage: number;
            employee: {
                __typename?: "PartialEmployee";
                id?: string | null;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                role?: string | null;
                wage?: number | null;
                identifier?: string | null;
            };
            shifts: Array<{
                __typename?: "ShiftSummary";
                id: string;
                cashTips?: number | null;
                employeeId: string;
                clockedInAt: string;
                clockedOutAt?: string | null;
                overtimeHours: number;
                regularHours: number;
                doubleOvertimeHours: number;
                totalHours: number;
                estWages: number;
                wage: number;
                note?: string | null;
                breaks: Array<{
                    __typename?: "Break";
                    id: string;
                    type: BreakType;
                    start: string;
                    end?: string | null;
                }>;
            }>;
        }>;
        employeesWithOngoingShifts: Array<{
            __typename?: "PartialEmployee";
            name?: string | null;
        }>;
    };
};

export type GetInvoicesQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
}>;

export type GetInvoicesQuery = {
    __typename?: "Query";
    invoices: Array<{
        __typename?: "Invoice";
        id: string;
        stripeInvoiceNumber: string;
        createdAt: any;
        totalAmount: number;
        customerName: string;
        customerEmail: string;
        customerPaidAt?: any | null;
        payoutProcessedAt?: any | null;
        description: string;
        amount: number;
        salesTaxPercentage: number;
        stripeInvoiceUrl: string | null;
        stripeInvoicePdf: string | null;
    } | null>;
};

export type CreateInvoiceMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    input: CreateInvoiceInput;
}>;

export type CreateInvoiceMutation = {
    __typename?: "Mutation";
    createInvoice: { __typename?: "Invoice"; id: string };
};

export type CreateStoreCampaignMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    data: CreateCampaignInput;
}>;

export type CreateStoreCampaignMutation = {
    __typename?: "Mutation";
    createCampaign: { __typename?: "Campaign"; id: string };
};

export type CreateShiftByAdminMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    employeeId: Scalars["ID"]["input"];
    payPeriodId: Scalars["ID"]["input"];
    clockedInAt: Scalars["Date"]["input"];
    clockedOutAt?: InputMaybe<Scalars["Date"]["input"]>;
    breaks?: InputMaybe<Array<BreakInput> | BreakInput>;
    cashTips?: InputMaybe<Scalars["Float"]["input"]>;
    note?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateShiftByAdminMutation = {
    __typename?: "Mutation";
    createShiftByAdmin: {
        __typename?: "Shift";
        id: string;
        employeeId: string;
        payPeriodId: string;
        clockedInAt: string;
        clockedOutAt?: string | null;
        cashTips?: number | null;
        note?: string | null;
        breaks: Array<{
            __typename?: "Break";
            id: string;
            type: BreakType;
            start: string;
            end?: string | null;
            expectedDuration: number;
        }>;
    };
};

export type UpdateShiftByAdminMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    shiftId: Scalars["ID"]["input"];
    clockedInAt: Scalars["Date"]["input"];
    clockedOutAt?: InputMaybe<Scalars["Date"]["input"]>;
    breaks?: InputMaybe<Array<BreakInput> | BreakInput>;
    cashTips?: InputMaybe<Scalars["Float"]["input"]>;
    note?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateShiftByAdminMutation = {
    __typename?: "Mutation";
    updateShiftByAdmin?: {
        __typename?: "Shift";
        id: string;
        employeeId: string;
        payPeriodId: string;
        clockedInAt: string;
        clockedOutAt?: string | null;
        cashTips?: number | null;
        note?: string | null;
        breaks: Array<{
            __typename?: "Break";
            id: string;
            type: BreakType;
            start: string;
            end?: string | null;
            expectedDuration: number;
        }>;
    } | null;
};

export type DeleteShiftByAdminMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    shiftId: Scalars["ID"]["input"];
}>;

export type DeleteShiftByAdminMutation = {
    __typename?: "Mutation";
    deleteShiftByAdmin?: { __typename?: "Shift"; id: string } | null;
};

export type GetStoreAudienceQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
}>;

export type GetStoreAudienceQuery = {
    __typename?: "Query";
    storeAudience: {
        __typename?: "StoreAudience";
        followers: number;
        recentCustomers: number;
        needAttentionCustomers: number;
        newCustomers: number;
        testCustomers?: number | null;
        followersWithIndividualConsent: number;
        recentCustomersWithIndividualConsent: number;
        newCustomersWithIndividualConsent: number;
        needAttentionCustomersWithIndividualConsent: number;
        testCustomersWithIndividualConsent?: number | null;
    };
};

export type GetTablesQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
}>;

export type GetTablesQuery = {
    __typename?: "Query";
    storeTables?: Array<{
        __typename?: "Table";
        id: string;
        name: string;
        numSeats: number;
    }> | null;
};

export type CreateTableMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    input: CreateTableInput;
}>;

export type CreateTableMutation = {
    __typename?: "Mutation";
    createStoreTable: { __typename?: "Table"; id: string };
};

export type UpdateTableMutationVariables = Exact<{
    tableId: Scalars["ID"]["input"];
    input: UpdateTableInput;
}>;

export type UpdateTableMutation = {
    __typename?: "Mutation";
    updateStoreTable: { __typename?: "Table"; id: string };
};

export type DeleteTableMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    tableId: Scalars["ID"]["input"];
}>;

export type DeleteTableMutation = {
    __typename?: "Mutation";
    deleteStoreTable: string;
};

export const BaseCampaignRewardFieldsFragmentDoc = gql`
    fragment BaseCampaignRewardFields on CampaignRewardData {
        title
        description
        discount
        expiresAt
        imageUrl
    }
`;
export const BaseCampaignFieldsFragmentDoc = gql`
    fragment BaseCampaignFields on Campaign {
        id
        status
        deliveryStatus
        createdAt
        audienceType
        numberOfUsers
        message {
            title
            body
        }
        template {
            variables {
                type
            }
        }
        metrics {
            attributedSales
        }
    }
`;
export const BaseCampaignMetricsClusterFieldsFragmentDoc = gql`
    fragment BaseCampaignMetricsClusterFields on CampaignMetricsChannelCluster {
        none
        push
        sms
    }
`;
export const BaseCampaignMetricsFieldsFragmentDoc = gql`
    fragment BaseCampaignMetricsFields on CampaignMetrics {
        numberOfUsers
        numberOf {
            claimed {
                ...BaseCampaignMetricsClusterFields
            }
            delivered {
                ...BaseCampaignMetricsClusterFields
            }
            failed {
                ...BaseCampaignMetricsClusterFields
            }
            notified {
                ...BaseCampaignMetricsClusterFields
            }
            optout {
                ...BaseCampaignMetricsClusterFields
            }
            sent {
                ...BaseCampaignMetricsClusterFields
            }
            used {
                ...BaseCampaignMetricsClusterFields
            }
            viewed {
                ...BaseCampaignMetricsClusterFields
            }
        }
        sendCount
        viewCount
        claimCount
        usedCount
    }
    ${BaseCampaignMetricsClusterFieldsFragmentDoc}
`;
export const CampaignWithMetricsFieldsFragmentDoc = gql`
    fragment CampaignWithMetricsFields on Campaign {
        ...BaseCampaignFields
        metrics {
            ...BaseCampaignMetricsFields
        }
    }
    ${BaseCampaignFieldsFragmentDoc}
    ${BaseCampaignMetricsFieldsFragmentDoc}
`;
export const BaseCampaignUserBreakdownFieldsFragmentDoc = gql`
    fragment BaseCampaignUserBreakdownFields on CampaignUserBreakdown {
        users {
            name
            events {
                type
                date
                errorMessage
            }
        }
    }
`;
export const CampaignWithMetricsAndUserBreakdownFieldsFragmentDoc = gql`
    fragment CampaignWithMetricsAndUserBreakdownFields on Campaign {
        ...CampaignWithMetricsFields
        metrics {
            attributedOrders
            attributedSales
        }
        userBreakdown {
            ...BaseCampaignUserBreakdownFields
        }
        store {
            id
            name
        }
        segmentSizeAtCreation
        reachableSizeAtCreation
    }
    ${CampaignWithMetricsFieldsFragmentDoc}
    ${BaseCampaignUserBreakdownFieldsFragmentDoc}
`;
export const GetStoreCampaignsWithMetricsDocument = gql`
    query GetStoreCampaignsWithMetrics($storeId: ID!, $newStyle: Boolean) {
        campaigns(filters: { storeId: $storeId, newStyle: $newStyle }) {
            ...CampaignWithMetricsFields
        }
    }
    ${CampaignWithMetricsFieldsFragmentDoc}
`;

/**
 * __useGetStoreCampaignsWithMetricsQuery__
 *
 * To run a query within a React component, call `useGetStoreCampaignsWithMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreCampaignsWithMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreCampaignsWithMetricsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      newStyle: // value for 'newStyle'
 *   },
 * });
 */
export function useGetStoreCampaignsWithMetricsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetStoreCampaignsWithMetricsQuery,
        GetStoreCampaignsWithMetricsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetStoreCampaignsWithMetricsQuery,
        GetStoreCampaignsWithMetricsQueryVariables
    >(GetStoreCampaignsWithMetricsDocument, options);
}
export function useGetStoreCampaignsWithMetricsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetStoreCampaignsWithMetricsQuery,
        GetStoreCampaignsWithMetricsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetStoreCampaignsWithMetricsQuery,
        GetStoreCampaignsWithMetricsQueryVariables
    >(GetStoreCampaignsWithMetricsDocument, options);
}
export type GetStoreCampaignsWithMetricsQueryHookResult = ReturnType<
    typeof useGetStoreCampaignsWithMetricsQuery
>;
export type GetStoreCampaignsWithMetricsLazyQueryHookResult = ReturnType<
    typeof useGetStoreCampaignsWithMetricsLazyQuery
>;
export type GetStoreCampaignsWithMetricsQueryResult = Apollo.QueryResult<
    GetStoreCampaignsWithMetricsQuery,
    GetStoreCampaignsWithMetricsQueryVariables
>;
export const GetCampaignWithRewardAndMetricsDocument = gql`
    query GetCampaignWithRewardAndMetrics($campaignId: ID!) {
        campaignById(campaignId: $campaignId) {
            campaign {
                ...CampaignWithMetricsAndUserBreakdownFields
            }
            reward {
                ...BaseCampaignRewardFields
            }
        }
    }
    ${CampaignWithMetricsAndUserBreakdownFieldsFragmentDoc}
    ${BaseCampaignRewardFieldsFragmentDoc}
`;

/**
 * __useGetCampaignWithRewardAndMetricsQuery__
 *
 * To run a query within a React component, call `useGetCampaignWithRewardAndMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignWithRewardAndMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignWithRewardAndMetricsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignWithRewardAndMetricsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetCampaignWithRewardAndMetricsQuery,
        GetCampaignWithRewardAndMetricsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetCampaignWithRewardAndMetricsQuery,
        GetCampaignWithRewardAndMetricsQueryVariables
    >(GetCampaignWithRewardAndMetricsDocument, options);
}
export function useGetCampaignWithRewardAndMetricsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetCampaignWithRewardAndMetricsQuery,
        GetCampaignWithRewardAndMetricsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetCampaignWithRewardAndMetricsQuery,
        GetCampaignWithRewardAndMetricsQueryVariables
    >(GetCampaignWithRewardAndMetricsDocument, options);
}
export type GetCampaignWithRewardAndMetricsQueryHookResult = ReturnType<
    typeof useGetCampaignWithRewardAndMetricsQuery
>;
export type GetCampaignWithRewardAndMetricsLazyQueryHookResult = ReturnType<
    typeof useGetCampaignWithRewardAndMetricsLazyQuery
>;
export type GetCampaignWithRewardAndMetricsQueryResult = Apollo.QueryResult<
    GetCampaignWithRewardAndMetricsQuery,
    GetCampaignWithRewardAndMetricsQueryVariables
>;
export const GetStoreStatsDocument = gql`
    query GetStoreStats($storeId: ID!) {
        storeById(id: $storeId) {
            stats {
                totalReachableSMSCustomers
            }
        }
    }
`;

/**
 * __useGetStoreStatsQuery__
 *
 * To run a query within a React component, call `useGetStoreStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreStatsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetStoreStatsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetStoreStatsQuery,
        GetStoreStatsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetStoreStatsQuery, GetStoreStatsQueryVariables>(
        GetStoreStatsDocument,
        options
    );
}
export function useGetStoreStatsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetStoreStatsQuery,
        GetStoreStatsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetStoreStatsQuery, GetStoreStatsQueryVariables>(
        GetStoreStatsDocument,
        options
    );
}
export type GetStoreStatsQueryHookResult = ReturnType<
    typeof useGetStoreStatsQuery
>;
export type GetStoreStatsLazyQueryHookResult = ReturnType<
    typeof useGetStoreStatsLazyQuery
>;
export type GetStoreStatsQueryResult = Apollo.QueryResult<
    GetStoreStatsQuery,
    GetStoreStatsQueryVariables
>;
export const PaySummaryByDateRangeDocument = gql`
    query paySummaryByDateRange(
        $storeId: ID!
        $startDate: Date!
        $endDate: Date!
    ) {
        paySummaryByDateRange(
            storeId: $storeId
            startDate: $startDate
            endDate: $endDate
        ) {
            payPeriodId
            employeeShiftDetails {
                employee {
                    id
                    name
                    firstName
                    lastName
                    role
                    wage
                    identifier
                }
                shifts {
                    id
                    cashTips
                    employeeId
                    clockedInAt
                    clockedOutAt
                    overtimeHours
                    regularHours
                    doubleOvertimeHours
                    totalHours
                    estWages
                    wage
                    note
                    breaks {
                        id
                        type
                        start
                        end
                    }
                }
                regularHours
                overtimeHours
                doubleOvertimeHours
                totalHours
                estWages
                blendedWage
            }
            employeesWithOngoingShifts {
                name
            }
            regularHours
            overtimeHours
            doubleOvertimeHours
            totalHours
            estWages
            totalHours
            regularHours
            timeCards
            netSales
            startDate
            endDate
        }
    }
`;

/**
 * __usePaySummaryByDateRangeQuery__
 *
 * To run a query within a React component, call `usePaySummaryByDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaySummaryByDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaySummaryByDateRangeQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function usePaySummaryByDateRangeQuery(
    baseOptions: Apollo.QueryHookOptions<
        PaySummaryByDateRangeQuery,
        PaySummaryByDateRangeQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PaySummaryByDateRangeQuery,
        PaySummaryByDateRangeQueryVariables
    >(PaySummaryByDateRangeDocument, options);
}
export function usePaySummaryByDateRangeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PaySummaryByDateRangeQuery,
        PaySummaryByDateRangeQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PaySummaryByDateRangeQuery,
        PaySummaryByDateRangeQueryVariables
    >(PaySummaryByDateRangeDocument, options);
}
export type PaySummaryByDateRangeQueryHookResult = ReturnType<
    typeof usePaySummaryByDateRangeQuery
>;
export type PaySummaryByDateRangeLazyQueryHookResult = ReturnType<
    typeof usePaySummaryByDateRangeLazyQuery
>;
export type PaySummaryByDateRangeQueryResult = Apollo.QueryResult<
    PaySummaryByDateRangeQuery,
    PaySummaryByDateRangeQueryVariables
>;
export const GetInvoicesDocument = gql`
    query GetInvoices($storeId: ID!) {
        invoices(storeId: $storeId) {
            id
            stripeInvoiceNumber
            createdAt
            totalAmount
            customerName
            customerEmail
            customerPaidAt
            payoutProcessedAt
            description
            amount
            salesTaxPercentage
            stripeInvoiceUrl
            stripeInvoicePdf
        }
    }
`;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetInvoicesQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetInvoicesQuery,
        GetInvoicesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(
        GetInvoicesDocument,
        options
    );
}
export function useGetInvoicesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetInvoicesQuery,
        GetInvoicesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(
        GetInvoicesDocument,
        options
    );
}
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<
    typeof useGetInvoicesLazyQuery
>;
export type GetInvoicesQueryResult = Apollo.QueryResult<
    GetInvoicesQuery,
    GetInvoicesQueryVariables
>;
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($storeId: ID!, $input: CreateInvoiceInput!) {
        createInvoice(storeId: $storeId, input: $input) {
            id
        }
    }
`;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<
    CreateInvoiceMutation,
    CreateInvoiceMutationVariables
>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateInvoiceMutation,
        CreateInvoiceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateInvoiceMutation,
        CreateInvoiceMutationVariables
    >(CreateInvoiceDocument, options);
}
export type CreateInvoiceMutationHookResult = ReturnType<
    typeof useCreateInvoiceMutation
>;
export type CreateInvoiceMutationResult =
    Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<
    CreateInvoiceMutation,
    CreateInvoiceMutationVariables
>;
export const CreateStoreCampaignDocument = gql`
    mutation CreateStoreCampaign($storeId: ID!, $data: CreateCampaignInput!) {
        createCampaign(storeId: $storeId, data: $data) {
            id
        }
    }
`;
export type CreateStoreCampaignMutationFn = Apollo.MutationFunction<
    CreateStoreCampaignMutation,
    CreateStoreCampaignMutationVariables
>;

/**
 * __useCreateStoreCampaignMutation__
 *
 * To run a mutation, you first call `useCreateStoreCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreCampaignMutation, { data, loading, error }] = useCreateStoreCampaignMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStoreCampaignMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateStoreCampaignMutation,
        CreateStoreCampaignMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateStoreCampaignMutation,
        CreateStoreCampaignMutationVariables
    >(CreateStoreCampaignDocument, options);
}
export type CreateStoreCampaignMutationHookResult = ReturnType<
    typeof useCreateStoreCampaignMutation
>;
export type CreateStoreCampaignMutationResult =
    Apollo.MutationResult<CreateStoreCampaignMutation>;
export type CreateStoreCampaignMutationOptions = Apollo.BaseMutationOptions<
    CreateStoreCampaignMutation,
    CreateStoreCampaignMutationVariables
>;
export const CreateShiftByAdminDocument = gql`
    mutation CreateShiftByAdmin(
        $storeId: ID!
        $employeeId: ID!
        $payPeriodId: ID!
        $clockedInAt: Date!
        $clockedOutAt: Date
        $breaks: [BreakInput!]
        $cashTips: Float
        $note: String
    ) {
        createShiftByAdmin(
            storeId: $storeId
            employeeId: $employeeId
            payPeriodId: $payPeriodId
            clockedInAt: $clockedInAt
            clockedOutAt: $clockedOutAt
            breaks: $breaks
            cashTips: $cashTips
            note: $note
        ) {
            id
            employeeId
            payPeriodId
            clockedInAt
            breaks {
                id
                type
                start
                end
                expectedDuration
            }
            clockedOutAt
            cashTips
            note
        }
    }
`;
export type CreateShiftByAdminMutationFn = Apollo.MutationFunction<
    CreateShiftByAdminMutation,
    CreateShiftByAdminMutationVariables
>;

/**
 * __useCreateShiftByAdminMutation__
 *
 * To run a mutation, you first call `useCreateShiftByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShiftByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShiftByAdminMutation, { data, loading, error }] = useCreateShiftByAdminMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      employeeId: // value for 'employeeId'
 *      payPeriodId: // value for 'payPeriodId'
 *      clockedInAt: // value for 'clockedInAt'
 *      clockedOutAt: // value for 'clockedOutAt'
 *      breaks: // value for 'breaks'
 *      cashTips: // value for 'cashTips'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useCreateShiftByAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateShiftByAdminMutation,
        CreateShiftByAdminMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateShiftByAdminMutation,
        CreateShiftByAdminMutationVariables
    >(CreateShiftByAdminDocument, options);
}
export type CreateShiftByAdminMutationHookResult = ReturnType<
    typeof useCreateShiftByAdminMutation
>;
export type CreateShiftByAdminMutationResult =
    Apollo.MutationResult<CreateShiftByAdminMutation>;
export type CreateShiftByAdminMutationOptions = Apollo.BaseMutationOptions<
    CreateShiftByAdminMutation,
    CreateShiftByAdminMutationVariables
>;
export const UpdateShiftByAdminDocument = gql`
    mutation UpdateShiftByAdmin(
        $storeId: ID!
        $shiftId: ID!
        $clockedInAt: Date!
        $clockedOutAt: Date
        $breaks: [BreakInput!]
        $cashTips: Float
        $note: String
    ) {
        updateShiftByAdmin(
            storeId: $storeId
            shiftId: $shiftId
            clockedInAt: $clockedInAt
            clockedOutAt: $clockedOutAt
            breaks: $breaks
            cashTips: $cashTips
            note: $note
        ) {
            id
            employeeId
            payPeriodId
            clockedInAt
            breaks {
                id
                type
                start
                end
                expectedDuration
            }
            clockedOutAt
            cashTips
            note
        }
    }
`;
export type UpdateShiftByAdminMutationFn = Apollo.MutationFunction<
    UpdateShiftByAdminMutation,
    UpdateShiftByAdminMutationVariables
>;

/**
 * __useUpdateShiftByAdminMutation__
 *
 * To run a mutation, you first call `useUpdateShiftByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftByAdminMutation, { data, loading, error }] = useUpdateShiftByAdminMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      shiftId: // value for 'shiftId'
 *      clockedInAt: // value for 'clockedInAt'
 *      clockedOutAt: // value for 'clockedOutAt'
 *      breaks: // value for 'breaks'
 *      cashTips: // value for 'cashTips'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateShiftByAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateShiftByAdminMutation,
        UpdateShiftByAdminMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateShiftByAdminMutation,
        UpdateShiftByAdminMutationVariables
    >(UpdateShiftByAdminDocument, options);
}
export type UpdateShiftByAdminMutationHookResult = ReturnType<
    typeof useUpdateShiftByAdminMutation
>;
export type UpdateShiftByAdminMutationResult =
    Apollo.MutationResult<UpdateShiftByAdminMutation>;
export type UpdateShiftByAdminMutationOptions = Apollo.BaseMutationOptions<
    UpdateShiftByAdminMutation,
    UpdateShiftByAdminMutationVariables
>;
export const DeleteShiftByAdminDocument = gql`
    mutation DeleteShiftByAdmin($storeId: ID!, $shiftId: ID!) {
        deleteShiftByAdmin(storeId: $storeId, shiftId: $shiftId) {
            id
        }
    }
`;
export type DeleteShiftByAdminMutationFn = Apollo.MutationFunction<
    DeleteShiftByAdminMutation,
    DeleteShiftByAdminMutationVariables
>;

/**
 * __useDeleteShiftByAdminMutation__
 *
 * To run a mutation, you first call `useDeleteShiftByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShiftByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShiftByAdminMutation, { data, loading, error }] = useDeleteShiftByAdminMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      shiftId: // value for 'shiftId'
 *   },
 * });
 */
export function useDeleteShiftByAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteShiftByAdminMutation,
        DeleteShiftByAdminMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteShiftByAdminMutation,
        DeleteShiftByAdminMutationVariables
    >(DeleteShiftByAdminDocument, options);
}
export type DeleteShiftByAdminMutationHookResult = ReturnType<
    typeof useDeleteShiftByAdminMutation
>;
export type DeleteShiftByAdminMutationResult =
    Apollo.MutationResult<DeleteShiftByAdminMutation>;
export type DeleteShiftByAdminMutationOptions = Apollo.BaseMutationOptions<
    DeleteShiftByAdminMutation,
    DeleteShiftByAdminMutationVariables
>;
export const GetStoreAudienceDocument = gql`
    query GetStoreAudience($storeId: ID!) {
        storeAudience(storeId: $storeId) {
            followers
            recentCustomers
            needAttentionCustomers
            newCustomers
            testCustomers
            followersWithIndividualConsent
            recentCustomersWithIndividualConsent
            newCustomersWithIndividualConsent
            needAttentionCustomersWithIndividualConsent
            testCustomersWithIndividualConsent
        }
    }
`;

/**
 * __useGetStoreAudienceQuery__
 *
 * To run a query within a React component, call `useGetStoreAudienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreAudienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreAudienceQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetStoreAudienceQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetStoreAudienceQuery,
        GetStoreAudienceQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetStoreAudienceQuery,
        GetStoreAudienceQueryVariables
    >(GetStoreAudienceDocument, options);
}
export function useGetStoreAudienceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetStoreAudienceQuery,
        GetStoreAudienceQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetStoreAudienceQuery,
        GetStoreAudienceQueryVariables
    >(GetStoreAudienceDocument, options);
}
export type GetStoreAudienceQueryHookResult = ReturnType<
    typeof useGetStoreAudienceQuery
>;
export type GetStoreAudienceLazyQueryHookResult = ReturnType<
    typeof useGetStoreAudienceLazyQuery
>;
export type GetStoreAudienceQueryResult = Apollo.QueryResult<
    GetStoreAudienceQuery,
    GetStoreAudienceQueryVariables
>;
export const GetTablesDocument = gql`
    query GetTables($storeId: ID!) {
        storeTables(storeId: $storeId) {
            id
            name
            numSeats
        }
    }
`;

/**
 * __useGetTablesQuery__
 *
 * To run a query within a React component, call `useGetTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTablesQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetTablesQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetTablesQuery,
        GetTablesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetTablesQuery, GetTablesQueryVariables>(
        GetTablesDocument,
        options
    );
}
export function useGetTablesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetTablesQuery,
        GetTablesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetTablesQuery, GetTablesQueryVariables>(
        GetTablesDocument,
        options
    );
}
export type GetTablesQueryHookResult = ReturnType<typeof useGetTablesQuery>;
export type GetTablesLazyQueryHookResult = ReturnType<
    typeof useGetTablesLazyQuery
>;
export type GetTablesQueryResult = Apollo.QueryResult<
    GetTablesQuery,
    GetTablesQueryVariables
>;
export const CreateTableDocument = gql`
    mutation CreateTable($storeId: ID!, $input: CreateTableInput!) {
        createStoreTable(storeId: $storeId, input: $input) {
            id
        }
    }
`;
export type CreateTableMutationFn = Apollo.MutationFunction<
    CreateTableMutation,
    CreateTableMutationVariables
>;

/**
 * __useCreateTableMutation__
 *
 * To run a mutation, you first call `useCreateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTableMutation, { data, loading, error }] = useCreateTableMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTableMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateTableMutation,
        CreateTableMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateTableMutation,
        CreateTableMutationVariables
    >(CreateTableDocument, options);
}
export type CreateTableMutationHookResult = ReturnType<
    typeof useCreateTableMutation
>;
export type CreateTableMutationResult =
    Apollo.MutationResult<CreateTableMutation>;
export type CreateTableMutationOptions = Apollo.BaseMutationOptions<
    CreateTableMutation,
    CreateTableMutationVariables
>;
export const UpdateTableDocument = gql`
    mutation UpdateTable($tableId: ID!, $input: UpdateTableInput!) {
        updateStoreTable(tableId: $tableId, input: $input) {
            id
        }
    }
`;
export type UpdateTableMutationFn = Apollo.MutationFunction<
    UpdateTableMutation,
    UpdateTableMutationVariables
>;

/**
 * __useUpdateTableMutation__
 *
 * To run a mutation, you first call `useUpdateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTableMutation, { data, loading, error }] = useUpdateTableMutation({
 *   variables: {
 *      tableId: // value for 'tableId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTableMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTableMutation,
        UpdateTableMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateTableMutation,
        UpdateTableMutationVariables
    >(UpdateTableDocument, options);
}
export type UpdateTableMutationHookResult = ReturnType<
    typeof useUpdateTableMutation
>;
export type UpdateTableMutationResult =
    Apollo.MutationResult<UpdateTableMutation>;
export type UpdateTableMutationOptions = Apollo.BaseMutationOptions<
    UpdateTableMutation,
    UpdateTableMutationVariables
>;
export const DeleteTableDocument = gql`
    mutation DeleteTable($storeId: ID!, $tableId: ID!) {
        deleteStoreTable(storeId: $storeId, tableId: $tableId)
    }
`;
export type DeleteTableMutationFn = Apollo.MutationFunction<
    DeleteTableMutation,
    DeleteTableMutationVariables
>;

/**
 * __useDeleteTableMutation__
 *
 * To run a mutation, you first call `useDeleteTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTableMutation, { data, loading, error }] = useDeleteTableMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      tableId: // value for 'tableId'
 *   },
 * });
 */
export function useDeleteTableMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteTableMutation,
        DeleteTableMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteTableMutation,
        DeleteTableMutationVariables
    >(DeleteTableDocument, options);
}
export type DeleteTableMutationHookResult = ReturnType<
    typeof useDeleteTableMutation
>;
export type DeleteTableMutationResult =
    Apollo.MutationResult<DeleteTableMutation>;
export type DeleteTableMutationOptions = Apollo.BaseMutationOptions<
    DeleteTableMutation,
    DeleteTableMutationVariables
>;
