/** @jsxImportSource @emotion/react */
import React from "react";

import { Input } from "src/@/components/ui/input";
import { Label } from "src/@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "src/@/components/ui/radio-group";
import {
    PauseReason,
    SelectedOption,
    SelectOption
} from "#dashboard/components/pause-orders/use-toggle-orders";

type ReasonInputProps = {
    options: typeof PauseReason;
    selectedOption: SelectedOption;
    selectOption: SelectOption;
    customReason: string;
    setCustomReason: (reason: string) => void;
};

export const ReasonInput = ({
    options,
    selectedOption,
    selectOption,
    customReason,
    setCustomReason
}: ReasonInputProps) => (
    <div className="flex flex-col lg:w-max">
        <h6 className="my-4">Reason</h6>
        <RadioGroup
            value={selectedOption ?? undefined}
            onValueChange={selectOption}
            className="grid grid-cols-2 items-center gap-4"
        >
            {Object.values(options).map((option) => (
                <div className="flex items-center space-x-2" key={option}>
                    <RadioGroupItem value={option} id={option} />
                    <Label htmlFor={option}>{option}</Label>
                </div>
            ))}
        </RadioGroup>
        {selectedOption === PauseReason.Other && (
            <Input
                className="my-3 text-neutral-950"
                type="text"
                placeholder="Enter custom reason"
                value={customReason}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setCustomReason(e.currentTarget.value);
                }}
            />
        )}
    </div>
);
