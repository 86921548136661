import { z } from "zod";

/**
 * Admin
 */
export const AdminNoteSchema = z.string().min(5);
export const ConfirmStoreSchema = z.boolean().refine((val) => val);
export const AdminHoldSchema = z.object({
    adminNote: AdminNoteSchema,
    confirmStore: ConfirmStoreSchema
});

export const AdminEnableSchema = z.object({
    adminNote: AdminNoteSchema.nullish(),
    confirmStore: ConfirmStoreSchema
});

/**
 * Shared
 */
const MongoObjectIdRegex = /[0-9a-fA-F]{24}/i;
const ProvidedLiteral = z.literal("Provided");
const PendingLiteral = z.literal("Pending");

const poBoxSchema = /P.?O.? {0,}Box/i;
export const Line1Schema = z
    .union([z.string().min(3), PendingLiteral])
    .refine((val) => !poBoxSchema.test(val));
export const Line2Schema = z
    .union([z.string().min(0), z.string().nullish(), PendingLiteral])
    .optional();
export const CitySchema = z.union([z.string().min(3), PendingLiteral]);
export const StateSchema = z.union([z.string().length(2), PendingLiteral]);
export const PostalCodeSchema = z.union([
    z.string().regex(/^\d{5}$/),
    PendingLiteral
]);
export const CountrySchema = z.string().length(2).default("US");
export const AddressSchema = z.object({
    line1: Line1Schema,
    line2: Line2Schema.optional(),
    city: CitySchema,
    state: StateSchema,
    postalCode: PostalCodeSchema,
    country: CountrySchema
});

export const PhoneSchema = z.union([
    z.string().regex(/^\d{3}[ -]?\d{3}[ -]?\d{4}$/), // 301-996-8000
    z.string().regex(/^\(\d{3}\)[ -]?\d{3}[ -]?\d{4}$/), // (301) 996 8000
    PendingLiteral
]);

// Note: This simply checks for a type on the front/back object
//       It would probably be better to check for a uint8/buffer array
export const FrontSchema = z.string();
export const BackSchema = z.string();
export const DocumentSchema = z.object({
    frontCheck: FrontSchema,
    backCheck: BackSchema
});

/**
 * Company
 */
// Note: The "Provided" case occurs for hidden encrypted values
//       "Pending" is waiting for stripe to verify and accept.
export const CompanyNameSchema = z.union([z.string(), z.literal("Pending")]);
export const CompanyDbaSchema = z.union([
    z.string().min(0),
    z.string().nullish(),
    PendingLiteral
]);

export const CompanyTaxIdSchema = z.union([
    z.string().regex(/^\d{2}-?\d{7}$/), // EIN
    z.string().regex(/^\d{3}-\d{2}-\d{4}$/), // SSN
    ProvidedLiteral
]);
export const CompanyUrlSchema = z.union([ProvidedLiteral, z.string().url()]);

export const CompanySchema = z.object({
    name: CompanyNameSchema,
    dba: CompanyDbaSchema.optional(),
    taxId: CompanyTaxIdSchema,
    address: AddressSchema.required(),
    phone: PhoneSchema,
    url: CompanyUrlSchema,
    document: DocumentSchema.optional()
});

/**
 * Account
 */
export const RoutingNumberSchema = z.union([
    z.string().length(9),
    ProvidedLiteral
]);
export const AccountNumberSchema = z.union([
    z
        .string()
        .min(4)
        .regex(/^(\d{4,}|[*]{1,}[\d]{4})$/),
    ProvidedLiteral
]);
export const HasAccountSchema = z.object({
    routingNumber: RoutingNumberSchema,
    accountNumber: AccountNumberSchema
});
export const AccountSchema = z.object({
    routingNumber: RoutingNumberSchema,
    accountNumber: AccountNumberSchema,
    confirmAccountNumber: AccountNumberSchema
});

export const AccountSchemaWithDocument = z.object({
    routingNumber: RoutingNumberSchema,
    accountNumber: AccountNumberSchema,
    confirmAccountNumber: AccountNumberSchema,
    frontCheck: FrontSchema
});

/**
 * Representative
 */
/**
 * This regex should allow for [a-zA-Z0-9] in any quantity, but
 * limits the special characters to only ['-,. ] and one instance,
 *  except for a ". " combination, such as "St. John's"
 *
 *  Also, cannot start with symbols.
 */
export const ValidNameRegex = /^(?:[a-zA-Z0-9]+['-, ]?\.? ?[a-zA-Z0-9]*)*$/;
export const NameSchema = z.string().min(1).regex(ValidNameRegex);
export const EmailSchema = z.string().email();
export const TitleSchema = z
    .string()
    .regex(/^[a-zA-Z0-9]+(?:[-., ]?[a-zA-Z0-9]+)*$/);
export const Ownership = z.number().min(0).max(100);
export const BirthYearSchema = z.number();
export const BirthMonthSchema = z.number();
export const BirthDaySchema = z.number();
export const DateOfBirthSchema = z.object({
    day: BirthDaySchema,
    month: BirthMonthSchema,
    year: BirthYearSchema
});
export const IdNumberSchema = z.union([
    z.string().regex(/^\d{3}-?\d{2}-?\d{4}$/),
    z.string().regex(/^\d{9}$/),
    ProvidedLiteral
]);
export const PersonSchema = z.object({
    firstName: NameSchema,
    lastName: NameSchema,
    email: EmailSchema,
    phone: PhoneSchema,
    address: AddressSchema.required(),
    dateOfBirth: DateOfBirthSchema.required(),
    idNumber: IdNumberSchema,
    title: TitleSchema,
    percentOwnership: Ownership.optional(),
    owner: z.boolean().default(false),
    executive: z.boolean().default(false),
    director: z.boolean().default(false),
    representative: z.boolean().default(false)
});

export const PersonMongoIdSchema = z.string().regex(MongoObjectIdRegex); // person verification mongo id
export const ConfirmSchema = z.boolean().refine((v) => v);
export const RemovePersonSchema = z.object({
    removePersonId: PersonMongoIdSchema,
    confirmRemove: ConfirmSchema
});
export const SetPrimarySchema = z.object({
    personId: PersonMongoIdSchema,
    confirm: ConfirmSchema
});
