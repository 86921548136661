import React, { useContext } from "react";

import SettingsSection from "#devices/components/EditDrawer/SettingsSection";
import Select from "#devices/components/FormControls/Select";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";

type Option = { value: string | number; label: string };

type SelectFieldProps = {
    id: string;
    label: string;
    description: string;
    options: Option[];
    field: string;
    prepStationFormat?: boolean;
    handleSelect?: (value: string) => void;
};

export const SelectField = ({
    id,
    label,
    description,
    options,
    field,
    prepStationFormat,
    handleSelect
}: SelectFieldProps) => {
    const { device, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);
    const disabled =
        prepStationFormat === undefined ? false : !prepStationFormat;
    if (!updatedDevice?.deviceDetails) {
        updatedDevice.deviceDetails = {};
    }

    const value = (updatedDevice?.deviceDetails[field] ??
        //@ts-ignore
        device?.deviceDetails[field] ??
        "NONE_SELECTED") as string | number;

    return (
        <SettingsSection label={label} description={description}>
            <Select
                id={id}
                value={value}
                options={options}
                onChange={(e) => {
                    handleSelect?.(e.target.value);
                    updateDeviceField(field, e.target.value);
                }}
                disabled={disabled}
            />
        </SettingsSection>
    );
};
