import { getUser } from "@snackpass/accounting";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export function useHideIntercomLauncher() {
    const user = useSelector(getUser);
    useEffect(() => {
        window.Intercom("update", {
            hide_default_launcher: true
        });
        const timeout = setTimeout(() => {
            window.Intercom("update", {
                hide_default_launcher: true
            });
        }, 1000);
        return () => clearTimeout(timeout);
    }, [user?.uid]);
}
