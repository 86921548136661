import { useState, useCallback } from "react";
import { NumberInput } from "@tremor/react";
import { toast } from "sonner";
import { ReloadIcon } from "@radix-ui/react-icons";

import api from "src/api/rest";
import { Button } from "src/@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "src/@/components/ui/dialog";
import { CardTitle } from "src/@/components/ui/card";

const StoreCreditInput = ({
    punchcardId,
    credit,
    modalOpen,
    setModalOpen,
    refetch
}: {
    punchcardId: string;
    credit: number;
    modalOpen: boolean;
    setModalOpen: (isOpen: boolean) => void;
    refetch: () => void;
}) => {
    const [storeCredit, setStoreCredit] = useState<number | null>(credit);
    const [deltaDisplay, setDeltaDisplay] = useState("");
    const [inputChanged, setInputChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const modifyPoints = useCallback(
        async (credit: number) => {
            if (!punchcardId) {
                toast.error("Couldn't update points. No punchcard ID found.");
                return;
            }
            try {
                setIsLoading(true);
                await api.punchCards.update(punchcardId, {
                    credit: credit
                });
                toast.success("Store credit updated successfully");
            } catch (e) {
                console.error("Error updating store credit:", e);
                toast.error(
                    "Could not update store credit. Please try again later."
                );
            } finally {
                setIsLoading(false);
                setStoreCredit(credit);
                setDeltaDisplay("");
                await refetch();
            }
        },
        [punchcardId, refetch]
    );

    const handleSaveClick = useCallback(async () => {
        try {
            if (!inputChanged) {
                toast.error("Nothing was changed.");
                return;
            }
            if (storeCredit !== null) {
                await modifyPoints(storeCredit);
            } else {
                setStoreCredit(credit);
                toast.info("No new store credit value entered.");
            }
            setInputChanged(false);
        } catch (error) {
            console.error("Error in handleSaveClick:", error);
            toast.error(
                "An error occurred while saving. Please try again later."
            );
        }
    }, [credit, inputChanged, modifyPoints, storeCredit]);

    const handleNewBalance = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value.trim();
            if (inputValue === "") {
                setStoreCredit(null);
                setInputChanged(credit !== null ? true : false);
                setDeltaDisplay("");
            } else if (!isNaN(Number(inputValue)) && credit !== null) {
                setStoreCredit(Number(Number(inputValue).toFixed(2)));
                setInputChanged(Number(inputValue) !== credit);
                setDeltaDisplay(Number(Number(inputValue) - credit).toFixed(2));
            }
        },
        [credit]
    );

    const handleDeltaBalance = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value.trim();
            if (inputValue === "") {
                setDeltaDisplay("");
                setStoreCredit(credit);
                setInputChanged(false);
            } else if (!isNaN(Number(inputValue)) && credit !== null) {
                const newBalance = (credit + Number(inputValue)).toFixed(2);
                setStoreCredit(Number(newBalance));
                setDeltaDisplay(inputValue);
                setInputChanged(Number(newBalance) !== credit);
            }
        },
        [credit]
    );

    const editModalOpenChange = useCallback(
        (open: boolean) => {
            setModalOpen(open);
            setStoreCredit(credit);
            setInputChanged(false);
            setDeltaDisplay("");
        },
        [credit, setModalOpen]
    );

    return (
        <Dialog open={modalOpen} onOpenChange={editModalOpenChange}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Adjust Store Credit</DialogTitle>
                    <DialogDescription>
                        Please enter an adjustment amount or a new value.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex items-center space-x-4">
                    <div className="flex w-full items-start">
                        <CardTitle className="text-base font-medium">
                            Adjust By
                        </CardTitle>
                    </div>
                    <NumberInput
                        icon={() => <span className="pl-3 text-small">$</span>}
                        placeholder="0"
                        min={-parseFloat(credit.toFixed(2))}
                        step={0.01}
                        className="ml-96 min-w-48 flex-1 [&>input]:pl-2"
                        value={deltaDisplay}
                        onChange={handleDeltaBalance}
                        onBlur={() => {
                            setDeltaDisplay(
                                Number(deltaDisplay ?? 0).toFixed(2)
                            );
                        }}
                        tabIndex={-1} // prevents autofocus when modal opens
                    />
                </div>
                <div className="flex items-center space-x-4">
                    <div className="flex w-full items-start">
                        <CardTitle className="text-base font-medium">
                            New Store Credit
                        </CardTitle>
                    </div>
                    <NumberInput
                        icon={() => <span className="pl-3 text-small">$</span>}
                        min={0}
                        placeholder="0"
                        step={0.01}
                        className="ml-12 min-w-48 flex-1 [&>input]:pl-2"
                        value={String(storeCredit) ?? ""}
                        onChange={(e) => handleNewBalance(e)}
                        tabIndex={-1} // prevents autofocus when modal opens
                    />
                </div>
                <DialogFooter>
                    <Button
                        disabled={isLoading}
                        onClick={async () => {
                            await handleSaveClick();
                            setModalOpen(false);
                        }}
                        className="w-full"
                    >
                        {isLoading && (
                            <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Adjust Store Credit
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
export default StoreCreditInput;
