import { useCallback } from "react";
import { useSelector } from "react-redux";

import { trackSegmentEvent } from "#utils/segment";
import { getActiveStore, useActiveUser } from "src/redux/selectors";

function useTrackCampaignSegmentEvent() {
    const activeStore = useSelector(getActiveStore);
    const user = useActiveUser();
    const trackActiveStore = useCallback(
        (eventType: string, extraData?: Record<string, string>) => {
            if (activeStore) {
                trackSegmentEvent(eventType, {
                    store_id: activeStore._id,
                    store_name: activeStore.name,
                    user_id: user?._id,
                    ...extraData
                });
            }
        },
        [activeStore, user?._id]
    );
    return trackActiveStore;
}

export default useTrackCampaignSegmentEvent;
