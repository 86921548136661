import React, { useEffect, useState } from "react";
import styled from "styled-components";

import DropDownSelect from "#reusable/select/dropdown";
import { ReactComponent as Plus } from "src/assets/icons/plus.svg";
import { ReactComponent as DeleteRed } from "src/assets/icons/delete-red.svg";

import { TimePicker } from "antd";
import type { Moment } from "moment";
import moment from "moment";
import "antd/dist/antd.css";
import { IHoursSchema } from "@snackpass/snackpass-types";

import colors from "#reusable/colors/colors.json";
import { SpecialHoursItemType, format12 } from "#reusable/special-hours/helper";
import { DAY_OPTIONS } from "#menu-editor/multi-menus/screens/multi-menu-settings/utils/time-helpers";

type SelectedDayOfWeekOptionType = {
    label: string;
    value: number;
};

type IconProps = {
    onClick: Function;
};

const IconWrapper = styled.div<IconProps>`
    display: flex;
    margin-left: 0.1rem;
    border: 1px solid ${colors["neutral-400"]};
    height: 36px;
    width: 36px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`;

type HourProps = {
    hours?: IHoursSchema | null;
    menuHours: SpecialHoursItemType[];
    setMenuHours: React.Dispatch<React.SetStateAction<SpecialHoursItemType[]>>;
    removeRow: Function;
    updateRowHours: Function;
    id: number;
    dayOfWeek: number;
    start?: Moment | null;
    end?: Moment | null;
};

export const MobileMenuHourRow: React.FC<HourProps> = ({
    menuHours,
    setMenuHours,
    removeRow,
    updateRowHours,
    id,
    dayOfWeek,
    start,
    end
}) => {
    const [selectedDay, setSelectedDay] = React.useState(DAY_OPTIONS[0]);
    const [startTime, setStartTime] = useState<Moment | null>(null);
    const [endTime, setEndTime] = useState<Moment | null>(null);

    useEffect(() => {
        setSelectedDay(
            dayOfWeek
                ? DAY_OPTIONS.filter((option) => option.value === dayOfWeek)[0]
                : DAY_OPTIONS[0]
        );
        setStartTime(start ?? null);
        setEndTime(end ?? null);
    }, [menuHours]);

    const handleStartTime = (dateObject: moment.Moment | null): void => {
        setStartTime(moment(dateObject, format12));
        updateRowHours(id, moment(dateObject, format12), endTime);
    };
    const handleEndTime = (dateObject: moment.Moment | null): void => {
        setEndTime(moment(dateObject, format12));
        updateRowHours(id, start, moment(dateObject, format12));
    };

    return (
        <>
            <div className="my-2 flex flex-row items-start justify-between">
                <DropDownSelect
                    mr={1}
                    square
                    placeholder={"Day"}
                    options={DAY_OPTIONS}
                    value={selectedDay}
                    onChange={(selectedOption: SelectedDayOfWeekOptionType) => {
                        setSelectedDay(selectedOption);
                        setMenuHours((existingMenus: SpecialHoursItemType[]) =>
                            existingMenus.map(
                                (menu: SpecialHoursItemType, j: number) =>
                                    j === id
                                        ? {
                                              ...menu,
                                              dayOfWeek: selectedOption.value
                                          }
                                        : menu
                            )
                        );
                        updateRowHours(id, startTime, endTime);
                    }}
                ></DropDownSelect>
                <div className="flex">
                    <IconWrapper
                        onClick={() => {
                            setMenuHours([
                                ...menuHours,
                                {
                                    id: menuHours[menuHours.length - 1].id + 1,
                                    dayOfWeek:
                                        (selectedDay.value + 1) %
                                        DAY_OPTIONS.length,
                                    time: { start: start, end: end }
                                }
                            ]);
                        }}
                    >
                        <Plus className="h-3 w-3" />
                    </IconWrapper>
                    <IconWrapper
                        onClick={() => {
                            removeRow(id);
                        }}
                    >
                        <DeleteRed />
                    </IconWrapper>
                </div>
            </div>
            <div className="mb-4 flex flex-row items-start justify-between">
                <TimePicker
                    size="large"
                    value={moment(startTime, format12)}
                    placeholder="Start Time"
                    defaultValue={moment("12:00 am", format12)}
                    format={format12}
                    style={{ borderRadius: "8px", flex: "1" }}
                    onChange={handleStartTime}
                    inputReadOnly={true}
                />
                <div className="flex h-9 items-center justify-center px-1">
                    -
                </div>
                <TimePicker
                    size="large"
                    placeholder="Start Time"
                    value={moment(endTime, format12)}
                    format={format12}
                    style={{ borderRadius: "8px", flex: "1" }}
                    defaultValue={moment("11:59 pm", format12)}
                    onChange={handleEndTime}
                    inputReadOnly={true}
                />
            </div>
        </>
    );
};
