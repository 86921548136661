import _, { find, reduce } from "lodash";
import { compose, first, intersection } from "lodash/fp";
import { IAddress } from "@snackpass/snackpass-types/src/v1";
import { IStore } from "@snackpass/snackpass-types";

export const USEFUL_ADDRESS_COMPONENTS = [
    "street_number",
    "route",
    "locality",
    "sublocality",
    "neighborhood",
    "administrative_area_level_2",
    "administrative_area_level_1",
    "postal_code"
] as const;

type SnackpassGoogleAddressMap = {
    [k in (typeof USEFUL_ADDRESS_COMPONENTS)[number]]: {
        long: string;
        short: string;
    };
};

/**
 * Builds a map of values from meaningful address fields and uses this map to construct an address object
 * @param addressComps an array of address components produced by the Google API
 * @param completeAddr a string representing the complete address (assumed to be a source of truth)
 * @returns a normalized address object
 */
export const normalizeAddress = (
    addressComps: google.maps.GeocoderAddressComponent[],
    completeAddr: string
) => {
    const addressMap = reduce(
        addressComps,
        (acc, partial) => {
            const addressType: keyof SnackpassGoogleAddressMap = compose(
                first,
                intersection(USEFUL_ADDRESS_COMPONENTS)
            )(partial.types);

            if (addressType) {
                acc[addressType] = {
                    long: partial.long_name,
                    short: partial.short_name
                };
            }
            return acc;
        },
        {} as SnackpassGoogleAddressMap
    );

    // Find which property contains a "city" value that is included in the complete address
    const city = find(
        [addressMap.locality, addressMap.sublocality, addressMap.neighborhood],
        (val) =>
            completeAddr.includes(val?.long) ||
            completeAddr.includes(val?.short)
    );

    return {
        line1: `${addressMap.street_number?.short ?? ""} ${
            addressMap.route?.short ?? ""
        }`.trim(),
        city: city?.long ?? "",
        county: addressMap.administrative_area_level_2?.short ?? "",
        state: addressMap.administrative_area_level_1?.short ?? "",
        zip: addressMap.postal_code?.short ?? "",
        country: "USA"
    } as IAddress;
};

export const assertAddressLineTwo = (
    line2: string | null,
    full: string | null
) => {
    if (line2 && full) {
        const finalFullAddress = full.split(",");
        finalFullAddress.splice(1, 0, line2);
        return finalFullAddress.join();
    }
    return full;
};

export const getAddress = (
    address: string | null | undefined,
    addressComponents: IAddress | null | undefined,
    activeStore: IStore
) => {
    if (
        !_.isEqual(activeStore?.addressComponents, addressComponents) &&
        addressComponents
    ) {
        //if address being changed
        return addressComponents?.line2
            ? assertAddressLineTwo(
                  addressComponents.line2,
                  addressComponents.full
              )
            : addressComponents?.full;
    }
    //if address component not being modified return the original address
    //there is edge case that initial data of addressComponent.full is empty
    //the validation rules(address can not be empty) is handling the case if address is null/undefined
    return address;
};
