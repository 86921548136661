import React from "react";

import {
    AlertDialog,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogCancel,
    AlertDialogAction,
    AlertDialogContent
} from "src/@/components/ui/alert-dialog";
import { buttonVariants } from "src/@/components/ui/button";

type Props = {
    message: string;
    open: boolean;
    close: () => void;
    onContinue: () => void;
};

/**
 * A confirm dialog that appears when a user is trying to logout
 */
export const ConfirmLogoutAlert = ({
    message,
    open,
    close,
    onContinue
}: Props) => (
    <AlertDialog open={open} onOpenChange={close}>
        <AlertDialogContent className="z-[9999]" overlayClassName="z-[9998]">
            {" "}
            <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>{message}</AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
                <AlertDialogCancel onClick={close}>Cancel</AlertDialogCancel>
                <AlertDialogAction
                    onClick={() => {
                        onContinue();
                        close();
                    }}
                    className={buttonVariants({ variant: "destructive" })}
                >
                    Log Out
                </AlertDialogAction>
            </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>
);
