import React, { useState } from "react";
import styled from "styled-components";
import { Row } from "antd";
import { match } from "ts-pattern";
import { truncate } from "lodash";

import Text from "#payouts/components/shared/Text";
import { ReactComponent as AlertRules } from "src/assets/icons/rules-alert.svg";
import { ReactComponent as Pencil } from "src/assets/icons/pencil-over-paper.svg";
import { ReactComponent as Play } from "#payouts/assets/play.svg";
import { Button } from "#payouts/components/shared/Button";
import HeaderInfo from "#table/HeaderInfo";
import { AddressSetting, Setting } from "#payouts/domain/types";
import { descriptions } from "#payouts/utils/descriptions";
import useWindowDimensions from "#hooks/use-window-dimensions";

export const SettingRow = ({ s }: { s: Setting | AddressSetting }) =>
    match(s.field)
        .with("address", () => <AddressBlock setting={s as AddressSetting} />)
        .otherwise(() => <Block setting={s as Setting} />);

export const SettingsBlock = ({
    title,
    settings,
    start = false,
    disabled = false,
    onClick = () => null,
    tooltip,
    extraButton,
    startText = "Start"
}: {
    title: React.ReactNode;
    settings: (Setting | AddressSetting)[];
    start?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    tooltip?: string;
    extraButton?: React.ReactNode;
    startText?: string;
}) => (
    <Container>
        <div className="container-header">
            {title && (
                <div className="container-title">
                    <Text.Title3>{title}</Text.Title3>
                    {tooltip ? <HeaderInfo message={tooltip} /> : null}
                </div>
            )}
            <Button
                disabled={disabled}
                size="small"
                smallRadius
                variant={start ? "primary" : "tertiary"}
                iconLeft={start ? <Play /> : <Pencil />}
                onClick={onClick}
            >
                {start ? startText : "Edit"}
            </Button>
            {extraButton}
        </div>
        {settings.map((s) => (
            <SettingRow key={s.field} s={s} />
        ))}
    </Container>
);

const Address = ({ address }: { address: AddressSetting["value"] }) => (
    <Text.Subtitle style={{ margin: 0, padding: 0 }}>
        <Row>{address.line1}</Row>
        <Row>{address.line2}</Row>
        <Row>{`${address.city}, ${address.state} ${address.postalCode}`}</Row>
        <Row>{address.country}</Row>
    </Text.Subtitle>
);

const ProtectedComponent = ({ setting }: { setting: Setting }) => (
    <Text.Subtitle style={{ margin: 0, padding: 0 }}>
        <>
            {setting.encrypted
                ? setting.encryptedValue ?? "Provided"
                : setting.value}
        </>
    </Text.Subtitle>
);

const AddressBlock = ({ setting }: { setting: AddressSetting }) => (
    <Highlight value={!!setting.value}>
        <div className="highlight-left">
            {setting.label}
            {setting.value?.line1 ? (
                <Address address={setting.value} />
            ) : (
                <MissingBody />
            )}
        </div>
    </Highlight>
);

const Block = ({ setting }: { setting: Setting }) => (
    <Highlight value={!!setting.value}>
        <div className="highlight-left">
            {setting.label}
            {setting.value ? (
                <ProtectedComponent setting={setting} />
            ) : (
                <MissingBody error={setting.error} />
            )}
        </div>
    </Highlight>
);

const Highlight = styled.div<{ value?: boolean }>`
    ${({ theme, value }) => `
        display: flex;
        margin: 5px 0;
        border: ${value ? "none" : `1px solid red`};
        background-color: ${value ? "none" : "rgba(255,0,0,0.1)"};
        padding: 5px ${theme.spacing.base};
        border-radius: ${theme.spacing.half};
        padding-left: ${value ? 0 : theme.spacing.base};

        .highlight-left {
            display: flex;
            flex-direction: column;
            flex: 1;
        }
    `}
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    .container-title {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        padding-left: 0;
    }

    .container-header {
        display: flex;
        flex-direction: row;
        flex: 1;
        width: 100%;
        justify-content: space-between;
        margin-bottom: ${({ theme }) => theme.spacing.base};
    }
`;

const MissingBody = ({ error }: { error?: string }) => {
    const { isMobile } = useWindowDimensions();

    const hasError = !!error?.length;
    const [open, setOpen] = useState(false);

    const handleClick = () => (hasError ? setOpen(!open) : null);

    const raw = hasError ? error : descriptions.missing;
    const length = isMobile ? 35 : 70;
    const trimmed = truncate(raw, { length, separator: " " });

    return (
        <MissingContainer open={open} mobile={isMobile}>
            <div className="alert-container">
                <AlertRules />
            </div>
            <div className="error-message">
                <div className="error-info">{open ? raw : trimmed}</div>
                <div className="error-toggle" onClick={handleClick}>
                    {!hasError ? "" : open ? "[Less]" : "[More]"}
                </div>
            </div>
        </MissingContainer>
    );
};

const MissingContainer = styled.div<{ open?: boolean; mobile?: boolean }>`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    justify-content: flex-start;
    color: red;
    font-family: Inter;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;

    .alert-container {
        display: flex;
        flex-direction: column;
        flex: 0 1;
        height: 100%;
        justify-content: ${({ open }) => (open ? "flex-start" : "center")};
        margin-top: ${({ open }) => (open ? "2.5px" : "auto")};
        margin-right: ${({ theme }) => theme.spacing.half};
    }

    .error-message {
        display: flex;
        flex-direction: ${({ open, mobile }) =>
            open && mobile ? "column" : "row"};
        flex: 1;
        width: 100%;
        justify-content: space-between;

        .error-info {
            display: flex;
            flex-direction: row;
            flex: 1;
            max-width: 90%;
        }

        .error-toggle {
            cursor: pointer;
            text-decoration: underline;
            &:hover {
                color: blue;
            }
        }
    }
`;
