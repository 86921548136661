/* global window, document */

export function loadIntercom() {
    // partner app provides it's own intercom
    // integration, no need for an extra one here
    if (window.PartnerAppJsApi) {
        window.Intercom = () => {};
        return;
    }

    window.intercomSettings = {
        app_id: "z0np13lu",
        hide_default_launcher: true
    };

    (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
            ic("reattach_activator");
            ic("update", w.intercomSettings);
        } else {
            var d = document;
            var i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
                var s = d.createElement("script");
                s.type = "text/javascript";
                s.async = true;
                s.src = "https://widget.intercom.io/widget/z0np13lu";
                var x = d.getElementsByTagName("script")[0];
                x.parentNode.insertBefore(s, x);
            };
            if (w.attachEvent) {
                w.attachEvent("onload", l);
            } else {
                w.addEventListener("load", l, false);
            }
        }
    })();
}
