/** @jsxImportSource @emotion/react */
import React, { Dispatch, SetStateAction, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "sonner";

import { deleteMultiMenu } from "#menu-editor/multi-menus/redux/thunks";
import { ConfirmModal } from "#menu-editor/multi-menus/shared-components/confirm-modal";
import { useAppDispatch } from "src/redux/hooks";

type ModalProps = {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    menuId: string;
    storeId?: string;
    menuName?: string;
};

export const DeleteMenuModal: React.FC<ModalProps> = ({
    isModalOpen,
    setIsModalOpen,
    menuId,
    storeId,
    menuName
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [isDeleting, setDeleting] = useState(false);
    const [deleteFailed, setDeleteFailed] = useState(false);

    const deleteCallback = (success: boolean) => {
        if (success) {
            toast.success("Menu removed successfully.");
            setIsModalOpen(false);
            history.push("/multi-menus");
        } else {
            setDeleteFailed(true);
        }
    };

    const handleDelete = async () => {
        if (storeId && !isDeleting) {
            setDeleting(true);
            await dispatch(
                deleteMultiMenu({
                    storeId,
                    menuId,
                    deleteCallback
                })
            );
        }
        setDeleting(false);
    };

    return (
        <ConfirmModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            fail={deleteFailed}
            isInProcess={isDeleting}
            onClick={handleDelete}
            title="Remove Menu?"
            message={`Are you sure you want to delete ${
                menuName ? `${menuName} ` : ""
            }menu?`}
            action="delete menu"
        />
    );
};
