import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Spinner } from "react-activity";
import { SystemColors } from "@snackpass/design-system";
import { useFormContext } from "react-hook-form";
import { useCallback, useState } from "react";

import { getActiveStore } from "src/redux/selectors/activeStore";
import api from "src/api/rest";
import { InlineStripeBillingForm } from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/InlineStripeForm/InlineStripeBillingForm";
import { Card, CardContent } from "src/@/components/ui/card";
import { Checkbox } from "src/@/components/ui/checkbox";
import { FormField, FormMessage } from "src/@/components/ui/form";

const HAS_PAYMENT_METHOD = "hasPaymentMethod";
/*
 * This component assumes the form has a boolean called hasPaymentMethod, and is blocking submission of the form
 * until hasPaymentMethod is set to true.
 */
export function CampaignRegInlineStripeBilling() {
    const [didAddBilling, setDidAddBilling] = useState(false);
    const { setValue, control, clearErrors } = useFormContext();
    const store = useSelector(getActiveStore);
    const { data, isLoading } = useQuery({
        queryKey: [HAS_PAYMENT_METHOD, store?._id],
        queryFn: async () => api.billing.getHasBillingMethods(store!._id),
        enabled: store?._id != null,
        retry: false
    });

    const onBillingAdded = useCallback(async () => {
        setDidAddBilling(true);
        setValue(HAS_PAYMENT_METHOD, true);
        clearErrors(HAS_PAYMENT_METHOD);
    }, [clearErrors, setValue]);

    if (isLoading) {
        return <Spinner color={SystemColors.v1.candy50} size={12} />;
    }

    const hasBillingMethodData = data?.data;

    // If we added billing manually, show continue showing the stripe thank you page even after success
    if (didAddBilling === false && hasBillingMethodData?.hasExistingMethods) {
        setValue(HAS_PAYMENT_METHOD, true);
        // If we have billing methods, nothing to render here.
        return null;
    }

    return (
        <Card>
            <CardContent className="p-4 lg:p-6">
                <div className="flex flex-col">
                    <p className="text-xl font-semibold">Billing</p>
                    <FormField
                        control={control}
                        name="hasPaymentMethod"
                        render={({ field }) => (
                            <>
                                {/* 0 width 0 opacity makes this effectively invisible while allowing it to be "focusable" for react hook form error focusing */}
                                <Checkbox
                                    ref={field.ref}
                                    checked={field.value}
                                    className="h-0 w-0 opacity-0"
                                />
                                <FormMessage />
                            </>
                        )}
                    />
                    <InlineStripeBillingForm onBillingAdded={onBillingAdded} />
                </div>
            </CardContent>
        </Card>
    );
}
