import { captureException } from "@sentry/react";

import api from "src/api/rest";
import { SaasAccount } from "#billing/domain/types";
import { notifyFailure } from "#onboarding/utils/notify";

export const buildFetchSaasAccount =
    (setSaasAccount: (s: SaasAccount) => void) => async (id: string) =>
        api.billing
            .getSaasAccount(id)
            .then(({ data }) => {
                if (data?.success) {
                    setSaasAccount(data.account);
                } else {
                    notifyFailure({
                        description: "Unable to retrieve SaaS Account"
                    });
                    captureException(Error(JSON.stringify(data)));
                }
            })
            .catch(() => {
                notifyFailure({
                    description: "Unable to retrieve SaaS Account"
                });
            });
