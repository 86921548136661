import styled from "@emotion/styled";
import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import { CampaignDetails } from "#guestbook";
import {
    GiftsDrawer,
    IncentivesDrawer,
    MenuDrawer,
    TemplatesDrawer
} from "#guestbook/components";
import { PushCampaignsPage } from "#guestbook/screens/Campaigns/PushCampaignsPage";
import { SMSCampaignsPage } from "#guestbook/screens/Campaigns/SMSCampaignsPage";
import useTrackCampaignSegmentEvent from "#guestbook/screens/Campaigns/useTrackCampaignSegmentEvent";
import { ConversationHome } from "#guestbook/screens/conversation-home";
import { AuditLog } from "#guestbook/screens/audit-log";
import { Routes } from "#navigation/routes";
import { SegmentEvents } from "#utils/segment";

const GuestbookNavigation = () => {
    const trackCampaignEvent = useTrackCampaignSegmentEvent();
    useEffect(() => {
        trackCampaignEvent(SegmentEvents.Guestbook.VIEWED);
    }, [trackCampaignEvent]);

    return (
        <Switch>
            <NavigationContainer>
                <TemplatesDrawer />
                <IncentivesDrawer />
                <GiftsDrawer />
                <MenuDrawer />
                <Route
                    exact
                    path={Routes.GuestbookCampaignsSMS}
                    component={SMSCampaignsPage}
                />
                <Route
                    exact
                    path={Routes.GuestbookCampaignsSMSDetails}
                    render={(props) => (
                        <CampaignDetails {...props} campaignType="sms" />
                    )}
                />
                <Route
                    exact
                    path={Routes.GuestbookCampaignsPush}
                    component={PushCampaignsPage}
                />
                <Route
                    exact
                    path={Routes.GuestbookCampaignsPushDetails}
                    render={(props) => (
                        <CampaignDetails {...props} campaignType="push" />
                    )}
                />
                <Route
                    exact
                    path={Routes.GuestbookConversations}
                    component={ConversationHome}
                />
                <Route
                    exact
                    path={Routes.GuestbookAuditLog}
                    component={AuditLog}
                />
            </NavigationContainer>
        </Switch>
    );
};

const NavigationContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
`;

export default GuestbookNavigation;
