import styled from "@emotion/styled";
import { SystemColors } from "@snackpass/design-system";
import { useFieldArray, useFormContext } from "react-hook-form";

import { defaultMenuItem } from "#promotion/utils/constants";
import { ReactComponent as PlusIcon } from "src/assets/icons/plus.svg";
import { ReactComponent as TrashIcon } from "src/assets/icons/trash-can.svg";
import { FIELD_NAMES } from "#promotion/utils/types";
import colors from "#reusable/colors/colors.json";

import { FormItemSelector } from "./form-items-selector";

export type FormItemsMultiSelectorProps = {
    fieldName: FIELD_NAMES;

    /** Allows for customizing the + button label. Default is "Add New Item" */
    addItemLabel?: string;
    /**
     * Description for item label
     */
    itemLabelDescription?: string;
    /**
     * Placeholder for item label text field
     */
    itemLabelPlaceholder?: string;

    /**
     * Include itemLabel
     */
    labeled?: boolean;
    /**
     * Allow multiple discounted items in same cart
     */
    multipleDiscountedItems?: boolean;
};

export const FormItemsMultiSelector = ({
    fieldName,
    addItemLabel = "",
    itemLabelDescription = "",
    itemLabelPlaceholder = "",
    labeled = true,
    multipleDiscountedItems = true
}: FormItemsMultiSelectorProps) => {
    const { clearErrors, trigger } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name: fieldName
    });

    const addSelector = () => {
        clearErrors(fieldName);
        append(defaultMenuItem);
        void trigger(fieldName);
    };

    const removeSelector = (index: number) => {
        clearErrors(fieldName);
        remove(index);
        void trigger(fieldName);
    };

    return (
        <Container>
            {fields.map((field, index) => (
                <SelectorContainer key={field.id}>
                    <TitleContainer>
                        <MenuLabel>Item {index + 1}</MenuLabel>
                        {multipleDiscountedItems && (
                            <RemoveSelectorButton
                                onClick={() => removeSelector(index)}
                            >
                                <TrashIcon
                                    width={12}
                                    height={12}
                                    fill={SystemColors.v1.melon50}
                                />
                                <RemoveSelectorLabel>
                                    Remove Item
                                </RemoveSelectorLabel>
                            </RemoveSelectorButton>
                        )}
                    </TitleContainer>
                    <FormItemSelector
                        fieldName={fieldName}
                        index={index}
                        labeled={labeled}
                        description={itemLabelDescription}
                        placeholder={itemLabelPlaceholder}
                    />
                </SelectorContainer>
            ))}
            {multipleDiscountedItems && (
                <AddSelectorButton
                    type="button"
                    value="Add Item"
                    onClick={addSelector}
                >
                    <PlusIcon
                        width={16}
                        height={16}
                        fill={SystemColors.v1.white}
                    />
                    <AddSelectorLabel>
                        {addItemLabel || "Add New Item"}
                    </AddSelectorLabel>
                </AddSelectorButton>
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const SelectorContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
`;

const MenuLabel = styled.p`
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
`;

const AddSelectorLabel = styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
`;

const AddSelectorButton = styled.button`
    margin-top: 12px;
    border-radius: 8px;
    color: white;
    padding: 20px;
    background-color: ${SystemColors.v1.candy50};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
`;

const RemoveSelectorButton = styled.button`
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid ${colors["neutral-400"]};
    background-color: transparent;
    box-shadow: none;
`;

const RemoveSelectorLabel = styled.p`
    color: ${SystemColors.v1.melon50};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin: 0;
`;

const OptionalLabel = styled.p`
    margin: 0;
    margin-top: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 14px;
    color: ${SystemColors.v1.gray30};
`;
