import React, { useContext } from "react";

import SettingsSection from "#devices/components/EditDrawer/SettingsSection";
import Toggle from "#devices/components/FormControls/Toggle";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";

type ToggleFieldProps = {
    id: string;
    label: string;
    description: string;
    field: string;
    disabled?: boolean;
};

export const ToggleField = ({
    id,
    label,
    description,
    field,
    disabled
}: ToggleFieldProps) => {
    const { device, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    if (!updatedDevice?.deviceDetails) {
        updatedDevice.deviceDetails = {};
    }

    const checked =
        updatedDevice?.deviceDetails[field] ??
        //@ts-ignore
        device.deviceDetails[field] ??
        false;

    return (
        <SettingsSection label={label} description={description}>
            <Toggle
                id={id}
                checked={checked as boolean}
                onChange={(e) => {
                    updateDeviceField(field, e.target.checked);
                }}
                disabled={disabled}
            />
        </SettingsSection>
    );
};
