import styled from "styled-components";

import Text from "#payouts/components/shared/Text";
import { SettingRow } from "#payouts/components/shared/SettingsBlock";
import { AddressSetting, Setting } from "#payouts/domain/types";

export const RepresentativeBlock = ({
    title,
    settings
}: {
    title?: string;
    settings: (Setting | AddressSetting)[];
    disabled?: boolean;
}) => (
    <Container>
        {title && (
            <div className="container-header">
                <div className="container-title">
                    <Text.Title3>{title}</Text.Title3>
                </div>
            </div>
        )}
        {settings.map((s) => (
            <SettingRow key={s.field} s={s} />
        ))}
    </Container>
);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    .container-header {
        display: flex;
        flex-direction: row;
        flex: 1;
        width: 100%;
        justify-content: space-between;
        margin-bottom: ${({ theme }) => theme.spacing.base};
    }

    .container-title {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        height: 100%;
        padding-left: 0;
    }
`;
