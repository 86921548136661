import { SystemColors } from "@snackpass/design-system";
import { Checkbox, Col, Form, Input, Row } from "antd";
import axios from "axios";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { z } from "zod";
import { captureException } from "@sentry/react";

import api from "src/api/rest";
import CloseButton from "#payouts/components/shared/CloseButton";
import { Header, StyledModal } from "#payouts/components/shared/Modal";
import { Button } from "#payouts/components/shared/Button";
import { notifyFailure } from "#payouts/utils/notify";
import { messages } from "#payouts/utils/messages";
import { TaxFormsContext } from "#tax-forms/Context";
import { getUser } from "src/redux/selectors";
import { ErrorWithCause } from "src/utils/errors";

type Props = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
};

type FormValues = {
    passcode: string;
    rememberMe: boolean;
};

export const PasscodeInputModal = ({ isOpen, setIsOpen }: Props) => {
    const { storeId, setEmailVerified } = useContext(TaxFormsContext);
    const [form] = Form.useForm();
    const user = useSelector(getUser);
    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const handleSubmit = async ({ passcode, rememberMe }: FormValues) => {
        if (!storeId || loading) return;
        setLoading(true);

        return api.verifications
            .verifyCode({
                storeId,
                passcode,
                ttlHours: rememberMe ? 7 * 24 : 24
            })
            .then(() => {
                // Cookie should be set in browser
                // cookies.x-snackpass-payouts-token
                setIsOpen(false);
                setEmailVerified(true);
            })
            .catch((error: ErrorWithCause) => {
                let errorMessage;
                if (axios.isAxiosError(error.cause)) {
                    const parsedDataResult = z
                        .object({ message: z.string() })
                        .safeParse(error.cause.response?.data);
                    if (parsedDataResult.success) {
                        errorMessage = {
                            message: parsedDataResult.data.message,
                            description: ""
                        };
                    }
                }
                notifyFailure(errorMessage || messages.modal.submit);
                captureException(error);
                setLoading(false);
            });
    };

    if (!isOpen) return null;

    return (
        <StyledModal
            open={isOpen}
            setOpen={setIsOpen}
            skipVerificationCheck
            header={
                <Header
                    left={<CloseButton onClose={() => setIsOpen(false)} />}
                    center="Verify it's you"
                />
            }
            footer={
                <>
                    <Button
                        block
                        smallRadius
                        variant="primary"
                        size="regular"
                        disabled={submitDisabled}
                        loading={loading}
                        loadingColor={SystemColors.v1.white}
                        children={<>Verify</>}
                        onClick={form.submit}
                    />
                </>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{ passcode: undefined, rememberMe: false }}
                onFinish={handleSubmit}
                onFieldsChange={(_, allFields) => {
                    const passcodeField = allFields.find(
                        (field) =>
                            Array.isArray(field.name) &&
                            field.name[0] === "passcode"
                    );
                    setSubmitDisabled(!passcodeField?.value);
                }}
            >
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name="passcode"
                            label={
                                <StyledLabel>
                                    <h2 className="title">
                                        Please enter the code sent to{" "}
                                        <span className="email">
                                            {user?.email || "your email"}
                                        </span>
                                    </h2>
                                </StyledLabel>
                            }
                        >
                            <Input placeholder="Enter your code" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <StyledLabel>
                        <h2 className="subtitle">
                            Note: If you do not see the code in your inbox,
                            please check your spam folder and allow up to 10
                            minutes for the code to be delivered.
                        </h2>
                    </StyledLabel>
                </Row>
                <Row>
                    <StyledLabel>
                        <h2 className="subtitle">
                            <a
                                href="https://support.snackpass.co/en/articles/8950536-snackpass-verification-code"
                                target="_blank"
                            >
                                Not receiving the code?
                            </a>
                        </h2>
                    </StyledLabel>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="rememberMe" valuePropName="checked">
                            <Checkbox>Remember this device for 7 days</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledModal>
    );
};

const StyledLabel = styled.div`
    ${({ theme }) => `
        display: flex;
        flex-direction: column;
        font-family: Inter;
        margin: 0 auto;
        
        h2 {        
            font-weight: 400;
            font-size: ${theme.typography.body.fontSize};
            line-height: ${theme.typography.body.lineHeight};

            .email {
                font-weight: 500;
                color: #0F0F0F;
                
            }
        }

        .title {
            color: #585B5F;
        }

        .subtitle {
            color: #585B5F;
            margin-bottom: ${theme.spacing.half};
        }
    `}
`;
