import { useMutation } from "@tanstack/react-query";
import { UseFormReset } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "sonner";

import { setActiveStore } from "src/redux/slices";
import api from "src/api/rest";
import {
    CustomFeeLabelValues,
    FeesSettingsMutation
} from "#settings/settings-fees/utils/types";
import { logAndSendError } from "src/utils/errors";
import { getCustomFeeLabelDefaults } from "#settings/settings-fees/forms/labels/getCustomFeeLabelDefaults";

export const useUpdateCustomFeeLabels = ({
    storeId,
    reset
}: {
    storeId: string;
    reset: UseFormReset<CustomFeeLabelValues>;
}) => {
    const dispatch = useDispatch();
    const { mutateAsync } = useMutation({
        mutationKey: [FeesSettingsMutation.Labels, storeId],
        async mutationFn(values: CustomFeeLabelValues) {
            const response = await api.stores.updateCustomFeeLabels(
                storeId,
                values
            );
            dispatch(setActiveStore(response.data.store));
            reset(getCustomFeeLabelDefaults(response.data.store));
            return response.data.store.name;
        },
        async onSuccess(storeName) {
            toast.success(`${storeName} saved`, {
                className: "bg-success-light",
                duration: 2000,
                id: "store-saved"
            });
        },
        onError(error) {
            logAndSendError({ error });
            toast.error(`Update failed`, {
                className: "bg-critical-light",
                duration: 2000,
                id: "store-update-failed"
            });
        }
    });
    return mutateAsync;
};
