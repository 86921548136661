import { Options } from "#reusable/select/dropdown";

export enum INTEGRATION_FIELD_NAMES {
    INTEGRATIONS = "integrationApps",
    INTEGRATION_LINK_OR_ACCOUNT_NUMBERS = "integrationLinkOrAccountNumbers",
    NAME = "name",
    EMAIL = "email",
    COMMENTS = "comments",
    PAYMENT_AGREEMENT = "paymentAgreement",
    SHOULD_MARKUP_PRICES = "shouldMarkupPrices",
    MARKUP_TYPE = "markupType",
    MARKUP_PERCENT = "markupPercent",
    MARKUP_DOLLARS = "markupDollars",
    MARKUP_COMMENTS = "markupComments"
}

export enum MARKUP_TYPES {
    DOLLARS = "DOLLARS",
    PERCENT = "PERCENT"
}

export const PERCENT_OPTIONS: Options[] = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "15", value: 15 },
    { label: "25", value: 25 }
];

export const DOLLAR_OPTIONS: Options[] = [
    { label: "0.25", value: 0.25 },
    { label: "0.50", value: 0.5 },
    { label: "1.00", value: 1.0 },
    { label: "2.00", value: 2.0 }
];

export interface CreateIntegration {
    [INTEGRATION_FIELD_NAMES.INTEGRATIONS]: IntegrationApps[];
    [INTEGRATION_FIELD_NAMES.INTEGRATION_LINK_OR_ACCOUNT_NUMBERS]: IntegrationLinkOrAccountNumber[];
    [INTEGRATION_FIELD_NAMES.NAME]: string;
    [INTEGRATION_FIELD_NAMES.EMAIL]: string;
    [INTEGRATION_FIELD_NAMES.COMMENTS]: string;
    [INTEGRATION_FIELD_NAMES.PAYMENT_AGREEMENT]: boolean;
    [INTEGRATION_FIELD_NAMES.PAYMENT_AGREEMENT]: boolean;
    [INTEGRATION_FIELD_NAMES.SHOULD_MARKUP_PRICES]: boolean;
    [INTEGRATION_FIELD_NAMES.MARKUP_TYPE]: MARKUP_TYPES;
    [INTEGRATION_FIELD_NAMES.MARKUP_PERCENT]: number;
    [INTEGRATION_FIELD_NAMES.MARKUP_DOLLARS]: number;
    [INTEGRATION_FIELD_NAMES.MARKUP_COMMENTS]: string;
}

export interface IntegrationLinkOrAccountNumber {
    integrationApp: string;
    linkOrAccountNumber: string | number | undefined;
}

export enum IntegrationApps {
    UberEats = "Uber Eats",
    DoorDash = "Doordash",
    Grubhub = "Grubhub",
    Fantuan = "Fantuan",
    HungryPanda = "Hungry Panda"
}

export const IntegrationAppOptions: IntegrationApps[] = [
    IntegrationApps.UberEats,
    IntegrationApps.DoorDash,
    IntegrationApps.Grubhub,
    IntegrationApps.Fantuan,
    IntegrationApps.HungryPanda
];

export const AccountNumberIntegrations: IntegrationApps[] = [
    IntegrationApps.HungryPanda
];

export const IntegrationAppPlaceholders = {
    [IntegrationApps.UberEats]: "www.ubereats.com/your-store",
    [IntegrationApps.DoorDash]: "www.doordash.com/your-store",
    [IntegrationApps.Grubhub]: "www.grubhub.com/restaurant/",
    [IntegrationApps.Fantuan]: "www.fantuanorder.com/store/",
    [IntegrationApps.HungryPanda]: "123456789"
};
