import { SystemColors } from "@snackpass/design-system";
import React from "react";
import { useDispatch } from "react-redux";

import { setInventoryIsFetching } from "src/redux/slices";
import { useFetchInventory } from "#inventory/lib";
import api from "src/api/rest";

type Props = {
    topUp: Record<string, number>;
    showTopUp: boolean;
    toggleTopUp: () => void;
};

const TopUpButton = (props: Props) => {
    const { topUp, toggleTopUp, showTopUp } = props;
    const dispatch = useDispatch();
    const [fetchInventory] = useFetchInventory();

    const _handleApply = async () => {
        const products = Object.keys(topUp);
        await Promise.all(
            products.map(async (productId) =>
                api.inventory.adjust(productId, topUp[productId])
            )
        );
        dispatch(setInventoryIsFetching(true));
        setTimeout(() => {
            fetchInventory();
        }, 1000);
        toggleTopUp();
    };

    return (
        <button
            style={{
                ...styles.button,
                ...styles.buttonBlue
            }}
            onClick={() => {
                void _handleApply();
            }}
        >
            <span
                style={styles.icon}
                className={showTopUp ? "fas fa-check" : "fas fa-plus"}
            />
            Apply
        </button>
    );
};

const styles = {
    button: {
        borderRadius: 56,
        marginLeft: 6,
        marginRight: 6,
        paddingLeft: 20,
        paddingRight: 20
    },
    buttonBlue: {
        backgroundColor: SystemColors.v1.candy50,
        color: SystemColors.v1.white,
        boxShadow: "none"
    },
    icon: { marginRight: 5 }
};

export default TopUpButton;
