import { getActiveStore } from "@snackpass/accounting/build/src/redux";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { useEffect } from "react";

import api from "src/api/rest";
import { DineInTable } from "#qr-code/lib";

export const useTables = () => {
    const activeStore = useSelector(getActiveStore);
    const storeId = activeStore?._id ?? "";

    const {
        isLoading,
        isError,
        data: tables,
        refetch
    } = useQuery({
        queryKey: [storeId, "tables-qr-codes"],
        queryFn: async (context) => {
            const res = await api.stores.listTables(context.queryKey[0]);
            return (res.data.tables as DineInTable[]) ?? [];
        },
        enabled: !!storeId
    });

    useEffect(() => {
        if (isError) {
            toast.error("Couldn't fetch tables", {
                description: "Unknown error. Try again."
            });
        }
    }, [isError]);

    return {
        isLoading,
        isError,
        refetch,
        tables
    };
};
