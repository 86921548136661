import { IProduct } from "@snackpass/snackpass-types";
import { EditorState } from "draft-js";

import {
    CampaignAudienceType,
    DiscountType
} from "src/api/graphql/generated/types";

export type ProductDiscount = {
    id: string;
    label: string;
    imageUrl: string | null;
};

export enum PromoType {
    Storewide = "STOREWIDE",
    MenuItem = "MENU_ITEM"
}

export type Promo = {
    expiresInDays: number | null;
    label: string;
    discount: {
        type: DiscountType;
        percentOff: number | null;
        dollarsOff: number | null;
        newPrice: number | null;
    };
    storewide: boolean;
    products: ProductDiscount[];
    subtext: string;
    maximumUses?: number;
    purchaseRequired: boolean;
};

export enum CampaignType {
    Product = "PRODUCT",
    Discount = "DISCOUNT",
    Announcement = "ANNOUNCEMENT"
}

export type Audience = {
    label: string;
    type: CampaignAudienceType;
    numberOfUsers: number;
};

export type CampaignProductData = {
    type: "product";
    label: string;
    product: IProduct;
};

export type CampaignDiscountData = {
    type: "discount";
    label: string;
    template: Promo;
};

export type CampaignVariableData = CampaignDiscountData | CampaignProductData;

export type CampaignVariable = {
    data: CampaignVariableData | null;
    placeholder: string;
    label: string;
    markup: string;
    id: string;
    // if the message variable is invisible / does not show up
    // in the template (ex. a gift added at the end)
    isInvisible: boolean;
};

export type CampaignState = {
    title: EditorState | null;
    message: EditorState | null;
    audience: Audience | null;
    type: CampaignType | null;
    discount: {
        type: DiscountType;
        promoType: PromoType;
        percentOff: number | undefined;
        dollarsOff: number | undefined;
        expiresInDays: number | undefined;
        purchaseRequired: boolean;
        maximumUses: number | undefined;
        product: IProduct | null;
    } | null;
    variables: CampaignVariable[];
    activeCampaignId: string;
};
