import React, { useContext, useMemo, useState, useEffect } from "react";
import { Drawer } from "antd";
import { debounce } from "radash";
import { useGate } from "statsig-react";

import api from "src/api/rest";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import DetailsDrawerHeader from "#devices/components/DetailsDrawer/DetailsDrawerHeader";
import RebootDeviceModal from "#devices/components/DetailsDrawer/RebootDeviceModal";
import DeviceDetailView from "#devices/components/DetailsDrawer/DeviceDetails";
import RestartAppModal from "#devices/components/DetailsDrawer/RestartAppModal";
import { determineDrawerWidth } from "#devices/utils/drawerWidth";
import { CenteredSpin } from "#devices/components/Loading";
import { DetailsDrawerFooter } from "#devices/components/DetailsDrawer/DetailsDrawerFooter";
import { StoreDevice, DeviceType } from "#devices/utils/deviceTypes";
import { notifications } from "#devices/components/Notification";
import useWindowDimensions from "#hooks/use-window-dimensions";
import config from "#config";

export default (): JSX.Element | null => {
    const {
        device,
        deviceToView,
        showRebootDeviceModal,
        isDetailsDrawerVisible,
        rebootingDevice,
        handleSetDevice,
        setDeviceToView,
        setIsDetailsDrawerVisible,
        setUpdatedDevice,
        setShowRebootDeviceModal,
        setRebootingDevice,
        restartingDevice,
        setRestartingDevice,
        showRestartAppModal,
        setShowRestartAppModal,
        loadingNetworkReport,
        setLoadingNetworkReport,
        setNetworkReport,
        storeDevices
    } = useContext(DevicesPageContext);

    const [isLoading, setIsLoading] = useState(false);
    const useNetworkConnectivityReport = useGate(
        config.featureGates.storeDevicesNetworkConnectivityReport
    );

    // Fetch device details when the drawer is opened, the device to view changes, or the store devices update.
    useEffect(() => {
        if (!isLoading && deviceToView?.id) {
            setIsLoading(true);
            api.storeDevices
                .getStoreDevice(deviceToView.id)
                .then((res) => {
                    handleSetDevice(res.data.device);
                    setUpdatedDevice({});
                    setIsLoading(false);
                })
                .catch((error) => {
                    notifications.error(
                        `Unable to retrieve device details at this time.\n\n${error.message}`
                    );
                    setIsLoading(false);
                    setIsDetailsDrawerVisible(false);
                    setDeviceToView(undefined);
                });
        }
    }, [deviceToView?.id, storeDevices, isDetailsDrawerVisible]);

    useEffect(() => {
        if (
            loadingNetworkReport ||
            !deviceToView ||
            !isDetailsDrawerVisible ||
            // Only show network report for KDS, Kiosk, and OrderHub
            ![DeviceType.KDS, DeviceType.Kiosk, DeviceType.OrderHub].includes(
                deviceToView.deviceType
            ) ||
            !useNetworkConnectivityReport.value
        )
            return;

        setLoadingNetworkReport(true);
        const endDate = new Date();
        const seventyTwoHours = 1000 * 60 * 60 * 24 * 3;
        const startDate = new Date(endDate.getTime() - seventyTwoHours);
        api.storeDevices
            .getDeviceNetworkConnectivityReport(
                deviceToView.id,
                deviceToView.storeId,
                startDate,
                endDate
            )
            .then((response) => {
                setNetworkReport(response.data.report);
            })
            .catch((error) => {
                // swallow
            })
            .finally(() => {
                setLoadingNetworkReport(false);
            });
    }, [
        deviceToView?.id,
        isDetailsDrawerVisible,
        useNetworkConnectivityReport.value
    ]);

    const rebootDeviceRequest = (device: StoreDevice) => {
        if (!rebootingDevice) {
            setRebootingDevice(true);
            api.storeDevices
                .rebootStoreDevice(device.id)
                .then(() => {
                    setRebootingDevice(false);
                    setShowRebootDeviceModal(false);
                    notifications.success("Device reboot initiated.");
                })
                .catch((error) => {
                    setRebootingDevice(false);
                    notifications.error(
                        `Unable to reboot device at this time.\n\n${error.message}`
                    );
                });
        }
    };

    const restartDeviceRequest = (device: StoreDevice) => {
        if (!restartingDevice) {
            setRestartingDevice(true);
            api.storeDevices
                .restartStoreDevice(device.id)
                .then(() => {
                    setRestartingDevice(false);
                    setShowRestartAppModal(false);
                    notifications.success("Device restart initiated.");
                })
                .catch((error) => {
                    setRestartingDevice(false);
                    notifications.error(
                        `Unable to restart device at this time.\n\n${error.message}`
                    );
                });
        }
    };

    const handleClose = () => {
        setIsDetailsDrawerVisible(false);
        setDeviceToView(undefined);
        handleSetDevice(undefined);
        setNetworkReport(undefined);
        setLoadingNetworkReport(false);
        setIsLoading(false);
        setShowRestartAppModal(false);
    };

    const handleRebootClose = () => {
        setShowRebootDeviceModal(false);
    };

    const handleRestartClose = () => {
        setShowRestartAppModal(false);
    };

    const handleReboot = useMemo(
        () =>
            debounce(
                { delay: 300 },
                () => device && rebootDeviceRequest(device)
            ),
        [device]
    );

    const handleRestart = useMemo(
        () =>
            debounce(
                { delay: 300 },
                () => device && restartDeviceRequest(device)
            ),
        [device]
    );

    const { width } = useWindowDimensions();

    return (
        <Drawer
            width={determineDrawerWidth(width)}
            placement="right"
            onClose={handleClose}
            open={isDetailsDrawerVisible}
            closable={false} // hides the default close button
            bodyStyle={{
                fontFamily: "Inter", // overwrite font family for everything in drawer
                overflowY: "scroll"
            }}
            footer={<DetailsDrawerFooter />}
        >
            <DetailsDrawerHeader
                onClose={handleClose}
                deviceName={!isLoading ? device?.name ?? "" : ""}
                deviceType={!isLoading ? device?.deviceType : undefined}
                isLoading={isLoading}
            />
            {deviceToView && isLoading ? (
                <CenteredSpin />
            ) : (
                <DeviceDetailView />
            )}

            <RebootDeviceModal
                isOpen={showRebootDeviceModal}
                handleClose={handleRebootClose}
                handleReboot={handleReboot}
            />
            <RestartAppModal
                isOpen={showRestartAppModal}
                handleRestart={handleRestart}
                handleClose={handleRestartClose}
            />
        </Drawer>
    );
};
