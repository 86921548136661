import "@djthoms/pretty-checkbox";

import { ReportGranularity } from "#/financial-reports/types";
import { useGranularityPicker } from "#hooks/use-granularity-picker";

import { FilterSelect } from "src/@/components/ui/filter-select";
import { Button } from "src/@/components/ui/button";
import { ReactComponent as FilterIcon } from "src/assets/icons/filter-sort.svg";

type Props = {
    initialState?: ReportGranularity;
};

const options = [
    {
        label: "Day",
        value: "day"
    },
    {
        label: "Week",
        value: "week"
    },
    {
        label: "Month",
        value: "month"
    },
    {
        label: "Payout Period",
        value: "payout_period"
    }
];

const GranularityPicker = ({ initialState }: Props) => {
    const { granularity, setGlobalGranularity } = useGranularityPicker();
    return (
        <FilterSelect
            customButton={
                <Button
                    variant="outline"
                    size="sm"
                    className="flex items-center space-x-2"
                >
                    <FilterIcon className="h-4 w-4" />
                    <span className="hidden md:block">Granularity</span>
                </Button>
            }
            title="Granularity"
            selectedValues={new Set([granularity ?? initialState])}
            options={options}
            onOptionSelected={function (value: string): void {
                setGlobalGranularity(value as ReportGranularity);
            }}
        />
    );
};

export default GranularityPicker;
