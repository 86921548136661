import React, { useCallback } from "react";
import { SystemColors } from "@snackpass/design-system";
import { useDispatch, useSelector } from "react-redux";
import { ContentState, EditorState, Modifier, SelectionState } from "draft-js";
import { compose } from "lodash/fp";
import styled from "@emotion/styled";

import { DECORATORS } from "#guestbook/components/message-input/editor-decorators";
import { getActiveStore } from "src/redux/selectors";
import { SegmentEvents, trackSegmentEvent } from "#utils/segment";

import { setMessageInput } from "../../redux";

import { Template, TEMPLATES, TemplateSection } from "./constants";

type TemplatesComponentProps = {
    onSelect: () => void;
};

export const Templates = ({ onSelect }: TemplatesComponentProps) => (
    <div>
        <h3
            className="mb-2"
            style={{
                fontWeight: "bold",
                fontSize: 22
            }}
        >
            Message Templates
        </h3>

        {TEMPLATES.map((section, index) => (
            <TemplateSectionComponent
                onSelect={onSelect}
                key={index}
                section={section}
            />
        ))}
    </div>
);

type TemplateSectionProps = {
    section: TemplateSection;
    onSelect: () => void;
};

const TemplateSectionComponent = ({
    section,
    onSelect
}: TemplateSectionProps) => (
    <div style={{ marginBottom: 0 }}>
        <div
            style={{
                fontSize: 20,
                margin: "16px 0"
            }}
        >
            {section.icon}{" "}
            <span
                style={{
                    fontWeight: "bold",
                    fontSize: 16
                }}
            >
                {section.label}
            </span>
        </div>

        {section.templates.map((template, index) => (
            <TemplateComponent
                onSelect={onSelect}
                template={template}
                key={template.id}
            />
        ))}
    </div>
);

const TemplateComponent = ({
    template,
    onSelect
}: {
    template: Template;
    onSelect: () => void;
}) => {
    const dispatch = useDispatch();
    const store = useSelector(getActiveStore);
    const _setMessage = compose(dispatch, setMessageInput);

    const onClick = useCallback(() => {
        const message = template.message;
        const signOff = `- ${store?.name}`;

        const contentState = ContentState.createFromText(
            message + `\n${signOff}`
        );
        const editorState = EditorState.createWithContent(
            contentState,
            DECORATORS
        );

        const blockMap = contentState.getBlockMap();

        // a new block gets created with every new line
        // signOffBlock will always be the last block during initialization
        const signOffBlock = blockMap.last();
        const signOffBlockKey = signOffBlock.getKey();

        const signOffSelection = new SelectionState({
            anchorKey: signOffBlockKey,
            anchorOffset: 0,
            focusKey: signOffBlockKey,
            focusOffset: signOff.length
        });

        const storeEntity = contentState.createEntity("STORE", "IMMUTABLE");
        const storeEntityKey = storeEntity.getLastCreatedEntityKey();

        // tag signOffBlock as immutable to prevent editing text
        // the entity is applied over the signOffSelection
        const contentStateWithStoreEntity = Modifier.applyEntity(
            contentState,
            signOffSelection,
            storeEntityKey
        );
        const editorStateWithStoreEntity = EditorState.push(
            editorState,
            contentStateWithStoreEntity,
            "apply-entity"
        );

        const focusEndSelection = new SelectionState({
            anchorKey: signOffBlockKey,
            anchorOffset: signOff.length,
            focusKey: signOffBlockKey,
            focusOffset: signOff.length
        });

        // move input cursor to the end of the signOff
        const templateEditorState = EditorState.forceSelection(
            editorStateWithStoreEntity,
            focusEndSelection
        );

        _setMessage({
            ...template,
            message: templateEditorState
        });
        onSelect();
        if (store) {
            trackSegmentEvent(
                SegmentEvents.Guestbook.Campaigns.PICKED_TEMPLATE,
                {
                    store_id: store._id,
                    store_name: store.name,
                    template_id: template.id,
                    template_title: template.title
                }
            );
        }
    }, [template, store?.name]);

    return (
        <TemplateContainer onClick={onClick}>
            {template.message}

            <div style={{ marginTop: 15, fontSize: 12, fontWeight: "bold" }}>
                Customize & Use
            </div>
        </TemplateContainer>
    );
};

const TemplateContainer = styled.div`
    cursor: pointer;
    border-radius: 16px;
    padding: 24px 16px;
    margin-bottom: 15px;
    background-color: ${SystemColors.v1.candy50};
    color: ${SystemColors.v1.milkfoam};
    font-size: 16px;
    font-weight: normal;
`;
