/**
 * This file declares all routes and which components
 * are displayed when a certain route is navigated to
 *
 * Also includes the top navigation bar which persists
 * in all of the pages.
 */
/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import {
    FetchTokenResponse,
    PubNubWithTokenProvider,
    useToken
} from "@snackpass/conversations.hooks";
import { storeUUID } from "@snackpass/conversations.types";
import PubNub from "pubnub";
import { RecoilRoot } from "recoil";

import ErrorPage from "#utility-pages/error-page";
import LoadingPage from "#utility-pages/loading-page";
import { getIsLoading, getUser } from "src/redux/selectors";
import { TopNavBar } from "#navigation/top-nav-bar";
import config from "#config";
import api from "src/api/rest";
import { ReportsContextProvider } from "#app/reports-context-provider";
import { sendError, ErrorWithCause } from "src/utils/errors";
import { useGuardedRoutes } from "#app/useGuardedRoutes";
import {
    useRequireAuthForRoute,
    useRequireOnboardedForRoute
} from "#navigation/guards";
import { getActiveStoreId } from "src/redux/slices";
import { NotAdminPage } from "#utility-pages/not-admin-of-store-page";
import { NavigationSideBar } from "#navigation/navigation-side-bar";

const fetchToken = async (
    uuid: string,
    retries = 3
): Promise<FetchTokenResponse> => {
    if (uuid !== "store_") {
        try {
            return await api.conversations.fetchToken(uuid);
        } catch (error) {
            if (retries > 0) {
                return fetchToken(uuid, retries - 1);
            }

            const errorWithCause = new ErrorWithCause(
                "Failed to fetch conversations token",
                error
            );
            sendError(errorWithCause);

            return { token: undefined };
        }
    }

    return { token: undefined };
};

const Navigation = () => {
    const user = useSelector(getUser);
    const isLoading = useSelector(getIsLoading);

    if (isLoading) {
        return <LoadingPage />;
    }

    if (
        !user?.snackpassPermissions?.isSnackpassEmployee &&
        (_.isEmpty(user?.snackpassPermissions?.storeIds) ||
            !user?.snackpassPermissions.isAdmin)
    ) {
        return <NotAdminPage />;
    }

    return <Router />;
};

function Router() {
    const activeStoreId = useSelector(getActiveStoreId);
    const uuid = useMemo(() => storeUUID(activeStoreId), [activeStoreId]);
    const pubNub = useMemo(
        () => new PubNub({ ...config.pubnub.conversations, uuid }),
        [uuid]
    );

    const [token] = useToken({
        uuid,
        ttl: 15, // minutes
        fetchToken
    });

    const guardedRouteElements = useGuardedRoutes();
    const guards = useGuards();

    return (
        <BrowserRouter>
            <GuardProvider
                guards={guards}
                loading={LoadingPage}
                error={ErrorPage}
            >
                <PubNubWithTokenProvider
                    client={pubNub}
                    uuid={uuid}
                    token={token}
                >
                    <ReportsContextProvider>
                        <div className="min-h-full w-full bg-white">
                            <TopNavBar />
                            <div className="flex w-full">
                                <div className="hidden lg:block">
                                    <NavigationSideBar />
                                </div>
                                <div className="h-full min-w-0 flex-1">
                                    <RecoilRoot
                                        key={activeStoreId || "unknown"}
                                    >
                                        <Switch>
                                            <>
                                                {guardedRouteElements.map(
                                                    (route, i) => ({
                                                        ...route,
                                                        key: i
                                                    })
                                                )}
                                            </>
                                            {/* If nothing matches forward them to dashboard */}
                                            <GuardedRoute
                                                render={() => (
                                                    <Redirect to="/" />
                                                )}
                                            />
                                        </Switch>
                                    </RecoilRoot>
                                </div>
                            </div>
                        </div>
                    </ReportsContextProvider>
                </PubNubWithTokenProvider>
            </GuardProvider>
        </BrowserRouter>
    );
}

const useGuards = () => {
    const requireOnboardedForRoute = useRequireOnboardedForRoute();
    const requireAuthForRoute = useRequireAuthForRoute();

    return useMemo(
        () => [requireAuthForRoute, requireOnboardedForRoute],
        [requireAuthForRoute, requireOnboardedForRoute]
    );
};

export default Navigation;
