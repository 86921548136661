import React, { useEffect } from "react";
import "antd/dist/antd.css";
import { useFormContext } from "react-hook-form";
import clsx from "clsx";

type Props = {
    /**
     * Will usually be a member of FIELD_NAMES except in cases with dynamic fieldName (eg. FormItemsSelector)
     */
    fieldName: string;
    inputLabel?: string;
    placeholder?: string;
    required?: boolean;
    httpPlaceholder?: boolean;
    height?: number;
    isTextArea?: boolean;
};

export const FormTextInput = ({
    fieldName,
    placeholder,
    height = 48,
    httpPlaceholder,
    isTextArea
}: Props) => {
    const { getFieldState, register, trigger } = useFormContext();

    const fieldState = getFieldState(fieldName);

    useEffect(() => {
        void trigger(fieldName);
    }, [trigger]);

    return (
        <div className="pb-1 pt-3">
            <div
                className={clsx(
                    "flex w-full flex-row rounded-lg border-2",
                    fieldState.error
                        ? " border-critical-accent-light"
                        : "border-neutral-400"
                )}
            >
                {httpPlaceholder && (
                    <div className="relative flex flex-row items-center justify-center rounded-l-lg border-r-2 border-neutral-400 bg-neutral-300 px-2">
                        <span className=" text-center font-normal text-neutral-700">
                            https://
                        </span>
                    </div>
                )}
                {isTextArea ? (
                    <textarea
                        className={
                            "w-full border-none pl-2 pt-2 outline-none placeholder:absolute placeholder:left-0 placeholder:top-0 placeholder:pl-2 placeholder:pt-2 placeholder:text-body placeholder:font-normal placeholder:text-neutral-400"
                        }
                        placeholder={placeholder}
                        style={{ height: `${height}px` }}
                        {...register(fieldName)}
                    />
                ) : (
                    <input
                        className={clsx(
                            "w-full border-none pl-2 pt-3 outline-none placeholder:absolute placeholder:left-0 placeholder:top-0 placeholder:pl-2 placeholder:pt-3 placeholder:text-body placeholder:font-normal placeholder:text-neutral-400",
                            httpPlaceholder
                                ? `rounded-r-lg`
                                : "rounded-lg"
                        )}
                        placeholder={placeholder}
                        {...register(fieldName, {
                            onChange: async () => trigger(fieldName)
                        })}
                    />
                )}
            </div>
            {fieldState.error?.message ? (
                <p className="m-0 mt-1 text-small text-critical-light">
                    {fieldState.error?.message}
                </p>
            ) : null}
        </div>
    );
};
