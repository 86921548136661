import { useMutation } from "@tanstack/react-query";
import { FeePolicy } from "@snackpass/snackpass-types";
import { UseFormReset } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "sonner";

import { setActiveStore } from "src/redux/slices";
import api from "src/api/rest";
import {
    FeesSettingsMutation,
    MiscellaneousFormValues
} from "#settings/settings-fees/utils/types";
import { miscellaneousFormDefaults } from "#settings/settings-fees/forms/miscellaneous/miscellaneous-defaults";

export const useUpdateMiscellaneous = ({
    storeId,
    reset
}: {
    storeId: string;
    reset: UseFormReset<MiscellaneousFormValues>;
}) => {
    const dispatch = useDispatch();
    const { mutateAsync } = useMutation({
        mutationKey: [FeesSettingsMutation.Misc, storeId],
        async mutationFn(values: MiscellaneousFormValues) {
            const bagFeePolicy =
                values.flat > 0
                    ? ({
                          name: "BAG_STORE_FEE",
                          payer: "customer",
                          recipient: "store",
                          flat: values.flat,
                          percent: 0,
                          isTaxable: !!values.isTaxable
                      } as FeePolicy)
                    : undefined;
            const response = await api.stores.updateBagFeePolicy(
                storeId,
                bagFeePolicy
            );
            dispatch(setActiveStore(response.data.store));
            reset(miscellaneousFormDefaults(response.data.store));
            return response.data.store.name;
        },
        async onSuccess(storeName) {
            toast.success(`${storeName} saved`, {
                className: "bg-success-light",
                duration: 2000,
                id: "store-saved"
            });
        },
        onError() {
            toast.error(`Update failed`, {
                className: "bg-critical-light",
                duration: 2000,
                id: "store-update-failed"
            });
        }
    });
    return mutateAsync;
};
