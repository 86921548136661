import { Addon, IProduct } from "@snackpass/snackpass-types";
import { useDispatch, useSelector } from "react-redux";

import { isProduct, WithParentId } from "#menu-editor/multi-menus/helpers";
import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import {
    getPriceAdjustmentSelector,
    getPriceOverrideSelector,
    isAddonSelected,
    isProductSelected
} from "#menu-editor/multi-menus/redux/selectors";

import {
    NumericOverrideInput,
    NumericOverrideInputProps
} from "./override-input";

interface PriceOverrideProps
    extends Pick<
        NumericOverrideInputProps,
        "inputComponent" | "inputComponentProps"
    > {
    item: WithParentId<IProduct> | WithParentId<Addon>;
}

export const PriceOverrideInput = ({
    item,
    inputComponent,
    inputComponentProps
}: PriceOverrideProps) => {
    const itemType = isProduct(item) ? "products" : "addons";

    const disabled = !useSelector(
        itemType === "products"
            ? isProductSelected(item._id)
            : isAddonSelected(item._id, item.parentId ?? "")
    );

    const priceOverrideSelector = getPriceOverrideSelector(itemType, item._id);
    const priceOverride = useSelector(priceOverrideSelector);

    const priceAdjustmentSelector = getPriceAdjustmentSelector(itemType);
    const priceAdjustment = useSelector(priceAdjustmentSelector);

    const basePrice =
        Math.round(item.price * (priceAdjustment ?? 1) * 100) / 100;

    const dispatch = useDispatch();

    const setPriceOverride = (price: number) => {
        void dispatch(
            multiMenuActions.setPriceOverride({
                itemType,
                itemId: item._id,
                price
            })
        );
    };
    const resetPriceOverride = () =>
        void dispatch(
            multiMenuActions.setPriceOverride({
                itemType,
                itemId: item._id,
                price: null
            })
        );

    return (
        <NumericOverrideInput
            inputComponent={inputComponent}
            inputComponentProps={{ ...inputComponentProps, label: "Price" }}
            baseValue={basePrice}
            overrideValue={priceOverride}
            prefix="$ "
            setOverride={setPriceOverride}
            resetOverride={resetPriceOverride}
            disabled={disabled}
        />
    );
};
