import React, { useContext } from "react";
import fileDialog from "file-dialog";
import { SystemColors } from "@snackpass/design-system";
import styled from "styled-components";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import produce from "immer";
import { toast } from "sonner";

import { ReactComponent as PlusIcon } from "src/assets/icons/plus-sign.svg";
import {
    ChannelOption,
    ChannelType,
    MAX_FILE_SIZE
} from "#devices/utils/deviceTypes/SnackTVDevice";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import Text from "#devices/components/Text";
import { getActiveStore } from "src/redux/selectors";
import api from "src/api/rest";
import colors from "#reusable/colors/colors.json";

const renderLabel = (label: string, id: string) => (
    <Text.Label htmlFor={id} as="label">
        {label}
    </Text.Label>
);

export const StaticImage = () => {
    const { snackTvDevice, updatedDevice, updateDeviceField, savingDevice } =
        useContext(DevicesPageContext);

    const store = useSelector(getActiveStore);

    const deviceOptions = (updatedDevice?.deviceDetails?.channelOptions ??
        snackTvDevice?.deviceDetails?.channelOptions ??
        []) as ChannelOption[];

    const channel =
        updatedDevice?.deviceDetails?.channel ??
        snackTvDevice?.deviceDetails?.channel;

    const channelOptions = deviceOptions.find(
        (o: ChannelOption) => o.channel === channel
    );

    const imageURL = channelOptions?.options?.imageURL ?? "";

    const { mutateAsync, isPending: imageUploading } = useMutation({
        mutationFn: async ({ formData }: { formData: FormData }) =>
            api.uploads.uploadAndReplaceImage(
                formData,
                `${store?.name}/snackTV`
            ),
        onSuccess: (data) => {
            if (data) {
                const updatedOptions = produce(deviceOptions, (draft) => {
                    if (draft) {
                        const channelOption = draft.find(
                            (o) => o.channel === channel
                        );
                        if (!channelOption) {
                            draft.push({
                                channel: channel as ChannelType,
                                options: {
                                    imageURL: data.data.location
                                }
                            });
                        } else {
                            channelOption.options.imageURL = data.data.location;
                        }
                    }
                });
                updateDeviceField("channelOptions", updatedOptions);
            }
        },
        onError: async () => {
            toast.error("Could not upload photo.");
        }
    });

    const openFileDialog = async () => {
        void fileDialog({ multiple: false, accept: "image/*" }).then(
            async (file: FileList) => {
                const image = file[0];
                const isImage = image.type.split("/")[0] === "image";
                if (!isImage) {
                    toast.error("That file is not a recognized image type");
                    return;
                }
                if (image.size > MAX_FILE_SIZE) {
                    toast.error(
                        `The file size must be smaller than ${
                            MAX_FILE_SIZE / 1024 / 1000
                        } MB, please try another image.`
                    );
                    return;
                }

                const formData = new FormData();
                formData.append("photo", image);

                if (store) {
                    await mutateAsync({ formData });
                }
            }
        );
    };

    return (
        <>
            {renderLabel("Upload an Image", "imageUploadButton")}
            <UploadButton onClick={openFileDialog} disabled={savingDevice}>
                {imageUploading ? "Image Uploading" : "Choose Image"}
                <PlusIcon fill={SystemColors.v1.black} />
            </UploadButton>
            {imageURL ? (
                <>
                    {renderLabel("Current Image", "currentImageLabel")}
                    <ImageContainer>
                        <ImageElement $src={imageURL} />
                    </ImageContainer>
                </>
            ) : null}
        </>
    );
};

const UploadButton = styled.button<{ disabled?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.base};
    margin-top: ${({ theme }) => theme.spacing.half};
    margin-bottom: ${({ theme }) => theme.spacing.base};
    color: ${SystemColors.v2.salt80.light};
    background-color: ${SystemColors.v2.salt10.light};
    width: 100%;
    box-shadow: ${`0px 0px 0px 1px ${colors["neutral-400"]}`};
    border-radius: ${({ theme }) => theme.spacing.half};
    border: none;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
`;

const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacing.half};
    border-radius: ${({ theme }) => theme.spacing.half};
`;
// if there is an image, use a white background in case it has transparency, otherwise use shade of gray for empty state
const ImageElement = styled.div<{ $src: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: auto;
    margin-bottom: ${({ theme }) => theme.spacing.base};
    border-radius: 16px;
    height: auto;
    aspect-ratio: 2;
    background-color: ${({ $src }) =>
        $src ? SystemColors.v1.white : SystemColors.v1.gray90};
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    background-image: url("${({ $src }) => $src}");
    position: relative;
    border: 1px solid ${SystemColors.v1.gray80};
`;
