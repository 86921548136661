import clsx from "clsx";
import { MouseEventHandler, ReactNode } from "react";

// To make this button an icon button
// pick an icon from reusable-component/icons and wrap it in this component.

type Props = {
    children: ReactNode;
    variant: "request" | "cancel";
    squareBtn?: boolean;
    sm?: boolean;
    fullWidth?: boolean;
    mobileFullWidth?: boolean;
    ml?: number;
    mr?: number;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
};

const IntegrationFormButton = ({
    children,
    variant,
    squareBtn,
    fullWidth,
    ml,
    mr,
    disabled,
    onClick
}: Props) => (
    <button
        className={clsx(
            "flex cursor-pointer items-center justify-center rounded-3xl border border-neutral-400 p-3 font-semibold",
            squareBtn && "rounded-none",
            variant === "cancel" && " bg-neutral-50 text-black",
            variant === "request" &&
                disabled &&
                " bg-neutral-600 text-neutral-50",
            variant === "request" &&
                !disabled &&
                " bg-black text-neutral-50",
            fullWidth && "w-full",
            disabled && "cursor-not-allowed"
        )}
        style={{ marginLeft: `${ml}px`, marginRight: `${mr}px` }}
        onClick={onClick}
    >
        {children}
    </button>
);

export default IntegrationFormButton;
