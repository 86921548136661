import { Table } from "antd";
import React, { useContext, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { getActiveStore } from "@snackpass/accounting";

import { ReportsContext } from "#app/reports-context-provider";
import {
    formatNumber,
    formatRange,
    toDollarFormatted
} from "#reports/sales-summary/lib";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import {
    aggregateItemsByCategory,
    formatForTableExport
} from "#reports/menu-category-insights/lib";
import { MenuItem, MenuTableRow } from "#reports/menu-item-insights/types";
import DownloadButton from "#reports/sales-summary/shared-components/DownloadButton";
import { GranularityType } from "#reports/sales-summary/types";
import ReportsTooltip from "#reports/sales-summary/shared-components/ReportsTooltip";
import { MENU_SALES_TOOLTIP_COPY } from "#reports/menu-item-insights/lib";

const CategoryPerformanceTable = () => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState } = useContext(ReportsContext);
    const { menuInsightsData, dateRanges } = reportsState;

    const data = aggregateItemsByCategory(menuInsightsData?.itemInsightsData);

    const columnsForExport = [
        {
            title: "",
            dataIndex: "index"
        },
        {
            title: "Category",
            dataIndex: "name"
        },
        {
            title: "Orders",
            dataIndex: "orders"
        },
        {
            title: "Net Sales",
            dataIndex: "sales"
        }
    ];

    const columns = [
        {
            title: "",
            dataIndex: "index",
            key: "index",
            width: "5%"
        },
        {
            title: "Category",
            dataIndex: "name",
            key: "name",
            width: "65%"
        },
        {
            title: "Orders",
            dataIndex: "orders",
            key: "orders",
            render: (text: number) => (isNaN(text) ? text : formatNumber(text)),
            width: "15%"
        },
        {
            title: (
                <>
                    Net Sales
                    <ReportsTooltip
                        body={MENU_SALES_TOOLTIP_COPY}
                        className="mx-3"
                    />
                </>
            ),
            dataIndex: "sales",
            key: "sales",
            width: "15%",
            render: (text: number) =>
                isNaN(text) ? text : toDollarFormatted(text)
        }
    ];

    const rows = useMemo(() => {
        if (!data) return [];
        return data
            .reduce((prev: MenuItem[], currentRow) => {
                currentRow.items.forEach((item) => {
                    const itemInArray = prev.find((e) => e.id == item.id);
                    if (!itemInArray) {
                        prev.push({ ...item });
                    } else {
                        // aggregate to get totals
                        itemInArray.sales += item.sales;
                        itemInArray.orders += item.orders;
                    }
                });
                return prev;
            }, [])
            .sort((a, b) => b.sales - a.sales)
            .map((category, index) => ({ index: index + 1, ...category }));
    }, [data]);

    const getRowClassName = (row: MenuTableRow) =>
        row.index == undefined ? "text-neutral-600" : "";

    return (
        <div className="mb-20 mt-10 pt-10">
            <div className="mb-4 flex items-center justify-between">
                <h4 className="text-large">Category Performance</h4>
                <DownloadButton
                    rows={formatForTableExport(rows)}
                    columns={columnsForExport}
                    filename={`${
                        activeStore?.name
                    } Category Performance ${formatRange(
                        dateRanges[0],
                        GranularityType.DAILY
                    )}`}
                />
            </div>
            <div>
                {!menuInsightsData?.itemInsightsDataLoading &&
                (menuInsightsData?.itemInsightsData ||
                    menuInsightsData?.itemInsightsDataFailed) ? (
                    menuInsightsData?.itemInsightsData ? (
                        <Table
                            className="whitespace-nowrap"
                            columns={columns}
                            scroll={{ x: true }}
                            dataSource={rows}
                            pagination={false}
                            rowClassName={getRowClassName}
                            sticky
                        />
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

export default CategoryPerformanceTable;
