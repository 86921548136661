import { useMemo } from "react";

import { Button } from "src/@/components/ui/button";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { ReactComponent as ChevronDown } from "src/assets/icons/chevron-down.svg";

type ChartTypeSelectProps = {
    onChange: (value: ChartType) => void;
    value: ChartType;
};

export enum ChartType {
    NET_SALES = "Net Sales",
    ORDERS = "Orders"
}

const chartTypeOptions = [
    {
        label: "Net Sales",
        value: ChartType.NET_SALES
    },
    {
        label: "Orders",
        value: ChartType.ORDERS
    }
];

export const ChartTypeSelect = ({ onChange, value }: ChartTypeSelectProps) => {
    const selectedValues = useMemo(() => new Set([value]), [value]);
    return (
        <FilterSelect
            title="Chart Type"
            customButton={
                <Button className="gap-1" variant="outline">
                    {value}
                    <ChevronDown className="w-3 fill-neutral-500" />
                </Button>
            }
            options={chartTypeOptions}
            selectedValues={selectedValues}
            onOptionSelected={(value: string) => onChange(value as ChartType)}
        />
    );
};
