import React, { useCallback, useContext, useMemo, useState } from "react";
import { Moment } from "moment";
import Skeleton from "react-loading-skeleton";
import { match } from "ts-pattern";
import { LineChart } from "@tremor/react";

import { ReportsContext } from "#app/reports-context-provider";
import {
    aggregateDatedRows,
    datePointsFromRangeAndGranularity,
    formatNumber,
    formatRange,
    getGraphGranularity,
    rangePresets,
    toDollarFormatted
} from "#reports/sales-summary/lib";
import {
    ComparisonType,
    GranularityType,
    SalesByChannelGroup,
    TableSummaryDataRow
} from "#reports/sales-summary/types";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import {
    ChartTypeSelect,
    ChartType
} from "#reports/sales-summary/shared-components/ChartTypeSelect";
import { SalesSummaryLegend } from "#reports/sales-summary/components/SalesSummaryLegend";
import { SalesSummaryTooltip } from "#reports/sales-summary/components/SalesSummaryTooltip";
import { calculateChartWidth } from "#utils/helpers";
import { useSalesReport } from "#reports/hooks";

export const SalesByPeriodChart = () => {
    const { reportsState } = useContext(ReportsContext);
    const { comparison, dateRanges, granularity } = reportsState;

    const { data: reportsData, isLoading, error } = useSalesReport();

    // Attempts to adhere to the granularity that the user selects, unless it results in too few data points.
    // This is used as the granularity for the chart as a whole.
    const highestSuitableGranularity = useMemo(
        () => getGraphGranularity(dateRanges[0], granularity),
        [dateRanges, granularity]
    );

    const aggregateData = useCallback(
        (rows: TableSummaryDataRow[] | undefined) => {
            if (!rows) return null;
            return aggregateDatedRows(
                rows,
                aggregateSummaryDataRows,
                highestSuitableGranularity
            );
        },
        [highestSuitableGranularity]
    );

    const shouldCompare = comparison != ComparisonType.NONE;
    const [graphType, setGraphType] = useState(ChartType.NET_SALES);

    const data = useMemo(
        () => aggregateData(reportsData?.salesReport),
        [aggregateData, reportsData?.salesReport]
    );
    const compareToData = useMemo(
        () => aggregateData(reportsData?.salesReportComparedTo),
        [aggregateData, reportsData?.salesReportComparedTo]
    );

    const formatDate = (date: Moment) =>
        highestSuitableGranularity == GranularityType.MONTHLY
            ? date.format("MMM YYYY")
            : date.format("MMM D");

    const labels = datePointsFromRangeAndGranularity(
        dateRanges[0],
        highestSuitableGranularity
    ).map((e) => formatDate(e));

    const chartData = useMemo(
        () =>
            data
                ? data.map((row, idx) => ({
                      label: labels[idx],
                      range: formatRange(dateRanges[0], granularity),
                      netSales: row?.netSales.total,
                      orders: row?.orders.total,

                      ...(shouldCompare
                          ? {
                                compareToRange: formatRange(
                                    dateRanges[1],
                                    granularity
                                ),
                                compareToNetSales:
                                    compareToData?.[idx]?.netSales.total,
                                compareToOrders:
                                    compareToData?.[idx]?.orders.total
                            }
                          : {})
                  }))
                : [],
        [data, labels, dateRanges, granularity, shouldCompare, compareToData]
    );

    const displayedCategories = useMemo(
        () =>
            match({ shouldCompare, graphType })
                .with(
                    { shouldCompare: false, graphType: ChartType.NET_SALES },
                    () => ["netSales"]
                )
                .with(
                    { shouldCompare: false, graphType: ChartType.ORDERS },
                    () => ["orders"]
                )
                .with(
                    { shouldCompare: true, graphType: ChartType.NET_SALES },
                    () => ["netSales", "compareToNetSales"]
                )
                .with(
                    { shouldCompare: true, graphType: ChartType.ORDERS },
                    () => ["order", "compareToOrders"]
                )
                .otherwise(() => []),
        [shouldCompare, graphType]
    );

    const valueFormatter =
        graphType == ChartType.NET_SALES ? toDollarFormatted : formatNumber;

    const chartTitle = useMemo(() => {
        const range = dateRanges[0];
        const label = rangePresets.find(
            (e) =>
                range[0].isSame(e.value[0]) &&
                range[1].isSameOrBefore(e.value[1])
        )?.label;
        return label ? label : formatRange(range, highestSuitableGranularity);
    }, [highestSuitableGranularity, dateRanges]);

    const yWidth = (): number => {
        const maxNetSales = Math.max(...chartData.map((item) => item.netSales));
        return calculateChartWidth(maxNetSales, true);
    };

    return (
        <div className="my-10">
            <div className="flex items-center justify-between">
                <h4 className="mb-2 text-large">{chartTitle}</h4>
                <ChartTypeSelect value={graphType} onChange={setGraphType} />
            </div>
            <SalesSummaryLegend />
            <div className="h-96 w-full">
                {match({ isLoading, hasError: !!error })
                    .with({ isLoading: true }, () => (
                        <Skeleton className="h-96" />
                    ))
                    .with({ hasError: true }, () => (
                        <ErrorChart className="h-96 rounded-md" />
                    ))
                    .otherwise(() => (
                        <LineChart
                            data={chartData}
                            categories={displayedCategories}
                            valueFormatter={valueFormatter}
                            customTooltip={SalesSummaryTooltip}
                            index={"label"}
                            colors={["blue", "yellow"]}
                            yAxisWidth={yWidth()}
                            minValue={0}
                            showLegend={false}
                        />
                    ))}
            </div>
        </div>
    );
};

const aggregateSummaryDataRows = (
    acc: TableSummaryDataRow,
    toAdd: TableSummaryDataRow
) => {
    for (const key in toAdd) {
        if ((acc[key] as SalesByChannelGroup).total) {
            const dataColumn = acc[key] as SalesByChannelGroup;
            const columnToAggregate = toAdd[key] as SalesByChannelGroup;

            dataColumn.total += columnToAggregate.total;
            dataColumn.snackpass += columnToAggregate.snackpass;
            dataColumn.thirdParty += columnToAggregate.snackpass;
        }
    }
    return acc;
};
