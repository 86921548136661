import React, { Dispatch, SetStateAction } from "react";
import { IHoursSchema } from "@snackpass/snackpass-types";
import styled from "styled-components";
import type { Moment } from "moment";

import { SpecialHoursItemType } from "../helpers/date-time-helper";

import { SpecialHourRow } from "./special-hours-row";

type SpecialHourProps = {
    show: boolean;
    setSpecialHours: Dispatch<SetStateAction<SpecialHoursItemType[]>>;
    specialHours: SpecialHoursItemType[];
    setShowSpecialHour: Dispatch<SetStateAction<boolean>>;
    storeHours: IHoursSchema | null;
    storeOpenDays: number[];
};

export const SpecialHourRows: React.FC<SpecialHourProps> = ({
    show,
    setSpecialHours,
    specialHours,
    setShowSpecialHour,
    storeOpenDays
}) => {
    const removeItem = (id: number) => {
        if (specialHours.length > 1) {
            const removedArr = [...specialHours].filter(
                (item) => item.id !== id
            );
            setSpecialHours(removedArr);
        } else {
            setShowSpecialHour(false);
        }
    };

    const updateItemHours = (idx: number, start?: Moment, end?: Moment) => {
        setSpecialHours((existingItems: SpecialHoursItemType[]) =>
            existingItems.map((item, j) => {
                const updatedItem = JSON.parse(JSON.stringify(item));
                updatedItem.time.start = start;
                updatedItem.time.end = end;
                return j === idx ? updatedItem : item;
            })
        );
    };

    const dayRows = () =>
        specialHours.map((element: SpecialHoursItemType) => (
            <SpecialHourRow
                key={`hour-row + ${element.id}`}
                show={show}
                id={element.id}
                dayOfWeek={element.dayOfWeek}
                specialHours={specialHours}
                setSpecialHours={setSpecialHours}
                removeItem={removeItem}
                updateItemHours={updateItemHours}
                storeOpenDays={storeOpenDays}
                start={element.time.start}
                end={element.time.end}
            />
        ));

    return (
        <SpecialHoursWrapper show={show}>
            {specialHours && dayRows()}
        </SpecialHoursWrapper>
    );
};

type Props = {
    show: boolean;
};

const SpecialHoursWrapper = styled.span<Props>`
    display: ${(props) => (props.show ? "flex" : "none")};
    flex-direction: column;
`;
