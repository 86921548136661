import React from "react";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "src/@/components/ui/alert-dialog";

type UnsavedChanges = {
    show: boolean;
    onConfirm: () => void;
    onCancel: () => void;
};

export function UnsavedChangesModal({
    show,
    onConfirm,
    onCancel
}: UnsavedChanges) {
    return show ? (
        <AlertDialog open={true}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle className="text-base">
                        Unsaved Changes
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                        Are you sure you want to leave?
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel onClick={onCancel}>
                        Cancel
                    </AlertDialogCancel>
                    <AlertDialogAction onClick={onConfirm}>
                        Continue
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    ) : null;
}

export default UnsavedChangesModal;
