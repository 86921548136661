import * as yup from "yup";

import { DealDropPromo, FIELD_NAMES } from "#promotion/utils/types";
import {
    toggleValidation,
    durationStartDateValidation,
    durationEndDateValidation
} from "#promotion/utils/validation/field-schemas";
import {
    GenericDefaultValues,
    GenericPromoValidations
} from "#promotion/utils/validation/form-schemas/generic";

export const DealDropDefaultValues = {
    ...GenericDefaultValues,
    // Type Overrides
    [FIELD_NAMES.DURATION_ENABLED]: true,
    [FIELD_NAMES.LIMIT_USES]: true
} as DealDropPromo;

export const DealDropValidationSchema = yup.object().shape({
    ...GenericPromoValidations,

    // Validation Overrides
    [FIELD_NAMES.DURATION_ENABLED]: toggleValidation
        .isTrue("Duration must be enabled for Deal Drop promotion")
        .required(),
    [FIELD_NAMES.LIMIT_USES]: toggleValidation
        .isTrue("Must have a usage limit for Deal Drop promotion")
        .required(),
    [FIELD_NAMES.DURATION_START_DATE]: durationStartDateValidation.concat(
        yup.string().required("Start date is required.")
    ),
    [FIELD_NAMES.DURATION_END_DATE]: durationEndDateValidation.concat(
        yup.string().required("End date is required.")
    )
});
