import { Card, CardContent, CardHeader } from "src/@/components/ui/card";
import { Money } from "src/utils";

type InvoicePreviewProps = {
    customerName?: string;
    customerEmail?: string;
    description?: string;
    amount?: number;
    salesTaxPercentage?: number;
    salesTaxAmount?: number;
    totalAmount?: number;
};

export const InvoicePreview = ({
    customerName,
    customerEmail,
    description,
    amount = 0,
    salesTaxPercentage = 0,
    salesTaxAmount = 0,
    totalAmount = 0
}: InvoicePreviewProps) => (
    <Card>
        <CardHeader>
            <p className="text-xl font-medium">Invoice Preview</p>
        </CardHeader>
        <CardContent className="space-y-4">
            {customerName && (
                <div>
                    <p>
                        <span className="font-bold">To:</span> {customerName}
                    </p>
                </div>
            )}
            {customerEmail && (
                <div>
                    <p>
                        <span className="font-bold">Send To:</span>{" "}
                        {customerEmail}
                    </p>
                </div>
            )}
            {description && (
                <div className="flex w-full justify-between pt-4">
                    <div>
                        <p>{description}</p>
                    </div>
                    {amount > 0 && (
                        <div>
                            <p>{Money.from.cents(amount).toFormat()}</p>
                        </div>
                    )}
                </div>
            )}
            {salesTaxAmount > 0 && (
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <p>Sales Tax ({salesTaxPercentage}%)</p>
                    </div>
                    <div className="text-right">
                        <p>{Money.from.cents(salesTaxAmount).toFormat()}</p>
                    </div>
                </div>
            )}
            {totalAmount > 0 && (
                <div className="grid grid-cols-2 gap-4 border-t pt-4">
                    <div className="font-bold">
                        <p>Total</p>
                    </div>
                    <div className="text-right font-bold">
                        <p>{Money.from.cents(totalAmount).toFormat()}</p>
                    </div>
                </div>
            )}
        </CardContent>
    </Card>
);
