import clsx from "clsx";

type OverlayContainerProps = {
    children: React.ReactNode;
    overlay?: React.ReactNode;
    overlayClassName?: string;
};

export function OverlayContainer({
    children,
    overlay,
    overlayClassName = "bg-[rgba(255,255,255,0.7)] text-body"
}: OverlayContainerProps) {
    return (
        <div className="relative">
            {children}
            {overlay && (
                <div
                    className={clsx(
                        "absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center",
                        overlayClassName
                    )}
                >
                    {overlay}
                </div>
            )}
        </div>
    );
}
