import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { EmployeeShiftDetails } from "@snackpass/snackpass-types";
import { DateTime } from "luxon";

import { getActiveStore } from "src/redux/selectors";
import API from "src/api/graphql";
import { PartialEmployee } from "src/api/graphql/generated/types";
import { mapEmployeeShiftDetailsGQLtoTS } from "#time-and-attendance/utils";
import { getEmployeesWithOngoingShifts } from "#employees/helpers";

type UsePaySummaryProps = {
    watchValueForRefresh: number;
    overrideEmployeeShiftDetails?: EmployeeShiftDetails[];
    overrideEmployeesWithOpenShifts?: PartialEmployee[];
    startDate: DateTime;
    endDate: DateTime;
};

const usePaySummary = ({
    watchValueForRefresh,
    overrideEmployeeShiftDetails,
    overrideEmployeesWithOpenShifts,
    startDate,
    endDate
}: UsePaySummaryProps) => {
    const activeStore = useSelector(getActiveStore);
    const [payPeriodId, setPayPeriodId] = useState("");
    const [employeeShiftDetails, setEmployeeShiftDetails] = useState<
        EmployeeShiftDetails[]
    >(overrideEmployeeShiftDetails ?? []);
    const [employeesWithOpenShifts, setEmployeesWithOpenShifts] = useState<
        string[]
    >(
        overrideEmployeesWithOpenShifts != null
            ? overrideEmployeesWithOpenShifts.map((emp) =>
                  emp.name != null ? emp.name : ""
              )
            : []
    );
    const [isLoading, setIsLoading] = useState<boolean>(
        !_.isNil(overrideEmployeeShiftDetails) ? false : true
    );

    useEffect(() => {
        if (!activeStore || !startDate || !endDate) return;
        const fetchPaySummary = async () => {
            API.stores
                .getTimecards({
                    storeId: activeStore._id,
                    startDate: startDate.startOf("day").toJSDate().toString(),
                    endDate: endDate.endOf("day").toJSDate().toString()
                })
                .then((paySummary) => {
                    if (paySummary) {
                        setPayPeriodId(paySummary.payPeriodId);
                        setEmployeeShiftDetails(
                            paySummary.employeeShiftDetails.map(
                                mapEmployeeShiftDetailsGQLtoTS
                            )
                        );
                        const employeesWithOngoing =
                            getEmployeesWithOngoingShifts(paySummary);
                        setEmployeesWithOpenShifts(employeesWithOngoing);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };
        setIsLoading(true);
        void fetchPaySummary();
    }, [activeStore, watchValueForRefresh, startDate, endDate]);

    return {
        payPeriodId,
        employeeShiftDetails,
        employeesWithOpenShifts,
        isLoading
    };
};

export default usePaySummary;
