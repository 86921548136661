import { keyBy } from "lodash";
import { useMemo } from "react";

import { useAppSelector } from "src/redux/hooks";
import { getActiveProductCategories } from "src/redux/selectors";

/**
 * Provides a lookup map for categories with its associated products.
 */
export function useCategoriesWithProductsMap() {
    const legacyCategories = useAppSelector(getActiveProductCategories);

    return useMemo(() => {
        const legacyCategoriesWithProducts = legacyCategories.map(
            (category) => ({
                id: category._id,
                name: category.name,
                productIds: category.productIds
            })
        );

        return keyBy(legacyCategoriesWithProducts, "id");
    }, [legacyCategories]);
}
