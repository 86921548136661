import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IStore } from "@snackpass/snackpass-types";

const storesInitialState: IStore[] = [];

export const storesSlice = createSlice({
    name: "stores",
    initialState: storesInitialState,
    reducers: {
        setStores: (_, action: PayloadAction<IStore[]>) => action.payload
    }
});

export const { setStores } = storesSlice.actions;
