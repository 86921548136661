import React from "react";

const MAX_OFFLINE_HOURS = 48;

function OfflinePaymentTerms() {
    return (
        <div className="flex flex-col gap-4 pt-4 md:pt-2">
            <div>
                <div className="text-base font-semibold leading-6">
                    Offline Payment Risks
                </div>
                <div className="text-small leading-5">
                    There is additional risk when accepting payments offline.
                    Snackpass is not responsible for any loss due to declined
                    cards or expired payments taken while offline. In addition,
                    Snackpass can not contact any customers on your behalf
                    should a payment be declined or expire when taken as an
                    offline payment.
                </div>
            </div>
            <div>
                <div className="text-base font-semibold leading-6">
                    Time Limit
                </div>
                <div className="text-small leading-5">
                    The Device needs to be reconnected to the internet within{" "}
                    {MAX_OFFLINE_HOURS} hours. We are unable to run the card
                    information due to card network restrictions after{" "}
                    {MAX_OFFLINE_HOURS} hours. When your Device is in Offline
                    Mode, there will be an orange banner across the top to
                    remind you that you're currently offline and to reconnect
                    within {MAX_OFFLINE_HOURS} hours.
                </div>
            </div>
            <div>
                <div className="text-base font-semibold leading-6">
                    Offline Transaction Details
                </div>
                <div className="text-small leading-5">
                    Offline transactions do not communicate with the server or
                    cardholders' bank. The following cannot be confirmed, which
                    may cause declined transactions after all operations are
                    restored and the customer is no longer present in the
                    establishment:
                    <ul>
                        {[
                            "If there are sufficient funds on the card",
                            "If the card is not expired",
                            "If the cardholder's account is not closed",
                            "If the card is not stolen",
                            "If the transaction is not fraudulent"
                        ].map((cause) => (
                            <li key={cause}>{cause}</li>
                        ))}
                    </ul>
                </div>
            </div>
            <div>
                <div className="text-base font-semibold leading-6">
                    Disabled Features
                </div>
                <div className="text-small leading-5">
                    The following features will not work while in offline mode
                    <ul>
                        {[
                            "Using Gift Cards",
                            "Buying Gift Cards",
                            "Inventory Management",
                            "Catering",
                            "Promotions (use keypad discounts instead)",
                            "Reward Redemption",
                            "Receipt Numbers (uses customer name instead)",
                            "Server Direct Printing",
                            "Manual Credit Card Entry",
                            "Unpaid Orders",
                            "Cash Tracking (mark as payment Other instead)"
                        ].map((feature) => (
                            <li key={feature}>{feature}</li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="mt-6 text-small leading-6 text-[#A0A2A7]">
                Effective August 1st, 2024. Terms subject to change.
            </div>
            <div className="sticky inset-x-0 bottom-0 block  min-h-[30px] bg-gradient-to-t from-white to-transparent">
                &nbsp;
            </div>
        </div>
    );
}

export default OfflinePaymentTerms;
