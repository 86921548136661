import { useContext, useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import { Checkbox, Col, Form, Input, Row, Select } from "antd";

import "antd/dist/antd.css";
import { debounce } from "radash";

import api from "src/api/rest";
import CloseButton from "#payouts/components/shared/CloseButton";
import { StyledModal, Header } from "#/payouts/components/shared/Modal";
import {
    StoreVerificationAddress,
    VerificationsResponse
} from "#payouts/domain/types";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { personDescriptions } from "#payouts/utils/descriptions";
import { PersonSchema } from "#payouts/utils/validation/schema";
import { stateList } from "#payouts/utils/StateList";
import { dayOptions, monthOptions, yearOptions } from "#payouts/utils/dates";
import theme from "#payouts/utils/theme";
import { appendToBody } from "#payouts/utils/appendToBody";
import { notifyFailure } from "#payouts/utils/notify";
import { messages } from "#payouts/utils/messages";
import { handleException } from "#payouts/utils/handleException";
import { Label, RowDivider } from "#payouts/components/shared/ModalComponents";
import { filterOption, filterSort } from "#payouts/utils/filterOptions";
import useWindowDimensions from "#hooks/use-window-dimensions";
import {
    birthDayValidation,
    birthMonthValidation,
    birthYearValidation,
    cityValidation,
    countryValidation,
    emailValidation,
    firstNameValidation,
    idNumberValidation,
    lastNameValidation,
    line1Validation,
    line2Validation,
    phoneValidation,
    postalCodeValidation,
    stateValidation,
    titleValidation
} from "#payouts/utils/validation/form";
import { UpdatedButton } from "#payouts/components/shared/UpdatedButton";
import HeaderInfo from "#table/HeaderInfo";
import { ModalRequirement } from "#payouts/components/shared/ModalRequirement";

export const RepresentativeModal = () => {
    const [form] = Form.useForm();

    const ssnInput = useRef<typeof Input | null>(null);

    const { isDesktop } = useWindowDimensions();

    const {
        storeId,
        user,
        verification,
        person,
        setVerification,
        personModal,
        handleModalChange
    } = useContext(PayoutsSettingsContext);

    // Check if we're editing an existing user, or creating a new one
    const updateToExisting = !!person?.personId;

    const [hasChange, setHasChange] = useState(false);
    const [loading, setLoading] = useState(false);

    const idNumberProvided = !!person?.idNumber;

    if (person?.address && /Not-Provided/i.test(person?.address?.line2 ?? "")) {
        person.address.line2 = undefined;
    }

    const [firstName, setFirstName] = useState(person?.firstName);
    const [lastName, setLastName] = useState(person?.lastName);
    const [email, setEmail] = useState(person?.email);
    const [title, setTitle] = useState(person?.title);
    const [isRep, _] = useState(person?.representative ?? false);
    const [isOwn, setIsOwn] = useState(person?.owner ?? false);
    const [isExec, setIsExec] = useState(person?.executive ?? false);
    const [phone, setPhone] = useState(person?.phone?.replace("+1", ""));
    const [address, setAddress] = useState({
        line1: person?.address?.line1,
        line2: person?.address?.line2,
        city: person?.address?.city,
        state: person?.address?.state,
        postalCode: person?.address?.postalCode,
        country: person?.address?.country ?? "US"
    });
    const [idNumber, setIdNumber] = useState(
        idNumberProvided ? "Provided" : undefined
    );

    const [filteredStates, setFilteredStates] = useState([...stateList]);

    const initialDob = person?.dateOfBirth
        ? DateTime.fromSeconds(person.dateOfBirth).setZone("UTC").toObject()
        : { month: undefined, day: undefined, year: undefined };

    const [dateOfBirth, setDateOfBirth] = useState<{
        month?: number;
        day?: number;
        year?: number;
    }>(initialDob);

    const error = personModal
        ? !PersonSchema.safeParse({
              firstName,
              lastName,
              email,
              title,
              owner: isOwn,
              executive: isExec,
              dateOfBirth,
              phone,
              address,
              idNumber
          }).success
        : false;

    // Check if others, or current email match active user login email
    const currentEmailGood =
        email?.toLowerCase() === user?.email?.toLowerCase();
    const othersGood = !!verification?.representatives?.filter(
        (r) =>
            r.id !== person?.id &&
            r.email.toLowerCase() === user?.email?.toLowerCase()
    ).length;
    const emailGood = currentEmailGood || othersGood;

    const satisfiesRepresentative =
        isOwn ||
        isExec ||
        !!verification?.representatives?.filter(
            (r) => r.id !== person?.id && (r.owner || r.executive)
        ).length;

    const handleStateSearch = (search: string) => {
        setFilteredStates(
            search.length
                ? [...stateList].filter(
                      (c) =>
                          c.label
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                          c.value.toLowerCase().includes(search.toLowerCase())
                  )
                : [...stateList]
        );
    };

    const handleAddressChange = (update: Partial<StoreVerificationAddress>) => {
        setHasChange(true);
        setAddress({ ...address, ...update });
    };

    const handleDateOfBirthChange = (update: {
        day?: number;
        month?: number;
        year?: number;
    }) => {
        setHasChange(true);
        setDateOfBirth({ ...dateOfBirth, ...update });
    };

    const resetState = () => {
        setHasChange(false);
        setLoading(false);
        handleModalChange();
    };

    const handleSubmit = async (params: FormData): VerificationsResponse => {
        if (disabled) {
            return Promise.reject();
        }
        setLoading(true);
        return updateToExisting && person?.personId
            ? api.verifications.updatePerson(storeId, person.personId, params)
            : api.verifications.createPerson(storeId, params);
    };

    const debouncedSubmit = debounce({ delay: 300 }, async (u: FormData) =>
        handleSubmit(u)
            .then((response) => {
                const { data } = response;
                if (data?.success) {
                    setVerification(data.verification);
                    resetState();
                } else {
                    notifyFailure(messages.modal.representative);
                    handleException(data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                notifyFailure({
                    message: messages.modal.representative.message,
                    description:
                        error.cause?.response?.data?.error ??
                        messages.modal.representative.description
                });
                handleException(error);
                setLoading(false);
            })
    );

    useEffect(() => {
        if (
            personModal &&
            person?.id &&
            ssnInput.current &&
            !idNumberProvided
        ) {
            setTimeout(() => {
                // @ts-expect-error - scrollIntoView is a valid method
                ssnInput?.current.scrollIntoView({
                    behavior: "smooth"
                });
            }, 300);
        }
    }, [person, person?.id, personModal, ssnInput, idNumberProvided]);

    const disabled = !storeId || loading;
    const isValid = hasChange && !error;

    const tooltip = [
        "We are required by by legal and financial regulators to collect certain information from account holders to prevent abuse of the financial system.",
        "We do not use this information for any other purposes, and we take the privacy and the security of your data very seriously.",
        "The security tools and best practices implemented by us and our payments providers ensure that your sensitive information is safely stored and encrypted."
    ].join("\\n");

    const Center = () => (
        <div>
            <span>Individual Details</span>
            {tooltip ? (
                <HeaderInfo multiline linegap message={tooltip} />
            ) : null}
        </div>
    );

    if (!personModal) return <></>;

    return (
        <StyledModal
            open={personModal}
            setOpen={() => handleModalChange()}
            header={
                <Header
                    left={<CloseButton onClose={() => resetState()} />}
                    center={<Center />}
                />
            }
            footer={
                <>
                    <UpdatedButton
                        smallRadius
                        block
                        variant="tertiary"
                        size="regular"
                        onClick={() => handleModalChange()}
                        disabled={disabled}
                        isValid={true} // cancel is always valid
                        children={<>Cancel</>}
                    />
                    <UpdatedButton
                        smallRadius
                        block
                        variant="primary"
                        size="regular"
                        disabled={disabled}
                        isValid={isValid}
                        loading={loading}
                        children={<>{updateToExisting ? "Update" : "Add"}</>}
                        onClick={async () => {
                            if (disabled) {
                                return;
                            }
                            if (!isValid) {
                                form.setFields([
                                    {
                                        name: "firstName",
                                        errors: firstNameValidation(firstName)
                                    },
                                    {
                                        name: "lastName",
                                        errors: lastNameValidation(lastName)
                                    },
                                    {
                                        name: "email",
                                        errors: emailValidation(email)
                                    },
                                    {
                                        name: "title",
                                        errors: titleValidation(title)
                                    },
                                    {
                                        name: "month",
                                        errors: birthMonthValidation(
                                            dateOfBirth.month
                                        )
                                    },
                                    {
                                        name: "day",
                                        errors: birthDayValidation(
                                            dateOfBirth.day
                                        )
                                    },
                                    {
                                        name: "year",
                                        errors: birthYearValidation(
                                            dateOfBirth.year
                                        )
                                    },
                                    {
                                        name: "line1",
                                        errors: line1Validation(address.line1)
                                    },
                                    {
                                        name: "line2",
                                        errors: line2Validation(address.line2)
                                    },
                                    {
                                        name: "city",
                                        errors: cityValidation(address.city)
                                    },
                                    {
                                        name: "state",
                                        errors: stateValidation(address.state)
                                    },
                                    {
                                        name: "postalCode",
                                        errors: postalCodeValidation(
                                            address.postalCode
                                        )
                                    },
                                    {
                                        name: "country",
                                        errors: countryValidation(
                                            address.country
                                        )
                                    },
                                    {
                                        name: "phone",
                                        errors: phoneValidation(phone)
                                    },
                                    {
                                        name: "idNumber",
                                        errors: idNumberValidation(idNumber)
                                    }
                                ]);
                                return;
                            }

                            const body = new FormData();
                            appendToBody(body, "firstName", firstName?.trim());
                            appendToBody(body, "lastName", lastName?.trim());
                            appendToBody(body, "email", email?.trim());
                            appendToBody(body, "owner", isOwn.toString());
                            appendToBody(body, "executive", isExec.toString());
                            appendToBody(
                                body,
                                "representative",
                                isRep.toString()
                            );
                            appendToBody(body, "title", title?.trim());
                            const dob =
                                DateTime.fromObject(dateOfBirth).toSeconds();

                            appendToBody(body, "dateOfBirth", dob);
                            appendToBody(
                                body,
                                "phone",
                                ["+1", phone?.trim()].join("")
                            );
                            appendToBody(
                                body,
                                "address",
                                JSON.stringify(address)
                            );
                            appendToBody(body, "idNumber", idNumber?.trim());

                            void debouncedSubmit(body);
                        }}
                    />
                </>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    firstName,
                    lastName,
                    email,
                    owner: isOwn,
                    executive: isExec,
                    title,
                    line1: address.line1,
                    line2: address.line2,
                    city: address.city,
                    state: address.state,
                    postalCode: address.postalCode,
                    country: address.country,
                    dateOfBirth,
                    month: dateOfBirth.month,
                    day: dateOfBirth.day,
                    year: dateOfBirth.year,
                    phone,
                    idNumber
                }}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="firstName"
                            style={{ marginBottom: theme.spacing.half }}
                            label={
                                <Label
                                    required
                                    label="Legal Name"
                                    subtitle={personDescriptions.name}
                                />
                            }
                        >
                            <Input
                                placeholder="First name"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const errors = firstNameValidation(value);
                                    setHasChange(true);
                                    setFirstName(value);
                                    form.setFields([
                                        { name: "firstName", value, errors }
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="lastName">
                            <Input
                                placeholder="Last name"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const errors = lastNameValidation(value);
                                    setHasChange(true);
                                    setLastName(value);
                                    form.setFields([
                                        { name: "lastName", value, errors }
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <RowDivider />
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="email"
                            label={
                                <Label
                                    required
                                    label={"Email"}
                                    subtitle={personDescriptions.email}
                                />
                            }
                        >
                            <Input
                                placeholder="name@example.com"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const errors = emailValidation(value);
                                    setEmail(value.toLowerCase());
                                    setHasChange(true);
                                    form.setFields([
                                        { name: "email", value, errors }
                                    ]);
                                }}
                            />
                        </Form.Item>
                        <ModalRequirement
                            good={emailGood}
                            message={`  Your login email (${user?.email}) must used for at least one owner or executive.`}
                        />
                    </Col>
                </Row>
                <RowDivider />
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="title"
                            label={
                                <Label
                                    required
                                    label={"Representative"}
                                    subtitle={personDescriptions.representative}
                                />
                            }
                        >
                            <Input
                                placeholder="Owner"
                                addonBefore="Title"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const errors = titleValidation(value);
                                    setHasChange(true);
                                    setTitle(value);
                                    form.setFields([
                                        { name: "title", value, errors }
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Form.Item name="isOwn">
                        <Checkbox
                            checked={isOwn}
                            onChange={(e) => {
                                const value = e.target.checked;
                                setHasChange(true);
                                setIsOwn(value);
                                form.setFields([{ name: "isOwn", value }]);
                            }}
                        >
                            {personDescriptions.owner}
                        </Checkbox>
                    </Form.Item>
                </Row>
                <Row>
                    <Form.Item name="isExec">
                        <Checkbox
                            checked={isExec}
                            children={personDescriptions.executive}
                            onChange={(e) => {
                                const value = e.target.checked;
                                setHasChange(true);
                                setIsExec(value);
                                form.setFields([{ name: "isExec", value }]);
                            }}
                        />
                    </Form.Item>
                    <ModalRequirement
                        good={satisfiesRepresentative}
                        message={personDescriptions.representativeClarifier}
                    />
                </Row>
                <RowDivider />
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="dateOfBirth"
                            style={{ marginBottom: 0 }}
                            label={
                                <Label
                                    required
                                    label={"Date of Birth"}
                                    subtitle={personDescriptions.dateOfBirth}
                                />
                            }
                        >
                            <Row>
                                <Col span={12}>
                                    <Form.Item name="month">
                                        <Select
                                            className="left"
                                            placeholder="Month"
                                            onChange={(month) => {
                                                const errors =
                                                    birthMonthValidation(month);
                                                setHasChange(true);
                                                handleDateOfBirthChange({
                                                    month
                                                });
                                                form.setFields([
                                                    {
                                                        name: "month",
                                                        value: month,
                                                        errors
                                                    }
                                                ]);
                                            }}
                                        >
                                            {monthOptions}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name="day">
                                        <Select
                                            className="center"
                                            placeholder="Day"
                                            onChange={(day) => {
                                                const errors =
                                                    birthDayValidation(day);
                                                setHasChange(true);
                                                handleDateOfBirthChange({
                                                    day
                                                });
                                                form.setFields([
                                                    {
                                                        name: "day",
                                                        value: day,
                                                        errors
                                                    }
                                                ]);
                                            }}
                                        >
                                            {dayOptions}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="year">
                                        <Select
                                            className="right"
                                            placeholder="Year"
                                            onChange={(year) => {
                                                const errors =
                                                    birthYearValidation(year);
                                                setHasChange(true);
                                                handleDateOfBirthChange({
                                                    year
                                                });
                                                form.setFields([
                                                    {
                                                        name: "year",
                                                        value: year,
                                                        errors
                                                    }
                                                ]);
                                            }}
                                        >
                                            {yearOptions}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
                <RowDivider />
                <Row>
                    <Col span={24}>
                        <Form.Item
                            className="compact"
                            name="country"
                            label={
                                <Label
                                    required
                                    label="Home Address"
                                    subtitle={personDescriptions.address}
                                />
                            }
                        >
                            <Select
                                disabled
                                placeholder="Country"
                                className="full"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const errors = countryValidation(value);
                                    setHasChange(true);
                                    handleAddressChange({ country: value });
                                    form.setFields([
                                        { name: "country", value, errors }
                                    ]);
                                }}
                            >
                                {[
                                    <Select.Option value="US" key="US">
                                        United States
                                    </Select.Option>
                                ]}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="line1" className="compact">
                            <Input
                                placeholder="Address line 1"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const errors = line1Validation(value);
                                    setHasChange(true);
                                    handleAddressChange({ line1: value });
                                    form.setFields([
                                        { name: "line1", value, errors }
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="line2" className="compact">
                            <Input
                                placeholder="Address line 2 (optional)"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const errors = line2Validation(value);
                                    setHasChange(true);
                                    handleAddressChange({ line2: value });
                                    form.setFields([
                                        { name: "line2", value, errors }
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item className="compact">
                            <Row>
                                <Col span={isDesktop ? 12 : 10}>
                                    <Form.Item name="city">
                                        <Input
                                            placeholder="City"
                                            className="left"
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const errors =
                                                    cityValidation(value);
                                                setHasChange(true);
                                                handleAddressChange({
                                                    city: value
                                                });
                                                form.setFields([
                                                    {
                                                        name: "city",
                                                        value,
                                                        errors
                                                    }
                                                ]);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={isDesktop ? 4 : 6}>
                                    <Form.Item name="state">
                                        <Select
                                            placeholder="State"
                                            className="center"
                                            onChange={(state) => {
                                                const errors: string[] = [];
                                                setHasChange(true);
                                                handleAddressChange({ state });
                                                form.setFields([
                                                    {
                                                        name: "state",
                                                        value: state,
                                                        errors
                                                    }
                                                ]);
                                            }}
                                            showSearch
                                            onSearch={handleStateSearch}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                filterOption(
                                                    input,
                                                    option?.children?.toString()
                                                )
                                            }
                                            filterSort={(a, b) =>
                                                filterSort(
                                                    a?.children?.toString(),
                                                    b?.children?.toString()
                                                )
                                            }
                                        >
                                            {filteredStates.map((s) => (
                                                <Select.Option
                                                    key={s.value}
                                                    value={s.value}
                                                >
                                                    {s.value}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="postalCode">
                                        <Input
                                            placeholder="Postal Code"
                                            className="right"
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const errors =
                                                    postalCodeValidation(value);
                                                setHasChange(true);
                                                handleAddressChange({
                                                    postalCode: value
                                                });
                                                form.setFields([
                                                    {
                                                        name: "postalCode",
                                                        value,
                                                        errors
                                                    }
                                                ]);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
                <RowDivider />
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="phone"
                            label={
                                <Label
                                    required
                                    label="Personal Phone Number"
                                    subtitle={personDescriptions.phone}
                                />
                            }
                        >
                            <Input
                                addonBefore="+1"
                                placeholder="(202) 358-0000"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const errors = phoneValidation(value);
                                    setHasChange(true);
                                    setPhone(value);
                                    form.setFields([
                                        { name: "phone", value, errors }
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <RowDivider />
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="idNumber"
                            label={
                                <Label
                                    required
                                    label="Full Social Security Number"
                                    subtitle={
                                        updateToExisting && idNumberProvided ? (
                                            personDescriptions.idNumberProvided
                                        ) : (
                                            <>
                                                Please reach out to
                                                <a
                                                    className="px-1"
                                                    onClick={() => {
                                                        window.Intercom("show");
                                                    }}
                                                >
                                                    support
                                                </a>
                                                if you prefer to use an ITIN.
                                            </>
                                        )
                                    }
                                />
                            }
                        >
                            {/* @ts-expect-error - ref is a valid prop */}
                            <div ref={ssnInput}>
                                <Input
                                    className={
                                        // if already provided, or a new person show normal
                                        idNumberProvided || !person?.id
                                            ? undefined
                                            : "border border-red-600 bg-red-100"
                                    }
                                    placeholder={
                                        idNumber ? "Provided" : "123-45-6789"
                                    }
                                    disabled={idNumberProvided}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const errors =
                                            idNumberValidation(value);
                                        setHasChange(true);
                                        setIdNumber(value);
                                        form.setFields([
                                            {
                                                name: "idNumber",
                                                value,
                                                errors
                                            }
                                        ]);
                                    }}
                                />
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledModal>
    );
};
