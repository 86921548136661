import { Label } from "src/@/components/ui/label";
import { Switch } from "src/@/components/ui/switch";

type Props = {
    value: boolean;
    onChange: (value: boolean) => void;
    label?: string;
};

export const ShowLegendSwitch = ({
    value,
    onChange,
    label = "Show Legend"
}: Props) => (
    <div className="flex items-center space-x-2">
        <Switch checked={value} onCheckedChange={onChange} />
        <Label>{label}</Label>
    </div>
);
