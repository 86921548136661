import { IStore } from "@snackpass/snackpass-types";

import { FormValues } from "#settings/settings-senior-support/one-off-payout";
import { Button } from "src/@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle
} from "src/@/components/ui/dialog";
import { formatCurrencyFromDollars } from "#billing/domain";
import { PayoutItem } from "#settings/settings-senior-support/one-off-payout/payout-item";

type Props = {
    store: IStore;
    values: FormValues;
    isVisible: boolean;
    canEdit: boolean;
    onClose: () => void;
    onSubmit: (v: FormValues) => void;
};

export const CreatePayoutPopover = ({
    store,
    values,
    isVisible,
    canEdit,
    onClose,
    onSubmit
}: Props) => {
    if (!isVisible) {
        return null;
    }

    const amount = formatCurrencyFromDollars(values.amountDollars);

    return (
        <Dialog
            open={isVisible}
            onOpenChange={(open: boolean) => {
                if (!open) {
                    onClose();
                }
            }}
        >
            <DialogContent className={"overflow-y-scroll bg-white"}>
                <DialogHeader className="w-full border-b border-neutral-400">
                    <DialogTitle className="mb-6 text-body">
                        Are you sure you want to pay {amount}?
                    </DialogTitle>
                </DialogHeader>
                <div className="flex flex-col">
                    <PayoutItem label="Store" value={store.name} />
                    <PayoutItem
                        label="Address"
                        value={store.addressComponents.line1!}
                    />
                    <PayoutItem label="Amount" value={amount} />
                    <PayoutItem
                        label="Description"
                        value={values.description}
                    />
                </div>
                <div className="sticky flex flex-col bg-white">
                    <div className="flex justify-around gap-4">
                        <Button
                            variant="outline"
                            className="flex-1"
                            type="submit"
                            onClick={onClose}
                        >
                            No
                        </Button>
                        <Button
                            className="flex-1 text-white"
                            type="submit"
                            disabled={!canEdit}
                            onClick={() => onSubmit(values)}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};
