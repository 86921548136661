import React, { useContext } from "react";
import styled from "styled-components";
import { Divider } from "antd";

import { SettingsBlock } from "#payouts/components/shared/SettingsBlock";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { RequirementBlock } from "#payouts/components/shared/RequirementBlock";
import { EmailRequirementBlock } from "#payouts/components/shared/EmailRequirementBlock";
import { useAccount } from "#payouts/utils/hooks/useAccount";
import { useCompany } from "#payouts/utils/hooks/useCompany";
import { useRepresentative } from "#payouts/utils/hooks/useRepresentative";
import { ReactComponent as StatusCircleIcon } from "src/assets/icons/status-circle.svg";
import { PersonStatusColors } from "#payouts/domain/types";

export const SubmitSummary = () => {
    const { editEnabled, verification, handleModalChange } = useContext(
        PayoutsSettingsContext
    );

    const account = useAccount();
    const company = useCompany();
    const representative = useRepresentative();

    const disabled = !editEnabled || !representative.hasEligiblePrimary;

    if (!verification || representative.done) return <></>;

    return (
        <SummaryDiv>
            <SettingsBlock
                start
                startText="Submit"
                disabled={disabled}
                title="Payouts Setup"
                onClick={() => handleModalChange({ terms: true })}
                settings={[]}
            />
            <div className="mb-2">
                You must meet the following requirements before you can submit
                your information for review:
            </div>
            <RequirementBlock
                good={company.done}
                message={"Provide company information"}
            />
            <RequirementBlock
                good={account.done}
                message={"Provide a business bank account"}
            />
            <RequirementBlock
                good={representative.hasVerifiedOwnerOrExec}
                message={
                    <div className="my-auto flex flex-row flex-wrap">
                        <div className="my-auto">Provide at least one</div>
                        <div className="mx-1 rounded border px-2">
                            <StatusCircleIcon
                                style={{
                                    margin: "auto 0",
                                    marginBottom: "2px",
                                    marginRight: "2px"
                                }}
                                width={8}
                                height={8}
                                fill={PersonStatusColors.verified}
                            />
                            Verified
                        </div>
                        <div className="my-auto">
                            owner or executive in representatives
                        </div>
                    </div>
                }
            />
            <EmailRequirementBlock />
            <Divider />
        </SummaryDiv>
    );
};

const SummaryDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    span {
        margin-left: ${({ theme }) => theme.spacing.half};
    }
`;
