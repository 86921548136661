import { createContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { getActiveStoreId } from "src/redux/slices/activeStoreId";

type TaxFormsContextT = {
    storeId: string;
    emailVerified: boolean;
    setEmailVerified: (value: boolean) => void;
};

export const TaxFormsContext = createContext<TaxFormsContextT>(
    {} as TaxFormsContextT
);

export const TaxFormsProvider = ({
    children
}: {
    children: React.ReactNode;
}) => {
    const [emailVerified, setEmailVerified] = useState(false);
    const activeStoreId = useSelector(getActiveStoreId);

    const value = useMemo(
        () => ({
            storeId: activeStoreId,
            emailVerified,
            setEmailVerified
        }),
        [emailVerified, setEmailVerified]
    );

    return (
        <TaxFormsContext.Provider value={value}>
            {children}
        </TaxFormsContext.Provider>
    );
};
