/** @jsxImportSource @emotion/react */
import "antd/dist/antd.css";
import { Col, Row, Spin } from "antd";
import { isEqual } from "lodash";
import React, {
    CSSProperties,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ScreenState } from "@snackpass/snackpass-types";
import { useMediaQuery } from "react-responsive";

import api from "src/api/rest";
import constants from "#core/constants";
import { getActiveStore, getCurrentMultiMenu } from "src/redux/selectors";
import {
    getMultiMenuLoadingState,
    getStoreMultiMenus
} from "#menu-editor/multi-menus/redux/selectors";
import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import { multiMenuThunks } from "#menu-editor/multi-menus/redux/thunks";
import { MenuTimeConflicts } from "#menu-editor/multi-menus/redux/types";
import {
    openNotification,
    NotificationPosition
} from "#menu-editor/multi-menus/shared-components/notification";
import { ScreenLayout } from "#menu-editor/multi-menus/styled-components/layout";

import { Text } from "#menu-editor/multi-menus/styled-components/text";

import { TimeConflictsModal } from "./components/time-conflicts-modal";

import { useAppDispatch } from "src/redux/hooks";
import { ScreenFooter } from "../../shared-components/footer";
import { MobileHeader } from "../../shared-components/mobile-header";

import { ResetOverridesModal } from "./components/reset-overrides-modal";
import { MenuNavigation } from "./components/navigation-arrows";
import { MenuDetailsForm } from "./components/menu-settings-form";
import { MenuAlert, rulesDefaultValue } from "./components/rule-alerts";
import { DeleteMenuModal } from "./components/delete-menu-modal";
import {
    checkValidationErrors,
    checkValidationWarnings
} from "./utils/rule-validators";
import { logAndSendError } from "src/utils/errors";

export const MultiMenuSettings = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const activeStore = useSelector(getActiveStore);
    const currentMenu = useSelector(getCurrentMultiMenu);
    const storeMenus = useSelector(getStoreMultiMenus);
    const {
        params: { id: menuId }
    } = useRouteMatch<{ id: string }>();
    const loadingState = useSelector(
        getMultiMenuLoadingState("FetchStoreMenus")
    );
    const saveState = useSelector(getMultiMenuLoadingState("UpdateMultiMenu"));
    const [timeConflicts, setTimeConflicts] = useState<MenuTimeConflicts[]>([]);
    const [isConflictModalOpen, setIsConflictModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
    const [ruleAlertType, setRuleAlertType] =
        useState<MenuAlert>(rulesDefaultValue);
    const [hasValidationErrors, setHasValidationErrors] = useState(
        !isEqual(
            rulesDefaultValue,
            checkValidationErrors(storeMenus, currentMenu)
        )
    );
    const menuName = useMemo(() => currentMenu.name, [currentMenu.id]);
    const topAlerts = useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`
    });
    const navigateToMenuOutline = useCallback(async () => {
        dispatch(multiMenuActions.selectMultiMenu({ id: menuId }));
        history.push(`/multi-menus/${currentMenu.id}`);
    }, [history]);
    const checkMenuTypeChanged = () =>
        !(
            currentMenu.type ===
            storeMenus.find(({ id }) => currentMenu.id === id)?.type
        );
    const checkPriceChanged = () =>
        !isEqual(
            currentMenu.priceAdjustment,
            storeMenus.find(({ id }) => currentMenu.id === id)?.priceAdjustment
        );
    const submitMenu = async () =>
        dispatch(
            multiMenuThunks.persistCurrentMenu([
                "name",
                "enabled",
                "type",
                "platforms",
                "hours",
                "priceAdjustment"
            ])
        )
            .unwrap()
            .then((menu) => history.push(`/multi-menus/${menu.id}`))
            .catch((err) => {
                logAndSendError(err);
                openNotification({
                    message: "Failed to update menu",
                    description:
                        "Failed to update current menu, please try again later.",
                    position: NotificationPosition.TopCenter
                });
            });

    useEffect(() => {
        const selectMenu = async () => {
            await dispatch(
                multiMenuThunks.selectMultiMenu({
                    menuId,
                    onMenuNotFound: () => history.replace("/multi-menus")
                })
            );
        };

        if (loadingState === "succeeded") selectMenu().catch(logAndSendError);
    }, [menuId, loadingState]);

    useEffect(() => {
        if (activeStore)
            setRuleAlertType(
                checkValidationWarnings(
                    rulesDefaultValue,
                    activeStore,
                    currentMenu.hours,
                    currentMenu.name
                )
            );
    }, [currentMenu.id]);

    useEffect(() => {
        if (!isResetModalOpen)
            dispatch(multiMenuActions.resetLoadingUpdateOverrides());
    }, [isResetModalOpen]);

    useMemo(() => {
        // On menu change, check for validation errors. This will affect ability to save.
        setHasValidationErrors(
            !isEqual(
                rulesDefaultValue,
                checkValidationErrors(storeMenus, currentMenu)
            )
        );
    }, [currentMenu, storeMenus]);

    const onSubmit = async () => {
        if (activeStore) {
            const { type, hours, platforms, enabled, name, priceAdjustment } =
                currentMenu;
            const detectedAlerts = checkValidationErrors(
                storeMenus,
                currentMenu
            );
            const detectedAlertsAndWarnings = checkValidationWarnings(
                detectedAlerts,
                activeStore,
                hours,
                name
            );
            setRuleAlertType(detectedAlertsAndWarnings);
            if (!isEqual(rulesDefaultValue, detectedAlerts)) {
                topAlerts.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                });
                return;
            }

            if (enabled && type && hours && platforms && platforms.length > 0) {
                const conflictsResponse = await api.multiMenus.checkConflicts(
                    activeStore._id,
                    {
                        id: menuId,
                        type,
                        hours,
                        platforms
                    }
                );
                if (conflictsResponse.length > 0) {
                    setTimeConflicts(conflictsResponse);
                    setIsConflictModalOpen(true);
                    return;
                }
            }
            if (checkMenuTypeChanged())
                dispatch(multiMenuActions.resetMenuItems());

            if (
                priceAdjustment &&
                priceAdjustment.value !== 1 &&
                checkPriceChanged()
            ) {
                setIsPriceModalOpen(true);
                return;
            }
            await submitMenu();
        }
    };

    return (
        <>
            <ScreenLayout
                breadcrumbItems={
                    isMobile
                        ? undefined
                        : [
                              {
                                  label: "Items",
                                  to: "/menu-editor"
                              },
                              { label: "Menus", to: "/multi-menus" },
                              {
                                  label: menuName ?? "Selected Menu",
                                  to: `/multi-menus/${currentMenu.id}`
                              },
                              {
                                  label: "Settings",
                                  to: `/multi-menus-settings/${currentMenu.id}`
                              }
                          ]
                }
                header={
                    isMobile ? (
                        <MobileHeader title="Menu Settings" />
                    ) : (
                        <Row>
                            <Col span={12}>
                                <Text type="title">Menu Settings</Text>
                            </Col>
                            <Col span={12} style={navigationCol}>
                                <MenuNavigation menuId={currentMenu.id} />
                            </Col>
                        </Row>
                    )
                }
                content={
                    loadingState !== "succeeded" ? (
                        <SpinnerWrapper>
                            <Spin size="large" />
                        </SpinnerWrapper>
                    ) : (
                        <ContentRow>
                            <Col span={isMobile ? 22 : 18} ref={topAlerts}>
                                <Spin
                                    spinning={saveState === "pending"}
                                    tip="Saving..."
                                    size="large"
                                >
                                    <MenuDetailsForm
                                        activeStore={activeStore}
                                        inEdit={true}
                                        ruleAlertType={ruleAlertType}
                                        setRuleAlertType={setRuleAlertType}
                                        setIsDeleteModalOpen={
                                            setIsDeleteModalOpen
                                        }
                                        setIsResetModalOpen={
                                            setIsResetModalOpen
                                        }
                                        setIsPriceModalOpen={
                                            setIsPriceModalOpen
                                        }
                                        isPriceModalOpen={isPriceModalOpen}
                                        onUpdatePrice={async () => {
                                            setIsPriceModalOpen(false);
                                            await submitMenu();
                                        }}
                                        isLoading={saveState === "pending"}
                                        isMobile={isMobile}
                                    />
                                </Spin>
                            </Col>
                        </ContentRow>
                    )
                }
                footer={
                    <ScreenFooter
                        onCancel={navigateToMenuOutline}
                        onSubmit={onSubmit}
                        disabled={
                            saveState === "pending" || hasValidationErrors
                        }
                    />
                }
            />

            <TimeConflictsModal
                isModalOpen={isConflictModalOpen}
                setIsModalOpen={setIsConflictModalOpen}
                conflicts={timeConflicts}
            />
            <DeleteMenuModal
                isModalOpen={isDeleteModalOpen}
                setIsModalOpen={setIsDeleteModalOpen}
                menuId={menuId}
                storeId={activeStore?._id}
                menuName={menuName}
            />
            <ResetOverridesModal
                isModalOpen={isResetModalOpen}
                setIsModalOpen={setIsResetModalOpen}
                menuId={menuId}
                storeId={activeStore?._id}
            />
        </>
    );
};

const SpinnerWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-content: center;
`;

const navigationCol = {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "end"
} as CSSProperties;

const ContentRow = styled(Row)`
    overflow-y: scroll;
    @media ${ScreenState.MOBILE} {
        height: calc(100% - 250px);
    }
    height: calc(100% - 222px);
    width: -webkit-fill-available;
    width: -moz-available;
`;
