import { OrdersFormFieldEnum, TogglableKeys } from "../types";

export const TOGGLE_ROWS_TITLES_AND_DESCRIPTIONS: Record<
    TogglableKeys,
    { title: string; description: string; deprecated?: boolean }
> = {
    [OrdersFormFieldEnum.autoAccept]: {
        title: "Auto accept orders",
        description:
            "Order Hub tablet will automatically start and print new orders as they are received",
        deprecated: true
    },
    [OrdersFormFieldEnum.autoComplete]: {
        title: "Use estimated pickup time to mark orders ready",
        description:
            "DEPRECATED. This setting will mark orders as ready for pickup based on the estimated pickup time.",
        deprecated: true
    },
    [OrdersFormFieldEnum.allowCustomNotes]: {
        title: "Custom notes",
        description: "Allow customers to add item level and order level notes"
    },
    [OrdersFormFieldEnum.hasEmployeeAuditTracking]: {
        title: "Store requires employee pin entry",
        description:
            "This will make it so employees must enter a pin number every time they want to perform an auditable action such as issuing a refund. This setting must be on for custom pins to be enabled."
    }
} as const;
