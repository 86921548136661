import { useSelector } from "react-redux";
import { HamburgerMenuIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { SystemColors } from "@snackpass/design-system";

import { Button } from "src/@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "src/@/components/ui/sheet";
import { NavigationSideBar } from "#navigation/navigation-side-bar";
import { getActiveStore, getUser } from "src/redux/selectors";
import { IntercomGetHelpButton } from "#shared-components/initials-avatar/intercom-get-help-button";
import { cn } from "src/@/lib/utils";
import { GlobalSearchCommandPalette } from "#global-search-command-palette";
import { useTrackPageViews } from "#navigation/useTrackPageViews";
import { ReactComponent as StatusCircleIcon } from "src/assets/icons/status-circle.svg";
import { useAppSelector } from "src/redux/hooks";
import { TooltipProvider } from "src/@/components/ui/tooltip";
import {
    HybridTooltip,
    HybridTooltipTrigger,
    HybridTooltipContent
} from "src/@/components/ui/HybridTooltip";

import { SelectStore } from "../store-selector/select-store";

// Keep in sync with StackedStickyHeader's top.
const TopNavBarHeightClassName = "h-12";

export const TopNavBar = () => {
    const user = useSelector(getUser);
    const store = useAppSelector(getActiveStore);
    const hasPaymentsEnabled = store?.paymentsEnabled;
    const [commandOpen, setCommandOpen] = useState<boolean>(false);

    useTrackPageViews();

    return (
        <div
            className={cn(
                "sticky top-0 z-20 flex h-12 flex-row items-center border-b border-b-neutral-400 bg-white",
                TopNavBarHeightClassName
            )}
        >
            <Sheet>
                <SheetTrigger asChild>
                    <Button
                        variant={"link"}
                        className="flex bg-transparent lg:hidden"
                    >
                        <HamburgerMenuIcon className="h-6 w-6" />
                    </Button>
                </SheetTrigger>
                <SheetContent
                    side="left"
                    overlayClassName="z-[9998] bg-black/40"
                    className="z-[9999] w-60 p-0"
                    closeButtonClassName={
                        window.PartnerAppJsApi ? "hidden" : undefined
                    }
                >
                    <NavigationSideBar />
                </SheetContent>
            </Sheet>
            <div className="flex h-full w-full flex-col justify-center px-2 md:w-80">
                {user && user.snackpassPermissions && <SelectStore />}
            </div>
            {hasPaymentsEnabled ? (
                <TooltipProvider delayDuration={100}>
                    <HybridTooltip>
                        <HybridTooltipTrigger asChild>
                            <StatusCircleIcon
                                className="ml-2"
                                width={18}
                                height={18}
                                fill={SystemColors.v2.parsley50.light}
                            />
                        </HybridTooltipTrigger>
                        <HybridTooltipContent>
                            Payments are enabled. Store can accept and process
                            payments.
                        </HybridTooltipContent>
                    </HybridTooltip>
                </TooltipProvider>
            ) : null}
            <div className="lg:hidden">
                <Button
                    className="-ml-1 mr-1 rounded bg-white p-1"
                    onClick={() => {
                        setCommandOpen(true);
                    }}
                    variant="outline"
                >
                    <MagnifyingGlassIcon className="h-5 w-7" />
                </Button>
            </div>
            <GlobalSearchCommandPalette
                isOpen={commandOpen}
                onClose={() => {
                    setCommandOpen(false);
                }}
            />
            <div className="flex flex-1 items-center justify-end">
                {!window.PartnerAppJsApi && (
                    <div className="flex items-center pr-4 font-medium leading-5 text-neutral-600">
                        <IntercomGetHelpButton />
                    </div>
                )}
            </div>
        </div>
    );
};
