import React, { useContext, useState } from "react";
import { Col, Form, Input, Row } from "antd";
import { UploadFile } from "antd/lib/upload";
import Upload from "antd/lib/upload/Upload";
import styled from "styled-components";
import { debounce } from "radash";

import api from "src/api/rest";
import CloseButton from "#payouts/components/shared/CloseButton";
import { StyledModal, Header } from "#payouts/components/shared/Modal";
import { VerificationsResponse } from "#payouts/domain/types";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { descriptions } from "#payouts/utils/descriptions";
import { appendToBody } from "#payouts/utils/appendToBody";
import { notifyFailure } from "#payouts/utils/notify";
import { messages } from "#payouts/utils/messages";
import {
    CheckHeader,
    Label,
    RowDivider
} from "#payouts/components/shared/ModalComponents";
import {
    AccountSchema,
    AccountSchemaWithDocument
} from "#payouts/utils/validation/schema";
import {
    accountNumberValidation,
    confirmAccountNumberValidation,
    frontDocumentValidation,
    routingNumberValidation
} from "#payouts/utils/validation/form";
import { handleException } from "#payouts/utils/handleException";
import theme from "#payouts/utils/theme";
import { ReactComponent as UploadButton } from "#payouts/assets/upload-button.svg";
import Text from "#payouts/components/shared/Text";
import { acceptFiles, fileSizeInMB } from "#payouts/utils/validation/files";
import { UpdatedButton } from "#payouts/components/shared/UpdatedButton";
import { useAccount } from "#payouts/utils/hooks/useAccount";

export const AccountModal = () => {
    const [form] = Form.useForm();

    const {
        storeId,
        verification,
        setVerification,
        accountModal,
        handleModalChange
    } = useContext(PayoutsSettingsContext);

    const account = useAccount();

    const [hasChange, setHasChange] = useState(false);
    const [loading, setLoading] = useState(false);

    const routingNumberProvided = !!verification?.routingNumber;
    const accountNumberProvided = !!verification?.accountNumber;

    const [routingNumber, setRoutingNumber] = useState(
        routingNumberProvided ? "Provided" : undefined
    );
    const [accountNumber, setAccountNumber] = useState(
        accountNumberProvided
            ? `*******${verification?.accountLast4}` ?? "Provided"
            : undefined
    );
    const [confirmAccountNumber, setConfirmAccountNumber] = useState(
        accountNumberProvided ? "Provided" : undefined
    );

    const showConfirm = confirmAccountNumber !== "Provided";

    const [front, setFront] = useState<UploadFile | undefined>(undefined);
    const [frontCheck, setFrontCheck] = useState(
        account.required ? undefined : account.provided ? "Provided" : undefined
    );
    const [frontFileList, setFrontFileList] = useState<UploadFile[]>([]);

    const matchError =
        routingNumber === "Provided"
            ? false
            : accountNumber !== confirmAccountNumber;

    const ErrorSchema = account.required
        ? AccountSchemaWithDocument
        : AccountSchema;

    const error = accountModal
        ? !ErrorSchema.safeParse({
              routingNumber,
              accountNumber,
              confirmAccountNumber,
              frontCheck
          }).success || matchError
        : false;

    const resetState = () => {
        setHasChange(false);
        setLoading(false);
        handleModalChange();
    };

    const disabled = !storeId || loading;
    const isValid = hasChange && !error;

    const submitUpdate = async (u: FormData): VerificationsResponse => {
        if (disabled || !isValid) {
            return Promise.reject();
        }
        setLoading(true);
        return api.verifications.update(storeId, u);
    };

    const debouncedSubmit = debounce({ delay: 300 }, async (u: FormData) =>
        submitUpdate(u)
            .then((response) => {
                const { data } = response;
                if (data?.success) {
                    setVerification(data.verification);
                    setHasChange(false);
                    setLoading(false);
                    handleModalChange(
                        routingNumber === "Provided" ? {} : { person: true }
                    );
                } else {
                    notifyFailure(messages.modal.account);
                    handleException(data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                notifyFailure({
                    message: messages.modal.account.message,
                    description:
                        error.cause?.response?.data?.error ??
                        messages.modal.account.description
                });
                handleException(error);
                setLoading(false);
            })
    );

    if (!accountModal) return <></>;

    const DocumentInput = () => {
        if (account.provided && !account.required) {
            return (
                <Input
                    disabled
                    placeholder="Provided"
                    addonBefore="Identity Document"
                />
            );
        }
        return (
            <>
                <FileText>
                    <Text.SmallLabel>Front:</Text.SmallLabel>
                </FileText>
                {frontFileList.length ? null : <UploadButton />}
            </>
        );
    };

    return (
        <StyledModal
            open={accountModal}
            setOpen={() => handleModalChange()}
            header={
                <Header
                    left={<CloseButton onClose={() => resetState()} />}
                    center="Business Details"
                />
            }
            footer={
                <>
                    <UpdatedButton
                        block
                        smallRadius
                        variant="tertiary"
                        size="regular"
                        children={<>Cancel</>}
                        disabled={disabled}
                        isValid={true} // cancel is always valid
                        onClick={() => handleModalChange()}
                    />
                    <UpdatedButton
                        block
                        smallRadius
                        variant="primary"
                        size="regular"
                        children={<>Submit</>}
                        disabled={disabled}
                        isValid={isValid}
                        loading={loading}
                        onClick={async () => {
                            if (disabled) {
                                return;
                            }
                            if (!isValid) {
                                form.setFields([
                                    {
                                        name: "routingNumber",
                                        errors: routingNumberValidation(
                                            routingNumber
                                        )
                                    },
                                    {
                                        name: "accountNumber",
                                        errors: accountNumberValidation(
                                            accountNumber
                                        )
                                    },
                                    {
                                        name: "confirmAccountNumber",
                                        errors: confirmAccountNumberValidation({
                                            account: accountNumber,
                                            confirm: confirmAccountNumber
                                        })
                                    },
                                    {
                                        name: "front",
                                        errors: frontDocumentValidation(
                                            frontCheck
                                        )
                                    }
                                ]);
                                return;
                            }

                            const body = new FormData();
                            appendToBody(body, "routingNumber", routingNumber);
                            appendToBody(
                                body,
                                "accountNumber",
                                routingNumber === "Provided"
                                    ? "Provided"
                                    : accountNumber
                            );

                            if (front) {
                                appendToBody(
                                    body,
                                    "document",
                                    JSON.stringify({
                                        name: "bank_account_ownership_verification",
                                        purpose: "account_requirement",
                                        filename: front.name,
                                        mimeType: front.type,
                                        type: "account"
                                    })
                                );
                                appendToBody(body, "frontImage", front);
                            }

                            void debouncedSubmit(body);
                        }}
                    />
                </>
            }
        >
            <CheckHeader />
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    routingNumber,
                    accountNumber,
                    confirmAccountNumber
                }}
            >
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name="routingNumber"
                            label={
                                <Label
                                    required
                                    label={"Routing Number"}
                                    subtitle={descriptions.routingNumber}
                                />
                            }
                        >
                            <Input
                                disabled={routingNumberProvided}
                                placeholder="Routing number"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const errors =
                                        routingNumberValidation(value);
                                    setHasChange(true);
                                    setRoutingNumber(value);
                                    form.setFields([
                                        {
                                            name: "routingNumber",
                                            value,
                                            errors
                                        }
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <RowDivider />
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name="accountNumber"
                            style={{ marginBottom: theme.spacing.half }}
                            label={
                                <Label
                                    required
                                    label={"Account Number"}
                                    subtitle={descriptions.accountNumber}
                                />
                            }
                        >
                            <Input
                                disabled={accountNumberProvided}
                                placeholder="Account number"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const errors =
                                        accountNumberValidation(value);
                                    setHasChange(true);
                                    setAccountNumber(value);
                                    form.setFields([
                                        {
                                            name: "accountNumber",
                                            value,
                                            errors
                                        }
                                    ]);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {showConfirm && (
                    <Row gutter={[0, 16]}>
                        <Col span={24}>
                            <Form.Item name="confirmAccountNumber">
                                <Input
                                    disabled={accountNumberProvided}
                                    placeholder="Confirm Account number"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const errors =
                                            confirmAccountNumberValidation({
                                                account: accountNumber,
                                                confirm: value
                                            });
                                        setHasChange(true);
                                        setConfirmAccountNumber(value);
                                        form.setFields([
                                            {
                                                name: "confirmAccountNumber",
                                                value,
                                                errors
                                            }
                                        ]);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {(account.required || account.provided) && (
                    <>
                        <RowDivider />
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="front"
                                    label={
                                        <Label
                                            required
                                            label={
                                                "Bank Account Verification Documents"
                                            }
                                            subtitle={
                                                <>
                                                    <p>
                                                        {
                                                            descriptions.bankAccountDocument
                                                        }
                                                    </p>
                                                    <br />
                                                    <p>
                                                        The check's name must
                                                        closely match{" "}
                                                        {verification?.name
                                                            ? `"${verification?.name}"`
                                                            : "your company name"}
                                                        . If it is not, you can
                                                        update the Doing
                                                        Business As (DBA) name
                                                        in the Company section
                                                        to match your check
                                                        name.
                                                    </p>
                                                </>
                                            }
                                        />
                                    }
                                >
                                    <Upload
                                        disabled={!account.required}
                                        beforeUpload={() => false}
                                        openFileDialogOnClick={
                                            !frontFileList.length
                                        }
                                        accept={acceptFiles}
                                        fileList={frontFileList}
                                        onChange={({ file, fileList }) => {
                                            if (
                                                file.size &&
                                                file.size > fileSizeInMB
                                            ) {
                                                return notifyFailure(
                                                    messages.modal.fileSize
                                                );
                                            }

                                            if (
                                                !fileList.length ||
                                                fileList.length === 1
                                            ) {
                                                const removed =
                                                    file.status === "removed";
                                                const frontFile = removed
                                                    ? undefined
                                                    : file;
                                                const frontFileList = removed
                                                    ? []
                                                    : [...fileList];
                                                setHasChange(true);
                                                setFront(frontFile);
                                                setFrontCheck(frontFile?.type);
                                                setFrontFileList(frontFileList);
                                                const errors =
                                                    frontDocumentValidation(
                                                        frontFile?.type
                                                    );
                                                form.setFields([
                                                    {
                                                        name: "front",
                                                        errors
                                                    }
                                                ]);
                                            }
                                        }}
                                    >
                                        <DocumentInput />
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
            </Form>
        </StyledModal>
    );
};

const FileText = styled.span`
    margin-right: ${({ theme }) => theme.spacing.double};
`;
