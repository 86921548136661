import { useMutation } from "@tanstack/react-query";
import { UseFormReset } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "sonner";

import { setActiveStore } from "src/redux/slices";
import api from "src/api/rest";
import {
    ChargeFormValues,
    FeesSettingsMutation
} from "#settings/settings-fees/utils/types";
import { buildChargePolicies } from "#settings/settings-fees/utils/build-charge-policy";
import { chargeFormDefaults } from "#settings/settings-fees/forms/charge/charge-defaults";

export const useUpdateCharge = ({
    storeId,
    reset
}: {
    storeId: string;
    reset: UseFormReset<ChargeFormValues>;
}) => {
    const dispatch = useDispatch();
    const { mutateAsync } = useMutation({
        mutationKey: [FeesSettingsMutation.Charge, storeId],
        async mutationFn(values: ChargeFormValues) {
            const chargeFeePolicy = {
                policyType: "FixedChargeFeePolicy",
                allowRefunds: values.defaultAllowRefunds,
                percentage: values.defaultPercent,
                flat: values.defaultFixed
            };
            const policies = buildChargePolicies(values);
            const response = await api.stores.updateChargePolicy(storeId, {
                chargeFeePolicy,
                customChargeFeePolicies: policies
            });
            dispatch(setActiveStore(response.data.store));
            reset(chargeFormDefaults(response.data.store));
            return response.data.store.name;
        },
        async onSuccess(storeName) {
            toast.success(`${storeName} saved`, {
                className: "bg-success-light",
                duration: 2000,
                id: "store-saved"
            });
        },
        onError() {
            toast.error(`Update failed`, {
                className: "bg-critical-light",
                duration: 2000,
                id: "store-update-failed"
            });
        }
    });
    return mutateAsync;
};
