import React from "react";

import { ReactComponent as StatusCircle } from "src/assets/icons/status-circle.svg";
import { PayoutHistoryItemStatus } from "src/api/rest";

const calculateStatus = (status: PayoutHistoryItemStatus) => {
    switch (status) {
        case "paid":
            return (
                <>
                    <StatusCircle className="fill-green-500" />
                    <span className="pl-2">Paid</span>
                </>
            );
        case "in_transit":
            return (
                <>
                    <StatusCircle className="fill-blue-500" />
                    <span className="pl-2">In Transit</span>
                </>
            );
        case "initiated":
            return (
                <>
                    <StatusCircle className="fill-yellow-500" />
                    <span className="pl-2">Initiated</span>
                </>
            );
        case "unknown":
            return (
                <>
                    <StatusCircle className="fill-yellow-400" />
                    <span className="pl-2">Unknown</span>
                </>
            );
    }
};

export const PaymentStatusDesktop = ({
    status
}: {
    status: PayoutHistoryItemStatus;
}) => (
    <div className="flex items-center overflow-auto">
        {calculateStatus(status)}
    </div>
);
