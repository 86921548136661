import { parseISO } from "date-fns";

type DateStyle = Extract<
    Intl.ResolvedDateTimeFormatOptions["dateStyle"],
    string
>;

type TimeStyle = Extract<
    Intl.ResolvedDateTimeFormatOptions["timeStyle"],
    string
>;

type DatetimeStyle = `${DateStyle}-${TimeStyle}`;

/**
 * Format a date or a string to a human-readable date string using
 * built-in Intl.DateTimeFormat.
 *
 * Locale and timezone aware.
 *
 * @param date Date object, or a string that can be parsed by `parseISO` from `date-fns`
 * @param style Format style as `${dateStyle}-${timeStyle}` or just `dateStyle`
 */
export function datetimeFormat(
    date: Date | string,
    style: DateStyle | DatetimeStyle
): string {
    let formatter = cachedFormatters.get(style);
    if (!formatter) {
        const [dateStyle, timeStyle] = isDatetimeStyle(style)
            ? (style.split("-") as [DateStyle, TimeStyle])
            : [style, undefined];
        formatter = new Intl.DateTimeFormat(undefined, {
            dateStyle,
            timeStyle
        });
        cachedFormatters.set(style, formatter);
    }
    return formatter.format(typeof date === "string" ? parseISO(date) : date);
}

const cachedFormatters = new Map<
    DateStyle | DatetimeStyle,
    Intl.DateTimeFormat
>();

function isDatetimeStyle(
    style: DateStyle | DatetimeStyle
): style is DatetimeStyle {
    return style.includes("-");
}
