import { BarChartProps } from "@tremor/react";
import clsx from "clsx";
import { toNumber } from "lodash/fp";
import { useMemo } from "react";

import { CHART_COLORS } from "#reports/location-sales/lib";

const NUM_LOCATIONS = 3;

/**
 * Tooltip used for the location sales daily and location sales hourly charts to display the total average daily/hourly and the top few locations.
 */
export const TopLocationsTooltip: BarChartProps["customTooltip"] = ({
    payload,
    active,
    label
}) => {
    const sortedPayload = useMemo(
        () =>
            payload?.toSorted(
                (a, b) => toNumber(b.value) - toNumber(a.value)
            ) ?? [],
        [payload]
    );

    if (!active || !payload) return null;

    const total = payload.reduce((acc, curr) => toNumber(curr.value) + acc, 0);
    const valueFormatter =
        payload.find((e) => !!e)?.payload["valueFormatter"] ?? toNumber;

    return (
        <div
            autoFocus={true}
            className="pointer-events-auto z-[9999] flex w-72 flex-col space-y-2 rounded-md border border-neutral-200 bg-neutral-50 p-2 text-small shadow-tremor-dropdown"
        >
            <p className="font-semibold">{label}</p>

            <div className="flex flex-1 space-x-2.5">
                <div className="w-full">
                    <div className="flex w-full justify-between">
                        <div className="text-neutral-600">Total</div>
                        <div className="ml-2 font-medium text-neutral-800">
                            {valueFormatter(total)}
                        </div>
                    </div>
                </div>
            </div>
            {sortedPayload.length >= NUM_LOCATIONS * 2 ? (
                <>
                    <p className="text-micro font-semibold">
                        Top {NUM_LOCATIONS} Locations
                    </p>
                    {sortedPayload
                        .slice(0, NUM_LOCATIONS)
                        .map((category, idx) => (
                            <ColoredStoreRow
                                key={idx}
                                color={category.color}
                                name={category.name?.toString()}
                                value={valueFormatter(category.value)}
                                percentage={calculatePercentage(
                                    toNumber(category.value),
                                    total
                                )}
                            />
                        ))}

                    <p className="text-micro font-semibold">
                        Bottom {NUM_LOCATIONS} Locations
                    </p>
                    {sortedPayload
                        .slice(
                            sortedPayload.length - NUM_LOCATIONS,
                            sortedPayload.length
                        )
                        .map((category, idx) => (
                            <ColoredStoreRow
                                key={idx}
                                color={category.color}
                                name={category.name?.toString()}
                                value={valueFormatter(category.value)}
                                percentage={calculatePercentage(
                                    toNumber(category.value),
                                    total
                                )}
                            />
                        ))}
                </>
            ) : (
                sortedPayload.map((category, idx) => (
                    <ColoredStoreRow
                        key={idx}
                        color={category.color}
                        name={category.name?.toString()}
                        value={valueFormatter(category.value)}
                        percentage={calculatePercentage(
                            toNumber(category.value),
                            total
                        )}
                    />
                ))
            )}
        </div>
    );
};

const ColoredStoreRow = ({
    name,
    color,
    value,
    percentage
}: {
    name?: string;
    color?: string;
    value: string;
    percentage: string;
}) => (
    <div className="flex flex-1 space-x-2.5">
        <div
            className={clsx(
                "flex w-1 flex-col rounded",
                CHART_COLORS.find((e) => e.color === color)?.className
            )}
        />
        <div className="w-full">
            <div className="flex w-full justify-between">
                <div className="text-micro text-neutral-600">{name}</div>
                <div className="ml-2 text-micro font-medium text-neutral-800">
                    {value}
                </div>
            </div>
            <div className="text-micro text-neutral-600">
                {percentage} of total
            </div>
        </div>
    </div>
);

const calculatePercentage = (
    numerator: number,
    denominator: number
): string => {
    if (denominator === 0) {
        return "0%";
    }
    const percentage = (numerator / denominator) * 100;
    return percentage.toFixed(2).replace(/\.?0+$/, "") + "%";
};
