import { useEffect, useMemo } from "react";
import { keyBy, orderBy } from "lodash/fp";
import { IProduct, IProductCategory } from "@snackpass/snackpass-types";

import { useCategories, useProducts, useMenuService } from "#menu/hooks";

export type CategoryWithProducts = {
    label: string;
    category: IProductCategory;
    products: IProduct[];
};

export type UseProductRewardsResponse = {
    recentProducts: IProduct[];
    topProducts: IProduct[];
    categoriesWithProducts: CategoryWithProducts[];
};

export const useProductRewards = (): UseProductRewardsResponse => {
    const products = useProducts();
    const categories = useCategories();
    const service = useMenuService();

    useEffect(() => {
        void service.fetchMenu();
    }, [service]);

    return useMemo(() => {
        if (!products.length) {
            return {
                categoriesWithProducts: [],
                topProducts: [],
                recentProducts: []
            };
        }

        const filteredProducts = products.filter(
            (p) => !p.isCatering && !p.specialProductType
        );

        const topProducts = filteredProducts
            .sort((a, b) => (b.purchaseCount ?? 0) - (a.purchaseCount ?? 0))
            .slice(0, 5);

        const productById = keyBy("_id", filteredProducts);

        const categoriesWithProducts = categories
            .filter((c) => !c.specialProductCategory)
            .map((c) => ({
                label: c.name,
                category: c,
                products: c.productIds
                    .map((id) => productById[id])
                    .filter((p) => !!p)
            }))
            .filter((c) => c.products.length > 0);

        return {
            categoriesWithProducts,
            topProducts,
            recentProducts: orderBy(
                "createdAt",
                "desc",
                filteredProducts
            ).slice(0, 5)
        };
    }, [categories, products]);
};
