import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { sort } from "radash";

import { Button } from "#payouts/components/shared/Button";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";
import { ReactComponent as PlusSign } from "src/assets/icons/plus-sign.svg";
import { ReactComponent as Play } from "#payouts/assets/play.svg";
import { Representatives } from "#payouts/components/summary/owner/Representatives";
import api from "src/api/rest";
import { PersonStatuses, PersonVerification } from "#payouts/domain/types";
import config from "#config";
import { useRepresentative } from "#payouts/utils/hooks/useRepresentative";

// do not check on staging, as the prod -> staging copy breaks the prod association with a test stripe key
const checkUnverifiedUsers = (ps: PersonVerification[] = []): boolean =>
    config.isProduction &&
    !!ps.filter((p) => p.status !== PersonStatuses.verified).length;

export const OwnersSummary = () => {
    const {
        storeId,
        editEnabled,
        verification,
        setVerification,
        handleModalChange
    } = useContext(PayoutsSettingsContext);

    const { started } = useRepresentative();

    const [shouldStopPolling, setShouldStopPolling] = useState(false);

    const checkStatus = async () => {
        const needsStatusCheck = checkUnverifiedUsers(
            verification?.representatives
        );
        if (!needsStatusCheck) {
            setShouldStopPolling(true);
            return;
        }

        void api.verifications
            .retrieve(storeId)
            .then(({ data }) => {
                if (data.success) {
                    const needsStatusCheck = checkUnverifiedUsers(
                        data.verification.representatives
                    );
                    if (!needsStatusCheck) {
                        setVerification(data.verification);
                        setShouldStopPolling(true);
                    }
                }
            })
            .catch(() => {
                // If there is an error, assume retries are useless and stop
                // polling.
                setShouldStopPolling(true);
            });
    };

    useEffect(() => {
        if (shouldStopPolling) {
            return;
        }

        void checkStatus();

        const interval = setInterval(() => {
            void checkStatus();
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [shouldStopPolling, storeId, verification?.id]);

    // Also hides the action button
    const disabled = !editEnabled;

    const iconLeft = started ? <PlusSign /> : <Play />;
    const tooltip = [
        "We are required by by legal and financial regulators to collect certain information from account holders to prevent abuse of the financial system.",
        "We do not use this information for any other purposes, and we take the privacy and the security of your data very seriously.",
        "The security tools and best practices implemented by us and our payments providers ensure that your sensitive information is safely stored and encrypted."
    ].join("\\n");

    const rawPeople = verification?.representatives ?? [];
    const people = sort(rawPeople, (p) => (p.representative ? 1 : 0), true);

    return (
        <SummaryDiv>
            <Representatives
                start={!started}
                title={
                    <div className="my-auto flex flex-row justify-center gap-2">
                        <span className="my-auto flex flex-col">
                            Representatives
                        </span>
                    </div>
                }
                tooltip={tooltip}
                people={people}
                action={
                    <Button
                        smallRadius
                        disabled={disabled}
                        size="small"
                        iconLeft={iconLeft}
                        variant={started ? "tertiary" : "primary"}
                        children={started ? "Add" : "Start"}
                        onClick={() => handleModalChange({ person: true })}
                    />
                }
            />
        </SummaryDiv>
    );
};

const SummaryDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
`;
