import { AddonGroup, IProduct } from "@snackpass/snackpass-types";
import faker from "faker";

import {
    NewAddonGroups,
    NewAddons
} from "#menu-editor/multi-menus/redux/thunk-helpers";

export const newAddonIds = (
    product: IProduct,
    newAddonNames: {
        [key: string]: { addonNames: string[] };
    }
) => {
    const newAddons: NewAddons = {};

    Object.entries(newAddonNames).map(([addonGroupId, addons]) => {
        if (addons.addonNames.length > 0) {
            if (!newAddons[addonGroupId])
                newAddons[addonGroupId] = {
                    addonIds: []
                };
            const addonGroup = product.addonGroups.find(
                (addonGroup) => addonGroup._id === addonGroupId
            );
            if (addonGroup) {
                addons.addonNames.forEach((name) => {
                    const id = addonGroup.addons.find(
                        (addon) => addon.name === name
                    )?._id;
                    if (id) newAddons[addonGroupId].addonIds.push(id);
                });
            }
        }
    });

    return newAddons;
};

export const newAddonGroupIds = (
    product: IProduct,
    newAddonGroupNames: string[]
) => {
    const newAddonGroupIds: NewAddonGroups = [];

    newAddonGroupNames.forEach((name) => {
        const addonGroup = product.addonGroups.find(
            (addonGroup) => addonGroup.name === name
        );
        if (addonGroup)
            newAddonGroupIds.push({
                addonGroupId: addonGroup._id,
                addonIds: addonGroup.addons.map((addon) => addon._id)
            });
    });

    return newAddonGroupIds;
};

export const sanitizeCopiedAddonGroup = (
    addonGroup: AddonGroup
): AddonGroup => ({
    ...addonGroup,
    _id: Date.now().toString(),
    addons: addonGroup.addons.map((addon, idx) => ({
        ...addon,
        _id: faker.date.toString() + idx
    }))
});

export const isCategoryNameTaken = (
    newCategoryName: string,
    products: IProduct[]
) => {
    const existingCategoryNames = new Set(
        products.map((product) => product.category)
    );
    return existingCategoryNames.has(newCategoryName);
};

export const isCategoryNameValid = (
    newCategoryName: string,
    isCatering: boolean
) => {
    const pattern = new RegExp(/\(Catering\)$/);
    const hasCateringName = pattern.test(newCategoryName);
    if ((!isCatering && hasCateringName) || (isCatering && !hasCateringName)) {
        return false;
    }
    return true;
};
