/** @jsxImportSource @emotion/react */
import { toDollar } from "@snackpass/accounting";
import { PurchaseSummaryData } from "@snackpass/snackpass-types";
import { ColumnDef } from "@tanstack/react-table";
import "bootstrap";
import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { DataTable } from "src/@/components/ui/data-table";
import { usePurchaseReportTotals } from "src/api/rest/usePurchaseReports";
import { useGlobalDate } from "#hooks";
import { SummaryTooltip } from "#orders/components/summary/SummaryTooltip";

export const OrderSalesSummary = () => {
    const { startDate, endDate } = useGlobalDate();
    const { total: report, isLoading } = usePurchaseReportTotals();

    const getDateString = () => {
        if (startDate.year() !== endDate.year()) {
            return `${startDate.format("ll")} – ${endDate.format("ll")}`;
        } else if (startDate.isSame(endDate, "days")) {
            return `${endDate.format("LL")}`;
        }
        return `${startDate.format("MMM DD")} – 
        ${endDate.format("ll")}`;
    };

    const salesRows = useSalesRows(report);
    const taxesAndFeesRows = useTaxesAndFeesRows(report);

    if (isLoading) {
        return <Skeleton className="h-96" />;
    }
    if (!report) return null;

    return (
        <div>
            <h4 className="my-6 text-large">
                Summary for{" "}
                <span className="font-semibold">{getDateString()}</span>
            </h4>
            <div className="flex flex-col items-center justify-center md:flex-row md:items-start md:space-x-4">
                <div className="w-full">
                    <DataTable
                        columns={getColumnsWithTitle("Sales")}
                        data={salesRows}
                    />
                </div>
                <div className="w-full">
                    <DataTable
                        columns={getColumnsWithTitle("Taxes & Fees")}
                        data={taxesAndFeesRows}
                    />
                </div>
            </div>
        </div>
    );
};

const useSalesRows = function (report?: PurchaseSummaryData) {
    return useMemo(
        () =>
            report
                ? [
                      {
                          title: "Orders",
                          value: report?.count.toString() ?? "--"
                      },
                      {
                          title: "Net Sales",
                          value: toDollar(report?.netSales),
                          toolTipText:
                              "The subtotal of all sales plus any customer to store fees."
                      },
                      {
                          title: "Tips",
                          value: toDollar(report?.tip)
                      },
                      {
                          title: "Expected Cash Collected",
                          value: toDollar(report?.expectedCashCollected),
                          toolTipText:
                              "The amount of money we expect you to have accepted in cash in your store for sales occurring in this time period."
                      },
                      {
                          title: "Estimated Payout",
                          value: toDollar(report?.expectedPayout),
                          toolTipText:
                              "The amount of money you should expect to be paid into your bank account for sales occurring in this time period."
                      },
                      ...(report?.actualPayout !== undefined
                          ? [
                                {
                                    title: "Actual Payout",
                                    value: toDollar(report?.actualPayout),
                                    toolTipText:
                                        "The amount of money you should expect to be paid into your bank account for sales occurring in this time period."
                                }
                            ]
                          : [])
                  ]
                : [],
        [report]
    );
};

const useTaxesAndFeesRows = function (report?: PurchaseSummaryData) {
    return useMemo(
        () =>
            report
                ? [
                      {
                          title: "Processing Fees",
                          value: toDollar(report?.processingFee)
                      },
                      {
                          title: "Snackpass Fees",
                          value: toDollar(report?.snackpassFees),
                          toolTipText:
                              "Any fees charged to your restaurant by Snackpass EXCEPT credit card fees. Commission is a common example."
                      },
                      {
                          title: "Cust. → Snackpass Fees",
                          value: toDollar(report?.customerToSnackpassFees),
                          toolTipText:
                              "Any fees charged directly to your customer by Snackpass. An example of this are customer fees on third party delivery orders, which allow us to keep restaurant costs low."
                      },
                      {
                          title: "Taxes You Owe",
                          value: toDollar(report?.storeTaxesToRemit),
                          toolTipText:
                              "Taxes you are responsible for remitting to the state."
                      },
                      ...(report?.storeTaxesWithheld
                          ? [
                                {
                                    title: "Taxes Remitted To State On Your Behalf",
                                    value: toDollar(report?.storeTaxesWithheld),
                                    toolTipText:
                                        "Taxes that Snackpass will remit to the government on your behalf. You will not be paid these out."
                                }
                            ]
                          : [])
                  ]
                : [],
        [report]
    );
};

const getColumnsWithTitle = (name: string): ColumnDef<OrdersSummaryRow>[] => [
    {
        id: "Item",
        accessorKey: "title",
        header: () => <>{name}</>,
        cell: (info) => (
            <div className="ml-2">
                <>{info.getValue()}</>{" "}
                {info.row.original.toolTipText ? (
                    <SummaryTooltip body={info.row.original.toolTipText} />
                ) : null}
            </div>
        )
    },
    {
        id: "Value",
        accessorKey: "value",
        header: () => <></>,
        cell: (info) => (
            <div className="text-right">
                <>{info.getValue()}</>
            </div>
        )
    }
];

type OrdersSummaryRow = {
    title: string;
    value: string;
    toolTipText?: string;
};
