import { createSelector } from "@reduxjs/toolkit";
import {
    Addon,
    AddonGroup,
    IProduct,
    IProductCategory,
    TransactionSourceTypeEnum,
    MenuOverrides
} from "@snackpass/snackpass-types";
import _, { cloneDeep } from "lodash";

import { removeNulls } from "#core";
import { IProductCategoryWithProducts } from "#menu-editor/mobile-friendly/helpers/context";
import {
    AsyncActions,
    LoadingStates,
    MultiMenuState
} from "#menu-editor/multi-menus/redux/types";
import { getActiveStore, getLegacyProducts } from "src/redux/selectors";
import { RootState } from "src/redux/store";

// TODO: createSelector

export const isCategorySelected = (categoryId: string) => (state: RootState) =>
    !!state.multiMenus.currentMenu.categories?.find(
        ({ id }) => id === categoryId
    );

export const isAnyCategorySelected =
    (categoryIds: string[]) => (state: RootState) =>
        categoryIds.some((categoryId) => isCategorySelected(categoryId)(state));

export const isProductSelected = (productId: string) => (state: RootState) =>
    !!state.multiMenus.currentMenu.categories
        ?.flatMap(({ productIds }) => productIds)
        .includes(productId);

export const isAddonGroupSelected =
    (addonGroupId: string) => (state: RootState) =>
        !!state.multiMenus.currentMenu.menuOverrides?.addonGroups?.[
            addonGroupId
        ];

export const isAddonSelected =
    (addonId: string, addonGroupId: string) => (state: RootState) =>
        !!state.multiMenus.currentMenu.menuOverrides?.addonGroups?.[
            addonGroupId
        ]?.addons?.includes(addonId);

export const allChildrenSelectedAddonGroup =
    ({ _id, addons }: AddonGroup) =>
    (state: RootState) =>
        (
            state.multiMenus.currentMenu.menuOverrides?.addonGroups?.[_id]
                ?.addons || []
        ).length === addons.length;

export const allChildrenSelectedProduct =
    (originalRow: IProduct) => (state: RootState) =>
        !originalRow.addonGroups.some(
            (ag) => !allChildrenSelectedAddonGroup(ag)(state)
        );

export const allChildrenSelectedCategory =
    (originalRow: IProductCategoryWithProducts) =>
    (state: RootState): boolean => {
        const multiMenuCategory =
            state.multiMenus.currentMenu?.categories?.find(
                ({ id }) => id === originalRow._id
            );
        if (!multiMenuCategory) return false;
        const allCategoryProductIds = originalRow.productIds;
        const allMultiMenuCategoryProductIds = multiMenuCategory.productIds;
        if (
            allCategoryProductIds.length !==
            allMultiMenuCategoryProductIds?.length
        )
            return false;
        return !originalRow.products.some(
            (p) => !allChildrenSelectedProduct(p)(state)
        );
    };

export const allChildrenSelectedCategories =
    (categories: IProductCategoryWithProducts[]) =>
    (state: RootState): boolean =>
        !categories.some(
            (category) => !allChildrenSelectedCategory(category)(state)
        );

export const getMultiMenuLoadingState =
    (asyncAction: AsyncActions) =>
    (state: RootState): LoadingStates =>
        state.multiMenus[`loading${asyncAction}`] ?? "idle";

export const getPriceOverrideSelector =
    (itemType: Exclude<keyof MenuOverrides, "addonGroups">, id: string) =>
    (state: RootState): number | undefined | null =>
        state.multiMenus.currentMenu?.menuOverrides?.[itemType]?.[id]?.price;

export const getTaxOverrideSelector = (id: string) => (state: RootState) =>
    state.multiMenus.currentMenu.menuOverrides?.products?.[id]?.taxes;

export const getPriceAdjustmentSelector =
    (itemType: Exclude<keyof MenuOverrides, "addonGroups">) =>
    (state: RootState): number | undefined | null => {
        const priceAdjustment = state.multiMenus.currentMenu?.priceAdjustment;
        if (!priceAdjustment) return;
        if (
            (priceAdjustment.scope !== "modifiers" &&
                itemType === "products") ||
            (priceAdjustment.scope !== "items" && itemType === "addons")
        )
            return priceAdjustment.value;
    };

export const getApplyAdjustmentSelector = (
    state: RootState
): boolean | undefined => {
    const priceAdjustment = state.multiMenus.currentMenu?.priceAdjustment;
    if (!priceAdjustment) return;
    return priceAdjustment.applyToOverrides;
};

export const getStoreMultiMenus = (
    state: RootState
): MultiMenuState["storeMenus"] => state.multiMenus.storeMenus;

export const getAllStoreMultiMenus = (
    state: RootState
): MultiMenuState["storeMenus"] => state.multiMenus.storeMenus;

export const getStoreMultiMenu =
    (menuId: string) =>
    (state: RootState): MultiMenuState["storeMenus"][number] | undefined =>
        state.multiMenus.storeMenus.find((menu) => menu.id === menuId);

export const getCurrentMultiMenu = (
    state: RootState
): MultiMenuState["currentMenu"] => state.multiMenus.currentMenu;

export const getCurrentMultiMenuId = createSelector(
    [getCurrentMultiMenu],
    (currentMenu) => currentMenu.id ?? "no-set"
);

/**
 * Gets all categories, and their respective products, which are present in the currently selected multi menu (if there is one)
 */
export const getMenuCategories = createSelector(
    [getActiveStore, getLegacyProducts, getCurrentMultiMenu],
    (activeStore, products, menu) => {
        if (!menu.categories || !activeStore || _.isEmpty(activeStore))
            return [];

        const menuCategories = menu.categories;

        const productsCopy = cloneDeep(products);

        const productMap: Map<string, IProduct> = productsCopy.reduce(
            (map, product) => {
                map.set(product._id, product);
                return map;
            },
            new Map<string, IProduct>()
        );

        const addonMap: Map<string, Addon> = productsCopy
            .flatMap(({ addonGroups }) => addonGroups)
            .flatMap(({ addons }) => addons)
            .reduce((map, addon) => {
                map.set(addon._id, addon);
                return map;
            }, new Map<string, Addon>());

        const applyAddonGroupOverrides = (
            ag: AddonGroup
        ): AddonGroup | null => {
            const addonGroupOverrides =
                menu.menuOverrides?.addonGroups?.[ag._id];
            if (!addonGroupOverrides) return null;
            const addons = removeNulls(
                addonGroupOverrides.addons.map(
                    (addonId) => addonMap.get(addonId) ?? null
                )
            );
            return { ...ag, addons };
        };

        const storeCategories =
            menu.type === "standard"
                ? activeStore.productCategories
                : activeStore.catering.productCategories;

        const categoriesWithProducts = menuCategories.map((menuCategory) => {
            const category = storeCategories.find(
                (category) => category._id === menuCategory.id
            ) as IProductCategory;

            const categoryProducts = removeNulls(
                menuCategory.productIds.map((productId) => {
                    const product = productMap.get(productId);
                    if (!product) return null;
                    product.addonGroups = removeNulls(
                        product.addonGroups.map(applyAddonGroupOverrides)
                    );
                    return product;
                })
            );

            return {
                ...category,
                ...(menuCategory.name && { name: menuCategory.name }),
                products: categoryProducts,
                productIds: menuCategory.productIds
            };
        });

        return categoriesWithProducts;
    }
);

export const getCurrentMenuId = createSelector(
    [getCurrentMultiMenu],
    (currentMenu) => currentMenu.id ?? "no-set"
);

/**
 * Gets all the possible products and categories that could be applied to the currently selected multi menu (if there is one)
 * Categories NOT selected for the current multi menu are also returned
 *
 * If you only want to show the categories that are selected in the menu, use *getMenuCategories* instead
 */
export const getAllEligibleMenuCategories = createSelector(
    [getActiveStore, getLegacyProducts, getCurrentMultiMenu],
    (activeStore, products, menu) => {
        if (_.isEmpty(menu) || !activeStore || _.isEmpty(activeStore))
            return [];

        const productMap: Map<string, IProduct> = products.reduce(
            (map, product) => {
                map.set(product._id, product);
                return map;
            },
            new Map<string, IProduct>()
        );

        const storeCategories =
            menu.type === "standard"
                ? activeStore.productCategories
                : activeStore.catering.productCategories;

        const categoriesWithProducts = storeCategories
            .filter((category) => category.specialProductCategory == null) // Remove gift cards from multi menu eligibility
            .map((category) => {
                const categoryProducts = category.productIds
                    .map((productId) => productMap.get(productId) ?? null)
                    .filter(
                        (maybeProduct) => maybeProduct !== null
                    ) as IProduct[];

                return {
                    ...category,
                    products: categoryProducts
                };
            });

        return categoriesWithProducts;
    }
);

export const getActiveThirdParty = createSelector(
    [getStoreMultiMenus],
    (storeMenus) =>
        storeMenus.find(
            (menu) =>
                menu.platforms.includes(TransactionSourceTypeEnum.ThirdParty) &&
                menu.enabled
        )
);
