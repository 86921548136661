import { atom } from "recoil";

export enum NewCampaignStep {
    SMSForm,
    SMSReview
}

export const newCampaignStepAtom = atom<NewCampaignStep | null>({
    key: "newCampaign/step",
    default: null
});

export const storeDisplayNameAtom = atom<string>({
    key: "newCampaign/storeDisplayName",
    default: "Store name not loaded"
});
