import clsx from "clsx";
import { forwardRef } from "react";

import { FormItem } from "src/@/components/ui/form";

export const InternalSettingsFormItem = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & { border?: boolean }
>(({ className, border = true, ...props }, ref) => (
    <FormItem
        className={clsx(
            "flex flex-col items-start justify-between space-y-2",
            border && "border-b pb-8",
            className
        )}
        ref={ref}
        {...props}
    />
));
InternalSettingsFormItem.displayName = "InternalSettingsFormItem";
