import { zodResolver } from "@hookform/resolvers/zod";
import { IStore } from "@snackpass/snackpass-types";
import { FormProvider, useForm } from "react-hook-form";

import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "src/@/components/ui/form";
import { Header } from "#settings/settings-fees/components/header";
import { MinimumFooter } from "#settings/settings-fees/forms/minimum/minimum-footer";
import { CustomFeeLabelFormSchema } from "#settings/settings-fees/utils/schema";
import { CustomFeeLabelValues } from "#settings/settings-fees/utils/types";
import { getCustomFeeLabelDefaults } from "#settings/settings-fees/forms/labels/getCustomFeeLabelDefaults";
import { useUpdateCustomFeeLabels } from "#settings/settings-fees/utils/hooks/useUpdateCustomFeeLabels";
import { Input } from "src/@/components/ui/input";

export const CustomFeeLabelsForm = ({ store }: { store: IStore }) => {
    const form = useForm<CustomFeeLabelValues>({
        defaultValues: getCustomFeeLabelDefaults(store),
        resolver: zodResolver(CustomFeeLabelFormSchema),
        mode: "all"
    });

    const updateStore = useUpdateCustomFeeLabels({
        storeId: store._id,
        reset: form.reset
    });

    return (
        <>
            <form
                onSubmit={form.handleSubmit(async (values) =>
                    updateStore(values)
                )}
                className="relative h-full w-full overflow-hidden"
            >
                <FormProvider {...form}>
                    <div className="flex flex-col gap-2">
                        <Header
                            title="Custom Fee Labels"
                            titleSubtext="Changes the fee names and tip message for this store across RDB, App, OO, Kiosk and Register."
                        />
                        <FormField
                            control={form.control}
                            name="customFeeLabels.snackpassConvenienceLabel"
                            render={({ field }) => (
                                <FormItem className="flex w-full flex-row content-center gap-4">
                                    <FormLabel className="min-w-[180px]">
                                        Operating Fee Label
                                    </FormLabel>
                                    <FormControl>
                                        <div className="flex">
                                            <Input
                                                id={field.name}
                                                placeholder="Operating Fee"
                                                {...field}
                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="customFeeLabels.snackpassConvenienceDescription"
                            render={({ field }) => (
                                <FormItem className="flex w-full flex-row content-center gap-4">
                                    <FormLabel className="min-w-[180px]">
                                        Operating Fee Description
                                    </FormLabel>
                                    <FormControl>
                                        <div className="flex w-[450px]">
                                            <Input
                                                id={field.name}
                                                placeholder="Charged by our service provider to cover their operational costs."
                                                {...field}
                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="customFeeLabels.convenienceStoreLabel"
                            render={({ field }) => (
                                <FormItem className="flex w-full flex-row content-center gap-4">
                                    <FormLabel className="min-w-[180px]">
                                        Service Fee Label
                                    </FormLabel>
                                    <FormControl>
                                        <div className="flex">
                                            <Input
                                                id={field.name}
                                                placeholder="Service Fee"
                                                {...field}
                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="customFeeLabels.convenienceStoreDescription"
                            render={({ field }) => (
                                <FormItem className="flex w-full flex-row content-center gap-4">
                                    <FormLabel className="min-w-[180px]">
                                        Service Fee Description
                                    </FormLabel>
                                    <FormControl>
                                        <div className="w-[450px]">
                                            <Input
                                                id={field.name}
                                                placeholder="Set by our restaurant to cover operational costs."
                                                {...field}
                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="customFeeLabels.tipLabelPrefix"
                            render={({ field }) => (
                                <FormItem className="flex w-full flex-row content-center gap-4">
                                    <FormLabel className="min-w-[180px]">
                                        Tip Label Prefix
                                    </FormLabel>
                                    <FormControl>
                                        <div className="flex w-[300px]">
                                            <Input
                                                id="tipLabelPrefix"
                                                placeholder="100% of your tip supports"
                                                {...field}
                                            />
                                            <div
                                                className={
                                                    "pointer-events-none flex items-center rounded-lg border px-2 text-sm text-gray-700"
                                                }
                                            >
                                                {store.name}
                                            </div>
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <MinimumFooter />
                    </div>
                </FormProvider>
            </form>
        </>
    );
};
