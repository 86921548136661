import clsx from "clsx";
import { ReactNode } from "react";
import { match } from "ts-pattern";

export type FeatureBadgeProps = {
    className?: string;
    variant?: "new" | "beta" | "legacy";
    children?: ReactNode;
};

export const FeatureBadge = ({
    className,
    variant,
    children
}: FeatureBadgeProps) => {
    const color = match(variant)
        .with("new", () => "bg-green-500 text-white")
        .with("legacy", () => "bg-yellow-500")
        .otherwise(() => "bg-purple-500 text-white");

    const text = match(variant)
        .with("new", () => "New")
        .with("legacy", () => "Legacy")
        .otherwise(() => "Beta");
    return (
        <div
            className={clsx(
                "flex h-5 w-auto items-center justify-center gap-2 rounded-full px-2 py-[1px] text-xs font-medium leading-4",
                color,
                className
            )}
        >
            {children || text}
        </div>
    );
};
