export const DAY_OPTIONS = [
    {
        label: "Monday",
        value: 1
    },
    {
        label: "Tuesday",
        value: 2
    },
    {
        label: "Wednesday",
        value: 3
    },
    {
        label: "Thursday",
        value: 4
    },
    {
        label: "Friday",
        value: 5
    },
    {
        label: "Saturday",
        value: 6
    },
    {
        label: "Sunday",
        value: 7
    }
];

export const DEFAULT_START_HOURS = 60 * 9; // 9am
export const DEFAULT_END_HOURS = 60 * (12 + 5); // 5pm
export const DAY_END_MINUTES = 24 * 60 - 1; // 11:59pm for day in minutes
export const NOON_HOUR_MINUTES = 12 * 60;
