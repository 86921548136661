/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Spinner } from "react-activity";
import { VariantProps } from "class-variance-authority";
import clsx from "clsx";
import { toast } from "sonner";

import { Button, buttonVariants } from "src/@/components/ui/button";
import { ReactComponent as DownloadIcon } from "src/assets/icons/download-icon.svg";
import { ExportToCsv } from "#utils/export-to-csv";

interface DownloadCsvButtonProps extends VariantProps<typeof buttonVariants> {
    getData: () => unknown[];
    filename: string;
    className?: string;
}
export const DownloadCsvButton = ({
    getData,
    filename,
    className,
    variant = "outline"
}: DownloadCsvButtonProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleOnClick = () => {
        setIsLoading(true);

        const presentableData = getData();

        if (presentableData.length) {
            const csvExporter = new ExportToCsv({
                filename,
                fieldSeparator: ",",
                useKeysAsHeaders: true
            });

            presentableData.length > 1
                ? csvExporter.generateCsv(presentableData)
                : toast.info("There are no payouts to export.");
        } else {
            toast.info("There are no payouts to export.");
        }

        setIsLoading(false);
    };

    if (isLoading) {
        return <Spinner color="black" size={12} />;
    }

    return (
        <Button
            variant={variant}
            onClick={handleOnClick}
            className={clsx("flex space-x-1", className)}
        >
            <DownloadIcon />
            <span className="hidden md:block">Export</span>
        </Button>
    );
};
