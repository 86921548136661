import { IStore } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";
import { AdjustedAddon } from "../adjusted-addon";

class AddonNameCheck implements IMenuAutomationCheck<AdjustedAddon> {
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "AddonNameCheck";
    }

    get itemToCheck(): ItemType {
        return "addon";
    }

    checkItems() {
        return [];
    }

    checkItem(item: AdjustedAddon): MenuIssue<AdjustedAddon> | null {
        if (item.name === "New Addon" || item.name === "New Option") {
            return new MenuIssue(
                "InvalidAddonName",
                "Modifier options cannot be named 'New Addon' or 'New Option'",
                "critical",
                item
            );
        }
        return null;
    }
}

export { AddonNameCheck };
