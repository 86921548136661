import { PrepStationDeviceDetails } from "#devices/utils/deviceTypes/PrepStationDevice";

import { CashDrawerDeviceDetails } from "./CashDrawerDevice";
import { KDSDeviceDetails } from "./KDSDevice";
import { KioskDeviceDetails } from "./KioskDevice";
import { OrderHubDeviceDetails } from "./OrderHubDevice";
import { PrinterDeviceDetails, ParsedDeviceEvent } from "./PrinterDevice";
import { SnackTVDeviceDetails } from "./SnackTVDevice";
import { DeviceStats } from "./DeviceStats";

/**
 * TODO: Defer type definition to snackpass-types instead of defining here
 */

export enum MDMProvider {
    Esper = "ESPER"
}

export const MDM_PROVIDER_ENUM = Object.values(MDMProvider);

export enum DeviceType {
    All = "ALL",
    OrderHub = "ORDER_HUB",
    KDS = "KDS",
    Kiosk = "KIOSK",
    SnackTV = "SNACKTV",
    Printer = "PRINTER",
    CashDrawer = "CASH_DRAWER",
    PrepStation = "PREP_STATION",
    StripeTerminal = "STRIPE_TERMINAL"
}

export const DEVICE_TYPE_ENUM = Object.values(DeviceType);

export const DEFAULT_COUNT = {
    [DeviceType.All]: 0,
    [DeviceType.CashDrawer]: 0,
    [DeviceType.KDS]: 0,
    [DeviceType.Kiosk]: 0,
    [DeviceType.OrderHub]: 0,
    [DeviceType.Printer]: 0,
    [DeviceType.SnackTV]: 0,
    [DeviceType.PrepStation]: 0,
    [DeviceType.StripeTerminal]: 0
};

export type BaseStoreDevice = {
    id: string;

    /**
     * ID of store this device is assigned to
     */
    storeId: string;

    /**
     * ID of the full record of this device
     * e.g. for a Kiosk device stored in MongoDB, the OID of the Kiosk
     * document
     * Set for all but KDS-type objects
     */
    deviceDetailsId: string;

    /* MDM details */

    /**
     * For devices managed with an MDM solution, the name of that provider
     */
    mdmProvider: MDMProvider;

    /**
     * ID of device in MDM provider's system
     * Set iff `mdmProvider` is set.
     */
    mdmDeviceId: string;

    /* Hardware details */

    /**
     * Hardware serial number
     */
    serial: string;

    /**
     * Hardware manufacturer (e.g. "Lenovo")
     */
    hardwareMake: string;

    /**
     * Model of this device (e.g. "Lenovo TB-J606F")
     */
    hardwareModel: string;

    /* Snackpass-specific traits */

    /**
     * User-friendly ID derived from `mdmId` (if defined; else null)
     */
    snackId: string;

    /**
     * User-specified label for this device
     */
    name: string;

    /**
     * Type of this device (e.g. kiosk, KDS, etc.)
     */
    deviceType: DeviceType;

    /**
     * Date this device most recently pinged the server
     */
    lastPingAt: Date | null;

    /**
     * Date this device was first registered through the dashboard
     */
    createdAt: Date;

    /**
     * Date this device was most recently updated
     */
    updatedAt: Date;
};

type BaseStoreDeviceDetail = Omit<BaseStoreDevice, "deviceDetailsId"> & {
    events: ParsedDeviceEvent[];
    stats: DeviceStats;
    hasDeviceToken?: boolean;
};

export type OrderHubDevice = BaseStoreDeviceDetail & {
    deviceDetails: OrderHubDeviceDetails;
};

export type KDSDevice = BaseStoreDeviceDetail & {
    deviceDetails: KDSDeviceDetails;
};

export type KioskDevice = BaseStoreDeviceDetail & {
    deviceDetails: KioskDeviceDetails;
};

export type SnackTvDevice = BaseStoreDeviceDetail & {
    deviceDetails: SnackTVDeviceDetails;
};

export type PrinterDevice = BaseStoreDeviceDetail & {
    deviceDetails: PrinterDeviceDetails;
};

export type CashDrawerDevice = BaseStoreDeviceDetail & {
    deviceDetails: CashDrawerDeviceDetails;
};

export type PrepStationDevice = BaseStoreDeviceDetail & {
    deviceDetails: PrepStationDeviceDetails;
};

export type StripeTerminalDevice = BaseStoreDeviceDetail & {
    deviceDetails: unknown;
};

export type StoreDevice =
    | OrderHubDevice
    | KDSDevice
    | KioskDevice
    | SnackTvDevice
    | PrinterDevice
    | CashDrawerDevice
    | PrepStationDevice
    | StripeTerminalDevice;

export type DeviceAddon = PrinterDevice | CashDrawerDevice;

/** The status of a device's network connectivity. */
export type NetworkReportStatus =
    | "unknown"
    | "poor"
    | "degraded"
    | "stable"
    | "n/a";

/**
 * Classification of a device's network connectivity for a given time period.
 */
export type NetworkReportPeriod = {
    /** The status of the device's network connectivity. */
    status: NetworkReportStatus;

    /** A label representing the time period. */
    label: string;
};
