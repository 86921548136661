import { Modal, Col } from "antd";
import React, { useContext } from "react";
import styled from "styled-components";

import { FooterContainer } from "#payouts/components/shared/ModalComponents";
import { PayoutsSettingsContext } from "#payouts/utils/PayoutsSettingsContext";

export const StyledModal = ({
    open = false,
    setOpen,
    header,
    footer,
    children,
    skipVerificationCheck
}: {
    open: boolean;
    setOpen: (o: boolean) => void;
    header?: JSX.Element;
    footer?: JSX.Element;
    children?: JSX.Element | JSX.Element[];
    skipVerificationCheck?: boolean;
}) => {
    const { verification } = useContext(PayoutsSettingsContext);
    if ((!verification && !skipVerificationCheck) || !open) return null;
    return (
        <Modal
            className="w-screen max-w-3xl"
            open={open}
            closable={false}
            title={header}
            footer={<FooterContainer>{footer}</FooterContainer>}
            onCancel={() => setOpen(false)}
        >
            {children}
        </Modal>
    );
};

export const Header = ({
    left,
    center,
    right
}: {
    left?: string | JSX.Element;
    center?: string | JSX.Element;
    right?: string | JSX.Element;
}) => (
    <HeaderWrapper>
        <Col span={4}>
            <Container>{left ?? null}</Container>
        </Col>
        <Col span={16}>
            <Container>
                <Flatten>{center ?? null}</Flatten>
            </Container>
        </Col>
        <Col span={4}>
            <Container>{right ?? null}</Container>
        </Col>
    </HeaderWrapper>
);

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    justify-content: space-between;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
`;

const Flatten = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    justify-content: center;
    line-height: ${({ theme }) => theme.spacing.doubleAndHalf};
`;
