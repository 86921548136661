import React, { SetStateAction, useContext, useEffect } from "react";

import { ReactComponent as SearchSVG } from "src/assets/icons/search.svg";

import "react-swipeable-list/dist/styles.css";
import { IProduct, ScreenState } from "@snackpass/snackpass-types";
import {
    useExpanded,
    UseTableRowProps,
    UseExpandedRowProps
} from "react-table-7";
import styled from "styled-components";
import hasOwn from "object.hasown";
import { SystemColors } from "@snackpass/design-system";
import { Spin } from "antd";
import _ from "lodash";
import produce from "immer";

import {
    IProductCategoryWithProducts,
    MenuTopLevelContext
} from "#menu-editor/mobile-friendly/helpers/context";
import MenuAutomation from "#menu-editor/mobile-friendly/menu-automation";
import SearchBar from "#reusable/input/search-bar";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { useFocus } from "#menu-editor/mobile-friendly/helpers/use-focus";
import { hasDuplicates } from "#menu-editor/mobile-friendly/helpers/utils";
import colors from "#reusable/colors/colors.json";
import {
    UseTable7TypedColumnOptions,
    useTable7Typed
} from "src/utils/useTable7Typed";

import { TableRow } from "./tableRow";

const StyleForDnd = styled.div`
    table {
        border-collapse: collapse;
        width: 100%;
        font-feature-settings: "tnum";
        font-variant-numeric: tabular-nums;
    }

    td {
        font-size: 1rem;
        font-weight: normal;
        line-height: 1rem;
        padding: 0px 2px 0px 5px;
    }

    thead th {
        display: flex;
    }

    .search-bar-thead {
        display: grid;
        padding: 0 !important;
    }

    thead th:first-of-type {
        border-left: none;
    }

    thead th:last-of-type {
        border-right: none;
    }

    thead th.no-wrap {
        white-space: nowrap;
    }

    .menuIssues {
        font-size: 4rem;
        position: absolute;
        top: 30%;
        right: 1%;
    }

    td,
    th[scope="row"],
    th[scope="rowgroup"],
    [role="rowheader"] {
        color: var(--grey-12);
    }

    td {
    }

    th[scope="rowgroup"],
    th[scope="row"],
    [role="rowheader"] {
        min-width: 320px;
    }

    .TableRow.isDragging {
        cursor: grabbing;
        transition: ease-in-out;
    }

    .TableRowHeadingCell {
        text-align: left;
        transition: ease-in-out;
        &:hover {
            background: rgba(0, 0, 0, 0.02);
        }
    }

    .TableRowHeadingCell.isDragging {
        opacity: 0.3;
        @media ${ScreenState.MOBILE} {
            opacity: 1;
            transform: scale(1.02);
            border: none;
            box-shadow: 1px 0 5px -2px ${SystemColors.v2.salt30.dark};
        }
    }
    .TableRowHeadingCell.rowIsActive {
        background: rgba(0, 0, 0, 0.035);
    }

    .TableRowHeadingCellWrapper {
        --depth: 1;
    }

    .TableRowHeadingCellButton {
        position: absolute;
        top: 30%;
        right: 1%;
    }

    .TableRowHeadingCellButtonActions {
        position: absolute;
        top: 30%;
        right: 1%;

        @media ${ScreenState.MOBILE} {
            display: none;
        }
    }

    .card {
        position: relative;
        border-radius: 0;
    }

    .card.isSubRow {
        border: none;
        border-bottom: 1px solid ${colors["neutral-400"]};
    }

    .card.isNotSubRow {
        border: none;
        border-bottom: 1px solid ${colors["neutral-400"]};
    }
    .delete-category {
        position: absolute;
        width: 18px;
        height: 18px;
        top: 20%;
        left: 20%;
        background-color: inherit;
        cursor: pointer;
    }
    .category-iconWrapper {
        position: absolute;
        top: 38%;
        right: 2%;
        margin-right: 40px;
        border: 1px solid ${colors["neutral-400"]};
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        align-items: center;
        display: flex;
    }

    .delete-category path {
        fill: ${SystemColors.v2.salt100.light};
    }

    .TableRowHeadingCellButton .chevron-down {
        width: 3rem;
        height: 3rem;
        padding: 1px 0.8rem;
        border: none;
        transition: 0.3s;
    }

    .TableRowHeadingCellButtonActions .iconWrapper {
        display: inline-block;
        border: 1px solid ${colors["neutral-400"]};
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        text-align: center;
        padding: 5px;
        margin-left: 5px;
    }

    .TableRowHeadingCellButtonActions .actions {
        padding: 1px;
        width: 1.1rem;
        height: 1.1rem;
        text-align: center;
    }

    .iconWrapper:hover {
        cursor: pointer;
    }

    .TableRowHeadingCellButton.isExpanded .chevron-down {
        transform: rotate(0.5turn);
    }

    .TableRowHeadingCellDragHandler {
        cursor: grab;
        position: absolute;
        top: 30%;
        left: 0;
        background-color: inherit;
        color: inherit;
    }

    .TableRowHeadingCellDragHandler.isSubRow {
        cursor: grab;
        position: absolute;
        top: 35%;
        left: 10px;
    }

    .TableRowHeadingCellDragHandler.isDragging {
        cursor: grabbing;
    }

    .TableRowHeadingCellChildren {
        grid-column-start: children;
        padding-top: 1rem;
    }

    .search-bar-wrapper {
        margin-bottom: 0.5rem;
        margin-left: 0.5rem;
        margin-top: 0.8rem;
        display: flex;
        .actions {
            flex: 4;
        }
    }

    .select-menu-mobile-wrapper {
        justify-content: flex-end;
        display: flex;
        margin-top: 8px;
        border-bottom: 1px solid ${colors["neutral-400"]};
    }

    .mobile-select-menu {
        margin-right: 5px;
        flex: 1;
        margin-top: 0.3rem;
        width: 4rem !important;
        height: 20px !important;
    }

    .search-bar-mobile {
        display: flex;
        flex: 1;
        justify-content: end;
        margin-right: 5px;
    }

    .swipeable-list-item__content .swipeable-list-item__content--return {
        flex: 7;
    }

    .swipe-icon path {
        fill: ${colors["neutral-400"]};
    }

    .swipe-icon {
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
    }
`;

const Search = styled.div`
    border: 1px solid ${SystemColors.v1.gray80};
    height: 34px;
    border-radius: 48px;
    display: flex;
    align-items: center;

    margin-top: 3px;
`;

const SearchInput = styled.input<{ isFocused?: boolean }>`
    background: none;
    border: none !important;
    outline: none !important;
    padding: 0 0 0 16px;
    font-size: 16px;
    transition: all 0.2s;
    line-height: 40px;
    display: ${({ isFocused }) => (isFocused ? "inherit" : "none")};
    width: ${({ isFocused }) => (isFocused ? "130px" : "0px")};
    &:focus {
        box-shadow: none;
    }
`;

const SearchIcon = styled.div`
    width: 34px;
    height: 34px;
    border-radius: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
`;

const MOBILE_BREAKPOINT = 768;

// Table

type TableProps = {
    columns: UseTable7TypedColumnOptions<
        typeof useExpanded,
        IProductCategoryWithProducts | IProduct
    >[];
    children?: React.ReactNode;
    isMenuSyncing: boolean;
    selectMenu: boolean;
    SelectMenuType: React.ReactNode;
    onChange: React.Dispatch<SetStateAction<string>>;
    globalFilter: string;
    menuAutomation: MenuAutomation;
};

export const Table = ({
    columns,
    children,
    isMenuSyncing,
    selectMenu,
    SelectMenuType,
    menuAutomation,
    globalFilter,
    onChange
}: TableProps) => {
    const { data, setData, showTableSpinner, products } =
        useContext(MenuTopLevelContext);

    // Show duplicate products/category if exists

    // create a map with the occurrence of each product in products collection
    const occurrenceMap = _.countBy(products, "_id");

    // update the occurrence of each product in categories/store collection
    data.map((category) => {
        if (occurrenceMap[category._id]) {
            // found a duplicate category, increment its occurrence
            occurrenceMap[category._id]++;
        } else {
            // add categories to occurrenceMap if it not exist in occurrenceMap
            occurrenceMap[category._id] = 1;
        }
        category.productIds.map((productId) => {
            occurrenceMap[productId]++;
        });
        // at this point, if category and product have no duplicates:
        // each category's occurrence should be 1 (category only appears in store.productCategories but not in product list)
        // each product's occurrence should 2 (each product appears in both store.productCategories and product list)
        // otherwise the occurrence should be bigger than these numbers, we will subtract 1 while rendering each row, the idea here is to give every duplicate category/product a unique occurrence number so that the react table can show it on the UI
    });
    const getOccurrence = (id: string) => {
        occurrenceMap[id] = occurrenceMap[id] - 1;
        return occurrenceMap[id];
    };
    const resolveRowId = (category: IProductCategoryWithProducts | IProduct) =>
        `${category?._id} ${getOccurrence(category._id)}`;

    const resolveSubRows = (
        category: IProductCategoryWithProducts | IProduct
    ) =>
        (hasOwn(category, "products") &&
            (category as IProductCategoryWithProducts).products) ||
        [];

    const { width } = useWindowDimensions();
    React.useEffect(() => {
        setData(data);
    }, [selectMenu, isMenuSyncing]);

    const handleRowMove = React.useCallback(
        ({
            dragIndex,
            hoverIndex,
            row
        }: {
            dragIndex: number;
            hoverIndex: number;
            row: UseTableRowProps<IProductCategoryWithProducts | IProduct> &
                UseExpandedRowProps<IProductCategoryWithProducts | IProduct>;
        }) => {
            const depth = row.depth;
            const index = row.index;
            const targetIndex = dragIndex > hoverIndex ? index + 1 : index - 1;
            const original = row.original;

            if (depth === 1) {
                setData((current: IProductCategoryWithProducts[]) => {
                    const topMostParentIndex = current.findIndex(
                        (entry: IProductCategoryWithProducts) =>
                            entry.productIds.find(
                                (productId: string) =>
                                    productId === original._id
                            )
                    );

                    // remove the duplicate products under the category if exists
                    if (
                        hasDuplicates(current[topMostParentIndex].productIds) ||
                        hasDuplicates(current[topMostParentIndex].products)
                    ) {
                        const uniqProductList = produce(current, (draft) => {
                            draft[topMostParentIndex].products = _.uniqBy(
                                draft[topMostParentIndex].products,
                                "_id"
                            );
                            draft[topMostParentIndex].productIds = _.uniq(
                                draft[topMostParentIndex].productIds
                            );
                        });

                        return uniqProductList;
                    }

                    const result = produce(current, (draft) => {
                        draft[topMostParentIndex].products.splice(index, 1);
                        draft[topMostParentIndex].productIds.splice(index, 1);
                        draft[topMostParentIndex].products.splice(
                            targetIndex,
                            0,
                            original as IProduct
                        );
                        draft[topMostParentIndex].productIds.splice(
                            targetIndex,
                            0,
                            original._id
                        );
                    });
                    // make sure categories have same length and products/productIds array has no duplicates after changing the order, if not, return original state and don't update
                    if (
                        !_.isEqual(current, result) &&
                        current.length === result.length &&
                        current[topMostParentIndex].productIds.length ===
                            result[topMostParentIndex].productIds.length &&
                        current[topMostParentIndex].products.length ===
                            result[topMostParentIndex].products.length &&
                        !hasDuplicates(result[topMostParentIndex].productIds) &&
                        !hasDuplicates(result[topMostParentIndex].products)
                    ) {
                        return result;
                    }

                    return current;
                });
            }

            if (depth === 0) {
                setData((current: IProductCategoryWithProducts[]) => {
                    // remove the duplicate categories if exists
                    if (hasDuplicates(current)) {
                        const removeCategoryDuplicates = produce(
                            current,
                            (draft) => (draft = _.uniqBy(draft, "_id"))
                        );

                        return removeCategoryDuplicates;
                    }
                    const result = produce(current, (draft) => {
                        draft.splice(index, 1);
                        draft.splice(
                            targetIndex,
                            0,
                            original as IProductCategoryWithProducts
                        );
                    });

                    // make sure categories have same length after update and the order changed, if not, return original state and don't update
                    if (
                        !_.isEqual(current, result) &&
                        current.length === result.length &&
                        !hasDuplicates(result)
                    ) {
                        return result;
                    }

                    return current;
                });
            }
        },
        []
    );
    const { getTableProps, getTableBodyProps, rows, prepareRow } =
        useTable7Typed<
            IProduct | IProductCategoryWithProducts,
            [typeof useExpanded]
        >(
            {
                columns,
                data,
                getRowId: resolveRowId,
                getSubRows: resolveSubRows,
                //@ts-ignore
                autoResetExpanded: false
            },
            useExpanded
        );

    const findParentIdx = (depth: number, id: string | number) => {
        if (depth === 1) {
            return data.findIndex((entry: IProductCategoryWithProducts) =>
                entry?.products?.find((product: IProduct) => product._id === id)
            );
        }
        return undefined;
    };
    const [isSearchExpanded, setIsSearchExpanded] =
        React.useState<boolean>(false);
    const { htmlElRef, setFocus } = useFocus();
    const searchBarRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (searchBarRef && searchBarRef.current) {
            searchBarRef.current.value = "";
        }

        if (htmlElRef && htmlElRef.current) {
            htmlElRef.current.value = "";
        }

        onChange("");
    }, [selectMenu]);
    return (
        <StyleForDnd>
            <Spin spinning={showTableSpinner} tip="Please wait...">
                <table {...getTableProps()}>
                    <thead className="search-bar-thead">
                        {width > MOBILE_BREAKPOINT ? (
                            <tr className="search-bar-wrapper">
                                <th className="search-header">
                                    <SearchBar
                                        value={globalFilter}
                                        searchBarRef={searchBarRef}
                                        onChange={onChange}
                                        placeHolder="Search Items"
                                    />
                                </th>
                                <td className="actions">{children}</td>
                            </tr>
                        ) : (
                            <tr className="select-menu-mobile-wrapper">
                                <td className="mobile-select-menu">
                                    {SelectMenuType}
                                </td>
                                <th className="search-bar-mobile">
                                    <Search
                                        onClick={() => {
                                            setIsSearchExpanded(
                                                (prev) => !prev
                                            );
                                            setFocus();
                                        }}
                                    >
                                        <SearchInput
                                            placeholder="Search"
                                            ref={htmlElRef}
                                            isFocused={isSearchExpanded}
                                            onChange={(e) => {
                                                onChange(e.target.value);
                                            }}
                                            onClick={(
                                                e: React.MouseEvent<
                                                    HTMLInputElement,
                                                    MouseEvent
                                                >
                                            ) => e.stopPropagation()}
                                        />
                                        <SearchIcon>
                                            <SearchSVG />
                                        </SearchIcon>
                                    </Search>
                                </th>
                                <th className="actions">{children}</th>
                            </tr>
                        )}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, index) => {
                            prepareRow(row);
                            const [cell, ...cells] = row.cells;
                            return data ? (
                                <TableRow
                                    {...row.getRowProps()}
                                    menuAutomation={menuAutomation}
                                    index={index}
                                    id={row.id}
                                    row={row}
                                    handleRowMove={handleRowMove}
                                    headerCell={cell}
                                    parentIndex={findParentIdx(
                                        row.depth,
                                        row.id
                                    )}
                                >
                                    {cells.map((cell) => (
                                        <table
                                            key={
                                                "category-table" +
                                                cell.column.id
                                            }
                                        >
                                            <tbody>{cell.render("Cell")}</tbody>
                                        </table>
                                    ))}
                                </TableRow>
                            ) : null;
                        })}
                    </tbody>
                </table>
            </Spin>
        </StyleForDnd>
    );
};
