import React from "react";

import { Input } from "src/@/components/ui/input";

type Props = {
    value?: number;
    disabled?: boolean;
    prefix?: string;
    placeholder: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const AmountInput = ({
    value,
    disabled = false,
    prefix,
    placeholder,
    onChange
}: Props) => (
    <div className="flex flex-row">
        {prefix && (
            <div
                className={`pointer-events-none flex items-center rounded-l-lg border border-r-0 px-2 text-sm text-gray-700${disabled ? " opacity-50" : ""}`}
            >
                {prefix}
            </div>
        )}
        <Input
            className={["flex w-[120px]", prefix ? "rounded-l-none" : ""].join(
                " "
            )}
            min={0}
            step={0.01}
            value={value}
            disabled={disabled}
            type="number"
            placeholder={placeholder}
            onChange={onChange}
        />
    </div>
);
