import React from "react";

import TimeCardTableWithOptionalSummary from "#employees/employee-time-cards/table-with-optional-summary";
import { FeatureBadge } from "#navigation/FeatureBadge";

const TimeCard = () => (
    <div className="p-6 md:px-12">
        <div className="flex flex-row items-center gap-3">
            <div className="text-large font-semibold">Time Cards</div>
            <FeatureBadge variant="beta" />
        </div>

        <TimeCardTableWithOptionalSummary />
    </div>
);

export default TimeCard;
