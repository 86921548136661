import { SystemColors } from "@snackpass/design-system";
import { IPurchase } from "@snackpass/snackpass-types";
import get from "lodash/get";
import React, { useState } from "react";
import { Spinner } from "react-activity";
import styled from "styled-components";
import { toast } from "sonner";

import API from "src/api/rest";
import { ReactComponent as Printer } from "src/assets/icons/printer.svg";
import { printHTML } from "#utils/print-html";

import { Button } from "./ui";

type Props = {
    purchase: IPurchase;
};

export const PrintButton = ({ purchase }: Props) => {
    const [isLoading, setLoading] = useState(false);

    const printReceipt = async () => {
        if (!purchase) return;
        setLoading(true);
        try {
            const { data } = await API.purchases.htmlReceipt(purchase._id, {
                userId: null
            });
            printHTML(data.html);
        } catch (err) {
            toast.error(
                get(err, "response.data.message") ||
                    "An error occurred printing."
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button onClick={printReceipt}>
            {isLoading ? (
                <Spinner color={SystemColors.v1.sesame} size={16} />
            ) : (
                <>
                    <div>
                        <Printer fill={SystemColors.v1.sesame} />
                    </div>
                    <MarginLeft>Print Order</MarginLeft>
                </>
            )}
        </Button>
    );
};

const MarginLeft = styled.div`
    margin-left: 8px;
`;
