import { ScreenState } from "@snackpass/snackpass-types";
import { Spin } from "antd";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useExpanded, useGlobalFilter, useAsyncDebounce } from "react-table-7";
import styled from "styled-components";

import constants from "#core/constants";
import { MenuOutlineCellTypeEnum } from "#menu-editor/multi-menus/helpers";
import {
    getRowId,
    getSubRows,
    menuOutlineSearchFunction
} from "#menu-editor/multi-menus/helpers/table-helpers";
import {
    getCurrentMenuId,
    getMenuCategories,
    getMultiMenuLoadingState
} from "#menu-editor/multi-menus/redux/selectors";
import { BaseTable } from "#menu-editor/multi-menus/screens/menu-overview-desktop/components/items-table/components/base-table";
import { menuOutlineColumns } from "#menu-editor/multi-menus/screens/menu-overview-desktop/components/items-table/components/columns/columns";
import { TableSearchBar } from "#menu-editor/multi-menus/shared-components/table-search-bar";
import colors from "#reusable/colors/colors.json";
import { useTable7Typed } from "src/utils/useTable7Typed";

type ItemsTableProps = {
    increaseOutlineChangesCount: () => void;
};

export const ItemsTable: React.FC<ItemsTableProps> = ({
    increaseOutlineChangesCount
}) => {
    const menuId = useSelector(getCurrentMenuId);
    const menuCategories = useSelector(getMenuCategories);
    const loadingStateUpdateOverrides = useSelector(
        getMultiMenuLoadingState("UpdateOverrides")
    );
    const loadingStateReorderItems = useSelector(
        getMultiMenuLoadingState("UpdateMultiMenu")
    );
    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`
    });

    const columns = useMemo(
        () =>
            menuOutlineColumns.filter(
                ({ id }) =>
                    !isMobile || !(id === MenuOutlineCellTypeEnum.Overrides)
            ),
        [isMobile]
    );

    const {
        getTableProps,
        getTableBodyProps,
        rows,
        rowsById,
        prepareRow,
        headerGroups,
        setGlobalFilter,
        state: { globalFilter }
    } = useTable7Typed(
        {
            getRowId,
            getSubRows: getSubRows,
            autoResetGlobalFilter: false,
            columns: columns as any,
            // @ts-expect-error types seem slighlty incompatible
            data: menuCategories,
            globalFilter: menuOutlineSearchFunction,
            autoResetExpanded: false
        },
        useGlobalFilter,
        useExpanded
    );

    const debouncedSetGlobalFilter = useAsyncDebounce(setGlobalFilter, 200);

    return (
        <ContentWrapper>
            {!isMobile ? (
                <SearchBarWrapper>
                    <TableSearchBar
                        debouncedSetGlobalFilter={debouncedSetGlobalFilter}
                        globalFilter={globalFilter}
                    />
                </SearchBarWrapper>
            ) : null}

            <TableWrapper>
                <Spin
                    spinning={
                        loadingStateUpdateOverrides === "pending" ||
                        loadingStateReorderItems === "pending"
                    }
                    tip="Please wait..."
                >
                    <BaseTable
                        rowsById={rowsById}
                        menuId={menuId}
                        getTableBodyProps={getTableBodyProps}
                        headerGroups={headerGroups}
                        rows={rows}
                        getTableProps={getTableProps}
                        prepareRow={prepareRow}
                        shouldDrag={!globalFilter}
                        increaseOutlineChangesCount={
                            increaseOutlineChangesCount
                        }
                        globalFilterApplied={!!globalFilter}
                    />
                </Spin>
            </TableWrapper>
        </ContentWrapper>
    );
};

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 0px;
`;

const SearchBarWrapper = styled.div`
    display: flex;
    flex: 0.1;
`;

const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;

    table {
        width: 100%;
    }

    td:nth-child(1) {
        width: calc((100% - 150px) * 0.55); /* 55% */
    }

    td:nth-child(2) {
        width: calc((100% - 150px) * 0.35); /* 35% */
    }

    td:nth-child(3) {
        width: 150px;
    }

    @media ${ScreenState.MOBILE} {
        td:nth-child(1) {
            width: 77.5%;
        }

        td:nth-child(2) {
            width: 22.5;
        }
    }

    th {
        padding: 16px 0 8px 0;
        border-bottom: 1px solid #e1e3e6;
    }

    td {
        padding: 0;
        height: 0; /* we need this for filling parent height */
        border-bottom: 1px solid #e1e3e6;
        border-top: 1px solid #e1e3e6;
    }

    .tableRowDragging {
        opacity: 0.3;
    }

    .categoryRow {
        height: 80px;
    }

    .addonGroupRow {
        background-color: #f1f5f8;
        border-bottom: 1px solid ${colors["neutral-400"]};
        border-top: 1px solid ${colors["neutral-400"]};
    }

    margin-top: 5px;
`;
