import { useContext, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { getActiveStore } from "@snackpass/accounting";

import { ReportsContext } from "#app/reports-context-provider";
import { formatRange } from "#reports/sales-summary/lib";
import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import DownloadButton from "#reports/sales-summary/shared-components/DownloadButton";
import { GranularityType } from "#reports/sales-summary/types";
import { DataTable } from "src/@/components/ui/data-table";
import { DataTableToolbarOptions } from "src/@/components/ui/data-table/table-toolbar";
import {
    columns,
    columnsForExport,
    formatRowsForExport
} from "#reports/promotions/lib";

const PromotionPerformanceTable = () => {
    const activeStore = useSelector(getActiveStore);
    const { reportsState } = useContext(ReportsContext);
    const { promotionsReportData, dateRanges } = reportsState;

    // Query params from react router, if use came from promo screen
    const promoNameQueryParam = new URLSearchParams(window.location.search).get(
        "promoName"
    );

    const toolbarOptions: DataTableToolbarOptions = {
        showColumnFilter: true,
        search: {
            key: "name",
            placeholder: "Search Promotions",
            defaultSearchValue: promoNameQueryParam
        },
        filters: [
            {
                title: "Type",
                key: "type",
                options: [
                    {
                        label: "Reward",
                        value: "REWARD"
                    },
                    {
                        label: "Discount",
                        value: "DISCOUNT"
                    },
                    {
                        label: "Deal",
                        value: "DEAL"
                    },
                    {
                        label: "Time Bomb",
                        value: "TIME_BOMB"
                    },
                    {
                        label: "Referral",
                        value: "REFERRAL"
                    },
                    {
                        label: "Promo Code",
                        value: "PROMO_CODE"
                    },
                    {
                        label: "Birthday",
                        value: "BIRTHDAY"
                    },
                    {
                        label: "Party",
                        value: "PARTY"
                    },
                    {
                        label: "Retargeting",
                        value: "RETARGETING"
                    }
                ]
            },
            {
                title: "Status",
                key: "Status",
                options: [
                    {
                        label: "Live",
                        value: true as unknown as string
                    },
                    {
                        label: "Ended",
                        value: false as unknown as string
                    }
                ]
            }
        ]
    };

    const isLoading = promotionsReportData?.promotionsDataLoading;
    const hasFailed = promotionsReportData?.promotionsDataFailed;
    const rows = useMemo(() => {
        const data = promotionsReportData?.promotionsData;
        if (!data) return [];
        // we sort the actual rows instead of through the react-table column sort so the export is also sorted.
        return data.sort((a, b) => b.totalUses - a.totalUses);
    }, [promotionsReportData]);

    return (
        <div className="mb-20">
            <div className="mb-4 flex items-center justify-between">
                <h4 className="text-large">Promotion Performance</h4>
                <DownloadButton
                    rows={formatRowsForExport(rows)}
                    columns={columnsForExport}
                    filename={`${
                        activeStore?.name
                    } Promotions Report ${formatRange(
                        dateRanges[0],
                        GranularityType.DAILY
                    )}`}
                />
            </div>
            <div>
                {!isLoading &&
                (hasFailed || promotionsReportData?.promotionsData) ? (
                    promotionsReportData?.promotionsData ? (
                        <DataTable
                            toolbar={toolbarOptions}
                            showPagination
                            columns={columns}
                            data={rows}
                            hiddenColumns={["type"]}
                        />
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

export default PromotionPerformanceTable;
