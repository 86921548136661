import React from "react";

import { cn } from "src/@/lib/utils";

enum ToggleState {
    None,
    View,
    Edit
}

type ToggleProps = {
    title: string;
    readPerm: boolean | undefined;
    writePerm: boolean | undefined;
    onSetRead: () => void;
    onSetWrite: () => void;
    onSetNone: () => void;
    disabled?: boolean;
};

const options = [
    { label: "None", value: ToggleState.None },
    { label: "View", value: ToggleState.View },
    { label: "Edit", value: ToggleState.Edit }
];

const getIndexFromState = (readPerm: boolean, writePerm: boolean): number => {
    if (readPerm && writePerm) {
        return ToggleState.Edit;
    } else if (readPerm) {
        return ToggleState.View;
    } else {
        return ToggleState.None;
    }
};

export const ToggleReadWriteNone = ({
    title,
    readPerm = false,
    writePerm = false,
    onSetRead,
    onSetWrite,
    onSetNone,
    disabled
}: ToggleProps) => {
    const selectedIndex = getIndexFromState(readPerm, writePerm);

    return (
        <div className="my-2 flex items-center justify-between border-b border-neutral-400 pb-4 pt-2">
            <div>{title}</div>
            <div className="min-w-[30%]">
                <div className="flex space-x-1 rounded-lg bg-gray-light p-[1px]">
                    <div className="relative flex-1 rounded-lg">
                        <div
                            className="absolute h-full p-[1px] transition-all duration-300"
                            style={{
                                width: `${100 / options.length}%`,
                                translate: `${selectedIndex * 100}%`
                            }}
                        >
                            <div className="relative h-full rounded-md bg-white shadow-sm" />
                        </div>
                        <div className="relative flex flex-row gap-[1px] rounded-md py-1">
                            {options.map((option) => {
                                const selected = selectedIndex === option.value;
                                const toggleId = `${title}-${option.value}`;
                                return (
                                    <label
                                        key={toggleId}
                                        className={cn(
                                            "rounded-md overflow-hidden transition-all duration-300 flex flex-1 justify-center relative py-1 select-none",
                                            !disabled && "cursor-pointer"
                                        )}
                                    >
                                        <span
                                            className={cn(
                                                selected && "font-medium",
                                                !selected && "text-neutral-600",
                                                "text-micro"
                                            )}
                                        >
                                            {option.label}
                                        </span>

                                        <input
                                            type="radio"
                                            id={toggleId}
                                            value={option.value}
                                            className={cn("hidden", {
                                                "shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]":
                                                    selected
                                            })}
                                            onClick={(e) => {
                                                switch (
                                                    parseInt(
                                                        (
                                                            e.target as HTMLInputElement
                                                        ).value
                                                    )
                                                ) {
                                                    case ToggleState.None:
                                                        onSetNone();
                                                        break;
                                                    case ToggleState.View:
                                                        onSetRead();
                                                        break;
                                                    case ToggleState.Edit:
                                                        onSetWrite();
                                                        break;
                                                }
                                            }}
                                            disabled={disabled}
                                        />
                                    </label>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
