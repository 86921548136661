"use client";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "sonner";
import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReloadIcon } from "@radix-ui/react-icons";
import {
    checkOverlappingHours,
    getFormattedTimezoneLong
} from "@snackpass/accounting/build/src/utils/Time";
import {
    SnackpassTimezoneEnum,
    ITimeRangeSchema
} from "@snackpass/snackpass-types";
import _ from "lodash";

import { Button } from "src/@/components/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage
} from "src/@/components/ui/form";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle
} from "src/@/components/ui/card";
import { Switch } from "src/@/components/ui/switch";
import { NumberInput } from "@tremor/react";
import { sendError } from "src/utils/errors";
import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";
import { getActiveStore } from "src/redux/selectors";
import api from "src/api/rest";
import { setActiveStore } from "src/redux/slices";
import { Textarea } from "src/@/components/ui/textarea";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "src/@/components/ui/select";
import { SpecialHourRows } from "#settings/settings-online-orders/special-delivery-hours";
import { validateHoursStartTimeBeforeEndTime } from "#settings/helper";
import { checkStoreHasDeliveryIntegrations } from "#settings/settings-platforms/helper";
import { useUnsavedChangesPrompt } from "#settings/hooks/useUnsavedChangesPrompt";
import { UnsavedChangesModal } from "#settings/components/unsaved-changes";
import { filterOutClosedHours } from "#settings/settings-online-orders/helpers";
import { getStoreOpenDays } from "#reusable/special-hours/helper";

const HourSchema = z.object({
    start: z.number(),
    end: z.number()
});

const formSchema = z
    .object({
        notes: z.string(),
        noNotes: z.boolean(),
        defaultBagInCart: z.boolean(),
        pickupMin: z.coerce
            .number({
                invalid_type_error:
                    "Please enter a number for the pickup minimum"
            })
            .optional(),
        hasPickupNow: z.boolean().optional(),
        hasScheduledOrders: z.boolean().optional(),
        pickupDirections: z.string().optional(),
        hasBatching: z.boolean().optional(),
        scheduleAheadInterval: z.coerce.number().optional(),
        scheduleAheadBatchSize: z.coerce.number().optional(),
        scheduledOrderPosTriggerTime: z.coerce.number().optional(),
        scheduleAheadFirstTimeSlotBuffer: z.coerce.number().optional(),
        deliveryMin: z.coerce
            .number({
                invalid_type_error:
                    "Please enter a number for the delivery minimum"
            })
            .optional(),
        hasSpecialDeliveryHours: z.boolean().optional(),
        specialDeliveryHoursLocal: z.array(HourSchema).nullable(),
        disablePopularSectionOnOO: z.boolean().optional(),

        // Wait Time settings
        pickupTimeType: z.enum(["specific", "range"]),
        defaultPickupMinTime: z.coerce.number().optional(),
        defaultPickupMaxTime: z.coerce.number().optional(),
        defaultPickupTime: z.coerce.number().optional()
    })
    .refine((data) => data.hasPickupNow || data.hasScheduledOrders, {
        message: "Either ASAP or Schedule Ahead required.",
        path: ["hasPickupNow"]
    })
    .refine((data) => data.hasPickupNow || data.hasScheduledOrders, {
        message: "Either ASAP or Schedule Ahead required.",
        path: ["hasScheduledOrders"]
    })
    .refine(
        (data) =>
            data.hasSpecialDeliveryHours
                ? !_.isEmpty(data.specialDeliveryHoursLocal)
                : true,
        {
            message:
                "Special delivery hours must be provided if special delivery is enabled.",
            path: ["specialDeliveryHoursLocal"]
        }
    )
    .refine(
        (data) => {
            if (data.hasSpecialDeliveryHours) {
                return checkOverlappingHours(
                    data.specialDeliveryHoursLocal as ITimeRangeSchema[]
                );
            }
            return true;
        },
        {
            message: "Store Hours cannot overlap!",
            path: ["specialDeliveryHoursLocal"]
        }
    )
    .refine(
        (data) => {
            if (data.hasSpecialDeliveryHours) {
                return validateHoursStartTimeBeforeEndTime(
                    data.specialDeliveryHoursLocal as ITimeRangeSchema[]
                );
            }
            return true;
        },
        {
            message: "End time must be after start time!",
            path: ["specialDeliveryHoursLocal"]
        }
    )
    .refine((data) => data.pickupTimeType !== "specific", {
        message:
            "Specific wait time is no longer available. Please switch to Range.",
        path: ["pickupTimeType"]
    })
    .refine(
        (data) =>
            data.pickupTimeType === "range" &&
            data.defaultPickupMinTime &&
            data.defaultPickupMaxTime &&
            data.defaultPickupMinTime < data.defaultPickupMaxTime,
        {
            message:
                "Lower bound on the manual time must be less than upper bound.",
            path: ["pickupTimeType"]
        }
    );

type ManualWaitTimeType = "specific" | "range";

const updateNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: { value: number | undefined; onChange: (val: number) => void }
) => {
    field.onChange(Number.parseFloat(e.target.value ?? 0));
};

type CustomDollarInputProps = {
    value: number | undefined;
    onChange: (value: number) => void;
    disabled: boolean;
};

const CustomDollarInput = ({
    value,
    onChange,
    disabled
}: CustomDollarInputProps) => {
    const [dollarValue, setDollarValue] = useState((value ?? 0).toFixed(2));

    useEffect(() => {
        setDollarValue((value ?? 0).toFixed(2));
    }, [value]);

    return (
        <NumberInput
            icon={() => <span className="pl-3 text-small">$</span>}
            placeholder="0.00"
            min={0}
            step={0.01}
            className="w-[180px] flex-1 [&>input]:pl-2"
            value={dollarValue}
            onBlur={() => {
                if (Number(dollarValue) !== value) {
                    setDollarValue(
                        Number(parseFloat(dollarValue ?? 0)).toFixed(2)
                    );
                    onChange(Number(dollarValue));
                }
            }}
            onChange={(e) => {
                setDollarValue(e.target.value);
            }}
            disabled={disabled}
            enableStepper={false}
        />
    );
};

const changeNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: { value: number | undefined; onChange: (val: number) => void }
) => {
    field.onChange(Number.parseFloat(e.target.value ?? 0));
};

export function SettingsOnlineOrders() {
    const [isLoading, setIsLoading] = useState(false);
    const canEdit = useHasEditSettingsForActiveStore();
    const activeStore = useSelector(getActiveStore);
    const dispatch = useDispatch();
    const disableDeliverySettings = !(
        activeStore?.delivery ||
        checkStoreHasDeliveryIntegrations(activeStore?.integrations)
    );

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            notes: "",
            defaultBagInCart: false,
            pickupMin: 0,
            hasPickupNow: true,
            hasScheduledOrders: true,
            pickupDirections: "",
            hasBatching: false,
            scheduleAheadInterval: 0,
            scheduleAheadBatchSize: 0,
            scheduledOrderPosTriggerTime: 0,
            scheduleAheadFirstTimeSlotBuffer: 15,
            deliveryMin: 0,
            hasSpecialDeliveryHours: false,
            specialDeliveryHoursLocal: [],
            disablePopularSectionOnOO: false,

            // Wait Time settings
            pickupTimeType: "range",
            defaultPickupMinTime: 10,
            defaultPickupMaxTime: 20,
            defaultPickupTime: 10
        }
    });

    const defaultValues: z.infer<typeof formSchema> = useMemo(
        () => ({
            notes: activeStore?.notes ?? "",
            noNotes: activeStore?.noNotes ?? false,
            // this is a hack because the type of defaultBagInCart is not boolean
            defaultBagInCart: activeStore?.defaultBagInCart === 1,
            pickupMin: activeStore?.pickupMin ?? 0,
            hasPickupNow: activeStore?.hasPickupNow ?? true,
            hasScheduledOrders: activeStore?.hasScheduledOrders ?? true,
            pickupDirections: activeStore?.pickupDirections ?? "",
            hasBatching: activeStore?.hasBatching ?? false,
            scheduleAheadInterval: activeStore?.scheduleAheadInterval ?? 0,
            scheduleAheadBatchSize: activeStore?.scheduleAheadBatchSize ?? 0,
            scheduledOrderPosTriggerTime:
                activeStore?.scheduledOrderPosTriggerTime ?? 0,
            scheduleAheadFirstTimeSlotBuffer:
                activeStore?.scheduleAheadFirstTimeSlotBuffer ?? 0,
            deliveryMin: activeStore?.deliveryMin
                ? activeStore?.deliveryMin
                : 0,
            hasSpecialDeliveryHours:
                activeStore?.hasSpecialDeliveryHours ?? false,
            specialDeliveryHoursLocal: activeStore
                ? filterOutClosedHours(
                      activeStore?.specialDeliveryHours?.local ?? [],
                      getStoreOpenDays(activeStore?.hours)
                  )
                : [],
            disablePopularSectionOnOO:
                activeStore?.disablePopularSectionOnOO ?? false,

            // Wait Time settings
            pickupTimeType:
                (activeStore?.pickupTimeType as ManualWaitTimeType) ?? "range",
            defaultPickupMinTime: activeStore?.defaultPickupMinTime ?? 10,
            defaultPickupMaxTime: activeStore?.defaultPickupMaxTime ?? 20,
            defaultPickupTime: activeStore?.defaultPickupTime ?? 10
        }),
        [activeStore]
    );

    const resetForm = useMemo(
        () => () => {
            form.reset(defaultValues);
        },
        [form, defaultValues]
    );

    // Update form default values when activeStore is loaded
    useEffect(() => {
        if (activeStore) {
            resetForm();
        }
    }, [activeStore, resetForm]);

    const onSubmit = useCallback(
        async (values: z.infer<typeof formSchema>) => {
            if (!activeStore) {
                return;
            }

            if (!canEdit) {
                toast.error("You do not have permission to edit this store");
                return;
            }

            try {
                setIsLoading(true);
                const { data } = await api.stores.update(activeStore._id, {
                    notes: values.notes,
                    noNotes: values.noNotes,
                    defaultBagInCart: values.defaultBagInCart,
                    // pickupTimeType: values.pickupTimeType,
                    pickupMin: values.pickupMin,
                    hasPickupNow: values.hasPickupNow,
                    hasScheduledOrders: values.hasScheduledOrders,
                    pickupDirections: values.pickupDirections,
                    hasBatching: values.hasBatching,
                    scheduleAheadInterval: values.scheduleAheadInterval,
                    scheduleAheadBatchSize: values.scheduleAheadBatchSize,
                    scheduledOrderPosTriggerTime:
                        values.scheduledOrderPosTriggerTime,
                    scheduleAheadFirstTimeSlotBuffer:
                        values.scheduleAheadFirstTimeSlotBuffer,
                    deliveryMin: values.deliveryMin,
                    hasSpecialDeliveryHours: values.hasSpecialDeliveryHours,
                    specialDeliveryHours: !_.isEmpty(
                        values.specialDeliveryHoursLocal
                    )
                        ? {
                              zone: activeStore.hours.zone,
                              local: values.specialDeliveryHoursLocal
                          }
                        : null,
                    disablePopularSectionOnOO: values.disablePopularSectionOnOO,

                    // Wait Time settings
                    pickupTimeType: values.pickupTimeType,
                    defaultPickupMinTime: values.defaultPickupMinTime,
                    defaultPickupMaxTime: values.defaultPickupMaxTime,
                    defaultPickupTime: values.defaultPickupTime
                });
                dispatch(setActiveStore(data.store));
                toast.success("Store updated successfully");
            } catch (e) {
                sendError(e);
                toast.error("Cannot update the store, please try again later");
            } finally {
                setIsLoading(false);
            }
        },
        [activeStore, canEdit, dispatch, setIsLoading]
    );

    useEffect(() => {
        const subscription = form.watch(() => {});
        return () => subscription.unsubscribe();
    }, [form]);

    const {
        showModal,
        handleConfirmNavigationClick,
        handleCancelNavigationClick
    } = useUnsavedChangesPrompt(form.formState.isDirty);

    return (
        <div className="h-max overflow-auto bg-gray-50">
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="mb-24 space-y-8 p-8 sm:p-16"
                >
                    <UnsavedChangesModal
                        show={showModal}
                        onConfirm={handleConfirmNavigationClick}
                        onCancel={handleCancelNavigationClick}
                    />
                    <div>
                        <CardTitle className="text-2xl font-bold">
                            Online Orders
                        </CardTitle>
                        <div className="text-gray-600">
                            Configurations that only apply to mobile and online
                            ordering.
                        </div>
                        <hr className="border-gray-300" />
                    </div>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">Menu</CardTitle>
                            <CardDescription>
                                Configurations that only apply to mobile and
                                online ordering.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="notes"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Menu Store Message
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Add an announcement for
                                                customers that appears at the
                                                top of your store menu page.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <Textarea
                                                        className="resize-none outline-none [scrollbar-width:'none'] [&::-webkit-scrollbar]:hidden"
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        rows={3}
                                                        maxLength={275}
                                                        disabled={!canEdit}
                                                    />
                                                </div>
                                            </FormControl>
                                            <CardDescription className="pt-2 text-xs">
                                                Characters: {field.value.length}
                                                /275
                                            </CardDescription>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="pickupDirections"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Pickup Directions
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Add pickup instructions for
                                                customers.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <Textarea
                                                        className="resize-none outline-none [scrollbar-width:'none'] [&::-webkit-scrollbar]:hidden"
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        rows={3}
                                                        maxLength={275}
                                                        disabled={!canEdit}
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="disablePopularSectionOnOO"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Hide Popular Items Section
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Hide the "Popular" section
                                                    from the online ordering.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">Cart</CardTitle>
                            <CardDescription>
                                Configurations that only apply to mobile and
                                online ordering.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="pickupMin"
                                render={({ field: { value, onChange } }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Cart Minimum
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Set the minimum amount customers
                                                must spend for mobile and online
                                                orders.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <CustomDollarInput
                                                        value={value}
                                                        onChange={onChange}
                                                        disabled={!canEdit}
                                                    />
                                                </div>
                                            </FormControl>
                                        </div>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <hr />

                            <FormField
                                control={form.control}
                                name="hasPickupNow"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    ASAP Orders
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Allow customers to order
                                                    ASAP.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Schedule Ahead
                            </CardTitle>
                            <CardDescription>
                                Manage how orders scheduled ahead are handled.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="hasScheduledOrders"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Schedule Ahead Orders
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Allow customers to schedule
                                                    orders up to 2 weeks in
                                                    advance.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="scheduledOrderPosTriggerTime"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Order Hub Fire Time
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                This is the duration before the
                                                scheduled order time when the
                                                order will be dispatched to the
                                                Order Hub. Scheduled Orders will
                                                immediately show up in the
                                                Register's Order Management.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        placeholder="0"
                                                        min={0}
                                                        className="w-[180px] flex-1 [&>input]:pl-2"
                                                        icon={() => (
                                                            <div className="absolute right-0 mr-20 flex shrink-0 items-center text-micro">
                                                                minutes
                                                            </div>
                                                        )}
                                                        {...field}
                                                        onChange={(e) =>
                                                            changeNumber(
                                                                e,
                                                                field
                                                            )
                                                        }
                                                        disabled={
                                                            !form.watch(
                                                                "hasScheduledOrders"
                                                            ) || !canEdit
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="scheduleAheadFirstTimeSlotBuffer"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                First Time Slot Buffer
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                This is the duration added to
                                                the first available time slot
                                                for scheduling ahead orders. The
                                                first time slot for scheduled
                                                orders is when your store opens.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        placeholder="0"
                                                        min={0}
                                                        className="w-[180px] flex-1 [&>input]:pl-2"
                                                        icon={() => (
                                                            <div className="absolute right-0 mr-20 flex shrink-0 items-center text-micro">
                                                                minutes
                                                            </div>
                                                        )}
                                                        {...field}
                                                        onChange={(e) =>
                                                            changeNumber(
                                                                e,
                                                                field
                                                            )
                                                        }
                                                        disabled={
                                                            !form.watch(
                                                                "hasScheduledOrders"
                                                            ) || !canEdit
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="scheduleAheadInterval"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Time Slot Interval
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Set the interval between time
                                                slots for scheduled orders.
                                                Example: if set to 30, the time
                                                slots will be 30 minutes apart.
                                                At 10:00, 10:30, 11:00, etc.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        placeholder="0"
                                                        min={0}
                                                        className="w-[180px] flex-1 [&>input]:pl-2"
                                                        icon={() => (
                                                            <div className="absolute right-0 mr-20 flex shrink-0 items-center text-micro">
                                                                minutes
                                                            </div>
                                                        )}
                                                        {...field}
                                                        onChange={(e) =>
                                                            changeNumber(
                                                                e,
                                                                field
                                                            )
                                                        }
                                                        disabled={
                                                            !form.watch(
                                                                "hasScheduledOrders"
                                                            ) || !canEdit
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="hasBatching"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Enable Max Orders per Time
                                                    Slot
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Limit the number of orders
                                                    that can be placed in each
                                                    time slot.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            !canEdit ||
                                                            !form.watch(
                                                                "hasScheduledOrders"
                                                            )
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="scheduleAheadBatchSize"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Max Orders per Time Slot
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Set the maximum number of orders
                                                that can be placed in each time
                                                slot. Example: 10 means that up
                                                to 10 orders can be placed in
                                                each time slot.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <NumberInput
                                                        placeholder="0"
                                                        min={0}
                                                        className="w-[180px] flex-1 [&>input]:pl-2"
                                                        icon={() => (
                                                            <div className="absolute right-0 mr-20 flex shrink-0 items-center text-micro">
                                                                orders
                                                            </div>
                                                        )}
                                                        {...field}
                                                        onChange={(e) =>
                                                            changeNumber(
                                                                e,
                                                                field
                                                            )
                                                        }
                                                        disabled={
                                                            !form.watch(
                                                                "hasScheduledOrders"
                                                            ) ||
                                                            !form.watch(
                                                                "hasBatching"
                                                            ) ||
                                                            !canEdit
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Delivery
                            </CardTitle>
                            <CardDescription>
                                {disableDeliverySettings
                                    ? "Store delivery is disabled. Contact support to adjust delivery settings"
                                    : "Contact support to adjust additional delivery settings"}
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="deliveryMin"
                                render={({ field: { value, onChange } }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Delivery Cart Minimum
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Set the minimum amount customers
                                                must spend for delivery orders.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <CustomDollarInput
                                                        value={value}
                                                        onChange={onChange}
                                                        disabled={
                                                            disableDeliverySettings ||
                                                            !canEdit
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                        </div>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="hasSpecialDeliveryHours"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex justify-between">
                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Enable Special Delivery
                                                    Hours
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Setup delivery on special
                                                    dates and hours.
                                                </CardDescription>
                                            </div>
                                            <div className="my-auto">
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={
                                                            field.onChange
                                                        }
                                                        disabled={
                                                            disableDeliverySettings ||
                                                            !canEdit
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="specialDeliveryHoursLocal"
                                disabled={disableDeliverySettings}
                                render={({ field: { value, onChange } }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Special Delivery Hours
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                {activeStore?.hours &&
                                                activeStore.hours?.zone
                                                    ? `Time zone: ${getFormattedTimezoneLong(
                                                          activeStore?.hours
                                                              .zone as SnackpassTimezoneEnum
                                                      )}`
                                                    : null}
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <SpecialHourRows
                                                        value={value ?? []}
                                                        onChange={onChange}
                                                        disabled={
                                                            !form.watch(
                                                                "hasSpecialDeliveryHours"
                                                            ) ||
                                                            disableDeliverySettings ||
                                                            !canEdit
                                                        }
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </CardContent>
                    </Card>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Wait Time
                            </CardTitle>
                            <CardDescription>
                                Choose the wait time that customers see.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="pickupTimeType"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Manual Time
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Choose a specific wait (10 min)
                                                or a range (10-15 min).
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <Select
                                                        value={field.value}
                                                        onValueChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    >
                                                        <SelectTrigger className="w-[280px]">
                                                            <SelectValue placeholder="Select pickup time type" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectItem
                                                                value="specific"
                                                                disabled={
                                                                    activeStore?.pickupTimeType ===
                                                                    "range"
                                                                }
                                                            >
                                                                Specific
                                                                (Legacy)
                                                            </SelectItem>
                                                            <SelectItem value="range">
                                                                Range
                                                            </SelectItem>
                                                        </SelectContent>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />

                            {form.watch("pickupTimeType") === "specific" && (
                                <FormField
                                    control={form.control}
                                    name="defaultPickupTime"
                                    render={({ field }) => (
                                        <FormItem>
                                            <div className="pt-4">
                                                <CardTitle className="text-base font-medium">
                                                    Specific time
                                                </CardTitle>

                                                <FormControl>
                                                    <div className="pt-2">
                                                        <NumberInput
                                                            placeholder="10"
                                                            min={0}
                                                            className="w-[280px] flex-1 [&>input]:pl-2"
                                                            icon={() => (
                                                                <div className="absolute right-0 mr-20 flex shrink-0 items-center text-micro">
                                                                    minutes
                                                                </div>
                                                            )}
                                                            value={field.value}
                                                            onChange={(e) =>
                                                                updateNumber(
                                                                    e,
                                                                    field
                                                                )
                                                            }
                                                            disabled={!canEdit}
                                                        />
                                                    </div>
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </FormItem>
                                    )}
                                />
                            )}

                            {form.watch("pickupTimeType") === "range" && (
                                <>
                                    <FormField
                                        control={form.control}
                                        name="defaultPickupMinTime"
                                        render={({ field }) => (
                                            <FormItem>
                                                <div className="pt-4">
                                                    <CardTitle className="text-base font-medium">
                                                        Lower bound
                                                    </CardTitle>

                                                    <FormControl>
                                                        <div className="pt-2">
                                                            <NumberInput
                                                                placeholder="10"
                                                                min={0}
                                                                className="w-[280px] flex-1 [&>input]:pl-2"
                                                                icon={() => (
                                                                    <div className="absolute right-0 mr-20 flex shrink-0 items-center text-micro">
                                                                        minutes
                                                                    </div>
                                                                )}
                                                                value={
                                                                    field.value
                                                                }
                                                                onChange={(e) =>
                                                                    updateNumber(
                                                                        e,
                                                                        field
                                                                    )
                                                                }
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            />
                                                        </div>
                                                    </FormControl>
                                                    <FormMessage />
                                                </div>
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={form.control}
                                        name="defaultPickupMaxTime"
                                        render={({ field }) => (
                                            <FormItem>
                                                <div className="pt-4">
                                                    <CardTitle className="text-base font-medium">
                                                        Upper bound
                                                    </CardTitle>

                                                    <FormControl>
                                                        <div className="pt-2">
                                                            <NumberInput
                                                                placeholder="10"
                                                                min={0}
                                                                className="w-[280px] flex-1 [&>input]:pl-2"
                                                                icon={() => (
                                                                    <div className="absolute right-0 mr-20 flex shrink-0 items-center text-micro">
                                                                        minutes
                                                                    </div>
                                                                )}
                                                                value={
                                                                    field.value
                                                                }
                                                                onChange={(e) =>
                                                                    updateNumber(
                                                                        e,
                                                                        field
                                                                    )
                                                                }
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            />
                                                        </div>
                                                    </FormControl>
                                                    <FormMessage />
                                                </div>
                                            </FormItem>
                                        )}
                                    />
                                </>
                            )}
                        </CardContent>
                    </Card>

                    {form.formState.isDirty ? (
                        <div className="fixed bottom-0 left-0 flex w-full flex-row items-center justify-end gap-4 border-t bg-white p-2">
                            <Button
                                type="button"
                                variant={"outline"}
                                onClick={resetForm}
                            >
                                Cancel
                            </Button>
                            <Button disabled={isLoading} type="submit">
                                {isLoading && (
                                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                Submit
                            </Button>
                        </div>
                    ) : null}
                </form>
            </Form>
        </div>
    );
}
