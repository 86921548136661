import { Modal } from "antd";
import React, { SetStateAction } from "react";
import "antd/dist/antd.css";
import { css } from "@emotion/react";
import { IProduct } from "@snackpass/snackpass-types";
import { Link } from "react-router-dom";

import { Routes } from "#navigation/routes";

type Promotions = {
    productPromos: string[];
};

const ProductPromos: React.FC<Promotions> = ({ productPromos }) => (
    <>
        {productPromos.map((p, i) => (
            <div>{`${i + 1}. ${p}`}</div>
        ))}
    </>
);

type DeleteProductModalProps = {
    isDeleteProductModalOpen: boolean;
    setIsDeleteProductModalOpen: React.Dispatch<SetStateAction<boolean>>;
    handleDeleteItem: () => void;
    productIdToPromotionsMap: Record<string, string[]>;
    productInEdit: IProduct | null;
};

const DeleteProductModal: React.FC<DeleteProductModalProps> = ({
    handleDeleteItem,
    isDeleteProductModalOpen,
    setIsDeleteProductModalOpen,
    productIdToPromotionsMap,
    productInEdit
}) => {
    const productPromos = productInEdit
        ? productIdToPromotionsMap[productInEdit?._id]
        : null;

    const pluraOrSingular =
        productPromos && productPromos.length > 1 ? "rewards" : "reward";

    return (
        <Modal
            centered
            css={ModalCSS}
            title="Remove product? "
            visible={isDeleteProductModalOpen}
            onOk={handleDeleteItem}
            okText={
                productPromos ? `I've adjusted the ${pluraOrSingular}` : "OK"
            }
            onCancel={() => {
                setIsDeleteProductModalOpen(false);
            }}
        >
            {productPromos ? (
                <p>
                    Note: this item is currently used as a {pluraOrSingular}!
                    Please update the {pluraOrSingular}:
                    <ProductPromos productPromos={productPromos} />
                    by removing
                    {` ${productInEdit?.name}`} from the {pluraOrSingular} in
                    the
                    <Link to={Routes.Promotion} className="px-1 no-underline">
                        Promos tab
                    </Link>
                    before removing this item in the menu editor.
                </p>
            ) : (
                "Are you sure you want to delete this product?"
            )}
        </Modal>
    );
};

const ModalCSS = css`
    font-family: "Inter";
    font-size: 16px;
    .ant-btn {
        border-radius: 8px;
    }

    .ant-form label {
        font-size: 16px;
        margin-top: 5px;
        margin-right: 1rem;
    }
`;

export default DeleteProductModal;
