import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Alert } from "antd";

import Text from "#devices/components/Text";
import { Button } from "src/@/components/ui/button";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { DevicesModal } from "#devices/components/Modal";
import { CenteredSpin } from "#devices/components/Loading";
import { DeviceType } from "#devices/utils/deviceTypes";

type SaveAndRebootDeviceModalProps = {
    handleClose: () => void;
    handleSave: () => void;
    isOpen: boolean;
};

const SaveAndRebootDeviceModal = ({
    handleClose,
    handleSave,
    isOpen
}: SaveAndRebootDeviceModalProps): JSX.Element => {
    const { device, rebootingDevice, savingDevice } =
        useContext(DevicesPageContext);

    const returnToDetails = () => {
        handleClose();
    };

    const disabled = savingDevice || rebootingDevice;

    const shouldShowRebootInfo = device?.deviceType !== DeviceType.SnackTV;

    const rebootable = ![
        DeviceType.Printer,
        DeviceType.CashDrawer,
        DeviceType.KDS,
        DeviceType.SnackTV,
        DeviceType.StripeTerminal
    ].includes(device?.deviceType ?? DeviceType.All);

    const rebootInfo = rebootable
        ? "This will interrupt any current orders or actions taking place on the device at this time."
        : "This may interrupt current orders being processed on the device.";

    return (
        <DevicesModal
            visible={isOpen}
            handleClose={returnToDetails}
            footer={
                <Container fluid>
                    <Row>
                        <Col xs={6}>
                            <Button
                                variant="outline"
                                className="w-full"
                                onClick={returnToDetails}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button
                                className="w-full"
                                onClick={handleSave}
                                disabled={disabled}
                            >
                                {disabled ? <CenteredSpin small /> : "Save"}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            }
        >
            <Text.Title2>Save Device Changes</Text.Title2>
            {rebootable && (
                <Alert
                    style={{ marginBottom: 20 }}
                    showIcon
                    message="Device reboot required to save changes."
                    type="warning"
                ></Alert>
            )}
            {shouldShowRebootInfo ? <Text.Body>{rebootInfo}</Text.Body> : null}
        </DevicesModal>
    );
};

export default SaveAndRebootDeviceModal;
