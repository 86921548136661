import React from "react";

const ChatImage = require("src/assets/icons/chat/black.png");

export enum TemplateVariableType {
    Product = "PRODUCT",
    Reward = "REWARD",
    Text = "TEXT"
}

type TemplateVariable = {
    id: string;
    type: TemplateVariableType;
    placeholder: string;
    label: string;
    markup: string;
    data: any;
    isInvisible: boolean;
};

export type Template = {
    id: string;
    title: string;
    message: string;
    variables: TemplateVariable[];
};

export type TemplateSection = {
    label: string;
    icon: JSX.Element;
    templates: Template[];
};

// parsing strings with {{ STUFF }} where can have 2 or 3 curly
// brackets on each side
export const HANDLEBARS_REGEX =
    /{{{?(#[a-zA-Z0-9_ ]+ )?[a-zA-Z0-9_ ]+.[a-zA-Z0-9_ ]*}?}}/g;

const buildTemplate = (
    id: string,
    title: string,
    message: string,
    mapping: Record<string, TemplateVariableType>
): Template => {
    const re = new RegExp(HANDLEBARS_REGEX);

    let matchArr;

    const variables: TemplateVariable[] = [];

    while ((matchArr = re.exec(message)) !== null) {
        const variableTemplate = matchArr[0];
        const sanitizedLabel = variableTemplate.replace(/[{}]/g, "");
        const type = mapping[sanitizedLabel];

        const v: TemplateVariable = {
            label: sanitizedLabel,
            id: sanitizedLabel.trim(),
            type,
            markup: variableTemplate,
            placeholder: variableTemplate,
            data: null,
            isInvisible: false
        };

        variables.push(v);
    }

    return {
        id,
        title,
        message,
        variables
    };
};

const ChatIcon = () => (
    <img src={ChatImage.default} style={{ width: 20, objectFit: "cover" }} />
);

export const TEMPLATES: TemplateSection[] = [
    {
        label: "New Customers",
        icon: <ChatIcon />,
        templates: [
            buildTemplate(
                "new_customers_1",
                "Let’s run it back ⚡",
                "Here’s {{Promo Gift}} on us for round two this week 🎁",
                {
                    "Promo Gift": TemplateVariableType.Reward
                }
            )
        ]
    },
    {
        label: "New Item",
        icon: <ChatIcon />,
        templates: [
            buildTemplate(
                "new_item_1",
                "POV: you're Cinderella 🪄",
                `{{New Item}} now LIVE in our menu 🆕 grab yours before it's gone ⏳`,
                {
                    "New Item": TemplateVariableType.Product
                }
            )
        ]
    },
    {
        label: "VIPs",
        icon: <ChatIcon />,
        templates: [
            buildTemplate(
                "vips_1",
                "Safe to say we like you ❣️",
                `You’re invited for a {{Promo Gift}}. No RSVP needed, just order ✨`,
                {
                    "Promo Gift": TemplateVariableType.Reward
                }
            )
        ]
    },
    {
        label: "We Miss You",
        icon: <ChatIcon />,
        templates: [
            buildTemplate(
                "miss_you_1",
                "You good?? Just checking in ⚡",
                `Here's {{Promo Deal}} in case you could use a pick me up 📈`,
                {
                    "Promo Deal": TemplateVariableType.Reward
                }
            )
        ]
    },
    {
        label: "Special Discount",
        icon: <ChatIcon />,
        templates: [
            buildTemplate(
                "special_discount_1",
                "You only get one shot, one opportunity 🎶",
                `We're giving away {{Promo Deal}} for a limited time 🤫`,
                {
                    "Promo Deal": TemplateVariableType.Reward
                }
            )
        ]
    }
];
