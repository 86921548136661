import React from "react";

import { getDateString } from "#date-picker/lib";

import { useGlobalDate } from "../../hooks/use-global-date";

export const DateAddressHeader = () => {
    const { startDate, endDate } = useGlobalDate();
    const dateString = getDateString({ startDate, endDate });

    return (
        <div>
            <span className="mb-2 flex flex-row items-center justify-between text-large font-semibold md:text-xlarge">
                {dateString}
            </span>
        </div>
    );
};
