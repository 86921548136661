import { OrderStatusConfiguration } from "@snackpass/snackpass-types";
import { match, P } from "ts-pattern";
import * as yup from "yup";

import { OrdersType } from "./types";

export const ordersInitialValues: OrdersType = {
    allowCustomNotes: true,
    autoAccept: true,
    autoComplete: false,
    hasEmployeeAuditTracking: false,
    initialReceiptNumber: 100,
    pickupTimeType: "specific"
};

export const ordersValidationSchema = yup.object({
    allowCustomNotes: yup.boolean(),
    autoAccept: yup.boolean(),
    autoComplete: yup.boolean(),
    defaultBagInCart: yup.number(),
    defaultPickupMinTime: yup.number().optional().min(0),
    defaultPickupMaxTime: yup
        .number()
        .optional()
        .min(yup.ref("defaultPickupMinTime")),
    defaultPickupTime: yup.number().optional().min(0),
    initialReceiptNumber: yup
        .number()
        .typeError("initialReceiptNumber")
        .test({
            name: "Start number must be greater than 0",
            message: "Start number must be greater than 0",
            test: (value: number | undefined | null) =>
                typeof value === "number" && value > 0
        })
        .required(),
    pickupTimeType: yup.string().oneOf(["specific", "range"])
});

export const isAutoAccept = (config?: OrderStatusConfiguration) =>
    match(config as number)
        .with(
            P.union(
                OrderStatusConfiguration.AutoAcceptAutoComplete,
                OrderStatusConfiguration.AutoAcceptManualComplete
            ),
            () => true
        )
        .otherwise(() => false);

export const isAutoComplete = (config?: OrderStatusConfiguration) =>
    match(config as number)
        .with(
            P.union(
                OrderStatusConfiguration.AutoAcceptAutoComplete,
                OrderStatusConfiguration.ManualAcceptAutoComplete
            ),
            () => true
        )
        .otherwise(() => false);

export const buildOrderStatusConfiguration = (
    autoAccept: boolean,
    autoComplete: boolean
) =>
    match({ autoAccept, autoComplete })
        // manual accept manual complete
        .with(
            { autoAccept: false, autoComplete: false },
            () => OrderStatusConfiguration.ManualAcceptManualComplete
        )
        // auto accept manual complete
        .with(
            { autoAccept: true, autoComplete: false },
            () => OrderStatusConfiguration.AutoAcceptManualComplete
        )
        // manual accept auto complete
        .with(
            { autoAccept: false, autoComplete: true },
            () => OrderStatusConfiguration.ManualAcceptAutoComplete
        )
        // auto accept auto complete
        .with(
            { autoAccept: true, autoComplete: true },
            () => OrderStatusConfiguration.AutoAcceptAutoComplete
        )
        .exhaustive();
