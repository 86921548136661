import { ColumnDef } from "@tanstack/react-table";

import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { formatDate, sortDates } from "#reports/gift-card-balances/lib";
import { formatCentsAsDollars } from "#reports/gift-card-purchases/lib";
import {
    GiftCardTransactionDataRow,
    GiftCardTransactionRow
} from "#reports/gift-card-transactions/types";

export enum GiftCardTransactionType {
    Purchase = "PURCHASE",
    RefundPurchase = "REFUND_PURCHASE",
    Usage = "USAGE",
    RefundUsage = "REFUND_USAGE",
    Payout = "PAYOUT",
    RefundPayout = "REFUND_PAYOUT",
    CashPayout = "CASH_PAYOUT",
    RefundCashPayout = "REFUND_CASH_PAYOUT"
}

export const getLabelForGiftCardEventType = (type: string) => {
    switch (type) {
        case GiftCardTransactionType.Purchase:
            return "Purchase";
        case GiftCardTransactionType.RefundPurchase:
            return "Refunded Purchase";
        case GiftCardTransactionType.Usage:
            return "Used";
        case GiftCardTransactionType.RefundUsage:
            return "Usage Refund";
        case GiftCardTransactionType.Payout:
            return "Payout";
        case GiftCardTransactionType.RefundPayout:
            return "Refunded Payout";
        case GiftCardTransactionType.CashPayout:
            return "Cash Payout";
        case GiftCardTransactionType.RefundCashPayout:
            return "Refunded Cash Payout";
        default:
            return "N/A";
    }
};

export const getMainActorStoreName = (
    row: GiftCardTransactionRow | GiftCardTransactionDataRow
): string => {
    switch (row.type) {
        case GiftCardTransactionType.Purchase:
        case GiftCardTransactionType.RefundPurchase:
            return row.purchaseStoreName;
        case GiftCardTransactionType.Usage:
        case GiftCardTransactionType.RefundUsage:
            return row.usageStoreName ?? "-";
        case GiftCardTransactionType.Payout:
        case GiftCardTransactionType.RefundPayout:
        case GiftCardTransactionType.CashPayout:
        case GiftCardTransactionType.RefundCashPayout:
            return row.payoutStoreName ?? "-";
        default:
            return "N/A";
    }
};

export const getGiftCardPurchaseMethod = (
    row: GiftCardTransactionRow | GiftCardTransactionDataRow
): string => {
    switch (row.giftCardPurchaseMethod) {
        case "CARD":
            return "Card";
        case "CASH":
            return "Cash";
        default:
            return "-";
    }
};

export const columns: ColumnDef<GiftCardTransactionRow>[] = [
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="mx-2 my-3"
                column={column}
                title="Store Name"
            />
        ),
        id: "Store Name",
        accessorKey: "storeName",
        cell: ({ getValue }) => (
            <div className="my-4 ml-2">
                <div className="font-bold">{getValue() as string}</div>
            </div>
        ),
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader className="" column={column} title="Date" />
        ),
        accessorFn: (e) => formatDate(e.date),
        id: "Date",
        sortingFn: (a, b) => sortDates(a.original.date, b.original.date),
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="mx-2 my-3 w-32"
                column={column}
                title="Type"
            />
        ),
        accessorFn: (e) => getLabelForGiftCardEventType(e.type),
        id: "Type",
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader className="" column={column} title="Code" />
        ),
        accessorFn: (e) => e.code,
        id: "Code",
        enableHiding: false,
        enableSorting: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="Transaction Amount"
            />
        ),
        accessorFn: (e) => formatCentsAsDollars(e.amount),
        id: "Transaction Amount",
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="Balance"
            />
        ),
        accessorFn: (e) => formatCentsAsDollars(e.amountRemaining),
        id: "Balance",
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="Purchase Customer"
            />
        ),
        accessorFn: (e) => e.purchaseCustomerEmail,
        id: "Purchase Customer",
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="Usage Customer"
            />
        ),
        accessorFn: (e) => e.usageCustomerPhone,
        id: "Usage Customer"
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                column={column}
                title="Gift Card Purchased Via"
            />
        ),
        accessorFn: (e) => e.giftCardPurchaseMethod,
        id: "Gift Card Purchased Via"
    }
];

export const columnsForExport = [
    {
        dataIndex: "storeName",
        title: "Store Name"
    },
    {
        dataIndex: "date",
        title: "Date"
    },
    {
        dataIndex: "type",
        title: "Type"
    },
    {
        dataIndex: "code",
        title: "Code"
    },
    {
        dataIndex: "purchaseStoreName",
        title: "Purchase Store"
    },
    {
        dataIndex: "usageStoreName",
        title: "Usage Store"
    },
    {
        dataIndex: "payoutStoreName",
        title: "Payout Store"
    },
    {
        dataIndex: "amount",
        title: "Amount"
    },
    {
        dataIndex: "amountRemaining",
        title: "Balance"
    },
    {
        dataIndex: "purchaseCustomerEmail",
        title: "Purchase Customer"
    },
    {
        dataIndex: "usageCustomerEmail",
        title: "Usage Customer"
    },
    {
        dataIndex: "giftCardPurchaseMethod",
        title: "Gift Card Purchased Via"
    }
];

export const formatRowsForExport = (
    rows: GiftCardTransactionRow[]
): { [key: string]: string }[] =>
    rows.map((e) => ({
        storeName: e.storeName,
        date: formatDate(e.date),
        type: getLabelForGiftCardEventType(e.type),
        code: e.code,
        purchaseStoreName: e.purchaseStoreName,
        usageStoreName: e.usageStoreName,
        payoutStoreName: e.payoutStoreName,
        amount: formatCentsAsDollars(e.amount),
        amountRemaining: formatCentsAsDollars(e.amountRemaining),
        purchaseCustomerEmail: e.purchaseCustomerEmail,
        usageCustomerEmail: e.usageCustomerEmail ? e.usageCustomerEmail : "",
        giftCardPurchaseMethod: e.giftCardPurchaseMethod
            ? e.giftCardPurchaseMethod
            : "-"
    }));
