import { HTMLAttributes } from "react";
import Skeleton from "react-loading-skeleton";

import { cn } from "src/@/lib/utils";

function CampaignHeaderSkeleton({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) {
    return (
        <div
            className={cn(
                "flex shrink-0 flex-row items-center justify-between gap-10 border-b p-4",
                className
            )}
            {...props}
        >
            <Skeleton className="h-8 w-36" />
            <Skeleton className="h-8 w-36" />
        </div>
    );
}

export default CampaignHeaderSkeleton;
