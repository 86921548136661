type StatusProps = {
    networkConnectionType: "none" | "cellular" | "ethernet" | "other" | "wifi";
    networkName: string;
};

type StatusResponse = { type: string; name: string };

const formattedConnectionType = {
    none: "None",
    cellular: "Cellular",
    ethernet: "Ethernet",
    other: "Other",
    wifi: "Wifi"
};

export const getDeviceConnection = (stats: StatusProps): StatusResponse => {
    const connectionType =
        formattedConnectionType[stats?.networkConnectionType];
    return {
        type: connectionType ?? "Unknown",
        name: stats?.networkName ?? "--"
    };
};
