import React, { useContext, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Moment } from "moment";
import { Color, Legend, BarChart, LineChartProps } from "@tremor/react";
import clsx from "clsx";

import ErrorChart from "#reports/sales-summary/shared-components/ErrorChart";
import {
    ChartType,
    ChartTypeSelect
} from "#reports/sales-summary/shared-components/ChartTypeSelect";
import { GranularityType } from "#reports/sales-summary/types";
import { aggregateMenuInsightsItemRows } from "#reports/menu-item-insights/lib";
import {
    aggregateDatedRows,
    datePointsFromRangeAndGranularity,
    formatNumber,
    getGraphGranularity,
    toDollarFormatted
} from "#reports/sales-summary/lib";
import { ReportsContext } from "#app/reports-context-provider";
import { calculateChartWidth } from "#utils/helpers";

import { MenuInsightsItemRow, MenuItem } from "../types";

const colors: Color[] = ["blue", "yellow", "purple", "orange", "teal"];
const tooltipClassNames: string[] = [
    "bg-blue-500",
    "bg-yellow-500",
    "bg-purple-500",
    "bg-orange-500",
    "bg-teal-500"
];

export interface Props {
    data?: MenuInsightsItemRow[];
    loading?: boolean;
    title: string;
}

const TopItemsChart = ({ data, loading, title }: Props) => {
    const { reportsState } = useContext(ReportsContext);
    const { dateRanges, granularity } = reportsState;
    const [graphType, setGraphType] = useState(ChartType.NET_SALES);

    // Attempts to adhere to the granularity that the user selects, unless it results in too few data points.
    // This is used as the granularity for the chart as a whole.
    const highestSuitableGranularity = useMemo(
        () => getGraphGranularity(dateRanges[0], granularity),
        [dateRanges, granularity]
    );

    const formatDate = (date: Moment) =>
        highestSuitableGranularity == GranularityType.MONTHLY
            ? date.format("MMM YYYY")
            : date.format("MMM D");

    const dateLabels = useMemo(
        () =>
            datePointsFromRangeAndGranularity(
                dateRanges[0],
                highestSuitableGranularity
            ).map((e) => formatDate(e)),
        [dateRanges, highestSuitableGranularity]
    );

    const aggregatedRows = useMemo(() => {
        if (!data) return null;
        return aggregateDatedRows(
            data,
            aggregateMenuInsightsItemRows,
            highestSuitableGranularity
        );
    }, [data, highestSuitableGranularity]);

    const valueFormatter = useMemo(
        () =>
            graphType == ChartType.NET_SALES ? toDollarFormatted : formatNumber,
        [graphType]
    );

    const topItems = useMemo(() => {
        if (!aggregatedRows) return [];
        return aggregatedRows
            ?.reduce((prev: MenuItem[], currentRow) => {
                currentRow.items.forEach((item) => {
                    const itemInArray = prev.find((e) => e.id == item.id);
                    if (!itemInArray) {
                        prev.push({ ...item });
                    } else {
                        // aggregate stats if already in array
                        itemInArray.sales += item.sales;
                        itemInArray.orders += item.orders;
                    }
                });
                return prev;
            }, [])
            .sort((a, b) =>
                graphType == ChartType.NET_SALES
                    ? b.sales - a.sales
                    : b.orders - a.orders
            )
            .slice(0, 5);
    }, [aggregatedRows, graphType]);

    const chartData = useMemo(() => {
        if (!aggregatedRows) return [];
        const topItemsSet = new Set(topItems.map((e) => e.id));
        // top 5 items only
        return aggregatedRows.map((row, idx) => ({
            label: dateLabels[idx],
            ...row.items
                .filter((item) => topItemsSet.has(item.id))
                .reduce(
                    (prev, curr) => ({
                        ...prev,
                        // identify by id instead of name in the case of duplicates.
                        [curr.id]:
                            graphType == ChartType.NET_SALES
                                ? curr.sales
                                : curr.orders
                    }),
                    // passing item names and value formatter into the chart data to use in the tooltip.
                    { itemNames: topItems.map((e) => e.name), valueFormatter }
                )
        }));
    }, [topItems, graphType]);

    const yWidth = (): number => {
        let maxValue = -Infinity;

        chartData.forEach((obj) => {
            Object.values(obj).forEach((value) => {
                if (typeof value === "number") {
                    maxValue = Math.max(value, maxValue);
                }
            });
        });

        return calculateChartWidth(maxValue, true);
    };

    return (
        <div className="pb-12">
            <div className="mb-3 flex items-center justify-between">
                <h4 className="mb-2 text-large">{title}</h4>
                <ChartTypeSelect value={graphType} onChange={setGraphType} />
            </div>
            <Legend
                className="mb-6 p-0"
                colors={colors}
                categories={topItems?.map((e) => e.name) ?? []}
            />
            <div className="w-full">
                {!loading ? (
                    data ? (
                        <BarChart
                            data={chartData}
                            valueFormatter={valueFormatter}
                            categories={topItems?.map((e) => e.id) ?? []}
                            yAxisWidth={yWidth()}
                            index={"label"}
                            colors={colors}
                            showLegend={false}
                            customTooltip={CustomTooltip}
                        />
                    ) : (
                        <ErrorChart className="h-96 rounded-md" />
                    )
                ) : (
                    <Skeleton className="h-96" />
                )}
            </div>
        </div>
    );
};

const CustomTooltip: LineChartProps["customTooltip"] = ({
    payload,
    active,
    label
}) => {
    if (!active || !payload) return null;
    return (
        <div className="flex w-56 flex-col space-y-2 rounded-md border border-neutral-200 bg-neutral-50 p-2 text-small shadow-tremor-dropdown">
            <p className="font-semibold">{label}</p>
            {payload.map((category, idx) => (
                <div key={idx} className="flex flex-1 space-x-2.5">
                    <div
                        className={clsx(
                            "flex w-1 flex-col rounded",
                            tooltipClassNames[idx]
                        )}
                    />
                    <div className="flex w-full justify-between">
                        <div className="text-neutral-600">
                            {category.payload["itemNames"][idx]}
                        </div>
                        <div className="ml-2 font-medium text-neutral-800">
                            {category.payload["valueFormatter"](category.value)}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TopItemsChart;
