import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
import { ClockIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import Skeleton from "react-loading-skeleton";
import { match } from "ts-pattern";
import _ from "lodash";

import { ReactComponent as ErrorIcon } from "src/assets/icons/rules-alert.svg";
import {
    ComparisonType,
    FreshnessType,
    ReportType
} from "#reports/sales-summary/types";
import {
    channelOptionsMap,
    formatRange,
    fulfillmentOptions,
    otherChannelOption,
    snackpassChannelOption,
    sourceOptions,
    isMultiLocationReport,
    getComparisonRange
} from "#reports/sales-summary/lib";
import { ReportsContext } from "#app/reports-context-provider";
import { getActiveStore } from "src/redux/selectors";
import api from "src/api/rest";
import { useTrackEvent } from "#reports/mixpanel-tracking/hooks";
import { SegmentEvents } from "#utils/segment";
import { DateFilterPicker } from "#reports/sales-summary/shared-components/FilterHeader/DateFilterPicker";
import { Badge } from "src/@/components/ui/badge";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { cn } from "src/@/lib/utils";

import { FulfillmentFilterPicker } from "./FulfillmentFilterPicker";
import { ChannelFilterPicker } from "./ChannelFilterPicker";
import { PlatformFilterPicker } from "./PlatformFilterPicker";
import { GranularityFilterPicker } from "./GranularityFilterPicker";
import { LocationFilterPicker } from "./LocationFilterPicker";
import { ComparisonFilterPicker } from "./ComparisonFilterPicker";
import { logAndSendError } from "src/utils/errors";

const DATA_FRESHNESS_COPY = `Data appearing after this time is not represented in our report.
    A variety of factors may impact the freshness of data, but Snackpass is working hard to ensure that data is available as fast as possible.`;

enum DateChangeType {
    SUGGESTED = "SUGGESTED",
    CUSTOM = "CUSTOM"
}

export enum FilterType {
    GRANULARITY,
    PLATFORM,
    CHANNEL,
    FULFILLMENT
}

type FilterHeaderProps = {
    reportType: ReportType;
    needsComparison?: boolean;
    showLocationFilter?: boolean;
    hideFilters?: FilterType[];
    showAllTimeRange?: boolean;
};

const FilterHeader = ({
    reportType,
    needsComparison = false,
    showLocationFilter = false,
    hideFilters = [],
    showAllTimeRange = false
}: FilterHeaderProps) => {
    const activeStore = useSelector(getActiveStore);
    const [errorText, setErrorText] = useState("");

    const [loadingFreshness, setLoadingFreshness] = useState<boolean>(false);
    const [freshnessError, setFreshnessError] = useState<boolean>(false);
    const [freshness, setFreshness] = useState<string>("");

    const { reportsState, setReportsState } = useContext(ReportsContext);
    const { comparison, dateRanges, filter, channels, stores } = reportsState;

    const trackEvent = useTrackEvent();

    const hiddenFilters = hideFilters as FilterType[];
    const hideGranularityFilter =
        hiddenFilters.length && hiddenFilters.includes(FilterType.GRANULARITY);
    const hidePlatformFilter =
        hiddenFilters.length && hiddenFilters.includes(FilterType.PLATFORM);
    const hideChannelFilter =
        hiddenFilters.length && hiddenFilters.includes(FilterType.CHANNEL);
    const hideFullfilmentFilter =
        hiddenFilters.length && hiddenFilters.includes(FilterType.FULFILLMENT);
    const showFilterBadge =
        (!hidePlatformFilter && !_.isEmpty(filter.source)) ||
        (!hideChannelFilter && !_.isEmpty(filter.channel)) ||
        (!hideFullfilmentFilter && !_.isEmpty(filter.fulfillment));

    const freshnessText = useMemo(() => {
        if (loadingFreshness) {
            return <Skeleton className="ml-1 inline-block w-16" />;
        }

        if (!freshness) {
            return "Never";
        }

        return moment(freshness).format("h:mm A");
    }, [freshness, loadingFreshness]);

    const fetchFreshness = useCallback(() => {
        const freshnessType = match({ reportType })
            .with(
                { reportType: ReportType.SALES_SUMMARY },
                { reportType: ReportType.SALES_CHANNELS },
                { reportType: ReportType.LOCATION_SALES_REPORT },
                () => FreshnessType.SALES
            )
            .with(
                { reportType: ReportType.CUSTOMER_DIRECTORY },
                () => FreshnessType.CUSTOMER
            )
            .with(
                { reportType: ReportType.PROMOTIONS_REPORT },
                () => FreshnessType.PROMOTION
            )
            .with(
                { reportType: ReportType.GIFT_CARD_BALANCE_REPORT },
                { reportType: ReportType.GIFT_CARD_PURCHASE_REPORT },
                { reportType: ReportType.GIFT_CARD_TRANSACTION_REPORT },
                () => {
                    setFreshness(new Date().toISOString());
                    setLoadingFreshness(false);
                    return undefined;
                }
            )
            .with(
                { reportType: ReportType.MENU_CATEGORY_INSIGHTS },
                { reportType: ReportType.MENU_ITEM_INSIGHTS },
                { reportType: ReportType.LOCATION_MENU_CATEGORY_REPORT },
                { reportType: ReportType.LOCATION_MENU_ITEM_REPORT },
                () => FreshnessType.MENU
            )
            .exhaustive();

        if (!activeStore?._id || !dateRanges || !freshnessType) {
            return;
        }

        setLoadingFreshness(true);
        setFreshnessError(false);
        const params = {
            storeId: activeStore?._id,
            type: freshnessType
        };

        api.reports
            .getReportsFreshness(params)
            .then((res) => {
                setFreshness(res.data?.lastUpdated);
                setLoadingFreshness(false);
            })
            .catch((e) => {
                setFreshnessError(true);
                logAndSendError(e);
            });
    }, [activeStore, dateRanges, reportType]);

    useEffect(() => {
        setLocations(reportType);
    }, [stores, reportType]);

    useEffect(fetchFreshness, [reportType]);

    const setLocations = (reportType: ReportType) => {
        if (isMultiLocationReport(reportType)) {
            setReportsState((prevState) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    storeIds: stores.map((e) => e._id) as string[]
                }
            }));
        } else {
            setReportsState((prevState) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    storeIds: []
                }
            }));
        }
    };

    const validateDateRanges = (newBase: Moment[], newComp: Moment[]) => {
        if (newBase[1].isAfter(moment().endOf("day"))) {
            setErrorText(
                "Please select a range that does not include future dates."
            );
            return false;
        }

        const earliestValidDate = moment().subtract(2, "years").startOf("year");

        if (newBase[0].isBefore(earliestValidDate) && !showAllTimeRange) {
            setErrorText(
                `We only allow you to view data from the past 2 years. Please select a date range after ${earliestValidDate.format(
                    "MMM D, YYYY"
                )}`
            );
            return false;
        }

        if (newComp[0].isBefore(earliestValidDate) && !showAllTimeRange) {
            setErrorText(`We only allow you to compare data from the past 2 years. Please select a comparison range after ${earliestValidDate.format(
                "MMM D, YYYY"
            )},
                or select "No Comparison".`);
            return false;
        }

        setErrorText("");
        return true;
    };

    const channelOptions = useMemo(() => {
        const channelOptions = [snackpassChannelOption];

        const mappedChannels = (
            channels
                ? channels.map((e) =>
                      e !== snackpassChannelOption.value &&
                      e !== otherChannelOption.value
                          ? channelOptionsMap[e]
                          : null
                  )
                : Object.values(channelOptionsMap)
        ).filter((e) => e) as { label: string; value: string }[];

        channelOptions.push(...mappedChannels);

        if (channels.includes(otherChannelOption.value)) {
            channelOptions.push(otherChannelOption);
        }

        return channelOptions;
    }, [channels, channelOptionsMap]);

    const handleDateChange = (value: Moment[], type: DateChangeType) => {
        if (value && value[0] && value[1]) {
            if (
                value[0].isSame(dateRanges[0][0]) &&
                value[1].isSame(dateRanges[0][1])
            )
                return;

            const newBaseRange = [value[0], value[1]];

            const comparisonRange = getComparisonRange(
                newBaseRange,
                comparison
            );

            if (validateDateRanges(newBaseRange, comparisonRange)) {
                if (type === DateChangeType.SUGGESTED) {
                    trackEvent(
                        SegmentEvents.Reports[reportType]
                            .USER_FILTERED_SUGGESTED_TIME
                    );
                } else if (type === DateChangeType.CUSTOM) {
                    trackEvent(
                        SegmentEvents.Reports[reportType]
                            .USER_FILTERED_CUSTOM_TIME
                    );
                }

                setReportsState((prevState) => ({
                    ...prevState,
                    dateRanges: [newBaseRange, comparisonRange],
                    allTime: false
                }));
            }
        }
    };

    const handleComparisonChange = (value: ComparisonType) => {
        trackEvent(SegmentEvents.Reports[reportType].USER_CHANGED_COMPARISON);

        setReportsState((prevState) => {
            const newComp = getComparisonRange(prevState.dateRanges[0], value);
            if (!validateDateRanges(prevState.dateRanges[0], newComp))
                return prevState;

            return {
                ...prevState,
                comparison: value,
                dateRanges: [[...prevState.dateRanges[0]], newComp]
            };
        });
    };

    const handleTagClose = (key: string, parentKey: string) => {
        setReportsState((prevState) => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                // Ignoring so we can access by string and be more concise.
                // @ts-ignore
                [parentKey]: prevState.filter[parentKey].filter((e) => e != key)
            }
        }));
    };

    return (
        <div className="relative p-6 md:px-24">
            <Alert className="mb-6">
                <ClockIcon className="h-4 w-4" />
                <AlertTitle className="text-small">
                    Data was last updated at {freshnessText}
                </AlertTitle>
                <AlertDescription>
                    Data is updated approximately every 30 minutes.
                </AlertDescription>
            </Alert>
            <h4 className="text-xlarge">
                {reportsState.allTime && showAllTimeRange
                    ? "All Time"
                    : formatRange(dateRanges[0])}
            </h4>
            <div className="mt-4 flex flex-wrap items-center justify-between gap-3">
                <div className="-ml-1 flex flex-nowrap items-center gap-1 overflow-x-auto whitespace-nowrap">
                    <DateFilterPicker
                        handleDateChange={handleDateChange}
                        showAllTimeRange={showAllTimeRange}
                    />
                    {needsComparison ? (
                        <>
                            <span className="text-micro">vs</span>
                            <ComparisonFilterPicker
                                handleComparisonChange={handleComparisonChange}
                            />
                        </>
                    ) : null}

                    {!hideGranularityFilter ? (
                        <GranularityFilterPicker />
                    ) : null}

                    {!hidePlatformFilter ? <PlatformFilterPicker /> : null}
                    {channelOptions.length > 1 && !hideChannelFilter ? (
                        <ChannelFilterPicker />
                    ) : null}
                    {!hideFullfilmentFilter ? (
                        <FulfillmentFilterPicker />
                    ) : null}
                    {showLocationFilter && stores.length > 1 ? (
                        <LocationFilterPicker />
                    ) : null}
                </div>
            </div>
            {errorText ? (
                <div className="mt-2 flex items-center justify-center text-body text-neutral-600 md:justify-start">
                    <ErrorIcon className="m-0 mr-1 h-3 w-3 [&>path]:fill-neutral-500" />{" "}
                    {errorText}
                </div>
            ) : null}
            <div
                className={cn(
                    "flex flex-nowrap items-center gap-1 overflow-x-auto md:flex-wrap",
                    {
                        "py-3": showFilterBadge
                    }
                )}
            >
                {!hidePlatformFilter &&
                    filter.source.map(
                        (val: string) =>
                            val && (
                                <Badge
                                    variant={"secondary"}
                                    className="gap-1 text-micro font-normal"
                                    key={val}
                                >
                                    {sourceOptions.find((e) => e.value == val)
                                        ?.label || ""}
                                    <CrossCircledIcon
                                        className="h-3 w-3 cursor-pointer"
                                        onClick={() =>
                                            handleTagClose(val, "source")
                                        }
                                    />
                                </Badge>
                            )
                    )}
                {!hideChannelFilter &&
                    filter.channel.map(
                        (val: string) =>
                            val && (
                                <Badge
                                    variant={"secondary"}
                                    className="gap-1 text-micro font-normal"
                                    key={val}
                                >
                                    {channelOptions.find((e) => e.value == val)
                                        ?.label || ""}
                                    <CrossCircledIcon
                                        className="h-3 w-3 cursor-pointer"
                                        onClick={() =>
                                            handleTagClose(val, "channel")
                                        }
                                    />
                                </Badge>
                            )
                    )}
                {!hideFullfilmentFilter &&
                    filter.fulfillment.map(
                        (val: string) =>
                            val && (
                                <Badge
                                    variant={"secondary"}
                                    className="gap-1 text-micro font-normal"
                                    key={val}
                                >
                                    {fulfillmentOptions.find(
                                        (e) => e.value == val
                                    )?.label || ""}
                                    <CrossCircledIcon
                                        className="h-3 w-3 cursor-pointer"
                                        onClick={() =>
                                            handleTagClose(val, "fulfillment")
                                        }
                                    />
                                </Badge>
                            )
                    )}
            </div>
        </div>
    );
};

export default FilterHeader;
