import React from "react";

import { TooltipProvider } from "src/@/components/ui/tooltip";
import {
    HybridTooltip,
    HybridTooltipTrigger
} from "src/@/components/ui/HybridTooltip";
import { RequestIntegration } from "#settings-integrations/components/request-integration";

type IntegrationHeaderProps = {
    storeId: string;
    showCreatingIntegrationView?: boolean;
    setShowCreatingIntegrationView?: React.Dispatch<
        React.SetStateAction<boolean>
    >;
};

const IntegrationHeader = ({
    storeId,
    showCreatingIntegrationView,
    setShowCreatingIntegrationView
}: IntegrationHeaderProps): JSX.Element => (
    <div className="flex justify-between">
        <div className="text-large font-semibold">Live Integrations</div>
        <div className="mb-5 flex gap-4">
            <TooltipProvider delayDuration={100}>
                <HybridTooltip>
                    <HybridTooltipTrigger asChild>
                        <RequestIntegration
                            storeId={storeId}
                            showCreatingIntegrationView={
                                showCreatingIntegrationView
                            }
                            setShowCreatingIntegrationView={
                                setShowCreatingIntegrationView
                            }
                            checked={true}
                        />
                    </HybridTooltipTrigger>
                </HybridTooltip>
            </TooltipProvider>
        </div>
    </div>
);

export { IntegrationHeader };
