import React, { SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddonGroup, IProduct } from "@snackpass/snackpass-types";
import { PartialDeep } from "type-fest";

import DropDownSelect from "#reusable/select/dropdown";
import {
    getActiveProductCategory,
    getLegacyProducts
} from "src/redux/selectors";
import { sanitizeCopiedAddonGroup } from "#menu-editor/mobile-friendly/helpers/menu-helpers";

const useProps = () => ({
    products: useSelector(getLegacyProducts),
    activeProductCategory: useSelector(getActiveProductCategory),
    dispatch: useDispatch()
});

type CopyModifierGroupsProps = {
    showNewModifierForm: boolean;
    setShowNewModifierForm: React.Dispatch<SetStateAction<boolean>>;
    setModifierGroups: React.Dispatch<
        SetStateAction<PartialDeep<AddonGroup>[]>
    >;
    modifierGroups: PartialDeep<AddonGroup>[];
    modifierGroupsOptions: {
        label: string;
        value: string;
        addonGroup: PartialDeep<AddonGroup>;
    }[];
    setIsModifierGroupInEdit: React.Dispatch<SetStateAction<boolean>>;
};

enum ModifierGroupType {
    newModGroup = "+ New Modifier Groups"
}

const CopyModifierGroups: React.FC<CopyModifierGroupsProps> = ({
    modifierGroups,
    setModifierGroups,
    setShowNewModifierForm,
    setIsModifierGroupInEdit
}) => {
    const { products, activeProductCategory } = useProps();
    const [sortedProducts, setSorted] = useState<IProduct[]>();

    const handleCreateAddonGroup = (item: {
        label: string;
        addonGroup: {
            name: string;
            required: boolean;
            limit: number;
            supportsMultiple: boolean;
            addons: [];
            _id: string;
        };
    }) => {
        const isAddingSameModifierGroup = modifierGroups.some((mg) => {
            if (
                mg._id === item.addonGroup._id ||
                mg.name === item.addonGroup.name
            ) {
                return true;
            }
            return false;
        });
        if (!item) return;
        if (isAddingSameModifierGroup) return;
        if (item.label === ModifierGroupType.newModGroup) {
            setIsModifierGroupInEdit(false);
            setShowNewModifierForm(true);
            return;
        }

        setModifierGroups([
            ...modifierGroups,
            sanitizeCopiedAddonGroup(item.addonGroup)
        ]);
    };

    // NB: We can use `product.toSorted` once we adopt ES2023
    const getSortedProducts = () =>
        [...products].sort((product1, product2) =>
            compareProducts(product1, product2, activeProductCategory)
        );
    const getGroupsList = () => {
        const ret: {
            label: string;
            value: string;
            addonGroup: AddonGroup;
        }[] = [];
        ret.push({
            label: ModifierGroupType.newModGroup,
            value: "",
            addonGroup: {
                name: "",
                required: false,
                limit: 0,
                supportsMultiple: false,
                addons: [],
                _id: ""
            }
        });
        sortedProducts?.forEach((productNew: IProduct) => {
            productNew.addonGroups.forEach((addonGroup: AddonGroup) => {
                ret.push({
                    label: `${productNew.name} - ${addonGroup.name}`,
                    value: addonGroup._id,
                    addonGroup: {
                        name: addonGroup.name,
                        required: addonGroup.required,
                        limit: addonGroup.limit,
                        supportsMultiple: addonGroup.supportsMultiple,
                        _id: addonGroup?._id,
                        isArchived: addonGroup.isArchived,
                        integrationIds: addonGroup.integrationIds,
                        addons: addonGroup.addons.map((addon) => {
                            const ret = { ...addon };
                            return ret;
                        })
                    }
                });
            });
        });
        return ret;
    };
    //TODO: from Alita: consider combining this logic (including abstractions) in its own useEffect. You can use useRef to set sorted products immediately and not needing to wait for the dom update. Also, you can just define sortedProducts as a temp variable (potentially)
    useEffect(() => {
        setSorted(getSortedProducts());
    }, [products]);
    useEffect(() => {
        getGroupsList();
    }, [sortedProducts]);

    const ret = getGroupsList();
    return (
        <DropDownSelect
            square
            placeholder="Add Modifier Group"
            options={ret}
            value={null}
            onChange={handleCreateAddonGroup}
            height="40px"
        />
    );
};

export default CopyModifierGroups;

// TODO: Optimize

// A compare function.
// If product1 should be sorted before product2, return -1.
// If product1 should be sorted after product2, return 1.
// If there should be no change in sort order, return 0.
function compareProducts(
    product1: IProduct,
    product2: IProduct,
    currentProductCategory: string
): number {
    const firstProductCategory = product1.category;
    const secondProductCategory = product2.category;
    // If neither of the products is in the current product category, then there is no change in sort order.
    if (
        firstProductCategory !== currentProductCategory &&
        secondProductCategory !== currentProductCategory
    ) {
        return 0;
    }
    // If both products are in the current product category, then there is no change in sort order.
    else if (
        firstProductCategory === currentProductCategory &&
        secondProductCategory === currentProductCategory
    ) {
        return 0;
    }
    // If product1 is in the active product category, then sort product1 before product2.
    else if (firstProductCategory === currentProductCategory) {
        return -1;
    }
    // At this point in the code, the only remaining case is that
    // product2 is in the active product category and product1 is not.
    // Sort product1 after product2.
    else {
        return 1;
    }
}
