import { useState } from "react";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "src/@/components/ui/dropdown-menu";
import PointBalanceInput from "#reports/customer-directory-insights/components/PointBalanceInput";
import StoreCreditInput from "#reports/customer-directory-insights/components/StoreCreditInput";

const EditLoyaltyInput = ({
    punchcardId,
    points,
    credit,
    refetch
}: {
    punchcardId: string;
    points: number;
    credit: number;
    refetch: () => void;
}) => {
    const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
    const [isPointBalanceModalOpen, setIsPointBalanceModalOpen] =
        useState(false);
    const [isStoreCreditModalOpen, setIsStoreCreditModalOpen] = useState(false);

    return (
        <div>
            <DropdownMenu
                open={isDropdownMenuOpen}
                onOpenChange={setIsDropdownMenuOpen}
                modal={false}
            >
                <DropdownMenuTrigger asChild>
                    <DotsHorizontalIcon />
                </DropdownMenuTrigger>

                <DropdownMenuContent>
                    <DropdownMenuItem
                        onClick={() => {
                            setIsPointBalanceModalOpen(true);
                            setIsDropdownMenuOpen(false);
                        }}
                    >
                        Adjust Point Balance
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        onClick={() => {
                            setIsStoreCreditModalOpen(true);
                            setIsDropdownMenuOpen(false);
                        }}
                    >
                        Adjust Store Credit
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
            <PointBalanceInput
                punchcardId={punchcardId}
                points={points}
                modalOpen={isPointBalanceModalOpen}
                setModalOpen={setIsPointBalanceModalOpen}
                refetch={refetch}
            />
            <StoreCreditInput
                punchcardId={punchcardId}
                credit={credit}
                modalOpen={isStoreCreditModalOpen}
                setModalOpen={setIsStoreCreditModalOpen}
                refetch={refetch}
            />
        </div>
    );
};
export default EditLoyaltyInput;
