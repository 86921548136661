/** @jsxImportSource @emotion/react */
import React, { SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";

import "antd/dist/antd.css";
import { ProductIngredientT } from "@snackpass/snackpass-types/src/v1";
import { isObject } from "lodash";
import { useSelector } from "react-redux";
import { Col, Form, InputNumber, Tooltip } from "antd";

import DropDownSelect from "#reusable/select/dropdown";
import { DropdownOption } from "#core";
import { getCombinedIngredientsWithInventory } from "src/redux/selectors";
import { ReactComponent as Delete } from "src/assets/icons/delete-grey.svg";
import colors from "#reusable/colors/colors.json";
import { RootState } from "src/redux/store";
import { InventoryItem } from "src/redux/slices";

type InventoryItemRowProp = {
    inventoryItem: InventoryItem;
    ingredient: ProductIngredientT;
    productInventoryItems: ProductIngredientT[] | undefined;
    setProductInventoryItems: React.Dispatch<
        SetStateAction<ProductIngredientT[] | undefined>
    >;
    handleUpdateIngredients: (ingredientId: string, newPerUnit: number) => void;
    handleRemoveIngredients: (ingredientId: string) => void;
};

type IngredientsProps = {
    formattedOptions: DropdownOption<InventoryItem>[];
    productInventoryItems: ProductIngredientT[] | undefined;
    setProductInventoryItems: React.Dispatch<
        SetStateAction<ProductIngredientT[] | undefined>
    >;
    handleAddIngredients: (value: DropdownOption<InventoryItem>) => void;
    handleUpdateIngredients: (ingredientId: string, newPerUnit: number) => void;
    handleRemoveIngredients: (ingredientId: string) => void;
};

const Ingredients: React.FC<IngredientsProps> = ({
    formattedOptions,
    productInventoryItems,
    setProductInventoryItems,
    handleAddIngredients,
    handleUpdateIngredients,
    handleRemoveIngredients
}) => {
    const ingredientInventoryItems = useSelector((state: RootState) =>
        getCombinedIngredientsWithInventory(state, productInventoryItems ?? [])
    );

    return (
        <IngredientsWrapper>
            <Form.Item name="ingredients">
                <DropDownSelect
                    square
                    options={formattedOptions}
                    value={null}
                    onChange={(selectedOption: any) => {
                        handleAddIngredients(selectedOption);
                    }}
                    placeholder="Ingredient"
                    height="40px"
                ></DropDownSelect>
            </Form.Item>

            {productInventoryItems?.length
                ? productInventoryItems.map(
                      (ingredient: ProductIngredientT, i: number) => (
                          <InventoryItemRow
                              key={i}
                              inventoryItem={ingredientInventoryItems[i][0]}
                              ingredient={ingredient}
                              productInventoryItems={productInventoryItems}
                              setProductInventoryItems={
                                  setProductInventoryItems
                              }
                              handleUpdateIngredients={handleUpdateIngredients}
                              handleRemoveIngredients={handleRemoveIngredients}
                          />
                      )
                  )
                : null}
        </IngredientsWrapper>
    );
};

const InventoryItemRow: React.FC<InventoryItemRowProp> = ({
    inventoryItem,
    ingredient,
    handleUpdateIngredients,
    handleRemoveIngredients
}) => {
    const [perUnit, setPerUnit] = useState<any>();

    useEffect(() => {
        setPerUnit(
            isObject(ingredient.unitsConsumed.amount)
                ? ingredient.unitsConsumed.amount["$numberDecimal"]
                : ingredient.unitsConsumed.amount
        );
    }, []);

    if (!ingredient || !inventoryItem) {
        return null;
    }

    return (
        <Row className="ingredientsRow">
            <Col span={7} className="ingredientName ingredientWrapper">
                {inventoryItem.name}
            </Col>
            <Col span={5} className="ingredientWrapper">
                <InputNumber
                    className="input-number quantity"
                    bordered={false}
                    formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={perUnit}
                    min={0}
                    onChange={(e) => {
                        setPerUnit(e);
                    }}
                    onBlur={(e: React.FocusEvent<HTMLElement>) => {
                        const val = (e.target as HTMLInputElement).value;
                        handleUpdateIngredients(inventoryItem.id, Number(val));
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter") {
                            e.currentTarget.blur();
                            const val = (e.target as HTMLInputElement).value;

                            handleUpdateIngredients(
                                inventoryItem.id,
                                Number(val)
                            );
                        }
                    }}
                />
            </Col>
            <Col span={8} className="ingredientWrapper">
                <span className="baseUnit">{inventoryItem.baseUnit}</span>
            </Col>
            <Col
                span={1}
                onClick={() => handleRemoveIngredients(inventoryItem.id)}
            >
                <span className="iconWrapper">
                    <Tooltip title="Delete">
                        <Delete className="delete-icon" />
                    </Tooltip>
                </span>
            </Col>
        </Row>
    );
};

const IngredientsWrapper = styled.div`
    font-family: "Inter";
    font-size: 16px;
    align-items: center;
    justify-content: center;
    .ant-btn {
        border-radius: 8px;
    }

    .ant-form label {
        font-size: 16px;
        margin-top: 5px;
        margin-right: 1rem;
    }
    .ant-col.ant-form-item-label {
        padding-bottom: 1px;
        padding-top: 1px;
    }

    .ant-row.ant-form-item {
        margin: 5px;
    }

    .input-number {
        border-radius: 8px;
    }

    input {
        border-radius: 8px;
    }

    .baseUnit::before {
        font-family: "Inter";
        content: "Unit:";
        position: absolute;
        top: 3px;
        left: 6px;
        font-size: 12px;
        color: rgba(96, 108, 118, 1);
    }

    .quantity::before {
        font-family: "Inter";
        content: "Quantity:";
        position: absolute;
        top: -22px;
        left: 1px;
        font-size: 12px;
        color: rgba(96, 108, 118, 1);
    }

    .ingredientName::before {
        font-family: "Inter";
        content: "Ingredient:";
        position: absolute;
        top: 3px;
        left: 6px;
        font-size: 12px;
        color: rgba(96, 108, 118, 1);
    }

    .ingredientWrapper {
        border-radius: 8px;
        border: 1px solid rgba(221, 229, 238, 1);
        padding-top: 25px;
        padding-left: 4px;
        margin-right: 0.2rem;
    }

    .ingredientName {
        margin-left: 1%;
        inline-size: 115px;
        overflow-wrap: break-word;
    }

    .ingredientsRow {
        padding: 1rem 0px;
        border-bottom: 0.2px solid ${colors["neutral-400"]};
        align-items: center;
    }
    .iconWrapper {
        display: flex;
        margin-left: 0.1rem;
        border: 1px solid ${colors["neutral-400"]};
        height: 55px;
        width: 36px;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
    }

    .delete-icon {
        cursor: pointer;
        width: 1rem;
        height: 1rem;
    }

    .delete-icon path {
        fill: #ff3929;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-bottom: 4px;
    .description {
        margin-top: 5px;
        margin-bottom: 1rem;
        color: #a0adba;
        font-size: 12px;
    }
`;

export default Ingredients;
