import { useContext, useMemo } from "react";

import { ChannelDisplayLayout } from "#devices/components/EditDrawer/Settings";
import {
    ChannelType,
    OtherChannelOptions
} from "#devices/utils/deviceTypes/SnackTVDevice";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import { Video } from "#devices/components/EditDrawer/DeviceEdit/SnackTV/Video";
import { StaticImage } from "#devices/components/EditDrawer/DeviceEdit/SnackTV/StaticImage";

export const OtherChannelDisplay = () => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const channel =
        (updatedDevice?.deviceDetails?.channel as ChannelType) ??
        snackTvDevice?.deviceDetails?.channel;

    const channelOptions = useMemo(() => {
        switch (channel) {
            case ChannelType.Video:
                return <Video />;
            case ChannelType.StaticImage:
                return <StaticImage />;
        }
    }, [channel]);

    return (
        <>
            <ChannelDisplayLayout options={OtherChannelOptions} />
            {channelOptions}
        </>
    );
};
