import { getActiveStore } from "@snackpass/accounting";
import { useCallback } from "react";

import config from "#config";
import { useAppSelector } from "src/redux/hooks";
import { logAndSendError } from "src/utils/errors";
import { useActiveUser } from "src/redux/selectors";

export const SegmentEvents = {
    CLICKED_PROMO_SHORTCUT: "Clicked Promo Shortcut",

    VISITED_WHATSNEW_SCREEN: "Visited What's New Screen",

    Reports: {
        SALES_SUMMARY: {
            // Filtered suggested time
            USER_FILTERED_SUGGESTED_TIME:
                "User Filtered Suggested Time Sales Summary Report",
            // Filtered custom time
            USER_FILTERED_CUSTOM_TIME:
                "User Filtered Custom Time Sales Summary Report",
            // Exported
            USER_EXPORTED: "User Exported Sales Summary Report",
            // Changed granularity
            USER_CHANGED_GRANULARITY:
                "User Changed Granularity Sales Summary Report",
            // Changed comparison
            USER_CHANGED_COMPARISON:
                "User Changed Comparison Sales Summary Report",
            // Changed source
            USER_CHANGED_SOURCE: "User Changed Source Sales Summary Report",
            // Changed channel
            USER_CHANGED_CHANNEL: "User Changed Channel Sales Summary Report",
            // Changed fulfillment
            USER_CHANGED_FULFILLMENT:
                "User Changed Fulfillment Sales Summary Report",
            // Changed location
            USER_CHANGED_LOCATION: "User Changed Location Sales Summary Report"
        },
        SALES_CHANNELS: {
            // Filtered suggested time
            USER_FILTERED_SUGGESTED_TIME:
                "User Filtered Suggested Time Sales Channels Report",
            // Filtered custom time
            USER_FILTERED_CUSTOM_TIME:
                "User Filtered Custom Time Sales Channels Report",
            // Exported
            USER_EXPORTED: "User Exported Sales Channels Report",
            // Changed granularity
            USER_CHANGED_GRANULARITY:
                "User Changed Granularity Sales Channels Report",
            // Changed comparison
            USER_CHANGED_COMPARISON:
                "User Changed Comparison Sales Channels Report",
            // Changed source
            USER_CHANGED_SOURCE: "User Changed Source Sales Channels Report",
            // Changed channel
            USER_CHANGED_CHANNEL: "User Changed Channel Sales Channels Report",
            // Changed fulfillment
            USER_CHANGED_FULFILLMENT:
                "User Changed Fulfillment Sales Channels Report",
            // Changed location
            USER_CHANGED_LOCATION: "User Changed Location Sales Channels Report"
        },
        MENU_ITEM_INSIGHTS: {
            // Filtered suggested time
            USER_FILTERED_SUGGESTED_TIME:
                "User Filtered Suggested Time Menu Item Report",
            // Filtered custom time
            USER_FILTERED_CUSTOM_TIME:
                "User Filtered Custom Time Menu Item Report",
            // Exported
            USER_EXPORTED: "User Exported Menu Item Report",
            // Changed granularity
            USER_CHANGED_GRANULARITY:
                "User Changed Granularity Menu Item Report",
            // Changed comparison
            USER_CHANGED_COMPARISON: "User Changed Comparison Menu Item Report",
            // Changed source
            USER_CHANGED_SOURCE: "User Changed Source Menu Item Report",
            // Changed channel
            USER_CHANGED_CHANNEL: "User Changed Channel Menu Item Report",
            // Changed fulfillment
            USER_CHANGED_FULFILLMENT:
                "User Changed Fulfillment Menu Item Report",
            // Changed location
            USER_CHANGED_LOCATION: "User Changed Location Menu Item Report"
        },
        MENU_CATEGORY_INSIGHTS: {
            // Filtered suggested time
            USER_FILTERED_SUGGESTED_TIME:
                "User Filtered Suggested Time Menu Category Report",
            // Filtered custom time
            USER_FILTERED_CUSTOM_TIME:
                "User Filtered Custom Time Menu Category Report",
            // Exported
            USER_EXPORTED: "User Exported Menu Category Report",
            // Changed granularity
            USER_CHANGED_GRANULARITY:
                "User Changed Granularity Menu Category Report",
            // Changed comparison
            USER_CHANGED_COMPARISON:
                "User Changed Comparison Menu Category Report",
            // Changed source
            USER_CHANGED_SOURCE: "User Changed Source Menu Category Report",
            // Changed channel
            USER_CHANGED_CHANNEL: "User Changed Channel Menu Category Report",
            // Changed fulfillment
            USER_CHANGED_FULFILLMENT:
                "User Changed Fulfillment Menu Category Report",
            // Changed location
            USER_CHANGED_LOCATION: "User Changed Location Menu Category Report"
        },
        LOCATION_SALES_REPORT: {
            // Filtered suggested time
            USER_FILTERED_SUGGESTED_TIME:
                "User Filtered Suggested Time Sales by Location Report",
            // Filtered custom time
            USER_FILTERED_CUSTOM_TIME:
                "User Filtered Custom Time Sales by Location Report",
            // Exported
            USER_EXPORTED: "User Exported Sales by Location Report",
            // Changed granularity
            USER_CHANGED_GRANULARITY:
                "User Changed Granularity Sales by Location Report",
            // Changed comparison
            USER_CHANGED_COMPARISON:
                "User Changed Comparison Sales by Location Report",
            // Changed source
            USER_CHANGED_SOURCE: "User Changed Source Sales by Location Report",
            // Changed channel
            USER_CHANGED_CHANNEL:
                "User Changed Channel Sales by Location Report",
            // Changed fulfillment
            USER_CHANGED_FULFILLMENT:
                "User Changed Fulfillment Sales by Location Report",
            // Changed location
            USER_CHANGED_LOCATION:
                "User Changed Location Sales by Location Report"
        },
        CUSTOMER_DIRECTORY_INSIGHTS: {
            // Filtered suggested time
            USER_FILTERED_SUGGESTED_TIME:
                "User Filtered Suggested Time Customer Directory Report",
            // Filtered custom time
            USER_FILTERED_CUSTOM_TIME:
                "User Filtered Custom Time Customer Directory Report",
            // Exported
            USER_EXPORTED: "User Exported Customer Directory Report",
            // Changed granularity
            USER_CHANGED_GRANULARITY:
                "User Changed Granularity Customer Directory Report",
            // Changed comparison
            USER_CHANGED_COMPARISON:
                "User Changed Comparison Customer Directory Report",
            // Changed source
            USER_CHANGED_SOURCE:
                "User Changed Source Customer Directory Report",
            // Changed channel
            USER_CHANGED_CHANNEL:
                "User Changed Channel Customer Directory Report",
            // Changed fulfillment
            USER_CHANGED_FULFILLMENT:
                "User Changed Fulfillment Customer Directory Report",
            // Changed location
            USER_CHANGED_LOCATION:
                "User Changed Location Sales by Customer Directory Report"
        },
        LOCATION_MENU_CATEGORY_REPORT: {
            // Filtered suggested time
            USER_FILTERED_SUGGESTED_TIME:
                "User Filtered Suggested Time Menu Category by Location Report",
            // Filtered custom time
            USER_FILTERED_CUSTOM_TIME:
                "User Filtered Custom Time Menu Category by Location Report",
            // Exported
            USER_EXPORTED: "User Exported Menu Category by Location Report",
            // Changed granularity
            USER_CHANGED_GRANULARITY:
                "User Changed Granularity Menu Category by Location Report",
            // Changed comparison
            USER_CHANGED_COMPARISON:
                "User Changed Comparison Menu Category by Location Report",
            // Changed source
            USER_CHANGED_SOURCE:
                "User Changed Source Menu Category by Location Report",
            // Changed channel
            USER_CHANGED_CHANNEL:
                "User Changed Channel Menu Category by Location Report",
            // Changed fulfillment
            USER_CHANGED_FULFILLMENT:
                "User Changed Fulfillment Menu Category by Location Report",
            // Changed location
            USER_CHANGED_LOCATION:
                "User Changed Location Sales by Menu Category by Location Report"
        },
        LOCATION_MENU_ITEM_REPORT: {
            // Filtered suggested time
            USER_FILTERED_SUGGESTED_TIME:
                "User Filtered Suggested Time Menu Item by Location Report",
            // Filtered custom time
            USER_FILTERED_CUSTOM_TIME:
                "User Filtered Custom Time Menu Item by Location Report",
            // Exported
            USER_EXPORTED: "User Exported Menu Item by Location Report",
            // Changed granularity
            USER_CHANGED_GRANULARITY:
                "User Changed Granularity Menu Item by Location Report",
            // Changed comparison
            USER_CHANGED_COMPARISON:
                "User Changed Comparison Menu Item by Location Report",
            // Changed source
            USER_CHANGED_SOURCE:
                "User Changed Source Menu Item by Location Report",
            // Changed channel
            USER_CHANGED_CHANNEL:
                "User Changed Channel Menu Item by Location Report",
            // Changed fulfillment
            USER_CHANGED_FULFILLMENT:
                "User Changed Fulfillment Menu Item by Location Report",
            // Changed location
            USER_CHANGED_LOCATION:
                "User Changed Location Sales by Menu Item by Location Report"
        },
        GIFT_CARD_PURCHASE_REPORT: {
            // Filtered suggested time
            USER_FILTERED_SUGGESTED_TIME:
                "User Filtered Suggested Time Gift Card Purchase Report",
            // Filtered custom time
            USER_FILTERED_CUSTOM_TIME:
                "User Filtered Custom Time Gift Card Purchase Report",
            // Exported
            USER_EXPORTED: "User Exported Gift Card Purchase Report",
            // Changed granularity
            USER_CHANGED_GRANULARITY:
                "User Changed Granularity Gift Card Purchase Report",
            // Changed comparison
            USER_CHANGED_COMPARISON:
                "User Changed Comparison Gift Card Purchase Report",
            // Changed source
            USER_CHANGED_SOURCE:
                "User Changed Source Gift Card Purchase Report",
            // Changed channel
            USER_CHANGED_CHANNEL:
                "User Changed Channel Gift Card Purchase Report",
            // Changed fulfillment
            USER_CHANGED_FULFILLMENT:
                "User Changed Fulfillment Gift Card Purchase Report",
            // Changed location
            USER_CHANGED_LOCATION:
                "User Changed Location Sales by Gift Card Purchase Report"
        },
        GIFT_CARD_BALANCE_REPORT: {
            // Filtered suggested time
            USER_FILTERED_SUGGESTED_TIME:
                "User Filtered Suggested Time Gift Card Balance Report",
            // Filtered custom time
            USER_FILTERED_CUSTOM_TIME:
                "User Filtered Custom Time Gift Card Balance Report",
            // Exported
            USER_EXPORTED: "User Exported Gift Card Balance Report",
            // Changed granularity
            USER_CHANGED_GRANULARITY:
                "User Changed Granularity Gift Card Balance Report",
            // Changed comparison
            USER_CHANGED_COMPARISON:
                "User Changed Comparison Gift Card Balance Report",
            // Changed source
            USER_CHANGED_SOURCE: "User Changed Source Gift Card Balance Report",
            // Changed channel
            USER_CHANGED_CHANNEL:
                "User Changed Channel Gift Card Balance Report",
            // Changed fulfillment
            USER_CHANGED_FULFILLMENT:
                "User Changed Fulfillment Gift Card Balance Report",
            // Changed location
            USER_CHANGED_LOCATION:
                "User Changed Location Sales by Gift Card Balance Report"
        },
        GIFT_CARD_TRANSACTION_REPORT: {
            // Filtered suggested time
            USER_FILTERED_SUGGESTED_TIME:
                "User Filtered Suggested Time Gift Card Transaction Report",
            // Filtered custom time
            USER_FILTERED_CUSTOM_TIME:
                "User Filtered Custom Time Gift Card Transaction Report",
            // Exported
            USER_EXPORTED: "User Exported Gift Card Transaction Report",
            // Changed granularity
            USER_CHANGED_GRANULARITY:
                "User Changed Granularity Gift Card Transaction Report",
            // Changed comparison
            USER_CHANGED_COMPARISON:
                "User Changed Comparison Gift Card Transaction Report",
            // Changed source
            USER_CHANGED_SOURCE:
                "User Changed Source Gift Card Transaction Report",
            // Changed channel
            USER_CHANGED_CHANNEL:
                "User Changed Channel Gift Card Transaction Report",
            // Changed fulfillment
            USER_CHANGED_FULFILLMENT:
                "User Changed Fulfillment Gift Card Transaction Report",
            // Changed location
            USER_CHANGED_LOCATION:
                "User Changed Location Sales by Gift Card Transaction Report"
        },
        PROMOTIONS_REPORT: {
            // Filtered suggested time
            USER_FILTERED_SUGGESTED_TIME:
                "User Filtered Suggested Time Menu Promotions Report",
            // Filtered custom time
            USER_FILTERED_CUSTOM_TIME:
                "User Filtered Custom Time Menu Promotions Report",
            // Exported
            USER_EXPORTED: "User Exported Menu Promotions Report",
            // Changed granularity
            USER_CHANGED_GRANULARITY:
                "User Changed Granularity Menu Promotions Report",
            // Changed comparison
            USER_CHANGED_COMPARISON:
                "User Changed Comparison Menu Promotions Report",
            // Changed source
            USER_CHANGED_SOURCE: "User Changed Source Menu Promotions Report",
            // Changed channel
            USER_CHANGED_CHANNEL: "User Changed Channel Menu Promotions Report",
            // Changed fulfillment
            USER_CHANGED_FULFILLMENT:
                "User Changed Fulfillment Menu Promotions Report",
            // Changed location
            USER_CHANGED_LOCATION:
                "User Changed Location Sales by Menu Promotions Report"
        }
    },

    Guestbook: {
        VIEWED: "Viewed Guestbook Tab",

        Campaigns: {
            VIEWED: "Viewed Guestbook Campaigns Tab",
            STARTED: "Started Guestbook Campaign",
            VIEWED_TEMPLATES: "Viewed Guestbook Campaign Templates",
            PICKED_TEMPLATE: "Picked Guestbook Campaign Template",
            SENT: "Sent Guestbook Campaign",
            VIEWED_CAMPAIGN: "Viewed Guestbook Campaign",
            PAGED_RECIPIENTS_LIST:
                "Paged through Guestbook Campaign Recipients List",
            SETUP_SMS: {
                START: "Setup SMS Campaign",
                CANCEL: "Cancelled SMS Campaign Setup",
                CONTINUE: "Continued SMS Campaign Setup",
                SUBMIT: "Submit SMS Campaign Setup",
                SUCCESS: "Success SMS Campaign Setup"
            },
            SETUP_PUSH: "Setup Push Campaign",
            NEW_CAMPAIGN: {
                FAIL_PRE_REG:
                    "Attempted to create new campaign before registration"
            }
        },
        Conversations: {
            VIEWED: "Viewed Guestbook Conversations Tab",
            SENT: "Sent Guestbook Conversations Chat Message",
            CLICKED_HOMEPAGE_PREVIEW:
                "Clicked Conversation Preview on Homepage",
            CLICKED_MAGIC_RESPOND: "Clicked Guestbook Magic Respond"
        }
    },

    PayoutsSettings: {
        CLICKED_GET_STARTED: "Initiated Payouts Setup",
        CLICKED_SUBMIT: "Completed Payouts Setup",
        CLICKED_BANNER: "Clicked End of Life Banner"
    },

    Permissions: {
        UPDATED_A_USER: "Updated A User",
        CREATED_A_USER: "Created A User",
        REMOVED_A_USER: "Removed A User"
    }
};

export const trackSegmentEvent = (event: string, properties?: Object): void => {
    if (config.isDevEnv) {
        console.log(
            "[trackSegmentEvent] would have tracked event",
            `\`${event}\``,
            "with properties:",
            properties
        );
    } else {
        try {
            window.analytics?.track(event, properties);
        } catch (e: unknown) {
            logAndSendError(
                "[trackSegmentEvent] failed to track event" +
                    `\`${event}\`` +
                    "with properties:" +
                    properties +
                    "Error:" +
                    e
            );
        }
    }
};

/**
 * Returns a function to track a segment event with an attached store id/name, user + any additional input properties.
 * usage:
 * const trackEvent = useTrackSegmentEvent();
 * trackEvent(SegmentEvents.VISITED_MENU_SCREEN, { property: "value" });
 */
export const useTrackSegmentEvent = () => {
    const user = useActiveUser();
    const activeStore = useAppSelector(getActiveStore);
    const storeId = activeStore?._id ?? null;
    const storeName = activeStore?.name ?? null;

    return useCallback(
        (event: string, properties?: object) =>
            trackSegmentEvent(event, {
                storeId,
                storeName,
                user: user?._id,
                ...properties
            }),
        [storeId, storeName, user?._id]
    );
};
