import React from "react";
import styled from "styled-components";

import colors from "#reusable/colors/colors.json";
import Text from "#devices/components/Text";

type DetailsSectionProps = {
    heading: string;
    children: React.ReactNode;
    bottom?: boolean;
};

const DetailsSectionWrapper = styled.div<{ bottom: boolean }>`
    border-bottom: 1px solid ${colors["neutral-400"]};
    margin-bottom: ${({ theme, bottom }) =>
        bottom ? theme.spacing.double : theme.spacing.base};
    padding-bottom: ${({ theme }) => theme.spacing.double};
    // grow to full height to match others in Row/Col
    height: calc(100% - ${({ theme }) => theme.spacing.double});
`;

const DetailsSection = ({
    heading,
    children,
    bottom = false
}: DetailsSectionProps): JSX.Element => (
    <DetailsSectionWrapper bottom={bottom}>
        <Text.Title2>{heading}</Text.Title2>
        {children}
    </DetailsSectionWrapper>
);

export default DetailsSection;
