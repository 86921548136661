import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useSelector } from "react-redux";
import { RemoveScroll } from "react-remove-scroll";
import { useSetRecoilState } from "recoil";
import { Spinner } from "react-activity";
import { useState } from "react";
import { toast } from "sonner";
import { AxiosError } from "axios";

import { Form } from "src/@/components/ui/form";
import CampaignBrandRegistrationHeader from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationHeader";
import {
    ShareTwilioBrandCheckSchema,
    SharedTwilioBrandFormSchema
} from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/campaignBrandRegistrationSMSFormSchema";
import { getActiveStore } from "src/redux/selectors";
import {
    BrandRegistrationStep,
    brandRegistrationStatusAtom,
    brandRegistrationStepAtom,
    isUsingChainsAtom
} from "#guestbook/screens/Campaigns/CampaignBrandRegistration/CampaignBrandRegistrationAtoms";
import { Button } from "src/@/components/ui/button";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle
} from "src/@/components/ui/card";
import SharedBrandRegistrationEINField from "#guestbook/screens/Campaigns/CampaignBrandRegistration/SharedBrandRegistrationSMSForm/SharedBrandRegistrationEINField";
import SharedBrandRegistrationSharedSelect from "#guestbook/screens/Campaigns/CampaignBrandRegistration/SharedBrandRegistrationSMSForm/SharedBrandRegistrationSharedSelect";
import api from "src/api/rest";
import SharedBrandRegistrationAreaCodeInput from "#guestbook/screens/Campaigns/CampaignBrandRegistration/SharedBrandRegistrationSMSForm/SharedBrandRegistrationAreaCodeInput";
import SharedBrandRegistrationDisplayNameInput from "#guestbook/screens/Campaigns/CampaignBrandRegistration/SharedBrandRegistrationSMSForm/SharedBrandRegistrationDisplayNameInput";
import SharedBrandRegistrationReviewAndConfirm from "#guestbook/screens/Campaigns/CampaignBrandRegistration/SharedBrandRegistrationSMSForm/SharedBrandRegistrationReviewAndConfirm";
import useTrackCampaignSegmentEvent from "#guestbook/screens/Campaigns/useTrackCampaignSegmentEvent";
import { SegmentEvents } from "#utils/segment";
import { storeDisplayNameAtom } from "#guestbook/screens/Campaigns/NewCampaign/NewCampaignAtoms";

enum ChainRegistrationStep {
    PreCheck,
    PostCheck,
    Review
}

function SharedBrandRegistrationSMSForm() {
    const setStep = useSetRecoilState(brandRegistrationStepAtom);
    const setIsUsingChains = useSetRecoilState(isUsingChainsAtom);
    const store = useSelector(getActiveStore);
    const [chainStep, setChainStep] = useState(ChainRegistrationStep.PreCheck);
    const trackCampaignEvent = useTrackCampaignSegmentEvent();

    // Conditionally applies refinements based on whether we are the review step or not to check the booleans
    // Idea from https://stackoverflow.com/questions/77121215/conditional-validation-in-zod
    const reviewStepSchema = SharedTwilioBrandFormSchema.superRefine(
        (data, refinementContext) => {
            if (chainStep === ChainRegistrationStep.PostCheck) {
                return;
            }
            const requiredReviewBooleans = ["agreedToPricing"] as const;
            for (const field of requiredReviewBooleans) {
                if (data[field] !== true) {
                    refinementContext.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: `Must agree to the terms`,
                        path: [field]
                    });
                }
            }

            if (data.hasPaymentMethod !== true) {
                refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: `Must have billing method`,
                    path: ["hasPaymentMethod"]
                });
            }
        }
    );

    const schema =
        chainStep === ChainRegistrationStep.PreCheck
            ? ShareTwilioBrandCheckSchema
            : reviewStepSchema;

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        mode: "onTouched",
        defaultValues: {
            ein: "",
            desiredAreaCode: "",
            displayName: "",
            agreedToPricing: false,
            hasPaymentMethod: false
        }
    });
    const [isLoading, setIsLoading] = useState(false);
    const setBrandRegistrationStatus = useSetRecoilState(
        brandRegistrationStatusAtom
    );
    const setStoreDisplayName = useSetRecoilState(storeDisplayNameAtom);

    const onSubmit = async (formValues: z.infer<typeof schema>) => {
        if (!store) return;
        if (chainStep === ChainRegistrationStep.PreCheck) {
            setIsLoading(true);
            try {
                await api.twilio.checkSharedTwilioBrand(store._id, formValues);
                // Resets the form so that validation functions as if the form has not been submitted when dealing with
                // the area code
                form.reset(
                    {
                        ...formValues,
                        agreedToPricing: false
                    },
                    {
                        keepIsSubmitted: false,
                        keepIsSubmitSuccessful: false,
                        keepTouched: false,
                        keepDefaultValues: true
                    }
                );
                setChainStep(ChainRegistrationStep.PostCheck);
            } catch (e: unknown) {
                if (e instanceof AxiosError) {
                    const errorMessage = e.response?.data?.message ?? e.message;
                    if (e.response && e.response.status === 429) {
                        toast.error("Failed checking EIN", {
                            description:
                                "Too many checks recently, try again in 15 minutes"
                        });
                    } else if (errorMessage.includes("Wrong EIN")) {
                        toast.error("EIN is not correct.", {
                            description: "Try registering individually instead."
                        });
                    } else {
                        toast.error("Failed checking EIN", {
                            description: errorMessage
                        });
                    }
                }
            } finally {
                setIsLoading(false);
            }
        } else if (chainStep === ChainRegistrationStep.PostCheck) {
            setChainStep(ChainRegistrationStep.Review);
            trackCampaignEvent(
                SegmentEvents.Guestbook.Campaigns.SETUP_SMS.CONTINUE,
                { isShared: "true" }
            );
        } else if (
            chainStep === ChainRegistrationStep.Review &&
            "agreedToPricing" in formValues // mostly for type narrowing
        ) {
            trackCampaignEvent(
                SegmentEvents.Guestbook.Campaigns.SETUP_SMS.SUBMIT,
                { isShared: "true" }
            );
            setIsLoading(true);
            try {
                const response = await api.twilio.createSharedTwilioBrand(
                    store._id,
                    formValues
                );
                trackCampaignEvent(
                    SegmentEvents.Guestbook.Campaigns.SETUP_SMS.SUCCESS,
                    { isShared: "true" }
                );
                setBrandRegistrationStatus(response.data.status);
                setStoreDisplayName(
                    response.data.displayName ?? "Store name missing!"
                );
                toast.success("Shared brand successful");
            } catch (e) {
                if (e instanceof AxiosError) {
                    toast.error("Failed registering shared brand", {
                        description: e.response?.data?.message ?? e.message
                    });
                }
            } finally {
                setIsLoading(false);
            }
        }
    };
    return (
        <Form {...form}>
            <form
                className="flex flex-col"
                onSubmit={form.handleSubmit(onSubmit, (b) => {
                    console.log(b);
                })}
            >
                <CampaignBrandRegistrationHeader>
                    <Button
                        type="button"
                        variant={"outline"}
                        onClick={() => {
                            trackCampaignEvent(
                                SegmentEvents.Guestbook.Campaigns.SETUP_SMS
                                    .CANCEL,
                                { isShared: "true" }
                            );
                            setStep(BrandRegistrationStep.GetStarted);
                        }}
                    >
                        Cancel
                    </Button>
                </CampaignBrandRegistrationHeader>

                <div
                    // See https://github.com/radix-ui/primitives/discussions/1100, RemoveScroll is a clunky fix to set the width assuming there WILL be a scrollbar
                    // This prevents the page from jumping when the Select popover is triggered because the scrollbar will disappear on mac
                    className={`flex flex-col items-center ${RemoveScroll.classNames.fullWidth}`}
                >
                    <div className="flex w-full max-w-4xl flex-col space-y-6 p-4">
                        <div className="text-2xl font-semibold">
                            Another store in your chain has already registered
                            for SMS messages
                            <p className="text-sm font-normal">
                                If you know the EIN of the registration, you can
                                share the same registration as them, allowing
                                you to save on the registration and monthly
                                fees. You can choose to share the same phone
                                number and display name or select a new one.
                            </p>
                        </div>
                        <Card>
                            <CardHeader>
                                <CardTitle>Shared SMS Registration</CardTitle>
                            </CardHeader>
                            {chainStep === ChainRegistrationStep.Review ? (
                                <CardContent className="p-4 lg:p-6">
                                    <SharedBrandRegistrationReviewAndConfirm />
                                    <div className="flex flex-row justify-end gap-2 pt-6">
                                        <Button
                                            type="button"
                                            variant={"outline"}
                                            onClick={() =>
                                                setChainStep(
                                                    ChainRegistrationStep.PostCheck
                                                )
                                            }
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            type="submit"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? (
                                                <Spinner size={14} />
                                            ) : (
                                                "Submit"
                                            )}
                                        </Button>
                                    </div>
                                </CardContent>
                            ) : (
                                <CardContent className="p-4 lg:p-6">
                                    <SharedBrandRegistrationSharedSelect />
                                    <SharedBrandRegistrationEINField />
                                    {chainStep ===
                                        ChainRegistrationStep.PostCheck && (
                                        <>
                                            <SharedBrandRegistrationAreaCodeInput />
                                            <SharedBrandRegistrationDisplayNameInput />
                                        </>
                                    )}
                                    <div className="flex flex-row justify-end pt-6">
                                        <Button
                                            type="submit"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? (
                                                <Spinner size={14} />
                                            ) : chainStep ===
                                              ChainRegistrationStep.PostCheck ? (
                                                "Continue"
                                            ) : (
                                                "Check EIN"
                                            )}
                                        </Button>
                                    </div>
                                </CardContent>
                            )}
                        </Card>
                        <div className="flex flex-row gap-6 py-6">
                            <Button
                                type="button"
                                onClick={() => setIsUsingChains(false)}
                            >
                                Register Individually Instead
                            </Button>
                            <p className="text-xs md:text-sm">
                                By registering individually, your phone number
                                will not be affected if customers choose to opt
                                out of SMS messages sent from the shared brand's
                                number.
                            </p>
                        </div>
                    </div>
                </div>
            </form>
        </Form>
    );
}

export default SharedBrandRegistrationSMSForm;
