/** @jsxImportSource @emotion/react */
import { Button, Modal } from "react-bootstrap";
import { capitalize } from "lodash";
import { Col, Row, Space } from "antd";
import { css } from "@emotion/react";
import React, { Dispatch, SetStateAction } from "react";
import { IHoursSchema, ScreenState } from "@snackpass/snackpass-types";

import {
    getDisplayHours,
    getHoursByDay,
    getTimeLabel
} from "#menu-editor/multi-menus/helpers";
import { MenuTimeConflicts } from "#menu-editor/multi-menus/redux/types";
import { ReactComponent as XIcon } from "src/assets/icons/close-x.svg";
import { RoundButton } from "#menu-editor/multi-menus/styled-components/button";
import { Text } from "#menu-editor/multi-menus/styled-components/text";
import colors from "#reusable/colors/colors.json";

type ModalProps = {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    conflicts: MenuTimeConflicts[];
};

const renderTimeMap = (displayHour: string, index: number) => (
    <Text key={index} type="body-regular">
        {displayHour}
    </Text>
);

const renderMenuMap = (element: MenuTimeConflicts, index: number) => (
    <div className="menu-container" key={index}>
        <div className="menu-label">{element.menu.name}</div>
        <div className="data-container">
            <div className="data-label">Hours</div>
            <HourRow hours={element.conflictHours} />
        </div>
        <div className="data-container ">
            <div className="data-label">Platform</div>
            <div className="data-right data-last">
                {element.menu.platforms
                    .map((platform) => capitalize(platform.toString()))
                    .join(", ")}
            </div>
        </div>
    </div>
);

const HourRow: React.FC<{ hours: IHoursSchema }> = ({ hours }) => {
    const menuHours = getHoursByDay(hours.local);
    const label = getTimeLabel(menuHours);
    const displayHours = getDisplayHours(menuHours);

    return (
        <Row className="data-right">
            <Text type="body-bold">{label}</Text>
            <Col span={24}>
                <Space direction="vertical">
                    {displayHours.map(renderTimeMap)}
                </Space>
            </Col>
        </Row>
    );
};

export const TimeConflictsModal: React.FC<ModalProps> = ({
    isModalOpen,
    setIsModalOpen,
    conflicts
}) => {
    const onConfirm = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal
            show={isModalOpen}
            css={warningModalStyles}
            size="xl"
            dialogClassName="modal-width"
            centered
        >
            <Modal.Header className="modal-header">
                <Modal.Title>
                    <RoundButton onClick={() => setIsModalOpen(false)}>
                        <XIcon />
                    </RoundButton>
                    <p className="header-title">
                        Time Settings Conflict with Another Active Menus
                    </p>
                </Modal.Title>
                <div className="header-subtitle-container">
                    <p className="header-subtitle">
                        Time and Visibility settings overlap with{" "}
                        {conflicts.length} active menu
                        {conflicts.length > 1 && "s"}. Please adjust selections
                        and try again.
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body className="modal-body">
                {conflicts.map(renderMenuMap)}
            </Modal.Body>
            <Modal.Footer>
                <Button className="okay-btn" onClick={onConfirm}>
                    Okay
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const warningModalStyles = css`
    font-family: "Inter";
    font-style: normal;

    .modal-width {
        @media ${ScreenState.DESKTOP} {
            width: 39%;
        }
    }
    .modal-content {
        border-radius: 16px;
    }
    .modal-header {
        display: flex;
        flex-direction: column;
    }
    .header-title {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        color: #282d32;
        @media ${ScreenState.MOBILE} {
            margin: 24px;
        }
        margin: 48px 24px 24px 24px;
    }
    .header-subtitle {
        color: #606c76;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin: 0px 24px 24px 24px;
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        padding-bottom: 0px;
    }
    .menu-container {
        border: 1px solid ${colors["neutral-400"]};
        border-radius: 16px;
        margin-bottom: 24px;
    }
    .menu-label {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 16px 16px 0px 16px;
    }
    .data-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #606c76;
        flex: 1;
    }
    .data-right {
        justify-content: end;
        text-align: right;
        padding-top: 4px;
    }
    .data-container {
        display: flex;
        justify-content: start;
        margin: 0px 16px;
    }
    .data-last {
        margin-bottom: 16px;
    }
    .okay-btn {
        flex: auto;
        background: #0077ff;
        border-radius: 8px;
    }
    .modal-footer {
        justify-content: start;
    }
`;
