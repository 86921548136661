import { EmployeePermissions } from "@snackpass/snackpass-types";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-activity";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { NumberInput } from "@tremor/react";

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from "src/@/components/ui/select";
import { DashboardPermissions } from "#settings/settings-permissions/admin-view-edit-dashboard-permissions";
import { SnackOSPermissions } from "#settings/settings-permissions/admin-view-edit-snackos-permissions";
import { useHasEditAdminForActiveStore } from "#hooks/use-has-edit-admin-for-active-store";
import { Button } from "src/@/components/ui/button";
import { Input } from "src/@/components/ui/input";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle
} from "src/@/components/ui/dialog";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormMessage
} from "src/@/components/ui/form";

import { EMPTY_EMP_DATA, InviteFields, Roles, StoreRole } from "./types";

type Props = {
    isVisible: boolean;
    onSubmit: (
        invite: InviteFields,
        permissionUpdates: Record<string, boolean>,
        employeePermissions: EmployeePermissions
    ) => void;
    onClose?: () => void;
};

export const adminValidationSchema = z.object({
    email: z.string().email(),
    wage: z.number().min(0),
    pin: z
        .string()
        .refine((input) => /^\d{4}$|^$/.test(input), {
            message: "Must be 4 digits"
        })
        .optional(),
    role: z.string().min(1, { message: "Job Title is a required field" }),
    identifier: z.string().optional()
});

const AdminInviteUserPopover = ({ isVisible, onClose, onSubmit }: Props) => {
    const [toggles, setToggles] = useState(Roles.allFalseRole);
    const [empToggles, setEmpToggles] = useState<EmployeePermissions>({
        discounts: false
    });
    const viewOnly = !useHasEditAdminForActiveStore();

    const form = useForm<z.infer<typeof adminValidationSchema>>({
        resolver: zodResolver(adminValidationSchema),
        defaultValues: {
            email: "",
            wage: 0,
            pin: "",
            role: "",
            identifier: ""
        }
    });

    const _resetForm = () => {
        setToggles(Roles.allFalseRole);
        setEmpToggles(EMPTY_EMP_DATA);
    };

    useEffect(() => {
        if (isVisible) {
            form.reset();
        }
    }, [form, isVisible]);

    const _onSubmit = (
        values: z.infer<typeof adminValidationSchema>,
        event?: React.BaseSyntheticEvent,
        errors?: z.ZodError
    ) => {
        if (!errors && !viewOnly) {
            onSubmit(
                {
                    email: values.email,
                    wage: values.wage.toString(),
                    pin: values.pin !== "" ? values.pin : undefined,
                    role: values.role,
                    identifier: values.identifier ?? ""
                },
                toggles,
                empToggles
            );
            _resetForm();
        }
    };

    return (
        <Dialog
            open={isVisible}
            onOpenChange={(open: boolean) => {
                if (!open) {
                    _resetForm();
                    onClose?.();
                }
            }}
        >
            <DialogContent className="overflow-y-scroll bg-white max-md:h-full max-md:min-w-full max-md:pb-20 md:h-[95%] md:min-w-[50%]">
                <DialogHeader className="w-full border-b border-neutral-400">
                    <DialogTitle className="mb-6 text-body">
                        New User
                    </DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(_onSubmit)}>
                        <div className="text-large font-semibold">
                            Basic Info
                        </div>
                        <div className="py-4 font-medium">Contact Info</div>
                        <div className="flex justify-center pb-6">
                            <div className="ml-1 flex-1 flex-col">
                                <FormField
                                    control={form.control}
                                    name="email"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input
                                                    placeholder="Email"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormDescription />
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="pb-4 font-medium">Identifier</div>
                        <div className="flex justify-center pb-6">
                            <div className="ml-1 flex-1 flex-col">
                                <FormField
                                    control={form.control}
                                    name="identifier"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormDescription>
                                                Store defined text such as “Jane
                                                Smith #14” to identify the user.
                                                For safety, avoid using personal
                                                data like birth date or
                                                government ID.
                                            </FormDescription>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="border-b border-neutral-400 pt-4" />
                        <div className="mb-4 pt-4 font-medium">Job Title</div>
                        <div className="ml-1 flex flex-col justify-center border-b border-neutral-400 pb-6">
                            <FormField
                                control={form.control}
                                name="role"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Select
                                                onValueChange={field.onChange}
                                                defaultValue={field.value}
                                            >
                                                <SelectTrigger className="border-neutral-400">
                                                    <SelectValue placeholder="--" />
                                                </SelectTrigger>
                                                <SelectContent className="bg-white">
                                                    {Object.entries(
                                                        StoreRole
                                                    ).map(
                                                        ([
                                                            groupKey,
                                                            { name, roles }
                                                        ]) => (
                                                            <SelectGroup
                                                                key={groupKey}
                                                            >
                                                                <SelectLabel>
                                                                    {name}
                                                                </SelectLabel>
                                                                {Object.entries(
                                                                    roles
                                                                ).map(
                                                                    ([
                                                                        roleKey,
                                                                        roleValue
                                                                    ]) => (
                                                                        <SelectItem
                                                                            value={
                                                                                roleValue
                                                                            }
                                                                            key={
                                                                                roleKey
                                                                            }
                                                                        >
                                                                            {
                                                                                roleValue
                                                                            }
                                                                        </SelectItem>
                                                                    )
                                                                )}
                                                            </SelectGroup>
                                                        )
                                                    )}
                                                </SelectContent>
                                            </Select>
                                        </FormControl>
                                        <FormDescription />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="py-4 text-large font-semibold">
                            Settings
                        </div>
                        <div className="flex flex-col">
                            <div className="flex items-center pb-4">
                                <div className="flex flex-1 flex-col justify-center font-medium">
                                    Wage
                                    <div className="mb-4 text-small font-normal text-neutral-500">
                                        Used for time clocking
                                    </div>
                                </div>
                                <div className="flex-1 flex-col">
                                    <FormField
                                        control={form.control}
                                        name="wage"
                                        render={({ field }) => (
                                            <FormItem className="flex-1">
                                                <FormControl>
                                                    <NumberInput
                                                        step={0.01}
                                                        icon={() => (
                                                            <span className="pl-3 text-small">
                                                                $
                                                            </span>
                                                        )}
                                                        placeholder="0.00"
                                                        min={0}
                                                        className="flex-1 [&>input]:pl-2"
                                                        {...field}
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                Number.parseFloat(
                                                                    e.target
                                                                        .value ??
                                                                        0
                                                                )
                                                            )
                                                        }
                                                    />
                                                </FormControl>
                                                <FormDescription />
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center border-b border-neutral-400 pb-4">
                                <div className="flex flex-1 flex-col justify-center font-medium">
                                    Pin
                                    <div className="mb-4 text-small font-normal text-neutral-500">
                                        Used for time clocking
                                    </div>
                                </div>
                                <div className="flex-1 flex-col">
                                    <FormField
                                        control={form.control}
                                        name="pin"
                                        render={({ field }) => (
                                            <FormItem className="flex-1">
                                                <FormControl>
                                                    <Input
                                                        {...field}
                                                        placeholder="1234"
                                                        type="number"
                                                    />
                                                </FormControl>
                                                <FormDescription />
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="my-4 text-large font-semibold">
                            Permissions
                        </div>
                        <DashboardPermissions
                            toggles={toggles}
                            setToggles={setToggles}
                            hasEmail={true}
                            hidePayouts={true}
                        />
                        <div className="py-4 font-medium">
                            SnackOS - Register
                        </div>
                        <SnackOSPermissions
                            toggles={empToggles}
                            setToggles={setEmpToggles}
                        />
                        <div className="sticky flex flex-col bg-white py-6 max-md:-bottom-[80px] md:-bottom-6">
                            <div className="flex justify-around">
                                <Button
                                    className="mr-2 flex-1 border border-neutral-400"
                                    variant="outline"
                                    type="button"
                                    onClick={() => {
                                        form.reset();
                                        _resetForm();
                                        toast.success(
                                            "Reset to previous settings"
                                        );
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button
                                    className="flex-1 bg-black text-white"
                                    type="submit"
                                >
                                    {form.formState.isLoading ? (
                                        <Spinner size={10} />
                                    ) : (
                                        "Save"
                                    )}
                                </Button>
                            </div>
                            {Object.keys(form.formState.errors).length > 0 && (
                                <div className="pt-3 text-center text-critical-light">
                                    Errors are preventing you from sending
                                    invite
                                </div>
                            )}
                        </div>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export default AdminInviteUserPopover;
