import { useState, useEffect } from "react";
import { IChain } from "@snackpass/snackpass-types";

import api from "src/api/rest";
import { sendError } from "src/utils/errors";

type UseGetChainConfigResult = {
    chainConfig: IChain | undefined;
    isLoadingChainConfig: boolean;
};

const useGetChainConfig = (
    chainId: string | null | undefined
): UseGetChainConfigResult => {
    const [chainConfig, setChainConfig] = useState<IChain | undefined>();
    const [isLoadingChainConfig, setIsLoadingChainConfig] =
        useState<boolean>(false);

    useEffect(() => {
        if (chainId) {
            setIsLoadingChainConfig(true);
            api.chains
                .get(chainId)
                .then(({ data }) => {
                    setChainConfig(data.chain);
                })
                .catch((err) => {
                    sendError(err);
                })
                .finally(() => setIsLoadingChainConfig(false));
        }
    }, [chainId]);

    return { chainConfig, isLoadingChainConfig };
};

export default useGetChainConfig;
