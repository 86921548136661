import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";

import DropDownSelect, { Options } from "#reusable/select/dropdown";
import { FormFieldName } from "#settings-integrations/components/shared/form-field-name";
import { FormFieldDescriptor } from "#settings-integrations/components/shared/form-field-descriptor";

type Props = {
    options: Options[];
    fieldName: string;
    name?: string;
    descriptor?: string;
    inputLabel?: string;
    divider?: boolean;
    /** Whether to allow for custom input from user */
    allowCustom?: boolean;
    /** Value to set on clear in null case (only applicable for allowCustom) */
    customClearValue?: any;
    /** Function to set how label should appear for a given value. (Note: This will overwrite label field set on Options) */
    formatOptionLabel?: (value: any) => string;
    required?: boolean;
    recommended?: boolean;
    autofilled?: boolean;
    disabled?: boolean;
};

type ContainerProps = {
    divider: boolean;
};

export const FormDropdownSelect = ({
    options,
    name,
    descriptor,
    inputLabel,
    fieldName,
    required = false,
    recommended = false,
    autofilled = false,
    divider = false,
    allowCustom = false,
    disabled = false,
    customClearValue,
    formatOptionLabel
}: Props) => {
    const { control, trigger } = useFormContext();

    const findOption = (value: string) =>
        options.find((i) => i.value === value) || { label: value, value };

    const _formatOptionLabel = (option: Options) =>
        formatOptionLabel ? formatOptionLabel(option.value) : option.value;

    // Trigger initial validation
    useEffect(() => {
        void trigger(fieldName);
    }, []);

    return (
        <div
            className={clsx(
                "mb-6 flex h-full w-full flex-1 flex-col md:flex-row",
                divider && "border-b-1 border-neutral-400 pb-6"
            )}
        >
            {(name || descriptor) && (
                <div className="max-w-1/2 flex w-full flex-col">
                    <FormFieldName
                        name={name}
                        required={required}
                        recommended={recommended}
                        autofilled={autofilled}
                    />
                    <FormFieldDescriptor descriptor={descriptor} />
                </div>
            )}
            <div className="relative flex w-full flex-col">
                {inputLabel && (
                    <p className="absolute left-3 top--2 m-0 py-1 text-small font-normal">
                        {inputLabel}
                    </p>
                )}
                <Controller
                    control={control}
                    name={fieldName}
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <>
                            {allowCustom ? (
                                <DropDownSelect
                                    isCreatable
                                    options={options}
                                    formatOptionLabel={_formatOptionLabel}
                                    square
                                    value={findOption(value)}
                                    onChange={(e: Options | null) => {
                                        onChange(
                                            Number(e?.value) ||
                                                (customClearValue ?? "")
                                        );
                                    }}
                                    placeholder="Select"
                                    height="48px"
                                    isSearchable
                                    disabled={disabled}
                                />
                            ) : (
                                <DropDownSelect
                                    options={options}
                                    square
                                    value={findOption(value)}
                                    onChange={(e: Options) => onChange(e.value)}
                                    placeholder="Select"
                                    height="48px"
                                    isSearchable={false}
                                    disabled={disabled}
                                />
                            )}
                            {error?.message && (
                                <p className="m-0 mt-4 text-sm text-critical-light">
                                    {error?.message}
                                </p>
                            )}
                        </>
                    )}
                />
            </div>
        </div>
    );
};
