import { AxiosResponse } from "axios";
import { match } from "ts-pattern";
import { ValueOf } from "type-fest";

export type VerificationsResponse<T = { verification: StoreVerification }> =
    Promise<AxiosResponse<{ success: boolean } & T>>;
export type VerificationsPersonResponse<T = { person: PersonVerification }> =
    Promise<AxiosResponse<{ success: boolean } & T>>;

export enum PayoutChannels {
    stripe = "StripePayoutChannel",
    routable = "RoutablePayoutChannel",
    unknown = "UnknownPayoutChannel"
}

export type Setting = {
    field: string;
    label: string;
    value?: string | StoreVerificationAddress | boolean;
    encrypted?: boolean;
    encryptedValue?: string;
    error?: string;
};

export type AddressSetting = Omit<Setting, "value"> & {
    value: {
        line1: string;
        line2?: string;
        city: string;
        state: string;
        postalCode: string;
        country: string;
    };
};

/**
 * Note: All types below here are copied from #storeVerifications/domain on server
 */
export enum Statuses {
    notStarted = "notStarted",
    paused = "paused",
    enabled = "enabled",
    deactivated = "deactivated"
}

export const PersonStatusColors = {
    unverified: "#FAAA00",
    pending: "#FAAA00",
    verified: "#3CC92C",
    unknown: "#FF3300"
} as const;

export const PersonStatusColorMap = (
    status?: PersonStatuses
): ValueOf<typeof PersonStatusColors> =>
    match(status)
        .with(PersonStatuses.unverified, () => PersonStatusColors.unverified)
        .with(PersonStatuses.pending, () => PersonStatusColors.pending)
        .with(PersonStatuses.verified, () => PersonStatusColors.verified)
        .otherwise(() => PersonStatusColors.unknown);

export enum StatusReasons {
    default = "default",
    snackpassAdminHold = "snackpassAdminHold",
    ownershipChange = "ownershipChange",
    verificationRequest = "verificationRequest",
    pendingReview = "pendingReview",
    suspectedFraud = "suspectedFraud",
    prohibitedEntity = "prohibitedEntity",
    notSpecified = "notSpecified",
    approved = "approved"
}

export enum PersonStatuses {
    unverified = "unverified",
    pending = "pending",
    verified = "verified",
    unknown = "unknown"
}

export type BusinessTypes =
    | "company"
    | "government_entity"
    | "individual"
    | "non_profit";

export enum StripeFilePurpose {
    accountRequirement = "account_requirement",
    additionalVerification = "additional_verification",
    businessIcon = "business_icon",
    businessLogo = "business_logo",
    customerSignature = "customer_signature",
    disputeEvidence = "dispute_evidence",
    identityDocument = "identity_document",
    pciDocument = "pci_document",
    taxDocumentUserUpload = "tax_document_user_upload",
    terminalReaderSplashscreen = "terminal_reader_splashscreen"
}

type WithTimestamp<T> = T & { createdAt: string; updatedAt: string };

export type VerificationError = {
    requirement: string;
    code: string;
    reason: string;
};

type MimeType = "image/jpeg" | "image/png";

export type StoreVerificationAddress = Required<{
    line1: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
}> &
    Partial<{
        line2: string | null;
        pending: boolean;
    }>;

export type StoreVerificationDocument = {
    name: string;
    purpose: StripeFilePurpose;
    filename: string;
    mimeType: MimeType;
    type: "account" | "company" | "person";
};

/**
 * Person Verification
 */
export type PersonVerification = WithTimestamp<
    Required<{
        id: string;
        verificationId: string;
        connectId: string;
        personId: string;
        firstName: string;
        lastName: string;
        email: string;
        dateOfBirth: number;
        ssnLast4: boolean;
        title: string;
        status: PersonStatuses;
    }> &
        Partial<{
            address: StoreVerificationAddress;
            phone: string;
            idNumber: boolean;
            idNumberType: string;
            idNumberSecondary: boolean;
            idNumberSecondaryType: string;
            percentOwnership: number;
            director: boolean;
            owner: boolean;
            executive: boolean;
            representative: boolean;
            document: StoreVerificationDocument;
            additionalDocument: StoreVerificationDocument;
            disabledReason: string;
            currentDueDate: number;
            currentRequirements: string[];
            verificationErrors: VerificationError[];
            eventualRequirements: string[];
            pastDueRequirements: string[];
            pendingVerification: string[];
            futureDueDate: number;
            futureRequirements: string[];
            futureVerificationErrors: VerificationError[];
            futureEventualRequirements: string[];
            futurePastDueRequirements: string[];
            futurePendingVerification: string[];
        }>
>;

/**
 * Store Verification
 */
export type StoreVerification = WithTimestamp<
    Required<{
        id: string;
        storeId: string;
        connectId: string;
        businessType: BusinessTypes;
        status: Statuses;
        statusReason: StatusReasons;
        representatives: PersonVerification[];
    }> &
        Partial<{
            name: string;
            dba: string;
            structure: string;
            address: StoreVerificationAddress;
            phone: string;
            taxId: boolean;
            merchantCategoryCode: string;
            url: string;
            termsOfServiceIp: string;
            termsOfServiceDate: string;
            bankName: string;
            routingNumber: boolean;
            accountNumber: boolean;
            accountLast4: string;
            ownersProvided: boolean;
            executivesProvided: boolean;
            directorsProvided: boolean;
            document: StoreVerificationDocument;
            adminNote: string | null;
            disabledReason: string;
            currentDueDate: number;
            currentRequirements: string[];
            verificationErrors: VerificationError[];
            eventualRequirements: string[];
            pastDueRequirements: string[];
            pendingVerification: string[];
            futureDueDate: number;
            futureRequirements: string[];
            futureVerificationErrors: VerificationError[];
            futureEventualRequirements: string[];
            futurePastDueRequirements: string[];
            futurePendingVerification: string[];
        }>
>;

/**
 * Decrypted
 */
export type DecryptedPersonVerification = Omit<
    PersonVerification,
    | "ssnLast4"
    | "idNumber"
    | "idNumberSecondary"
    | "document"
    | "additionalDocument"
> &
    Required<{ ssnLast4: string }> &
    Partial<{
        idNumber: string;
        idNumberSecondary: string;
        document: Partial<StoreVerificationDocument>;
        additionalDocument: Partial<StoreVerificationDocument>;
    }>;

export type DecryptedStoreVerification = Omit<
    StoreVerification,
    "representatives" | "taxId" | "routingNumber" | "accountNumber" | "document"
> &
    Required<{ representatives: DecryptedPersonVerification[] }> &
    Partial<{
        taxId: string;
        routingNumber: string;
        accountNumber: string;
        document: StoreVerificationDocument;
    }>;
