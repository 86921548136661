import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import update from "immutability-helper";
import Select, { ValueType } from "react-select";
import { PlusIcon } from "@radix-ui/react-icons";
import produce from "immer";
import { useSelector } from "react-redux";

import Text from "#devices/components/Text";
import { Button } from "src/@/components/ui/button";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import {
    ChannelOption,
    ChannelType
} from "#devices/utils/deviceTypes/SnackTVDevice";
import { ReactComponent as DragHandle } from "src/assets/icons/drag-dots.svg";
import { ReactComponent as DeleteIcon } from "src/assets/icons/delete-red.svg";
import { getActiveStore } from "src/redux/selectors";
import { DndBackendProvider } from "#devices/utils/dndBackendProvider";

import {
    ActionRow,
    ButtonContainer,
    DeleteButton,
    DropdownIndicator,
    draggableSelectStyles,
    HandleContainer,
    IndicatorSeparator,
    SelectWrapper,
    StyledCol,
    StyledRow,
    TableStyles
} from "./styles";
import { DraggableBodyRow } from "./DraggableBodyRow";

interface DataType {
    key: number;
    category: string;
    order: number;
}

type CategoryOption = {
    readonly value: string;
    readonly label: string;
};

let categoryCount = 0;

// https://ant.design/components/table
export const PinnedCategories = (): JSX.Element => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const store = useSelector(getActiveStore);

    const storeCategories = store
        ? store.productCategories.map((category) => ({
              value: category._id,
              label: category.name
          }))
        : [];

    const deviceOptions = (updatedDevice?.deviceDetails?.channelOptions ??
        snackTvDevice?.deviceDetails?.channelOptions ??
        []) as ChannelOption[];

    const channel =
        updatedDevice?.deviceDetails?.channel ??
        snackTvDevice?.deviceDetails?.channel;

    const channelOptions = deviceOptions.find(
        (o: ChannelOption) => o.channel === channel
    );

    const initialData = channelOptions?.options?.pinnedCategories?.map(
        ({ category, order }, key) => ({
            key,
            category,
            order
        })
    );

    useEffect(() => {
        categoryCount += initialData?.length ?? 0;
    }, []);

    const backend = useMemo(() => DndBackendProvider(), []);

    const [tableData, setTableData] = useState<DataType[]>(initialData ?? []);

    const handleTableChange = (data: DataType[]) => {
        const pinnedCategories = data.map((row, order) => ({
            category: row.category,
            order
        }));

        const updatedOptions = produce(deviceOptions, (draft) => {
            if (draft) {
                const channelOption = draft.find((o) => o.channel === channel);
                if (!channelOption) {
                    draft.push({
                        channel: channel as ChannelType,
                        options: {
                            pinnedCategories
                        }
                    });
                } else {
                    channelOption.options.pinnedCategories = pinnedCategories;
                }
            }
        });
        setTableData(data);
        updateDeviceField("channelOptions", updatedOptions);
    };

    const columns: ColumnsType<DataType> = [
        {
            title: "Drag",
            dataIndex: "drag",
            key: "drag",
            width: "5%",
            render: () => (
                <HandleContainer>
                    <DragHandle />
                </HandleContainer>
            )
        },
        {
            title: "Select",
            dataIndex: "select",
            key: "select",
            render: (value: string, record: DataType, index: number) => (
                <CategoryOptionSelect
                    value={{
                        value: record.category,
                        label: record.category
                    }}
                    onChange={(item) => {
                        if (item) {
                            const data = [...tableData];
                            data[index] = {
                                ...data[index],
                                category: item.label
                            };
                            handleTableChange(data);
                        }
                    }}
                />
            )
        },
        {
            title: "Delete",
            dataIndex: "delete",
            key: "delete",
            width: "5%",
            render: (value: string, record: DataType, index: number) => (
                <DeleteButton
                    onClick={() => {
                        const data = [...tableData];
                        data.splice(index, 1);
                        handleTableChange(data);
                    }}
                >
                    <DeleteIcon />
                </DeleteButton>
            )
        }
    ];

    const CategoryOptionSelect: FC<{
        onChange: (value: ValueType<CategoryOption, false>) => void;
        value: CategoryOption;
    }> = ({ onChange, value }) => (
        <SelectWrapper>
            <Select<CategoryOption, false>
                value={value}
                styles={draggableSelectStyles}
                placeholder="Select to pin Category"
                options={storeCategories}
                onChange={onChange}
                components={{
                    DropdownIndicator,
                    IndicatorSeparator
                }}
            />
        </SelectWrapper>
    );

    const moveRow = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragRow = tableData[dragIndex];
            const newTableData = update(tableData, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRow]
                ]
            });
            handleTableChange(newTableData);
        },
        [tableData]
    );

    const components = {
        body: {
            row: DraggableBodyRow
        }
    };

    return (
        <TableStyles>
            <StyledRow>
                <StyledCol>
                    <Text.Label>Pinned Categories</Text.Label>
                    <Text.Body>
                        Only these categories will be displayed
                    </Text.Body>
                </StyledCol>
                <StyledCol>
                    <ActionRow>
                        <ButtonContainer>
                            <Button
                                className="px-2"
                                onClick={() => {
                                    const newData = [...tableData];
                                    newData.push({
                                        key: categoryCount,
                                        category: "",
                                        order: categoryCount
                                    });
                                    categoryCount += 1;
                                    handleTableChange(newData);
                                }}
                            >
                                <PlusIcon className="mr-1 h-5 w-5" />
                                Add Category
                            </Button>
                        </ButtonContainer>
                    </ActionRow>
                </StyledCol>
            </StyledRow>

            <Table
                columns={columns}
                showHeader={false}
                dataSource={tableData}
                pagination={false}
                components={components}
                locale={{
                    emptyText: "No Categories Selected"
                }}
                onRow={(_, index) => {
                    const attr = {
                        index,
                        moveRow
                    };
                    return attr as React.HTMLAttributes<unknown>;
                }}
            />
        </TableStyles>
    );
};
