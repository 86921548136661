import { MouseEvent, CSSProperties } from "react";
import { Addon, IProduct } from "@snackpass/snackpass-types";
import { Button, Col, Row } from "antd";
import classNames from "classnames";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

import { IProductCategoryWithProducts } from "#menu-editor/mobile-friendly/helpers/context";
import {
    isAddonGroup,
    isCategory,
    isProduct
} from "#menu-editor/multi-menus/helpers";
import { multiMenuThunks } from "#menu-editor/multi-menus/redux/thunks";
import { ReactComponent as ChevronDown } from "src/assets/icons/chevron-down.svg";
import constants from "#core/constants";
import { Text } from "#menu-editor/multi-menus/styled-components/text";
import { commonStyles } from "#menu-editor/multi-menus/helpers/styles";
import { useAppDispatch } from "src/redux/hooks";
import {
    openNotification,
    NotificationPosition
} from "#menu-editor/multi-menus/shared-components/notification";
import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import { getCurrentMultiMenuId } from "#menu-editor/multi-menus/redux/selectors";
import { logAndSendError } from "src/utils/errors";

import { ProductDropdown } from "./product-dropdown";

const stopPropagation = (e: MouseEvent<HTMLDivElement>) => e.stopPropagation();

type ActionsCellProps = {
    row: {
        isExpanded: boolean;
        canExpand: boolean;
        getToggleRowExpandedProps: Function;
        original: IProductCategoryWithProducts | IProduct | Addon;
    };
    overrideCellStates: Record<string, { price: boolean; taxes: boolean }>;
    togglePriceOverrideCell: () => void;
    toggleTaxesOverrideCell: () => void;
};
export const ActionsCell: React.FC<ActionsCellProps> = ({
    row,
    overrideCellStates,
    togglePriceOverrideCell,
    toggleTaxesOverrideCell
}) => {
    const dispatch = useAppDispatch();
    const currentMenuId = useSelector(getCurrentMultiMenuId);

    const { isExpanded, canExpand, getToggleRowExpandedProps } = row;

    const isMobile = useMediaQuery({
        query: `(max-width: ${constants.MOBILE_MAX_WIDTH}px)`
    });

    const inDisplayMode = !(
        overrideCellStates?.[row.original._id]?.price ||
        overrideCellStates?.[row.original._id]?.taxes
    );

    if (inDisplayMode || isCategory(row.original))
        return (
            <Row align="middle" justify="end" gutter={8}>
                {!isMobile && isProduct(row.original) ? (
                    <ProductDropdown item={row.original} />
                ) : null}
                {canExpand && isMobile && isCategory(row.original) ? (
                    <Col>
                        <Text type="body-regular">
                            {`${
                                isAddonGroup(row.original)
                                    ? row.original.addons.length
                                    : row.original.products.length
                            }`}
                        </Text>
                    </Col>
                ) : null}
                <Col>
                    <ExpanderIconWrapper
                        {...row.getToggleRowExpandedProps()}
                        canExpand={canExpand}
                    >
                        <ExpanderIconStyles isExpanded={isExpanded}>
                            <ChevronDown
                                className={classNames({
                                    visible: canExpand,
                                    invisible: !canExpand
                                })}
                            />
                        </ExpanderIconStyles>
                    </ExpanderIconWrapper>
                </Col>
            </Row>
        );
    else
        return (
            <Row
                justify="end"
                align="middle"
                style={{
                    ...styles.actionCellStyles,
                    ...commonStyles.fillParentHeight
                }}
                // stop OnClickAwayListener from closing edit mode
                onClick={stopPropagation}
            >
                <DoneButton
                    type="primary"
                    shape="round"
                    onClick={async () => {
                        if (overrideCellStates[row.original._id].price)
                            togglePriceOverrideCell();

                        if (overrideCellStates[row.original._id].taxes)
                            toggleTaxesOverrideCell();

                        dispatch(
                            multiMenuThunks.updateOverrides({
                                itemId: row.original._id,
                                itemType: isProduct(row.original)
                                    ? "products"
                                    : "addons"
                            })
                        )
                            .unwrap()
                            .catch((err) => {
                                logAndSendError(err);
                                dispatch(
                                    multiMenuActions.selectMultiMenu({
                                        id: currentMenuId
                                    })
                                );
                                openNotification({
                                    message: "Failed to update menu",
                                    description:
                                        "Failed to update current menu overrides, please try again later.",
                                    position: NotificationPosition.TopCenter
                                });
                            });
                    }}
                >
                    Done
                </DoneButton>
            </Row>
        );
};

const DoneButton = styled(Button)`
    background: #0077ff;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
`;

const ExpanderIconWrapper = styled.span<{ canExpand: boolean }>`
    padding: 6px;
    border-radius: 56px;

    margin-right: 20px;

    &:hover {
        box-shadow: ${({ canExpand }) =>
            canExpand
                ? "inset 999px 4px 0px rgba(241, 245, 248, 0.5)"
                : undefined};
    }
`;

// Trying to style ChevronIcon component directly causes the app tests to fail :|
const ExpanderIconStyles = styled.span<{ isExpanded: boolean }>`
    > svg {
        transition: 0.3s;
        transform: ${({ isExpanded }) =>
            isExpanded ? undefined : "rotate(-0.25turn)"};
    }
`;

const styles = {
    actionCellStyles: { paddingRight: "20px" } as CSSProperties
};
