import moment from "moment-timezone";
import {
    PurchaseSummaryData,
    SnackpassTimezoneEnum
} from "@snackpass/snackpass-types";

export const getPresentableData = (
    summaries: PurchaseSummaryData[],
    timezone: "" | SnackpassTimezoneEnum
) =>
    summaries.map((summary) => {
        const isDayGranular = summary.granularity === "day";
        const isActualPayout = summary.actualPayout !== undefined;
        return {
            [isDayGranular ? "Date" : "Start Date"]: moment(summary.date)
                .tz(timezone)
                .format("MM/DD/YY"),
            ...(!isDayGranular && {
                "End Date": moment(summary.endDate)
                    .tz(timezone)
                    .format("MM/DD/YY")
            }),
            Orders: summary.count,
            "Net Sales": summary.netSales,
            Tips: summary.tip,
            "Taxes You Owe": summary.storeTaxesToRemit,
            "Taxes Remitted On Your Behalf": summary.storeTaxesWithheld,
            "Processing Fees": summary.processingFee,
            "Snackpass Fees": summary.snackpassFees,
            "Customer to Snackpass Fees": summary.customerToSnackpassFees,
            "Estimated Payout": summary.expectedPayout,
            "Expected Cash Collected": summary.expectedCashCollected,
            "Store Credit": summary.storeCredit,
            "Gift Card Credit": summary.giftCardCredit,
            ...(isActualPayout && { "Actual Payout": summary.actualPayout })
        };
    });
