import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";

export const useGetAuditLogs = () => {
    const store = useSelector(getActiveStore);

    const { data, isFetching, error, refetch } = useQuery({
        queryKey: [`${store?._id}_guestbook_audit_log`],
        queryFn: async () => {
            if (!store) return {};
            try {
                const response = await api.punchCards.getStorePunchcardChanges(
                    store?._id ?? ""
                );
                return response.data.changes;
            } catch (err) {
                console.error(err);
            }
            return [];
        }
    });

    return {
        data,
        isFetching,
        error,
        refetch
    };
};
