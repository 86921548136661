import React from "react";
import { useSelector } from "react-redux";

import { getUser } from "src/redux/selectors";

export const NotAdminPage = () => {
    const user = useSelector(getUser);
    const userIdentifier = user?.email || user?.number || "No user";

    return (
        <div className="mt-8 flex w-full flex-col items-center">
            <div>You do not have access to this page.</div>
            <div>Please contact Snackpass for further assistance.</div>
            <div>You are logged in as: {userIdentifier}</div>
        </div>
    );
};
