import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SnackpassTimezoneEnum } from "@snackpass/snackpass-types";

import { getStoreTimezone } from "#utils/helpers";

import { getActiveStore } from "../../redux/selectors";

/**
 * manages the state of the store and returns its timezone so you don't have to
 * NOTE: this defaults to eastern time if the store is not present during
 * mount
 */
export const useActiveStoreTimezone = () => {
    const activeStore = useSelector(getActiveStore);
    const [timezone, setTimezone] = useState<string>(
        activeStore?._id
            ? getStoreTimezone(activeStore)
            : /** TODO: this is a temporary work around that is only possible
             because of the fact we tend to attempt and load content
             before the store has been fetched. This requires a systematic
             reworking of how we do state management. Or how we provide
             loading cushion to this script before its running. A offset
             of 0 is eastern time.
             */
              SnackpassTimezoneEnum.newYork
    );

    useEffect(() => {
        if (activeStore?._id) {
            setTimezone(getStoreTimezone(activeStore));
        }
    }, [activeStore?._id]);

    return timezone;
};
