// Remove any non-digit characters
export const phoneDigitsOnly = (value?: string) => value?.replace(/\D/g, "");

export const formatPhone = (value?: string) => {
    if (!value) return value;
    const digits = phoneDigitsOnly(value) ?? "";
    const formatted = digits.replace(
        /(\d{1})(\d{3})(\d{3})(\d{4})/,
        "+$1 ($2) $3-$4"
    );
    return formatted;
};

export const formatPhoneShort = (value?: string) => {
    if (!value) return value;
    const digits = phoneDigitsOnly(value) ?? "";
    return digits.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};
