import { useSelector } from "react-redux";
import React, {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
    PlusCircledIcon,
    TrashIcon,
    CopyIcon,
    ReloadIcon,
    ExclamationTriangleIcon
} from "@radix-ui/react-icons";
import { parenthesizeNumber } from "#settings/settings-business-info/utils/helpers";

import { parsePhoneNumber } from "libphonenumber-js";
import { getActiveStore, useActiveUser } from "src/redux/selectors";
import { useAppDispatch } from "src/redux/hooks";
import { Button } from "src/@/components/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage
} from "src/@/components/ui/form";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "src/@/components/ui/select";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle
} from "src/@/components/ui/card";
import {
    IStore,
    ITimeRangeSchema,
    SnackpassTimezoneEnum
} from "@snackpass/snackpass-types";
import _ from "lodash";
import { toast } from "sonner";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
    geocodeByPlaceId
} from "react-places-autocomplete";

import { parseCrossDayRanges } from "#reusable/special-hours/helper";
import api from "src/api/rest";
import { setActiveStore } from "src/redux/slices";
import { formatPhoneNumber } from "#settings/helper";
import { sendError } from "src/utils/errors";
import {
    RegularHours,
    SpecialHours
} from "#settings/settings-business-info/types";
import { useHasEditSettingsForActiveStore } from "#hooks/use-has-edit-settings-for-active-store";
import {
    dateTimeRangeToSpecialHours,
    formatRegularHoursForRequest,
    formatSpecialHoursForRequest,
    timeRangeToRegularHours
} from "#settings/settings-business-info/utils/hours";
import { splitMultipleEmails } from "#settings/settings-business-info/utils/email";
import {
    getAddress,
    normalizeAddress
} from "#settings/settings-business-info/utils/address";
import { DateTime } from "luxon";
import {
    RegularHoursSchema,
    SpecialHoursSchema,
    IAddressSchema,
    GeolocationSchema,
    StoreKindSchema
} from "#settings/settings-business-info/schema";
import { StoreKind, StoreKindDisplay } from "@snackpass/snackpass-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Input } from "src/@/components/ui/input";

import { timezoneOptions } from "#onboarding/utils/timezones";
import { Alert, AlertDescription } from "src/@/components/ui/alert";
import { RegularHoursDatatable } from "#settings/settings-business-info/components/regular-hours-datatable";
import { SpecialHoursDatatable } from "#settings/settings-business-info/components/special-hours-datatable";
import { format } from "date-fns";
import { useUnsavedChangesPrompt } from "#settings/hooks/useUnsavedChangesPrompt";
import { UnsavedChangesModal } from "#settings/components/unsaved-changes";

type AddressType = {
    line1?: string | undefined;
    line2?: string | null | undefined;
    city?: string | undefined;
    county?: string | null | undefined;
    state?: string | undefined;
    zip?: string | undefined;
    country?: string | undefined;
    full?: string | undefined;
};

const businessSchema = z
    .object({
        name: z.string(),
        address: z.string().nullable().optional(),
        phoneNumber: z.string().min(1, "Phone number required"),
        email: z.array(z.string()),
        local: z.array(RegularHoursSchema),
        special: z.array(SpecialHoursSchema),
        addressComponents: IAddressSchema.nullable().optional(),
        timezone: z.string(),
        geolocation: GeolocationSchema.nullable(),
        kind: StoreKindSchema.nullable(),
        isAddressValid: z.boolean()
    })
    .refine((data) => data.email.length > 0, {
        message: "At least one email is required",
        path: ["email"]
    })
    .refine(
        (data) =>
            data.email.every(
                (email) => z.string().email().safeParse(email).success
            ),
        {
            message: "One or more emails are invalid",
            path: ["email"]
        }
    )
    .refine(
        (data) => {
            try {
                const phone = parsePhoneNumber(data.phoneNumber || "", "US");
                return phone !== undefined && phone.isValid();
            } catch (err) {
                sendError(err);
            }
            // if there is no value, it is fine because the phone number wasn't filled out
            return !data.phoneNumber;
        },
        {
            message: "Phone number invalid",
            path: ["phoneNumber"]
        }
    )
    .refine(
        (data) => {
            const groupedByWeekday = _.groupBy(data.local, "weekday");

            for (const ranges of Object.values(groupedByWeekday)) {
                const sortedRanges = ranges.sort(
                    (a, b) => a.range.start - b.range.start
                );

                // check for overlapping ranges
                for (let i = 1; i < sortedRanges.length; i++) {
                    const prevRange = sortedRanges[i - 1].range;
                    const currentRange = sortedRanges[i].range;
                    if (prevRange.end > currentRange.start) {
                        return false;
                    }
                }
            }

            return true;
        },
        {
            message: "Store hours cannot overlap for the same day",
            path: ["local"]
        }
    )
    .refine(
        (data) => {
            const groupedByDate = _.groupBy(data.special, (hour) =>
                format(hour.date, "PPP")
            );

            for (const ranges of Object.values(groupedByDate)) {
                const sortedRanges = ranges.sort(
                    (a, b) => a.range.start - b.range.start
                );

                // check for overlapping ranges
                for (let i = 1; i < sortedRanges.length; i++) {
                    const prevRange = sortedRanges[i - 1].range;
                    const currentRange = sortedRanges[i].range;
                    if (prevRange.end > currentRange.start) {
                        return false;
                    }
                }

                // check isClosed consistency
                const closedStatuses = new Set(
                    sortedRanges.map((range) => range.isClosed)
                );
                if (closedStatuses.size !== 1) {
                    return false;
                }
            }

            return true;
        },
        {
            message:
                "Store hours cannot overlap or be simultaneously closed and open on the same day",
            path: ["special"]
        }
    );

const defaultValues = {
    name: "",
    address: "",
    phoneNumber: "",
    email: [],
    local: [],
    addressComponents: {
        line1: "",
        line2: "",
        city: "",
        county: "",
        state: "",
        country: "USA",
        zip: "",
        full: ""
    },
    timezone: SnackpassTimezoneEnum.newYork,
    geolocation: null,
    special: [],
    kind: null,
    isAddressValid: true
};

const resolveCrossDayRanges = (hours: ITimeRangeSchema[]): ITimeRangeSchema[] =>
    hours.flatMap(parseCrossDayRanges);

const emitLog = (store: IStore) => {
    const logMessage = `Store hours 🕘 modified to:\n${store?.hoursDescription}`;
    void api.logs.send({
        channel: "logs-ops",
        title: `🔔 ${store.name} in ${store.region}`,
        description: logMessage
    });
};

const SettingsBusinessInfoScreen = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [sessionToken, setSessionToken] = useState(
        new google.maps.places.AutocompleteSessionToken()
    );
    const canEdit = useHasEditSettingsForActiveStore();
    const dispatch = useAppDispatch();
    const activeStore = useSelector(getActiveStore);
    const user = useActiveUser();
    const canSeeStoreKind =
        user?.snackpassPermissions.isAdmin &&
        user?.snackpassPermissions.isSnackpassEmployee;

    const storeKindOptions = useMemo(
        () =>
            Object.keys(StoreKindDisplay).map((t) => ({
                value: t,
                label: StoreKindDisplay[t as StoreKind] // Add index signature to allow indexing with a string
            })),
        []
    );

    const timeout: MutableRefObject<NodeJS.Timeout | null> = useRef(null);

    const form = useForm<z.infer<typeof businessSchema>>({
        resolver: zodResolver(businessSchema),
        defaultValues: defaultValues
    });

    // @ts-expect-error - zod refinement ensures address is not null
    const initialValue: z.infer<typeof businessSchema> = useMemo(() => {
        if (!activeStore) {
            return defaultValues;
        }

        let local: RegularHours[];

        if (activeStore.hours.local.length > 0) {
            const resolvedLocalRanges = resolveCrossDayRanges(
                activeStore.hours.local
            );

            local = timeRangeToRegularHours(
                resolvedLocalRanges,
                activeStore.hours.zone
            );
        }

        let special: SpecialHours[] = [];

        if (
            activeStore.hours.special !== undefined &&
            activeStore.hours.special.length > 0
        ) {
            special = dateTimeRangeToSpecialHours(
                activeStore.hours.special,
                DateTime.local().zone.name
            );
        }

        return {
            name: activeStore.name || "",
            initialStoreName: activeStore.name || "",
            address: activeStore.address || "",
            email: activeStore.email
                ? splitMultipleEmails(activeStore.email)
                : [],
            phoneNumber: formatPhoneNumber(activeStore.phoneNumber) || "",
            // @ts-expect-error - local is clearly defined above
            local,
            special,
            addressComponents:
                (activeStore?.addressComponents &&
                    activeStore.addressComponents) ??
                defaultValues.addressComponents,
            timezone: activeStore.hours.zone ?? SnackpassTimezoneEnum.newYork,
            geolocation: activeStore.geolocation,
            kind: activeStore.kind ?? null,
            isAddressValid: true
        };
    }, [activeStore]);

    const onSubmit = async (values: z.infer<typeof businessSchema>) => {
        if (!activeStore || !canEdit) return;
        try {
            setIsLoading(true);
            const local = formatRegularHoursForRequest(values.local);
            const special = formatSpecialHoursForRequest(
                values.special,
                DateTime.local().zone.name
            );
            if (!values.isAddressValid) {
                toast.warning("Address hasn't been verified.");
            }
            const { data } = await api.stores.update(activeStore._id, {
                name: values.name,
                address: getAddress(
                    values.address,
                    values.addressComponents,
                    activeStore
                ),
                addressComponents: values.addressComponents,
                email:
                    values.email && values.email.length > 0
                        ? values.email.join()
                        : null,
                phoneNumber: values.phoneNumber,
                hours: {
                    zone: values.timezone,
                    local: local,
                    special: special
                },
                geolocation: values.geolocation,
                kind: values.kind
            });
            if (!_.isEqual(activeStore.hours.local, values.local)) {
                emitLog(activeStore);
            }
            dispatch(setActiveStore(data.store));
            toast.success("Store updated successfully");
        } catch (e) {
            sendError(e);
            toast.error("Cannot update the store, please try again later");
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = useCallback(() => {
        form.reset(initialValue);
    }, [form, initialValue]);

    useEffect(() => {
        if (activeStore) {
            resetForm();
        }
    }, [activeStore, form.reset, resetForm]);

    const onAddressSelect = useCallback(
        (address: string, placeId: string) => {
            const line2 = form.watch("addressComponents")?.line2 ?? "";
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            timeout.current = setTimeout(async () => {
                try {
                    form.setValue("isAddressValid", true);
                    const results = await geocodeByAddress(address);
                    const latLng = await getLatLng(results[0]);
                    const [place] = await geocodeByPlaceId(placeId);

                    const addressObj = {
                        ...normalizeAddress(
                            place.address_components ?? [],
                            address
                        ),
                        line2,
                        full: place.formatted_address
                    };

                    // @ts-expect-error - zod refinement ensures address is not null
                    form.setValue("addressComponents", addressObj);
                    form.setValue("address", addressObj.full);
                    form.setValue("geolocation", {
                        type: "Point",
                        coordinates: [latLng.lng, latLng.lat]
                    });
                } catch (error) {
                    // @ts-expect-error - error message is a string
                    toast.error(error.message);
                    form.setValue("geolocation", null);
                    form.setValue("isAddressValid", false);
                }
            }, 1000);
            setSessionToken(new google.maps.places.AutocompleteSessionToken());
        },
        [form, timeout, setSessionToken]
    );

    const manualAddressChange = useCallback(
        (
            fieldValue: string,
            fieldName: keyof AddressType,
            field: {
                value: AddressType | null | undefined;
                onChange: (val: AddressType) => void;
            }
        ) => {
            field.onChange({
                ...field.value,
                [fieldName]: fieldValue
            });
            if (fieldName !== "line2") {
                form.setValue("isAddressValid", false);
            }
        },
        [form]
    );

    const changeEmail = (
        email: string,
        index: number,
        field: { value: string[]; onChange: (emails: string[]) => void }
    ) => {
        field.onChange([
            ...field.value.slice(0, index),
            email,
            ...field.value.slice(index + 1)
        ]);
    };

    const deleteEmail = (
        index: number,
        field: { value: string[]; onChange: (emails: string[]) => void }
    ) => {
        field.onChange(field.value?.filter((_, idx) => idx !== index));
    };

    const addEmail = (field: {
        value: string[];
        onChange: (emails: string[]) => void;
    }) => {
        field.onChange([...(field.value ?? []), ""]);
    };

    const {
        showModal,
        handleConfirmNavigationClick,
        handleCancelNavigationClick
    } = useUnsavedChangesPrompt(form.formState.isDirty);

    return (
        <div className="h-max overflow-auto bg-gray-50">
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="mb-24 space-y-8 p-8 sm:p-16"
                >
                    <UnsavedChangesModal
                        show={showModal}
                        onConfirm={handleConfirmNavigationClick}
                        onCancel={handleCancelNavigationClick}
                    />
                    <div>
                        <CardTitle className="text-2xl font-bold">
                            Business Info
                        </CardTitle>
                        <div className="text-gray-600">
                            Manage the information you display about your store.
                        </div>
                        <hr className="border-gray-300" />
                    </div>

                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Store ID
                            </CardTitle>
                            <CardDescription>
                                This is your store’s ID on Snackpass. Support
                                may ask for it when troubleshooting.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className="flex">
                                <Input
                                    value={activeStore?._id}
                                    disabled
                                    className="max-w-60"
                                />
                                <div className="pl-4">
                                    <CopyToClipboard
                                        text={`${activeStore?._id}`}
                                    >
                                        <Button
                                            type="button"
                                            variant={"outline"}
                                            onClick={() => {
                                                toast.success("Copied");
                                            }}
                                        >
                                            <CopyIcon />
                                        </Button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Location
                            </CardTitle>
                            <CardDescription>
                                This is how your store will appear to customers.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Store Name
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                How you want your store to
                                                appear to your customers.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="flex pt-4">
                                                    <Input
                                                        value={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        placeholder="Snackpass Restaurant (New York, 123 Main St)"
                                                        disabled={!canEdit}
                                                    />
                                                    <div className="pl-4">
                                                        <CopyToClipboard
                                                            text={`${field.value}`}
                                                        >
                                                            <Button
                                                                type="button"
                                                                variant={
                                                                    "outline"
                                                                }
                                                                onClick={() => {
                                                                    toast.success(
                                                                        "Copied"
                                                                    );
                                                                }}
                                                            >
                                                                <CopyIcon />
                                                            </Button>
                                                        </CopyToClipboard>
                                                    </div>
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="addressComponents"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Store Address
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Customers will see this address
                                                on your ordering and app page.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <PlacesAutocomplete
                                                        value={
                                                            field.value?.line1
                                                                ? field.value
                                                                      .line1
                                                                : ""
                                                        }
                                                        onChange={(address) => {
                                                            manualAddressChange(
                                                                address,
                                                                "line1",
                                                                field
                                                            );
                                                        }}
                                                        onSelect={(
                                                            address: string,
                                                            placeId: string
                                                        ) => {
                                                            onAddressSelect(
                                                                address,
                                                                placeId
                                                            );
                                                        }}
                                                        searchOptions={{
                                                            componentRestrictions:
                                                                {
                                                                    country:
                                                                        "us"
                                                                },
                                                            sessionToken
                                                        }}
                                                    >
                                                        {({
                                                            getInputProps,
                                                            suggestions,
                                                            getSuggestionItemProps,
                                                            loading
                                                        }) => (
                                                            <>
                                                                <Input
                                                                    {...getInputProps(
                                                                        {
                                                                            placeholder:
                                                                                "Enter Address...",
                                                                            disabled:
                                                                                !canEdit
                                                                        }
                                                                    )}
                                                                />
                                                                <div className="relative w-full">
                                                                    <div className="absolute inset-x-0 top-0 z-[1000] w-full">
                                                                        {loading && (
                                                                            <div className="bg-white">
                                                                                Loading...
                                                                            </div>
                                                                        )}
                                                                        {suggestions.map(
                                                                            (
                                                                                suggestion
                                                                            ) => {
                                                                                const addProps =
                                                                                    suggestion.active
                                                                                        ? {
                                                                                              className:
                                                                                                  "suggestion-item--active",
                                                                                              style: {
                                                                                                  backgroundColor:
                                                                                                      "#fafafa",
                                                                                                  cursor: "pointer"
                                                                                              }
                                                                                          }
                                                                                        : {
                                                                                              className:
                                                                                                  "suggestion-item",
                                                                                              style: {
                                                                                                  backgroundColor:
                                                                                                      "#ffffff",
                                                                                                  cursor: "pointer"
                                                                                              }
                                                                                          };
                                                                                return (
                                                                                    <div
                                                                                        {...getSuggestionItemProps(
                                                                                            suggestion,
                                                                                            addProps
                                                                                        )}
                                                                                        className="border border-neutral-200 pl-3 text-base"
                                                                                        key={
                                                                                            suggestion.placeId
                                                                                        }
                                                                                    >
                                                                                        <CardDescription>
                                                                                            {
                                                                                                suggestion.description
                                                                                            }
                                                                                        </CardDescription>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </PlacesAutocomplete>
                                                    <div className="pt-4">
                                                        <Input
                                                            value={
                                                                field.value
                                                                    ?.line2
                                                                    ? field
                                                                          .value
                                                                          .line2
                                                                    : ""
                                                            }
                                                            onChange={(e) => {
                                                                manualAddressChange(
                                                                    e.target
                                                                        .value,
                                                                    "line2",
                                                                    field
                                                                );
                                                            }}
                                                            placeholder="Line 2"
                                                            disabled={!canEdit}
                                                        />
                                                    </div>
                                                    <div className="pt-4">
                                                        <Input
                                                            value={
                                                                field.value
                                                                    ?.city
                                                                    ? field
                                                                          .value
                                                                          .city
                                                                    : ""
                                                            }
                                                            onChange={(e) => {
                                                                manualAddressChange(
                                                                    e.target
                                                                        .value,
                                                                    "city",
                                                                    field
                                                                );
                                                            }}
                                                            placeholder="City"
                                                        />
                                                    </div>
                                                    <div className="flex items-center space-x-4 pt-4">
                                                        <Input
                                                            value={
                                                                field.value
                                                                    ?.state
                                                                    ? field
                                                                          .value
                                                                          .state
                                                                    : ""
                                                            }
                                                            placeholder="State"
                                                            onChange={(e) => {
                                                                manualAddressChange(
                                                                    e.target
                                                                        .value,
                                                                    "state",
                                                                    field
                                                                );
                                                            }}
                                                        />
                                                        <Input
                                                            value={
                                                                field.value?.zip
                                                                    ? field
                                                                          .value
                                                                          .zip
                                                                    : ""
                                                            }
                                                            placeholder="Zip Code"
                                                            onChange={(e) => {
                                                                manualAddressChange(
                                                                    e.target
                                                                        .value,
                                                                    "zip",
                                                                    field
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="phoneNumber"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Store Phone Number
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Customers will be able to
                                                contact this number for help
                                                with their order.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <Input
                                                        className="max-w-60"
                                                        value={
                                                            field.value
                                                                ? parenthesizeNumber(
                                                                      field.value
                                                                  )
                                                                : ""
                                                        }
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        placeholder="(123) 456-7890"
                                                        disabled={!canEdit}
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="email"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Store Email Address
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                This email will be used for
                                                account information including
                                                subscriptions, billing and tax
                                                purposes.
                                            </CardDescription>
                                            <FormControl>
                                                <div className="pt-4">
                                                    {field.value?.length > 0
                                                        ? field.value.map(
                                                              (
                                                                  value,
                                                                  index
                                                              ) => (
                                                                  <div
                                                                      className="flex max-w-sm items-center space-x-2 pt-2"
                                                                      key={
                                                                          index
                                                                      }
                                                                  >
                                                                      <Input
                                                                          className="max-w-60"
                                                                          value={
                                                                              value
                                                                          }
                                                                          onChange={(
                                                                              e
                                                                          ) => {
                                                                              changeEmail(
                                                                                  e
                                                                                      .target
                                                                                      .value,
                                                                                  index,
                                                                                  field
                                                                              );
                                                                          }}
                                                                          placeholder="example@gmail.com"
                                                                          disabled={
                                                                              !canEdit
                                                                          }
                                                                      />
                                                                      <div className="pl-2">
                                                                          <Button
                                                                              type="button"
                                                                              variant="outline"
                                                                              onClick={() => {
                                                                                  deleteEmail(
                                                                                      index,
                                                                                      field
                                                                                  );
                                                                              }}
                                                                          >
                                                                              <TrashIcon className="mr-1 h-4 w-4" />{" "}
                                                                              Delete
                                                                          </Button>
                                                                      </div>
                                                                  </div>
                                                              )
                                                          )
                                                        : null}
                                                    <div className="flex justify-between pt-4">
                                                        <Button
                                                            type="button"
                                                            onClick={() => {
                                                                addEmail(field);
                                                            }}
                                                        >
                                                            <PlusCircledIcon className="mr-1 h-4 w-4" />{" "}
                                                            Add Email
                                                        </Button>
                                                    </div>
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            {canSeeStoreKind && (
                                <FormField
                                    control={form.control}
                                    name="kind"
                                    render={({ field }) => (
                                        <FormItem>
                                            <hr />

                                            <div>
                                                <CardTitle className="text-base font-medium">
                                                    Store Type
                                                </CardTitle>
                                                <CardDescription className="pt-0.5">
                                                    Only visible to Snackpass
                                                    employees.
                                                </CardDescription>
                                                <FormControl>
                                                    <div className="pt-4">
                                                        <Select
                                                            value={
                                                                field.value !==
                                                                null
                                                                    ? field.value
                                                                    : undefined
                                                            }
                                                            onValueChange={
                                                                field.onChange
                                                            }
                                                        >
                                                            <SelectTrigger className="max-w-60">
                                                                <SelectValue placeholder="Restaurant Type" />
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                {storeKindOptions.map(
                                                                    (
                                                                        option
                                                                    ) => (
                                                                        <SelectItem
                                                                            key={
                                                                                option.value
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                        >
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </SelectItem>
                                                                    )
                                                                )}
                                                            </SelectContent>
                                                        </Select>
                                                    </div>
                                                </FormControl>
                                                <FormMessage />
                                            </div>
                                        </FormItem>
                                    )}
                                />
                            )}
                        </CardContent>
                    </Card>
                    <Card className="max-w-4xl border-neutral-400">
                        <CardHeader>
                            <CardTitle className="text-large">
                                Store Hours
                            </CardTitle>
                            <CardDescription>
                                This is when your store is open to accept
                                orders.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <FormField
                                control={form.control}
                                name="timezone"
                                render={({ field }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Store Timezone
                                            </CardTitle>
                                            <FormControl>
                                                <div className="pt-4">
                                                    <Select
                                                        value={field.value}
                                                        onValueChange={
                                                            field.onChange
                                                        }
                                                        disabled={!canEdit}
                                                    >
                                                        <SelectTrigger className="max-w-60">
                                                            <SelectValue placeholder="Select Timezone" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            {timezoneOptions.map(
                                                                (option) => (
                                                                    <SelectItem
                                                                        key={
                                                                            option.value
                                                                        }
                                                                        value={
                                                                            option.value
                                                                        }
                                                                    >
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </SelectItem>
                                                                )
                                                            )}
                                                        </SelectContent>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="local"
                                render={({ field: { value, onChange } }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Regular Hours
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                Let customers know when you’re
                                                open.
                                            </CardDescription>
                                            <FormControl>
                                                <div>
                                                    <RegularHoursDatatable
                                                        value={value}
                                                        onChange={onChange}
                                                        disabled={!canEdit}
                                                    />
                                                </div>
                                            </FormControl>
                                        </div>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <hr />
                            <FormField
                                control={form.control}
                                name="special"
                                render={({ field: { value, onChange } }) => (
                                    <FormItem>
                                        <div>
                                            <CardTitle className="text-base font-medium">
                                                Special Hours
                                            </CardTitle>
                                            <CardDescription className="pt-0.5">
                                                These hours will override
                                                regular hours.
                                            </CardDescription>
                                            <FormControl>
                                                <div>
                                                    <SpecialHoursDatatable
                                                        value={value}
                                                        onChange={onChange}
                                                        disabled={!canEdit}
                                                    />
                                                </div>
                                            </FormControl>
                                        </div>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <br />
                            <Alert variant="warning">
                                <AlertDescription className="text-sm">
                                    <ExclamationTriangleIcon className="mr-2" />
                                    Special hours do not cancel currently
                                    scheduled orders or override third party
                                    delivery hours. You may cancel scheduled
                                    orders from your register. To add holiday
                                    hours to your third party delivery menu,
                                    please contact our support team.
                                </AlertDescription>
                            </Alert>
                        </CardContent>
                    </Card>
                    {form.formState.isDirty ? (
                        <div className="fixed bottom-0 left-0 flex w-full flex-row items-center justify-end gap-4 border-t bg-gray-50 p-2">
                            <Button
                                type="button"
                                variant={"outline"}
                                onClick={resetForm}
                            >
                                Cancel
                            </Button>
                            <Button disabled={isLoading} type="submit">
                                {isLoading && (
                                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                Submit
                            </Button>
                        </div>
                    ) : null}
                </form>
            </Form>
        </div>
    );
};

export default SettingsBusinessInfoScreen;
