import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";

import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { GiftCardBalanceReportRow } from "#reports/gift-card-balances/types";
import { formatCentsAsDollars } from "#reports/gift-card-purchases/lib";

const PHYSICAL_GIFT_CARD = "PHYSICAL_GIFT_CARD";
export const SKIPPED_PHONE = "skippedphone@snackpass.co";

export const formatDate = (date: string) =>
    DateTime.fromISO(date).isValid
        ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
        : "-";
const mapStatus = (status: string) => {
    switch (status) {
        case "REDEEMED":
            return "Redeemed";
        case "OUT_OF_CREDIT":
            return "Out of credit";
        case "INACTIVE":
            return "Inactive";
        case "REFUNDED":
            return "Refunded";
        case "SENT":
            return "Sent";
        case "ACTIVE":
            return "Active";
        default:
            return "-";
    }
};

export const sortDates = (a: string, b: string) => {
    const aDate = DateTime.fromISO(a);
    const bDate = DateTime.fromISO(b);
    if (!aDate.isValid) {
        return -1;
    }
    if (!bDate.isValid) {
        return 1;
    }
    return aDate.diff(bDate).as("milliseconds");
};

export const columns: ColumnDef<GiftCardBalanceReportRow>[] = [
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="mx-2 my-3"
                column={column}
                title="Store Name"
            />
        ),
        id: "Store Name",
        accessorKey: "storeName",
        accessorFn: (row) => row.storeName,
        cell: (info) => (
            <div className="my-4 ml-2">
                <div className="font-bold">{info.getValue() as string}</div>
            </div>
        ),
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="Purchase Date"
            />
        ),
        accessorFn: (e) => formatDate(e.purchaseDate),
        id: "Purchase Date",
        sortingFn: (a, b) =>
            sortDates(a.original.purchaseDate, b.original.purchaseDate),
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className="w-28"
                column={column}
                title="Gift Card Type"
            />
        ),
        accessorFn: (e) => e.type,
        id: "Gift Card Type",
        enableSorting: false,
        cell: ({ getValue }) => (
            <div className="my-4 ml-2">
                <div>
                    {getValue() === PHYSICAL_GIFT_CARD ? "Physical" : "Digital"}
                </div>
            </div>
        ),
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader className="" column={column} title="Code" />
        ),
        accessorFn: (e) => e.code,
        id: "Code",
        enableHiding: false,
        enableSorting: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="First Use Date"
            />
        ),
        accessorFn: (e) => formatDate(e.firstUseDate),
        id: "First Use Date",
        sortingFn: (a, b) =>
            sortDates(a.original.firstUseDate, b.original.firstUseDate),
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="Last Use Date"
            />
        ),
        accessorFn: (e) => formatDate(e.lastUseDate),
        id: "Last Use Date",
        sortingFn: (a, b) =>
            sortDates(a.original.lastUseDate, b.original.lastUseDate),
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="Purchase Amount"
            />
        ),
        accessorFn: (e) => formatCentsAsDollars(e.maxCreditCents),
        id: "Purchase Amount",
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="Current Balance"
            />
        ),
        accessorFn: (e) => formatCentsAsDollars(e.remainingCreditCents),
        id: "Current Balance",
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader className="" column={column} title="From" />
        ),
        accessorFn: (e) => e.fromName,
        id: "From"
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="From Email"
            />
        ),
        accessorFn: (e) => e.fromEmail,
        id: "From Email",
        cell: ({ getValue, row }) => (
            <div className="my-4 ml-2">
                <div>
                    {getValue() === SKIPPED_PHONE
                        ? "-"
                        : (getValue() as string)}
                </div>
            </div>
        ),
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader className="" column={column} title="To" />
        ),
        accessorFn: (e) => e.toName,
        id: "To"
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="To Email"
            />
        ),
        accessorFn: (e) => e.toEmail,
        id: "To Email",
        enableGlobalFilter: false
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader
                className=""
                column={column}
                title="To Phone"
            />
        ),
        accessorFn: (e) => e.toPhone,
        id: "To Phone"
    }
];

export const columnsForExport = [
    {
        dataIndex: "storeName",
        title: "Store Name"
    },
    {
        dataIndex: "type",
        title: "Type"
    },
    {
        dataIndex: "code",
        title: "Code"
    },
    {
        dataIndex: "status",
        title: "Status"
    },
    {
        dataIndex: "firstUseDate",
        title: "First Use Date"
    },
    {
        dataIndex: "lastUseDate",
        title: "Last Use Date"
    },
    {
        dataIndex: "purchaseDate",
        title: "Purchase Date"
    },
    {
        dataIndex: "maxCreditCents",
        title: "Max credits"
    },
    {
        dataIndex: "remainingCreditCents",
        title: "Remaining credits"
    },
    {
        dataIndex: "fromName",
        title: "From"
    },
    {
        dataIndex: "fromEmail",
        title: "From Email"
    },
    {
        dataIndex: "toName",
        title: "To"
    },
    {
        dataIndex: "toPhone",
        title: "To Phone"
    },
    {
        dataIndex: "toEmail",
        title: "To Email"
    }
];

export const formatRowsForExport = (
    rows: GiftCardBalanceReportRow[]
): { [key: string]: string }[] =>
    rows.map((e) => ({
        storeName: e.storeName,
        type: e.type,
        code: e.code,
        status: mapStatus(e.status),
        firstUseDate: formatDate(e.firstUseDate),
        lastUseDate: formatDate(e.lastUseDate),
        purchaseDate: formatDate(e.purchaseDate),
        maxCreditCents: formatCentsAsDollars(e.maxCreditCents),
        remainingCreditCents: formatCentsAsDollars(e.remainingCreditCents),
        fromName: e.fromName,
        fromEmail: e.fromEmail,
        toName: e.toName,
        toPhone: e.toPhone,
        toEmail: e.toEmail
    }));
