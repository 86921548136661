import {
    IProduct,
    ScreenState,
    Menu as MultiMenu
} from "@snackpass/snackpass-types";
import { Dispatch, RefObject, SetStateAction } from "react";
import styled from "styled-components";

import { IProductCategoryWithProducts } from "#menu-editor/mobile-friendly/helpers/context";
import {
    isAddonGroup,
    MenuOutlineCellTypeEnum
} from "#menu-editor/multi-menus/helpers";
import { ActionsCell } from "#menu-editor/multi-menus/screens/menu-overview-desktop/components/items-table/components/columns/cells/actions/actions-cell";
import {
    DRAG_COL_PADDING,
    DRAG_COL_WIDTH,
    ItemCell
} from "#menu-editor/multi-menus/screens/menu-overview-desktop/components/items-table/components/columns/cells/items/item-cell";
import { OverridesCell } from "#menu-editor/multi-menus/screens/menu-overview-desktop/components/items-table/components/columns/cells/overrides/overrides-cell";
import { ItemHeader } from "#menu-editor/multi-menus/screens/menu-overview-desktop/components/items-table/components/columns/headers/items/item-header";
import { OverridesHeader } from "#menu-editor/multi-menus/screens/menu-overview-desktop/components/items-table/components/columns/headers/overrides/overrides-header";
import { Text } from "#menu-editor/multi-menus/styled-components/text";

export const menuOutlineColumns = [
    {
        id: MenuOutlineCellTypeEnum.Item,
        Header: ({
            getToggleAllRowsExpandedProps,
            toggleAllRowsExpanded,
            rows,
            data
        }: {
            getToggleAllRowsExpandedProps: Function;
            toggleAllRowsExpanded: (isExpanded?: boolean) => void;
            rows: Record<string, unknown>[];
            data: MultiMenu[];
        }) => (
            <ItemHeader
                getToggleAllRowsExpandedProps={getToggleAllRowsExpandedProps}
                toggleAllRowsExpanded={toggleAllRowsExpanded}
                areAnyExpanded={rows.length > data.length}
            />
        ),
        Cell: ({
            row,
            rowRef,
            setRowIsDragging,
            shouldDrag,
            increaseOutlineChangesCount
        }: {
            //eslint-disable-next-line
            row: any;
            rowRef: RefObject<HTMLTableRowElement>;
            setRowIsDragging: (isDragging: boolean) => void;
            shouldDrag: boolean;
            increaseOutlineChangesCount: () => void;
        }) =>
            !isAddonGroup(row.original) ? (
                <ItemCell
                    row={row}
                    rowRef={rowRef}
                    setRowIsDragging={setRowIsDragging}
                    shouldDrag={shouldDrag}
                    increaseOutlineChangesCount={increaseOutlineChangesCount}
                />
            ) : (
                <EnabledTextWrapper>
                    <Text type="body-bold">{row.original.name}</Text>
                </EnabledTextWrapper>
            )
    },
    {
        id: MenuOutlineCellTypeEnum.Overrides,
        with: 1,
        Header: () => <OverridesHeader />,
        Cell: ({
            row: { original: originalRow },
            overrideCellStates,
            togglePriceOverrideCell,
            toggleTaxesOverrideCell,
            setOverrideCellStates
        }: {
            overrideCellStates: Record<
                string,
                { price: boolean; taxes: boolean }
            >;
            togglePriceOverrideCell: () => void;
            toggleTaxesOverrideCell: () => void;
            row: { original: IProductCategoryWithProducts | IProduct };
            setOverrideCellStates: Dispatch<
                SetStateAction<
                    Record<string, { price: boolean; taxes: boolean }>
                >
            >;
        }) =>
            !isAddonGroup(originalRow) ? (
                <OverridesCell
                    overrideCellStates={overrideCellStates}
                    togglePriceOverrideCell={togglePriceOverrideCell}
                    toggleTaxesOverrideCell={toggleTaxesOverrideCell}
                    originalRow={originalRow}
                    setOverrideCellStates={setOverrideCellStates}
                />
            ) : null
    },
    {
        id: MenuOutlineCellTypeEnum.Actions,
        Cell: ({
            row,
            togglePriceOverrideCell,
            toggleTaxesOverrideCell,
            overrideCellStates
        }: {
            row: {
                isExpanded: boolean;
                canExpand: boolean;
                getToggleRowExpandedProps: Function;
                original: IProductCategoryWithProducts | IProduct;
            };
            overrideCellStates: Record<
                string,
                { price: boolean; taxes: boolean }
            >;
            togglePriceOverrideCell: () => void;
            toggleTaxesOverrideCell: () => void;
        }) =>
            !isAddonGroup(row.original) ? (
                <ActionsCell
                    row={row}
                    overrideCellStates={overrideCellStates}
                    togglePriceOverrideCell={togglePriceOverrideCell}
                    toggleTaxesOverrideCell={toggleTaxesOverrideCell}
                />
            ) : null
    }
];

const EnabledTextWrapper = styled.div`
    padding: 8px 0px;
    margin-left: ${() => DRAG_COL_PADDING + DRAG_COL_WIDTH}px;
    @media ${ScreenState.MOBILE} {
        margin-left: ${() => DRAG_COL_WIDTH}px;
    }
`;
