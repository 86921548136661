import styled from "@emotion/styled";
import {
    CampaignEvent,
    ComplaintEvent,
    ComplimentEvent,
    FollowEvent,
    MenuSuggestionEvent,
    PurchaseEvent,
    RefundEvent,
    StoreGiftEvent
} from "@snackpass/conversations.types";
import { SystemColors } from "@snackpass/design-system";
import moment from "moment";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";

import { capitalizeFirstChar } from "#utils/helpers";
import { ReactComponent as GiftSVG } from "src/assets/icons/conversation-gift.svg";

export const PurchaseEventMessage = ({
    message
}: {
    message: PurchaseEvent;
}) => {
    const {
        amountPaid,
        numItems,
        transactionSource,
        receiptNumber,
        purchaseId
    } = message;
    const dispatch = useDispatch();
    const _onClick = useCallback(() => {
        dispatch(show("OrderDetailsDrawer", { purchaseId: purchaseId }));
    }, []);

    return (
        <EventContainer onClick={_onClick}>
            <div className="event-title">
                {capitalizeFirstChar(transactionSource)} Order {receiptNumber}
            </div>
            <div className="event-description">
                {numItems} Items · {amountPaid}
            </div>
            <div className="event-view-order blue">View Order</div>
        </EventContainer>
    );
};

export const FollowEventMessage = ({ message }: { message: FollowEvent }) => {
    const { userName, storeName, numPurchases } = message;
    return (
        <EventContainer>
            <div className="event-title">
                {userName} Followed {storeName}
            </div>
            <div className="event-description">{numPurchases} Orders</div>
        </EventContainer>
    );
};

export const ComplaintEventMessage = ({
    message
}: {
    message: ComplaintEvent;
}) => {
    const { numItems, amountPaid, label, purchaseId, receiptNumber } = message;
    const dispatch = useDispatch();
    const _onClick = useCallback(() => {
        dispatch(show("OrderDetailsDrawer", { purchaseId: purchaseId }));
    }, []);

    return (
        <RedContainer>
            <div className="event-title">Issue: {label}</div>
            <div className="event-description">
                {numItems} Items · {amountPaid}
            </div>
            <div className="event-view-order red" onClick={_onClick}>
                View Order {receiptNumber}
            </div>
        </RedContainer>
    );
};
export const ComplimentEventMessage = ({
    message
}: {
    message: ComplimentEvent;
}) => {
    const { numItems, amountPaid, label, purchaseId } = message;
    const dispatch = useDispatch();
    const _onClick = useCallback(() => {
        dispatch(show("OrderDetailsDrawer", { purchaseId: purchaseId }));
    }, []);

    return (
        <EventContainer>
            <div className="event-title">Rated Order: {label}</div>
            <div className="event-description">
                {numItems} Items · {amountPaid}
            </div>
            <div className="event-view-order blue" onClick={_onClick}>
                View Order
            </div>
        </EventContainer>
    );
};

export const RefundEventMessage = ({ message }: { message: RefundEvent }) => {
    const { receiptNumber, amountRefunded, ts, purchaseId } = message;
    const dispatch = useDispatch();
    const _onClick = useCallback(() => {
        dispatch(show("OrderDetailsDrawer", { purchaseId: purchaseId }));
    }, []);

    return (
        <EventContainer right={true}>
            <div className="event-title">{amountRefunded} Refund</div>
            <div className="event-description">
                Issued {moment(ts).format("MMMM DD, YYYY")}
            </div>
            <div className="event-view-order blue" onClick={_onClick}>
                View Order
            </div>
        </EventContainer>
    );
};

export const GiftEventMessage = ({
    message
}: {
    message: Pick<StoreGiftEvent, "giftStoreName" | "expirationDate">;
}) => {
    const { giftStoreName, expirationDate } = message;

    return (
        <GiftContainer>
            <div className="gift-box">
                <GiftSVG />
            </div>
            <div className="gift-detail">
                <div className="gift-name">{giftStoreName}</div>
                <div className="expiration-date">
                    Expires {moment(expirationDate).format("MMM DD, YYYY")}
                </div>
            </div>
        </GiftContainer>
    );
};

export const MenuSuggestionEventMessage = ({
    message
}: {
    message: MenuSuggestionEvent;
}) => {
    const { productName } = message;

    return (
        <RedContainer>
            <div className="event-title">Menu feedback</div>
            {/* if productName is "General", it means the user submit the suggestion in store level */}
            {productName !== "General" ? (
                <div className="event-description">{`For item  ${productName}`}</div>
            ) : null}
        </RedContainer>
    );
};

export const CampaignEventMessage = ({
    message
}: {
    message: CampaignEvent;
}) => {
    const { body, title, isClaimable, expirationDate } = message;

    const rewardContent = isClaimable ? (
        <GiftEventMessage message={{ giftStoreName: title, expirationDate }} />
    ) : null;

    return (
        <CampaignContainer>
            {rewardContent}
            <RightMessageBoxWrapper>
                <RightMessageBox>{body}</RightMessageBox>
            </RightMessageBoxWrapper>
        </CampaignContainer>
    );
};

interface EventContainerProps {
    right?: boolean;
}

const EventContainer = styled.div<EventContainerProps>`
    align-items: center;
    padding: 5px 15px;
    width: fit-content;
    height: fit-content;
    background: ${SystemColors.v1.white};
    border: 1px solid ${SystemColors.v1.gray80};
    border-radius: 16px;
    margin-left: ${(props) => (props.right ? "0px;" : "15px")};
    margin-right: ${(props) => (props.right ? "15px" : "0px")};
    margin-bottom: 10px;
    box-sizing: border-box;

    .event-title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .event-description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: ${SystemColors.v1.gray30};
    }

    .event-view-order {
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-feature-settings: "ss01";
        cursor: pointer;
    }
    .blue {
        color: ${SystemColors.v1.candy50};
    }
`;

const GiftContainer = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    border: 1px solid ${SystemColors.v1.gray80};
    border-radius: 16px;
    margin-right: 15px;
    margin-bottom: 10px;
    box-sizing: border-box;
    background: linear-gradient(90deg, #fff5fa 65px, #ffffff 65px);
    .gift-box {
        padding: 10px 13px;
        text-align: center;
    }
    .gift-detail {
        width: max-content;
    }
    .gift-name {
        color: ${SystemColors.v1.dragonfruit70};
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        font-feature-settings: "ss01";
        padding: 0px 10px;
        max-width: 300px;
        word-wrap: break-word;
    }
    .expiration-date {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding: 0px 10px;
        color: ${SystemColors.v1.gray40};
    }
`;

const RedContainer = styled(EventContainer)`
    color: ${SystemColors.v1.melon50};
    border: 1px solid ${SystemColors.v1.melon50};
`;

const CampaignContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 300px;
    word-wrap: break-word;
`;

const RightMessageBoxWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
`;
const RightMessageBox = styled.div`
    align-items: center;
    padding: 5px 15px;
    width: fit-content;
    height: fit-content;
    background: ${SystemColors.v1.candy50};
    border-radius: 16px;
    color: white;
    margin-right: 15px;
    margin-bottom: 10px;
`;
