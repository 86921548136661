import { useContext } from "react";
import produce from "immer";

import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import {
    ChannelOption,
    ChannelType
} from "#devices/utils/deviceTypes/SnackTVDevice";
import SettingsSection from "#devices/components/EditDrawer/SettingsSection";
import Toggle from "#devices/components/FormControls/Toggle";

export const HideDescription = () => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const deviceOptions = (updatedDevice?.deviceDetails?.channelOptions ??
        snackTvDevice?.deviceDetails?.channelOptions ??
        []) as ChannelOption[];

    const channel =
        (updatedDevice?.deviceDetails?.channel as ChannelType) ??
        snackTvDevice?.deviceDetails?.channel;

    const channelOptions = deviceOptions.find(
        (o: ChannelOption) => o.channel === channel
    );

    const checked = channelOptions?.options?.hideDescription;

    return (
        <SettingsSection
            label="Hide Product Description"
            description="Hide all product descriptions from channel"
        >
            <Toggle
                id="hideDescriptionToggle"
                checked={checked}
                onChange={(e) => {
                    const updatedOptions = produce(deviceOptions, (draft) => {
                        if (draft) {
                            const channelOption = draft.find(
                                (o) => o.channel === channel
                            );
                            if (!channelOption) {
                                draft.push({
                                    channel,
                                    options: {
                                        hideDescription: e.target.checked
                                    }
                                });
                            } else {
                                channelOption.options.hideDescription =
                                    e.target.checked;
                            }
                        }
                    });
                    updateDeviceField("channelOptions", updatedOptions);
                }}
            />
        </SettingsSection>
    );
};
