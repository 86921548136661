import { IStore } from "@snackpass/snackpass-types";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { match } from "ts-pattern";

import { OnboardingContext } from "#onboarding/utils/OnboardingContext";
import { IStep, OnboardingStep } from "#onboarding/utils/types";
import {
    BusinessSchema,
    LocationSchema
} from "#onboarding/utils/validation/schema";
import { StoreVerification } from "#payouts/domain/types";
import { getActiveStore, getUser } from "src/redux/selectors";

type MaybeStore = IStore | null;
type MaybeVerification = StoreVerification | null;

export const useIsCompleted = (step: Pick<IStep, "name">) => {
    const user = useSelector(getUser);
    const store = useSelector(getActiveStore);

    const { verification, confirmRepresentative, saasAccount } =
        useContext(OnboardingContext);

    const summaryInput = {
        user,
        store,
        verification,
        confirmRepresentative,
        saasAccount
    };

    return match(step.name)
        .with(OnboardingStep.Summary, () => summary(summaryInput))
        .with(OnboardingStep.Business, () => business(verification))
        .with(OnboardingStep.Location, () => location(store))
        .exhaustive();
};

const summary = ({
    store,
    verification
}: {
    store: MaybeStore;
    verification: MaybeVerification;
}) => location(store) && business(verification);

export const location = (store: MaybeStore) =>
    LocationSchema.safeParse({
        name: store?.name,
        phone: store?.phoneNumber?.replace("+1", ""),
        email: store?.email?.split(",")[0],
        slug: store?.slug,
        logoUrl: store?.logoUrl,
        digitalReceiptUrl: store?.loyaltyCardBackgroundImage,
        tipImageUrl: store?.tipImageUrl,
        attractScreenUrl: store?.kioskPreferences?.attractScreenUrl,
        thumbnailUrl: store?.thumbnailUrl,
        slugAvailable: true,
        address: store?.addressComponents,
        timezone: store?.hours.zone,
        taxRate: store?.taxRate,
        hours: store?.hours.local
    }).success;

// Note: Consider moving the check slug call to context and tracking
//       the "slugAvailable" value in context rather than in the Business
//       component where this is updated.
const business = (verification: MaybeVerification) =>
    BusinessSchema.safeParse({
        name: verification?.name,
        taxId: verification?.taxId,
        phone: verification?.phone
    }).success;
