import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { toast } from "sonner";

import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";

export const useRemoveTable = () => {
    const activeStore = useSelector(getActiveStore);
    const storeId = activeStore?._id ?? "";

    const { mutateAsync: removeTable, isPending } = useMutation({
        onError: (err) => {
            toast.error("Couldn't remove table", {
                description:
                    (err as AxiosError)?.message ?? "Unknown error. Try again."
            });
        },
        mutationFn: async (tableId: string) => {
            if (!tableId) {
                return;
            }

            return api.stores.deleteTable(storeId, tableId);
        },
        onSuccess: () => {
            toast.success("Removed Table Successfully");
        }
    });

    return {
        removeTable,
        isLoading: isPending
    };
};
