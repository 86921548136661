import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FirebaseError } from "firebase/app";
import { sendPasswordResetEmail } from "firebase/auth";
import { toast } from "sonner";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { firebaseAuth } from "#app/firebase";
import { sendError } from "src/utils/errors";
import { Form } from "src/@/components/ui/form";
import { FormCard } from "src/@/components/form-card";
import { getUser } from "src/redux/selectors";

export const BlankSchema = z.object({
    blank: z.string().optional()
});

export function ResetPassword() {
    const user = useSelector(getUser);

    const form = useForm<z.infer<typeof BlankSchema>>({
        resolver: zodResolver(BlankSchema),
        defaultValues: {}
    });

    // our FormCard won't submit unless the form is dirty so dirty it up!
    useEffect(() => {
        form.setValue("blank", "", { shouldDirty: true });
    }, [form]);

    const onSubmit = async () => {
        const email = user?.email ?? "";

        if (!email) {
            toast.error(
                "You have no email address! Please contact Snackpass support."
            );
        }

        try {
            await sendPasswordResetEmail(firebaseAuth, email);
            await toast.success("The reset email has been sent");
        } catch (error) {
            const err = error as FirebaseError;
            sendError(err);
            toast.error("The email failed to send");
        }
    };

    return (
        <Form {...form}>
            <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="w-full max-w-[900px]"
            >
                <FormCard
                    title="Reset Password"
                    subtitle="Send a reset link to your email address."
                    buttonText="Reset Password"
                    showSaveButton
                ></FormCard>
            </form>
        </Form>
    );
}
