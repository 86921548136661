/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from "react";
import { CellInfo } from "react-table";
import { useSelector } from "react-redux";
import { AddonGroup, ScreenState } from "@snackpass/snackpass-types";
import { css } from "@emotion/react";
import { PartialDeep } from "type-fest";
import classNames from "classnames";
import { Tooltip } from "antd";
import { SystemColors } from "@snackpass/design-system";

import { ReactComponent as EditIcon } from "src/assets/icons/edit.svg";
import { ReactComponent as Delete } from "src/assets/icons/delete-grey.svg";
import useWindowDimensions from "#hooks/use-window-dimensions";
import { isServerGeneratedId } from "#menu-editor/mobile-friendly/helpers/utils";
import { getActiveStoreIs3PIntegrated } from "src/redux/selectors";

import {
    ProductEditSharedContext,
    ProductNewSharedContext
} from "../../helpers/context";

import { Table } from "./modifier-groups-table";

const MOBILE_BREAKPOINT = 768;
type ModifiersType = {
    isProductInEditMode: boolean;
};
const Modifiers: React.FC<ModifiersType> = ({ isProductInEditMode }) => {
    const {
        setShowNewModifierForm,
        modifierGroups,
        setModifierGroups,
        setDeletedAddonGroups,
        setIsModifierGroupInEdit,
        setModifierGroupInEdit
    } = useContext(
        isProductInEditMode ? ProductEditSharedContext : ProductNewSharedContext
    );
    const { width } = useWindowDimensions();
    const thirdPartyEnabled = useSelector(getActiveStoreIs3PIntegrated);

    type ModifierGroupsCardProps = {
        cell: CellInfo;
    };

    const ModifierCard: React.FC<ModifierGroupsCardProps> = ({ cell }) => {
        const totalModifiers = cell.original.addons ? cell.original.addons : [];
        const name = cell.original.name;
        const ModifierList = () =>
            useMemo(
                () => (
                    <span
                        className="modifier-list"
                        onClick={() => {
                            setModifierGroupInEdit(cell.original);
                            setIsModifierGroupInEdit(true);
                            setShowNewModifierForm(true);
                        }}
                    >
                        {totalModifiers.map(
                            (
                                modifier: {
                                    name: string;
                                    price: number;
                                },
                                idx: number
                            ) => (
                                <span
                                    key={
                                        "modifier-name" +
                                        name +
                                        idx +
                                        modifier.price
                                    }
                                >
                                    {` ${modifier.name}`}
                                    {idx < totalModifiers.length - 1
                                        ? ","
                                        : null}
                                </span>
                            )
                        )}
                    </span>
                ),
                []
            );
        return (
            <td css={ModifierCardStyle}>
                <>
                    <span
                        className="modifier-name"
                        onClick={() => {
                            setModifierGroupInEdit(cell.original);
                            setIsModifierGroupInEdit(true);
                            setShowNewModifierForm(true);
                        }}
                    >
                        {name}
                    </span>
                    {
                        <>
                            <Tooltip
                                title={thirdPartyEnabled ? "View" : "Edit"}
                            >
                                <EditIcon
                                    className={classNames({
                                        TableRowHeadingCellButtonActions: true,
                                        editIcon: true
                                    })}
                                    onClick={() => {
                                        setModifierGroupInEdit(cell.original);
                                        setIsModifierGroupInEdit(true);
                                        setShowNewModifierForm(true);
                                    }}
                                    fill={SystemColors.v2.salt30.dark}
                                />
                            </Tooltip>
                            {!thirdPartyEnabled && (
                                <Tooltip title="Delete">
                                    <Delete
                                        className={classNames({
                                            TableRowHeadingCellButtonActions:
                                                true,
                                            deleteIcon: true
                                        })}
                                        onClick={() => {
                                            const modifierGroupsClone: PartialDeep<AddonGroup>[] =
                                                JSON.parse(
                                                    JSON.stringify(
                                                        modifierGroups
                                                    )
                                                );
                                            const idx =
                                                modifierGroupsClone.findIndex(
                                                    (
                                                        modifierGroup: PartialDeep<AddonGroup>
                                                    ) =>
                                                        modifierGroup?._id ===
                                                        cell.original?._id
                                                );
                                            modifierGroupsClone.splice(idx, 1);
                                            if (
                                                cell.original?._id &&
                                                !isServerGeneratedId(
                                                    cell.original._id
                                                )
                                            ) {
                                                setDeletedAddonGroups(
                                                    (prev) => [
                                                        ...prev,
                                                        cell.original?._id
                                                    ]
                                                );
                                            }
                                            setModifierGroups(
                                                modifierGroupsClone
                                            );
                                            setShowNewModifierForm(false);
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </>
                    }

                    {width > MOBILE_BREAKPOINT ? (
                        <ModifierList />
                    ) : (
                        <span
                            className="total-modifiers"
                            onClick={() => {
                                setModifierGroupInEdit(cell.original);
                                setIsModifierGroupInEdit(true);
                                setShowNewModifierForm(true);
                            }}
                        >
                            {totalModifiers.length
                                ? `${totalModifiers.length} Options`
                                : ""}
                        </span>
                    )}
                </>
            </td>
        );
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "name",
                accessor: "name" as keyof AddonGroup,
                // Types on this are difficult. Should be CellInfo.
                Cell: (props: any) => <ModifierCard cell={props.row} />
            }
        ],
        [modifierGroups]
    );

    return (
        <Table
            columns={columns}
            data={modifierGroups as AddonGroup[]} // Modifier groups might be typed as partial for a reason
            setData={setModifierGroups}
            isProductInEditMode={isProductInEditMode}
        ></Table>
    );
};

const ModifierCardStyle = css`
    font-family: "Inter";
    display: grid;
    grid-template-columns: 1fr auto auto 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr auto;
    grid-auto-rows: minmax(1.5rem, auto);
    align-items: center;
    justify-content: center;
    padding: 2px 10px !important;

    .modifier-name {
        grid-column: 1/6;
        grid-row: 1/2;
        margin-left: 2rem;
        padding-top: 1.5rem;
        font-size: 16px !important;
        justify-self: start;
        text-align: left;
        cursor: pointer;
        line-height: 120%;
        @media ${ScreenState.MOBILE} {
            word-wrap: break-word;
            width: 16rem;
        }
    }

    .sub-row-modifier-name {
        @media ${ScreenState.MOBILE} {
            word-wrap: break-word;
        }

        grid-column: 2/10;
        grid-row: 1/2;
        margin-left: 1rem;
        padding-top: 1.5rem;
        font-size: 16px !important;
        justify-self: start;
        text-align: left;
        line-height: 24px;
    }

    .sub-row-modifier-name span {
        font-weight: 200 !important;
    }

    .total-modifiers {
        grid-column: 1/11;
        grid-row: 2/3;
        margin-left: 2rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 16px !important;
        justify-self: start;
        cursor: pointer;
    }
    .modifier-list {
        grid-column: 1/11;
        grid-row: 2/3;
        margin-left: 2rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 16px !important;
        text-align: left;
        overflow: visible;
        color: grey;
        line-height: 1.1;
        cursor: pointer;
    }
    .actions {
        margin-right: 8px;
        margin-top: 5px;
        padding-top: 3px;
        cursor: pointer;
    }

    .delete-icon {
        margin-right: 8px;
        margin-top: 5px;
        cursor: pointer;
        width: 1rem;
        height: 1rem;
    }

    .delete-icon path {
        fill: #ff3929;
    }

    .duplicate-icon path {
        fill: #282d32;
    }

    .addOnPrice {
        grid-column: 11;
        display: flex;
        grid-row: 1/3;
        margin-right: 3.5rem;
        margin-top: 2px;
        color: grey;
        font-size: 16px !important;
        justify-self: end;
        align-self: center;
    }
    .deleteIcon {
        width: 1.1rem;
        height: 1.05rem;
    }
    .EditIcon {
        padding: 4px;
    }
    .deleteIcon path {
        fill: #ff3929 !important;
    }
    .EditIcon path {
        fill: #282d32;
    }

    .soldOutTag {
        position: absolute;
        top: 40%;
        right: 1%;
        color: rgba(255, 57, 41, 1);
        border-radius: 6px;
        padding: 0 5px;
        font-family: "Inter";
        font-weight: 800;
        margin-left: 5px;
        white-space: nowrap;
        font-size: 16px !important;
        border: 1px solid rgba(221, 229, 238, 1);
        border-radius: 8px;
    }
    .inStockTag {
        position: absolute;
        top: 40%;
        right: 1%;
        color: rgba(0, 204, 34, 1);
        border-radius: 6px;
        padding: 0 5px;
        font-family: "Inter";
        font-weight: 800;
        margin-left: 5px;
        white-space: nowrap;
        font-size: 16px !important;
        border: 1px solid rgba(221, 229, 238, 1);
        border-radius: 8px;
    }
`;

export default Modifiers;
