import produce from "immer";
import React, { useContext } from "react";
import styled from "styled-components";

import Input from "#devices/components/FormControls/Input";
import { DevicesPageContext } from "#devices/utils/DevicesPageContext";
import {
    ChannelOption,
    ChannelType,
    DEFAULT_CHANNEL_OPTIONS
} from "#devices/utils/deviceTypes/SnackTVDevice";

const FormControlStyles = styled.div`
    padding-top: ${({ theme }) => theme.spacing.base};

    input::placeholder {
        opacity: 0.5;
    }
`;

export const EditFontSize = () => {
    const { snackTvDevice, updatedDevice, updateDeviceField } =
        useContext(DevicesPageContext);

    const deviceOptions = (updatedDevice?.deviceDetails?.channelOptions ??
        snackTvDevice?.deviceDetails?.channelOptions ??
        []) as ChannelOption[];

    const channel =
        updatedDevice?.deviceDetails?.channel ??
        snackTvDevice?.deviceDetails?.channel;

    const channelOptions = deviceOptions.find(
        (o: ChannelOption) => o.channel === channel
    );

    const value =
        channelOptions?.options?.fontSize ?? DEFAULT_CHANNEL_OPTIONS.fontSize;

    return (
        <FormControlStyles>
            <Input
                id="fontSizeChannelOption"
                label="Font Size"
                type="number"
                min="0"
                value={value}
                onChange={(e) => {
                    const updatedOptions = produce(deviceOptions, (draft) => {
                        if (draft) {
                            const channelOption = draft.find(
                                (o) => o.channel === channel
                            );
                            if (!channelOption) {
                                draft.push({
                                    channel: channel as ChannelType,
                                    options: {
                                        fontSize: parseInt(e.target.value, 10)
                                    }
                                });
                            } else {
                                channelOption.options.fontSize = parseInt(
                                    e.target.value,
                                    10
                                );
                            }
                        }
                    });
                    updateDeviceField("channelOptions", updatedOptions);
                }}
            />
        </FormControlStyles>
    );
};
