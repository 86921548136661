import { IProduct, IStore } from "@snackpass/snackpass-types";

import { IMenuAutomationCheck, ItemType, MenuIssue } from "../interfaces";

const allowedToBeLowercase = [
    "or",
    "and",
    "with",
    "the",
    "oz",
    "w/",
    "de",
    "of",
    "pcs",
    "in"
];

class ProductTitleCaseCheck implements IMenuAutomationCheck<IProduct> {
    public store: IStore;

    constructor(store: IStore) {
        this.store = store;
    }

    get name() {
        return "ProductTitleCaseCheck";
    }

    get itemToCheck(): ItemType {
        return "product";
    }

    checkItems(): MenuIssue<IProduct>[] {
        return [];
    }

    checkItem(item: IProduct): MenuIssue<IProduct> | null {
        const words = item.name.split(" ");
        for (const word of words) {
            // if a word's first letter is not uppercase
            // and it is not allowed to be lowercase
            const currentChar = word.charAt(0);
            if (
                currentChar !== currentChar.toUpperCase() &&
                !allowedToBeLowercase.includes(word)
            ) {
                return new MenuIssue<any>(
                    "ProductNotTitleCase",
                    "Products must be title cased (first letter of each word capitalized).",
                    "warning",
                    item
                );
            }
        }
        return null;
    }
}

export { ProductTitleCaseCheck };
