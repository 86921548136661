import React from "react";
import { Col } from "antd";

import { Text } from "#reusable/text/index";
import { StyledRow } from "#settings/components/shared-component";

import { Input } from "./Input";

type Props = {
    title: string;
    subtitle: string;
    inputProps: React.InputHTMLAttributes<HTMLInputElement>;
};

export const SettingsInput = ({ title, subtitle, inputProps }: Props) => (
    <StyledRow>
        <Col>
            <Text size="m">{title}</Text>
            <Text size="s" color="light-grey" weight="thin">
                {subtitle}
            </Text>
        </Col>
        <Col span={4}>
            <Input {...inputProps} />
        </Col>
    </StyledRow>
);
