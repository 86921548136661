import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
    name: "loading",
    initialState: false,
    reducers: {
        setIsLoading: (_, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setIsLoading } = loadingSlice.actions;
