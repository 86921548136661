/** @jsxImportSource @emotion/react */
import { Menu as MultiMenu } from "@snackpass/snackpass-types";
import React, { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";

import { multiMenuActions } from "#menu-editor/multi-menus/redux/actions";
import { getMultiMenuLoadingState } from "#menu-editor/multi-menus/redux/selectors";
import { multiMenuThunks } from "#menu-editor/multi-menus/redux/thunks";
import { ConfirmModal } from "#menu-editor/multi-menus/shared-components/confirm-modal";
import { useAppDispatch } from "src/redux/hooks";
import { logAndSendError } from "src/utils/errors";

type ModalProps = {
    setEnabled: Dispatch<SetStateAction<boolean>>;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    menu?: MultiMenu;
};

export const Enable3PMenuModal: React.FC<ModalProps> = ({
    isModalOpen,
    setIsModalOpen,
    setEnabled,
    menu
}) => {
    const dispatch = useAppDispatch();
    const updateState = useSelector(
        getMultiMenuLoadingState("UpdateMultiMenu")
    );

    const handleEnable = async () => {
        if (!menu) return;
        dispatch(
            multiMenuThunks.updateMenu({
                menu: { ...menu, enabled: false },
                fields: ["enabled", "platforms"]
            })
        )
            .unwrap()
            .then(() => {
                dispatch(multiMenuActions.activeMenu({ enabled: true }));
                setEnabled(true);
                setIsModalOpen(false);
            })
            .catch((err) => {
                logAndSendError(err);
            });
    };

    return (
        <ConfirmModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            fail={updateState === "failed"}
            isInProcess={updateState === "pending"}
            onClick={handleEnable}
            title="Enable Menu?"
            message={`By enabling this menu you will disable "${menu?.name}" menu. Are you sure you want to disable "${menu?.name}"?`}
            action="enable menu"
        />
    );
};
