import * as yup from "yup";

import { PERCENT_OPTIONS, DOLLAR_OPTIONS } from "#promotion/utils/constants";
import { FIELD_NAMES, PlatformValues } from "#promotion/utils/types";
import { promoCodeValidation } from "#promotion/utils/validation/field-schemas";
import {
    GenericDefaultValues,
    GenericPromoValidations
} from "#promotion/utils/validation/form-schemas/generic";

export const PromoCodeDefaultValues = {
    ...GenericDefaultValues,
    // Type Overrides
    [FIELD_NAMES.PROMO_CODE_ENABLED]: true,
    [FIELD_NAMES.SINGLE_USE]: true,
    [FIELD_NAMES.DISCOUNT_ADDONS]: true,
    [FIELD_NAMES.DISCOUNT_AMOUNT_PERCENT]: Number(PERCENT_OPTIONS[3].value), // 25%
    [FIELD_NAMES.DISCOUNT_AMOUNT_DOLLARS]: Number(DOLLAR_OPTIONS[1].value), // $5
    [FIELD_NAMES.PLATFORMS]: PlatformValues.AppAndKiosk
};

export const PromoValidationSchema = yup.object({
    ...GenericPromoValidations,
    // Validation Overrides
    [FIELD_NAMES.PROMO_CODE]: promoCodeValidation
        .trim()
        .min(2, "Code must be at least 2 valid characters.")
        .max(30, "Code must no longer than 30 valid characters.")
        .required("Promo code is required.")
        .matches(
            /^[a-zA-Z0-9-]+$/,
            "Code can only contain letters, numbers, and hyphens."
        )
});
