import { toDollar } from "@snackpass/accounting";
import {
    Fulfillment,
    InStorePaymentType,
    PaymentProvider,
    PurchaseReportData,
    PurchaseStatus,
    PurchaseSummaryData,
    TransactionSource
} from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import { Dispatch, SetStateAction } from "react";

import {
    formatPurchaseChannel,
    formatTransactionSource,
    nameToFirstNameLastInitial
} from "#utils/helpers";

export type SinglePurchaseSummary = {
    id: string;
    customerName: string | null;
    refund: number;
    createdAt: Date | string | null;
    subtotal: number;
    orders: number;
    storeTaxPaidOut: number;
    storeTaxWithheld: number;
    tip: number;
    fulfillment: Fulfillment;
    deliveryFee: number;
    delivery3PFee: number;
    customerToStoreFees: number;
    customerToSnackpassFees: number;
    total: number;
    adjustments: number;
    fax: number;
    commission: number;
    netPayout: number;
    earnings: number;
    netSales: number;
    creditCard: number;
    storeCredit: number;
    notes: string | null;
    scheduledDate: Date | null;
    type: TransactionSource | "";
    cashTotal: number;
    paymentMethod: string;
};

export const defaultSummary: SinglePurchaseSummary = {
    id: "",
    customerName: null,
    refund: 0,
    createdAt: null,
    subtotal: 0,
    orders: 0,
    storeTaxPaidOut: 0,
    storeTaxWithheld: 0,
    tip: 0,
    fulfillment: "DINE_IN",
    deliveryFee: 0,
    delivery3PFee: 0,
    customerToStoreFees: 0,
    customerToSnackpassFees: 0,
    total: 0,
    adjustments: 0,
    fax: 0,
    commission: 0,
    netPayout: 0,
    creditCard: 0,
    storeCredit: 0,
    notes: "",
    scheduledDate: null,
    type: "",
    earnings: 0,
    netSales: 0,
    cashTotal: 0,
    paymentMethod: "Card"
};

export const getMonthString = (date: Date) => moment(date).format("YYY-MM MMM");

export const getTwoDigitDateString = (
    timezone: string,
    date: Date | string | null
): string => (date ? moment(date).tz(timezone).format("h:mm a M/D/YYYY") : "");

export const formatPaymentMethod = (p: PurchaseReportData): string => {
    switch (p.paymentMethod) {
        case PaymentProvider.inStore:
            switch (p.inStorePaymentType) {
                case InStorePaymentType.other:
                    return "In-Store (Other)";
                case InStorePaymentType.giftCards:
                    return "In-Store (Gift Card)";
                default:
                    return "In-Store (Cash)";
            }
        case PaymentProvider.thirdParty:
            return "Third-Party";
        case PaymentProvider.square:
        case PaymentProvider.stripe:
            return "Card";
        case PaymentProvider.giftCards:
            return "Gift Card Credit";
        case undefined:
            return p.hasSpecialGiftAccounting
                ? "Card"
                : p.giftCardCredit
                  ? "Gift Card Credit"
                  : p.storeCredit
                    ? "Store Credit"
                    : p.snackpassCredit
                      ? "Snackpass Credit"
                      : "-";
        default:
            return p.paymentMethod;
    }
};

export const formatStatus = (p: PurchaseReportData): string => {
    switch (p.status) {
        case PurchaseStatus.received:
            return "Received";
        case PurchaseStatus.started:
            return "Started";
        case PurchaseStatus.canceled:
            return p.refundedAmount > 0 ? "Refunded" : "Canceled";
        case PurchaseStatus.completed:
            return "Completed";
        default:
            return p.status ?? "Received";
    }
};

export const formatItems = (p: PurchaseReportData): string => {
    const names = p.items.map((item) => {
        const { name } = item;
        let itemText = name;
        if (item.weight) {
            const { amount, unit } = item.weight;
            itemText = `${name}(${amount}${unit})`;
        }
        return itemText;
    });
    return names.join("; ");
};

export const formatPromos = (p: PurchaseReportData): string =>
    [...p.promoNames, ...p.rewardNames].join("; ");

export const formatNotes = (p: PurchaseReportData): string =>
    p.notes.join("; ");

export const formatRefund = (p: PurchaseReportData) =>
    p.refundedAmount ? toDollar(p.refundedAmount) : "";

export const formatFulfillment = (p: PurchaseReportData) =>
    p.fulfillment.toLowerCase().replace("_", "-");

export const getPurchaseDateString = (timezone: string, date: Date): string =>
    date ? getTwoDigitDateString(timezone, date) : "";

export const getPurchaseCreatedAtString = (
    timezone: string,
    summarizedPurchase: SinglePurchaseSummary
): string =>
    !summarizedPurchase.createdAt
        ? ""
        : getTwoDigitDateString(timezone, summarizedPurchase.createdAt);

export const getPurchaseScheduledForDateString = (
    timezone: string,
    summarizedPurchase: SinglePurchaseSummary
): string =>
    !summarizedPurchase.scheduledDate
        ? ""
        : getTwoDigitDateString(timezone, summarizedPurchase.scheduledDate);

export type SelectedOption = null | string;
export type SelectOption = Dispatch<SetStateAction<SelectedOption>>;

export const setPurchasesFields = (
    purchases: PurchaseReportData[],
    isSnackTeam: boolean,
    timezone: string,
    showRemittance: boolean,
    selectedPaymentType?: SelectedOption
) =>
    purchases.map((p) => ({
        "Date Received": getPurchaseDateString(
            timezone,
            new Date(p.dateReceived)
        ),
        Status: formatStatus(p),
        "Customer Name": nameToFirstNameLastInitial(p.customerName),
        Items: formatItems(p),
        Promotions: formatPromos(p),
        Notes: formatNotes(p),
        Subtotal: toDollar(p.subtotal),
        "Custom Surcharge": toDollar(p.customSurchargeTotal || 0),
        "Custom Discounts": toDollar(p.customDiscountsTotal || 0),
        "Up Charge": toDollar(p.upChargeAmount),
        "Delivery Charge": toDollar(p.delivery1PFee),
        "Taxes You Owe": toDollar(p.storeTaxesToRemit),
        ...(showRemittance
            ? {
                  "Taxes Remitted On Your Behalf": toDollar(
                      p.storeTaxesWithheld
                  )
              }
            : {}),
        "Estimated Third-Party Taxes": toDollar(p.store3PTaxesToRemit),
        Tip: toDollar(p.tip),
        Total: toDollar(p.total),
        "3P Delivery Charge": toDollar(p.delivery3PFee),
        "Fax Fee": toDollar(p.faxFee),
        "Snackpass Fee": toDollar(p.commission),
        "Processing Fee": toDollar(p.processingFee),
        "Estimated Third Party Fees": toDollar(p.thirdPartyFees),
        "Cust. To Store Fees": toDollar(p.allCustomerToStoreFees),
        "Cust. To Snackpass Fees": toDollar(p.customerToSnackpassFees),
        "Gift Card Credit": toDollar(p.giftCardCredit),
        "Store Credit": toDollar(p.storeCredit),
        "Net Sales": toDollar(p.netSales),
        Earnings: toDollar(p.earnings),
        "Estimated Third-Party Payout": toDollar(p.expected3PPayout),
        "Expected Cash Collected": toDollar(p.expectedCashCollected),
        "Estimated Payout": toDollar(p.expectedPayout),
        ...(p.actualPayout !== undefined
            ? {
                  "Actual Payout": toDollar(p.actualPayout)
              }
            : {}),
        "Payment Method": formatPaymentMethod(p),
        "Scheduled For":
            p.scheduledFor &&
            getPurchaseDateString(timezone, new Date(p.scheduledFor)),
        Refund: formatRefund(p),
        Fulfillment: formatFulfillment(p),
        Source: formatTransactionSource(p.source),
        Channel: formatPurchaseChannel(p.purchase),
        Catering: p.isCatering ? "Yes" : "No",
        "Refunded By": p.refundedBy ?? "",
        "Up-Charged By": p.upchargedBy ?? "",
        "Cash Accepted By": p.cashAcceptedBy ?? "",
        "Created By": p.orderCreatedBy ?? "",
        ...(isSnackTeam && { ID: p.purchaseId }),
        ...(isSnackTeam && { "Payout ID": p.payoutId ?? "" })
    }));

export const getTotalFieldsRow = (
    total: PurchaseSummaryData,
    isSnackTeam: boolean,
    showRemittance: boolean
) => [
    {
        "Date Received": "TOTALS:",
        Status: "",
        "Customer Name": "",
        Items: "",
        Promotions: "",
        Notes: "",
        Subtotal: toDollar(total.subtotal),
        "Custom Surcharge": toDollar(total.customSurchargeTotal || 0),
        "Custom Discounts": toDollar(total.customDiscountsTotal || 0),
        "Up Charge": toDollar(total.upChargeAmount),
        "Delivery Charge": toDollar(total.delivery1PFee),
        "Taxes You Owe": toDollar(total.storeTaxesToRemit),
        ...(showRemittance
            ? {
                  "Taxes Remitted On Your Behalf": toDollar(
                      total.storeTaxesWithheld
                  )
              }
            : {}),
        "Estimated Third-Party Taxes": toDollar(total.store3PTaxesToRemit),
        Tip: toDollar(total.tip),
        Total: toDollar(total.total),
        "3P Delivery Charge": toDollar(total.delivery3PFee),
        "Fax Fee": toDollar(total.faxFee),
        "Snackpass Fee": toDollar(total.commission),
        "Processing Fee": toDollar(total.processingFee),
        "Estimated Third Party Fees": toDollar(total.thirdPartyFees),
        "Cust. To Store Fees": toDollar(total.allCustomerToStoreFees),
        "Cust. To Snackpass Fees": toDollar(total.customerToSnackpassFees),
        "Gift Card Credit": toDollar(total.giftCardCredit),
        "Store Credit": toDollar(total.storeCredit),
        "Net Sales": toDollar(total.netSales),
        Earnings: toDollar(total.earnings),
        "Estimated Third-Party Payout": toDollar(total.expected3PPayout),
        "Expected Cash Collected": toDollar(total.expectedCashCollected),
        "Estimated Payout": toDollar(total.expectedPayout),
        ...(total.actualPayout !== undefined
            ? {
                  "Actual Payout": toDollar(total.actualPayout)
              }
            : {}),
        "Payment Method": "",
        "Scheduled For": "",
        Refund: "",
        Fulfillment: "",
        Source: "",
        Channel: "",
        Catering: "",
        "Refunded By": "",
        "Up-Charged By": "",
        "Cash Accepted By": "",
        "Created By": "",
        ...(isSnackTeam && { ID: "" }),
        ...(isSnackTeam && { "Payout ID": "" })
    }
];
