import { SnackpassTimezoneEnum } from "@snackpass/snackpass-types";
import { DateTime } from "luxon";

const Empty = "--";

const dateTimeFromJSDate = (date: Date) => DateTime.fromJSDate(new Date(date));

/** return a string like "1s" or "30m" for a short format distance to now */
export const lastActiveTime = (lastPingAt: Date | null): string => {
    if (lastPingAt) {
        return dateTimeFromJSDate(lastPingAt).toRelative() ?? Empty;
    }
    return Empty;
};

/** return date in MM/dd/yyyy format */
export const getFormattedDate = (date?: Date | null) => {
    if (date) {
        return (
            dateTimeFromJSDate(date).toLocaleString(DateTime.DATE_SHORT) ??
            Empty
        );
    }
    return Empty;
};

/** return date in MM/dd/yyyy format */
export const getFormattedTime = (date?: Date | null) => {
    if (date) {
        return (
            dateTimeFromJSDate(date).toLocaleString(
                DateTime.TIME_WITH_SHORT_OFFSET
            ) ?? Empty
        );
    }
    return Empty;
};

/** return date in MM/dd/yyyy hh:mm:ss format */
export const getFormattedLongDate = (date: Date | null, timezone?: string) => {
    if (date) {
        return (
            dateTimeFromJSDate(date)
                .setZone(timezone ?? SnackpassTimezoneEnum.newYork)
                .toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS) ?? Empty
        );
    }
    return Empty;
};
