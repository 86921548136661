import { PromoTarget } from "@snackpass/snackpass-types";

import { Routes } from "#navigation/routes";
import {
    FIELD_NAMES,
    FormTypeInfo,
    FormTypes,
    PromoShortcut
} from "#promotion/utils/types";
import { Options } from "#reusable/select/dropdown";

export const DISCOUNT_TYPES = {
    PERCENT_OFF: "PERCENT_OFF",
    DOLLARS_OFF: "DOLLARS_OFF",
    FREE_ITEM: "FREE_ITEM"
};

export const Forms: Record<FormTypes, FormTypeInfo> = {
    [FormTypes.Generic]: {
        route: Routes.CreatePromotionGeneric,
        advancedFields: [
            FIELD_NAMES.REQUIRED_PURCHASE,
            FIELD_NAMES.ONE_PER_CART,
            FIELD_NAMES.SINGLE_USE,
            FIELD_NAMES.LIMIT_USES,
            FIELD_NAMES.PROMO_CODE_ENABLED,
            FIELD_NAMES.SCHEDULE_ENABLED,
            FIELD_NAMES.DURATION_ENABLED
        ]
    },
    [FormTypes.Reward]: {
        route: Routes.CreatePromotionReward,
        advancedFields: []
    },
    [FormTypes.HappyHour]: {
        route: Routes.CreatePromotionHappyHour,
        advancedFields: [
            FIELD_NAMES.REQUIRED_PURCHASE,
            FIELD_NAMES.ONE_PER_CART,
            FIELD_NAMES.SINGLE_USE,
            FIELD_NAMES.LIMIT_USES,
            FIELD_NAMES.DURATION_ENABLED
        ]
    },
    [FormTypes.Bogo]: {
        route: Routes.CreatePromotionBogo,
        advancedFields: [
            FIELD_NAMES.ONE_PER_CART,
            FIELD_NAMES.SINGLE_USE,
            FIELD_NAMES.LIMIT_USES,
            FIELD_NAMES.SCHEDULE_ENABLED,
            FIELD_NAMES.DURATION_ENABLED
        ]
    },
    // Students and FirstTime use the same advanced fields as Generic, but this makes it explicit
    [FormTypes.Students]: {
        route: Routes.CreatePromotionStudents,
        advancedFields: [
            FIELD_NAMES.REQUIRED_PURCHASE,
            FIELD_NAMES.ONE_PER_CART,
            FIELD_NAMES.SINGLE_USE,
            FIELD_NAMES.LIMIT_USES,
            FIELD_NAMES.PROMO_CODE_ENABLED,
            FIELD_NAMES.SCHEDULE_ENABLED,
            FIELD_NAMES.DURATION_ENABLED
        ]
    },
    [FormTypes.FirstTime]: {
        route: Routes.CreatePromotionFirstTime,
        advancedFields: [
            FIELD_NAMES.REQUIRED_PURCHASE,
            FIELD_NAMES.ONE_PER_CART,
            FIELD_NAMES.SINGLE_USE,
            FIELD_NAMES.LIMIT_USES,
            FIELD_NAMES.PROMO_CODE_ENABLED,
            FIELD_NAMES.SCHEDULE_ENABLED,
            FIELD_NAMES.DURATION_ENABLED
        ]
    },
    [FormTypes.PromoCode]: {
        route: Routes.CreatePromotionPromoCode,
        advancedFields: [
            FIELD_NAMES.REQUIRED_PURCHASE,
            FIELD_NAMES.ONE_PER_CART,
            FIELD_NAMES.SINGLE_USE,
            FIELD_NAMES.LIMIT_USES,
            FIELD_NAMES.PROMO_CODE_ENABLED,
            FIELD_NAMES.SCHEDULE_ENABLED,
            FIELD_NAMES.DURATION_ENABLED
        ]
    },
    [FormTypes.DealDrop]: {
        route: Routes.CreatePromotionDealDrop,
        advancedFields: [
            FIELD_NAMES.REQUIRED_PURCHASE,
            FIELD_NAMES.ONE_PER_CART,
            FIELD_NAMES.SINGLE_USE,
            FIELD_NAMES.PROMO_CODE_ENABLED,
            FIELD_NAMES.SCHEDULE_ENABLED
        ]
    },
    [FormTypes.GiftCard]: {
        route: Routes.CreatePromotionGiftCard,
        advancedFields: []
    }
};
export const HOURS_PER_DAY = 24;
export const MINUTES_PER_HOUR = 60;
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_DAY = MINUTES_PER_HOUR * HOURS_PER_DAY;
export const DAYS_OF_WEEK = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
];

export const dayMap = [
    {
        day: "Monday",
        field: FIELD_NAMES.SCHEDULE_MONDAY as const,
        enabled: FIELD_NAMES.SCHEDULE_MONDAY_ENABLED as const
    },
    {
        day: "Tuesday",
        field: FIELD_NAMES.SCHEDULE_TUESDAY as const,
        enabled: FIELD_NAMES.SCHEDULE_TUESDAY_ENABLED as const
    },
    {
        day: "Wednesday",
        field: FIELD_NAMES.SCHEDULE_WEDNESDAY as const,
        enabled: FIELD_NAMES.SCHEDULE_WEDNESDAY_ENABLED as const
    },
    {
        day: "Thursday",
        field: FIELD_NAMES.SCHEDULE_THURSDAY as const,
        enabled: FIELD_NAMES.SCHEDULE_THURSDAY_ENABLED as const
    },
    {
        day: "Friday",
        field: FIELD_NAMES.SCHEDULE_FRIDAY as const,
        enabled: FIELD_NAMES.SCHEDULE_FRIDAY_ENABLED as const
    },
    {
        day: "Saturday",
        field: FIELD_NAMES.SCHEDULE_SATURDAY as const,
        enabled: FIELD_NAMES.SCHEDULE_SATURDAY_ENABLED as const
    },
    {
        day: "Sunday",
        field: FIELD_NAMES.SCHEDULE_SUNDAY as const,
        enabled: FIELD_NAMES.SCHEDULE_SUNDAY_ENABLED as const
    }
];

export const PERCENT_OPTIONS: Options[] = [
    { label: "100", value: 100 },
    { label: "75", value: 75 },
    { label: "50", value: 50 },
    { label: "25", value: 25 }
];

export const DOLLAR_OPTIONS: Options[] = [
    { label: "10", value: 10 },
    { label: "5", value: 5 },
    { label: "2", value: 2 }
];

export const POINTS_OPTIONS: Options[] = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 }
];

export const AUDIENCE_OPTIONS: Options[] = [
    { label: "All customers", value: PromoTarget.All },
    { label: "First-time customers", value: PromoTarget.FirstTime },
    { label: "Students", value: PromoTarget.Students }
];

export const EXPIRATION_DAY_OPTIONS: Options[] = [
    { label: "Never", value: -1 },
    { label: "7", value: 7 },
    { label: "30", value: 30 }
];

export const defaultMenuItem = { label: "", products: [], categories: [] };

export const genericShortcuts = [
    PromoShortcut.Bogo,
    PromoShortcut.HappyHour,
    PromoShortcut.PromoCode,
    PromoShortcut.FirstTime,
    PromoShortcut.DealDrop,
    PromoShortcut.Student,
    PromoShortcut.GiftCard
];

export const socialShortcuts = [
    PromoShortcut.Reward,
    PromoShortcut.Referral,
    PromoShortcut.Party
];
