import React from "react";

import { DeviceType } from "#devices/utils/deviceTypes";
import EditSection from "#devices/components/EditDrawer/EditSection";
import { getDeviceTypeName } from "#devices/utils/deviceOptions";
import { DeleteSection } from "#devices/components/EditDrawer/DeleteSection";
import { EditDeviceName } from "#devices/components/EditDrawer/Settings";
import { EditDeviceType } from "#devices/components/EditDrawer/Settings/DeviceType";

const deviceTypeOptions = [DeviceType.KDS].map((option) => ({
    value: option,
    label: getDeviceTypeName(option) || "--"
}));

export default (): JSX.Element => (
    <>
        <EditSection heading="Info">
            <EditDeviceName />
            <EditDeviceType disabled options={deviceTypeOptions} />
        </EditSection>

        <EditSection heading="Danger Zone">
            <DeleteSection />
        </EditSection>
    </>
);
