import { provideMenuService } from "../service";

/**
 * Fetch the legacy menu for a given store using the legacy menu endpoint.
 *
 * @deprecated Use the `useMenuService` hook instead.
 */
export async function fetchLegacyMenu(storeID: string) {
    const menuService = provideMenuService(storeID);

    const result = await menuService.fetchMenu();

    // Unwrap the result for usage in legacy implementations.
    return result.match(
        (menu) => {
            if (!menu) {
                throw new Error("Menu not found");
            }
            return menu;
        },
        (error) => {
            throw error;
        }
    );
}
