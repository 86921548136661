import React, { useMemo } from "react";
import { TransactionSource } from "@snackpass/snackpass-types";

import { useTransactionSourcePicker } from "#hooks";
import "@djthoms/pretty-checkbox";

import { ReactComponent as FilterIcon } from "src/assets/icons/filter-sort.svg";
import { FilterSelect } from "src/@/components/ui/filter-select";
import { DEFINED_SOURCES } from "#pickers/transaction-source-picker/lib";
import { Button } from "src/@/components/ui/button";

const TransactionSourcePicker = () => {
    const { transactionSources, setGlobalSources } =
        useTransactionSourcePicker();

    const selectedSources = useMemo(
        () => new Set(transactionSources as string[]),
        [transactionSources]
    );

    return (
        <FilterSelect
            customButton={
                <Button
                    variant="outline"
                    size="sm"
                    className="flex items-center space-x-2"
                >
                    <FilterIcon className="h-4 w-4" />
                    <span className="hidden md:block">Source</span>
                </Button>
            }
            title="Source"
            selectedValues={selectedSources}
            options={DEFINED_SOURCES}
            onOptionSelected={function (value: string): void {
                if (selectedSources.has(value)) {
                    selectedSources.delete(value);
                } else {
                    selectedSources.add(value);
                }
                setGlobalSources(
                    Array.from(selectedSources) as TransactionSource[]
                );
            }}
        />
    );
};

export default TransactionSourcePicker;
