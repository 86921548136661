import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import config from "#config";
import { firebaseAuth } from "#app/firebase";

const httpLink = createHttpLink({
    uri: config.gqlUrl
});

const authLink = setContext(async (_, { headers }) => {
    const token = await firebaseAuth.currentUser?.getIdToken();
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ""
        }
    };
});

export const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

export * from "./queries";
export * from "./mutations";
