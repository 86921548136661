/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMemo, useState } from "react";
import {
    CashDrawer,
    CashDrawerWithPrinter,
    ScreenState
} from "@snackpass/snackpass-types";
import styled from "styled-components";
import moment from "moment";
import { toDollar } from "@snackpass/accounting";

import DateRangeSelector from "#date-picker/date-range-selector";
import MoneyIcon from "src/assets/icons/money-green.png";
import { useCashEvents } from "src/api/rest/useCashEvents";
import { gridGap } from "#css";
import colors from "#reusable/colors/colors.json";
import CashDrawerSelector from "#financial-reports/screens/cash-report/cashdrawer-selector";
import { useCashDrawers } from "#devices/hooks/useCashDrawers";
import { CashReportContext } from "#financial-reports/screens/cash-report/cash-report-context";
import { isAuditEvent } from "#financial-reports/screens/cash-report/lib";

import { CashReportTable } from "./cash-report-table";

const CashReportLayout = () => {
    const [selectedCashDrawer, setSelectedCashDrawer] = useState<
        string | undefined
    >(undefined);
    const { data } = useCashEvents(selectedCashDrawer);
    const { data: cashDrawers } = useCashDrawers();

    const lastAuditsPerDrawer = useMemo(
        () =>
            cashDrawers
                ?.filter(
                    (drawer) =>
                        selectedCashDrawer === undefined ||
                        drawer.id === selectedCashDrawer
                )
                .map((cashDrawer: CashDrawerWithPrinter) => {
                    const lastAudit = data.find(
                        (event) =>
                            isAuditEvent(event) &&
                            event.cashDrawer.id === cashDrawer.id
                    );
                    return {
                        cashDrawer,
                        lastAudit
                    };
                }),
        [cashDrawers, data]
    );

    // Has multiple cash drawers and not filtering for any selected cash drawer.
    const viewingMultipleCashDrawers =
        cashDrawers &&
        cashDrawers.length > 1 &&
        selectedCashDrawer === undefined;

    const verifiedLabels = lastAuditsPerDrawer?.map(
        ({ cashDrawer, lastAudit }) => {
            const verifiedLabel = lastAudit
                ? `Verified ${moment(lastAudit.createdAt).format(
                      "MM/DD/YY [at] H:mm a"
                  )}`
                : "Never Verified";

            return `${
                viewingMultipleCashDrawers ? `${cashDrawer.name}: ` : ""
            }${verifiedLabel}`;
        }
    );

    const cashDrawerContextValue = useMemo(
        () => ({
            cashDrawer: selectedCashDrawer,
            setCashDrawer: setSelectedCashDrawer
        }),
        [selectedCashDrawer, setSelectedCashDrawer]
    );

    const totalsPerCashDrawer = useMemo(
        () =>
            data.reduce(
                (acc, { cashDrawer, totalAmount }) =>
                    acc.find((e) => e.cashDrawer.id === cashDrawer.id)
                        ? acc
                        : acc.concat([{ cashDrawer, totalAmount }]),
                [] as {
                    cashDrawer: CashDrawer;
                    totalAmount: number;
                }[]
            ),
        [data]
    );

    const totalAmount = useMemo(
        () =>
            totalsPerCashDrawer.reduce(
                (acc, { totalAmount }) => acc + totalAmount,
                0
            ),
        [totalsPerCashDrawer]
    );

    return (
        <div css={bodyStyle}>
            <CashReportContext.Provider value={cashDrawerContextValue}>
                <div css={headerStyle}>
                    <div className="title">Cash</div>
                    <DateRangeSelector subtitle={"Currently showing "} />
                    <CashDrawerSelector />
                </div>
                <Grid>
                    <div className="info-container">
                        <img src={MoneyIcon} className="cash-icon" />
                        <div className="info-description">
                            <h1 className="cash-total mb-2">
                                {toDollar(totalAmount)}
                            </h1>
                            {verifiedLabels?.map((label) => (
                                <p className="info-subtitle">{label}</p>
                            ))}
                        </div>
                    </div>
                    <CashReportTable />
                </Grid>
            </CashReportContext.Provider>
        </div>
    );
};

const { MOBILE, TABLET, DESKTOP } = ScreenState;
const { MOBILE_GRIDGAP, TABLET_GRIDGAP, DESKTOP_GRIDGAP } = gridGap;

const Grid = styled.span`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        "1"
        "2";

    @media ${MOBILE} {
        grid-gap: ${MOBILE_GRIDGAP};
        grid-template-rows: min-content min-content;
    }

    @media ${TABLET} {
        grid-gap: ${TABLET_GRIDGAP};
        grid-template-rows: min-content min-content;
    }

    @media ${DESKTOP} {
        grid-gap: ${DESKTOP_GRIDGAP};
        grid-template-rows: min-content min-content;
    }
`;

const headerStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 15px;
    gap: 8px;
    .hover {
        margin-left: auto;
    }
    .title {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
    }
`;

const bodyStyle = css`
    .info-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 24px;
        gap: 24px;

        border: 1px solid ${colors["neutral-400"]};
        border-radius: 16px;
    }
    .cash-icon {
        width: 48px;
        height: 48px;
    }
    .info-description {
        flex-direction: column;
    }
    .cash-total {
        font-size: 48px;
        font-weight: 700;
    }
    .info-subtitle {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
    }

    @media ${ScreenState.MOBILE} {
        margin: 16px 16px 0px 16px;
    }

    @media ${ScreenState.TABLET} {
        margin: 24px 24px 0px 24px;
    }

    @media ${ScreenState.DESKTOP} {
        margin: 48px 48px 0px 48px;
    }
`;

export default CashReportLayout;
