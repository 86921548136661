import { Draft } from "immer";
import { curry } from "lodash";
import {
    AddonGroup,
    IProduct,
    Menu as MultiMenu
} from "@snackpass/snackpass-types";

import { MultiMenuState } from "#menu-editor/multi-menus/redux/types";

const findCategoryById = (state: Draft<MultiMenuState>, categoryId: string) =>
    state.currentMenu.categories?.find(({ id }) => id === categoryId);

const filterCategory = (state: Draft<MultiMenuState>, categoryId: string) =>
    state.currentMenu.categories?.filter(({ id }) => id !== categoryId);

const getAddonGroupsOverrides = (state: Draft<MultiMenuState>) =>
    state.currentMenu.menuOverrides?.addonGroups;

const getAddonOverrides = (state: Draft<MultiMenuState>) =>
    state.currentMenu.menuOverrides?.addons;

const selectProduct = (
    state: Draft<MultiMenuState>,
    categoryId: string,
    productId: string
) => {
    if (!state.currentMenu.categories) return;
    const productCategory = findCategoryById(state, categoryId);
    if (productCategory && !productCategory.productIds.includes(productId)) {
        productCategory.productIds.push(productId);
    } else if (!productCategory) {
        state.currentMenu.categories.push({
            id: categoryId,
            productIds: [productId]
        });
    }
};

const discardProductOverrides = curry(
    (state: Draft<MultiMenuState>, productId: string) => {
        const productOverrides = state.currentMenu.menuOverrides?.products;
        if (productOverrides?.[productId]) delete productOverrides[productId];
    }
);

const selectAddon = (
    state: Draft<MultiMenuState>,
    addonGroupId: string,
    addonId: string
) => {
    const addonGroupOverrides = state.currentMenu.menuOverrides?.addonGroups;
    if (!addonGroupOverrides) return;
    const parentOverride = addonGroupOverrides[addonGroupId];
    if (parentOverride && !parentOverride.addons.includes(addonId)) {
        parentOverride.addons.push(addonId);
    } else if (!parentOverride) {
        addonGroupOverrides[addonGroupId] = { addons: [addonId] };
    }
};

const discardAddonOverride = curry(
    (state: Draft<MultiMenuState>, addonId: string) => {
        const addonOverrides = getAddonOverrides(state);
        if (!addonOverrides) return;
        if (addonOverrides[addonId]) delete addonOverrides[addonId];
    }
);

const selectAddonGroup = curry(
    (state: Draft<MultiMenuState>, addonGroup: AddonGroup) => {
        const addonGroupsOverrides =
            state.currentMenu.menuOverrides?.addonGroups;
        if (!addonGroupsOverrides) return;
        addonGroupsOverrides[addonGroup._id] = {
            addons: addonGroup.addons.map(({ _id }) => _id)
        };
    }
);

const selectProductRequiredAddonGroups = (
    state: Draft<MultiMenuState>,
    product: IProduct
) =>
    product.addonGroups
        .filter(({ required }) => required)
        .forEach(selectAddonGroup(state));

const discardAddonGroup = curry(
    (state: Draft<MultiMenuState>, addonGroup: AddonGroup) => {
        const addonGroupsOverrides = getAddonGroupsOverrides(state);
        if (!addonGroupsOverrides) return;
        if (addonGroupsOverrides[addonGroup._id])
            delete addonGroupsOverrides[addonGroup._id];
        addonGroup.addons
            .map(({ _id }) => _id)
            .forEach(discardAddonOverride(state));
    }
);

const updateMenu = curry(
    (state: Draft<MultiMenuState>, multiMenu: MultiMenu) => {
        const index = state.storeMenus.findIndex(
            ({ id }) => id === multiMenu.id
        );
        if (index !== -1) state.storeMenus[index] = multiMenu;
    }
);

const resetMenuItems = (state: Draft<MultiMenuState>) => {
    state.currentMenu.categories = [];
    state.currentMenu.menuOverrides = {
        addonGroups: {},
        products: {},
        addons: {}
    };
};

export const reducerHelpers = {
    findCategoryById,
    filterCategory,
    selectProduct,
    discardProductOverrides,
    selectAddon,
    discardAddonOverride,
    selectAddonGroup,
    selectProductRequiredAddonGroups,
    discardAddonGroup,
    getAddonGroupsOverrides,
    getAddonOverrides,
    updateMenu,
    resetMenuItems
};
